import { useCallback, useEffect, useRef } from 'react';

export default function useDebouncedCallback(callback, depth = [], timeout) {
    const timerRef = useRef(0);

    const debouncedCallback = useCallback((...args) => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            callback(...args);
        }, timeout);
    }, depth);

    useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        [],
    );

    return debouncedCallback;
}
