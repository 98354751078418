import React from 'react';

import css from './index.module.css';

import InfoItem from './InfoItem';

const InfoBlock = ({data}) => {
    return (
        <div className={css.collectionData}>
            {data.map((data, id) => <InfoItem key={id} title={data.title} value={data.value} />)}
        </div>
    )
}

export default InfoBlock;