import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    addCollection,
    addStatus,
    removeCollection,
    removeStatus,
    setCreatedGreateThenInH,
} from '../../../redux/slices/admin/whitelists';
import {
    useGetFilteredSimplifiedAccountsQuery,
    useGetFilteredSimplifiedCollectionsQuery,
} from '../../../redux/api/handleService';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const WhitelistFiltersContext = React.createContext({});

const WhitelistFiltersContextProvider = (props) => {
    const { children } = props;

    const dispatch = useDispatch();

    const [selectedAccounts, setSelectedAccounts] = useState([]);

    const [collections, setCollections] = useState([]);
    const [collectionsSearchQuery, setCollectionsSearchQuery] = useState('');
    const [collectionsPage, setCollectionPage] = useState(1);
    const collectionsTotalPages = useRef(0);

    const [accounts, setAccounts] = useState([]);
    const [accountsSearchQuery, setAccountsSearchQuery] = useState('');
    const [accountsPage, setAccountsPage] = useState(1);
    const accountsTotalPages = useRef(0);

    const {
        data: accountsData,
        isFetching: isAccountsLoading,
    } = useGetFilteredSimplifiedAccountsQuery({
        query: accountsSearchQuery,
        page: accountsPage,
        pageSize: 15,
    });

    const {
        data: collectionsData,
        isFetching: isCollectionsLoading,
    } = useGetFilteredSimplifiedCollectionsQuery({
        accountsIds: selectedAccounts.map((el) => el.id),
        query: collectionsSearchQuery,
        page: collectionsPage,
        pageSize: 15,
    });

    const handleSelectAccounts = useCallback((account) => {
        setSelectedAccounts((p) => [...p, account]);
        setCollections([]);
    }, []);

    const handleRemoveAccounts = useCallback((id) => {
        setSelectedAccounts((p) => p.filter((el) => el.id !== id));
        setCollections([]);
    }, []);

    const addCollectionIdHandler = useCallback((collection) => {
        dispatch(addCollection(collection));
    }, []);

    const removeCollectionIdHandler = useCallback((id) => {
        dispatch(removeCollection(id));
    }, []);

    const handleChangeCollectionsSearchQuery = useDebouncedCallback(
        (value) => {
            setCollectionsSearchQuery(value);
            setCollectionPage(1);
            setCollections([]);
        },
        [],
        500,
    );

    const handleChangeAccountsSearchQuery = useDebouncedCallback(
        (value) => {
            setAccountsSearchQuery(value);
            setAccountsPage(1);
            setAccounts([]);
            setSelectedAccounts([]);
        },
        [],
        500,
    );

    const handleChangeDateGte = useCallback((value) => {
        dispatch(setCreatedGreateThenInH(value));
    }, []);

    const increaseCollectionsPage = useCallback(() => {
        if (collectionsPage < collectionsTotalPages.current && !isCollectionsLoading) {
            setCollectionPage((p) => p + 1);
        }
    }, [collectionsPage, isCollectionsLoading]);

    const increaseAccountsPage = useCallback(() => {
        if (accountsPage < accountsTotalPages.current && !isAccountsLoading) {
            setAccountsPage((p) => p + 1);
        }
    }, [accountsPage, isAccountsLoading]);

    const handleAddStatusFilter = useCallback((status) => {
        dispatch(addStatus(status));
    }, []);

    const removeStatusFilter = useCallback((status) => {
        dispatch(removeStatus(status));
    }, []);

    useEffect(() => {
        if (collectionsData) {
            collectionsTotalPages.current = collectionsData.total_pages || 0;
            setCollections((p) => [...p, ...collectionsData.results]);
        }
    }, [collectionsData]);

    useEffect(() => {
        if (accountsData) {
            accountsTotalPages.current = accountsData.total_pages || 0;
            setAccounts((p) => [...p, ...accountsData.results]);
        }
    }, [accountsData]);

    const actions = {
        addCollectionIdHandler,
        removeCollectionIdHandler,

        handleSelectAccounts,
        handleRemoveAccounts,
        handleChangeCollectionsSearchQuery,
        handleChangeAccountsSearchQuery,
        handleChangeDateGte,

        increaseCollectionsPage,
        increaseAccountsPage,

        handleAddStatusFilter,
        removeStatusFilter,
    };

    return (
        <WhitelistFiltersContext.Provider
            value={{
                actions,
                state: {
                    collections: collections || [],
                    accounts: accounts || [],
                    selectedAccounts,
                    isCollectionsLoading,
                    isAccountsLoading,
                },
            }}
        >
            {children}
        </WhitelistFiltersContext.Provider>
    );
};

export { WhitelistFiltersContext, WhitelistFiltersContextProvider };
