import React from 'react';

const Alcohol = ({ ...props }) => {
    return (
        <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M11.5007 11.9167C17.2507 11.9167 19.1673 7.81275 19.1673 2.75H3.83398C3.83398 7.81275 5.75065 11.9167 11.5007 11.9167ZM11.5007 11.9167V18.3333M11.5007 18.3333L15.334 20.1667H7.66732L11.5007 18.3333ZM4.79232 7.33333H18.209"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default Alcohol;
