export const detectDeviceUrl = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Проверяем, является ли устройство мобильным
    const isMobile = /android|iphone|ipad|ipod/i.test(userAgent);

    if (isMobile) {
        if (/android/i.test(userAgent)) {
            return 'https://play.google.com/store/apps/details?id=io.metamask&hl=en&pli=1';
        } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            return 'https://apps.apple.com/ru/app/metamask-blockchain-wallet/id1438144202';
        } else {
            return 'https://metamask.io/ru/';
        }
    } else {
        return 'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
    }
};