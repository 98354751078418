import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';

import { TokenInfo } from '../../components/TokenInfo';
import UserAvatar from '../../components/UserAvatar/UserAvatar';

const OwnerItem = ({}) => {
    return (
        <div className={styles.ownersItem}>
            <img src="/assets/img/brand-light.png" alt="light" className={styles.ownersItemLight} />

            <div className={styles.ownersItemWrapper}>
                <Link to="#" className={styles.ownersItemUser}>
                    <UserAvatar avatar="/assets/img/avatar2.png" style="silver" />

                    <span className={styles.ownersItemUserInfo}>
                        <span className={styles.ownersItemUserName}>Виктор Сергеевич</span>

                        <span className={styles.ownersItemUserRole}>Collector Adran</span>
                    </span>
                </Link>

                <div className={styles.ownersItemCollections}>
                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand1.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand2.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand1.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand2.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <Link to="#" className={styles.ownersItemCollectionsMore}>
                        Ещё 10
                    </Link>
                </div>

                <p className={cn(styles.ownersItemPrice, styles.silver)}>
                    <span>38,7 млн</span>
                </p>
            </div>
        </div>
    );
};

export default OwnerItem;
