import React from 'react';
import { Grid, IconButton } from '@mui/material';

const CollectionAction = (props) => {
    const { status, firstIcon, firstAction, secondIcon, secondAction } = props;

    return (
        <div className="collection_action_container collection__action">
            <div className="collection__status">
                <span className="collection__whiteList--addedTitle">{status}</span>
            </div>
            <IconButton disableRipple sx={{ padding: 0 }} onClick={firstAction}>
                <div className="collection__whiteList--trashIconBox">{firstIcon}</div>
            </IconButton>
            {secondIcon && secondAction && (
                <IconButton disableRipple sx={{ padding: 0 }} onClick={secondAction}>
                    <div className="collection__whiteList--trashIconBox">{secondIcon}</div>
                </IconButton>
            )}
        </div>
    );
};

export default React.memo(CollectionAction);
