exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PropertyItem_tokenInfoProp__1_5Gk{\n    width: calc(100% / 3 - 12px / 3);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    grid-gap: 12px;\n    gap: 12px;\n    padding: 1.8rem 2rem 1.1rem 2rem;\n    background: #1B2337;\n    border-radius: 10px;\n}\n\n@media (max-width: 998px) {\n    .PropertyItem_tokenInfoProp__1_5Gk{\n        width: calc(100% / 2 - 6px / 2);\n    }\n}\n\n.PropertyItem_tokenInfoPropTitle__2rZ_h, .PropertyItem_tokenInfoPropValue__22Zfs, .PropertyItem_tokenInfoPropSubtext__1xO4e{\n    width: 100%;\n    text-align: center;\n    line-height: 1;\n}\n\n.PropertyItem_tokenInfoPropTitle__2rZ_h{\n    color: #2069E2;\n    font-size: 1.3rem;\n    font-weight: 700;\n    text-transform: uppercase;\n}\n\n.PropertyItem_tokenInfoPropValue__22Zfs{\n    color: #FCFDFF;\n    font-size: 1.5rem;\n    font-weight: 700;\n}\n\n.PropertyItem_tokenInfoPropSubtext__1xO4e{\n    font-size: 1.3rem;\n    font-weight: 300;\n    color: rgba(255, 255, 255, .46);\n    margin-top: 0.3rem;\n}", ""]);

// exports
exports.locals = {
	"tokenInfoProp": "PropertyItem_tokenInfoProp__1_5Gk",
	"tokenInfoPropTitle": "PropertyItem_tokenInfoPropTitle__2rZ_h",
	"tokenInfoPropValue": "PropertyItem_tokenInfoPropValue__22Zfs",
	"tokenInfoPropSubtext": "PropertyItem_tokenInfoPropSubtext__1xO4e"
};