import React from 'react';

const TrashWithArrow = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.3556 7.18813C19.3698 7.08116 19.3371 6.9732 19.2658 6.89192C19.1948 6.81063 19.092 6.76401 18.9838 6.76401C16.8278 6.76401 7.17087 6.76401 5.01462 6.76401C4.90666 6.76401 4.80394 6.81063 4.73263 6.89192C4.66157 6.9732 4.6289 7.08116 4.64287 7.18813C4.93159 9.37479 6.24858 19.3479 6.50415 21.2835C6.52883 21.47 6.6879 21.6094 6.87615 21.6094H17.1225C17.3108 21.6094 17.4696 21.47 17.4943 21.2835C17.7499 19.3479 19.0668 9.37479 19.3556 7.18813ZM13.5962 14.1515C13.5962 14.2011 13.6159 14.249 13.6511 14.2842C13.6862 14.3193 13.7341 14.339 13.7837 14.339C14.2455 14.339 15.4478 14.339 16.1092 14.339C16.185 14.339 16.2536 14.3847 16.2825 14.4547C16.3115 14.5248 16.2955 14.6056 16.2419 14.6592C15.3303 15.5707 12.747 18.1541 12.1319 18.7692C12.0586 18.8423 11.9399 18.8423 11.8668 18.7692C11.2517 18.1541 8.66837 15.5707 7.7568 14.6592C7.70319 14.6056 7.68699 14.5248 7.71616 14.4547C7.74508 14.3847 7.8134 14.339 7.8892 14.339C8.55093 14.339 9.75322 14.339 10.215 14.339C10.2646 14.339 10.3122 14.3193 10.3474 14.2842C10.3825 14.249 10.4025 14.2011 10.4025 14.1515C10.4025 13.3773 10.4025 10.4335 10.4025 9.65928C10.4025 9.60941 10.4222 9.56179 10.4573 9.52663C10.4925 9.49148 10.5401 9.47178 10.59 9.47178C11.1557 9.47178 12.8427 9.47178 13.4087 9.47178C13.4586 9.47178 13.5062 9.49148 13.5414 9.52663C13.5765 9.56179 13.5962 9.60941 13.5962 9.65928C13.5962 10.4335 13.5962 13.3773 13.5962 14.1515ZM10.3097 3.60195H4.11328C3.90633 3.60195 3.73828 3.76975 3.73828 3.97695V4.97553C3.73828 5.18273 3.90633 5.35053 4.11328 5.35053H19.8852C20.0924 5.35053 20.2604 5.18273 20.2604 4.97553V3.97695C20.2604 3.76975 20.0924 3.60195 19.8852 3.60195H13.689V2.76569C13.689 2.6662 13.6496 2.57071 13.5793 2.50039C13.5089 2.43008 13.4134 2.39069 13.314 2.39069C12.6639 2.39069 11.3347 2.39069 10.6847 2.39069C10.5852 2.39069 10.4898 2.43008 10.4194 2.50039C10.3491 2.57071 10.3097 2.6662 10.3097 2.76569V3.60195Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default React.memo(TrashWithArrow);
