import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // page: 1,
  // lastItemsCount: 20,
  // packs: [],
  // packsPage: 1,
  // packsLastItemsCount: 20,
  // name: '',
}

const packsStatisticSlice = createSlice({
  name: 'packs',
  initialState: initialState,
  reducers: {
    // packsIncrementPage: (state) => {
    //   state.page += 1
    // },
    // packsSetLastItemsCount: (state, action) => {
    //   state.lastItemsCount = action.payload
    // },
    // packsAddPageData: (state, action) => {
    //   state.packs = [...state.packs, ...action.payload]
    // },
    // packsRemove: (state) => {
    //   state.packs = []
    //   state.page = 1
    //   state.lastItemsCount = 20
    // },
    // changeSearchPackName: (state, action) => {
    //   state.name = action.payload
    // },
    // setPacks: (state,action) => {
    //   state.packs = action.payload
    // }
  },
})

export const {
  // packsSetLastItemsCount,
  // packsRemove,
  // packsAddPageData,
  // packsIncrementPage,
  // changeSearchPackName,
  // setPacks
} = packsStatisticSlice.actions

export const packsStatisticReducer = packsStatisticSlice.reducer
