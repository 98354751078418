import React, { useCallback, useEffect, useState, useMemo } from 'react';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS } from '../../../const/breakpoints/BREAKPOINTS';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeletePackMutation } from '../../../redux/api/dataService';
import { CancelModal } from '../../Сonclusions/modals/CancelModal';
import { handleCopySelectedPack } from './utils';
import { useDynamicPagination } from '../../useDynamicPagination';
import { useGenerateRoutes, useGetTableView } from '../../useGetTableView';
import { useOutletContext } from 'react-router-dom';
import { expandListOfParameters } from '../../utils';

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/pack/factory`;
const accessToken = localStorage.getItem('access_token');
const Packs = () => {
    const location = useLocation();
    const { collectionId, accountId, pageId } = useParams();
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const navigate = useNavigate();
    const [isDelete, setIsDelete] = useState(false);
    const [clickedItemId, setClickedItemId] = useState();
    const [deletePack] = useDeletePackMutation();
    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
    useOutletContext();
    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'packs');
    useGenerateRoutes([
        { title: 'page', id: pageId },
        { title: 'account', id: accountId },
        { title: 'collection', id: collectionId },
    ]);
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            { title: 'page_id', value: pageId },
            { title: 'collection_id', value: collectionId },
            { title: 'account_id', value: accountId },
            { title: 'page_size', value: 20 },
        ]);
    }, [accountId, pageId, requestParams, requestParams]);

    const items = useDynamicPagination(TABLE_URL, params, isResetList, setIsResetList, setCount);
    useEffect(() => {
        if (items) {
            setCount(items.count);
        }
    }, [items]);
    const handleDeletePackRequest = (id) => {
        if (clickedItemId) {
            deletePack({
                id: clickedItemId,
            })
                .unwrap()
                .finally(() => {
                    setIsDelete(false);
                });
        }
    };

    const handleNavigate = useCallback(
        (el, type) => {
            if (type === 'navigateNext' && el && el.page && el.collection && el.account) {
                navigate(`../tokens/${el.page.id}/${el.collection.id}/${el.id}/${el.account.id}`);
            }
            if (type === 'navigateEdit' && el && el.page && el.collection && el.account) {
                navigate(
                    `/admin/edit/pack/${el.id}/${el.page.id}/${el.collection.id}/${el.account.id}`,
                );
            }
        },
        [items],
    );
    const handleDownloadQr = (el) => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/download_qr_codes_for_pack/${el.id}/`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json',
                },
            },
        )
            .then((response) => response.blob())
            .then((blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `${el.name}.zip`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
            });
    };

    return (
        <>
            <TableRow names differentColumn>
                <TableCell title largeColumn>
                    Статус
                </TableCell>

                <TableCell title largeColumn>
                    Пак
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    Токены
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD620}>
                    Доход
                </TableCell>

                <TableCell title textCenter textOrange breakpoint={BREAKPOINTS.MD1200}>
                    Продано
                </TableCell>

                <TableCell title textCenter textGreen breakpoint={BREAKPOINTS.MD1200}>
                    Забронировано
                </TableCell>

                <TableCell title textCenter textBlue breakpoint={BREAKPOINTS.MD1200}>
                    Отчеканено
                </TableCell>
            </TableRow>
            {items &&
                items.items.map((el, i) => {
                    return (
                        <TableRow
                            differentColumn
                            actionType="factoryPacks"
                            handleDownloadQr={() => handleDownloadQr(el)}
                        >
                            <TableCell largeColumn onClick={() => handleNavigate(el)}>
                                <p className="whithdraw__index">{i + 1}</p>

                                <div className="stats__item--status--circle white"></div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <p className="withdraw__item--collection--name stats__item--text--white">
                                        {el.status}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell
                                largeColumn
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                <div className="stats__item--wrapper withdraw__item">
                                    <Link className="withdraw__item--collection--name">
                                        {el && el.collection && el.collection.name}
                                        <br />
                                        <p
                                            style={{
                                                maxWidth: '100%',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {el.name}
                                        </p>
                                    </Link>
                                </div>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD700}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.items_count}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {Math.floor(el.volume_troded_count / 100)} RUB
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textOrange
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.owners_count}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textGreen
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                0
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textBlue
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                0
                            </TableCell>
                        </TableRow>
                    );
                })}
            <CancelModal
                handleOk={() => {
                    handleDeletePackRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            />
        </>
    );
};

export default Packs;
