const DIALOG_TYPES = {
    PERSONS: 'persons',
    BRAND: 'brand',
    WATCHES: 'watches',
    ALCO: 'alco',
    CAR: 'car',
    NRC: 'nrc',
};

export { DIALOG_TYPES };
