import React from 'react';
import styles from '../../index.module.css';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
import { useGetAccountsListFilteredQuery } from '../../../../redux/api/handleService';
import Link from '../../../../icons/Link';
import Vk from '../../../../icons/Vk';
import Youtube from '../../../../icons/Youtube';
import Instagram from '../../../../icons/Instagram';
import Telegram2 from '../../../../icons/Telegram2';
import Share from '../../../../icons/Share';
import { useLocation, useNavigate } from 'react-router-dom';

const getImageBacketPath = (path) => {
    try {
        return `${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${path}`;
    } catch (e) {
        return path;
    }
};

const Partners = () => {
    const { data: accaunts, isLoading } = useGetAccountsListFilteredQuery({
        page: 1,
        pageSize: 8,
    });
    const navigate = useNavigate();
    const location = useLocation()
    const scrollToHash = (hash) => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
                location.hash = '';
            }
        }
    };

    return (
        <section className={styles.partners} id="partner">
            <div className={base.container}>
                <div className={styles.partnersInner}>
                    <h2 className={cn(base.title2, styles.partnersTitle)}>Партнеры проекта</h2>

                    <div className={styles.partnersContentWrapper}>
                        <div className={styles.partnersContent}>
                            <img
                                src="/assets/img/partners-bg.svg"
                                alt="partnersbg"
                                className={styles.partnersBg}
                            />
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr ',
                                    gap: '10px',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    botton: 0,
                                }}
                            >
                                {!isLoading &&
                                    accaunts &&
                                    accaunts.results &&
                                    accaunts.results.map((account, index) => {
                                        const {
                                            id,
                                            logo,
                                            name,
                                            description,
                                            link_instagram: linkInstagram,
                                            link_telegram: linkTelegram,
                                            link_vk: linkVk,
                                            link_yoursite: linkYoursite,
                                            link_youtube: linkYoutube,
                                        } = account;
                                        return (
                                            <div
                                                className={cn(
                                                    styles.partnersItem,
                                                    styles[`partner${index + 1}`],
                                                )}
                                                key={index}
                                            >
                                                <Link to={`/brandnew/${id}`}>
                                                    <img
                                                        src={getImageBacketPath(logo)}
                                                        alt="partner"
                                                        className={styles.partnersItemImg}
                                                    />
                                                </Link>

                                                <div
                                                    className={styles.brandItemInfoPopupWrapper}
                                                >
                                                    <div className={styles.brandItemInfoPopup}>
                                                        <div
                                                            className={
                                                                styles.brandItemInfoPopupContent
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.brandItemInfoPopupName
                                                                }
                                                            >
                                                                {name}
                                                            </p>

                                                            <p

                                                                className={
                                                                    styles.brandItemInfoPopupText
                                                                }
                                                            >
                                                                {description}
                                                            </p>

                                                            <div
                                                                className={
                                                                    styles.brandItemPopupSocialLinks
                                                                }
                                                            >
                                                                {linkVk &&
                                                                    linkVk !== 'null' && (
                                                                        <a
                                                                            href={linkVk}
                                                                            className={
                                                                                styles.brandItemPopupSocialLink
                                                                            }
                                                                        >
                                                                            <Vk />
                                                                        </a>
                                                                    )}
                                                                {linkYoutube &&
                                                                    linkYoutube !== 'null' && (
                                                                        <a
                                                                            href={linkYoutube}
                                                                            className={
                                                                                styles.brandItemPopupSocialLink
                                                                            }
                                                                        >
                                                                            <Youtube />
                                                                        </a>
                                                                    )}
                                                                {linkInstagram &&
                                                                    linkInstagram !==
                                                                    'null' && (
                                                                        <a
                                                                            href={linkInstagram}
                                                                            className={
                                                                                styles.brandItemPopupSocialLink
                                                                            }
                                                                        >
                                                                            <Instagram />
                                                                        </a>
                                                                    )}
                                                                {linkTelegram &&
                                                                    linkTelegram !== 'null' && (
                                                                        <a
                                                                            href={linkInstagram}
                                                                            className={
                                                                                styles.brandItemPopupSocialLink
                                                                            }
                                                                        >
                                                                            <Telegram2 />
                                                                        </a>
                                                                    )}
                                                            </div>
                                                            {linkYoursite &&
                                                                linkYoursite !== 'null' && (
                                                                    <a
                                                                        href={linkYoursite}
                                                                        className={
                                                                            styles.brandItemSite
                                                                        }
                                                                    >
                                                                        <Share />
                                                                        {linkYoursite}
                                                                    </a>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>

                            <div className={styles.partnersWrap}>
                                <div className={styles.partnersContentWrap}>
                                    {!isLoading &&
                                        accaunts &&
                                        accaunts.results &&
                                        accaunts.results.map((accaunt, index) => {
                                            const {
                                                id,
                                                logo,
                                                name,
                                                description,
                                                link_instagram: linkInstagram,
                                                link_telegram: linkTelegram,
                                                link_vk: linkVk,
                                                link_yoursite: linkYoursite,
                                                link_youtube: linkYoutube,
                                            } = accaunt;
                                            return (
                                                <div className={styles.partnersContentItem}>
                                                    <Link to={`/brandnew/${id}`}>
                                                        <img
                                                            src={getImageBacketPath(logo)}
                                                            alt="partner"
                                                            className={styles.partnersItemImg}
                                                        />
                                                    </Link>

                                                    <div
                                                        className={
                                                            styles.brandItemInfoPopupWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.brandItemInfoPopup
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.brandItemInfoPopupContent
                                                                }
                                                            >
                                                                <p
                                                                    className={
                                                                        styles.brandItemInfoPopupName
                                                                    }
                                                                >
                                                                    {name}
                                                                </p>

                                                                <p
                                                                    className={
                                                                        styles.brandItemInfoPopupText
                                                                    }
                                                                >
                                                                    {description}
                                                                </p>

                                                                <div
                                                                    className={
                                                                        styles.brandItemPopupSocialLinks
                                                                    }
                                                                >
                                                                    {linkVk &&
                                                                        linkVk !== 'null' && (
                                                                            <a
                                                                                href={linkVk}
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Vk />
                                                                            </a>
                                                                        )}
                                                                    {linkYoutube &&
                                                                        linkYoutube !==
                                                                        'null' && (
                                                                            <a
                                                                                href={
                                                                                    linkYoutube
                                                                                }
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Youtube />
                                                                            </a>
                                                                        )}
                                                                    {linkInstagram &&
                                                                        linkInstagram !==
                                                                        'null' && (
                                                                            <a
                                                                                href={
                                                                                    linkInstagram
                                                                                }
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Instagram />
                                                                            </a>
                                                                        )}
                                                                    {linkTelegram &&
                                                                        linkTelegram !==
                                                                        'null' && (
                                                                            <a
                                                                                href={
                                                                                    linkInstagram
                                                                                }
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Telegram2 />
                                                                            </a>
                                                                        )}
                                                                </div>
                                                                {linkYoursite &&
                                                                    linkYoursite !== 'null' && (
                                                                        <a
                                                                            href={linkYoursite}
                                                                            className={
                                                                                styles.brandItemSite
                                                                            }
                                                                        >
                                                                            <Share />
                                                                            {linkYoursite}
                                                                        </a>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>

                                <p className={styles.partnersWrapTitle}>Партнеры</p>

                                <p className={styles.partnersText}>Они доверяют нам</p>

                                <div className={styles.partnersButtons}>
                                    <button
                                        className={cn(
                                            styles.partnersButton,
                                            styles.partnersButtonBlue,
                                        )}
                                        onClick={() => {
                                            scrollToHash('#start_cooperation');
                                        }}
                                    >
                                        Стать партнером
                                    </button>

                                    <button
                                        className={cn(base.orangeButton, styles.partnersButton)}
                                        onClick={() => {
                                            navigate(`/brandsnew`);
                                        }}
                                    >
                                        Смотреть всех
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;
