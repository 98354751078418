import React from 'react';

const Instagram = ({ ...props }) => {
    return (
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.3 1.5C10.5 1.5 10.8 1.5 11.7 1.5C12.5 1.5 12.9 1.7 13.2 1.8C13.6 2 13.9 2.1 14.2 2.4C14.5 2.7 14.7 3 14.8 3.4C14.9 3.7 15 4.1 15.1 4.9C15.1 5.8 15.1 6 15.1 8.3C15.1 10.6 15.1 10.8 15.1 11.7C15.1 12.5 14.9 12.9 14.8 13.2C14.6 13.6 14.5 13.9 14.2 14.2C13.9 14.5 13.6 14.7 13.2 14.8C12.9 14.9 12.5 15 11.7 15.1C10.8 15.1 10.6 15.1 8.3 15.1C6 15.1 5.8 15.1 4.9 15.1C4.1 15.1 3.7 14.9 3.4 14.8C3 14.6 2.7 14.5 2.4 14.2C2.1 13.9 1.9 13.6 1.8 13.2C1.7 12.9 1.6 12.5 1.5 11.7C1.5 10.8 1.5 10.6 1.5 8.3C1.5 6 1.5 5.8 1.5 4.9C1.5 4.1 1.7 3.7 1.8 3.4C2 3 2.1 2.7 2.4 2.4C2.7 2.1 3 1.9 3.4 1.8C3.7 1.7 4.1 1.6 4.9 1.5C5.8 1.5 6.1 1.5 8.3 1.5ZM8.3 0C6 0 5.8 0 4.9 0C4 0 3.4 0.200001 2.9 0.400001C2.4 0.600001 1.9 0.900001 1.4 1.4C0.900001 1.9 0.700001 2.3 0.400001 2.9C0.200001 3.4 0.1 4 0 4.9C0 5.8 0 6.1 0 8.3C0 10.6 0 10.8 0 11.7C0 12.6 0.200001 13.2 0.400001 13.7C0.600001 14.2 0.900001 14.7 1.4 15.2C1.9 15.7 2.3 15.9 2.9 16.2C3.4 16.4 4 16.5 4.9 16.6C5.8 16.6 6.1 16.6 8.3 16.6C10.5 16.6 10.8 16.6 11.7 16.6C12.6 16.6 13.2 16.4 13.7 16.2C14.2 16 14.7 15.7 15.2 15.2C15.7 14.7 15.9 14.3 16.2 13.7C16.4 13.2 16.5 12.6 16.6 11.7C16.6 10.8 16.6 10.5 16.6 8.3C16.6 6.1 16.6 5.8 16.6 4.9C16.6 4 16.4 3.4 16.2 2.9C16 2.4 15.7 1.9 15.2 1.4C14.7 0.900001 14.3 0.700001 13.7 0.400001C13.2 0.200001 12.6 0.1 11.7 0C10.8 0 10.6 0 8.3 0Z"
                fill="currentColor"
            />
            <path
                d="M8.3 4C5.9 4 4 5.9 4 8.3C4 10.7 5.9 12.6 8.3 12.6C10.7 12.6 12.6 10.7 12.6 8.3C12.6 5.9 10.7 4 8.3 4ZM8.3 11.1C6.8 11.1 5.5 9.9 5.5 8.3C5.5 6.8 6.7 5.5 8.3 5.5C9.8 5.5 11.1 6.7 11.1 8.3C11.1 9.8 9.8 11.1 8.3 11.1Z"
                fill="currentColor"
            />
            <path
                d="M12.7 4.9C13.2523 4.9 13.7 4.45229 13.7 3.9C13.7 3.34772 13.2523 2.9 12.7 2.9C12.1477 2.9 11.7 3.34772 11.7 3.9C11.7 4.45229 12.1477 4.9 12.7 4.9Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Instagram;
