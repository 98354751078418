import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import FeedbackForm from './FeedbackForm';

const Refund = () => {
    return (
        <div className={styles.marginWrapper}>
            <img src="/assets/img/doc-bg.png" alt="bg" className={styles.docBg} />

            <div className={base.container}>
                <div className={styles.docWrapper}>
                    <div className={styles.breadcrumbs}>
                        <Link to="/" className={styles.breadcrumbsLink}>
                            Главная
                        </Link>

                        <p className={styles.breadcrumbsText}>Возврат средств</p>
                    </div>

                    <h2 className={styles.docTitle}>Возврат средств</h2>

                    <p className={styles.docText}>Условия возврата средств</p>

                    <p className={styles.docText}>
                        В соответствии с Законом Российской Федерации от 07.02.1992 N 2300-1 (ред.
                        от 04.08.2023) "О защите прав потребителей", возврат средств возможен при
                        соблюдении следующих условий:
                    </p>

                    <p className={styles.docText}>Подача заявления о возврате:</p>

                    <ul className={styles.docUl}>
                        <li>
                            Для возврата средств необходимо написать в Службу поддержки, указав
                            причину возврата и приложив копию документа, подтверждающего покупку
                            (электронный чек, квитанцию).
                        </li>
                    </ul>

                    <p className={styles.docText}>Срок подачи заявления:</p>

                    <ul className={styles.docUl}>
                        <li>
                            Заявление на возврат средств должно быть подано не позднее 14 дней с
                            момента оплаты Тарифа.
                        </li>
                        <li>
                            Возврат средств возможен только за оплаченные цифровые сертификаты,
                            разработка и создание которых не было запущено в работу Платформой.
                        </li>
                        <li>
                            Не осуществляется возврат средств за цифровые сертификаты в отношении
                            которых началось согласование и разработка дизайна.
                        </li>
                    </ul>

                    <p className={styles.docText}>Порядок возврата средств:</p>

                    <ul className={styles.docUl}>
                        <li>
                            Возврат средств производится на тот же способ оплаты, который был
                            использован при покупке.
                        </li>
                        <li>
                            Срок возврата средств составляет не более 40 рабочих дней с момента
                            получения нами заявления о возврате.
                        </li>
                    </ul>

                    <p className={styles.docText}>Исключения:</p>

                    <ul className={styles.docUl}>
                        <li>
                            Возврат средств не производится за цифровые сертификаты в отношении
                            которых началось согласование и разработка дизайна.
                        </li>
                    </ul>

                    <p className={styles.docText}>Прочие условия:</p>

                    <ul className={styles.docUl}>
                        <li>
                            В случае возникновения споров, они разрешаются в соответствии с
                            законодательством Российской Федерации.
                        </li>
                    </ul>

                    <p className={styles.docText}>
                        Пожалуйста, внимательно ознакомьтесь с условиями возврата перед покупкой.
                        Ваше удовлетворение услугами Платформы важно для нас, и мы стремимся
                        обеспечить максимально удобные и прозрачные условия для наших партнёров и
                        клиентов.
                    </p>

                    <FeedbackForm />
                </div>
            </div>
        </div>
    );
};

export default Refund;
