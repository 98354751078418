exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CheckBox_root__2GAUo {\n    padding: 10px;\n    color: white;\n}\n\n.CheckBox_root__2GAUo:hover svg {\n    border-radius: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "CheckBox_root__2GAUo"
};