import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import QRCode from 'react-qr-code';
import Linkify from 'react-linkify';
import { VideoPlayer } from '../../common/VideoPlayer';
import Modal from '../../common/Modal';
import { onClose, onOpen } from '../../redux/dialogs/aplyToWhitelistDialog';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import { useGetCollectionQuery, useGetTokenQuery } from '../../redux/api/dataService';
import { useGetFilteredTokensQuery } from '../../redux/api/handleService';
import { normilizeError } from '../../utils/http/normilizeError';
import { isVideo } from '../../utils/isVideo';
import { roundInt } from '../../utils/roundInt';
import { copyUrl, copyText } from '../../functions/utils';
import {
    useBookTokenMutation,
    useGetCollectionWhiteListQuery,
    usePostCollectionTokensForCheckMutation,
} from '../../redux/api/ugcService';
import { pagesSelectors } from '../../redux/slices/pages';
import WLCreationDialog from '../../components/WlModals/WLCreationDialog';
import { DIALOG_TYPES } from '../../components/WlModals/const';
import useUploadToBlockChain from '../../hooks/useUploadToBlockChain';
import TokenCardSkeleton from './TokenCardSkeleton';
import { COLLECTION_PAGE } from '../../const/http/CLIENT_URLS';
import ImageWithModal from '../../common/ImageWithModal';
import FullDescription from '../../common/FullDescription';
import { PatnerOffer } from './components/PartnerOffer/PatnerOffer'
import './index.css';
import {PayModal} from "./PayModal/PayModal";
import { SellTimer } from './components/SellTimer/SellTimer';
import styles from './index.module.css';
import { WHITE_LIST_APPLICATION_STATUSES } from '../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import TokenItem from '../Collection/UI/TokenItem';
import { CollectionCertificates } from './components/CollectionCertificates/CollectionCertificates';
import { OwnersReturn } from './components/OwnersReturn/OwnersReturn';
import { Footer } from '../Main/components/Footer';


const DIALOG_TYPES_ARR = Object.values(DIALOG_TYPES);
const COLLECTION_TOKENS_PER_PAGE = 10;

const TokenCard = () => {
    const [social, setSocial] = React.useState(false);
    const [qrModal, setQrModal] = React.useState(false);
    const [isPay, setIsPay] = useState(false)


    const { whitelistMintToken } = useUploadToBlockChain();


    const { id } = useParams();
    const pages = useSelector(pagesSelectors.selectAll);
    const authInfo = useSelector((state) => state.auth);
    const createWLDialog = useSelector((state) => state.aplyToWhitelistDialog);

    const dispatch = useDispatch();

    const {
        data: token,
        error: getTokenError,
        isLoading,
        refetch: refetchToken,
    } = useGetTokenQuery(
        {
            id,
        },
        {
            skip: !id,
        },
    );

    const {
        data: collection,
        error: getCollectionError,
        isLoading: isTokenCollectionLoading,
    } = useGetCollectionQuery(
        {
            id: token ? token.collection.id : '',
        },
        {
            skip: !token || !token.collection.id,
        },
    );


    // const {
    //     data: whiteListApplicationData,
    //     isLoading: isWhitelistApplicationLoading,
    //     isSuccess: isWhitelistApplicationSuccess,
    //     error: whitelistApplicationError,
    //     refetch: refetchWhiteListApplication,
    // } = useGetCollectionWhiteListQuery(
    //     {
    //         id: token && token.collection.id,
    //     },
    //     {
    //         skip: !token || !token.collection.id,
    //     },
    // );

    // const [
    //     checkCollectionTokens,
    //     { data: bookedTokensRS, error: checkCollectionTokensError },
    // ] = usePostCollectionTokensForCheckMutation();

    const [
        bookToken,
        { data: tokenBookedData, isLoading: bookTokenInProccessing },
    ] = useBookTokenMutation();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const [prop, setProp] = useState(true);
    const [desc, setDesc] = useState(true);
    const [about, setAbout] = useState(true);
    const [partnerOffers, setPartnerOffers] = useState(true)

    const [isTokensCheckInProgress, setIsTokensCheckInProgress] = useState(false);
    const [whiteListApplication, setWhiteListApplication] = useState(null);
    const [bookedTokensIds, setBookedTokensIds] = useState([]);

    // const onMint = useCallback(() => {
    //     if (!authInfo.walletAddress) {
    //         addNotification({
    //             type: NOTIFICATION_TYPES.ERROR,
    //             text: 'Authorize first',
    //         });
    //     }
    //
    //     whitelistMintToken({
    //         userAddress: authInfo.walletAddress,
    //     });
    // }, [authInfo.walletAddress]);

    // const onGetToWhitelistHandler = useCallback(() => {
    //     if (!authInfo.isAuth || !collection || !collection.id) {
    //         addNotification({
    //             type: NOTIFICATION_TYPES.ERROR,
    //             text: 'Authorize through your wallet to get items to whitelist',
    //         });
    //
    //         return;
    //     }
    //
    //     dispatch(onOpen(collection.id));
    // }, [authInfo.isAuth, collection]);

    const closeCreationDialogHandler = useCallback(() => {
        dispatch(onClose());
    }, []);

    const onBookTokenHandler = useCallback(
        (tokenId) => {
            if (whiteListApplication) {
                bookToken({ id: whiteListApplication.id, tokenId });
            }
        },
        [whiteListApplication],
    );

    const collectionPage = useMemo(() => {
        if (collection && collection.page && pages && pages.length > 0) {
            return pages.find((p) => p.id === collection.page) || null;
        }
        return null;
    }, [pages, collection]);

    const actionBtn = useMemo(() => {
        if (!token) {
            return null;
        }

        if (
           !token.paid
        ) {
            return (
                <button
                    className="button token__card--content--price--buy"
                    onClick={() => setIsPay(true)}
                >
                    Купить
                </button>
            );
        }

        // status white
        // if (WHITE_LIST_APPLICATION_STATUSES.WHITE === whiteListApplication.status) {
        //     // if token not booked BUT WHITELIST APPLICATION EXISTS
        //     if (whiteListApplication) {
        //         if (!whiteListApplication.token_id) {
        //             if (token.status === TOKEN_STATUSES.STOP) {
        //                 return (
        //                     <Grid
        //                         container
        //                         direction="row"
        //                         justifyContent="flex-start"
        //                         alignItems="center"
        //                         flexWrap="nowrap"
        //                         gap={1}
        //                         className="token_card_indicator_container"
        //                     >
        //                         <div className="collection__applicationStatus_container">
        //                             <span className="collection__whiteList--addedTitle">
        //                                 Whitelisted
        //                             </span>
        //                         </div>
        //                     </Grid>
        //                 );
        //             }
        //
        //             return (
        //                 <button
        //                     disabled={bookTokenInProccessing}
        //                     onClick={() => {
        //                         onBookTokenHandler(token.id);
        //                     }}
        //                     className="token_card_indicator_container collection__get default__hover button token__card--content--price--buy"
        //                 >
        //                     Book
        //                 </button>
        //             );
        //             // if token id exists in application
        //         } else {
        //             if (token.status === TOKEN_STATUSES.BOOK) {
        //                 return (
        //                     <Grid
        //                         container
        //                         direction="row"
        //                         justifyContent="flex-start"
        //                         alignItems="center"
        //                         flexWrap="nowrap"
        //                         gap={1}
        //                         className="token_card_indicator_container"
        //                     >
        //                         <div className="collection__applicationStatus_container">
        //                             <span className="collection__whiteList--addedTitle">
        //                                 {whiteListApplication.token_id &&
        //                                 token.id === whiteListApplication.token_id
        //                                     ? 'Booked'
        //                                     : 'Token in this collection already booked'}
        //                             </span>
        //                         </div>
        //                     </Grid>
        //                 );
        //             }
        //
        //             if (
        //                 token.status === TOKEN_STATUSES.MINT_1 &&
        //                 whiteListApplication.token_id === token.id
        //             ) {
        //                 return (
        //                     <button
        //                         onClick={onMint}
        //                         className="token_card_indicator_container collection__get default__hover button token__card--content--price--buy"
        //                     >
        //                         Mint
        //                     </button>
        //                 );
        //             }
        //         }
        //     }
        // }

        if (!bookedTokensIds.every((bookedId) => bookedId !== token.id)) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap={1}
                    className="token_card_indicator_container"
                >
                    <div className="collection__applicationStatus_container">
                        <span className="collection__whiteList--addedTitle">Забронирован</span>
                    </div>
                </Grid>
            );
        }

        // if ([WHITE_LIST_APPLICATION_STATUSES.NEW].includes(whiteListApplication.status)) {
        //     return (
        //         <Grid
        //             container
        //             direction="row"
        //             justifyContent="flex-start"
        //             alignItems="center"
        //             flexWrap="nowrap"
        //             gap={1}
        //         >
        //             <div className="collection__applicationStatus_container">
        //                 <span className="collection__whiteList--addedTitle">
        //                     Whitelist application pendding
        //                 </span>
        //             </div>
        //         </Grid>
        //     );
        // }

        return null;
    }, [whiteListApplication, token, bookedTokensIds, collection, authInfo.isAuth]);



    const tokenStatus = useMemo(() => {
        if (!token || (token && token.paid)) {
            return null;
        }
        if (!token.paid) {
            return (
              <>
                  <img src="/assets/img/clock.svg" alt="clock" />
                  <SellTimer tokenId={token.id}/>
              </>
            );
        }

        // if (token.status === TOKEN_STATUSES.STOP) {
        //     return (
        //         <>
        //             <img src="/assets/img/clock.svg" alt="clock" />
        //             <p className="token__card--price--text">
        //                 Сертификат продан
        //             </p>
        //         </>
        //     );
        // }
        //
        // if (token.status === TOKEN_STATUSES.BOOK) {
        //     return (
        //         <>
        //             <img src="/assets/img/clock.svg" alt="clock" />
        //             <p className="token__card--price--text">
        //                 В данный момент идет этап бронирования
        //             </p>
        //         </>
        //     );
        // }
        //
        // if (token.status === TOKEN_STATUSES.MINT_1) {
        //     return (
        //         <>
        //             <img src="/assets/img/clock.svg" alt="clock" />
        //             <p className="token__card--price--text">
        //                 В настоящее время ведется частное создание
        //             </p>
        //         </>
        //     );
        // }
    }, [token]);

    const ownedBy = useMemo(() => {
        if (!token) {
            return null;
        }
        if (!token.email) {
            return <>Нет владельца</>;
        }

        // Вы владелец
        if (token.paid && token.email && authInfo && authInfo.email && authInfo.email === token.email) {
            return <>Вы владелец</>;
        }

        if (token.paid) {
            return <>Сертификат продан за {Math.floor(token.price / 100).toLocaleString('ru-RU')} RUB</>;
        }




    }, [token]);

    const copyUrlPage = () => {
        copyUrl();
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Ссылка скопирована!',
        });
        setSocial(false);
    };


    useEffect(() => {
        if (getTokenError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(getTokenError),
            });
        }
    }, [getTokenError]);

    useEffect(() => {
        if (getCollectionError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(getCollectionError),
            });
        }
    }, [getCollectionError]);


    useEffect(() => {
        document.addEventListener('click', (e) => {
            const div = document.getElementById('social__modal');
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setSocial(false);
            }
        });
    }, []);

    // useEffect(() => {
    //     if (isWhitelistApplicationSuccess && whiteListApplicationData) {
    //         setWhiteListApplication(whiteListApplicationData);
    //     }
    // }, [whiteListApplicationData, isWhitelistApplicationSuccess]);

    // useEffect(() => {
    //     if (collectionTokens && collectionTokens.results && collectionTokens.results.length) {
    //         setIsTokensCheckInProgress(true);
    //         const ids = collectionTokens.results.map((t) => t.id);
    //
    //         checkCollectionTokens({ tokens: [...ids, token.id] });
    //     }
    // }, [collectionTokens, token]);

    // useEffect(() => {
    //     if (bookedTokensRS) {
    //         setBookedTokensIds(bookedTokensRS.tokens);
    //         setIsTokensCheckInProgress(false);
    //     }
    // }, [bookedTokensRS]);

    // useEffect(() => {
    //     if (checkCollectionTokensError) {
    //         setIsTokensCheckInProgress(false);
    //     }
    // }, [checkCollectionTokensError]);

    // useEffect(() => {
    //     if (whitelistApplicationError) {
    //         setIsTokensCheckInProgress(false);
    //     }
    // }, [whitelistApplicationError]);

    useEffect(() => {
        if (tokenBookedData) {
            setWhiteListApplication(tokenBookedData);

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Token booked successfuly',
            });
        }
    }, [tokenBookedData]);

    // useEffect(() => {
    //     if (token && token.collection.id) {
    //         refetchWhiteListApplication({
    //             id: token.collection.id,
    //         });
    //     }
    // }, [token]);

    if (
        !token ||
        isTokenCollectionLoading ||
        isLoading ||
        // isWhitelistApplicationLoading ||
        isTokensCheckInProgress
    ) {
        return <TokenCardSkeleton />;
    }

    return (
        <>
            <div className="token__card">
                <div className="container">
                    <div className="token__card--inner">
                        <div className="mobile__profile--inner">
                            <div className="profile__buttons">
                                {/*<button*/}
                                {/*    className="button profile__share"*/}
                                {/*    onClick={() => setQrModal(true)}*/}
                                {/*>*/}
                                {/*    <img*/}
                                {/*        src="/assets/img/qr.svg"*/}
                                {/*        alt="qr"*/}
                                {/*        className="profile__share--icon"*/}
                                {/*    />*/}
                                {/*</button>*/}
                            </div>

                            {/*<div className="profile__ava--inner">*/}
                            {/*    <img*/}
                            {/*        src="/assets/img/avatar.png"*/}
                            {/*        alt="ava"*/}
                            {/*        className="profile__ava--img"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>

                        <div className="token__card--content">
                            <div className="token__card--left">
                                <div className="token__card--img--inner">
                                    <div className="token__card--img--box">
                                        <div className="mobile__profile--inner">
                                            <p className="profile__owned">{ownedBy}</p>
                                            <div className="profile__share" id="social__modal">
                                                <img
                                                  src="/assets/img/dots.svg"
                                                  alt="dots"
                                                  className="profile__share--icon"
                                                  onClick={() => setSocial(prev => !prev)}
                                                />

                                                {social && (
                                                  <div className="collection__dots--social">
                                                      <a href="#" className="collection__dots--link">
                                                          <img
                                                            src="/assets/img/reload.svg"
                                                            alt="refresh"
                                                            className="collection__dots--link--icon"
                                                          />
                                                          Обновить данные
                                                      </a>

                                                      {/*<a href="#" className="collection__dots--link">*/}
                                                      {/*    <img*/}
                                                      {/*      src="/assets/img/opensea.svg"*/}
                                                      {/*      alt="opensea"*/}
                                                      {/*      className="collection__dots--link--icon"*/}
                                                      {/*    />*/}
                                                      {/*    OpenSea*/}
                                                      {/*</a>*/}

                                                      <div
                                                        className="collection__dots--link"
                                                        onClick={copyUrlPage}
                                                      >
                                                          <img
                                                            src="/assets/img/share2.svg"
                                                            alt="Share"
                                                            className="collection__dots--link--icon"
                                                          />
                                                          Поделиться
                                                      </div>
                                                  </div>
                                                )}
                                            </div>
                                        </div>
                                        {/*<button className="button token__card--copy">*/}
                                        {/*    <img*/}
                                        {/*        src="/assets/img/eth-token.svg"*/}
                                        {/*        alt="eth"*/}
                                        {/*        className="token__card--copy--icon"*/}
                                        {/*    />*/}
                                        {/*</button>*/}

                                    </div>

                                    {isVideo(token.file_2_name_ext) ? (
                                        <div className="player-wrapper token__card--container">
                                            <VideoPlayer
                                                src={token.file_2}
                                                className="token__card--video"
                                            />
                                        </div>
                                    ) : (
                                        <ImageWithModal
                                            src={token.file_2 || token.file_1}
                                            alt="NFT image"
                                            className="token__card--img lazy-load-image-background"
                                            style={{
                                                backgroundSize: 'cover',
                                                objectFit: 'cover',
                                                backgroundPosition: 'center',
                                                minWidth: '100%'
                                            }}
                                        />
                                    )}
                                </div>

                                <div className="token__card--wrap stack mobileoff">
                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setDesc(prev => !prev)}
                                            className="token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/description.svg"
                                                    alt="dates"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    Описание
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    desc ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {desc && (
                                            <div className="token__card--item--bottom">
                                                {/* <p className="token__card--item--about--text">
                                                    {token.pack.name}
                                                </p> */}
                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                    ) => (
                                                        <a
                                                            key={decoratedText}
                                                            href={decoratedHref}
                                                            className="desc_link"
                                                        >
                                                            {decoratedText}
                                                        </a>
                                                    )}
                                                >
                                                    <p className="token__card--item--about--text">
                                                        {token.description}
                                                    </p>
                                                </Linkify>
                                            </div>
                                        )}
                                    </div>

                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setProp(prev => !prev)}
                                            className="token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/prop.svg"
                                                    alt="prop"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    Свойства
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    prop ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {prop && (
                                            <div className="token__card--item--bottom prop">
                                                <div className="token__card--item--prop">
                                                    {(token.properties || []).map((p) => (
                                                        <div
                                                            key={p.id}
                                                            className="token__card--item--prop--item"
                                                        >
                                                            <p className="token__card--item--prop--item--subtitle">
                                                                {p.type === 'Status' ? 'Статус' : p.type}
                                                            </p>

                                                            <p className="token__card--item--prop--item--title">
                                                                {p.name}
                                                            </p>

                                                            {/*<p className="token__card--item--prop--item--text">*/}
                                                            {/*    10% имеют такое св-во*/}
                                                            {/*</p>*/}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setAbout(prev => !prev)}
                                            className="token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/about.svg"
                                                    alt="about"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    О компании {token.collection.name}
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    about ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {about && (
                                            <div className="token__card--item--bottom">
                                                <div className="token__card--item--about">
                                                    <div className="token__card--item--about--item">
                                                        <div
                                                            style={{
                                                                backgroundImage: collection && collection.logo ? `url('${collection.logo}')` : 'none',
                                                                backgroundSize: 'cover',
                                                                objectFit: 'cover',
                                                                backgroundPosition: 'center',
                                                            }}
                                                            className="token__card--item--about--img"
                                                        />
                                                        {collection &&
                                                          <Linkify
                                                            componentDecorator={(
                                                              decoratedHref,
                                                              decoratedText,
                                                            ) => (
                                                              <a
                                                                key={decoratedText}
                                                                href={decoratedHref}
                                                                className="desc_link"
                                                              >
                                                                  {decoratedText}
                                                              </a>
                                                            )}
                                                          >
                                                              <p className="token__card--item--about--text">
                                                                  {collection.description}
                                                              </p>
                                                          </Linkify>
                                                        }

                                                    </div>
                                                    {collection &&
                                                      <div className="token__card--item--social">
                                                          {collection && collection.link_yoursite && (
                                                            <a
                                                              href={collection.link_yoursite}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/Internet.svg"
                                                                  alt="twitter"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection && collection.link_telegram && (
                                                            <a
                                                              href={collection.link_telegram}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/tg.svg"
                                                                  alt="opensea"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection && collection.link_vk && (
                                                            <a
                                                              href={collection.link_vk}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/vk.svg"
                                                                  alt="insta"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection && collection.link_instagram && (
                                                            <a
                                                              href={collection.link_instagram}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/insta.svg"
                                                                  alt="discord"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection && collection.link_youtube && (
                                                              <a
                                                                  href={collection.link_youtube}
                                                                  className="token__card--item--social--link"
                                                                  target={'_blank'}
                                                              >
                                                                  <img
                                                                      src="/assets/img/youtube.svg"
                                                                      alt="discord"
                                                                      className="token__card--item--social--icon"
                                                                  />
                                                              </a>
                                                          )}

                                                      </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/*<div className="token__card--item">*/}
                                    {/*    <div*/}
                                    {/*        onClick={() => setDetails(prev => !prev)}*/}
                                    {/*        className="token__card--item--top"*/}
                                    {/*    >*/}
                                    {/*        <span className="token__card--item--top--title--inner">*/}
                                    {/*            <img*/}
                                    {/*                src="/assets/img/details.svg"*/}
                                    {/*                alt="details"*/}
                                    {/*                className="token__card--item--top--title--icon"*/}
                                    {/*            />*/}

                                    {/*            <p className="token__card--item--top--title">*/}
                                    {/*                Details*/}
                                    {/*            </p>*/}
                                    {/*        </span>*/}

                                    {/*        <img*/}
                                    {/*            src="/assets/img/arrow-select.svg"*/}
                                    {/*            alt="arrow"*/}
                                    {/*            className={`token__card--item--open${*/}
                                    {/*                details ? ' active' : ''*/}
                                    {/*            }`}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}

                                    {/*    {details && (*/}
                                    {/*        <div className="token__card--item--bottom">*/}
                                    {/*            <div className="token__card--item--details">*/}
                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Contract Address*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text blueC bold cup" onClick={copyAddress}>*/}
                                    {/*                        0xa5f1...f215*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Token ID*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text blueC bold">*/}
                                    {/*                        {token.id.slice(0, 5)}...*/}
                                    {/*                        {token.id.slice(-5)}*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Token Standard*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        ERC-721*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Blockchain*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        Ethereum*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Creator Fee*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        {roundInt({*/}
                                    {/*                            num: Number(token.creator_royalty),*/}
                                    {/*                        })}*/}
                                    {/*                        %*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Investor Fee*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        {roundInt({*/}
                                    {/*                            num: Number(token.investor_royalty),*/}
                                    {/*                        })}*/}
                                    {/*                        %*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className="token__card--content--right">
                                <div className="token__card--social--wrap">
                                    <div className="token__card--content--title--inner">
                                        <a
                                            href={COLLECTION_PAGE({ id: token.collection.id })}
                                            className="token__card--content--subtitle"
                                        >
                                            {token.collection.name}
                                        </a>

                                        <h2 className="token__card--content--title">
                                            {token.name}
                                        </h2>

                                        <p
                                            className="token__card--content--name"
                                            style={{
                                                marginBottom: token.paid ? '10px' : 0
                                            }}
                                        >
                                            {ownedBy}
                                        </p>
                                    </div>

                                    <div className="token__card--item--social big">
                                        <a
                                            href="#"
                                            onClick={refetchToken}
                                            className="token__card--item--social--link"
                                        >
                                            <img
                                                src="/assets/img/reload.svg"
                                                alt="reload"
                                                className="token__card--item--social--icon"
                                            />
                                        </a>

                                        <div
                                            className="button token__card--item--social--link"
                                            onClick={() => setQrModal(true)}
                                        >
                                            <img
                                                src="/assets/img/qr.svg"
                                                alt="qr"
                                                className="token__card--item--social--icon w20"
                                            />
                                        </div>

                                        {/*<a*/}
                                        {/*    href="#"*/}
                                        {/*    className="button token__card--item--social--link"*/}
                                        {/*>*/}
                                        {/*    <img*/}
                                        {/*        src="/assets/img/opensea.svg"*/}
                                        {/*        alt="opensea"*/}
                                        {/*        className="token__card--item--social--icon"*/}
                                        {/*        style={{width: 24}}*/}
                                        {/*    />*/}
                                        {/*</a>*/}

                                        <div
                                            className="token__card--item--social--link"
                                            onClick={copyUrlPage}
                                        >
                                            <img
                                                src="/assets/img/share2.svg"
                                                alt="share"
                                                className="token__card--item--social--icon"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {!token.paid &&
                                    <div className="token__card--price">
                                        {!token.paid &&
                                            <div className="token__card--price--wrap--text">
                                                {tokenStatus}
                                            </div>
                                        }


                                        <div className="token__card--price--box">
                                            <p className="token__card--price--subtext">Стоимость</p>

                                            <div className="token__card--content--price--val--inner">
                                                <p className="token__card--content--price--val">
                                                    {Math.floor(token.price/100) } RUB
                                                </p>
                                            </div>

                                            {actionBtn}
                                        </div>
                                    </div>
                                }

                                <OwnersReturn token={token} collection={collection} tokenRefetch={refetchToken} />



                                {/*<div className="token__card--wrap full mobileoff">*/}
                                {/*    <div className="token__card--item">*/}
                                {/*        <div*/}
                                {/*            onClick={() => setHistory(prev => !prev)}*/}
                                {/*            className="token__card--item--top"*/}
                                {/*        >*/}
                                {/*            <span className="token__card--item--top--title--inner">*/}
                                {/*                <img*/}
                                {/*                    src="/assets/img/history.svg"*/}
                                {/*                    alt="history"*/}
                                {/*                    className="token__card--item--top--title--icon"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--item--top--title">*/}
                                {/*                    Price History*/}
                                {/*                </p>*/}
                                {/*            </span>*/}

                                {/*            <img*/}
                                {/*                src="/assets/img/arrow-select.svg"*/}
                                {/*                alt="arrow"*/}
                                {/*                className={`token__card--item--open${*/}
                                {/*                    history ? ' active' : ''*/}
                                {/*                }`}*/}
                                {/*            />*/}
                                {/*        </div>*/}

                                {/*        {history && (*/}
                                {/*            <div className="token__card--item--bottom empty p0">*/}
                                {/*                /!* <img*/}
                                {/*                    src="/assets/img/info.svg"*/}
                                {/*                    alt="info"*/}
                                {/*                    className="price__empty--icon"*/}
                                {/*                />*/}

                                {/*                <p className="price__empty--title">*/}
                                {/*                    Events from the marketplace*/}
                                {/*                </p>*/}

                                {/*                <p className="price__empty--text">*/}
                                {/*                    Check back after minting.*/}
                                {/*                </p> *!/*/}

                                {/*                <StatChart class="token__card--chart" />*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="token__card--wrap full mobileoff">*/}
                                {/*    <div className="token__card--item">*/}
                                {/*        <div*/}
                                {/*            onClick={() => setList(prev => !prev)}*/}
                                {/*            className="token__card--item--top"*/}
                                {/*        >*/}
                                {/*            <span className="token__card--item--top--title--inner">*/}
                                {/*                <img*/}
                                {/*                    src="/assets/img/listings.svg"*/}
                                {/*                    alt="listings"*/}
                                {/*                    className="token__card--item--top--title--icon"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--item--top--title">*/}
                                {/*                    Listings*/}
                                {/*                </p>*/}
                                {/*            </span>*/}

                                {/*            <img*/}
                                {/*                src="/assets/img/arrow-select.svg"*/}
                                {/*                alt="arrow"*/}
                                {/*                className={`token__card--item--open${*/}
                                {/*                    list ? ' active' : ''*/}
                                {/*                }`}*/}
                                {/*            />*/}
                                {/*        </div>*/}

                                {/*        {list && (*/}
                                {/*            <div className="token__card--item--bottom token__card--table">*/}
                                {/*                /!* <img*/}
                                {/*                    src="/assets/img/no-listing.svg"*/}
                                {/*                    alt="empty"*/}
                                {/*                    className="token__card--empty--img"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--empty--subtext">*/}
                                {/*                    No listings yet*/}
                                {/*                </p> *!/*/}

                                {/*                <div className="token__card--table--items headers">*/}
                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        Price*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        USD Price*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title center">*/}
                                {/*                        Quantity*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        Expiration*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        From*/}
                                {/*                    </p>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/opensea.svg" alt="opensea" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/binance.svg" alt="binance" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/r.svg" alt="r" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="token__card--wrap full mobileoff">*/}
                                {/*    <div className="token__card--item">*/}
                                {/*        <div*/}
                                {/*            onClick={() => setOffers(prev => !prev)}*/}
                                {/*            className="token__card--item--top"*/}
                                {/*        >*/}
                                {/*            <span className="token__card--item--top--title--inner">*/}
                                {/*                <img*/}
                                {/*                    src="/assets/img/offers.svg"*/}
                                {/*                    alt="offers"*/}
                                {/*                    className="token__card--item--top--title--icon"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--item--top--title">*/}
                                {/*                    Offers*/}
                                {/*                </p>*/}
                                {/*            </span>*/}

                                {/*            <img*/}
                                {/*                src="/assets/img/arrow-select.svg"*/}
                                {/*                alt="arrow"*/}
                                {/*                className={`token__card--item--open${*/}
                                {/*                    offers ? ' active' : ''*/}
                                {/*                }`}*/}
                                {/*            />*/}
                                {/*        </div>*/}

                                {/*        {offers && (*/}
                                {/*            <div className="token__card--item--bottom token__card--table">*/}
                                {/*                /!* <img*/}
                                {/*                    src="/assets/img/no-offers.svg"*/}
                                {/*                    alt="empty"*/}
                                {/*                    className="token__card--empty--img"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--empty--subtext">*/}
                                {/*                    No offers yet*/}
                                {/*                </p> *!/*/}

                                {/*                <div className="token__card--table--items headers">*/}
                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        Price*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        USD Price*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title center">*/}
                                {/*                        Quantity*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        Expiration*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--title">*/}
                                {/*                        From*/}
                                {/*                    </p>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/opensea.svg" alt="opensea" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/binance.svg" alt="binance" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}

                                {/*                <div className="token__card--table--items">*/}
                                {/*                    <p className="token__card--table--item token__table--price">*/}
                                {/*                        10,000 RUB*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        $2,090.55*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text center">*/}
                                {/*                        1*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--text">*/}
                                {/*                        in 1 month*/}
                                {/*                    </p>*/}

                                {/*                    <p className="token__card--table--item token__table--name">*/}
                                {/*                        user name*/}
                                {/*                    </p>*/}

                                {/*                    <button className="button token__card--table--button">*/}
                                {/*                        Buy*/}

                                {/*                        <img src="/assets/img/r.svg" alt="r" className="token__card--button--icon" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="token__card--wrap full mobileoff">*/}
                                {/*    <div className="token__card--item">*/}
                                {/*        <div*/}
                                {/*            onClick={() => setConnecting(prev => !prev)}*/}
                                {/*            className="token__card--item--top"*/}
                                {/*        >*/}
                                {/*            <span className="token__card--item--top--title--inner">*/}
                                {/*                <img*/}
                                {/*                    src="/assets/img/connecting.svg"*/}
                                {/*                    alt="connecting"*/}
                                {/*                    className="token__card--item--top--title--icon"*/}
                                {/*                />*/}

                                {/*                <p className="token__card--item--top--title">*/}
                                {/*                    Connecting*/}
                                {/*                </p>*/}
                                {/*            </span>*/}

                                {/*            <img*/}
                                {/*                src="/assets/img/arrow-select.svg"*/}
                                {/*                alt="arrow"*/}
                                {/*                className={`token__card--item--open${*/}
                                {/*                    offers ? ' active' : ''*/}
                                {/*                }`}*/}
                                {/*            />*/}
                                {/*        </div>*/}

                                {/*        {connecting && (*/}
                                {/*            <div className="token__card--item--bottom token__card--table empty">*/}
                                {/*                <div className="token__connect--inner">*/}
                                {/*                    <img src="/assets/img/tshirt.svg" alt="tshirt" className="token__connect--img" />*/}

                                {/*                    <div className="token__connect--line"></div>*/}

                                {/*                    <img src="/assets/img/nft.svg" alt="nft" className="token__connect--img2" />*/}
                                {/*                </div>*/}

                                {/*                <p className="token__connect--status">Delivery status: <span className="request">Request</span></p>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {
                                  token.properties &&
                                  token.properties.some((el) => el.type === 'Status') &&
                                  <PatnerOffer collection={collection} setPartnerOffers={setPartnerOffers} partnerOffers={partnerOffers} token={token}/>
                                }



                                <div className="token__card--wrap stack mobile">
                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setDesc(prev => !prev)}
                                            className="button token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/description.svg"
                                                    alt="dates"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    Описание
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    desc ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {desc && (
                                            <div className="token__card--item--bottom">
                                                {/* <p className="token__card--item--about--text">
                                                    {token.pack.name}
                                                </p> */}
                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                    ) => (
                                                        <a
                                                            key={decoratedText}
                                                            href={decoratedHref}
                                                            className="desc_link"
                                                        >
                                                            {decoratedText}
                                                        </a>
                                                    )}
                                                >
                                                    <p className="token__card--item--about--text scrollable-text">
                                                        {token.description}
                                                    </p>
                                                </Linkify>
                                            </div>
                                        )}
                                    </div>

                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setProp(prev => !prev)}
                                            className="token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/prop.svg"
                                                    alt="prop"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    Свойства
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    prop ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {prop && (
                                            <div className="token__card--item--bottom prop">
                                                <div className="token__card--item--prop">
                                                    {(token.properties || []).map((p) => (
                                                        <div
                                                            key={p.id}
                                                            className="token__card--item--prop--item"
                                                        >
                                                            <p className="token__card--item--prop--item--subtitle">
                                                                {p.type === 'Status' ? 'Статус' : p.type}
                                                            </p>

                                                            <p className="token__card--item--prop--item--title">
                                                                {p.name}
                                                            </p>

                                                            <p className="token__card--item--prop--item--text">
                                                                10% имею такое св-во
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="token__card--item">
                                        <div
                                            onClick={() => setAbout(prev => !prev)}
                                            className="token__card--item--top"
                                        >
                                            <span className="token__card--item--top--title--inner">
                                                <img
                                                    src="/assets/img/about.svg"
                                                    alt="about"
                                                    className="token__card--item--top--title--icon"
                                                />

                                                <p className="token__card--item--top--title">
                                                    О компании {token.collection.name}
                                                </p>
                                            </span>

                                            <img
                                                src="/assets/img/arrow-select.svg"
                                                alt="arrow"
                                                className={`token__card--item--open${
                                                    about ? ' active' : ''
                                                }`}
                                            />
                                        </div>

                                        {about && (
                                            <div className="token__card--item--bottom">
                                                <div className="token__card--item--about">
                                                    <div className="token__card--item--about--item">
                                                        <div
                                                            style={{
                                                                backgroundImage: collection ? `url('${collection.logo}')` : 'none',
                                                                backgroundSize: 'cover',
                                                                objectFit: 'cover',
                                                                backgroundPosition: 'center',
                                                            }}
                                                            className="token__card--item--about--img"
                                                        />
                                                        {collection &&
                                                          <Linkify
                                                            componentDecorator={(
                                                              decoratedHref,
                                                              decoratedText,
                                                            ) => (
                                                              <a
                                                                key={decoratedText}
                                                                href={decoratedHref}
                                                                className="desc_link"
                                                              >
                                                                  {decoratedText}
                                                              </a>
                                                            )}
                                                          >
                                                              <p className="token__card--item--about--text">
                                                                  {collection.description}
                                                              </p>
                                                          </Linkify>
                                                        }


                                                    </div>

                                                    {collection &&
                                                      <div className="token__card--item--social">
                                                          {collection.link_yoursite && (
                                                            <a
                                                              href={collection.link_yoursite}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/Internet.svg"
                                                                  alt="twitter"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection.link_telegram && (
                                                            <a
                                                              href={collection.link_telegram}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/tg.svg"
                                                                  alt="opensea"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection.link_vk && (
                                                            <a
                                                              href={collection.link_vk}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/vk.svg"
                                                                  alt="insta"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}

                                                          {collection.link_instagram && (
                                                            <a
                                                              href={collection.link_instagram}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/insta.svg"
                                                                  alt="discord"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          )}
                                                          {collection.link_youtube &&
                                                            <a
                                                              href={collection.link_youtube}
                                                              className="token__card--item--social--link"
                                                              target={'_blank'}
                                                            >
                                                                <img
                                                                  src="/assets/img/youtube.svg"
                                                                  alt="globus"
                                                                  className="token__card--item--social--icon"
                                                                />
                                                            </a>
                                                          }
                                                      </div>
                                                    }


                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/*<div className="token__card--item">*/}
                                    {/*    <div*/}
                                    {/*        onClick={() => setDetails(prev => !prev)}*/}
                                    {/*        className="token__card--item--top"*/}
                                    {/*    >*/}
                                    {/*        <span className="token__card--item--top--title--inner">*/}
                                    {/*            <img*/}
                                    {/*                src="/assets/img/details.svg"*/}
                                    {/*                alt="details"*/}
                                    {/*                className="token__card--item--top--title--icon"*/}
                                    {/*            />*/}

                                    {/*            <p className="token__card--item--top--title">*/}
                                    {/*                Details*/}
                                    {/*            </p>*/}
                                    {/*        </span>*/}

                                    {/*        <img*/}
                                    {/*            src="/assets/img/arrow-select.svg"*/}
                                    {/*            alt="arrow"*/}
                                    {/*            className={`token__card--item--open${*/}
                                    {/*                details ? ' active' : ''*/}
                                    {/*            }`}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}

                                    {/*    {details && (*/}
                                    {/*        <div className="token__card--item--bottom">*/}
                                    {/*            <div className="token__card--item--details">*/}
                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Contract Address*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text blueC bold">*/}
                                    {/*                        0xa5f1...f215*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Token ID*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text ellipsis blueC bold">*/}
                                    {/*                        {token.id.slice(0, 5)}...*/}
                                    {/*                        {token.id.slice(-5)}*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Token Standard*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        ERC-721*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Blockchain*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        Ethereum*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Creator Fee*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        {roundInt({*/}
                                    {/*                            num: Number(token.creator_royalty),*/}
                                    {/*                        })}*/}
                                    {/*                        %*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="token__card--item--details--item">*/}
                                    {/*                    <p className="token__card--item--details--item--text">*/}
                                    {/*                        Investor Fee*/}
                                    {/*                    </p>*/}

                                    {/*                    <p className="token__card--item--details--item--text bold">*/}
                                    {/*                        {roundInt({*/}
                                    {/*                            num: Number(token.investor_royalty),*/}
                                    {/*                        })}*/}
                                    {/*                        %*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        {/*<div className="token__card--wrap full">*/}
                        {/*    <div className="token__card--item">*/}
                        {/*        <div*/}
                        {/*            onClick={() => setAct(prev => !prev)}*/}
                        {/*            className="token__card--item--top"*/}
                        {/*        >*/}
                        {/*            <span className="token__card--item--top--title--inner">*/}
                        {/*                <img*/}
                        {/*                    src="/assets/img/activity.svg"*/}
                        {/*                    alt="activity"*/}
                        {/*                    className="token__card--item--top--title--icon"*/}
                        {/*                />*/}

                        {/*                <p className="token__card--item--top--title">*/}
                        {/*                    Item Activity*/}
                        {/*                </p>*/}
                        {/*            </span>*/}

                        {/*            <img*/}
                        {/*                src="/assets/img/arrow-select.svg"*/}
                        {/*                alt="arrow"*/}
                        {/*                className={`token__card--item--open${act ? ' active' : ''}`}*/}
                        {/*            />*/}
                        {/*        </div>*/}

                        {/*        {act && (*/}
                        {/*            <div className="token__card--item--bottom nop">*/}
                        {/*                <div className="token__card--filter--inner">*/}
                        {/*                    <CustomSelect*/}
                        {/*                        optionsList={[*/}
                        {/*                            { value: 'book', name: 'Bookings' },*/}
                        {/*                            { value: 'sales', name: 'Sales' },*/}
                        {/*                            { value: 'listings', name: 'Listings' },*/}
                        {/*                            { value: 'transfers', name: 'Transfers' },*/}
                        {/*                            { value: 'offers', name: 'Offers' },*/}
                        {/*                        ]}*/}
                        {/*                        value={filter}*/}
                        {/*                        placeholder="Filter"*/}
                        {/*                        onChange={filterChenge}*/}
                        {/*                    />*/}
                        {/*                </div>*/}

                        {/*                <div className="listing__table">*/}
                        {/*                    <div className="token__card--content--listing--item titles">*/}
                        {/*                        <p className="token__card--content--listing--item--title">*/}
                        {/*                            Event*/}
                        {/*                        </p>*/}

                        {/*                        <p className="token__card--content--listing--item--title">*/}
                        {/*                            Price*/}
                        {/*                        </p>*/}

                        {/*                        <p className="token__card--content--listing--item--title">*/}
                        {/*                            From*/}
                        {/*                        </p>*/}

                        {/*                        <p className="token__card--content--listing--item--title">*/}
                        {/*                            To*/}
                        {/*                        </p>*/}

                        {/*                        <p className="token__card--content--listing--item--title">*/}
                        {/*                            Date*/}
                        {/*                        </p>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Minted*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Booked*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Booked Cancel*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Booked expired*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Transfer*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}

                        {/*                    <div className="token__card--content--listing--item">*/}
                        {/*                        <div className="listing__item listing__event">*/}
                        {/*                            <div className="listing__event--circle"></div>*/}

                        {/*                            <p className="listing__event--title">*/}
                        {/*                                Sold*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__price">*/}
                        {/*                                10,000 RUB*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                ---*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item">*/}
                        {/*                            <p className="listing__link">*/}
                        {/*                                user name*/}
                        {/*                            </p>*/}
                        {/*                        </div>*/}

                        {/*                        <div className="listing__item listing__date">*/}
                        {/*                            <p className="listing__date--text">*/}
                        {/*                                5 days ago*/}
                        {/*                            </p>*/}

                        {/*                            <img src="/assets/img/share-blue.svg" alt="share" className="listing__date--icon" />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}

                        {/*                /!* <div className="token__card--item--bottom--table empty">*/}
                        {/*                    <img*/}
                        {/*                        src="/assets/img/no-activity.svg"*/}
                        {/*                        alt="empty"*/}
                        {/*                        className="token__card--empty--img"*/}
                        {/*                    />*/}

                        {/*                    <p className="token__card--empty--subtext">*/}
                        {/*                        No item activity yet*/}
                        {/*                    </p>*/}
                        {/*                </div> *!/*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <CollectionCertificates token={token} whiteListApplication={whiteListApplication} />
                        <Footer className={'footer_card'}/>
                    </div>

                </div>

            </div>
            {collectionPage && collection && (
                <WLCreationDialog
                    open={createWLDialog.isOpen}
                    dialogType={
                        DIALOG_TYPES_ARR.includes(collectionPage.application_form)
                            ? collectionPage.application_form
                            : DIALOG_TYPES.PERSONS
                    }
                    // whiteListApplicationData={
                    //     whitelistApplicationError ? null : whiteListApplicationData
                    // }
                    collection={collection}
                    collectionId={collection.id}
                    onClose={closeCreationDialogHandler}
                    // onCreateSuccessfully={refetchWhiteListApplication}
                />
            )}

            <Modal
                active={qrModal}
                setActive={setQrModal}
                title="Scan QR for verification"
                size="small"
            >
                <div className="modal__qr">
                    <QRCode value={window.location.href} bgColor="#1B1B1B" fgColor="#fff" />
                </div>

                <p className="modal__subtitle--blue modal__qr--blue">Check domain name</p>

                <p className="modal__qr--title">Check profile picture</p>

                <FullDescription
                    description="To verify NFT ownership, scan the QR and check the website domain name
                        (checkbrand.com) in the window that opens. Compare the avatar with the
                        user's face. A match indicates successful verification. An additional option
                        for verification: put a predefined image (e.g. the logo of the verifier's
                        company) in the avatar."
                />
            </Modal>
            <PayModal isPay={isPay} setIsPay={setIsPay} token={token} collection={collection}/>
        </>
    );
};

export default TokenCard;
