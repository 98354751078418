import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { normilizeError } from '../../utils/http/normilizeError';
import {
    useCreateAccountMutation,
    useGetAccountQuery,
    useUpdateAccountMutation,
} from '../../redux/api/dataService';
import Input from '../../common/Input';
import File from '../../common/File';
import CenteredContainer from '../../common/CenteredContainer';
import Loader from '../../common/Loader';
import { pagesSelectors } from '../../redux/slices/pages';
import { CustomSelect } from '../../common/CustomSelect';

import './index.css';
import { useCheckValidationErrors } from '../../hooks/useCheckValidationErrors';
import { isValidURL } from '../CreatePage';
import { useGetPagesQuery } from '../../redux/api/handleService';

const pageList = [
    {
        value: '1',
        name: 'Cars',
    },
    {
        value: '2',
        name: 'Brands',
    },
    {
        value: '3',
        name: 'Alcohol',
    },
    {
        value: '4',
        name: 'Persons',
    },
    {
        value: '5',
        name: 'Watches',
    },
];

const adminList = [
    {
        value: '1',
        name: 'Admin1',
    },
    {
        value: '2',
        name: 'Admin2',
    },
    {
        value: '3',
        name: 'Admin3',
    },
    {
        value: '4',
        name: 'Admin4',
    },
    {
        value: '5',
        name: 'Admin5',
    },
];

const SocialLinks = {
    YOURSITE: {
        imgLink: '/assets/img/Internet.svg',
        name: 'yourSite',
        placeholder: 'https://yoursite.com',
    },
    TG: {
        imgLink: '/assets/img/tg.svg',
        name: 'telegram',
        placeholder: 'https://t.me/profile',
    },
    INSTAGRAM: {
        imgLink: '/assets/img/inst.svg',
        name: 'instagram',
        placeholder: 'https://instagram.com/@account_name',
    },
    VK: {
        imgLink: '/assets/img/vk.svg',
        name: 'vk',
        placeholder: 'https://vk.com/profile',
    },
    YOUTUBE: {
        imgLink: '/assets/img/youtube.svg',
        name: 'youtube',
        placeholder: 'https://youtube.com/abcdef',
    },
};

const SOCIAL_LINKS_ARR = Object.values(SocialLinks);

const CreateAccount = (props) => {
    const { isEdit } = props;

    const { id } = useParams();

    const navigate = useNavigate()

    const allPages = useSelector(pagesSelectors.selectAll);
    const {errors, handlerErrors, handelClearErrors} = useCheckValidationErrors()

    const pages = useMemo(
        () =>
            allPages.map((page) => ({
                value: page.id,
                name: page.name,
            })),
        [allPages],
    );

    const [
        onCreateAccountRequest,
        { isLoading, error, isSuccess, reset },
    ] = useCreateAccountMutation();

    const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(
        { id },
        {
            skip: !id || !isEdit,
        },
    );

    const [
        onUpdateAccountRequest,
        {
            isLoading: isAccountUpdatingProccessing,
            error: updateAccountError,
            isSuccess: isAccountUpdatingSuccessfully,
            reset: resetAccountUpdate,
        },
    ] = useUpdateAccountMutation();

    const { isLoading: isPagesLoading } = useGetPagesQuery();
    
    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const [logo, setLogo] = useState('');
    const [accountName, setAccountName] = useState('');
    const [cover, setCover] = useState('');
    const [banner, setBanner] = useState('');
    const [admin, setAdmin] = useState('1');
    const [page, setPage] = useState('');
    const [url, setUrl] = useState('');
    const [descriprion, setDescriprion] = useState('');
    const [social, setSocial] = useState({
        yourSite: '',
        telegram: '',
        instagram: '',
        vk: '',
        youtube: '',
    });

    const [isSendData, setIsSendData] = useState(false)

    const setPageHandler = (value) => {
        setPage(value);
    };

    const setAdminHandler = (value) => {
        setAdmin(value);
    };

    const handleClearFields = () => {
        setLogo('')
        setAccountName('')
        setCover('')
        setBanner('')
        setAdmin('1')
        setPage('1')
        setUrl('')
        setDescriprion('')
        setSocial({
            yourSite: '',
            telegram: '',
            instagram: '',
            vk: '',
            youtube: '',
        })
    }

    const createAccountFunc = useCallback(() => {
        setIsSendData(true)
        let formData = new FormData();

        if (
            (!logo && !isEdit) ||
            (!cover && !isEdit) ||
            (!banner && !isEdit) ||
            !page ||
            !url ||
            !descriprion ||
            !accountName
        ) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Fill all required fields',
            });

            return;
        }

        formData.append('link_yoursite', social.yourSite);
        formData.append('link_telegram', social.telegram);
        formData.append('link_instagram', social.instagram);
        formData.append('link_vk', social.vk);
        formData.append('link_youtube', social.youtube);

        if (logo && !isValidURL(logo)) {
            formData.append('logo', logo);
        }

        if (cover && !isValidURL(cover)) {
            formData.append('cover', cover);
        }

        if (banner && !isValidURL(banner)) {
            formData.append('banner', banner);
        }

        formData.append('page', page);
        formData.append('name', accountName);
        formData.append('url', url);
        formData.append('description', descriprion);

        if (isEdit) {
            onUpdateAccountRequest({ id, data: formData })
                .unwrap()
                .then((res) => {
                    localStorage.setItem('isDelay', 'true')
                    handleClearFields()
                    navigate(`/admin/statistics2/brands/${res.page}`)
                })
                .catch((err) => {
                    handlerErrors(err.data)
                })
                .finally(() => {
                    setIsSendData(false)
                });
        } else {
            onCreateAccountRequest(formData)
                .unwrap()
                .then(() => {
                    handleClearFields()
                })
                .catch((err) => {
                    handlerErrors(err.data)
                })
                .finally(() => {
                    setIsSendData(false)
                });
        }
    }, [isEdit, id, page, logo, accountName, cover, banner, url, descriprion, social]);

    const onChangeSocialLinksHandler = useCallback((key, value) => {
        setSocial((p) => ({
            ...p,
            [key]: value,
        }));
    }, []);

    useEffect(() => {
        if (error) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(error),
            });
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            setPage('');
            setLogo('');
            setAccountName('');
            setCover('');
            setBanner('');
            setUrl('');
            setDescriprion('');
            setSocial('');
            setSocial({
                opensea: '',
                discord: '',
                instagram: '',
                twitter: '',
            });

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Accaunt successfuly created',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (updateAccountError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(updateAccountError),
            });
        }
    }, [updateAccountError]);

    useEffect(() => {
        if (isAccountUpdatingSuccessfully) {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Account successfuly updated',
            });
        }
    }, [isAccountUpdatingSuccessfully]);

    useEffect(() => {
        if (account && isEdit) {
            setPage(account.page);
            setAccountName(account.name);
            setUrl(account.url);
            setDescriprion(account.description);
            setSocial({
                yourSite: account.link_yoursite,
                telegram: account.link_telegram,
                instagram: account.link_instagram,
                vk: account.link_vk,
                youtube: account.link_youtube,
            });
            account.logo && setLogo(account.logo)
            account.banner && setBanner(account.banner)
            account.cover && setCover(account.cover);
        }
    }, [account, isEdit]);

    useEffect(
        () => () => {
            reset();
            resetAccountUpdate();
        },
        [],
    );

    if (isPagesLoading || isAccountLoading || (!account && isEdit)) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">
                            {isEdit ? 'Update Account' : 'Create a Brand'}
                        </h2>

                        <div className="create__content">
                            <File
                                title="Logo image"
                                text="350 x 350 recommended"
                                type="logo"
                                id="createaccountLogo"
                                defaultValue={account && account.logo}
                                value={logo}
                                setValue={(value) => {
                                    setLogo(value)
                                    handelClearErrors('logo')
                                }}
                                isError={(!isEdit && isSendData && !logo) || errors.includes('logo')}
                            />

                            <File
                                title="Cover"
                                text="500 x 500 recommended"
                                required
                                type="cover"
                                id="createaccountCover"
                                defaultValue={account && account.cover}
                                value={cover}
                                setValue={(value) => {
                                    setCover(value)
                                    handelClearErrors('cover')
                                }}
                                isError={(!isEdit && isSendData && !cover) || errors.includes('cover')}
                            />

                            <File
                                title="Banner image"
                                text="1400 x 350 recommended"
                                id="createaccountBanner"
                                defaultValue={account && account.banner}
                                value={banner}
                                setValue={(value) => {
                                    setBanner(value)
                                    handelClearErrors('banner')
                                }}
                                isError={(!isEdit && isSendData && !banner) || errors.includes('banner')}
                            />

                            {/*<div className="admin__add--item full">*/}
                            {/*    <div className="admin__add--title--inner">*/}
                            {/*        <p className="admin__add--title">Brand administrator</p>*/}
                            {/*    </div>*/}

                            {/*    <div className="payment__box">*/}
                            {/*        <div className="payment__content brand__admin--content">*/}
                            {/*            <div className={`payment__content--item`}>*/}
                            {/*                <input type="checkbox" className="checkbox" id="admin1" name="brandAdmin" />*/}

                            {/*                <label htmlFor="admin1" className="payment__content--label">*/}
                            {/*                    Brand administrator name 1*/}
                            {/*                </label>*/}
                            {/*            </div>*/}

                            {/*            <div className="payment__content--item">*/}
                            {/*                <input type="checkbox" className="checkbox" id="admin2" name="brandAdmin" />*/}

                            {/*                <label htmlFor="admin2" className="payment__content--label">*/}
                            {/*                    Brand administrator name 2*/}
                            {/*                </label>*/}
                            {/*            </div>*/}

                            {/*            <div className="payment__content--item">*/}
                            {/*                <input type="checkbox" className="checkbox" id="admin3" name="brandAdmin" />*/}

                            {/*                <label htmlFor="admin3" className="payment__content--label">*/}
                            {/*                    Brand administrator name 3*/}
                            {/*                </label>*/}
                            {/*            </div>*/}

                            {/*            <div className="payment__content--item">*/}
                            {/*                <input type="checkbox" className="checkbox" id="admin4" name="brandAdmin" />*/}

                            {/*                <label htmlFor="admin4" className="payment__content--label">*/}
                            {/*                    Brand administrator name 4*/}
                            {/*                </label>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Select page</p>
                                </div>

                                <CustomSelect
                                    optionsList={pages}
                                    value={page}
                                    placeholder="Select page"
                                    onChange={(value) => {
                                        setPageHandler(value)
                                        handelClearErrors('page')
                                    }}
                                    isError={(isSendData && !page) || errors.includes('page')}
                                />
                            </div>

                            <Input
                                title="Account Name"
                                text="50"
                                placeholder="Enter brand name"
                                required
                                value={accountName}
                                setValue={(value) => {
                                    setAccountName(value)
                                    handelClearErrors('name')
                                }}
                                isError={(isSendData && !accountName) || errors.includes('name')}
                            />

                            <Input
                                title="URL"
                                text="50"
                                placeholder="account-name"
                                value={url}
                                setValue={(value) => {
                                    setUrl(value)
                                    handelClearErrors('url')
                                }}
                                isError={(isSendData && !url) || errors.includes('url')}
                            />

                            <Input
                                title="Description"
                                text="1000"
                                placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."
                                textarea
                                value={descriprion}
                                setValue={(value) => {
                                    setDescriprion(value)
                                    handelClearErrors('description')
                                }}
                                isError={(isSendData && !descriprion) || errors.includes('description')}
                            />

                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Links</p>
                                </div>

                                <div className="create__item--links">
                                    {SOCIAL_LINKS_ARR.map(({ imgLink, name, placeholder }) => (
                                        <div className="create__item--link" key={name}>
                                            <img
                                                src={imgLink}
                                                alt={name}
                                                className="create__item--link--icon"
                                            />

                                            <Input
                                                className="linkContainer"
                                                placeholder={placeholder}
                                                isLink
                                                value={social[name]}
                                                setValue={(value) =>
                                                    onChangeSocialLinksHandler(name, value)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="create__button--content">
                            {isLoading || isAccountUpdatingProccessing ? (
                                <button className="button create__button disabled">
                                    Loading...
                                </button>
                            ) : (
                                <button
                                    className="button create__button default__hover"
                                    onClick={createAccountFunc}
                                >
                                    {isEdit ? 'Save changes' : 'Create account '}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreateAccount);
