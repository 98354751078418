import React from 'react';

const Watch2 = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="19"
            viewBox="0 0 15 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.90737 16.9213C5.91839 16.9213 4.95695 16.7248 4.05061 16.3386C3.21518 15.9802 2.45906 15.4776 1.80469 14.8359L3.10163 18.9374C3.11665 18.9745 3.15504 18.9982 3.1976 18.9982H3.88531V17.7029C3.88531 17.5824 3.98129 17.4846 4.10146 17.4846H9.70324C9.82259 17.4846 9.91939 17.5815 9.91939 17.7029V19.0007H10.6096C10.6522 19.0007 10.6906 18.9745 10.7056 18.9383L12.0025 14.8368C11.349 15.4759 10.5946 15.9811 9.75659 16.3394C8.85107 16.7256 7.89129 16.9221 6.89984 16.9221L6.90737 16.9213Z"
                fill="currentColor"
            />
            <path
                d="M11.7845 4.57359C11.1502 3.93271 10.4124 3.42931 9.59025 3.07936C8.73981 2.71424 7.83597 2.53125 6.90292 2.53125C5.96902 2.53125 5.06769 2.71508 4.21559 3.07936C3.39269 3.43099 2.65575 3.9344 2.02137 4.57359C1.38708 5.21446 0.88884 5.95987 0.542487 6.79056C0.181114 7.64983 0 8.56304 0 9.50576C0 10.4493 0.181939 11.36 0.542487 12.221C0.77116 12.7674 1.06577 13.2784 1.42047 13.7447C1.43967 13.7708 1.46137 13.7961 1.48056 13.8223C1.64748 14.0356 1.82858 14.2414 2.02136 14.4353C2.65566 15.0762 3.39342 15.5796 4.21558 15.9295C5.06603 16.2946 5.96987 16.4776 6.90291 16.4776C7.83681 16.4776 8.73815 16.2938 9.59024 15.9295C10.4132 15.5779 11.1501 15.0745 11.7845 14.4353C11.9764 14.2413 12.1584 14.0356 12.3253 13.8223C12.3445 13.7961 12.3662 13.7708 12.3854 13.7447C12.7401 13.2758 13.0347 12.7665 13.2633 12.221C13.6247 11.3617 13.8058 10.4485 13.8058 9.50576C13.8058 8.56217 13.6239 7.65149 13.2633 6.79056C12.9153 5.95912 12.4171 5.21454 11.7845 4.57359ZM6.90463 15.1486C5.41323 15.1486 4.00942 14.5617 2.95461 13.495C1.89886 12.4283 1.31798 11.0099 1.31798 9.50399C1.31798 7.99712 1.89886 6.57875 2.95461 5.513C4.01036 4.44631 5.41419 3.85941 6.90463 3.85941C8.39604 3.85941 9.79985 4.44631 10.8547 5.513C11.9104 6.5797 12.4913 7.99809 12.4913 9.50399C12.4913 11.0109 11.9104 12.4292 10.8547 13.495C9.79891 14.5617 8.39508 15.1486 6.90463 15.1486Z"
                fill="currentColor"
            />
            <path
                d="M6.90744 2.09554C7.89642 2.09554 8.85786 2.29201 9.7642 2.67821C10.6038 3.0366 11.3599 3.54421 12.0159 4.18677L10.7123 0.0632388C10.6972 0.0261353 10.6589 0.0025257 10.6163 0.0025257H9.92858V1.29774C9.92858 1.41832 9.8326 1.51613 9.71243 1.51613H4.11065C3.99131 1.51613 3.8945 1.41916 3.8945 1.29774V0H3.20429C3.16173 0 3.12334 0.0261396 3.10831 0.0623991L1.80469 4.18593C2.46067 3.54253 3.21681 3.03576 4.05638 2.67737C4.96191 2.29116 5.92168 2.0947 6.91314 2.0947L6.90744 2.09554Z"
                fill="currentColor"
            />
            <path
                d="M14.8068 8.79755L14.1875 8.62891C14.2217 8.91813 14.2384 9.21158 14.2384 9.50756C14.2384 9.80354 14.2217 10.097 14.1875 10.3862L14.8068 10.2176C14.9069 10.1914 14.982 10.0582 14.982 9.90893V9.10363C14.982 8.95438 14.9069 8.82115 14.8068 8.79501V8.79755Z"
                fill="currentColor"
            />
            <path
                d="M10.5335 6.52205C10.4391 6.40568 10.2689 6.38797 10.1529 6.48326L7.19188 8.9202C7.10425 8.8772 7.00577 8.85359 6.90563 8.85359C6.79212 8.85359 6.68112 8.88394 6.58515 8.93791L4.82001 7.64691C4.69816 7.55837 4.52957 7.58619 4.44195 7.70762C4.35432 7.83073 4.38186 8.00107 4.50204 8.0896L6.26718 9.3806C6.25883 9.42192 6.25466 9.46493 6.25466 9.51046C6.25466 9.68501 6.32309 9.85113 6.44494 9.97424C6.56679 10.0973 6.73119 10.1665 6.90395 10.1665C7.07671 10.1665 7.24112 10.0973 7.36297 9.97424C7.48482 9.85112 7.55325 9.68501 7.55325 9.51046C7.55325 9.45396 7.54658 9.39831 7.53155 9.34603L10.4926 6.90909C10.6077 6.8138 10.6253 6.64178 10.531 6.52458L10.5335 6.52205Z"
                fill="currentColor"
            />
            <path
                d="M6.90365 5.8497C7.023 5.8497 7.11981 5.75273 7.11981 5.6313V4.7223C7.11981 4.60172 7.02383 4.50391 6.90365 4.50391C6.78431 4.50391 6.6875 4.60088 6.6875 4.7223V5.6313C6.6875 5.75189 6.78348 5.8497 6.90365 5.8497Z"
                fill="currentColor"
            />
            <path
                d="M6.90365 13.168C6.78431 13.168 6.6875 13.2649 6.6875 13.3864V14.2954C6.6875 14.4159 6.78348 14.5138 6.90365 14.5138C7.023 14.5138 7.11981 14.4168 7.11981 14.2954V13.3864C7.11981 13.2658 7.02383 13.168 6.90365 13.168Z"
                fill="currentColor"
            />
            <path
                d="M11.6393 9.28906H10.7396C10.6202 9.28906 10.5234 9.38604 10.5234 9.50746C10.5234 9.62804 10.6194 9.72585 10.7396 9.72585H11.6393C11.7586 9.72585 11.8554 9.62888 11.8554 9.50746C11.8554 9.38687 11.7594 9.28906 11.6393 9.28906Z"
                fill="currentColor"
            />
            <path
                d="M3.07286 9.28906H2.17318C2.05384 9.28906 1.95703 9.38604 1.95703 9.50746C1.95703 9.62804 2.05301 9.72585 2.17318 9.72585H3.07286C3.1922 9.72585 3.28901 9.62888 3.28901 9.50746C3.28901 9.38687 3.19303 9.28906 3.07286 9.28906Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Watch2;
