exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageWithModal_container__2hvis {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.ImageWithModal_container__2hvis .ImageWithModal_skeleton__3qFPL {\n    width: 100%;\n    position: absolute;\n    min-height: 580px;\n    z-index: 11;\n}\n\n.ImageWithModal_modalImage__2jhK8 {\n    max-width: 100%;\n    max-height: 90vh;\n    .ImageWithModal_thumbnail_img__3gV4W img:hover {\n        transform: scale(1.5);\n    }\n}\n\n.ImageWithModal_modalImageClose__3N92J{\n    position: absolute;\n    top: 15px;\n    right: 15px;\n    cursor: pointer;\n    z-index: 1000;\n    width: 24px;\n}\n\n.ImageWithModal_modalPaper__27d1z {\n    margin: 0 !important;\n    padding: 0 !important;\n    max-width: 100vw !important;\n    max-height: unset!important;\n    overflow-y: hidden!important;\n}\n\n.ImageWithModal_modalRoot__q5Zn8 {\n    margin: 0 !important;\n    padding: 0 !important;\n}\n\n.ImageWithModal_modalDialogInner__1y-_w {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"container": "ImageWithModal_container__2hvis",
	"skeleton": "ImageWithModal_skeleton__3qFPL",
	"modalImage": "ImageWithModal_modalImage__2jhK8",
	"thumbnail_img": "ImageWithModal_thumbnail_img__3gV4W",
	"modalImageClose": "ImageWithModal_modalImageClose__3N92J",
	"modalPaper": "ImageWithModal_modalPaper__27d1z",
	"modalRoot": "ImageWithModal_modalRoot__q5Zn8",
	"modalDialogInner": "ImageWithModal_modalDialogInner__1y-_w"
};