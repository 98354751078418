import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Brands from './Brands';
import Collections from './Collections';
import { Conclusions } from './index';
import Pages from './Pages';
import { TableContainer } from './Container/Container';
export const ConclusionLayout = () => {
    return (
        <section className="statistics">
            <div className="container">
                {/* <Routes>
                    <Route index element={<Pages />} />
                    <Route path="/brands/:pageId" element={<Brands />} />
                    <Route path="/collections/:pageId/:accountId" element={<Collections />} /> 
                    <Route
                        path="/tokens/:pageId/:collectionId/:accountId"
                        element={<Conclusions />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes> */}
                <Routes>
                    <Route path="/" element={<TableContainer />}>
                        <Route index element={<Pages />} />
                        <Route path="/brands/:pageId" element={<Brands />} />
                        <Route path="/collections/:pageId/:accountId" element={<Collections />} />
                        <Route
                            path="/tokens/:pageId/:collectionId/:accountId"
                            element={<Conclusions />}
                        />
                        <Route path="/brands/" element={<Brands />} />
                        <Route path="/collections/" element={<Collections />} />
                    </Route>
                </Routes>
            </div>
        </section>
    );
};
