import React from 'react';

import styles from '../index.module.css';

import Info from '../../../icons/Info';
import Vk from '../../../icons/Vk';
import Twitter from '../../../icons/Twitter';
import Youtube from '../../../icons/Youtube';
import Instagram from '../../../icons/Instagram';
import Telegram2 from '../../../icons/Telegram2';
import Share from '../../../icons/Share';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { S3Context } from '../../../context/S3Context';

const BrandItem = ({ brand }) => {
    const {
        id,
        cover,
        name,
        collections_count: collectionsCount,
        items_count: itemsCount,
        floor_price_count: floorPriceCount,
        page,
        description,
        link_instagram: linkInstagram,
        link_telegram: linkTelegram,
        link_vk: linkVk,
        link_yoursite: linkYoursite,
        link_youtube: linkYoutube } = brand;

    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);

    return (
        <div className={ styles.brandItemWrapper }>
            <div className={ styles.brandItem }>
                <img src="/assets/img/element-fire.png" alt="decor" className={ styles.brandItemDecor } />

                <Link to={ `/brandnew/${id}` } className={ styles.brandItemImgInner } >
                    <img src={ getImageBacketPath(cover) } alt="image" />
                </Link>

                <div className={ styles.brandItemInfo }>
                    <p className={ styles.brandItemInfoName }>{ name }</p>

                    <div className={ styles.brandItemInfoTags }>
                        <p className={ styles.brandItemInfoTag }>{ page.name }</p>
                    </div>

                    <div className={ styles.brandItemInfoWrapper }>
                        <div className={ styles.brandItemInfoPoints }>
                            <div className={ styles.brandItemInfoPoint }>
                                <p className={ styles.brandItemInfoPointName }>Коллекц.</p>

                                <p className={ styles.brandItemInfoPointValue }>{ collectionsCount } шт.</p>
                            </div>

                            <div className={ styles.brandItemInfoPoint }>
                                <p className={ styles.brandItemInfoPointName }>Сертифик.</p>

                                <p className={ styles.brandItemInfoPointValue }>{ itemsCount } шт.</p>
                            </div>
                        </div>

                        <div className={ styles.brandItemInfoButtonWrapper }>
                            <button className={ styles.brandItemInfoButton }>
                                <Info />
                            </button>

                            <div className={ styles.brandItemInfoPopupWrapper }>
                                <div className={ styles.brandItemInfoPopup }>
                                    <div className={ styles.brandItemInfoPopupContent }>
                                        <p className={ styles.brandItemInfoPopupName }>{ name }</p>

                                        <p className={ styles.brandItemInfoPopupText }>
                                            { description }
                                        </p>

                                        <div className={ styles.brandItemPopupSocialLinks }>
                                            {
                                                linkVk &&
                                                <a href={ linkVk } className={ styles.brandItemPopupSocialLink }>
                                                    <Vk />
                                                </a>
                                            }
                                            {
                                                linkInstagram &&
                                                <a href={ linkInstagram } className={ styles.brandItemPopupSocialLink }>
                                                    <Instagram />
                                                </a>
                                            }
                                            {
                                                linkYoutube &&
                                                <a href={ linkYoutube } className={ styles.brandItemPopupSocialLink }>
                                                    <Youtube />
                                                </a>

                                            }
                                            {
                                                linkTelegram &&
                                                <a href={ linkTelegram } className={ styles.brandItemPopupSocialLink }>
                                                    <Telegram2 />
                                                </a>
                                            }
                                        </div>

                                        {
                                            linkYoursite &&
                                            <a href={ linkYoursite } target='_blank' className={ styles.brandItemSite }>
                                                <Share />
                                                { linkYoursite }
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandItem;
