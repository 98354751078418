exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tooltip_tooltipInner__3VBD4 {\n    position: relative;\n    display: flex;\n}\n\n.Tooltip_tooltipContentWrapper__2PXlt {\n    width: 200px;\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    pointer-events: none;\n    transition: 0.2s linear;\n    z-index: 3;\n    padding-top: 1.3rem;\n}\n\n.Tooltip_tooltipInner__3VBD4:hover .Tooltip_tooltipContentWrapper__2PXlt {\n    opacity: 1;\n    pointer-events: all;\n}\n\n@media (max-width: 768px) {\n    .Tooltip_tooltipContentWrapper__2PXlt {\n        left: auto;\n        right: 0;\n        transform: translateX(0);\n    }\n}\n\n.Tooltip_tooltipContent__2uPvk {\n    width: 100%;\n    padding: 1.3rem 1.5rem;\n    border-radius: 5px;\n    background: #181e30;\n    border: 1px solid #505a6d;\n    font-size: 1.2rem;\n    font-weight: 300;\n    color: #e5e8eb;\n    position: relative;\n}\n\n.Tooltip_tooltipContent__2uPvk:before,\n.Tooltip_tooltipContent__2uPvk:after {\n    content: '';\n    display: block;\n    border-style: solid;\n    border-width: 0 8px 9px 8px;\n    border-color: transparent transparent #181e30 transparent;\n    position: absolute;\n    bottom: calc(100% - 1px);\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.Tooltip_tooltipContent__2uPvk:before {\n    border-color: transparent transparent #505a6d transparent;\n    bottom: 100%;\n    border-width: 0 9px 9px 9px;\n}\n\n.Tooltip_tooltipInner__3VBD4:hover > svg path {\n    stroke: #2069e2;\n}", ""]);

// exports
exports.locals = {
	"tooltipInner": "Tooltip_tooltipInner__3VBD4",
	"tooltipContentWrapper": "Tooltip_tooltipContentWrapper__2PXlt",
	"tooltipContent": "Tooltip_tooltipContent__2uPvk"
};