exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_text__2teZc {\n    color: hsla(210, 13%, 91%, 1);\n    font-size: 14px;\n}\n\n.styles_btn__4NK8T {\n    color: white;\n    margin-top: 4px;\n    display: flex;\n}\n\n.styles_btn__4NK8T img {\n    margin-top: 8px;\n    margin-left: 7px;\n}", ""]);

// exports
exports.locals = {
	"text": "styles_text__2teZc",
	"btn": "styles_btn__4NK8T"
};