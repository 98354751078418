import React from 'react';

const User2 = ({ ...props }) => {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.3088 14.4121C13.3088 15.4732 12.4584 16.3334 11.4082 16.3334H1.90154C0.851349 16.3334 0 15.4732 0 14.4121C0 11.2289 2.55395 8.64844 5.70451 8.64844H7.60605C10.7556 8.64939 13.3088 11.2299 13.3088 14.4121Z"
                fill="currentColor"
            />
            <path
                d="M6.65473 7.68424C4.55433 7.68424 2.85156 5.96393 2.85156 3.84163C2.85156 1.72041 4.55421 0 6.65473 0C8.75525 0 10.4579 1.72031 10.4579 3.84261C10.4579 5.96383 8.75525 7.68424 6.65473 7.68424Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default User2;
