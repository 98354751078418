import { InputLabel } from '@mui/material';
import React, { useCallback, useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
export const ChangeLinkQr = ({ element, setHandleClose }) => {
    
    const [linksData, setLinksData] = useState({
        qrLink: '',
        tokenLink: '',
    });

    useEffect(() => {
        console.log(element)
        if (element && element.short_url && element.short_url.url_hash) {
            
            setLinksData((prev) => ({
                ...prev,
                qrLink: element.short_url.url_hash,
            }));
        }
    }, [ element]);

    const handleChangeLinkValue = (value, type) => {
        setLinksData((prev) => ({
            ...prev,
            [type]: value,
        }));
    };
    const accessToken = localStorage.getItem('access_token');

    const handleChangeLinks = useCallback(() => {
        const requestBody = {};

        if (linksData.qrLink.length !== 0) {
            requestBody.url_hash = linksData.qrLink;
        }
        const checkIsDataExist = Object.keys(requestBody).length > 0;
        if (checkIsDataExist) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/${element.short_url.id}/`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                },
            )
                .then((res) => {
                  if (Number(res.status) <=210) {
                    setHandleClose()
                  }
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [element, linksData]);

    return element ? (
        <div className="tokenTransferModal">
            <div className="tokenTransferModal__container">
                <div className="container__header">
                    <h1>Изменить адрес qr</h1>{' '}
                    <button className="header__button" onClick={setHandleClose}>
                        ×
                    </button>
                </div>

                <div className="container__content">
                    <div className="stats__item__modal">
                        <img
                            src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${element.file_1}`}
                            alt={'img'}
                            style={{
                                objectFit: 'cover',
                                width: '48px',
                                height: '48px',
                            }}
                        />
                        <div className="stats__item__name">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                {element.collection.name}
                            </Link>

                            <p>{element.name}</p>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: '5px',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <InputLabel sx={{ color: 'white', fontSize: '15px' }}>
                            Адрес qr - кода
                        </InputLabel>
                        <input
                            className="container__input"
                            style={{ width: '100%', maxWidth: '400px', height: '35px' }}
                            value={linksData.qrLink}
                            onChange={(e) => handleChangeLinkValue(e.target.value, 'qrLink')}
                        />
                    </div>
                    <button className="modal__button" onClick={handleChangeLinks}>
                        Подтвердить
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <div> </div>
    );
};
