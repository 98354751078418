import React, { useContext } from 'react';
import { copyText } from '../../../functions/utils';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../../context/NotificationContext';

export const Footer = ({className}) => {

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const copyAddress = () => {
        copyText("https://checkbrand.site/");
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
    }

    return (
        <footer className={className} style={{width: '100%'}}>
            <div
                className={'contactsBox desktop'}
            >
                <a
                    href={'tel:89223120735'}
                    style={{
                        color: 'white'
                    }}
                    className={'tel'}
                >
                    8 922 312-07-35
                </a>
                <p style={{width: '250px'}}>Ежедневно с 8:00 по 20:00 по МСК</p>
                <a href={'mailto:support@checkbrand.com'}>support@checkbrand.com</a>
                <p style={{marginTop: '25px'}} className={'item desktop'}>© CheckBrand, 2024</p>
            </div>
            <div
                style={{width: '100%'}}
                className={'box'}
            >
                <span
                    style={{
                        color:'hsla(210, 13%, 91%, 0.8)',
                        marginTop: '12px',
                        fontSize: '14px'
                    }}
                    className={'contact'}
                >
                        Наши контакты:
                </span>
                <div className={'itemsBox'}>
                    <div className={'link_wrapper'}>
                        <a
                            className={'link_btn'}
                            href="https://wa.me/79223120735"
                            target={'_blank'}
                        >
                            <img src={'/assets/img/whatsapp.svg'} alt={'link'}/>
                        </a>
                        <a
                            className={'link_btn'}
                            href="https://t.me/bratouverie"
                            target={'_blank'}
                        >
                            <img src={'/assets/img/telegram.svg'} alt={'link'}/>
                        </a>
                        <a
                            className={'link_btn'}
                            href={'https://www.instagram.com/bratouverie/'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/insta.svg'} alt={'link'}/>
                        </a>
                        <div className={'link_btn_div'} onClick={copyAddress}>
                            <img src={'/assets/img/share2.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Поделиться </p>
                        </div>
                    </div>

                    <div
                        className={'contactsBox mobile'}
                    >
                        <a
                            href={'tel:8 922 312-07-35'}
                            style={{
                                color: 'white'
                            }}
                            className={'tel'}
                        >
                            8 922 312-07-35
                        </a>
                        <p>Ежедневно с 8:00 по 20:00 по МСК</p>
                        <a href={'mailto:support@checkbrand.com'}>support@checkbrand.com</a>
                        <p style={{marginTop: '40px'}} className={'item desktop'}>© CheckBrand, 2024</p>
                    </div>

                    <div className={'links'}>
                        <a
                            className={'link_btn_service desktop'}
                            href={'https://t.me/+5nhoVbOlL9dmNjJi'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/read.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Инструкция</p>
                        </a>
                        <a
                            className={'link_btn_service desktop'}
                            href={'https://t.me/+5nhoVbOlL9dmNjJi'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/read.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Оферта</p>
                        </a>
                        <a
                            className={'link_btn_service desktop'}
                            href={'https://t.me/+5nhoVbOlL9dmNjJi'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/read.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Возврат средств</p>
                        </a>
                        <a
                            className={'link_btn_service desktop'}
                            href={'https://t.me/+5nhoVbOlL9dmNjJi'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/read.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Политика конфиденциальности</p>
                        </a>
                        <a
                            className={'link_btn_service desktop'}
                            href={'https://t.me/+5nhoVbOlL9dmNjJi'}
                            target={'_blank'}
                        >
                            <img src={'/assets/img/read.svg'} alt={'link'}/>
                            <p style={{  fontSize: '14px' }}>Пользовательское соглашение</p>
                        </a>
                    </div>
                </div>
            </div>

            <p style={{margin: '-40px auto 0 auto'}} className={'item mobile'}>© CheckBrand, 2024</p>
        </footer>
    )
}
