import Input from '../../common/Input';
import Modal from '../../common/Modal';
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styles from './modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWelcomeModalActive } from '../../redux/slices/auth';
import InputMask from 'react-input-mask';
// import { SendCodeButton } from './SendCodeButton';
import { handleFetchCodeByType, handleUpdateUserContacts } from './utils';
import {
    CONTACTS_VERIFY_STEPS,
    MODAL_TEXT_CONTENT,
    fetchUserByToken,
    getCodeByType,
} from './utils';

export const ContactsModal = ({ setIsConfirmedEmail }) => {
    const accessToken = localStorage.getItem('access_token')
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [verifyStep, setVerifyStep] = useState(0);
    const [code, setCode] = useState({
        email: '',
        phone: '',
    });
    const dispatch = useDispatch();
    const isWelcomeModalActive = useSelector((state) => state.auth.isWelcomeModalActive);
    const [userProfile, setUserProfile] = useState(null);
    const [codeError,setCodeError] = useState('')
    const [errorMsg, setErrorMsg] = useState('');
    const currentStep = useMemo(() => {
        return CONTACTS_VERIFY_STEPS[verifyStep];
    }, [verifyStep]);

    const handleCloseModal = () => {
        setIsConfirmedEmail(false);
        dispatch(setIsWelcomeModalActive(false));
    };

    const handleNextStep = useCallback(
        (type) => {
            if (userProfile) {
                const { email_verified, phone_verified } = userProfile;
                const requestPayload = {};

                if (userProfile.new_email !== email) {
                    requestPayload.email = email;
                }
                if (userProfile.new_phone !== phone) {
                    requestPayload.phone = phone;
                }
                if (Object.keys(requestPayload).length > 0) {
                    if (verifyStep === 0) {
                        handleUpdateUserContacts(
                            setVerifyStep,
                            email,
                            phone,
                            setPhone,
                            setEmail,
                            userProfile,
                            requestPayload,
                        );
                    }
                    if (verifyStep === 1) {
                        const nextStep =
                            requestPayload.phone && requestPayload.phone === phone ? 2 : 3;
                        handleFetchCodeByType(
                            'email',
                            setCodeError,
                            code.email,
                            setVerifyStep,
                            nextStep,
                        );
                    }
                    if (verifyStep === 2) {
                        handleFetchCodeByType('phone', setCodeError, code.phone, setVerifyStep, 3);
                    }
                }
                if (Object.keys(requestPayload).length === 0) {
                    if (
                        verifyStep === 0 &&
                        (!email_verified || (!email_verified && !phone_verified))
                    ) {
                        setVerifyStep(1);
                    }
                    if (verifyStep === 1 && phone_verified) {
                        handleFetchCodeByType('email', setCodeError, code.email, setVerifyStep, 3);
                    }
                    if (verifyStep === 1 && !phone_verified) {
                        handleFetchCodeByType('phone', setCodeError, code.phone, setVerifyStep, 2);
                    }
                    if (verifyStep === 2) {
                        handleFetchCodeByType('phone', setCodeError, code.phone, setVerifyStep, 3);
                    }
                }
            }
        },
        [code, userProfile, email, phone, verifyStep],
    );

    console.log(code.email)

    const handleGoBack = () => {
        if (verifyStep > 0 && verifyStep < 3) {
            const { email_verified } = userProfile;
            if (verifyStep === 1) {
                setVerifyStep((prev) => prev - 1);
            }
            if (verifyStep === 2 && email_verified) {
                setVerifyStep(0);
            }
            if (verifyStep === 2 && !email_verified) {
                setVerifyStep((prev) => prev - 1);
            }
        }
    };

    useEffect(() => {
        if (verifyStep === 1) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/send_email/${email.trim()}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    'Content-type':'application/json'
                },
                method:'GET'
            }).then((res) => {
                console.log(res);
            });
        }
        if (verifyStep === 2) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/send_sms/${phone.trim().substring(2)}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    'Content-type':'application/json'
                },
                method:'GET'
            }).then((res) => {
                console.log(res);
            });
        }
    
    }, [verifyStep,phone,email]);

    useEffect(() => {
        if (isWelcomeModalActive) {
            fetchUserByToken(setPhone, setEmail, setUserProfile);
        }
        if (!isWelcomeModalActive) {
            setVerifyStep(0);
        }
    }, [isWelcomeModalActive]);

    const handleSetPhoneValue = (value) => {
            setPhone(value);
    };
    return (
        userProfile && (
            <Modal
                active={isWelcomeModalActive}
                title={'Контакты'}
                onClose={handleCloseModal}
                type={'contacts'}
                goPrevStep={() => handleGoBack()}
                verifyStep={verifyStep}
            >
                {verifyStep === 0 && <p className={styles.welcome}>CheckBrand приветствует Вас!</p>}
                <p style={{ margin: '20px 0 40px 0', color: 'white' }}>
                    {MODAL_TEXT_CONTENT[currentStep].title}
                </p>

                {verifyStep === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        {userProfile.email === email && userProfile.email_verified ? (
                            <span style={{ color: '#48ab48' }}>подтверждено</span>
                        ) : (
                            <span style={{ color: 'red' }}>не подтверждено</span>
                        )}
                        <Input
                            placeholder="Электронная почта*"
                            value={email}
                            setValue={setEmail}
                            className={styles.input}
                        />
                        {userProfile.phone === phone && userProfile.phone_verified ? (
                            <span style={{ color: '#48ab48' }}>подтверждено</span>
                        ) : (
                            <span style={{ color: 'red' }}>не подтверждено</span>
                        )}
                        <InputMask
                            maskChar={null}
                            mask="+79999999999"
                            placeholder={'Телефон'}
                            className={`${styles.input} input create__item--input `}
                            value={phone}
                            onChange={(e) => {
                                handleSetPhoneValue(e.target.value);
                            }}
                        />
                        <p className={styles.text}>*Обязательное поле</p>
                    </div>
                )}
                {verifyStep === 1 && (
                    <div style={{ width: '65%', gap: '10px' }}>
                        <p className={styles.text}>почта {email}</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                className="input create__item--input "
                                placeholder="Введите 6-и значный код"
                                value={code.email}
                                onChange={(e) =>
                                    setCode((prev) => ({ ...prev, email: e.target.value }))
                                }
                                isError={code.email.length === 0}
                                style={{ width: '100%', margin: '5px' }}
                            />

                            {/* <SendCodeButton
                                type={'email'}
                                data={email}
                                codeError={errorEmailCode}
                                updateError={errorEmailUpdate}
                                setUpdateError={setErrorEmailUpdate}
                            /> */}
                        </div>
                        <span style={{ color: 'red' }}>{errorMsg}</span>
                    </div>
                )}
                {verifyStep === 2 && (
                    <div style={{ width: '65%', gap: '10px' }}>
                        <p className={styles.text}>телефон {phone}</p>
                        <div>
                            <input
                                className="input create__item--input "
                                placeholder="Введите 6-и значный код"
                                value={code.phone}
                                onChange={(e) =>
                                    setCode((prev) => ({ ...prev, phone: e.target.value }))
                                }
                                isError={code.length === 0}
                                style={{ width: '100%', margin: '5px' }}
                            />
                            {/* <SendCodeButton
                                type={'phone'}
                                data={phone}
                                codeError={errorPhoneCode}
                                updateError={errorPhoneUpdate}
                                setUpdateError={setErrorPhoneUpdate}
                            /> */}
                        </div>
                        <span style={{ color: 'red' }}>{errorMsg}</span>
                    </div>
                )}
                <p style={{ color: 'red' }}>
                    {errorMsg && errorMsg.email_otp && errorMsg.email_otp[0]}
                </p>
                {verifyStep === 3 ? (
                    <button
                        className={`button token__card--content--price--buy ${styles.btn}`}
                        onClick={() => handleCloseModal()}
                        style={{ height: '52px' }}
                    >
                        {verifyStep < 3 ? 'продолжить' : 'готово'}
                    </button>
                ) : (
                    <button
                        className={`button token__card--content--price--buy ${styles.btn}`}
                        onClick={() => handleNextStep(MODAL_TEXT_CONTENT[currentStep].type)}
                        style={{ height: '52px' }}
                    >
                        {verifyStep < 3 ? 'продолжить' : 'готово'}
                    </button>
                )}
            </Modal>
        )
    );
};
