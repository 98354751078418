import React from 'react';

const Stack = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_16498_8839)">
                <path
                    d="M14.6229 4.85547H2.38021C1.53596 4.85547 0.851562 5.53182 0.851562 6.36613V12.9811C0.851562 13.8154 1.53596 14.4918 2.38021 14.4918H14.6229C15.4672 14.4918 16.1516 13.8154 16.1516 12.9811V6.36613C16.1516 5.53182 15.4672 4.85547 14.6229 4.85547Z"
                    fill="currentColor"
                />
                <path
                    d="M3.16642 3.80654H13.8334C14.1756 3.80654 14.4529 3.5325 14.4529 3.19428C14.4529 2.85607 14.1756 2.58203 13.8334 2.58203H3.16642C2.82418 2.58203 2.54688 2.85607 2.54688 3.19428C2.54688 3.5325 2.82418 3.80654 3.16642 3.80654Z"
                    fill="currentColor"
                />
                <path
                    d="M4.42291 1.5331H12.5786C12.8403 1.5331 13.0523 1.25906 13.0523 0.920847C13.0523 0.582633 12.8403 0.308594 12.5786 0.308594H4.42291C4.16124 0.308594 3.94922 0.582633 3.94922 0.920847C3.94922 1.25906 4.16124 1.5331 4.42291 1.5331Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_16498_8839">
                    <rect width="17" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Stack;
