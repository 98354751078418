import React from 'react';

const Web2 = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_16593_6792)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.12818 0.335939C4.48526 0.814519 3.02473 1.77716 1.93713 3.09827C0.849523 4.41938 0.185347 6.03762 0.03125 7.74187H3.91696C4.14347 5.14845 4.89539 2.62827 6.12696 0.334725L6.12818 0.335939ZM3.91696 9.25972H0.03125C0.185027 10.964 0.848918 12.5824 1.93631 13.9038C3.0237 15.2251 4.48409 16.188 6.12696 16.6669C4.89539 14.3733 4.14347 11.8531 3.91696 9.25972ZM8.07468 16.9911C6.60245 14.6561 5.69983 12.0078 5.43968 9.25972H11.5536C11.2935 12.0078 10.3908 14.6561 8.91861 16.9911C8.63747 17.0051 8.35581 17.0051 8.07468 16.9911ZM10.8675 16.6657C12.5102 16.1869 13.9705 15.2241 15.0579 13.903C16.1452 12.582 16.8092 10.9638 16.9632 9.25972H13.0775C12.851 11.8531 12.0991 14.3733 10.8675 16.6669V16.6657ZM13.0775 7.74187H16.9632C16.8095 6.03755 16.1456 4.41916 15.0582 3.09783C13.9708 1.7765 12.5104 0.813587 10.8675 0.334725C12.0991 2.62827 12.851 5.14845 13.0775 7.74187ZM8.07468 0.0105104C8.35622 -0.00350347 8.63828 -0.00350347 8.91982 0.0105104C10.3916 2.34556 11.2938 4.99393 11.5536 7.74187H5.44089C5.70439 4.97694 6.60904 2.32615 8.07468 0.0105104Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_16593_6792">
                    <rect width="17" height="17" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Web2;
