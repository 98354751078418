exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Administrator_content__2cG25 {\n  width: 100%;\n  margin-top: 5rem;\n}\n\n@media (max-width: 768px) {\n  .Administrator_content__2cG25{\n    margin-top: 3rem;\n  }\n}\n\n.Administrator_contentGroup__2eou8 {\n  margin-bottom: 5.7rem;\n}\n\n.Administrator_contentGroup__2eou8 div:not(:first-child) {\n  margin-top: 12px;\n}\n\n.Administrator_comInner__utVl0{\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 12px;\n  gap: 12px;\n  margin-bottom: 12px;\n}\n\n.Administrator_comInner__utVl0 div:not(:first-child){\n  margin-top: 0;\n}", ""]);

// exports
exports.locals = {
	"content": "Administrator_content__2cG25",
	"contentGroup": "Administrator_contentGroup__2eou8",
	"comInner": "Administrator_comInner__utVl0"
};