import React, { memo } from 'react';
import { Button, Checkbox, InputLabel } from '@mui/material';
import { InputField } from '../../common/SearchInput';

const variants = {
    brands: {
        name: 'Бренды',
        type: 'resource_id',
    },
    collections: {
        name: 'Коллекции',
        type: 'sub_resource_id',
    },
};
export const SelectMenu = memo(({ data, title, setOpen, onChange, setSearchValue, searchValue }) => {
    return (
        <div className="selectFilter">
            <button style={{ color: 'white' }}>
                <h3>{variants[title] && variants[title].name}</h3>
            </button>
            <InputField value={searchValue} setValue={(value) => setSearchValue(title,value)} onEnterSearch={() => {}} />
            <section style={{ width: '100%', paddingLeft: '20px' }}>
                {data.map((item, index) => {
                    return (
                        <section
                            key={index}
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '35px',
                                gap: '5px',
                            }}
                        >
                            <p style={{ color: 'white', fontSize: '14px' }}>{item.name}</p>
                            <Checkbox
                                size="small"
                                checked={item.state === true}
                                value={item.value}
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                }}
                                color="default"
                                onChange={(e) => onChange(item, variants[title].type)}
                            />
                        </section>
                    );
                })}
            </section>
        </div>
    );
});
