exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalNew_modal__3ygZG{\n    width: 100%;\n    height: 100vh;\n    position: fixed;\n    display: block;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 1004;\n    overflow-x: hidden;\n    overflow-y: auto;\n    opacity: 0;\n    pointer-events: none;\n    transition: 0.2s linear;\n}\n\n.ModalNew_modal__3ygZG.ModalNew_active__3C3G3 {\n    opacity: 1;\n    pointer-events: all;\n    z-index: 1004;\n}\n\n.ModalNew_modalInner__zDQnv{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 1.75rem auto 1.75rem auto;\n    min-height: calc(100% - 3.5rem);\n    max-width: 795px;\n}\n\n.ModalNew_modalContent__ZThhV{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    transform: scale(0);\n    transition: 0.2s linear;\n    overflow: hidden;\n    border-radius: 50px;\n    background: #15141F;\n    padding: 8.8rem 0 2rem 0;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.ModalNew_modalContent__ZThhV:before{\n    content: \"\";\n    display: block;\n    width: 100%;\n    height: 36px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    background: linear-gradient(to right, #2081E2, #2065E2);\n    border-radius: 50px 50px 0 0;\n}\n\n.ModalNew_modal__3ygZG.ModalNew_active__3C3G3 .ModalNew_modalContent__ZThhV {\n    transform: scale(1);\n}\n\n.ModalNew_modalClose__12ad5{\n    color: #fff;\n    width: 24px;\n    flex-shrink: 0;\n    position: absolute;\n    top: 51px;\n    right: 34px;\n    cursor: pointer;\n}\n\n@media (max-width: 480px) {\n    .ModalNew_modalClose__12ad5{\n        right: 13px;\n    }\n}\n\n.ModalNew_modalTitle__1jja8{\n    width: 100%;\n    padding: 0rem 1.6rem;\n    text-align: center;\n}", ""]);

// exports
exports.locals = {
	"modal": "ModalNew_modal__3ygZG",
	"active": "ModalNew_active__3C3G3",
	"modalInner": "ModalNew_modalInner__zDQnv",
	"modalContent": "ModalNew_modalContent__ZThhV",
	"modalClose": "ModalNew_modalClose__12ad5",
	"modalTitle": "ModalNew_modalTitle__1jja8"
};