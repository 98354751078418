import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generateQueryParams } from './utils';
import { useDispatch } from 'react-redux';
import { setParentsList } from './Statistics2/Container/tableSlice';
import { queryAllByAltText } from '@testing-library/react';

const parentEntities = [
    { name: 'page', type: 'page' },
    { name: 'account', type: 'brands' },
    { name: 'collection', type: 'collections' },
    { name: 'pack', type: 'packs' },
];

const handleFindEntities = (parentList, child) => {
    const resultNodes = [];

    parentList.forEach((element) => {
        const foundedElement = child[element.name];
        if (foundedElement) {
            resultNodes.push({ ...foundedElement, type: element.type });
        }
    });
    return resultNodes;
};

export const useDynamicPagination = (url, queryParams, handleRefetch) => {
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem('access_token');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [items, setItems] = useState([]);
    const [parentNodes, setParentNodes] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const { refetch, setRefetch } = handleRefetch;
    const filteredQuery = queryParams.filter(
        (param) => param.value !== '' && param.value !== String(undefined),
    );

    const defaultQuery = generateQueryParams([
        ...filteredQuery,
        {
            title: 'page',
            value: page,
        },
    ]);

    const refetchQuery = generateQueryParams([
        ...filteredQuery,
        {
            title: 'page',
            value: 1,
        },
    ]);

    const handleScrollPage = useCallback(
        (e) => {
            if (
                e.target.documentElement.scrollHeight -
                    (e.target.documentElement.scrollTop + window.innerHeight) <
                    100 &&
                items.length < count
            ) {
                setFetching(true);
            }
        },
        [items, count],
    );

    useEffect(() => {
        document.addEventListener('scroll', handleScrollPage);
        return () => {
            document.removeEventListener('scroll', handleScrollPage);
        };
    }, []);
    useEffect(() => {
        if (fetching) {
            fetch(`${url}${defaultQuery}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return new Error('error');
                    }
                })
                .then((result) => {
                    setItems([...items, ...result.results]);
                    console.log(result.count)
                    setCount(result.count);
                    setPage((prev) => prev + 1);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setFetching(false);
                });
        }

        if (refetch === true) {
            fetch(`${url}${refetchQuery}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return new Error('error');
                    }
                })
                .then((result) => {
                    setItems(result.results);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setRefetch(false);
                });
        }
    }, [fetching, defaultQuery, refetch, url]);

    return { items: items, parents: parentNodes, count: count };
};

// const search = queryParams.find((param) => param.title === 'q');
// useEffect(() => {
//     if (search && search.value.length > 0) {
//         const searchParam = generateQueryParams([search]);
//         fetch(`${url}${searchParam}`, {
//             headers: {
//                 Authorization: `Bearer ${accessToken}`,
//             },
//         })
//             .then((res) => {
//                 if (Number(res.status) === 200) {
//                     return res.json();
//                 }
//                 if (Number(res.status) >= 400) {
//                     return new Error('error');
//                 }
//             })
//             .then((result) => {
//                 if (result && result.results) {
//                     setSearchResult([...result.results]);
//                 }
//             });
//     }
// }, [search]);

// console.log(searchResult)
