exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccountsSearchResults_container__1z1iH {\n    width: 100%;\n    min-height: calc(100vh - 100px);\n    padding-left: 20px;\n    padding-right: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.AccountsSearchResults_itemsWrapper__2_RlG {\n    max-width: 1920px;\n    min-width: 100%;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    grid-gap: 16px;\n    gap: 16px;\n    padding-top: 16px;\n}\n\n.AccountsSearchResults_no_items_container__dDec_ {\n    width: 100%;\n    height: 100%;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.AccountsSearchResults_loader__1gLhl {\n    margin-top: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "AccountsSearchResults_container__1z1iH",
	"itemsWrapper": "AccountsSearchResults_itemsWrapper__2_RlG",
	"no_items_container": "AccountsSearchResults_no_items_container__dDec_",
	"loader": "AccountsSearchResults_loader__1gLhl"
};