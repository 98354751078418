exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BuyCertificate_text__rfJ8H {\n    color: hsla(210, 77%, 51%, 1);\n    margin-top: 24px;\n}\n\n.BuyCertificate_wrapper__16SMD {\n    margin-top: 60px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.BuyCertificate_img__2NT1m {\n    width: 88px;\n    height: 88px;\n}\n\n.BuyCertificate_name__3qouQ {\n    color: hsla(210, 77%, 51%, 1);\n    margin-left: 21px;\n}\n\n.BuyCertificate_description__2hW0V {\n    color: white;\n}\n\n.BuyCertificate_textCollection__2g5kE {\n    color: hsla(210, 77%, 51%, 1);\n    margin-bottom: 4px;\n}", ""]);

// exports
exports.locals = {
	"text": "BuyCertificate_text__rfJ8H",
	"wrapper": "BuyCertificate_wrapper__16SMD",
	"img": "BuyCertificate_img__2NT1m",
	"name": "BuyCertificate_name__3qouQ",
	"description": "BuyCertificate_description__2hW0V",
	"textCollection": "BuyCertificate_textCollection__2g5kE"
};