import React from 'react';

const NotContent = ({ ...props }) => {
    return (
        <svg viewBox="0 0 79 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M73.32 0H6.66284C3.45342 0 0.902344 2.63337 0.902344 5.76049V59.9091C0.902344 63.1186 3.53571 65.6696 6.66284 65.6696H73.2377C76.4471 65.6696 78.9982 63.1186 78.9982 59.9091V5.76049C79.0805 2.55108 76.4471 0 73.32 0ZM25.5902 5.92508C26.9892 5.92508 28.059 7.07718 28.059 8.39386C28.059 9.79284 26.9069 10.8626 25.5902 10.8626C24.1912 10.8626 23.1214 9.71055 23.1214 8.39386C23.1214 6.99488 24.1912 5.92508 25.5902 5.92508ZM18.0192 5.92508C19.4182 5.92508 20.488 7.07718 20.488 8.39386C20.488 9.79284 19.3359 10.8626 18.0192 10.8626C16.6203 10.8626 15.5505 9.71055 15.5505 8.39386C15.5505 6.99488 16.7026 5.92508 18.0192 5.92508ZM10.4483 5.92508C11.8473 5.92508 12.9171 7.07718 12.9171 8.39386C12.9171 9.79284 11.765 10.8626 10.4483 10.8626C9.04933 10.8626 7.97952 9.71055 7.97952 8.39386C7.97952 6.99488 9.13162 5.92508 10.4483 5.92508ZM71.5918 57.7695C71.5918 58.0164 71.4273 58.181 71.1804 58.181H8.80245C8.55557 58.181 8.39099 58.0164 8.39099 57.7695V16.87H71.5918V57.7695Z"
                fill="currentColor"
            />
            <path
                d="M25.8375 35.8775C26.2489 36.289 26.7427 36.4535 27.2365 36.4535C27.7302 36.4535 28.224 36.289 28.6354 35.8775L31.0219 33.491L33.4084 35.8775C33.8199 36.289 34.3136 36.4535 34.8074 36.4535C35.3011 36.4535 35.7949 36.289 36.2064 35.8775C36.947 35.1369 36.947 33.8202 36.2064 33.0795L33.8199 30.693L36.2064 28.3066C36.947 27.5659 36.947 26.3315 36.2064 25.5086C35.4657 24.768 34.149 24.768 33.4084 25.5086L30.9396 27.8951L28.5531 25.5086C27.8125 24.768 26.4958 24.768 25.7552 25.5086C25.0145 26.2492 25.0145 27.4836 25.7552 28.3066L28.1417 30.693L25.7552 33.0795C25.0145 33.8202 25.0145 35.0546 25.8375 35.8775Z"
                fill="currentColor"
            />
            <path
                d="M43.9417 35.8775C44.3532 36.289 44.8469 36.4535 45.3407 36.4535C45.8345 36.4535 46.3282 36.289 46.7397 35.8775L49.1262 33.491L51.5127 35.8775C51.9241 36.289 52.4179 36.4535 52.9116 36.4535C53.4054 36.4535 53.8991 36.289 54.3106 35.8775C55.0512 35.1369 55.0512 33.8202 54.3106 33.0795L51.9241 30.693L54.3106 28.3066C55.0512 27.5659 55.0512 26.3315 54.3106 25.5086C53.57 24.768 52.2533 24.768 51.5127 25.5086L49.0439 27.8951L46.6574 25.5086C45.9167 24.768 44.6001 24.768 43.8594 25.5086C43.1188 26.2492 43.1188 27.4836 43.8594 28.3066L46.2459 30.693L43.8594 33.0795C43.1188 33.8202 43.1188 35.0546 43.9417 35.8775Z"
                fill="currentColor"
            />
            <path
                d="M28.4702 47.1543C27.8942 48.1418 28.2233 49.3762 29.2109 49.9522C29.54 50.1168 29.8692 50.1991 30.1984 50.1991C30.939 50.1991 31.5973 49.8699 32.0088 49.1293C33.6547 46.3313 36.6995 44.5209 39.9089 44.5209C43.1183 44.5209 46.1632 46.249 47.809 49.1293C48.3851 50.1168 49.6195 50.446 50.607 49.8699C51.5945 49.2939 51.9237 48.0595 51.3476 47.072C48.9611 42.9573 44.5996 40.4062 39.8266 40.4062C35.0536 40.4062 30.8567 43.0396 28.4702 47.1543Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NotContent;
