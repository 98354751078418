import React from 'react';

const Link = ({...props}) => {
    return (
        <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83301 6.66667C4.94895 6.66667 4.10111 7.01786 3.47599 7.64298C2.85086 8.2681 2.49967 9.11594 2.49967 10C2.49967 10.8841 2.85086 11.7319 3.47599 12.357C4.10111 12.9821 4.94895 13.3333 5.83301 13.3333H7.49967C7.95991 13.3333 8.33301 13.7064 8.33301 14.1667C8.33301 14.6269 7.95991 15 7.49967 15H5.83301C4.50692 15 3.23516 14.4732 2.29747 13.5355C1.35979 12.5979 0.833008 11.3261 0.833008 10C0.833008 8.67392 1.35979 7.40215 2.29747 6.46447C3.23516 5.52678 4.50692 5 5.83301 5H7.49967C7.95991 5 8.33301 5.3731 8.33301 5.83333C8.33301 6.29357 7.95991 6.66667 7.49967 6.66667H5.83301ZM11.6663 5.83333C11.6663 5.3731 12.0394 5 12.4997 5H14.1663C15.4924 5 16.7642 5.52678 17.7019 6.46447C18.6396 7.40215 19.1663 8.67392 19.1663 10C19.1663 11.3261 18.6396 12.5979 17.7019 13.5355C16.7642 14.4732 15.4924 15 14.1663 15H12.4997C12.0394 15 11.6663 14.6269 11.6663 14.1667C11.6663 13.7064 12.0394 13.3333 12.4997 13.3333H14.1663C15.0504 13.3333 15.8982 12.9821 16.5234 12.357C17.1485 11.7319 17.4997 10.8841 17.4997 10C17.4997 9.11594 17.1485 8.2681 16.5234 7.64298C15.8982 7.01786 15.0504 6.66667 14.1663 6.66667H12.4997C12.0394 6.66667 11.6663 6.29357 11.6663 5.83333Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83301 10.0013C5.83301 9.54106 6.2061 9.16797 6.66634 9.16797H13.333C13.7932 9.16797 14.1663 9.54106 14.1663 10.0013C14.1663 10.4615 13.7932 10.8346 13.333 10.8346H6.66634C6.2061 10.8346 5.83301 10.4615 5.83301 10.0013Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Link;
