import React from 'react';

const TrashIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path
                fill="currentColor"
                d="M19.356 7.188a.374.374 0 0 0-.372-.424H5.014a.375.375 0 0 0-.371.424l1.861 14.096a.375.375 0 0 0 .372.325h10.246c.189 0 .348-.139.372-.326l1.862-14.095Zm-6.987 2.51a.468.468 0 0 0-.332-.138h-3.75c-.259 0-.468.21-.468.47v8.314c0 .26.21.47.468.47h7.424c.26 0 .47-.21.47-.47v-4.641a.468.468 0 0 0-.138-.331l-3.674-3.675Zm2.49 7.325a.187.187 0 0 0-.188-.187H9.374a.188.188 0 0 0-.187.187v.422c0 .104.084.188.187.188h5.297a.188.188 0 0 0 .188-.188v-.422Zm0-1.593a.187.187 0 0 0-.188-.188H9.374a.188.188 0 0 0-.187.188v.421c0 .104.084.188.187.188h5.297a.187.187 0 0 0 .188-.188v-.421Zm0-1.594a.187.187 0 0 0-.188-.188H9.374a.188.188 0 0 0-.187.188v.422c0 .103.084.187.187.187h5.297a.187.187 0 0 0 .188-.187v-.422Zm-2.528-2.54a.187.187 0 0 0-.187-.187h-2.77a.187.187 0 0 0-.187.188v.422c0 .103.084.187.187.187h2.77a.188.188 0 0 0 .187-.187v-.422ZM10.31 3.603H4.113a.375.375 0 0 0-.375.375v.999c0 .207.168.375.375.375h15.772a.375.375 0 0 0 .375-.375v-.999a.375.375 0 0 0-.375-.375H13.69v-.836a.375.375 0 0 0-.375-.375h-2.63a.375.375 0 0 0-.374.375v.836Z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default React.memo(TrashIcon);
