import React from 'react';

const CrossIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 14 14" fill="none">
            <path
                opacity="0.8"
                d="M0.79665 0.136684C0.614405 -0.0455612 0.318928 -0.0455612 0.136684 0.136684C-0.0455612 0.318928 -0.0455612 0.614405 0.136684 0.79665L6.34003 7L0.136684 13.2034C-0.0455612 13.3856 -0.0455612 13.6811 0.136684 13.8633C0.318928 14.0456 0.614405 14.0456 0.79665 13.8633L7 7.65997L13.2034 13.8633C13.3856 14.0456 13.6811 14.0456 13.8633 13.8633C14.0456 13.6811 14.0456 13.3856 13.8633 13.2034L7.65997 7L13.8633 0.79665C14.0456 0.614405 14.0456 0.318928 13.8633 0.136684C13.6811 -0.0455612 13.3856 -0.0455612 13.2034 0.136684L7 6.34003L0.79665 0.136684Z"
                fill="currentColor"
                fillOpacity="0.8"
            />
        </svg>
    );
};

export default React.memo(CrossIcon);
