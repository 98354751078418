exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RefreshDataComponent_collectionContentUpdate__1wai2 {\n    display: flex;\n    align-items: center;\n    grid-gap: 21px;\n    gap: 21px;\n}\n\n.RefreshDataComponent_collectionContentUpdateText__2NUK_ {\n    color: #e5e8eb;\n    font-size: 1.5rem;\n}\n\n.RefreshDataComponent_collectionContentUpdateButton__2nZv_{\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background: rgba(229, 232, 235, 0.02);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-shrink: 0;\n    grid-gap: 10px;\n    gap: 10px;\n}\n\n.RefreshDataComponent_collectionContentUpdateButton__2nZv_:hover{\n    background: rgba(229, 232, 235, 0.04);\n}\n\n.RefreshDataComponent_collectionContentUpdateButton__2nZv_:active{\n    background: rgba(229, 232, 235, 0.06);\n}\n\n.RefreshDataComponent_collectionContentUpdateIcon__LMzDp {\n    max-width: 20px;\n    max-height: 20px;\n}\n\n@media (max-width: 768px) {\n    .RefreshDataComponent_collectionContentUpdate__1wai2 {\n        grid-gap: 10px;\n        gap: 10px;\n    }\n}\n@media (max-width: 480px) {\n    .RefreshDataComponent_collectionContentUpdateText__2NUK_{\n        font-size: 1.5rem;\n    }\n\n    .RefreshDataComponent_collectionContentUpdateButton__2nZv_{\n        max-width: 16px;\n        max-height: 16px;\n    }\n\n    .RefreshDataComponent_collectionContentUpdateIcon__LMzDp {\n        max-width: 100%;\n        max-height: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"collectionContentUpdate": "RefreshDataComponent_collectionContentUpdate__1wai2",
	"collectionContentUpdateText": "RefreshDataComponent_collectionContentUpdateText__2NUK_",
	"collectionContentUpdateButton": "RefreshDataComponent_collectionContentUpdateButton__2nZv_",
	"collectionContentUpdateIcon": "RefreshDataComponent_collectionContentUpdateIcon__LMzDp"
};