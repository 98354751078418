exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WithdrawalAndReturnConditions_block__2-GRj {\n    background: hsla(210, 13%, 91%, 0.02);\n    padding: 20px;\n    max-height: 300px;\n    overflow-x: auto;\n    color: hsla(210, 13%, 91%, 0.6);\n}\n\n.WithdrawalAndReturnConditions_block__2-GRj span {\n    display: block;\n    margin-bottom: 20px;\n    color: hsla(210, 13%, 91%, 0.6);\n    font-size: 14px;\n}\n\n.WithdrawalAndReturnConditions_block__2-GRj p {\n    color: hsla(210, 13%, 91%, 0.6);\n    margin-bottom: 10px;\n    font-size: 14px;\n}\n\n", ""]);

// exports
exports.locals = {
	"block": "WithdrawalAndReturnConditions_block__2-GRj"
};