import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './index.module.css';

const OwnerItemSkeleton = () => {
    return (
        <div className={styles.ownersItemSkeleton}>
            <div className={styles.ownersItemWrapperSkeleton}>
                <div className={styles.ownersItemUser}>
                    <Skeleton
                        containerClassName={styles.ownersItemImgSkeletonWrapper}
                        className={styles.skeletonBrs}
                    />

                    <div className={styles.ownersItemUserInfoSkeleton}>
                        <Skeleton
                            containerClassName={styles.ownersItemNameSkeletonWrapper}
                            className={styles.skeletonBrs}
                        />

                        <Skeleton
                            containerClassName={styles.ownersItemRoleSkeletonWrapper}
                            className={styles.skeletonBrs}
                        />
                    </div>
                </div>

                <div className={styles.ownersItemCollections}>
                    <Skeleton
                        containerClassName={styles.ownersItemCollectionImgSkeletonWrapper}
                        className={styles.skeletonBrs10}
                    />

                    <Skeleton
                        containerClassName={styles.ownersItemCollectionImgSkeletonWrapper}
                        className={styles.skeletonBrs10}
                    />

                    <Skeleton
                        containerClassName={styles.ownersItemCollectionImgSkeletonWrapper}
                        className={styles.skeletonBrs10}
                    />

                    <Skeleton
                        containerClassName={styles.ownersItemCollectionImgSkeletonWrapper}
                        className={styles.skeletonBrs10}
                    />

                    <Skeleton
                        containerClassName={styles.ownersItemCollectionImgMoreSkeletonWrapper}
                        className={styles.skeletonBrs10}
                    />
                </div>

                <Skeleton
                    containerClassName={styles.ownersItemButtonSkeletonWrapper}
                    className={styles.skeletonBrs10}
                />
            </div>
        </div>
    );
};

export default OwnerItemSkeleton;
