import React,{useState} from "react";
import FaqItem from "../FaqItem";
import styles from '../../index.module.css';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
const FaqItems = () => {
    const [faqNumber, setFaqNumber] = useState(1);
    const [faqNumber2, setFaqNumber2] = useState(1);
    const [faqNumber3, setFaqNumber3] = useState(1);
    return(
        <section className={styles.faq} id="faq">
        <div className={base.container}>
            <div className={styles.faqInner}>
                <h2 className={cn(base.title2, styles.faqTitle)}>
                    Ответы на
                    <br />
                    частые вопросы
                </h2>

                <div className={styles.faqContent}>
                    <FaqItem question="Что обуславливает более высокую стоимость одежды с цифровым сертификатом?">
                        <div className={styles.faqItemPagginationContent}>
                            {faqNumber === 1 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Более высокая стоимость одежды с цифровым
                                        сертификатом обусловлена рядом дополнительных
                                        преимуществ и возможностей, которые получает
                                        владелец такой вещи.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Во-первых, цифровой сертификат открывает
                                        эксклюзивный доступ к специальным товарам, услугам и
                                        мероприятиям от партнеров бренда. Это могут быть
                                        ограниченные коллекции, закрытые распродажи,
                                        VIP-обслуживание и многое другое.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Таким образом, покупатель не просто приобретает
                                        одежду, а целый набор дополнительных привилегий,
                                        которые значительно повышают ценность и полезность
                                        покупки.
                                    </p>
                                </div>
                            )}

                            {faqNumber === 2 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Во-вторых, цифровой сертификат наделяет владельца
                                        особым статусом и причастностью к бренду.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Он получает доступ к закрытым клубным мероприятиям,
                                        эксклюзивным предложениям и гарантированным призам,
                                        что подчеркивает его особый статус среди других
                                        покупателей.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Это создает ощущение престижа и исключительности,
                                        что также находит отражение в более высокой
                                        стоимости.
                                    </p>
                                </div>
                            )}

                            {faqNumber === 3 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Наконец, цифровой сертификат выступает гарантом
                                        подлинности и происхождения одежды, защищая ее от
                                        подделок.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Это особенно важно для коллекционеров и ценителей,
                                        которые готовы платить больше за уверенность в
                                        аутентичности приобретаемой вещи.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Таким образом, цифровой сертификат не только
                                        наделяет одежду дополнительными функциями и
                                        преимуществами, но и выступает своеобразным знаком
                                        качества, что и обуславливает ее более высокую
                                        стоимость на рынке.
                                    </p>
                                </div>
                            )}

                            <div className={styles.faqItemPaggination}>
                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber === 1,
                                    })}
                                    onClick={() => setFaqNumber(1)}
                                >
                                    1
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber === 2,
                                    })}
                                    onClick={() => setFaqNumber(2)}
                                >
                                    2
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber === 3,
                                    })}
                                    onClick={() => setFaqNumber(3)}
                                >
                                    3
                                </button>
                            </div>
                        </div>
                    </FaqItem>

                    <FaqItem question="Какие преимущества токенизированной одежды привлекают интерес покупателей?">
                        <div className={styles.faqItemPagginationContent}>
                            {faqNumber2 === 1 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Одним из ключевых преимуществ токенизированной
                                        одежды, которое привлекает повышенный интерес
                                        покупателей, является ее уникальный и инновационный
                                        дизайн.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Многие модели могут быть оформлены с использованием
                                        QR-кодов или других элементов цифровой графики, что
                                        придает им особую визуальную привлекательность и
                                        технологичность.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Сканирование QR-кода на одежде вызывает у
                                        покупателей любопытство и желание узнать больше о
                                        связанном с ним цифровом сертификате.
                                    </p>
                                </div>
                            )}

                            {faqNumber2 === 2 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Кроме того, владение токенизированной одеждой дает
                                        возможность стать частью эксклюзивного сообщества
                                        ценителей инноваций и технологий.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Покупатели получают возможность коллекционировать и
                                        демонстрировать перед окружающими свои цифровые
                                        сертификаты, что повышает их статус и престиж.
                                    </p>
                                </div>
                            )}

                            {faqNumber2 === 3 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Многие покупатели также ценят возможность получать
                                        высокие рейтинги в социальных сетях за накопление
                                        редких и дорогостоящих сертификатов, а также шанс
                                        выиграть ценные призы и приглашения, доступные
                                        только для владельцев определенных токенов.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Таким образом, помимо практических преимуществ,
                                        токенизированная одежда обладает мощным
                                        символическим значением, позволяя покупателям
                                        выразить свою причастность к технологическим
                                        инновациям и продемонстрировать свой статус в
                                        обществе.
                                    </p>
                                </div>
                            )}

                            <div className={styles.faqItemPaggination}>
                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber2 === 1,
                                    })}
                                    onClick={() => setFaqNumber2(1)}
                                >
                                    1
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber2 === 2,
                                    })}
                                    onClick={() => setFaqNumber2(2)}
                                >
                                    2
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber2 === 3,
                                    })}
                                    onClick={() => setFaqNumber2(3)}
                                >
                                    3
                                </button>
                            </div>
                        </div>
                    </FaqItem>

                    <FaqItem question="Как цифровой сертификат гарантирует подлинность и защищает от контрафакта?">
                        <div className={styles.faqItemPagginationContent}>
                            {faqNumber3 === 1 && (
                                <p className={styles.faqItemText}>
                                    Ключевой механизм защиты заключается в неразрывной связи
                                    между цифровым сертификатом и физической вещью. Каждая
                                    единица одежды имеет уникальный QR-код, который напрямую
                                    связан с ее цифровым сертификатом. При покупке, этот
                                    QR-код передается новому владельцу вместе с одеждой.
                                </p>
                            )}

                            {faqNumber3 === 2 && (
                                <p className={styles.faqItemText}>
                                    После приобретения, владелец регистрирует цифровой
                                    сертификат на специальной платформе CheckBrand. В его
                                    профиле фиксируется факт владения данным сертификатом и
                                    связанной с ним вещью. Теперь любой желающий может
                                    просканировать QR-код на одежде и убедиться, что она
                                    принадлежит именно этому владельцу.
                                </p>
                            )}

                            {faqNumber3 === 3 && (
                                <p className={styles.faqItemText}>
                                    Важно отметить, что до момента регистрации на
                                    CheckBrand, цифровой сертификат не имеет владельца.
                                    Попытки подделать или скопировать QR-код будут сразу
                                    выявлены, так как сканирование покажет, что сертификат
                                    не закреплен ни за кем.
                                </p>
                            )}

                            {faqNumber3 === 4 && (
                                <div className={styles.faqItemPagginationTextWrapper}>
                                    <p className={styles.faqItemText}>
                                        Таким образом, цифровой сертификат становится
                                        неотъемлемой частью подлинной вещи, гарантируя ее
                                        происхождение и защищая от контрафакта. Более того,
                                        сама платформа CheckBrand создает единую базу
                                        владельцев брендовых товаров, позволяя обществу
                                        самостоятельно выявлять случаи ношения подделок.
                                    </p>

                                    <p className={styles.faqItemText}>
                                        Простота и прозрачность этой системы защиты вызывает
                                        большой интерес у покупателей, которые ценят
                                        гарантии подлинности и возможность демонстрировать
                                        свою причастность к эксклюзивным вещам.
                                    </p>
                                </div>
                            )}

                            <div className={styles.faqItemPaggination}>
                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber3 === 1,
                                    })}
                                    onClick={() => setFaqNumber3(1)}
                                >
                                    1
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber3 === 2,
                                    })}
                                    onClick={() => setFaqNumber3(2)}
                                >
                                    2
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber3 === 3,
                                    })}
                                    onClick={() => setFaqNumber3(3)}
                                >
                                    3
                                </button>

                                <button
                                    className={cn(styles.faqItemPagginationItem, {
                                        [styles.active]: faqNumber3 === 4,
                                    })}
                                    onClick={() => setFaqNumber3(4)}
                                >
                                    4
                                </button>
                            </div>
                        </div>
                    </FaqItem>

                    <FaqItem question="Какие шаги необходимо предпринять, чтобы начать сотрудничество?">
                        <div className={styles.faqItemPagginationContent}>
                            <div className={styles.faqItemPagginationTextWrapper}>
                                <ul className={styles.faqItemUl}>
                                    <li>
                                        Свяжитесь с нами любым удобным способом (по
                                        телефону, электронной почте, через форму на сайте) и
                                        расскажите о потребностях и задачах Вашего бренда.
                                    </li>
                                    <li>
                                        Мы проанализируем Ваш запрос и предложим оптимальные
                                        решения для токенизации Вашей брендовой продукции, а
                                        также обсудим условия и стоимость.
                                    </li>
                                    <li>
                                        После согласования всех деталей мы заключим договор
                                        и приступим к процессу токенизации Ваших изделий.
                                    </li>
                                    <li>
                                        На протяжении всего сотрудничества мы будем на
                                        связи, чтобы оперативно решать возникающие вопросы.
                                    </li>
                                    <li>
                                        По завершении работ мы предоставим Вам отчет и будем
                                        рады дальнейшему взаимодействию.
                                    </li>
                                </ul>

                                <p className={styles.faqItemText}>
                                    Будем рады ответить на любые дополнительные вопросы!
                                </p>
                            </div>
                        </div>
                    </FaqItem>
                </div>
            </div>
        </div>
    </section>
    )
}

export default FaqItems