import React from 'react';

const Prop = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9651 5.19118H2.19456C1.16416 5.19118 0.328125 6.02383 0.328125 7.04994C0.328125 8.0761 1.16421 8.9087 2.19456 8.9087H10.9651V5.19118ZM13.8026 5.19118H11.4765V8.9087H13.8026C14.833 8.9087 15.669 8.07605 15.669 7.04994C15.669 6.02378 14.8329 5.19118 13.8026 5.19118ZM6.6998 0H2.19472C1.16432 0 0.328289 0.832007 0.328289 1.8581C0.328289 2.88427 1.16437 3.71686 2.19472 3.71686H6.6998V0ZM13.8026 0H7.21116V3.71702H13.8026C14.833 3.71702 15.669 2.88438 15.669 1.85827C15.669 0.832105 14.8329 0.000163184 13.8026 0.000163184V0Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Prop;
