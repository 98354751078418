exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoBlock_collectionData__262_U {\n    width: 100%;\n    display: flex;\n    align-items: flex-start;\n    grid-gap: 60px;\n    gap: 60px;\n    margin-top: 2.4rem;\n}\n\n.InfoBlock_collectionDataItem__3-lzN {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    grid-gap: 4px;\n    gap: 4px;\n}\n\n.InfoBlock_collectionDataTitle__3wpyB {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #e5e8eb;\n    font-size: 2rem;\n    font-weight: 600;\n    line-height: 1;\n    white-space: nowrap;\n}\n\n.InfoBlock_collectionDataText__20tGL {\n    color: rgba(229, 232, 235, 0.8);\n    font-size: 1.4rem;\n}\n\n@media (max-width: 768px) {\n    .InfoBlock_collectionData__262_U{\n        grid-gap: 35px;\n        gap: 35px;\n        flex-wrap: wrap;\n    }\n\n    .InfoBlock_collectionDataItem__3-lzN {\n        width: calc(100% / 3 - 40px / 3);\n        min-width: 1px;\n        justify-content: center;\n        align-items: flex-start;\n        background: none;\n    }\n\n    .InfoBlock_collectionDataText__20tGL {\n        font-size: 1.6rem;\n        text-align: left;\n        margin-top: 0;\n    }\n}\n@media (max-width: 480px) {\n    .InfoBlock_collectionDataTitle__3wpyB {\n        font-size: 1.8rem;\n    }\n\n    .InfoBlock_collectionDataText__20tGL {\n        font-size: 1.4rem;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"collectionData": "InfoBlock_collectionData__262_U",
	"collectionDataItem": "InfoBlock_collectionDataItem__3-lzN",
	"collectionDataTitle": "InfoBlock_collectionDataTitle__3wpyB",
	"collectionDataText": "InfoBlock_collectionDataText__20tGL"
};