import React from 'react';
import styles from '../../index.module.css';
import Comment from '../../../../icons/Comment';
import cn from 'classnames';
import { useEffect, useState, useContext } from 'react';
// import { S3Context } from '../../../../context/S3Context';
import base from '../../../../styles/base.module.css';
import { useLocation } from 'react-router-dom';
const answers = [
    'Токенизируйте - создайте для клиентов коллекцию цифровых сертификатов',
    'Лидируйте - возглавьте новую эру токенизированной брендовой одежды',
    'Наполняйте - подключите к своей продукции товары и услуги партнёров',
];

const questions = [
    'Как бренду одежды привлечь внимание к своей продукции?',
    'Как бренду одежды победить в конкуренции с другими брендами?',
    'Как бренду одежды поднять стоимость своего товара?',
];

const answerText = [
    'Токенизируйте - создайте для клиентов коллекцию цифровых сертификатов',
    'Лидируйте - возглавьте новую эру токенизированной брендовой одежды',
    ' Наполняйте - подключите к своей продукции товары и услуги партнёров',
];

export const Welcome = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexQuestion, setActiveIndexQuestion] = useState(0);
    const location = useLocation();
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setActiveIndex((prevIndex) => (prevIndex + 1) % answers.length);
    //         setActiveIndexQuestion((prevIndex) => (prevIndex + 1) % questions.length);
    //     }, 2000);
    //     return () => clearInterval(interval);
    // }, []);


    const scrollToHash = (hash) => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
                location.hash = '';
            }
        }
    };
    
    return (
        <section className={styles.mainnew}>
            <img src="/assets/img/main-bg2.png" alt="main bg" className={styles.mainnewBg} />
            <img
                src="/assets/img/main-woman.svg"
                alt="main woman"
                className={styles.mainnewWoman}
            />
            <img
                src="/assets/img/main-bg-mobile.svg"
                alt="main woman"
                className={styles.mainnewWomanMobile}
            />
            <img
                src="/assets/img/main-shadow.svg"
                alt="main shadow"
                className={styles.mainnewShadow}
            />

            <div className={base.container}>
                <div className={styles.mainnewInner}>
                    <div className={styles.mainnewWrapper}>
                        <p className={styles.mainTitle}>Платформа токенизации брендовых вещей</p>

                        <button
                            className={cn(base.orangeButton, styles.mainButton)}
                            onClick={() => {
                                scrollToHash('#start_cooperation');
                            }}
                        >
                            Начать токенизацию продукции
                        </button>
                    </div>

                    <div className={styles.mainnewContent}>
                        <div className={styles.mainnewLeft}>
                            <p className={styles.mainnewSubtext}>Найдите ответы на вопросы:</p>

                            <div className={styles.mainnewComment}>
                                <Comment />

                                <div className={styles.mainnewCarouselAnswers}>
                                    {questions.map((data, index) => (
                                        <p
                                            className={cn(styles.mainnewCarouselAnswer, {
                                                [styles.active]: index === activeIndexQuestion,
                                            })}
                                        >
                                            {data}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className={styles.mainnewRight}>
                            <p className={styles.mainnewSubtext}>CheckBrand предлагает решение:</p>

                            <div className={styles.mainnewAnswer}>
                                {answerText.map((text, index) => {
                                    return (
                                        <p
                                            className={cn(styles.mainnewAnswerText, {
                                                [styles.active]: activeIndex === index,
                                            })}
                                        >
                                            {text}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
                                {/* <p
                                    className={cn(styles.mainnewAnswerText, {
                                        [styles.active]: activeIndex === 0,
                                    })}
                                >
                                    Токенизируйте - создайте для клиентов коллекцию цифровых
                                    сертификатов
                                </p>

                                <p
                                    className={cn(styles.mainnewAnswerText, {
                                        [styles.active]: activeIndex === 1,
                                    })}
                                >
                                    Лидируйте - возглавьте новую эру токенизированной брендовой
                                    одежды
                                </p>

                                <p
                                    className={cn(styles.mainnewAnswerText, {
                                        [styles.active]: activeIndex === 2,
                                    })}
                                >
                                    Наполняйте - подключите к своей продукции товары и услуги
                                    партнёров
                                </p> */}