import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentsList: [],
};

const tableStatistic2Slice = createSlice({
    name: 'tableSlice',
    initialState: initialState,
    reducers: {
        setParentsList: (state, action) => {
            state.parentsList = action.payload
        },
    },
});

export const { setParentsList } = tableStatistic2Slice.actions;

export const tableReducer = tableStatistic2Slice.reducer;
