import React from 'react';
// import React from 'react';
import styles from '../../index.module.css';
import cn from 'classnames';
import { useEffect, useState, useContext } from 'react';
import Light from '../../../../icons/Light';
import base from '../../../../styles/base.module.css';
import { useLocation } from 'react-router-dom';
import { howItWorksTitles } from './utils';

const HowItWorks = () => {
    return (
        <section className={styles.howit}>
            <img src="/assets/img/blur-right.svg" alt="blur" className={styles.howitBlur} />

            <div className={base.container}>
                <div id="how_it_work" className={styles.howitInner}>
                    <h2 className={cn(base.title2, styles.howitTitle)}>Как это работает</h2>

                    {howItWorksTitles.map((title, index) => {
                        return (
                            <div className={styles.howitContent} key={index}>
                                <div className={styles.howitItem}>
                                    <div className={styles.howitItemImgInner}>
                                        <img
                                            src="/assets/img/element-fire.png"
                                            alt="light"
                                            className={styles.howitItemLight}
                                        />

                                        <img src={title.img} alt="how img" />
                                    </div>

                                    <div className={styles.howitItemWrap}>
                                        <div className={styles.howitTextInner}>
                                            <p className={styles.howitText}>{title.text}</p>

                                            <div className={styles.howitLightText}>
                                                <Light />
                                                {title.subText}
                                            </div>
                                        </div>

                                        <div className={styles.howitSubtextInner}>
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {title.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
