import React from 'react';
import InputMask from 'react-input-mask';

export const TelegramMaskedInput = ({ value, onChange }) => {
  const telegramMask = `https://t.me/${''.padStart(value.length + 1, '*')}`;

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedText = (e.clipboardData || window.clipboardData).getData('text');

    const processedText = pastedText.replace(/^https:\/\/t\.me\//, '');

    onChange(processedText);
  }

  return (
    <InputMask
      maskChar={null}
      mask={telegramMask}
      placeholder={'https://t.me/'}
      className="input service__item--input"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onPaste={handlePaste}
    />
  );
};