import React from 'react';

const Car = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.93242 13.9326H8.33242M20.0658 13.9326H15.6658M5.39909 9.53255H18.5991M22.2658 9.52772V18.3326C22.2658 19.1426 21.6091 19.7992 20.7991 19.7992H19.3324C18.5224 19.7992 17.8658 19.1426 17.8658 18.3326V16.8659H6.13242V18.3326C6.13242 19.1426 5.47577 19.7992 4.66576 19.7992H3.19909C2.38907 19.7992 1.73242 19.1426 1.73242 18.3326V9.52772L4.8505 4.33434C5.64584 3.00966 7.07771 2.19922 8.62281 2.19922H15.3754C16.9205 2.19922 18.3523 3.00966 19.1477 4.33434L22.2658 9.52772Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default Car;
