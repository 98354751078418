import React from 'react';

const AboutItem = ({img, title, text}) => {
    return (
        <div className="about__history--item">
            <div className="about__history--item--img--inner">
                <img
                    src={img}
                    alt="about"
                    className="about__history--item--img"
                />
            </div>

            <div className="about__history--item--text--block">
                <p className="about__history--item--text--title">{title}</p>

                <div className="about__history--item--text--wrap">
                    {text.map((data, id) => (<p key={id} className="about__history--item--text">
                        {data.text}
                    </p>))}
                </div>
            </div>
        </div>
    );
};

export default AboutItem;