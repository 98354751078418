import { ethers } from 'ethers';
import { getAgoTimeFormat } from '../../../utils/getAgoTimeFormat';

const META_DATA_FIELDS_ARR = [
    'field_1',
    'field_2',
    'field_3',
    'field_4',
    'field_5',
    'field_6',
    'field_7',
    'field_8',
    'field_9',
    'field_10',
];

export const convertWhitelistsObj = async (whitelist) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(whitelist.user_wallet);
    const balanceInEth = Number(ethers.utils.formatEther(balance)).toFixed(4);

    const metaData = META_DATA_FIELDS_ARR.map((key) => whitelist[key])
        .filter((data) => data)
        .join(', \n');

    return {
        id: whitelist.id,
        status: whitelist.status,
        avatar: '',
        collection: {
            collectionId: whitelist.collection_id,
            name: '',
        },
        user: {
            discordName: whitelist.discord_name,
            email: whitelist.email,
        },
        metadata: metaData,
        address: whitelist.address,
        wallet: {
            address: whitelist.user_wallet,
            balance: balanceInEth,
        },
        time: getAgoTimeFormat(
            new Date().getTime() -
                (new Date(whitelist.updated_at).getTime() -
                    new Date().getTimezoneOffset() * 60 * 1000),
        ),
        inWork: whitelist.in_work,
        tokenId: whitelist.token_id,
        hide: whitelist.hide,
    };
};
