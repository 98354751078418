import React from 'react';

import './index.css';

import { CustomSelect } from '../../common/CustomSelect';
import FilterItem from '../../components/FilterItem';
import File from '../../common/File';
import FullDescription from '../../common/FullDescription';
import { Link } from 'react-router-dom';

const sortList = [
    { value: 'user1', name: 'User 1' },
    { value: 'user2', name: 'User 2' },
    { value: 'user3', name: 'User 3' },
    { value: 'user4', name: 'User 4' },
];

const CreateFactorystores = () => {
    const [logo, setLogo] = React.useState('');

    const [filter, setFilter] = React.useState('user1');
    const [filters, setFilters] = React.useState([]);
    const [filterActive, setFilterActive] = React.useState(false);
    const [filterData2, setFilterData2] = React.useState([
        {
            name: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <div className="title__inner">
                        <Link to="../factorystores">
                            <img src="/assets/img/back-arrow.svg" alt="arrow" />
                        </Link>
                        
                        <h2 className="title collection__title">Create Factory / Store</h2>
                    </div>

                    <FullDescription description="Fill in the fields and click Save. The service will be available to users after moderation.
                        Specify the NFT - collections whose owners will be able to use the service.
                        Specify the type of service, description, contacts and other information.
                        In case of providing a low-quality/inconsistent with the service description, the service will be deleted.
                        It is forbidden to provide illegal services.
                        Before providing the service it is necessary to scan the QR code of the NFT owner on his/her phone and verify the avatar of the NFT owner with the client's face.
                        Or ask the user to put your company logo on his/her profile avatar and send you the link to his/her NFT.
                        The service can only be provided if there is a match.
                        By posting your ad you agree to the rules of the service." />

                    <button className="button service__show--collection" onClick={() => setFilterActive(true)}>
                        Choose collections

                        <span className="service__collection--count">
                            8
                        </span>
                    </button>

                    <div className="collection__content">
                        <div className={`collection__filter--box service__filter${filterActive ? " active" : ""}`}>
                            <div className="collection__filter--title--box">
                                <p className="collection__filter--title">Filters</p>

                                <img
                                    src="/assets/img/cross2.svg"
                                    alt="cross"
                                    className="collection__filter--title--cross"
                                    onClick={() => setFilterActive(false)}
                                />
                            </div>

                            <FilterItem
                                title="Brands"
                                value={filterData2.length}
                                elements={filterData2}
                                setData={setFilterData2}
                                setFilters={setFilters}
                                filter
                                filters={filters}
                            />

                            <div className="collection__filter--buttons">
                                <button className="button collection__filter--button--filter">
                                    Clear all
                                </button>

                                <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive(false)}>
                                    Done
                                </button>
                            </div>
                        </div>

                        <div className="collection__content--preitems">
                            <div className="collection__filter--active">
                                <div className="collection__filter--active--content">
                                    <button className="button collection__filter--active--item">
                                        <p className="collection__filter--active--item--text">
                                            Ready
                                        </p>

                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="collection__filter--active--item--delete"
                                        />
                                    </button>
                                </div>

                                <button className="button collection__filter--clear">
                                    Clear All
                                </button>
                            </div>

                            <div className="service__content">
                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Kind of point
                                    </p>

                                    <div className="service__item--content">
                                        <CustomSelect
                                            optionsList={sortList}
                                            value={filter}
                                            placeholder="Factory"
                                            onChange={filterChange}
                                        />
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Point name
                                    </p>

                                    <div className="service__item--content">
                                        <input className="input service__item--input" placeholder="Enter point name" />

                                        <p className="service__item--input--char">
                                            30 characters
                                        </p>
                                    </div>
                                </div>

                                <div className="service__item">
                                    <p className="service__item--title">
                                        Point logo
                                    </p>

                                    <div className="service__item--content">
                                        <File
                                            type="logosmall"
                                            id="createfactoryLogo"
                                            value={logo}
                                            setValue={setLogo}
                                        />
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Moderators
                                    </p>

                                    <div className="service__item--content">
                                        <CustomSelect
                                            optionsList={sortList}
                                            value={filter}
                                            placeholder="Factory"
                                            onChange={filterChange}
                                        />
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Point description
                                    </p>

                                    <div className="service__item--content">
                                        <textarea className="input service__item--textarea long" placeholder="Enter a point description (if necessary)"></textarea>

                                        <p className="service__item--input--char">
                                            500 characters
                                        </p>
                                    </div>
                                </div>

                                <button className="button service__confirm">
                                    Save сhanges
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateFactorystores;