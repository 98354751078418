import React from 'react';
import styles from '../../index.module.css';
import Question2 from '../../../../icons/Question2';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
import { tarifTitles, tarifList } from './consts';
import { PaymentModal } from './PaymentModal/PaymentModal';
import { Tarif } from './Tarif/Tarif';
import { Tooltip } from '../../../../components/Tooltip';

const BuyTarif = () => {
    const [selectedTariff, setSelectedTariff] = React.useState({});
    const [testModal, setTestModal] = React.useState(false);

    return (
        <section className={styles.tarif}>
            <img src="/assets/img/blur-right.svg" alt="blur" className={styles.tarifBlur} />
            <img src="/assets/img/blur-left.svg" alt="blur" className={styles.tarifBlur2} />

            <div className={base.container}>
                <div id="rate" className={styles.tarifInner}>
                    <h2 className={cn(base.title2, styles.tarifTitle)}>Выберите тариф</h2>

                    <div className={styles.tarifContent}>
                        <div className={styles.tarifNames}>
                            {tarifTitles.map((block, index) => {
                                return (
                                    <span className={styles.tarifName} key={index}>
                                        {block.text}
                                        <Tooltip text={block.tooltip}>
                                            <Question2 />
                                        </Tooltip>
                                    </span>
                                );
                            })}
                            <div className={styles.tarifNamesEmpty}></div>
                        </div>
                        <div className={styles.tarifWrapper}>
                            {tarifList.map((tarif, index) => {
                                return (
                                    <Tarif
                                        tarif={tarif}
                                        key={index}
                                        setSelectedTariff={setSelectedTariff}
                                        handleOpenModal={setTestModal}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal
                state={testModal}
                handleSetModal={setTestModal}
                selectedTariff={selectedTariff}
            />
        </section>
    );
};

export default BuyTarif;
