
import Modal from '../../../common/Modal';
import React from 'react';
import Linkify from 'react-linkify';

export const TrustModal = ({isOpen, setIsOpen}) => {
    return (
        <Modal
            active={isOpen}
            title={'Авторизация'}
            setActive={setIsOpen}
            size={'medium'}
        >
            <p style={{color: '#257FD9'}}>Подключите цифровой кошелёк Trust</p>

            <img
                src="/assets/img/trust2.png"
                alt="trust"
                style={{marginTop: '15px'}}
            />
            <button
                style={{
                    background: '#1767B7',
                    borderRadius: '4px',
                    color: 'white',
                    marginTop: '50px',
                    height: '52px',
                    width: '100%'
                }}
            >
                Посмотреть инструкцию
            </button>
            <Linkify
                componentDecorator={(
                    decoratedHref,
                    decoratedText,
                ) => (
                    <a
                        key={decoratedText}
                        href={decoratedHref}
                        className="desc_link"
                    >
                        {decoratedText}
                    </a>
                )}
            >
                <p className="token__card--item--about--text" style={{marginTop: '40px'}}>
                    На платформе используются передовые средства защиты цифровой собственности. <br />
                    Вам понадобится цифровой кошелёк Trust, чтобы владеть и управлять цифровым сертификатом. <br />
                    После нажатия кнопки, вы перейдёте в Телеграм канал, где вам
                    будет предложен минутный видеоролик - инструкция.
                </p>
            </Linkify>
        </Modal>
    )
}
