import React from 'react';

const Diamond = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.59189 0.940166C4.73157 0.696887 4.99069 0.546875 5.27121 0.546875H19.2288C19.5093 0.546875 19.7684 0.696887 19.9081 0.940166L23.896 7.88571C24.0605 8.17232 24.0275 8.53136 23.8134 8.78315L12.8468 21.682C12.698 21.8571 12.4798 21.958 12.25 21.958C12.0202 21.958 11.802 21.8571 11.6532 21.682L0.686547 8.78315C0.47248 8.53136 0.439461 8.17232 0.604018 7.88571L4.59189 0.940166ZM5.72472 2.11354L2.23696 8.18806L12.25 19.9653L22.263 8.18806L18.7753 2.11354H5.72472Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.01195 0.591453C5.42007 0.447927 5.86727 0.662424 6.0108 1.07054L12.2506 18.8137L18.4904 1.07054C18.6339 0.662424 19.0811 0.447927 19.4893 0.591453C19.8974 0.734978 20.1119 1.18218 19.9684 1.5903L12.9896 21.4347C12.8793 21.7483 12.5831 21.9582 12.2506 21.9582C11.9182 21.9582 11.6219 21.7483 11.5116 21.4347L4.53286 1.5903C4.38933 1.18218 4.60383 0.734978 5.01195 0.591453Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.5 8.27552C0.5 7.8429 0.85071 7.49219 1.28333 7.49219H23.2166C23.6493 7.49219 24 7.8429 24 8.27552C24 8.70814 23.6493 9.05885 23.2166 9.05885H1.28333C0.85071 9.05885 0.5 8.70814 0.5 8.27552Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2503 0.546875C12.5162 0.546875 12.764 0.681797 12.9083 0.905183L17.3946 7.85073C17.6293 8.21413 17.525 8.69902 17.1616 8.93375C16.7982 9.16849 16.3133 9.06418 16.0786 8.70078L12.2503 2.77391L8.4219 8.70078C8.18716 9.06418 7.70227 9.16849 7.33887 8.93375C6.97547 8.69902 6.87116 8.21413 7.10589 7.85073L11.5923 0.905183C11.7365 0.681797 11.9843 0.546875 12.2503 0.546875Z"
                fill="white"
            />
        </svg>
    );
};

export default Diamond;
