import React, { useEffect, useState } from 'react';
import styles from '../../index.module.css'
import TokenItem from '../../../Collection/UI/TokenItem';
import { WHITE_LIST_APPLICATION_STATUSES } from '../../../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import { useGetFilteredTokensQuery } from '../../../../redux/api/handleService';


export const CollectionCertificates = ({ token, whiteListApplication }) => {
    const [more, setMore] = useState(true)

    const [randomizedTokens, setRandomizedTokens] = useState([]);


    // TODO добавить превью изображений

    const {
        data: collectionTokens,
    } = useGetFilteredTokensQuery(
        {
            page: 1,
            pageSize: 1000,
            collection_ids: [token ? token.collection.id : ''],
            paid: false
        },
        {
            skip: !token || !token.collection.id,
        },
    );

    useEffect(() => {
        if (collectionTokens && collectionTokens.results) {
            const randomized = [...collectionTokens.results];
            randomized.sort(() => Math.random() - 0.5);

            const filtered = randomized.map(el => {
                if (!el.paid && (el.id !== token.id)) {
                    return el;
                }
            }).filter(el => el !== undefined)

            setRandomizedTokens(filtered.slice(0, 10));
        }
    }, [collectionTokens]);



    return (
        <div className="token__card--wrap full">
            <div className="token__card--item">
                <div
                    onClick={() => setMore(prev => !prev)}
                    className="token__card--item--top"
                >
                                    <span className="token__card--item--top--title--inner">
                                        <img
                                            src="/assets/img/more.svg"
                                            alt="more"
                                            className="token__card--item--top--title--icon"
                                        />

                                        <p className="token__card--item--top--title">
                                            Сертификаты коллекции
                                        </p>
                                    </span>

                    <img
                        src="/assets/img/arrow-select.svg"
                        alt="arrow"
                        className={`token__card--item--open${
                            more ? ' active' : ''
                        }`}
                    />
                </div>

                {more && (
                    <>
                        <div className={styles.items_wrapper}>
                            {!randomizedTokens ||
                            !randomizedTokens.length ? (
                                <div className="collection__items--none">

                                </div>
                            ) : (
                                <div
                                    className={styles.items_container}
                                    style={{
                                        '--items-count': 10,
                                    }}
                                >
                                    {randomizedTokens
                                        .filter((ct) => (ct.id !== token.id) && !ct.paid)
                                        .map((t) => (
                                            <TokenItem
                                                key={t.id}
                                                token={t}
                                                // bookTokenInProccessing={
                                                //     bookTokenInProccessing
                                                // }
                                                // onBookTokenHandler={
                                                //     onBookTokenHandler
                                                // }
                                                isAvailableForBooking={
                                                    whiteListApplication &&
                                                    whiteListApplication.status ===
                                                    WHITE_LIST_APPLICATION_STATUSES.WHITE &&
                                                    !whiteListApplication.token_id
                                                }
                                            />
                                        ))}
                                </div>
                            )}
                        </div>

                        <div className="token__card--collection--view">
                            <a
                                href={`/collection/${token.collection.id}`}
                                className="token__card--collection--button"
                                style={{
                                    marginTop: '30px',
                                    width: '180px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                               Смотреть все
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>

    )
}
