import React from 'react';

const ArrowRightShort = ({ ...props }) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.46967 3.21967C8.76256 2.92678 9.23744 2.92678 9.53033 3.21967L14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033L9.53033 14.7803C9.23744 15.0732 8.76256 15.0732 8.46967 14.7803C8.17678 14.4874 8.17678 14.0126 8.46967 13.7197L13.1893 9L8.46967 4.28033C8.17678 3.98744 8.17678 3.51256 8.46967 3.21967Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ArrowRightShort;
