import { useEffect, useRef } from 'react'

export const useClickOut = (handleClickout) => {
    const ref = useRef(null)
    useEffect(() => {
        const handleClick = (event) => {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                handleClickout
            ) {
                handleClickout()
            }
        }
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [handleClickout])
    return ref
}
