import React from 'react';

import AboutItem from './AboutItem';

const AboutBlock = ({ data }) => {
    const {titleOrange, title, elements} = data;
    

    return (
        <div className="about__nft--block">
            <p className="about__subtitle"><span className="orange">{titleOrange}</span> {title}</p>

            <div className="about__history--content">
                {elements.map((data, id) => <AboutItem key={id} img={data.img} title={data.title} text={data.textElements} />)}
            </div>
        </div>
    );
};

export default AboutBlock;