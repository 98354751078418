import React from 'react';

const Certificate = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.6007 0H2.40004C1.07627 0 0 1.09305 0 2.43745V10.5625C0 11.9069 1.07627 13 2.40004 13H13.6C14.9237 13 16 11.9069 16 10.5625V2.43745C16.0007 1.09305 14.9237 0 13.6007 0ZM10.4724 11.3745H5.52834C5.18934 9.33778 3.60601 7.72984 1.60054 7.38548V5.61376C3.60601 5.26948 5.18927 3.66146 5.52834 1.62473H10.4732C10.8122 3.66146 12.3955 5.2694 14.401 5.61376V7.38548C12.3948 7.72976 10.8115 9.33778 10.4724 11.3745ZM11.2007 6.49961C11.2007 6.94826 10.8429 7.31235 10.4004 7.31235H5.60035C5.15858 7.31235 4.80008 6.94826 4.80008 6.49961C4.80008 6.05096 5.15858 5.68687 5.60035 5.68687H10.4004C10.8429 5.68687 11.2007 6.05096 11.2007 6.49961ZM9.60017 8.93706C9.60017 9.38647 9.24241 9.74981 8.7999 9.74981H7.20013C6.75837 9.74981 6.39987 9.38647 6.39987 8.93706C6.39987 8.48765 6.75837 8.12432 7.20013 8.12432H8.7999C9.24241 8.12432 9.60017 8.48765 9.60017 8.93706ZM6.40063 4.06216C6.40063 3.61351 6.75914 3.24942 7.2009 3.24942H8.80067C9.24318 3.24942 9.60094 3.61351 9.60094 4.06216C9.60094 4.51081 9.24318 4.8749 8.80067 4.8749H7.2009C6.75839 4.8749 6.40063 4.51081 6.40063 4.06216Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Certificate;
