import React, { memo } from 'react';
import Vk from '../../../../../icons/Vk';
import Telegram2 from '../../../../../icons/Telegram2';
import Instagram from '../../../../../icons/Instagram';
import styles from '../../../index.module.css';
import cn from 'classnames';
import base from '../../../../../styles/base.module.css';



export const TeamModal = memo(({employe}) => {
    return employe && (
            <div className={styles.modalTeamContent}>
                <p className={cn(base.title2, styles.modalTeamTitle)}>{employe.name}</p>

                <img src={employe.image} alt="team" className={styles.modalTeamImg} />

                <p className={styles.modalTeamRole}>{employe.role}</p>

                <div className={styles.modalTeamSocials}>
                    <a href={''} className={styles.teamSlideInfoSocialLink}>
                        <Vk />
                    </a>

                    <a href={''} className={styles.teamSlideInfoSocialLink}>
                        <Instagram />
                    </a> 

                    <a href={''} className={styles.teamSlideInfoSocialLink}>
                        <Telegram2 />
                    </a>
                </div>

                <div className={styles.modalTeamDesc}>
                    <p
                        style={{
                            color: 'rgba(229, 232, 235, .8)',
                            fontWeight: 300,
                            fontSize: '1.4rem',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {' '}
                        {employe.text}
                    </p>
                </div>
            </div>
        )
    
})
