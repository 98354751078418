import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { dataApi } from '../api/dataService';
import { handleApi } from '../api/handleService';

const pagesAdapter = createEntityAdapter({
    selectId: (page) => page.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const pagesSlice = createSlice({
    name: 'pages',
    initialState: pagesAdapter.getInitialState({
        isLoading: false,
        isPageCreationProccessing: false,
        error: null,
    }),
    reducers: {
        addPage: pagesAdapter.addOne,
        deletePageById: (state, payload) => {
            pagesAdapter.removeOne(state, payload.payload.id);
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // GET ALL PAGES LIST
        builder.addMatcher(handleApi.endpoints.getPages.matchFulfilled, (state, action) => {
            state.isLoading = false;
        
            // Если action.payload теперь объект, и данные находятся в action.payload.results
            if (action.payload.results) {
                pagesAdapter.upsertMany(state, action.payload.results);
            }
        });
        

        builder.addMatcher(dataApi.endpoints.getPages.matchPending, (state, action) => {
            state.error = null;
            state.isLoading = true;
        });

        builder.addMatcher(dataApi.endpoints.getPages.matchRejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        // CREATE PAGE
        builder.addMatcher(dataApi.endpoints.createPage.matchPending, (state, action) => {
            state.error = null;
            state.isPageCreationProccessing = true;
        });

        builder.addMatcher(dataApi.endpoints.createPage.matchFulfilled, (state, action) => {
            pagesAdapter.addOne(state, action.payload);
            state.isPageCreationProccessing = false;
        });

        builder.addMatcher(dataApi.endpoints.createPage.matchRejected, (state, action) => {
            state.error = action.payload;
            state.isPageCreationProccessing = false;
        });
        // UPDATE PAGE
        builder.addMatcher(dataApi.endpoints.updatePage.matchFulfilled, (state, action) => {
            pagesAdapter.updateOne(state, {
                id: action.payload.id,
                changes: action.payload,
            });
        });
    },
});

export const pagesSelectors = pagesAdapter.getSelectors((state) => state.pages);

export const { addPage, deletePageById, clearError } = pagesSlice.actions;

export default pagesSlice.reducer;
