import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

import FeedbackForm from './FeedbackForm';

const Rules = () => {
    return (
        <div className={styles.marginWrapper}>
            <img src="/assets/img/doc-bg.png" alt="bg" className={styles.docBg} />

            <div className={base.container}>
                <div className={styles.docWrapper}>
                    <div className={styles.breadcrumbs}>
                        <Link to="/" className={styles.breadcrumbsLink}>
                            Главная
                        </Link>

                        <p className={styles.breadcrumbsText}>Пользовательское соглашение</p>
                    </div>

                    <h2 className={styles.docTitle}>Пользовательское соглашение</h2>

                    <p className={styles.docText}>
                        Настоящим Пользовательским соглашением (далее – Соглашение) Администрация
                        Платформы CheckBrand предлагает любому дееспособному лицу, действующему
                        исключительно в своих интересах, в дальнейшем именуемому Пользователь,
                        использовать услуги, предоставляемые Платформой CheckBrand, который
                        расположен в сети Интернет по адресу{' '}
                        <a href="https://checkbrand.com">https://checkbrand.com</a>.
                    </p>

                    <p className={styles.docText}>
                        В соответствии с настоящим Соглашением Администрация Платформы предлагает
                        Пользователю услуги по использованию возможностей и функционала Платформы
                        CheckBrand, размещенного на сайте, на условиях, оговоренных в настоящем
                        Соглашении, а также других документов, которые регулируют такие отношения
                        между Администрацией Платформы и Пользователем.
                    </p>

                    <p className={styles.docText}>
                        Настоящее Соглашение признается офертой. В соответствии со статьей 438
                        Гражданского Кодекса Российской Федерации (ГК РФ), безусловным принятием
                        (акцептом) условий настоящей оферты Соглашения считается осуществление
                        Пользователем совершение любых действий, в отношении Платформы или с момента
                        начала использования Пользователем Платформы в той мере, в какой это
                        допустимо функциональными возможностями Платформы. Настоящее Соглашение,
                        заключаемое путем акцепта настоящей оферты, не требует двустороннего
                        подписания и действительно в электронном виде. Использование Платформы
                        Пользователем означает, что Пользователь принимает и обязуется соблюдать все
                        нижеприведенные условия Соглашения.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>1. Общие положения соглашения</p>

                    <p className={styles.docText}>
                        1.1. Настоящее Соглашение регулирует отношения, которые возникают между
                        Администрацией Платформы и Пользователями, в процессе использования
                        Пользователями функциональных возможностей Платформы.
                    </p>

                    <p className={styles.docText}>
                        1.2. Настоящее Соглашение разработано Администрацией Платформы и содержит в
                        себе базовые нормы и правила, на основании которых функционирует Платформа.
                    </p>

                    <p className={styles.docText}>
                        1.3. Действующая редакция настоящего Соглашения размещается по адресу{' '}
                        <a href="https://checkbrand.com/rules">https://checkbrand.com/rules</a>.
                    </p>

                    <p className={styles.docText}>
                        1.4. Администрация Платформы оставляет за собой право вносить изменения в
                        текст настоящего Соглашения, уведомляя об этом Пользователей путем
                        публикации новой редакции Соглашения в сети Интернет по адресу{' '}
                        <a href="https://checkbrand.com/rules">https://checkbrand.com/rules</a>.
                        Пользователь обязан регулярно отслеживать изменения, вносимые в Соглашение.
                        Если Пользователь не согласен с новой редакцией Соглашения, то Пользователь
                        обязан незамедлительно прекратить использование Платформы. В случае если
                        после вступления в действие новой редакции Соглашения Пользователь
                        продолжает использовать Платформу, то Пользователь тем самым подтверждает
                        свое полное согласие с новой редакцией Соглашения.
                    </p>

                    <p className={styles.docText}>
                        1.5. Пользователь обязан полностью ознакомиться с Соглашением до момента
                        начала использования функционала Платформы. Использование Платформы означает
                        полное и безоговорочное принятие Пользователем настоящего Соглашения в
                        соответствии с нормами действующего законодательства.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>2. Акцепт соглашения</p>

                    <p className={styles.docText}>
                        2.1. Для полноценного использования функциональных возможностей Платформы,
                        Пользователь должен произвести акцепт настоящего Соглашения.
                    </p>

                    <p className={styles.docText}>
                        2.2. Под Акцептом настоящего Соглашения понимается полное принятие и
                        согласие Пользователя с условиями настоящего Соглашения, а также другими
                        документами, которые регулируют функционирование Платформы и размещены на
                        его страницах.
                    </p>

                    <p className={styles.docText}>
                        2.3. С юридической точки зрения акцептом настоящего соглашения являются
                        юридические действия Пользователя, направленные на использования
                        функциональных возможностей Платформы.
                    </p>

                    <p className={styles.docText}>
                        2.4. Пользователю запрещается использовать функциональные возможности
                        Платформы без полного и безоговорочного согласия с условиями настоящего
                        Соглашения.
                    </p>

                    <p className={styles.docText}>
                        2.5. Все электронные документы, уведомления и волеизъявления, оформленные
                        или осуществленные дистанционно, посредством Платформы и в рамках настоящего
                        Соглашения, признаются совершенными в простой письменной форме надлежащим
                        образом.
                    </p>

                    <p className={styles.docText}>
                        2.6. Срок акцепта настоящего Соглашения не ограничен или устанавливается
                        персонально.
                    </p>

                    <p className={styles.docText}>
                        2.7. Акцепт настоящего Соглашения не может быть отозванным Пользователем.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        3. Правовой статус Администрации Платформы
                    </p>

                    <p className={styles.docText}>
                        3.1. Администрация Платформы действует от имени Платформы и владельца
                        Платформы, в рамках настоящего Соглашения и других документов, которые
                        регулируют функционирование Платформы.
                    </p>

                    <p className={styles.docText}>
                        3.2. Администрация Платформы осуществляет контроль за функционированием
                        Платформы, за его работоспособностью, а также за действиями Пользователей в
                        процессе использования ими функциональных возможностей Платформы.
                    </p>

                    <p className={styles.docText}>
                        3.3. Администрация Платформы оставляет за собой право ограничения доступа к
                        Платформе Пользователям, которые нарушают условия и требования настоящего
                        Соглашения, а также других документов, которые регулируют порядок
                        использования Платформы.
                    </p>

                    <p className={styles.docText}>3.4. Администрация Платформы имеет право:</p>

                    <p className={styles.docText}>
                        3.4.1. В любое время изменять оформление Платформы, его Контент, список
                        сервисов, изменять или дополнять используемые, или хранящиеся в Платформе
                        программное обеспечение и другие объекты, любые серверные приложения в любое
                        время с предварительным уведомлением или без такового.
                    </p>

                    <p className={styles.docText}>
                        3.4.2. При необходимости отправлять Пользователям по электронной почте и
                        другими доступными способами сообщения, касающиеся использования Платформы.
                    </p>

                    <p className={styles.docText}>
                        3.4.3. Оказывать Пользователю бесплатные услуги, порядок оказания которых
                        описываются в настоящем Соглашении, а также других документах, которые
                        регулируют взаимоотношения между Администрацией Платформы и Пользователями.
                    </p>

                    <p className={styles.docText}>
                        3.4.4. Запрашивать в любое время и в любой форме у Пользователя согласие на
                        обработку персональных данных в Платформе.
                    </p>

                    <p className={styles.docText}>
                        3.4.5. Импортировать и сохранять персональные данные, к которым был
                        предоставлен доступ Пользователем.
                    </p>

                    <p className={styles.docText}>
                        3.4.6. Устанавливать дополнительные ограничения на использование Платформы,
                        а также изменять такие ограничения в любое время.
                    </p>

                    <p className={styles.docText}>
                        3.4.7. Осуществлять иные действия с целью улучшения качества и удобства
                        использования Пользователями Платформы.
                    </p>

                    <p className={styles.docText}>3.5. Администрация Платформы обязуется:</p>

                    <p className={styles.docText}>
                        3.5.1. Предоставлять информацию и персональные данные, оставленные
                        Пользователями, третьим лицам и иным Пользователям с учетом положений
                        настоящего Соглашения и норм действующего законодательства.
                    </p>

                    <p className={styles.docText}>
                        3.5.2. Организовать и обеспечить надлежащее оказание услуг Пользователю, в
                        рамках условий настоящего Соглашения, а также других документов, которые
                        регулируют взаимоотношения между Пользователем и Администрацией Платформы.
                    </p>

                    <p className={styles.docText}>
                        3.5.3. Предоставлять устные и письменные консультации Пользователям по
                        дополнительным вопросам, которые могут возникнуть в процессе выполнения
                        настоящего Соглашения, а также других документов, которые регулируют
                        взаимоотношения между Пользователем и Администрацией Платформы.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        4. Правовой статус пользователя
                    </p>

                    <p className={styles.docText}>4.1. Пользователь имеет право:</p>

                    <p className={styles.docText}>
                        4.1.1. Пользоваться функциональными возможностями Платформы, которые
                        предусмотрены для такого статуса Пользователя.
                    </p>

                    <p className={styles.docText}>
                        4.1.2. Требовать от Администрации Платформы и других Пользователей
                        соблюдения условий настоящего Соглашения, а также других документов, которые
                        регулируют функционирование Платформы.
                    </p>

                    <p className={styles.docText}>
                        4.1.3. Направлять Администрации Платформы запросы по функционированию
                        Платформы.
                    </p>

                    <p className={styles.docText}>4.2. Пользователь обязуется:</p>

                    <p className={styles.docText}>
                        4.2.1. Соблюдать все условия настоящего Соглашения, а также других
                        документов, которые регулируют функционирование Платформы.
                    </p>

                    <p className={styles.docText}>
                        4.2.2. Не использовать услуги, предоставляемые Администрацией Платформы, в
                        противоправных целях или в целях, которые могут каким-либо образом нанести
                        ущерб Платформе и/или третьим лицам.
                    </p>

                    <p className={styles.docText}>
                        4.2.3. Не разглашать конфиденциальную информацию, которая стала ему известна
                        в результате взаимоотношений с Платформой.
                    </p>

                    <p className={styles.docText}>
                        4.2.4. Не производить запрещенных Соглашением действий на Платформе.
                    </p>

                    <p className={styles.docText}>
                        4.2.5. Не реже чем раз в 2 (два) месяца знакомиться с содержанием настоящего
                        Соглашения и иных правил, регламентирующих порядок предоставления услуг
                        Администрацией Платформы и порядка использования Платформы.
                    </p>

                    <p className={styles.docText}>
                        4.3. При использовании Платформы Пользователю запрещается:
                    </p>

                    <p className={styles.docText}>
                        4.3.1. Использовать Платформу любым способом, который может помешать
                        нормальному функционированию Платформы и его элементов.
                    </p>

                    <p className={styles.docText}>
                        4.3.2. Загружать, хранить, публиковать, распространять и предоставлять
                        доступ или иным образом использовать вирусы и другие вредоносные программы.
                    </p>

                    <p className={styles.docText}>
                        4.3.3. Осуществлять действия, направленные на дестабилизацию
                        функционирования Платформы, осуществлять попытки несанкционированного
                        доступа к управлению Платформой или его закрытым разделам, а также
                        осуществлять любые иные аналогичные действия.
                    </p>

                    <p className={styles.docText}>
                        4.3.4. Собирать и/или хранить персональные данные Пользователей для
                        коммерческих целей.
                    </p>

                    <p className={styles.docText}>
                        4.3.5. Использовать автоматизированные скрипты (программы) для сбора
                        информации и/или взаимодействия с Платформой.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>5. Разрешение споров</p>

                    <p className={styles.docText}>
                        5.1. Настоящее Соглашение регулируется и толкуется в соответствии с
                        законодательством Российской Федерации.
                    </p>

                    <p className={styles.docText}>
                        5.2. Все споры, возникшие в рамках исполнения настоящего соглашения и других
                        документов Платформы, решаются путем переговоров.
                    </p>

                    <p className={styles.docText}>
                        5.3. При невозможности урегулирования спора путем переговоров, спор
                        передается на рассмотрение судов любой юрисдикции по месту нахождения
                        владельца Платформы.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>6. Форс-мажор</p>

                    <p className={styles.docText}>
                        6.1. Администрация Платформы освобождается от ответственности за частичное
                        или полное неисполнение обязательств по настоящему Соглашению, если такое
                        неисполнение явилось следствием действия обстоятельств непреодолимой силы,
                        возникших после публикации настоящего Соглашения в результате действия
                        обстоятельств непреодолимой силы, которые стороны не могли предвидеть или
                        предотвратить.
                    </p>

                    <p className={styles.docText}>
                        6.2. К обстоятельствам непреодолимой силы стороны относят исключительно
                        следующие события, делающие исполнение соответствующей стороной обязательств
                        по договору невозможным: землетрясения, наводнения, иные стихийные бедствия,
                        пожары, ядерные и иные промышленные аварии, а также забастовки, военные
                        действия, гражданские беспорядки либо акты государственных органов,
                        препятствующие выполнению условий Соглашения. Все остальные препятствия
                        независимо от их природы или характеристик непреодолимой силой не считаются,
                        за исключением тех препятствий, которые специально будут признаны сторонами
                        как вызванные действием обстоятельств непреодолимой силы.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>7. Заключительные положения</p>

                    <p className={styles.docText}>
                        7.1. Соглашение заключено между Пользователем и Администрацией Платформы
                        относительно порядка использования Платформы, носит обязательный характер и
                        действует с момента акцепта пользователями на неопределенный срок.
                    </p>

                    <p className={styles.docText}>
                        7.2. Соглашение регулируется законодательством РФ. Вопросы, не
                        урегулированные Соглашением, подлежат разрешению в соответствии с
                        законодательством РФ.
                    </p>

                    <p className={styles.docText}>
                        7.3. Адрес электронной почты Администрации Платформы для рассмотрения
                        обращений Пользователей:{' '}
                        <a href="mailto:support@checkbrand.com">support@checkbrand.com</a>
                    </p>

                    <p className={styles.docText}>Дата публикации: 09.09.2024 г.</p>

                    <FeedbackForm />
                </div>
            </div>
        </div>
    );
};

export default Rules;
