import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generateQueryParams } from './utils';

const fetchRequest = (
    // type,
    url,
    query,
    accessToken,
    items,
    setItems,
    setCount,
    setPage,
    setFetching,
    setTotalCount,
) => {
    fetch(`${url}${query}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
            if (Number(res.status) >= 400) {
                return new Error('error');
            }
        })
        .then((result) => {
            setItems([...items, ...result.results]);
            setCount(result.count);
            setTotalCount(result.count);
            setPage((prev) => prev + 1);
        })
        .catch((err) => {
            // console.log(err);
        })
        .finally(() => {
            setFetching(false);
        });
};

// TABLE_URL, params, isResetList, setIsResetList, setCount
export const useDynamicPagination = (
    url,
    queryParams,
    isResetList,
    setIsResetList,
    setTotalCount,
) => {
    const accessToken = localStorage.getItem('access_token');
    const [count, setCount] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const filteredQueryDTO = useMemo(
        () =>
            queryParams.filter((param) => param.value !== '' && param.value !== String(undefined)),
        [queryParams],
    );

    const query = useMemo(
        () => generateQueryParams([...filteredQueryDTO, { title: 'page', value: page }]),
        [filteredQueryDTO, page],
    );
    const handleScrollPage = useCallback(
        (e) => {
            const isPaginationRequest =
                e.target.documentElement.scrollHeight -
                    (e.target.documentElement.scrollTop + window.innerHeight) <
                    100 && items.length < count;

            if (isPaginationRequest) {
                setFetching(true);
            }
        },
        [items, count],
    );

    useEffect(() => {
        document.addEventListener('scroll', handleScrollPage);
        return () => {
            document.removeEventListener('scroll', handleScrollPage);
        };
    }, []);

    useEffect(() => {
        if (isResetList) {
            setItems([]);
            setPage(1);
            setFetching(true);
            setIsResetList(false);
        }
    }, [isResetList]);

    useEffect(() => {
        if (fetching) {
            fetchRequest(
                // 'fetch',
                url,
                query,
                accessToken,
                items,
                setItems,
                setCount,
                setPage,
                setFetching,
                setTotalCount,
            );
        }
    }, [fetching, query, url]);

    return { items: items };
};
