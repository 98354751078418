import React from 'react';

const Share2 = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64284 9.36272C5.09568 10.1918 4.14709 10.7406 3.06807 10.7406C1.37461 10.7406 0 9.38706 0 7.71984C0 6.05262 1.37472 4.69912 3.06807 4.69912C4.14705 4.69912 5.09564 5.2479 5.64284 6.07696L11.7118 3.08975C11.6334 2.85732 11.5908 2.60809 11.5908 2.34964C11.5908 1.05236 12.6595 0 13.9772 0C15.295 0 16.3637 1.05225 16.3637 2.34964C16.3637 3.64704 15.295 4.69929 13.9772 4.69929C13.1659 4.69929 12.4483 4.29986 12.017 3.68983L5.94805 6.67791C6.06993 7.00264 6.13641 7.35339 6.13641 7.7201C6.13641 8.08681 6.06993 8.43754 5.94805 8.7623L12.017 11.7504C12.4483 11.1403 13.1659 10.7409 13.9772 10.7409C15.2949 10.7409 16.3637 11.7932 16.3637 13.0906C16.3637 14.388 15.295 15.4402 13.9772 15.4402C12.6595 15.4402 11.5908 14.388 11.5908 13.0906C11.5908 12.8321 11.6334 12.5829 11.7118 12.3505L5.64284 9.36272Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Share2;
