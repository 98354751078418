import React from 'react';

const Alcohol2 = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3542 0C10.3542 0 11.8023 0.111592 11.9788 0.457573C11.9788 0.457573 11.9788 3.32487 11.9788 4.36235C11.9788 5.40006 13.3314 6.29396 14.0897 6.82063C14.8479 7.34731 15.4957 8.33716 15.4957 8.33716L14.6584 19H7.3052L7.88045 11.6735C9.94764 11.6826 13.0454 11.6788 14.2837 11.6627L14.512 8.75551C12.9974 7.98067 7.7111 7.98067 6.19651 8.75551L6.27044 9.69797C5.91905 9.4414 5.44107 9.05527 5.44107 8.82905V8.01803C5.69519 7.68255 6.13437 7.15704 6.61859 6.82063C7.37681 6.29396 8.72946 5.40006 8.72946 4.36235C8.72946 3.32487 8.72946 0.457573 8.72946 0.457573C8.90619 0.111592 10.3542 0 10.3542 0ZM3.57255 5.79786C3.57255 5.79786 4.57866 5.87561 4.70133 6.11583C4.70133 6.11583 4.70133 8.10814 4.70133 8.82905C4.70133 9.54996 5.64137 10.1712 6.1681 10.5372C6.69506 10.9033 7.1451 11.5908 7.1451 11.5908L6.56338 19H0.581717L0 11.5908C0 11.5908 0.450034 10.9033 0.976768 10.5372C1.50373 10.1712 2.44354 9.54996 2.44354 8.82905C2.44354 8.10814 2.44354 6.11583 2.44354 6.11583C2.56644 5.87561 3.57255 5.79786 3.57255 5.79786ZM0.683599 11.8817L0.842081 13.9018C1.94938 13.916 5.19572 13.916 6.30302 13.9018L6.4615 11.8817C5.40895 11.3431 1.73614 11.3431 0.683599 11.8817Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Alcohol2;
