import React from 'react';

const ViewBig = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.96447 0H0.941759C0.413052 0 0 0.420028 0 0.920831V5.97732C0 6.49428 0.429574 6.89815 0.941759 6.89815H5.96447C6.49318 6.89815 6.90623 6.47812 6.90623 5.97732V0.920831C6.92275 0.420028 6.49318 0 5.96447 0Z"
                fill="currentColor"
            />
            <path
                d="M5.96447 8.72266H0.941759C0.413052 8.72266 0 9.14268 0 9.64349V14.4253C0 14.9423 0.429574 15.3462 0.941759 15.3462H5.96447C6.49318 15.3462 6.90623 14.9261 6.90623 14.4253V9.64349C6.92275 9.14268 6.49318 8.72266 5.96447 8.72266Z"
                fill="currentColor"
            />
            <path
                d="M14.7535 0H9.73082C9.20211 0 8.78906 0.420028 8.78906 0.920831V5.97732C8.78906 6.49428 9.21864 6.89815 9.73082 6.89815H14.7535C15.2822 6.89815 15.6953 6.47812 15.6953 5.97732V0.920831C15.6953 0.420028 15.2657 0 14.7535 0Z"
                fill="currentColor"
            />
            <path
                d="M14.7535 8.72266H9.73082C9.20211 8.72266 8.78906 9.14268 8.78906 9.64349V14.4253C8.78906 14.9423 9.21864 15.3462 9.73082 15.3462H14.7535C15.2822 15.3462 15.6953 14.9261 15.6953 14.4253V9.64349C15.6953 9.14268 15.2657 8.72266 14.7535 8.72266Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ViewBig;
