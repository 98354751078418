import React, {useState} from 'react';
import Input from '../../common/Input';

import css from './index.module.css';
import { CustomSelect } from '../../common/CustomSelect';

const currencyList = [
    {
        value: '1',
        name: 'Ruble (RUB)',
        icon: '/assets/img/rub.svg'
    },
    {
        value: '2',
        name: 'USDT (BEP-20)',
        icon: '/assets/img/tether.svg'
    },
    {
        value: '3',
        name: 'BNB',
        icon: '/assets/img/bnb.svg'
    },
    {
        value: '4',
        name: 'Percentage',
        icon: '/assets/img/percent.svg'
    },
];

const Administrator = () => {
    const [currency, setCurrency] = useState('1');

    const setCurrencyHandler = (value) => {
        setCurrency(value);
    };

    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">Administrator</h2>
                        <div className={css.content}>
                            <div className={css.contentGroup}>
                                <Input
                                    title="Administrator address"
                                    text="Enter the address of the new administrator"
                                    placeholder="Wallet address"
                                    withButton
                                    buttonTitle="Change address"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="Mint CBCOIN"
                                    text="Enter the address of the coin recipient"
                                    placeholder="Wallet address"
                                />

                                <Input
                                    text="Enter the amount of coins issued"
                                    placeholder="1000"
                                    withButton
                                    buttonTitle="Mint coins"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="CBCOIN / USDT"
                                    text="Enter the coin value"
                                    placeholder="1.0"
                                    withButton
                                    buttonTitle="Change cost"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="1 NFT / CBCOIN"
                                    text="Enter the cost of placing one NFT"
                                    placeholder="1.0"
                                    withButton
                                    buttonTitle="Change cost"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <div className={css.comInner}>
                                    <Input
                                        title="Platform comission"
                                        placeholder="1.0"
                                        typeItem="half"
                                    />

                                    <div className="admin__add--item half">
                                        <div className="admin__add--title--inner">
                                            <p className="admin__add--text">Specify the platform commission</p>
                                        </div>

                                        <CustomSelect
                                            optionsList={currencyList}
                                            value={currency}
                                            placeholder="%"
                                            onChange={setCurrencyHandler}
                                        />
                                    </div>
                                </div>

                                <button className="button control__add">
                                    Set commission
                                </button>
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="ERC-20 CBCOIN"
                                    text="Available coins"
                                    placeholder="2.299.999.999.000"
                                />

                                <Input
                                    text="Issued coins"
                                    placeholder="1.000"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Administrator;