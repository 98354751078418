import { createSlice } from '@reduxjs/toolkit'



const initialState = {
  page: 1,
  lastItemsCount: 20,
  tokens: [],
  tokensPage: 1,
  tokensLastItemsCount: 20,
  name: '',
  paid: false,
  userTokenReciever:null,
  filterStore:''
}

const tokensFactorySlice = createSlice({
  name: 'tokens',
  initialState: initialState,
  reducers: {
    tokensIncrementPage: (state) => {
      state.page += 1
    },
    tokensSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    tokensAddPageData: (state, action) => {
      state.tokens = [...state.tokens, ...action.payload]
    },
    tokensRemove: (state) => {
      state.tokens = []
      state.page = 1
      state.lastItemsCount = 20
    },
    changeSearchTokenName: (state, action) => {
      state.name = action.payload
    },
    changeTokenPaid: (state, action) => {
      state.paid = action.payload
    },

    setUserTokenTransferStatus: (state, action) => {
      state.userTokenReciever = action.payload
    },
    setFilterStoreStatus: (state, action) => {
      state.filterStore = action.payload
    },
    setTokens: (state,action) => {
      state.tokens = action.payload
    }
   
  },
})

export const {
  tokensSetLastItemsCount,
  tokensRemove,
  tokensAddPageData,
  tokensIncrementPage,
  changeSearchTokenName,
  changeTokenPaid,
  setUserTokenTransferStatus,
  setFilterStoreStatus,
  setTokens
} = tokensFactorySlice.actions

export const tokensFactoryReducer = tokensFactorySlice.reducer
