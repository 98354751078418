import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import FilterTable from '../../components/FilterTable';
import WhitelistItem from './FactorystoresItem';

const data = [
    {
        status: 'Factory',
        avatar: '/assets/img/avatar.png',
        user: {
            name: 'Factory name',
        },
        address: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        moderator: 'User name User name User name User name User name User name',
        description: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        access: 'Brand name name name Brand name name name Brand name name name',
        last: '5 days ago',
        action: true
    },
    {
        status: 'Store',
        avatar: '/assets/img/avatar.png',
        user: {
            name: 'Store name',
        },
        address: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        moderator: 'User name User name User name User name User name User name',
        description: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        access: 'Brand name name name Brand name name name Brand name name name',
        last: '5 days ago',
        action: true
    },
];

const sortList = [{ value: 'recent', name: 'Recent changes' }];

const sortList2 = [{ value: '90', name: 'Last 90 Days' }];

const Factorystores = () => {
    const [filter, setFilter] = React.useState('recent');
    const [filter2, setFilter2] = React.useState('90');
    const [filtersContent, setFiltersContent] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            name: 'Ready',
            active: false,
        },
        {
            name: 'Waiting',
            active: false,
        },
        {
            name: 'Stoped',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            name: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    return (
        <section className="admin__padding admins">
            <div className="container">
                <div className="admins__inner">
                    <h1 className="title">Factories and Stores</h1>

                    <FilterTable
                        sorts={[
                            {
                                optionsList: sortList2,
                                value: filter2,
                                placeholder: 'Select filter',
                                onChange: filterChange2,
                            },
                            {
                                optionsList: sortList,
                                value: filter,
                                placeholder: 'Select filter',
                                onChange: filterChange,
                            },
                        ]}
                        filters={[
                            {
                                title: "Status",
                                value: "2",
                                elements: filterData,
                                setData: setFilterData,
                                filters: filtersContent,
                                setFilters: setFiltersContent
                            },
                            {
                                title: "Character 1",
                                value: "3",
                                elements: filterData2,
                                setData: setFilterData2,
                                filters: filtersContent,
                                filter: true,
                                setFilters: setFiltersContent
                            }
                        ]}
                        button={
                            <Link
                                to="/admin/createfactorystores"
                                className="collection__get default__hover"
                            >
                                Create Factory / Store
                            </Link>
                        }
                        tokensCount="25 points"
                    >
                        <div className="stats__item--names whithdraw__names">
                            <div className="admins__item--item stats__item--name off700">
                                Status
                            </div>

                            <div className="admins__item--item stats__item--name">
                                Point
                            </div>

                            <div className="admins__item--item stats__item--name">
                                Address
                            </div>

                            <div className="admins__item--item stats__item--name off700 activeoff1200">
                                Moderator
                            </div>

                            <div className="admins__item--item stats__item--name off998 activeoff1600">
                                Description
                            </div>

                            <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                Access
                            </div>

                            <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                Date
                            </div>

                            <div className="admins__item--item stats__item--name">
                                Action
                            </div>
                        </div>

                        {data.map((data, id) => (
                            <WhitelistItem key={id} data={data} id={id + 1} />
                        ))}
                    </FilterTable>
                </div>
            </div>
        </section>
    );
}

export default Factorystores;