import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, InputLabel } from '@mui/material';
import { findRole } from './utils';
// import { Delete } from '@mui/icons-material';
const fetchColelctionById = (id, setCollectionsData) => {
    const accessToken = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/${id}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    })
        .then((res) => {
            return res.json();
        })
        .then((response) => {
            if (response.name) {
                setCollectionsData((prev) => [...prev, { name: response.name, id: response.id }]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

const getName = (id, variants, index) => {
    if (variants.length === 0) {
        return id;
    }

    if (variants.length > 0) {
        const foundedElementById = variants.find((collection) => collection.id === id);
        return foundedElementById ? foundedElementById.name : id;
    }
};
export const UsersAccess = memo(({ user, rolesDeleteList, handleRemoveAcces }) => {
    const [collectionsData, setCollectionsData] = useState([]);
    const getisDelete = useCallback(
        (access) => {
            return findRole(rolesDeleteList, access);
        },
        [rolesDeleteList],
    );

    useEffect(() => {
        if (collectionsData.length === 0) {
            user.access.forEach((access) => {
                if (access) {
                    fetchColelctionById(access.sub_resource_id, setCollectionsData);
                }
            });
        }
    }, [user.access, collectionsData]);
    return (
        user.access &&
        user.access.map((access, id) => {
            const isAlreadyDelete = getisDelete(access);
            return (
                <section className="userAccess__acess">
                    <InputLabel
                        sx={{
                            fontSize: '16px',
                            color: 'white',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '140px',
                        }}
                    >
                        {getName(access.sub_resource_id, collectionsData, id)}
                    </InputLabel>
                    <Button
                        color={isAlreadyDelete ? 'info' : 'error'}
                        sx={{ fontSize: '12px', maxWidth: '50px' }}
                        variant={isAlreadyDelete ? 'outlined' : 'contained'}
                        onClick={() =>
                            handleRemoveAcces(
                                {
                                    resource_id: access.resource_id,
                                    sub_resource_id: access.sub_resource_id,
                                    id:access.id
                                },
                                isAlreadyDelete,
                            )
                        }
                    >
                        {isAlreadyDelete ? 'Отмена' : 'Удалить'}
                    </Button>
                </section>
            );
        })
    );
});
