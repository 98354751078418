exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_name__3VToY {\n    color: hsla(210, 71%, 50%, 1);\n    font-size: 16px;\n}\n\n.styles_img__rIK85 {\n    width: 88px;\n    height: 88px;\n    border-radius: 44px;\n    margin-top: 12px;\n    object-fit: cover;\n}\n\n.styles_state__1H-bA {\n    margin-top: 28px;\n    color: hsla(42, 85%, 59%, 1);\n}\n\n.styles_text__1qXv7 {\n    color: white;\n    margin-top: 28px;\n    text-align: center;\n}", ""]);

// exports
exports.locals = {
	"name": "styles_name__3VToY",
	"img": "styles_img__rIK85",
	"state": "styles_state__1H-bA",
	"text": "styles_text__1qXv7"
};