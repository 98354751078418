import React, { useEffect, useState } from 'react';
import Modal from '../../../common/Modal';
import Input from '../../../common/Input';
import { emailRegex } from '../PayModal/PayModal';
import styles from '../../../components/WelcomeModal/modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { useChangeUserDataMutation } from '../../../redux/api/userService';
import { setInstagram, setWhatsapp, setTelegram as setTelegramState, initEmail } from '../../../redux/slices/auth';

//  Оформить возврат
export const IssueARefundModal = (
    {
        isReturn,
        setIsReturn,
        setIsReturnModal,
        setIsWithdrawalOfMoney,
        type,
        setIsConfirmEmailModal,
        setChangedEmail
    }) => {
    const dispatch = useDispatch()
    const emailValue = useSelector(state => state.auth.email)
    const telegramValue = useSelector(state => state.auth.telegram)
    const instagram = useSelector(state => state.auth.inst)
    const userName = useSelector(state => state.auth.username)
    const whatsapp = useSelector(state => state.auth.whatsapp)


    const [email, setEmail] = useState('')
    const [telegram, setTelegram] = useState('')
    const [phone, setPhone] = useState('')
    const [inst, setInst] = useState('')

    const [isSendData, setIsSendData] = useState(false)

    const [changeData] = useChangeUserDataMutation()

    const handleContinueClick = () => {
        setIsSendData(true)
        const body = {
            username: userName,
            instagram: inst,
            telegram: telegram,
            whatsapp: phone
        }

        if(email !== emailValue && emailRegex.test(email)) {
            body.email = email
        }
        if ((email === emailValue) && emailRegex.test(email) && telegram && phone.length === 15) {
            changeData({
                body: body
            }).unwrap().then(() => {
                type === 'return' ? setIsReturnModal(true) : setIsWithdrawalOfMoney(true)
                setIsReturn(false)
                if(email !== emailValue) {
                    setIsConfirmEmailModal(true)
                    dispatch(initEmail(email))
                    setChangedEmail(email)
                }
                dispatch(setInstagram(inst))
                dispatch(setTelegramState(telegram))
                dispatch(setWhatsapp(phone))
            })
        }
    }

    useEffect(() => {
        if(telegramValue) {
            setTelegram(telegramValue)
        }
    }, [telegramValue])

    useEffect(() => {
        if(emailValue) {
            setEmail(emailValue)
        }
    }, [emailValue])

    useEffect(() => {
        if(whatsapp) {
            setPhone(whatsapp)
        }
    }, [whatsapp])

    useEffect(() => {
        if(instagram) {
            setInst(instagram)
        }
    }, [instagram])

    return (
        <Modal
            active={isReturn}
            title={'Укажите контакты'}
            setActive={setIsReturn}
            onClose={() => {
                setIsReturn(false)
            }}
        >
            <p>Пожалуйста укажите свои контакты</p>
            <p
                style={{
                    marginTop: '10px'
                }}
            >
                для вывода/возврата денежных средств
            </p>
            <div
                style={{
                    marginTop: '42px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}
            >
                <Input
                    title=""
                    placeholder="Электронная почта*"
                    value={email}
                    setValue={setEmail}
                    isError={
                        isSendData
                            ? !emailRegex.test(email)
                            : email
                                ? !emailRegex.test(email)
                                : false
                    }
                />
                <Input
                    title=""
                    placeholder="Телеграм*"
                    value={telegram}
                    setValue={setTelegram}
                    isError={isSendData && !telegram}
                />
                <InputMask
                    maskChar={null}
                    mask="7\ 999 99 999 99"
                    placeholder={'Телефон*'}
                    className="input create__item--input"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    style={{
                        border: isSendData && !phone ? '1px solid red' : '1px solid transparent'
                    }}
                />
                <Input
                    title=""
                    placeholder="Инстаграм"
                    value={inst}
                    setValue={setInst}
                />
                <p className={styles.text}>*Обязательное поле</p>
            </div>
            <button
                className={`button token__card--content--price--buy ${styles.btn}`}
                onClick={handleContinueClick}
                style={{height: '52px', width: '100%'}}

            >
                Далее
            </button>
            <div  style={{marginTop: '44px', minWidth: '100%'}}>
                <Input
                    placeholder="Укажите свои контакты. Менеджер свяжется с вами и согласует вывод/возврат денежных средств. Деньги поступят в течении дня (макс. срок: до семи банковских дней)."
                    textarea
                    disabled={true}
                />
            </div>
        </Modal>
    )
}
