import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';

const RankingsSkeleton = () => {
    return (
        <div className="rankings">
            <div className="container">
                <div className="rankings__inner">
                    <Skeleton containerClassName="rankings__title--skeleton" className="skeleton__content" />

                    <div className="rankings__subtitle rankings__subtitle--inner--skeleton">
                        <Skeleton containerClassName="rankings__subtitle--skeleton" className="skeleton__content" />
                    </div>

                    <div className="rankings__filter--content">
                        <Skeleton containerClassName="rankings__filter--skeleton" className="skeleton__content" />
                    </div>

                    <div className="collection__content">
                        <div className="collection__content--preitems">
                            <div className="collection__content--info">
                                <Skeleton containerClassName="collection__content--info--skeleton" className="skeleton__content" />
                            </div>

                            <div className="collection__content--items">
                                <div className="stats__content stats__content--skeletons">
                                    {new Array(0, 0, 0).map((_, id) => <Skeleton containerClassName="stats__content--skeleton" className="skeleton__content" />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankingsSkeleton;
