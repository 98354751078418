import React from 'react';

const Share3 = ({ ...props }) => {
    return (
        <svg
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M22.6888 13.829C22.6888 16.9243 21.4416 19.7267 19.4251 21.7561C17.4088 23.7856 14.6234 25.0411 11.5495 25.0411C8.47428 25.0411 5.69005 23.7857 3.67385 21.7561C1.65752 19.7266 0.410156 16.9242 0.410156 13.829C0.410156 10.7337 1.6574 7.93126 3.67385 5.90188C5.69017 3.87238 8.47434 2.61686 11.5495 2.61686C11.8477 2.61686 12.0904 2.85992 12.0904 3.16131V5.50435C12.0904 5.80452 11.8489 6.04879 11.5495 6.04879C9.41484 6.04879 7.48308 6.92015 6.08476 8.32743C4.68782 9.7335 3.82091 11.6803 3.82091 13.8279C3.82091 15.9765 4.68661 17.9209 6.08476 19.3283C7.48169 20.7344 9.41459 21.607 11.5495 21.607C13.6844 21.607 15.6159 20.7356 17.0142 19.3283C18.4111 17.9223 19.2781 15.9767 19.2781 13.8279C19.2781 13.5277 19.5195 13.2834 19.819 13.2834H22.1468C22.445 13.2834 22.6877 13.5265 22.6877 13.8279L22.6888 13.829ZM20.0652 2.31166H23.0872V0.83995C23.0896 0.705055 23.1379 0.570157 23.2369 0.465652C23.4422 0.248117 23.7839 0.239611 24 0.447423C25.8002 2.17677 27.6862 3.90729 29.4406 5.67316C29.6459 5.89069 29.6375 6.23462 29.4225 6.44123L24.028 11.6218C23.929 11.7312 23.7865 11.7993 23.6295 11.7993C23.3313 11.7993 23.0886 11.5562 23.0886 11.2548V9.78312H20.6835C19.487 9.78312 18.3798 10.1003 17.4442 10.6836C16.5073 11.2682 15.7322 12.1237 15.1997 13.2017L13.8957 15.8377C13.8124 16.0297 13.6216 16.1646 13.3995 16.1646C13.1013 16.1646 12.8586 15.9215 12.8586 15.6201V9.57182C12.8586 7.57389 13.6675 5.75946 14.9739 4.44471C16.2791 3.13098 18.0818 2.31552 20.0677 2.31552L20.0652 2.31166Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Share3;
