import React from 'react';

const Pen = ({ ...props }) => {
    return (
        <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.87655 2.81966L9.08101 3.61524L7.38479 1.91898L8.18036 1.1234C8.34511 0.958809 8.61282 0.958926 8.77739 1.1234L9.87655 2.22261C10.0411 2.38721 10.0411 2.6551 9.87655 2.81966ZM1.03809 8.37807L0 11L2.6219 9.96195C2.46784 9.61269 2.25157 9.29773 1.97692 9.02313C1.70224 8.74847 1.3873 8.53213 1.03809 8.37807ZM1.38376 7.92003C1.74919 8.09724 2.08113 8.33336 2.37389 8.62615C2.66666 8.91887 2.90281 9.25086 3.07998 9.61629L8.68405 4.01218L6.98785 2.31592L1.38376 7.92003Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Pen;
