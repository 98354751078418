import React from 'react';

const Menu = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.16797 8.83073H5.83464C7.5013 8.83073 8.33464 7.9974 8.33464 6.33073V4.66406C8.33464 2.9974 7.5013 2.16406 5.83464 2.16406H4.16797C2.5013 2.16406 1.66797 2.9974 1.66797 4.66406V6.33073C1.66797 7.9974 2.5013 8.83073 4.16797 8.83073Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.168 8.83073H15.8346C17.5013 8.83073 18.3346 7.9974 18.3346 6.33073V4.66406C18.3346 2.9974 17.5013 2.16406 15.8346 2.16406H14.168C12.5013 2.16406 11.668 2.9974 11.668 4.66406V6.33073C11.668 7.9974 12.5013 8.83073 14.168 8.83073Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.168 18.8307H15.8346C17.5013 18.8307 18.3346 17.9974 18.3346 16.3307V14.6641C18.3346 12.9974 17.5013 12.1641 15.8346 12.1641H14.168C12.5013 12.1641 11.668 12.9974 11.668 14.6641V16.3307C11.668 17.9974 12.5013 18.8307 14.168 18.8307Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.16797 18.8307H5.83464C7.5013 18.8307 8.33464 17.9974 8.33464 16.3307V14.6641C8.33464 12.9974 7.5013 12.1641 5.83464 12.1641H4.16797C2.5013 12.1641 1.66797 12.9974 1.66797 14.6641V16.3307C1.66797 17.9974 2.5013 18.8307 4.16797 18.8307Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Menu;
