import React from "react";
import Modal from "../../../../common/Modal";
import styles from './styles.module.css'
import Input from "../../../../common/Input";
import {useSelector} from "react-redux";
import {useClickOnSocialMutation} from "../../../../redux/api/dataService";

export const OfferModal = (
  {
    isModalActive,
    setIsModalActive,
    item,
    setContactType,
    setIsUserContact,
    token,
    refetch,
    setIsUserThanksModalActive
  }
) => {
  const telegram = useSelector(state => state.auth.telegram)
  const whatsapp = useSelector(state => state.auth.whatsapp)

  const [clickOnSocial] = useClickOnSocialMutation()

  const handleClickOnSocial = ( type) => {
    setContactType(type)
    if(type === 'telegram' && !telegram ) {
      setIsUserContact(true)
      setIsModalActive(false)
    } else if(type === 'whatsapp' && !whatsapp ) {
      setIsUserContact(true)
      setIsModalActive(false)
    } else {
      clickOnSocial({
          body: {
            service: item.id,
            token: token.id
          }
        }
      )
        .unwrap()
        .then(() => {
          setIsUserThanksModalActive(true)
          refetch()
          setIsModalActive(false)
        })
    }
  }

  return (
    <Modal
      title={'Получение услуги'}
      active={isModalActive}
      setActive={setIsModalActive}
      onClose={() => setIsModalActive(false)}
      size={'medium'}
    >
      <p className={styles.name}>{item.company.name}</p>

      <img src={item.company.logo} className={styles.img}/>

      <p className={styles.state}>{item.certificate_type.toUpperCase()}</p>
      <p className={styles.text}>Выберите способ связи для согласования <br/> предоставления услуги</p>

      <button
        className={`button token__card--content--price--buy`}
        onClick={() => handleClickOnSocial('telegram')}
        style={{height: '52px', width: '100%'}}
        disabled={!item.manager_telegram}
      >
        Телеграм
      </button>
      <button
        className={`button token__card--content--price--buy`}
        onClick={() => {
          handleClickOnSocial('whatsapp');
        }}
        style={{height: '52px', width: '100%', background: 'hsla(125, 41%, 62%, 0.8)', marginTop: '12px'}}
        disabled={!item.manager_whatsapp}
      >
        Ватсап
      </button>

      <div  style={{marginTop: '44px', minWidth: '100%'}}>
        <Input
          placeholder="Выберите способ связи нажатием кнопки. Откроется соответствующее приложение. Отправьте встроенное сообщение. Менеджер свяжется с вами в течении минуты, что бы согласовать предоставление вам услуг.Так же вы всегда можете обратиться в службу заботы. Благодарим что вы с нами."
          textarea
          disabled={true}
        />
      </div>
    </Modal>
  )
}