exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".base_container__5yWHq {\n    width: 100%;\n    max-width: 1172px;\n    margin: 0 auto;\n}\n\n.base_marginWrapper__1wxo5 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    margin-top: -6.2rem;\n}\n\n.base_title2__Irdts{\n    font-size: 3.2rem;\n    font-weight: 700;\n    color: #E5E8EB;\n    text-transform: uppercase;\n    font-family: 'DrukWideCyr', sans-serif;\n    word-break: break-word;\n}\n\n@media (max-width: 1200px) {\n    .base_container__5yWHq{\n        max-width: 978px;\n    }\n}\n@media (max-width: 998px) {\n    .base_container__5yWHq{\n        max-width: 748px;\n    }\n\n    .base_title2__Irdts{\n        font-size: 2.8rem;\n    }\n}\n@media (max-width: 768px) {\n    .base_container__5yWHq{\n        max-width: 696px;\n    }\n}\n@media (max-width: 716px) {\n    .base_container__5yWHq{\n        max-width: 460px;\n    }\n\n    .base_title2__Irdts{\n        font-size: 2rem;\n    }\n}\n@media (max-width: 480px) {\n    .base_container__5yWHq{\n        max-width: 100%;\n        padding: 0rem 1.5rem;\n    }\n}\n\n.base_orangeButton__2CUOp{\n    border-radius: 50px;\n    background: #FF7327;\n}\n\n.base_whiteButton__1cXvD{\n    border: 1px solid rgba(255, 255, 255, .43);\n    border-radius: 50px;\n    position: relative;\n}\n\n.base_whiteButton__1cXvD:before{\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    display: block;\n    border-radius: 50px;\n    background: linear-gradient(-45deg, #2081E2, #2065E2);\n    opacity: 0;\n    transition: .2s linear;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: -1;\n}\n\n@media (any-hover: hover) {\n    .base_orangeButton__2CUOp:hover{\n        background: #F2994A;\n    }\n\n    .base_whiteButton__1cXvD:hover{\n        border: 1px solid rgba(255, 255, 255, .66);\n    }\n\n    .base_whiteButton__1cXvD:hover:before{\n        opacity: 1;\n    }\n}", ""]);

// exports
exports.locals = {
	"container": "base_container__5yWHq",
	"marginWrapper": "base_marginWrapper__1wxo5",
	"title2": "base_title2__Irdts",
	"orangeButton": "base_orangeButton__2CUOp",
	"whiteButton": "base_whiteButton__1cXvD"
};