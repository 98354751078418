import React from 'react';

const Youtube = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.5 2.125C19.25 1.25 18.625 0.625 17.75 0.375C16.25 2.23517e-07 9.87499 0 9.87499 0C9.87499 0 3.62501 2.23517e-07 2.00001 0.375C1.12501 0.625 0.499996 1.25 0.249996 2.125C-3.81842e-06 3.75 0 7 0 7C0 7 3.7998e-06 10.25 0.375004 11.875C0.625004 12.75 1.25 13.375 2.125 13.625C3.625 14 10 14 10 14C10 14 16.25 14 17.875 13.625C18.75 13.375 19.375 12.75 19.625 11.875C20 10.25 20 7 20 7C20 7 20 3.75 19.5 2.125ZM7.99999 10V4L13.25 7L7.99999 10Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Youtube;
