import React from 'react';

const Check5 = ({ ...props }) => {
    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.7487 4.58333L1.83203 5.5L4.58203 8.25L9.16536 3.66667L8.2487 2.75L4.58203 6.41667L2.7487 4.58333Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Check5;
