import React, { useEffect,useRef,useState } from 'react';
import CollectionItemEmpty from '../../../BrandsNew/components/CollectionItemEmpty';
import styles from '../../index.module.css';
import CollectionItem from '../../../BrandsNew/components/CollectionItem';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
import { useNavigate } from 'react-router-dom';
import { useGetFilteredCollectionsQuery } from '../../../../redux/api/handleService';
import { collectionsSlice } from '../../../../redux/slices/collections';

const CompanyBrands = () => {
    const navigate = useNavigate();
    const isRendered = useRef(true)
    const [collections,setCollections] = useState([])
    useEffect(() => {
        if (isRendered.current === true) {
            const access_token = localStorage.getItem('access_token');
            fetch(`${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/?page=1&page_size=8`, {
                method:'GET',
                headers: {
                    authorization: `Bearer ${access_token}`,
                },
            })
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return res.json();
                    }

                })
                .then((response) => {
                    if (response && response.results) {
                       setCollections(response.results)
                    }
                })
                .catch((err) => console.log(err));
                isRendered.current = false
        }
    },[isRendered]);

    return (
        <section className={styles.brands} id="brand">
            <div className={base.container}>
                <div className={styles.brandsInner}>
                    <h2 className={base.title2}>Бренды компаний</h2>

                    <p className={styles.brandsText}>успешно выпустившие сертификаты на рынок:</p>

                    <div className={styles.brandsContent}>
                        {/* {Array.from({ length: 8 }).map((_, index) =>
                            isLoadingCollection ? (
                                <CollectionItemEmpty key={index} />
                            ) : collections && collections.results && collections.results[index] ? (
                                <CollectionItem
                                    key={index}
                                    collection={collections.results[index]}
                                />
                            ) : (
                                <CollectionItemEmpty key={index} />
                            ),
                        )} */}
                        {collections &&
                            collections.length > 0 &&
                            collections.map((collection) => {
                                return (
                                    <CollectionItem key={collection.id} collection={collection} />
                                );
                            })}
                        <CollectionItemEmpty key={collections.length} />
                        <CollectionItemEmpty key={collections.length + 1} />
                    </div>

                    <div className={styles.brandsAll}>
                        <button
                            className={cn(base.orangeButton, styles.brandsAllButton)}
                            onClick={() => {
                                navigate(`/brandsnew/collections`);
                            }}
                        >
                            Смотреть все бренды
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CompanyBrands;
