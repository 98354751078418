import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 1,
  lastItemsCount: 20,
  items: [],
  itemsPage: 1,
  itemsLastItemsCount: 20,
  name: '',
}

const pagesFactorySlice = createSlice({
  name: 'pages',
  initialState: initialState,
  reducers: {
    pagesIncrementPage: (state) => {
      state.page += 1
    },
    pagesSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    pagesAddPageData: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    pagesRemove: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = 20
    },
    changeSearchPagesName: (state, action) => {
      state.name = action.payload
    },
    setItems: (state,action) => {
      state.items = action.payload
    }
  },
})

export const {
  changeSearchPagesName,
  pagesSetLastItemsCount,
  pagesRemove,
  pagesIncrementPage,
  pagesAddPageData,
  setItems
} = pagesFactorySlice.actions

export const pagesFactoryReducer = pagesFactorySlice.reducer
