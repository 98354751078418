import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import FilterTable from '../FilterTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TableNavigation } from '../TableNavigation/TableNavigation';
import { useLocation } from 'react-router-dom';
import {
    handleChangeCheckboxState,
    handleSearchValueBykey,
    handleSelectFilterOption,
    handleSelectTableMode,
} from './utils';
import { PAGE_FILTERS } from './utils';
import { setParentsList } from './tableSlice';
export const TableContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const nodesList = useSelector((state) => state.tableSlice.parentsList, shallowEqual);
    const [viewType, setViewType] = React.useState([
        { title: 'brands', state: false, src: '/assets/img/view3.svg' },
        { title: 'collections', state: false, src: '/assets/img/view1.svg' },
        { title: 'packs', state: false, src: '/assets/img/view2.svg' },
        { title: 'tokens', state: false, src: '/assets/img/view4.svg' },
    ]);
    // const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState({ isFullListMode: false, type: null, name: '' });
    const [refetch, setRefetch] = useState(false);
    const [pageFilters, setPageFilters] = useState([]);
    const [search, setSearch] = useState('');
    const [requestParams, setRequestParams] = useState([
        {
            title: 'page_size',
            value: 20,
        },
        {
            title: 'status_store',
            value: null,
        },
        {
            title: 'q',
            value: null,
        },
    ]);
    const [isResetList, setIsResetList] = useState(false);

    const handleChangeTableView = (checkbox) => {
        handleSelectTableMode(checkbox, navigate, dispatch, setParentsList);
    };
    useEffect(() => {
        if (currentPage.isFullListMode) {
            setViewType((prev) => {
                return prev.map((checkbox) => {
                    if (checkbox.title === currentPage.type) {
                        return { ...checkbox, state: true };
                    }
                    return { ...checkbox, state: false };
                });
            });
        }
    }, [currentPage]);

    const handleSetSearchValue = (e) => {
        if (e.length > 0) setSearch(e);
        else setSearch(null);
    };
    const handleSearch = useCallback(
        (e) => handleSearchValueBykey(e, search, setRequestParams, setIsResetList),
        [search],
    );
    const handleSelectFilter = useCallback(
        (checkbox) =>
            handleSelectFilterOption(checkbox, setRequestParams, setPageFilters, setIsResetList),
        [],
    );

    useEffect(() => {
        if (currentPage && currentPage.name === 'Категории') {
            dispatch(setParentsList([]));
        }
        setPageFilters(PAGE_FILTERS[currentPage.type]);
    }, [currentPage]);

    return (
        <div className="admins__inner">
            {nodesList && (
                <TableNavigation
                    linksList={nodesList}
                    currentPage={currentPage}
                    setCurrentPage={setViewType}
                />
            )}
            <FilterTable
                views={location.pathname !== '/admin/statistics2' ? true : false}
                viewTableType={'statistics2'}
                handleChangeView={handleChangeTableView}
                viewType={viewType}
                setViewType={setViewType}
                search={search}
                setSearch={handleSetSearchValue}
                handleRefresh={setRefetch}
                filters={pageFilters}
                filterOnChnage={handleSelectFilter}
                onEnterSearch={handleSearch}
                count={count}
            >
                <Outlet
                    context={[requestParams, isResetList, setIsResetList, setCount, setCurrentPage]}
                />
            </FilterTable>
        </div>
    );
};
