import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import CertificatesItemSkeleton from '../BrandsNew/components/CertificatesItemSkeleton';
import CertificatesItemMiniSkeleton from '../BrandsNew/components/CertificateItemMiniSkeleton';

const TokenNewSkeletonPage = () => {
    return (
        <section className={styles.token}>
            <div className={base.container}>
                <div className={styles.tokenInner}>
                    <div className={styles.tokenContent}>
                        <div className={styles.tokenContentWrapper}>
                            <div className={styles.tokenContentMobile}>
                                <Skeleton
                                    containerClassName={styles.tokenImgSkeletonWrapper}
                                    className={styles.skeletonBrs10}
                                />

                                <div className={cn(styles.tokenInfoNameInner, styles.mobile)}>
                                    <div className={styles.tokenInfoNameWrapper}>
                                        <Skeleton
                                            containerClassName={styles.tokenNameSkeletonWrapper}
                                            className={styles.skeletonBrs10}
                                        />

                                        <Skeleton
                                            containerClassName={styles.tokenTitleSkeletonWrapper}
                                            className={styles.skeletonBrs10}
                                        />

                                        <Skeleton
                                            containerClassName={styles.tokenOwnerSkeletonWrapper}
                                            className={styles.skeletonBrs10}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.collectionTagsSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>

                            <div className={cn(styles.tokenInfoWrapper, styles.tokenInfoWrapperSkeleton)}>
                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.tokenInfoContent}>
                            <div className={styles.tokenInfoNameInner}>
                                <div className={styles.tokenInfoNameWrapper}>
                                    <Skeleton
                                        containerClassName={styles.tokenNameSkeletonWrapper}
                                        className={styles.skeletonBrs10}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenTitleSkeletonWrapper}
                                        className={styles.skeletonBrs10}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenOwnerSkeletonWrapper}
                                        className={styles.skeletonBrs10}
                                    />
                                </div>

                                <Skeleton
                                    containerClassName={styles.collectionTagsSkeletonWrapper}
                                    className={styles.skeletonBrs15}
                                />
                            </div>

                            <div className={styles.tokenMainBlockInfo}>
                                <div className={styles.tokenMainAvail}>
                                    <Skeleton
                                        containerClassName={styles.tokenMainAvailIconImgSkeleton}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenMainAvailSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenMainBlockInfoContent}>
                                    <div className={styles.tokenMainBlockTextWrapper}>
                                        <Skeleton
                                            containerClassName={styles.tokenMainTextImgSkeleton}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.tokenMainTitleImgSkeleton}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.tokenMainButtonImgSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>

                            <div className={styles.tokenPartnersComing}>
                                <div className={styles.tokenPartnersComingInner}>
                                    <Skeleton
                                        containerClassName={styles.tokenPartnersImgSkeleton}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenPartnersTextSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>

                            <div className={cn(styles.tokenInfoWrapper, styles.tokenInfoWrapperSkeleton, styles.mobile)}>
                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenInfoItemSkeleton}>
                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemSquareSkeleton}
                                        className={styles.skeletonBrs6}
                                    />

                                    <Skeleton
                                        containerClassName={styles.tokenInfoItemLineSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>

                            <div className={styles.tokenCollectionBlock}>
                                <div className={styles.tokenCollectionTitleInner}>
                                    <div className={styles.tokenMainAvail}>
                                        <Skeleton
                                            containerClassName={styles.tokenInfoItemSquareSkeleton}
                                            className={styles.skeletonBrs6}
                                        />

                                        <Skeleton
                                            containerClassName={styles.tokenInfoItemLineSkeleton}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.tokenCollectionTagsSkeleton}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.tokenCollectionsMini}>
                                    <CertificatesItemMiniSkeleton />
                                    <CertificatesItemMiniSkeleton />
                                    <CertificatesItemMiniSkeleton />
                                    <CertificatesItemMiniSkeleton />
                                    <CertificatesItemMiniSkeleton />
                                    <CertificatesItemMiniSkeleton />
                                </div>

                                <Skeleton
                                    containerClassName={styles.tokenCollectionButtonSkeleton}
                                    className={styles.skeletonBrs15}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TokenNewSkeletonPage;
