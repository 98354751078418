import React from 'react';
import Joi from '@hapi/joi';
import { useForm } from 'react-hook-form';
import { Form } from '../../common/Form';
import { TextField } from '../../common/TextField';
import { useValidationResolver } from '../../hooks/useValidationResolver';

import './index.css';
import CrossIcon from '../../icons/CrossIcon';

const schema = Joi.object({
    email: Joi.string().required(),
    discord_name: Joi.string(),
});

const PersonModal = (props) => {
    const { onClose, onSubmit, whiteListApplicationData, collection, isLoading } = props;

    const { validationResolver } = useValidationResolver(schema);

    const {
        register,
        handleSubmit: onSubmitForm,
        formState: { errors: formErrors },
    } = useForm({
        validationResolver,
        defaultValues: whiteListApplicationData,
        mode: 'onSubmit',
    });

    const handleSubmit = onSubmitForm((data) => {
        onSubmit(data);
    });

    return (
        <>
            <div className="modal__content">
                <div className="modal__content--top">
                    <h2 className="modal__title">Get on Whitelist</h2>
                    <CrossIcon className="modal__close" onClick={onClose} />
                </div>

                <div className="modal__content--bottom">
                    <p className="blue">{collection.name}</p>

                    <p className="create__item--text">
                        To get on Whitelist specify data for feedback
                    </p>
                    <Form onSubmit={handleSubmit} className="whitelist_form">
                        <div className="modal__inputs">
                            <TextField
                                placeholder="Email"
                                inputClassesProps={{
                                    input: 'input modal__input',
                                }}
                                name="email"
                                inputProps={{ ...register('email', { required: true }) }}
                                error={formErrors && formErrors['email']}
                            />

                            <TextField
                                placeholder="Discord name"
                                name="discord_name"
                                inputClassesProps={{
                                    input: 'input modal__input',
                                }}
                                inputProps={{ ...register('discord_name', { required: true }) }}
                                error={formErrors && formErrors['discord_name']}
                            />
                        </div>
                        <div className="modal__buttons">
                            {isLoading ? (
                                <button
                                    className="button modal__button modal__default--button"
                                    disabled
                                >
                                    Loading...
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="button modal__button modal__default--button"
                                >
                                    Confirm
                                </button>
                            )}
                        </div>
                    </Form>

                    <div class="modal__info">
                        <div class="modal__info--block">
                            After confirmation, the information will be sent to the moderator for
                            verification. If verified successfully, you will receive an email with
                            further instructions.
                            <br />
                            Whitelist cannot be sold or transferred to another person. It will be
                            attached to your wallet. Only you will be able to mint NFT of this
                            collection.
                            <br />
                            A whitelist gives the right to mint one token. No more than one
                            whitelist is possible in one collection during a minting.
                            <br />
                            By clicking Confirm you confirm your agreement to the processing of your
                            data.
                            <br />
                            By participating in the minting, you are assuming an obligation to
                            transfer the intellectual property rights to the information contained
                            in the NFT description belonging to you as the author of the story, in
                            case you sell the NFT containing such information in favor of the new
                            owner of the NFT.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(PersonModal);
