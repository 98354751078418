import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteEntityDialog from '../../../components/DeleteEntityDialog/DeleteEntityDialog';
import { DIALOG_TYPES } from '../../../components/WlModals/const';
import WLCreationDialog from '../../../components/WlModals/WLCreationDialog';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { onClose, onOpen } from '../../../redux/dialogs/aplyToWhitelistDialog';
import {
    onClose as onDeletationDialogClose,
    onOpen as onDeletationDialogOpen,
} from '../../../redux/dialogs/deleteEntityDialog';
import {
    useBookTokenMutation,
    useGetCollectionWhiteListQuery,
    useHideWhiteListRequestMutation,
    usePatchApplicationMutation,
} from '../../../redux/api/ugcService';
import { CollectionContext } from './CollectionContext';
import { NotificationContext } from '../../../context/NotificationContext';
import { normilizeError } from '../../../utils/http/normilizeError';

const DEFAULT_STATE = { whiteListApplication: null };

const WhiteListContext = React.createContext({});

const DIALOG_TYPES_ARR = Object.values(DIALOG_TYPES);

const WhiteListContextProvider = (props) => {
    const { children } = props;

    const createWLDialog = useSelector((state) => state.aplyToWhitelistDialog);
    const { isOpen, id: deletedWhiteListId } = useSelector((state) => state.deleteEntityDialog);

    const authInfo = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const {
        state: { collection },
    } = useContext(CollectionContext);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const [state, setState] = useState(DEFAULT_STATE);

    // const {
    //     data: whiteListApplicationData,
    //     isLoading: isWhitelistApplicationLoading,
    //     isSuccess: isWhitelistApplicationSuccess,
    //     error: whitelistApplicationError,
    //     refetch: refetchWhiteListApplication,
    // } = useGetCollectionWhiteListQuery(
    //     { id: collection.id },
    //     { skip: !collection || !collection.id },
    // );

    const [
        bookToken,
        { data: tokenBookedData, isLoading: bookTokenIsLoading, reset: resetBookToken },
    ] = useBookTokenMutation();

    const [
        cancelBookedWhiteListRequest,
        {
            data: cancelBookedApplicationSucciededData,
            error: cancelBookedWhiteListRequestError,
            reset: resetCancelBookedWhiteListRequest,
        },
    ] = usePatchApplicationMutation();

    const [
        hideWhiteListRequest,
        {
            isSuccess: isWhiteListHidden,
            isLoading: isHideWhiteListProccessing,
            reset: resetHideWhiteListRequest,
        },
    ] = useHideWhiteListRequestMutation();

    const closeCreationDialogHandler = useCallback(() => {
        dispatch(onClose());
    }, []);

    const closeDeletationDialogHandler = useCallback(() => {
        dispatch(onDeletationDialogClose());
    }, []);

    const onCancelHandler = useCallback(() => {
        if (state.whiteListApplication && state.whiteListApplication.id) {
            dispatch(
                onDeletationDialogOpen({
                    id: state.whiteListApplication.id,
                    onDelete: onCancel,
                    title: 'Are you sure you want cancel booking?',
                }),
            );
        }
    }, [state.whiteListApplication]);

    const onDeleteWhiteListRequestHandler = useCallback(() => {
        if (state.whiteListApplication && state.whiteListApplication.id) {
            dispatch(
                onDeletationDialogOpen({
                    id: state.whiteListApplication.id,
                    onDelete: onDeleteHandler,
                    title: 'Are you sure you want to delete WhiteList request?',
                }),
            );
        }
    }, [state.whiteListApplication]);

    const onGetToWhitelistHandler = useCallback(() => {
        if (!authInfo.isAuth) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Authorize through your wallet to get items to whitelist',
            });

            return;
        }

        dispatch(onOpen(collection.id));
    }, [authInfo.isAuth, collection.id]);

    const onDeleteHandler = useCallback((id) => {
        hideWhiteListRequest({ id });
    }, []);

    const onBookTokenHandler = useCallback(
        (tokenId) => {
            if (state.whiteListApplication) {
                bookToken({ id: state.whiteListApplication.id, tokenId });
            }
        },
        [state.whiteListApplication],
    );

    const onCancel = useCallback((id) => {
        cancelBookedWhiteListRequest({
            id,
            data: { token_none: true },
        });
    }, []);

    // useEffect(() => {
    //     if (isWhitelistApplicationSuccess && whiteListApplicationData) {
    //         setState((p) => ({
    //             ...p,
    //             whiteListApplication: whiteListApplicationData,
    //         }));
    //     }
    // }, [whiteListApplicationData, isWhitelistApplicationSuccess]);

    useEffect(() => {
        if (tokenBookedData) {
            setState((p) => ({
                ...p,
                whiteListApplication: tokenBookedData,
            }));
        }
    }, [tokenBookedData]);

    useEffect(() => {
        if (cancelBookedApplicationSucciededData && deletedWhiteListId) {
            setState((p) => ({ ...p, whiteListApplication: cancelBookedApplicationSucciededData }));

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application booking was canceled successfully',
            });

            closeDeletationDialogHandler();
            resetCancelBookedWhiteListRequest();
        }
    }, [cancelBookedApplicationSucciededData, deletedWhiteListId]);

    useEffect(() => {
        if (cancelBookedWhiteListRequestError && deletedWhiteListId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(cancelBookedWhiteListRequestError),
            });
            closeDeletationDialogHandler();
        }
    }, [cancelBookedWhiteListRequestError, deletedWhiteListId]);

    useEffect(() => {
        if (isWhiteListHidden) {
            closeDeletationDialogHandler();
            // refetchWhiteListApplication();
        }
    }, [isWhiteListHidden]);

    useEffect(
        () => () => {
            resetHideWhiteListRequest();
            resetCancelBookedWhiteListRequest();
            resetBookToken();
        },
        [],
    );

    const actions = {
        // refetchWhiteListApplication,
        hideWhiteListRequest,
        bookToken,
        cancelBookedWhiteListRequest,
        onCancelHandler,
        onDeleteWhiteListRequestHandler,
        onGetToWhitelistHandler,
        onBookTokenHandler,
    };

    return (
        <WhiteListContext.Provider
            value={{
                state,
                actions,

                // isWhitelistApplicationLoading,
                bookTokenIsLoading,
            }}
        >
            {children}
            {isOpen && (
                <DeleteEntityDialog
                    open={isOpen}
                    isDeletationProccessing={isHideWhiteListProccessing}
                    onClose={closeDeletationDialogHandler}
                    // onDeleteSuccessfully={refetchWhiteListApplication}
                    onDelete={onDeleteHandler}
                    title={'Are you sure you want to delete WhiteList request?'}
                />
            )}
            {collection && (
                <WLCreationDialog
                    open={createWLDialog.isOpen}
                    dialogType={
                        DIALOG_TYPES_ARR.includes(collection.application_form)
                            ? collection.application_form
                            : DIALOG_TYPES.PERSONS
                    }
                    collection={collection}
                    // whiteListApplicationData={
                    //     whitelistApplicationError ? null : whiteListApplicationData
                    // }
                    collectionId={collection.id}
                    onClose={closeCreationDialogHandler}
                    // onCreateSuccessfully={refetchWhiteListApplication}
                />
            )}
        </WhiteListContext.Provider>
    );
};

export { WhiteListContext, WhiteListContextProvider };
