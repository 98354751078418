exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PartnerOffer_wpapper__kagdo {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.PartnerOffer_wrapper__text__1vqjf {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    margin-top: 20px;\n}\n\n\n.PartnerOffer_text__dTC4K {\n    color: white;\n    font-size: 12px;\n    font-weight: 600;\n}\n\n.PartnerOffer_offersContainer__3MkYB {\n    max-height: 555px;\n    overflow-x: auto;\n}\n\n@media (max-width: 994px) {\n    .PartnerOffer_offersContainer__3MkYB {\n        max-height: 430px;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"wpapper": "PartnerOffer_wpapper__kagdo",
	"wrapper__text": "PartnerOffer_wrapper__text__1vqjf",
	"text": "PartnerOffer_text__dTC4K",
	"offersContainer": "PartnerOffer_offersContainer__3MkYB"
};