import React from 'react';
import MuiDialog from '@mui/material/Dialog';

import './index.css';

const Dialog = ({
    visible,
    title,
    children,
    classes = {},
    disabelDefaultStyles = false,
    onClose,
}) => {
    return (
        <MuiDialog
            open={visible}
            classes={{
                root: `${disabelDefaultStyles && 'root-dialog'} ${
                    classes.root ? classes.root : ''
                }`,
                paper: `${!disabelDefaultStyles && 'paper-dialog'} ${
                    classes.paper ? classes.paper : ''
                }`,
            }}
            onClose={onClose}
        >
            {title && (
                <div className="title-dialog-container">
                    <h2 className="title-dialog">{title}</h2>

                    <img
                        src="/assets/img/cross2.svg"
                        alt="Cross"
                        className="modal__close"
                        onClick={onClose}
                    />
                </div>
            )}

            <div
                className={`${disabelDefaultStyles && 'dialog__inner'} ${
                    classes.dialogInner ? classes.dialogInner : ''
                }`}
            >
                {children}
            </div>
        </MuiDialog>
    );
};

export default React.memo(Dialog);
