import React, { useCallback, useContext, useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { cnb } from 'cnbuilder';
import Skeleton from 'react-loading-skeleton';
import { dataApi } from '../../redux/api/dataService';
import RightShortFilledArrowIcon from '../../icons/RightShortFilledArrowIcon';
import { COLLECTION_PAGE } from '../../const/http/CLIENT_URLS';
import { S3Context } from '../../context/S3Context';

import './index.css';

const ImgBlockItem = ({ path, name, id, collectionsCount, alt = 'img', shouldBeSkeleton }) => {
    const {
        actions: { getImageBacketPath },
    } = useContext(S3Context);

    console.log(path, name, id, collectionsCount, alt, shouldBeSkeleton)


    const [
        getFirstCollection,
        { data: firstCollectionArray },
    ] = dataApi.endpoints.getFilteredCollection.useLazyQuery();

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const cardClickHandler = useCallback(async () => {
        getFirstCollection({
            accountId: id,
            page: 1,
            pageSize: 1,
        });
    }, [id]);

    useEffect(() => {
        if (firstCollectionArray && firstCollectionArray.results.length) {
            const collection = firstCollectionArray.results[0];

            window.open(COLLECTION_PAGE({ id: collection.id }), '_self');
        }
    }, [firstCollectionArray]);

    if (shouldBeSkeleton) {
        return (
            <div key={id} className="imgblock__item">
                <div className="imgblock__item--inner">
                    <Skeleton
                        containerClassName="imgblock__item--skeleton"
                        className="skeleton__content"
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            onClick={cardClickHandler}
            className={cnb('imgblock__item', {
                ['disabled-link']: collectionsCount === 0,
            })}
        >
            {!isImageLoaded && (
                <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    className="skeleton"
                    animation="wave"
                />
            )}
            <div className="collection__item--chain">
                <img
                    src="/assets/img/eth-black.svg"
                    alt="eth"
                    className="collection__item--chain--icon"
                />
            </div>

            <span className="imgblock__item--inner">
                <LazyLoadImage
                    effect="blur"
                    alt={alt}
                    onLoad={() => {
                        setIsImageLoaded(true);
                    }}
                    src={getImageBacketPath(path)}
                    className="imgblock__item--img"
                />
            </span>

            <div className="imgblock__item--choose">
                {name}
                <RightShortFilledArrowIcon className="imgblock__item--choose--icon" />
            </div>
        </div>
    );
};

export default React.memo(ImgBlockItem);
