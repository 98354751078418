import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import CenteredContainer from '../../common/CenteredContainer';
import Loader from '../../common/Loader';
import { TokenCommonFieldsForm } from '../../common/TokenCommonFieldsForm';
import UploadManyTokensForm from '../../common/UploadManyTokensForm/UploadManyTokensForm';

import './index.css';
import { useGetPacksListFilteredQuery } from '../../redux/api/handleService';
import { NotificationContext } from '../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { useGetCollectionsQuery } from '../../redux/api/dataService';
import { useCheckValidationErrors } from '../../hooks/useCheckValidationErrors';
import { useSelector } from 'react-redux';

const CreateItem = () => {
    const {errors, handlerErrors, handelClearErrors} = useCheckValidationErrors()

    const { data: collections, isLoading: isCollectionsLoading } = useGetCollectionsQuery({
        page: 1,
        pageSize: 1000,
    });

    const { data: packs, isLoading: isPacksLoading } = useGetPacksListFilteredQuery({
        page: 1,
        pageSize: 1000,
    });

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const walletAddress = useSelector(state => state.auth.walletAddress)

    const [packId, setPackId] = useState('');
    const [tokenCommonName, setTokenCommonName] = useState('Unique card #');
    const [numbering, setNumbering] = useState('1');
    const [tokenPrice, setTokenPrice] = useState(9900);
    const [tokenIdForPayment, setTokenIdForPayment] = useState('');
    const [investorRoyalty, setInvestorRoyalty] = useState(4.5);
    const [creatorRoyalty, setCreatorRoyalty] = useState(0.5);
    const [isTokenNameEqualFileName, setIsTokenNameEqualFileName] = useState(false);
    const [isAuction, setIsAuction] = useState(false);
    const [isNoPrice, setIsNoPrice] = useState(false);
    const [properties, setProperties] = useState([]);
    const [description, setDescriprion] = useState('');
    const [levels, setLevels] = useState([]);
    const [stats, setStats] = useState([]);
    const [opensea, setOpensea] = useState('');
    const [checkbrandcom, setCheckbrandcom] = useState('');
    const [creatorRoyaltyDestribution, setCreatorRoyaltyDestribution] = useState([{
        percentage: '100',
        wallet: walletAddress,
    }]);
    const [incomeRoyaltyDestribution, setIncomeRoyaltyDestribution] = useState([{
        percentage: '100',
        wallet: walletAddress,
    }]);
    const [collectionId, setCollectionId] = useState('');
    const [unlockable, setUnlockable] = useState(false);
    const [unlockableContent, setUnlockableContent] = useState('');

    const [isTokenUploadStarted, setIsTokenUploadStarted] = useState(false);
    const [isSendData, setIsSendData] = useState(false)

    const tokensDataToUpload = useMemo(() => {
        let status_price = 'price';

        if (isAuction) {
            status_price = 'auction';
        }

        if (isNoPrice) {
            status_price = 'no_price';
        }

        return {
            pack: packId,
            currency_token: tokenIdForPayment,
            status_price,
            investor_royalty: Number(investorRoyalty),
            creator_royalty: Number(creatorRoyalty),
            description,
            unlockable: unlockable,
            unlockable_content: unlockableContent,
            income_distribution: incomeRoyaltyDestribution.map((el) => ({
                wallet: el.wallet,
                percent: Number(el.percentage),
            })),
            creator_royalty_distribution: creatorRoyaltyDestribution.map((el) => ({
                wallet: el.wallet,
                percent: Number(el.percentage),
            })),
            opensea: opensea,
            checkbrandcom: checkbrandcom,
            properties: properties.map((p) => {
                return {
                    name: p.name,
                    type: p.type,
                };
            }),
        };
    }, [
        isAuction,
        isNoPrice,
        packId,
        tokenIdForPayment,
        investorRoyalty,
        creatorRoyalty,
        description,
        unlockable,
        unlockableContent,
        incomeRoyaltyDestribution,
        creatorRoyaltyDestribution,
        opensea,
        checkbrandcom,
        properties,
    ]);

    const selectedCollection = useMemo(() => {
        if (!collectionId || !collections || !collections.results) {
            return null;
        }

        return collections.results.find((c) => c.id === collectionId);
    }, [collections, collectionId]);

    const handleClose = () => {
        setPackId('')
        setTokenCommonName('Unique card #')
        setNumbering('1')
        setTokenPrice(9900)
        setTokenIdForPayment('')
        setInvestorRoyalty(4.5)
        setCreatorRoyalty(0.5)
        setIsTokenNameEqualFileName(false)
        setIsAuction(false)
        setIsNoPrice(false)
        setProperties([])
        setDescriprion('')
        setLevels([])
        setStats([])
        setOpensea('')
        setCheckbrandcom('')
        setCreatorRoyaltyDestribution([{
            percentage: '100',
            wallet: walletAddress,
        }])
        setIncomeRoyaltyDestribution([{
            percentage: '100',
            wallet: walletAddress,
        }])
        setCollectionId('')
        setUnlockable(false)
        setIsTokenUploadStarted(false)
        setUnlockableContent('')
        setIsSendData(false)
    }

    const checkIsAbleToUpload = useCallback(() => {
        setIsSendData(true)
        if (
            !collectionId ||
            !tokenPrice ||
            !tokenIdForPayment ||
            !description ||
            !investorRoyalty ||
            !creatorRoyalty
        ) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Fill all required fields',
            });

            return false;
        }

        if (
            Number(selectedCollection.percentage_fee) !==
            Number(investorRoyalty) + Number(creatorRoyalty)
        ) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text:
                    'The sum of investor and creator royalty should be equal to collection percentage fee',
            });
            return false;
        }

        const incomeSumRoyaltyPerc = incomeRoyaltyDestribution.reduce(
            (a, c) => a + Number(c.percentage),
            0,
        );

        const creatorSumRoyaltyPerc = creatorRoyaltyDestribution.reduce(
            (a, c) => a + Number(c.percentage),
            0,
        );

        if (incomeSumRoyaltyPerc < 100) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'The total royalties of the income must be equal to 100%',
            });

            return false;
        }

        if (creatorSumRoyaltyPerc < 100) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'The total royalties of the creators must be equal to 100%',
            });

            return false;
        }

        return true;
    }, [
        incomeRoyaltyDestribution,
        creatorRoyaltyDestribution,
        collectionId,
        tokenPrice,
        tokenIdForPayment,
        description,
        investorRoyalty,
        creatorRoyalty,
    ]);

    useEffect(() => {
        if(selectedCollection && selectedCollection.percentage_fee) {
            const result = Math.round((Number(selectedCollection.percentage_fee) - Number(creatorRoyalty) + Number.EPSILON) * 100) / 100;
            setInvestorRoyalty(result)
        }
    }, [creatorRoyalty])

    useEffect(() => {
        if(selectedCollection && selectedCollection.percentage_fee) {
            const result = Math.round((Number(selectedCollection.percentage_fee) - Number(investorRoyalty) + Number.EPSILON) * 100) / 100;
            setCreatorRoyalty(result)
        }
    }, [investorRoyalty])

    const onPackIdChangeHandler = useCallback((value) => {
        setPackId(value);
    }, []);

    if (isPacksLoading) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }
    return (
        <>
            <div className="default__padding createpage">
                <div className="container">
                    <div className="createpage__inner">
                        <div className="createpage__content--wrap">
                            <h2 className="title left">Create items</h2>

                            <div className="create__content">
                                <TokenCommonFieldsForm
                                    isTokenUploadStarted={isTokenUploadStarted}
                                    collections={collections}
                                    tokenCommonName={tokenCommonName}
                                    setTokenCommonName={setTokenCommonName}
                                    numbering={numbering}
                                    setNumbering={setNumbering}
                                    tokenPrice={tokenPrice}
                                    setTokenPrice={setTokenPrice}
                                    tokenIdForPayment={tokenIdForPayment}
                                    setTokenIdForPayment={setTokenIdForPayment}
                                    investorRoyalty={investorRoyalty}
                                    setInvestorRoyalty={setInvestorRoyalty}
                                    creatorRoyalty={creatorRoyalty}
                                    setCreatorRoyalty={setCreatorRoyalty}
                                    isTokenNameEqualFileName={isTokenNameEqualFileName}
                                    setIsTokenNameEqualFileName={setIsTokenNameEqualFileName}
                                    isAuction={isAuction}
                                    setIsAuction={setIsAuction}
                                    isNoPrice={isNoPrice}
                                    setIsNoPrice={setIsNoPrice}
                                    properties={properties}
                                    setProperties={setProperties}
                                    description={description}
                                    setDescriprion={setDescriprion}
                                    levels={levels}
                                    setLevels={setLevels}
                                    stats={stats}
                                    setStats={setStats}
                                    opensea={opensea}
                                    setOpensea={setOpensea}
                                    checkbrandcom={checkbrandcom}
                                    setCheckbrandcom={setCheckbrandcom}
                                    creatorRoyaltyDestribution={creatorRoyaltyDestribution}
                                    setCreatorRoyaltyDestribution={setCreatorRoyaltyDestribution}
                                    incomeRoyaltyDestribution={incomeRoyaltyDestribution}
                                    setIncomeRoyaltyDestribution={setIncomeRoyaltyDestribution}
                                    collectionId={collectionId}
                                    setCollectionId={setCollectionId}
                                    unlockable={unlockable}
                                    setUnlockable={setUnlockable}
                                    unlockableContent={unlockableContent}
                                    setUnlockableContent={setUnlockableContent}
                                    onPackIdChangeHandler={onPackIdChangeHandler}
                                    isAbleToUpload={Boolean(packId)}
                                    packId={packId}
                                    packs={packs}
                                    isCollectionsLoading={isCollectionsLoading}
                                    isCreateToken
                                    isSendData={isSendData}
                                    errors={errors}
                                    handelClearErrors={handelClearErrors}
                                />

                                <UploadManyTokensForm
                                    numbering={numbering}
                                    tokenCommonName={tokenCommonName}
                                    tokenPrice={tokenPrice}
                                    investorRoyalty={investorRoyalty}
                                    creatorRoyalty={creatorRoyalty}
                                    isTokenNameEqualFileName={isTokenNameEqualFileName}
                                    setIsTokenUploadStarted={setIsTokenUploadStarted}
                                    isAbleToUpload={Boolean(packId)}
                                    requiredError="No pack selected or no files upload"
                                    tokensDataToUpload={tokensDataToUpload}
                                    isTokenUploadStarted={isTokenUploadStarted}
                                    checkIsAbleToUpload={checkIsAbleToUpload}
                                    isCreateToken
                                    isSendData={isSendData}
                                    handlerErrors={handlerErrors}
                                    handleClose={handleClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(CreateItem);
