import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from '../index.module.css';

const CertificatesItemSkeleton = ({view = 'big', filterOpen = false}) => {
    return (
        <div className={cn(styles.certificateItemWrapper, styles[view], {
            [styles.filterOpen]: filterOpen
        })}>
            <div className={cn(styles.brandItem, styles.skeletonBg)}>
                <div className={styles.certificateItemImgInner}>
                    <Skeleton
                        containerClassName={styles.certificatesItemImgSkeletonWrapper}
                        className={styles.brandItemImgSkeleton}
                    />
                </div>

                <div className={styles.certificateItemInfo}>
                    <Skeleton
                        containerClassName={styles.certificatesItemNameSkeletonWrapper}
                        className={styles.certificatesItemNameSkeleton}
                    />

                    <div className={styles.certificateItemInfoTags}>
                        <Skeleton
                            containerClassName={styles.certificatesItemTagSkeletonWrapper}
                            className={styles.certificatesItemTagSkeleton}
                        />
                    </div>

                    <Skeleton
                        containerClassName={styles.certificatesItemTextSkeletonWrapper}
                        className={styles.certificatesItemTextSkeleton}
                    />

                    <Skeleton
                        containerClassName={styles.certificatesItemButtonSkeletonWrapper}
                        className={styles.certificatesItemButtonSkeleton}
                    />
                </div>
            </div>
        </div>
    );
};

export default CertificatesItemSkeleton;
