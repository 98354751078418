import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

import FeedbackForm from './FeedbackForm';

const Ofer = () => {
    return (
        <div className={styles.marginWrapper}>
            <img src="/assets/img/doc-bg.png" alt="bg" className={styles.docBg} />

            <div className={base.container}>
                <div className={styles.docWrapper}>
                    <div className={styles.breadcrumbs}>
                        <Link to="/" className={styles.breadcrumbsLink}>
                            Главная
                        </Link>

                        <p className={styles.breadcrumbsText}>Публичная оферта</p>
                    </div>

                    <h2 className={styles.docTitle}>Публичная оферта</h2>

                    <p className={styles.docText}>
                        г. Москва
                        <br />
                        Версия от «16» сентября 2024 года
                    </p>

                    <p className={styles.docText}>
                        ООО “Братоуверие”, именуемое в дальнейшем «Платформа», с одной стороны, и
                        Акцептант, именуемый в дальнейшем «Бренд», с другой стороны, совместно
                        именуемые «Стороны», а по отдельности – «Сторона», заключили настоящий
                        договор-оферту (далее – «Договор») о нижеследующем.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>1. Термины и определения</p>

                    <p className={styles.docText}>
                        В настоящем договоре стороны установили определение для наиболее значимых
                        терминов:
                    </p>

                    <p className={styles.docText}>
                        1.1 Бренд – юридическое лицо или индивидуальный предприниматель, которые
                        являются стороной договора и заинтересованы в изготовлении Сертификатов для
                        своего Товара.
                    </p>

                    <p className={styles.docText}>
                        1.2 Сайт – интернет-сайт, принадлежащий Платформе на праве владения или ином
                        праве, через который Платформа осуществляет свою деятельность.
                    </p>

                    <p className={styles.docText}>
                        1.3 Сертификат – специальный электронный идентификатор для конкретного
                        Товара, который содержит информацию о конкретном Товаре и его владельце и
                        подтверждает его подлинность; является также объектом интеллектуальной
                        собственности в форме произведения.
                    </p>

                    <p className={styles.docText}>
                        1.4 Товар – предмет одежды, коллекция одежды или иной продукт Бренда,
                        который Бренд реализует клиентам в своей коммерческой деятельности.
                    </p>

                    <p className={styles.docText}>
                        1.5 Клиенты – юридические или физические лица, которые заинтересованы в
                        приобретении Товаров, а также которые приобрели такие Товары.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>2. Предмет договора</p>

                    <p className={styles.docText}>
                        2.1 Платформа обязуется создать для Бренда Сертификаты на товары Бренда и
                        передать исключительные права на них Бренду, а Бренд обязуется принять и
                        оплатить такие Сертификаты.
                    </p>

                    <p className={styles.docText}>
                        2.2 Количество Сертификатов и иные характеристики согласуются сторонами
                        согласно тарифу на Сайте, который выбрал Бренд.
                    </p>

                    <p className={styles.docText}>
                        2.3 Платформа может размещать Сертификаты на Сайте с целью отчуждения
                        Сертификата и продажи привязанного к нему Товара. Бренд, в свою очередь,
                        выплачивает Платформе денежное вознаграждение за каждый факт такого
                        отчуждения и продажи.
                    </p>

                    <p className={styles.docText}>
                        2.4 Бренд, со своей стороны, также обязуется передать Товар Клиенту, который
                        приобрёл Сертификат, связанный с таким Товаром.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        3. Порядок исполнения договора
                    </p>

                    <p className={styles.docText}>3.1 Платформа в рамках Договора:</p>

                    <p className={styles.docText}>
                        3.1.1 Обязуется изготовить Сертификаты в срок, согласованный сторонами, и
                        передать исключительные права на них Бренду. Моментом передачи
                        исключительных прав является дата подписания сторонами передаточного акта
                        или дата размещения Сертификатов на Сайте.
                    </p>

                    <p className={styles.docText}>
                        3.1.2 Размещает изготовленные Сертификаты в базе данных Платформы в формате
                        реестра, в котором хранятся данные о Сертификатах, связанных с ними Товарах
                        и владельцах. Гарантированный срок хранения такой базы данных: 10 лет.
                    </p>

                    <p className={styles.docText}>
                        3.1.3 В случае если Бренд не предоставляет необходимые данные о Товаре в
                        согласованные сторонами сроки, Платформа вправе:
                    </p>

                    <p className={styles.docText}>
                        3.1.3.1 отложить изготовление Сертификатов соразмерно срокам задержки
                        предоставления данных;
                    </p>

                    <p className={styles.docText}>
                        3.1.3.2 отказаться от исполнения Договора в одностороннем внесудебном
                        порядке.
                    </p>

                    <p className={styles.docText}>
                        3.1.4 Если Бренд не заявил отказ, Платформа может размещать Сертификаты на
                        Сайте с целью продажи Товаров через приобретение Сертификатов.
                    </p>

                    <p className={styles.docText}>
                        3.1.5 Срок размещения Сертификатов на Сайте составляет: 5 (пять) лет. По
                        истечении этого срока Платформа может убрать с Сайта Сертификат или
                        продолжить его размещение, если иное не согласовано сторонами.
                    </p>

                    <p className={styles.docText}>
                        3.1.6 Платформа обеспечивает работоспособность Сайта и доступ к Сертификатам
                        в течение срока действия Договора.
                    </p>

                    <p className={styles.docText}>3.2 Бренд в рамках Договора:</p>

                    <p className={styles.docText}>
                        3.2.1 Обязуется принять и оплатить Сертификаты.
                    </p>

                    <p className={styles.docText}>
                        3.2.2 Обязуется предоставить Платформе информацию о Товарах, необходимую для
                        создания Сертификатов, в течение 5 (пять) рабочих дней с момента
                        согласования конкретного перечня товаров или коллекции.
                    </p>

                    <p className={styles.docText}>
                        3.2.3 Предоставляет Платформе право размещать Сертификаты на Сайте с целью
                        предложения и продажи Товаров. При размещении, предложении и продаже
                        Сертификатов Платформа действует от имени Бренда в качестве агента, а Бренд
                        выступает в качестве принципала (Глава 52 Гражданского кодекса РФ).
                    </p>

                    <p className={styles.docText}>
                        3.2.4 Платформа может отозвать своё согласие на размещение Сертификатов на
                        Сайте. В этом случае Товары и Сертификаты остаются связанными, а Бренд
                        продаёт товары своими силами.
                    </p>

                    <p className={styles.docText}>
                        3.2.5 Обязуется выплатить Платформе вознаграждение за реализацию
                        Сертификатов на Сайте.
                    </p>

                    <p className={styles.docText}>
                        3.2.6 Может продавать Товары в своих онлайн и/или офлайн магазинах.
                    </p>

                    <p className={styles.docText}>
                        3.3 Если Товар был продан клиенту через магазин Бренда, Бренд уведомляет
                        Платформу о продаже. В этом случае Платформа обязуется передать такому
                        клиенту Сертификат, связанный с проданным Товаром, и снять данный Сертификат
                        с публикации на Сайте как уже реализованный.
                    </p>

                    <p className={styles.docText}>
                        3.4 Если Сертификат был продан клиенту через Сайт, Платформа уведомляет об
                        этом Бренд. В этом случае Бренд обязуется передать такому клиенту Товар,
                        связанный с этим Сертификатом. Отправка Товара клиенту производится за счёт
                        Бренда.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>4. Финансовые условия</p>

                    <p className={styles.docText}>
                        4.1 За создание Сертификатов и отчуждение исключительных прав на них Бренд
                        выплачивает Платформе вознаграждение согласно тарифам на Сайте.
                    </p>

                    <p className={styles.docText}>
                        4.2 Условия выплаты: 100% предоплата – после согласования перечня Товаров.
                    </p>

                    <p className={styles.docText}>
                        4.3 За каждый проданный Сертификат через Сайт, Бренд выплачивает Платформе
                        вознаграждение в размере 10% от стоимости проданного Сертификата.
                    </p>

                    <p className={styles.docText}>
                        4.4 Срок выплаты: 5 (пять) рабочих дней после состоявшейся продажи.
                    </p>

                    <p className={styles.docText}>
                        4.5 Суммы по настоящему договору не облагаются НДС.
                    </p>

                    <p className={styles.docText}>
                        4.6 Платформа вправе удержать своё вознаграждение из денежных средств,
                        подлежащих передаче Бренду, которые поступили на счёт Платформы. Оставшиеся
                        денежные средства Платформа перечисляет на банковский счёт Бренда до конца
                        календарного месяца, в котором поступила оплата от клиента (без выплаты
                        процентов за пользование или удержание денежных средств).
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        5. Порядок передачи сертификатов
                    </p>

                    <p className={styles.docText}>
                        5.1 Платформа передаёт Сертификаты Бренду в электронном формате через
                        электронную почту, мессенджеры (WhatsApp, Telegram и др.), контакты,
                        указанные в настоящем договоре или достоверно принадлежащие стороне, а также
                        иными возможными способами, позволяющими идентифицировать получателя. Срок
                        для рассмотрения претензий о недостатках сертификатов: 2 (два) рабочих дня,
                        по истечении этого срока Сертификаты считаются принятыми надлежащим образом.
                    </p>

                    <p className={styles.docText}>
                        5.2 Платформа передаёт Сертификат клиенту в электронном формате через
                        электронную почту, загрузку на Сайте, размещение в облачном хранилище, а
                        также иными возможными способами, если такой клиент приобрёл Сертификат на
                        Сайте или приобрёл Товар у Бренда.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        6. Интеллектуальная собственность
                    </p>

                    <p className={styles.docText}>
                        6.1 Созданные Сертификаты являются объектами интеллектуальной собственности,
                        полный объём исключительных прав на них принадлежит Бренду.
                    </p>

                    <p className={styles.docText}>
                        6.2 Платформа отчуждает Бренду исключительные права на изготовленные
                        Сертификаты в полном объёме для использования их как в целом, так и
                        отдельных частей в любой форме и любым способом, включая перечисленные в ст.
                        1270 Гражданского кодекса РФ.
                    </p>

                    <p className={styles.docText}>
                        6.3 Если Бренд для изготовления Сертификатов предоставляет товарные знаки,
                        фирменные наименования, обозначения и символы, права на которые принадлежат
                        Бренду, Платформа использует интеллектуальную собственность Бренда только в
                        рамках исполнения Договора. Договор не подразумевает передачу
                        Интеллектуальной собственности Бренда Платформе.
                    </p>

                    <p className={styles.docText}>
                        6.4 Размещение на Сайте Сертификатов, а также иных объектов интеллектуальной
                        собственности, принадлежащих Бренду, не означает факта использования этих
                        объектов Платформой. Такое размещение будет считаться согласованным с
                        Брендом, а Платформа в данном случае будет действовать как агент.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>7. Гарантии и заверения</p>

                    <p className={styles.docText}>
                        7.1 Сайт принадлежит Платформе на праве владения или ином праве. Платформа
                        гарантирует и заверяет, что Платформе принадлежит право размещать информацию
                        на Сайте, использовать его в коммерческих целях, рекламировать товары,
                        услуги на Сайте. Платформа гарантирует, что размещение Сертификатов на Сайте
                        не нарушит действующее законодательство, в том числе законодательство о
                        рекламе, о персональных данных, авторские права третьих лиц. Платформа несёт
                        имущественную и иную ответственность за нарушения, связанные с размещением
                        Сертификатов на Сайте, в том числе если претензии и/или иски предъявлены в
                        отношении Бренда. При этом Платформа не несёт ответственности, если такая
                        ответственность возложена на Бренд согласно закону или Договору.
                    </p>

                    <p className={styles.docText}>
                        7.2 Бренд гарантирует, что обладает всеми правами на Товар, а также на
                        связанные с ним результаты интеллектуальной деятельности и средства
                        индивидуализации, поэтому размещение Сертификатов на Сайте не нарушит права
                        и законные интересы третьих лиц и действующее законодательство. Бренд несёт
                        имущественную и иную ответственность перед третьими лицами за все
                        последствия таких нарушений, в том числе если претензии и/или иски
                        предъявлены в отношении Платформы.
                    </p>

                    <p className={styles.docText}>
                        7.3 До заключения и на протяжении действия договора стороны гарантируют и
                        заверяют друг друга в том, что:
                    </p>

                    <p className={styles.docText}>
                        7.3.1 будут действовать добросовестно по отношению друг к другу, что
                        означает отсутствие намерений причинить имущественный вред, рациональное и
                        бережное отношение к финансам и имуществу друг друга;
                    </p>

                    <p className={styles.docText}>
                        7.3.2 будут заботиться защите деловой репутации и имиджа друг друга, будут
                        воздерживаться от любых негативных действий и высказываний в отношении друг
                        друга в публичном или непубличном пространстве.
                    </p>

                    <p className={styles.docText}>
                        7.3.3 будут обеспечивать защиту интеллектуальной собственности друг друга, в
                        том числе: (a) не передавать объекты интеллектуальных прав третьим лицам без
                        согласия правообладателя; (b) не подвергать объекты интеллектуальных прав
                        другой стороны риску передачи третьим лицам.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>8. Ответственность сторон</p>

                    <p className={styles.docText}>
                        8.1 Ответственность Сторон определяется в соответствии с законодательством
                        Российской Федерации.
                    </p>

                    <p className={styles.docText}>
                        8.2 Стороны несут ответственность друг перед другом за убытки, причинённые в
                        результате нарушения обязательств по настоящему договору. Сторона направляет
                        требование о возмещении убытков с подтверждением понесённых убытков и
                        расчётом требований виновной Стороне. Срок исполнения – в течение 30
                        (тридцати) календарных дней с момента получения требования. В случае если
                        требование не было удовлетворено, спор подлежит разрешению в порядке,
                        предусмотренном настоящим договором.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>
                        9. Соглашение о конфиденциальности
                    </p>

                    <p className={styles.docText}>
                        9.1 Стороны рассматривают и охраняют всю конфиденциальную информацию,
                        полученную в результате исполнения договора в письменной, электронной,
                        устной, аудиовизуальной или иной форме о фактах, событиях, явлениях и
                        процессах, составляющих коммерческую, банковскую, налоговую тайну, сведения
                        любого характера (производственные, технические, экономические,
                        организационные, условия заключаемых договоров, секреты производства и
                        ноу-хау), которая имеет действительную или потенциальную коммерческую
                        ценность в силу неизвестности третьим лицам, к которой у третьих лиц нет
                        свободного доступа на законном основании, за исключением информации:
                    </p>

                    <p className={styles.docText}>9.1.1 уже являющейся публично известной;</p>

                    <p className={styles.docText}>
                        9.1.2 полученной законным путём от третьих лиц без обязательства о
                        неразглашении;
                    </p>

                    <p className={styles.docText}>
                        9.1.3 специально указанной при передаче как не конфиденциальная информация;
                    </p>

                    <p className={styles.docText}>
                        9.1.4 подлежащей передаче полностью или частично компетентным органам власти
                        в соответствии с действующим законодательством либо судебным решением, при
                        условии, что передающая сторона предприняла необходимые меры: (a)
                        незамедлительно уведомила вторую сторону о полученном запросе на раскрытие
                        информации; (b) ограничилась минимально необходимым объёмом передаваемой
                        информации, основываясь на действующем законодательстве в рамках применимого
                        права; (c) сообщила компетентным органам власти о необходимости принятия мер
                        по защите конфиденциальности передаваемой информации и её ценности для
                        второй стороны.
                    </p>

                    <p className={styles.docText}>
                        9.2 Сообщение конфиденциальной информации допускается только лицам,
                        привлечённым к исполнению договора. Такое сообщение должно осуществляться с
                        предупреждением о необходимости соблюдения режима конфиденциальности и
                        только в той мере, насколько это необходимо для исполнения обязательств по
                        настоящему договору.
                    </p>

                    <p className={styles.docText}>
                        9.3 В целях охраны конфиденциальной информации стороны обязуются:
                    </p>

                    <p className={styles.docText}>
                        9.3.1 не разглашать конфиденциальную информацию, не использовать эту
                        информацию в целях, не связанных с исполнением настоящего договора;
                    </p>

                    <p className={styles.docText}>
                        9.3.2 при попытке посторонних лиц получить конфиденциальную информацию
                        немедленно сообщить об этом другой стороне;
                    </p>

                    <p className={styles.docText}>
                        9.3.3 соблюдать столь же высокую степень секретности во избежание
                        разглашения или использования конфиденциальной информации, какую каждая
                        сторона соблюдала бы в разумной степени в отношении своей собственной
                        конфиденциальной информации.
                    </p>

                    <p className={styles.docText}>
                        9.4 Соглашение о неразглашении конфиденциальной информации по настоящему
                        договору остаётся обязательным к соблюдению на протяжении действия
                        настоящего договора, а также в течение трёх лет после его расторжения,
                        истечения срока действия или прекращения по любым основаниям.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>10. Форс-мажор</p>

                    <p className={styles.docText}>
                        10.1 Стороны освобождаются от ответственности за частичное или полное
                        неисполнение обязательств по договору, если это неисполнение явилось
                        следствием возникших после заключения договора обстоятельств непреодолимой
                        силы, которые стороны не могли предвидеть или предотвратить. Такими
                        обстоятельствами являются землетрясения, наводнения, пожары, войны,
                        объявление чрезвычайного или военного положения, политические волнения,
                        забастовки, правительственные акты и иные обстоятельства, которые делают
                        невозможным исполнение обязательств по настоящему договору.
                    </p>

                    <p className={styles.docText}>
                        10.2 Сторона, ссылающаяся на обстоятельства непреодолимой силы, обязана в
                        семидневный срок проинформировать другую сторону о наступлении и характере
                        подобных обстоятельств в письменной форме с приложением копий документов,
                        подтверждающих возникновение обстоятельств непреодолимой силы.
                    </p>

                    <p className={styles.docText}>
                        10.3 Если указанные обстоятельства продолжаются более 2 (двух) месяцев,
                        каждая из сторон вправе расторгнуть настоящий договор, предварительно
                        уведомив об этом другую сторону.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>11. Действие договора</p>

                    <p className={styles.docText}>
                        11.1 Правом, применимым к отношениям сторон по настоящему договору, является
                        право, основанное на действующем законодательстве Российской Федерации.
                    </p>

                    <p className={styles.docText}>
                        11.2 Договор вступает в силу с момента оплаты по тарифу на Сайте и действует
                        до полного исполнения сторонами своих обязательств по договору.
                    </p>

                    <p className={styles.docText}>
                        11.3 Договор может быть расторгнут до окончания срока действия:
                    </p>

                    <p className={styles.docText}>
                        11.3.1 В результате одностороннего отказа одной из Сторон от исполнения
                        Договора путём направления другой Стороне соответствующего уведомления не
                        позднее, чем за 1 (один) месяц до предполагаемой даты расторжения Договора;
                    </p>

                    <p className={styles.docText}>11.3.2 По соглашению Сторон.</p>

                    <p className={cn(styles.docText, styles.bold)}>12. Разрешение споров</p>

                    <p className={styles.docText}>
                        12.1 Все споры и разногласия, которые могут возникнуть между сторонами,
                        будут разрешаться путём переговоров с соблюдением обязательного досудебного
                        порядка путём направления претензии со сроком рассмотрения в течение 30
                        (тридцати) календарных дней с момента получения.
                    </p>

                    <p className={styles.docText}>
                        12.2 При неурегулировании разногласий в процессе переговоров после истечения
                        согласованного срока споры разрешаются в судебном порядке, установленном
                        действующим законодательством Российской Федерации.
                    </p>

                    <p className={styles.docText}>
                        12.3 Стороны согласовали, что споры будут разрешаться с применением
                        договорной подсудности: в Арбитражном суде г. Москвы, а в случае
                        несоответствия одной из сторон процессуальным требованиям – в Тверском
                        районном суде г. Москвы.
                    </p>

                    <p className={styles.docText}>
                        12.4 Под спорами в настоящем разделе понимаются споры, в том числе связанные
                        с неисполнением или ненадлежащим исполнением обязательств по договору,
                        вопросами о недействительности, незаключённости договора, вопросами о
                        принадлежности и/или нарушении прав на результаты интеллектуальной
                        деятельности и средства индивидуализации , а также все иные споры между
                        сторонами, связанные с предметом и исполнением договора.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>13. Заключительные положения</p>

                    <p className={styles.docText}>
                        13.1 Все изменения и дополнения к настоящему договору имеют юридическую силу
                        только при составлении соответствующего письменного документа, подписанного
                        обеими сторонами, тогда они являются неотъемлемой частью настоящего
                        договора.
                    </p>

                    <p className={styles.docText}>
                        13.2 Стороны признают действительность документов, в том числе настоящего
                        договора, дополнений и приложений, если такие документы передаются сторонами
                        друг другу в форме простого электронного документа (сканированный образ
                        подписанного документа) посредством электронной почты, мессенджеров
                        (WhatsApp, Telegram и др.) и др. через контакты, указанные в настоящем
                        договоре или достоверно принадлежащие стороне.
                    </p>

                    <p className={styles.docText}>
                        13.3 Во всем остальном стороны при исполнении настоящего договора
                        руководствуются законодательством Российской Федерации.
                    </p>

                    <p className={cn(styles.docText, styles.bold)}>14. Реквизиты платформы</p>

                    <p className={styles.docText}>
                        ООО «БРАТОУВЕРИЕ»
                        <br />
                        ОГРН 1222500000315
                        <br />
                        ИНН 2511119031
                        <br />
                        Юридический адрес:
                        <br />
                        Приморский край, г. Уссурийск, ул. Ленинградская 43 кор. В, оф. 9
                    </p>

                    <p className={styles.docText}>
                        Тел. +7 (922) 312-07-35
                        <br />
                        E-mail: bratouverie@gmail.com
                    </p>

                    <p className={styles.docText}>
                        Р/счёт 40702810802500119656
                        <br />в ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"
                        <br />
                        К/счёт 30101810845250000999
                        <br />
                        БИК 044525999
                    </p>

                    <FeedbackForm />
                </div>
            </div>
        </div>
    );
};

export default Ofer;
