export const handleFetchCodeByType = (type, setError, code, setStep, nextStep) => {
    const accessToken = localStorage.getItem('access_token');
    if (type === 'email')
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_email/${code}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
            .then((res) => {
                if (Number(res.status) === 200) {
                    setStep(nextStep);
                } else {
    
                    return res.json();
                }
            })
            .then((response) => {
                if (response && response.msg) {
                    setError(response.msg);
                }
            });

    if (type === 'phone')
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_phone/${code}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
            .then((res) => {
                if (Number(res.status) === 200) {
                    setStep(nextStep);
                } else {
                    return res.json();
                }
            })
            .then((response) => {
                if (response && response.msg) {
                    setError(response.msg);
                }
            });
};

export const CONTACTS_VERIFY_STEPS = [
    'initialModalState',
    'emailCodeVerifaction',
    'phoneNumberVerifaction',
    'successedVerifycation',
];

export const MODAL_TEXT_CONTENT = {
    initialModalState: {
        title: 'Пожалуйста укажите свои контакты',
        type: 'default',
    },

    emailCodeVerifaction: {
        title: 'Пожалуйста подтвердите вашу почту',
        type: 'emailVerify',
    },

    phoneNumberVerifaction: {
        title: 'Пожалуйста подтвердите свой телефон',
        type: 'phoneVerify',
    },

    successedVerifycation: {
        title: 'Верификация прошла успешно!',
        type: 'success',
    },
};

export const fetchUserByToken = (setPhone, setEmail, setUserProfile) => {
    const access_token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
        })
        .then((response) => {
            if (response && !response.errors) {
                setUserProfile(response);
                if (response.phone) {
                    setPhone(response.new_phone ? response.new_phone : response.phone);
                }
                if (response.email) {
                    setEmail(response.new_email ? response.new_email : response.email);
                }
            }
        });
};



export const handleUpdateUserContacts = (
    setVerifyStep,
    email,
    phone,
    setPhone,
    setEmail,
    userProfile,
    requestPayload
) => {
    const accessToken = localStorage.getItem('access_token');

    if (Object.keys(requestPayload).length > 0) {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify(requestPayload),
        })
            .then((res) => {
                if (Number(res.status) <= 210) {
                    return res.json();
                }
                if (Number(res.status) >= 400) {
                    // return res.json();
                    console.log('error');
                }
            })
            .then((response) => {
                if (response) {
                    if (requestPayload.phone && requestPayload.email) {
                        setVerifyStep(1);
                    }
                    if (requestPayload.phone && !requestPayload.email) {
                        setVerifyStep(2);
                    }
                    if (!requestPayload.phone && requestPayload.email) {
                        setVerifyStep(1);
                    }
                }
            });
    }
};
