import React from 'react';

const Cross = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5896 4.41009C15.915 4.73553 15.915 5.26317 15.5896 5.5886L5.58958 15.5886C5.26414 15.914 4.73651 15.914 4.41107 15.5886C4.08563 15.2632 4.08563 14.7355 4.41107 14.4101L14.4111 4.41009C14.7365 4.08466 15.2641 4.08466 15.5896 4.41009Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.41107 4.41009C4.73651 4.08466 5.26414 4.08466 5.58958 4.41009L15.5896 14.4101C15.915 14.7355 15.915 15.2632 15.5896 15.5886C15.2641 15.914 14.7365 15.914 14.4111 15.5886L4.41107 5.5886C4.08563 5.26317 4.08563 4.73553 4.41107 4.41009Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Cross;
