import React from 'react';

export const Video2 = ({ innerColor = "#182A48", ...props }) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_16587_6206)">
                <path
                    d="M9 16.5C13.1423 16.5 16.5 13.1423 16.5 9C16.5 4.85775 13.1423 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1423 4.85775 16.5 9 16.5Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 9.00034V6.40234L9.75 7.70134L12 9.00034L9.75 10.2993L7.5 11.5983V9.00034Z"
                    fill={innerColor}
                    stroke={innerColor}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath>
                    <rect width="18" height="18" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    );
};
