import React from 'react';

const AddFile = ({ ...props }) => {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.26256 1.59459C2.59075 1.26641 3.03587 1.08203 3.5 1.08203H8.45833C8.61304 1.08203 8.76142 1.14349 8.87081 1.25289L12.0791 4.46122C12.1885 4.57062 12.25 4.71899 12.25 4.8737V12.1654C12.25 12.6295 12.0656 13.0746 11.7374 13.4028C11.4092 13.731 10.9641 13.9154 10.5 13.9154H2.33333C2.01117 13.9154 1.75 13.6542 1.75 13.332C1.75 13.0099 2.01117 12.7487 2.33333 12.7487H10.5C10.6547 12.7487 10.8031 12.6872 10.9125 12.5778C11.0219 12.4684 11.0833 12.3201 11.0833 12.1654V5.11532L8.21671 2.2487H3.5C3.34529 2.2487 3.19692 2.31016 3.08752 2.41955C2.97812 2.52895 2.91667 2.67732 2.91667 2.83203V5.16536C2.91667 5.48753 2.6555 5.7487 2.33333 5.7487C2.01117 5.7487 1.75 5.48753 1.75 5.16536V2.83203C1.75 2.3679 1.93437 1.92278 2.26256 1.59459Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.16536 1.08203C8.48753 1.08203 8.7487 1.3432 8.7487 1.66536V4.58203H11.6654C11.9875 4.58203 12.2487 4.8432 12.2487 5.16536C12.2487 5.48753 11.9875 5.7487 11.6654 5.7487H8.16536C7.8432 5.7487 7.58203 5.48753 7.58203 5.16536V1.66536C7.58203 1.3432 7.8432 1.08203 8.16536 1.08203Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.16797 9.2513C1.16797 8.92914 1.42914 8.66797 1.7513 8.66797H5.2513C5.57347 8.66797 5.83464 8.92914 5.83464 9.2513C5.83464 9.57347 5.57347 9.83463 5.2513 9.83463H1.7513C1.42914 9.83463 1.16797 9.57347 1.16797 9.2513Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5013 6.91797C3.82347 6.91797 4.08464 7.17914 4.08464 7.5013V11.0013C4.08464 11.3235 3.82347 11.5846 3.5013 11.5846C3.17914 11.5846 2.91797 11.3235 2.91797 11.0013V7.5013C2.91797 7.17914 3.17914 6.91797 3.5013 6.91797Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default AddFile;
