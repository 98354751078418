export const getFileNameAndExt = fileName => {
    const arr = fileName && fileName.split('.');

    const ext = arr && arr.pop();
    const fileNameWithoutExt = arr ? `${arr.join('.')}` : '';

    return {
        ext: ext || '',
        fileName: fileNameWithoutExt,
    };
};
