import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetWhiteListFilteredQuery } from '../../../redux/api/ugcService';
import {
    addWhitelists,
    increasePage,
    setPage,
    setIsLoading,
    setTotalCount,
    setWhitelists,
} from '../../../redux/slices/admin/whitelists';
import { convertWhitelistsObj } from '../helpers/convertWhitelistsObj';
import useDebouncedValue from '../../../hooks/useDebouncedValue';
import useDebounce from '../../../hooks/useDebounce';

const WhitelistItemsContext = React.createContext({});

const WhitelistItemsContextProvider = (props) => {
    const { children } = props;

    const {
        page,
        rowsPerPage,
        selectedCollections,
        statuses,
        query,
        createdGreateThenInH,
    } = useSelector((state) => ({
        page: state.whitelists.page,
        rowsPerPage: state.whitelists.rowsPerPage,
        selectedCollections: state.whitelists.selectedCollections,
        statuses: state.whitelists.statuses,
        query: state.whitelists.query,
        createdGreateThenInH: state.whitelists.createdGreateThenInH,
    }));

    const dispatch = useDispatch();

    const [isDataProccesing, setIsDataProccesing] = useState(false);

    const collectionsIds = useMemo(() => (selectedCollections || []).map((el) => el.id), [
        selectedCollections,
    ]);

    const debouncedQuery = useDebouncedValue(query, 500);

    const [state, setState] = useState({
        page: 1,
        collectionsIds: [],
        statuses: [],
        query: '',
        createdGreateThenInH: 'all',
    });

    const [trigger, { data }] = useLazyGetWhiteListFilteredQuery();

    const refetchApplicationsHandler = useCallback(() => {
        trigger({
            pageSize: rowsPerPage,
            sort: '-created_at',
            page: 1,
            collectionsIds: state.collectionsIds,
            statuses: state.statuses,
            query: state.query,
            createdGreateThenInH: state.createdGreateThenInH,
        });
    }, [state]);

    const nextPageHandler = useCallback(() => {
        setIsDataProccesing(true);
        dispatch(increasePage());
        setState((p) => {
            trigger({
                pageSize: rowsPerPage,
                sort: '-created_at',
                page: p.page + 1,
                collectionsIds: p.collectionsIds,
                statuses: p.statuses,
                query: p.query,
                createdGreateThenInH: p.createdGreateThenInH,
            });

            return {
                ...p,
                page: p.page + 1,
            };
        });
    }, []);

    useDebounce(
        () => {
            setIsDataProccesing(true);
            dispatch(setWhitelists([]));

            trigger({
                pageSize: rowsPerPage,
                sort: '-created_at',
                page: 1,
                collectionsIds: collectionsIds.length ? collectionsIds : [],
                statuses: statuses.map((el) => el.id),
                query: debouncedQuery,
                createdGreateThenInH,
            });
        },
        [collectionsIds, statuses, debouncedQuery, createdGreateThenInH],
        200,
    );

    useEffect(() => {
        if (data && data.items) {
            setIsDataProccesing(true);
            setState((p) => ({
                ...p,
                page: data.page,
            }));
            dispatch(setPage(data.page));
            (async () => {
                const convertedObjs = await Promise.all(
                    data.items.map((application) => convertWhitelistsObj(application)),
                );

                dispatch(
                    data.page === 1 ? setWhitelists(convertedObjs) : addWhitelists(convertedObjs),
                );
                dispatch(setTotalCount(data.total));

                setIsDataProccesing(false);
            })();
        }
    }, [data]);

    useEffect(() => {
        dispatch(setIsLoading(isDataProccesing));
    }, [isDataProccesing]);

    const actions = {
        refetchApplicationsHandler,
        nextPageHandler,
    };

    return (
        <WhitelistItemsContext.Provider
            value={{
                actions,
            }}
        >
            {children}
        </WhitelistItemsContext.Provider>
    );
};

export { WhitelistItemsContext, WhitelistItemsContextProvider };
