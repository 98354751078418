import React from 'react';

const ShareLink = ({ ...props }) => {
    return (
        <svg
            width="9"
            height="7"
            viewBox="0 0 9 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.21295 6.99636C0.22848 6.99863 0.243511 7 0.258039 7C0.364251 7 0.461941 6.93942 0.49902 6.84604C0.535092 6.75721 1.38529 4.73197 4.28 4.9652V6.71578C4.28 6.80552 4.33662 6.88843 4.42629 6.92669C4.51597 6.96495 4.62118 6.95356 4.69834 6.89617L8.90526 3.77819C8.96438 3.73491 8.99745 3.67068 8.99995 3.6019C9.00145 3.53312 8.96939 3.46799 8.91278 3.42243L4.70585 0.0579269C4.6302 -0.00265667 4.52248 -0.0167773 4.43031 0.0205748C4.33812 0.0579269 4.279 0.141747 4.279 0.232844V1.95882C3.71087 1.96702 2.5015 2.09412 1.497 2.9441C0.509535 3.77907 0.00551501 5.0654 0 6.76549C0.00150303 6.87982 0.0896983 6.97631 0.21295 6.99636Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ShareLink;
