import React from 'react';

export const Plus2 = ({ ...props }) => {
    return (
        <svg width={8} height={8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.57143 4.07143H4.07143V4.57143V7.42857C4.07143 7.44752 4.0639 7.46568 4.05051 7.47908C4.03711 7.49247 4.01894 7.5 4 7.5C3.98106 7.5 3.96289 7.49247 3.94949 7.47908L3.64013 7.78844L3.94949 7.47908C3.9361 7.46568 3.92857 7.44752 3.92857 7.42857V4.57143V4.07143H3.42857H0.571429C0.552485 4.07143 0.534317 4.0639 0.520921 4.05051C0.507525 4.03711 0.5 4.01894 0.5 4C0.5 3.98106 0.507525 3.96289 0.520921 3.94949C0.534316 3.9361 0.552484 3.92857 0.571429 3.92857H3.42857H3.92857V3.42857V0.571429C3.92857 0.552484 3.9361 0.534316 3.94949 0.520921C3.96289 0.507525 3.98106 0.5 4 0.5C4.01894 0.5 4.03711 0.507525 4.05051 0.520921C4.0639 0.534316 4.07143 0.552485 4.07143 0.571429V3.42857V3.92857H4.57143H7.42857C7.44752 3.92857 7.46568 3.9361 7.47908 3.94949L7.83263 3.59594L7.47908 3.94949C7.49247 3.96289 7.5 3.98105 7.5 4C7.5 4.01895 7.49247 4.03711 7.47908 4.05051C7.46568 4.0639 7.44752 4.07143 7.42857 4.07143H4.57143Z"
                fill="#207AE1"
                stroke="currentColor"
            />
        </svg>
    );
};
