export const handleFetchCodeByType = (type, code, setStep, nextStep, setError) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_${type}/${code}`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    })
        .then((res) => {
            if (res.ok) {
                setStep(nextStep);
            } else {
                return res.json();
            }
        })
        .then((response) => {
            if (response && response.msg) {
                setError(response.msg);
            }
        })
        .catch((error) => {
            setError('Произошла ошибка при обработке запроса.');
        });
};
export const CONTACTS_VERIFY_STEPS = [
    'initialModalState',
    'emailCodeVerifaction',
    'phoneNumberVerifaction',
    'successedVerifycation',
];

export const MODAL_TEXT_CONTENT = {
    initialModalState: {
        title: 'Пожалуйста укажите свои контакты',
        type: 'default',
    },

    emailCodeVerifaction: {
        title: 'Пожалуйста подтвердите вашу почту',
        type: 'emailVerify',
    },

    phoneNumberVerifaction: {
        title: 'Пожалуйста подтвердите свой телефон',
        type: 'phoneVerify',
    },

    successedVerifycation: {
        title: 'Верификация прошла успешно!',
        type: 'success',
    },
};

export const fetchUserByToken = (setPhone, setEmail, setUserProfile) => {
    const access_token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
        })
        .then((response) => {
            if (response && !response.errors) {
                setUserProfile(response);
                if (response.phone) {
                    setPhone(response.new_phone ? response.new_phone : response.phone);
                }
                if (response.email) {
                    setEmail(response.new_email ? response.new_email : response.email);
                }
            }
        });
};

export const handleUpdateUserContacts = (setVerifyStep, email, phone, nextStep, type, setError) => {
    const requestBodyTypes = {
        all: { email, phone },
        phone: { phone: phone.substring(2) },
        email: { email },
    };
    const accessToken = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(requestBodyTypes[type]),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response) {
                setVerifyStep(nextStep);
            }
        })
        .catch((err) => {
            setError(err.msg);
        });
};

export const handleFetchSMSCode = (phone, setError) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${
        process.env.REACT_APP_BACKEND_URL
    }/auth_service/user/api/v1/profile/send_sms/${phone.substring(2)}`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    }).catch((error) => {
        setError(error.msg);
    });
};