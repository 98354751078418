exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UserAvatar_ownersItemUserAvatar__2MUhe{\n    width: 78px;\n    aspect-ratio: 1 / 1;\n    border-radius: 50%;\n    display: flex;\n    padding: 2px;\n}\n\n.UserAvatar_ownersItemUserAvatar__2MUhe span{\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    display: flex;\n    align-items: flex-end;\n    justify-content: center;\n    overflow: hidden;\n}\n\n.UserAvatar_ownersItemUserAvatar__2MUhe img{\n    width: auto;\n    height: auto;\n    max-width: 100%;\n    max-height: 100%;\n    position: relative;\n    bottom: -1px;\n}\n\n.UserAvatar_default__2dpbW{\n    background: #25476D;\n}\n\n.UserAvatar_default__2dpbW span{\n    background: linear-gradient(to right, #30364A, #2A2F41, #373C51, #2F3445, #303546, #2F3445, #2E3445, #2B3142);\n}\n\n.UserAvatar_silver__1NOBg{\n    background: linear-gradient(to right, #6A767E, #9BA4AB, #D9DCDF, #D1D5DB, #C8CED7, #C3CAD4, #fff);\n}\n\n.UserAvatar_silver__1NOBg span{\n    background: linear-gradient(to right, #30364A, #2A2F41, #373C51, #2F3445, #303546, #2F3445, #2E3445, #2B3142);\n}\n\n.UserAvatar_gold__3mD-i{\n    background: linear-gradient(to right, #C88D25, #F1DB88, #C58C23, #E0B853);\n}\n\n.UserAvatar_gold__3mD-i span{\n    background: linear-gradient(45deg, #635437, #574224, #665F48, #574224, #635437);\n}\n\n.UserAvatar_bronze__3poWe{\n    background: linear-gradient(to right, #9A562F, #5E2A12, #6D371B, #D28142, #562610);\n}\n\n.UserAvatar_bronze__3poWe span{\n    background: linear-gradient(to right, #442C27, #2E1C1C, #362222, #2F1D1D, #54392B, #2B1A1B, #2E1C1D);\n}", ""]);

// exports
exports.locals = {
	"ownersItemUserAvatar": "UserAvatar_ownersItemUserAvatar__2MUhe",
	"default": "UserAvatar_default__2dpbW",
	"silver": "UserAvatar_silver__1NOBg",
	"gold": "UserAvatar_gold__3mD-i",
	"bronze": "UserAvatar_bronze__3poWe"
};