import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';

import ArrowLink from '../../../icons/ArrowLink';
import Clock from '../../../icons/Clock';

const StepItem = ({ number, name, time, image, openDefault = false, children }) => {
    const [step, setStep] = React.useState(openDefault);

    return (
        <div
            className={cn(styles.stepItem, {
                [styles.active]: step,
            })}
        >
            <div className={styles.stepItemTop} onClick={() => setStep((prev) => !prev)}>
                <div className={styles.stepItemTopStepInner}>
                    <p className={styles.stepItemTopStep}>Этап {number}</p>

                    <p className={styles.stepItemTopName}>{name}</p>
                </div>

                <div className={styles.stepItemTopTimeWrapper}>
                    <div className={styles.stepItemTopTime}>
                        <Clock />
                        {time}
                    </div>

                    <ArrowLink className={styles.stepItemArrow} />
                </div>
            </div>

            {step && (
                <div className={styles.stepItemContent}>
                    <img
                        src="/assets/img/step-bg.png"
                        alt="step bg"
                        className={styles.stepItemBg}
                    />

                    {children}

                    <div className={styles.stepItemImgInner}>
                        <img src={image} alt="step img" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StepItem;
