import React, { useEffect, useState, useRef } from 'react';
import { useGetAccountsListFilteredQuery } from '../../redux/api/handleService';
import Loader from '../../common/Loader';
import ImgBlockItem from '../ImgBlock/ImgBlockItem';

import styles from './index.module.css';
import NoItems from '../../common/NoItems';

const ACCOUNTS_PER_PAGE = 20;

const AccountsSearchResults = (props) => {
    const { query } = props;

    const containerRef = useRef(null);
    const totalAccounts = useRef(0);

    const [page, setPage] = useState(1);

    const { data, isFetching } = useGetAccountsListFilteredQuery(
        {
            page,
            pageSize: ACCOUNTS_PER_PAGE,
            query,
        },
        {
            skip: !query,
        },
    );

    useEffect(() => {
        const scrollHandler = () => {
            if (
                containerRef.current &&
                containerRef.current.offsetHeight - window.pageYOffset < 200
            ) {
                setPage((p) => {
                    if (totalAccounts.current / ACCOUNTS_PER_PAGE > p + 1) {
                        return p;
                    }
                    return p + 1;
                });
            }
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (data && data.count) {
            totalAccounts.current = data.count;
        }
    }, [data]);

    return (
        <div ref={containerRef} className="container">
            {(data && data.results && data.results.length) || !isFetching ? (
                <div className="imgblock__content">
                    {data && data.results && data.results.length ? (
                        <>
                            {data.results.map((account) => (
                                <ImgBlockItem
                                    key={account.id}
                                    name={account.name}
                                    path={account.logo}
                                    id={account.id}
                                    collectionsCount={account.collections_count}
                                />
                            ))}
                        </>
                    ) : (
                        <NoItems />
                    )}
                </div>
            ) : (
                <Loader className={styles.loader} />
            )}
        </div>
    );
};

export default React.memo(AccountsSearchResults);
