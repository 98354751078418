import React, { useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TOKEN_STATUSES } from '../../../const/collection/TOKEN_STATUSES';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../../context/NotificationContext';
import useUploadToBlockChain from '../../../hooks/useUploadToBlockChain';
import { roundInt } from '../../../utils/roundInt';
import { Skeleton } from '@mui/material';
import { S3Context } from '../../../context/S3Context';

import styles from '../index.module.css';
import { isVideo } from '../../../utils/isVideo';
import {useNavigate} from "react-router-dom";

const TokenItem = (props) => {
    const {
        token,
        bookTokenInProccessing,
        onBookTokenHandler,
        isAvailableForBooking,
        bookedTokenId,
        canBeMinting,
        isFullImagePathProvided = false,
    } = props;

    const auth = useSelector((state) => state.auth);

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [actionMenu, setActionMenu] = useState(false);

    const actionMenuRef = useRef(null);
    const navigate = useNavigate()

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const {
        actions: { getImageBacketPath },
    } = useContext(S3Context);

    const { whitelistMintToken } = useUploadToBlockChain();

    const onBook = useCallback(() => {
        onBookTokenHandler(token.id);
    }, [token.id]);

    const onMint = useCallback(() => {
        if (!auth.walletAddress) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Authorize first',
            });
        }

        whitelistMintToken({
            userAddress: auth.walletAddress,
        });
    }, [auth.walletAddress]);

    const action = useMemo(() => {
        if (isAvailableForBooking && token.status === TOKEN_STATUSES.BOOK) {
            return (
                <div className="collection__button--hidden">
                    <button
                        disabled={bookTokenInProccessing}
                        onClick={onBook}
                        className="button collection__item--button blue__button"
                    >
                        Book
                    </button>
                </div>
            );
        }

        if (canBeMinting) {
            return (
                <div className="collection__button--hidden">
                    <button
                        disabled={bookTokenInProccessing}
                        onClick={onMint}
                        className="button collection__item--button blue__button"
                    >
                        Mint
                    </button>
                </div>
            );
        }

        return null;
    }, [token.status, isAvailableForBooking, canBeMinting]);

    const tokenCardSign = useMemo(() => {
        if (bookedTokenId && bookedTokenId === token.id) {
            return 'Booked';
        }

        return `${token && token.investor_royalty ? `Доходность: ${token.investor_royalty}%/день` : ''}`;
    }, [bookedTokenId, isAvailableForBooking, token.id]);

    const filePath = useMemo(() => {
        if (isFullImagePathProvided) {
            return token.file_1 || token.file_2;
        }

        if (token.file_1) {
            return `${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${token.file_1}`
        }

        if (token.file_2) {
            return `${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${token.file_2}`
        }

        return null;
    }, [isFullImagePathProvided, token.file_1, token.file_1]);

    const handleOutsideClick = (e) => {
        if (actionMenuRef.current && !actionMenuRef.current.contains(e.target)) {
            setActionMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);


    return (
        <div className={styles.collection_item}>
            {!isImageLoaded && (
                <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    className="skeleton"
                    animation="wave"
                />
            )}
            <a href={`/token/${token.id}`} className="collection__item--img--inner">
                <LazyLoadImage
                    effect="blur"
                    alt="token"
                    onLoad={() => {
                        setIsImageLoaded(true);
                    }}
                    src={filePath}
                    className={styles.collection_item_img}
                />
                {/*<div className={styles.collection_item_chain}>*/}
                {/*    <img*/}
                {/*        src="/assets/img/eth-black.svg"*/}
                {/*        alt="eth"*/}
                {/*        className={styles.collection_item_chain_icon}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className={styles.collection_item_dec}>*/}
                {/*    <img*/}
                {/*        src="/assets/img/plus.svg"*/}
                {/*        alt="plus"*/}
                {/*        className={styles.collection_item_icon}*/}
                {/*    />*/}
                {/*</div>*/}

                {isVideo(token.file_2) && (
                    <div className="collection__item--video">
                        <img
                            src="/assets/img/video.svg"
                            alt="video"
                            className="collection__item--video--icon"
                        />
                    </div>
                )}
            </a>

            <div className="collection__item--data--inner">
                <p className="collection__item--title">{token.name}</p>

                <p className="collection__item--text">
                    {token.paid ? `Продан за ${Math.floor(token.price/100)} RUB` : `${Math.floor(token.price/100)} RUB`}
                </p>

                <p className="collection__item--button--text">{tokenCardSign}</p>

                {action}
            </div>

            <div className={styles.tokenButtonInner}>
                {!token.paid &&
                  <div className={styles.tokenButtonAction} onClick={() => window.location.replace(`/token/${token.id}`)}>
                       Купить
                  </div>
                }

                {/*<div className={styles.actionWrapper} ref={actionMenuRef}>*/}
                {/*    <div className={styles.actionContent} onClick={() => setActionMenu(prev => !prev)}>*/}
                {/*        <img src="/assets/img/dots-white.svg" alt="dots" />*/}
                {/*    </div>*/}

                {/*    {actionMenu && <div className={styles.actionContentInner}>*/}
                {/*        <p className={styles.actionPoint}>Mint</p>*/}
                {/*        <p className={styles.actionPoint}>Transfer</p>*/}
                {/*        <p className={styles.actionPoint}>Hide</p>*/}
                {/*    </div>}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default React.memo(TokenItem);
