import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../../context/NotificationContext';
import { useGetCollectionQuery } from '../../../redux/api/dataService';
import { usePostCollectionTokensForCheckMutation } from '../../../redux/api/ugcService';
import { normilizeError } from '../../../utils/http/normilizeError';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { useGetFilteredTokensQuery } from '../../../redux/api/handleService';
import { FiltersContext, SORT_BY_OPTIONS } from './FiltersContext';
import useDebounce from '../../../hooks/useDebounce';

const DEFAULT_STATE = {
    collectionTokensPage: 1,
    collectionTokensCount: 0,
    collectionTokens: [],
    collection: {},
    bookedTokensIds: [],
    query: '',
    sortBy: SORT_BY_OPTIONS.DESCENDING.value,
    status: [],
    levels_stats_ids: [],
    properties_id: [],
};

const COLLECTION_TOKENS_PER_PAGE = 12;

const CollectionContext = React.createContext({});

const CollectionContextProvider = (props) => {
    const { children, id } = props;

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const {
        state: { activeStatusesFilter, activePropertiesFilter, activeLevelStatsFilter, sortBy, paid },
        actions: { dropAllFilters },
        debouncedQuery,
    } = useContext(FiltersContext);

    const [state, setState] = useState(DEFAULT_STATE);

    const isCollectionTokensRefetching = useRef(false);

    const {
        data: collection,
        error: getCollectionError,
        isLoading: isCollectionLoading,
    } = useGetCollectionQuery(
        {
            id,
        },
        {
            skip: !id,
        },
    );

    const {
        data: collectionTokens,
        error: getTokensError,
        isLoading: isTokensLoading,
        isFetching: isFetchingLoading,
        refetch: refetchCollectionTokens,
    } = useGetFilteredTokensQuery(
        {
            page: state.collectionTokensPage,
            pageSize: COLLECTION_TOKENS_PER_PAGE,
            collection_ids: [id],

            query: state.query,
            sortBy: state.query ? null : state.sortBy,
            status: state.status,
            paid: paid,
            levels_stats_ids: state.levels_stats_ids,
            properties_id: state.properties_id,
        },
        {
            skip: !id,
        },
    );

    // const [
    //     checkCollectionTokens,
    //     { data: bookedTokensRS, reset: resetCheckCollectionTokens },
    // ] = usePostCollectionTokensForCheckMutation();

    const onNextPageCollectionsTokensHandler = useDebouncedCallback(
        () => {
            setState((p) => {
                if (
                    p.collectionTokensCount > p.collectionTokens.length &&
                    !isCollectionTokensRefetching.current
                ) {
                    isCollectionTokensRefetching.current = true;

                    return {
                        ...p,
                        collectionTokensPage: p.collectionTokensPage + 1,
                    };
                }
                return p;
            });
        },
        [],
        250,
    );

    const refetchCollectionTokensHandler = useCallback(() => {
        dropAllFilters();

        setState((p) => {
            if (p.collectionTokensPage === 1) {
                refetchCollectionTokens();
                return p;
            }

            return {
                ...p,
                collectionTokensPage: 1,
            };
        });
    }, []);

    useEffect(() => {
        if (collection) {
            setState((p) => ({
                ...p,
                collection,
            }));
        }
    }, [collection]);

    useEffect(() => {
        setState((p) => {
            return {
                    ...p,
                    collectionTokensPage: 1,
                };

        })
    }, [paid])

    useEffect(() => {
        if (collectionTokens && collectionTokens.results) {
            setState((p) => ({
                ...p,
                collectionTokensCount: collectionTokens.count,
                collectionTokens:
                    collectionTokens.page === 1
                        ? collectionTokens.results
                        : [...p.collectionTokens, ...collectionTokens.results],
            }));

            isCollectionTokensRefetching.current = false;
        }
    }, [collectionTokens]);

    useEffect(() => {
        if (getTokensError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(getTokensError),
            });
        }
    }, [getTokensError]);

    // useEffect(() => {
    //     if (bookedTokensRS) {
    //         setState((p) => ({
    //             ...p,
    //             bookedTokensIds: bookedTokensRS.tokens,
    //         }));
    //     }
    // }, [bookedTokensRS]);

    useEffect(() => {
        if (getCollectionError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(getCollectionError),
            });
        }
    }, [getCollectionError]);

    // useEffect(() => {
    //     if (collectionTokens && collectionTokens.results && collectionTokens.results.length) {
    //         const ids = collectionTokens.results.map((t) => t.id);
    //
    //         checkCollectionTokens({ tokens: ids });
    //     }
    // }, [collectionTokens]);

    useDebounce(
        () => {
            setState((p) => ({
                ...p,
                collectionTokensPage: 1,
                status: activeStatusesFilter,
                properties_id: activePropertiesFilter.map((el) => el.id),
                levels_stats_ids: activeLevelStatsFilter.map((el) => el.id),
                query: debouncedQuery,
                sortBy,
            }));
        },
        [
            activeStatusesFilter,
            activePropertiesFilter,
            activeLevelStatsFilter,
            debouncedQuery,
            sortBy,
        ],
        200,
    );

    // useEffect(
    //     () => () => {
    //         resetCheckCollectionTokens();
    //     },
    //     [],
    // );

    const actions = {
        // checkCollectionTokens,
        onNextPageCollectionsTokensHandler,
        onCollectionTokensRefetch: refetchCollectionTokensHandler,
    };

    return (
        <CollectionContext.Provider
            value={{
                state,
                actions,
                sortBy,
                isCollectionLoading,
                isTokensLoading,
                isFetchingLoading,
            }}
        >
            {children}
        </CollectionContext.Provider>
    );
};

export { CollectionContext, CollectionContextProvider };
