import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from '../index.module.css';

const BrandItemSkeleton = () => {
    return (
        <div className={styles.brandItemWrapper}>
            <div className={cn(styles.brandItem, styles.skeletonBg)}>
                <div className={styles.brandItemImgInner}>
                    <Skeleton
                        containerClassName={styles.brandItemImgSkeletonWrapper}
                        className={styles.brandItemImgSkeleton}
                    />
                </div>

                <div className={styles.brandItemInfo}>
                    <Skeleton
                        containerClassName={styles.brandItemNameSkeletonWrapper}
                        className={styles.brandItemNameSkeleton}
                    />

                    <Skeleton
                        containerClassName={styles.brandItemTagSkeletonWrapper}
                        className={styles.brandItemTagSkeleton}
                    />

                    <div className={styles.brandItemInfoWrapper}>
                        <div className={styles.brandItemInfoPoints}>
                            <div className={styles.brandItemInfoPoint}>
                                <Skeleton
                                    containerClassName={styles.brandItemPointSkeletonWrapper}
                                    className={styles.brandItemPointSkeleton}
                                />

                                <Skeleton
                                    containerClassName={styles.brandItemPointValueSkeletonWrapper}
                                    className={styles.brandItemPointValueSkeleton}
                                />
                            </div>

                            <div className={styles.brandItemInfoPoint}>
                                <Skeleton
                                    containerClassName={styles.brandItemPointSkeletonWrapper}
                                    className={styles.brandItemPointSkeleton}
                                />

                                <Skeleton
                                    containerClassName={styles.brandItemPointValueSkeletonWrapper}
                                    className={styles.brandItemPointValueSkeleton}
                                />
                            </div>
                        </div>

                        <Skeleton
                            containerClassName={styles.brandItemButtonSkeletonWrapper}
                            className={styles.brandItemButtonSkeleton}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandItemSkeleton;
