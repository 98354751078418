import Modal from "../../../../../../common/Modal";
import React, {useContext} from 'react'
import styles from '../OfferItemModal/offer.module.css'
import {useNotUsedServiceMutation, useUsedServiceMutation} from "../../../../../../redux/api/dataService";
import {NotificationContext} from "../../../../../../context/NotificationContext";
import NOTIFICATION_TYPES from "../../../../../../const/notifications/NOTIFICATION_TYPES";
import {useDispatch} from "react-redux";
import {setIsNoServiceModal} from "../../../../../../redux/slices/auth";


export const UseServiceModal = (
  {
    offer,
    token,
    setIsUsedService,
    isUsedService,
    refetch,
    setIsSuccessUse
  }) => {
  const [usedService] = useUsedServiceMutation()
  const [notUsedService] = useNotUsedServiceMutation()

  const dispatch = useDispatch()

  const {
    actions: { addNotification },
  } = useContext(NotificationContext);

  const handleUsedServiceClick = () => {
    usedService({
        service: offer.id,
        token: token.id
    })
      .unwrap()
      .then(() => {
        setIsSuccessUse(true)
        refetch()
        setIsUsedService(!isUsedService)
      })
      .catch((error) => {
        addNotification({
          type: NOTIFICATION_TYPES.ERROR,
          text: error.data.non_field_errors[0],
        })
      })
      .finally(() => {
        setIsUsedService(!isUsedService)
      })
  }

  const handleNotUsedServiceClick = () => {
    notUsedService({
      body: {
        service: offer.id,
        token: token.id
      }
    })
      .unwrap()
      .then(() => {
        dispatch(setIsNoServiceModal(true))
        refetch()
        setIsUsedService(!isUsedService)
      })
      .catch((error) => {
        addNotification({
          type: NOTIFICATION_TYPES.ERROR,
          text: error.data.non_field_errors[0],
        })
      })
      .finally(() => {
        setIsUsedService(!isUsedService)
      })
  }

  return (
    <>
      <Modal
        active={isUsedService}
        title={offer.company.name}
        onClose={() => setIsUsedService(false)}
      >
        <div style={{display: 'flex', justifyContent: 'flex-start', margin: '8px 0 36px 0', width: '100%', position: 'relative'}}>
          <p style={{color: 'hsla(210, 13%, 91%, 1)', position: 'absolute', top: 0, left: 0}}>Сервис</p>
          <p style={{color: 'hsla(42, 85%, 59%, 1)', margin: '0 auto'}}>{offer.type === 'vip_service' ? 'VIP' : offer.type}</p>
        </div>
        <div className={styles.wrapper}>
          <p style={{color: 'hsla(210, 13%, 91%, 1)', fontWeight: '500', marginBottom: '20px'}}>{offer.preview}</p>
          <p style={{color: 'hsla(210, 13%, 91%, 1)', marginBottom: '36px'}}>{offer.description}</p>
          {offer.contacts && offer.contacts.length && offer.contacts[0].site &&  <a target={'_blank'} href={offer.contacts[0].site}>{offer.contacts[0].site}</a>}
          {offer.contacts && offer.contacts.length && offer.contacts[0].phone &&  <a href={`tel:${offer.contacts[0].phone}`}>{offer.contacts[0].phone}</a>}
          {offer.contacts && offer.contacts.length && offer.contacts[0].social &&  <a target={'_blank'} href={offer.contacts[0].social}>{offer.contacts[0].social}</a>}
          {offer.contacts && offer.contacts.length && offer.contacts[0].email &&  <a href={`mailto:${offer.contacts[0].email}`}>{offer.contacts[0].email}</a>}
        </div>
        <button
          className={`button token__card--content--price--buy`}
          style={{height: '52px', width: '100%'}}
          onClick={handleUsedServiceClick}
        >
          Услуга оказана
        </button>
        <button
          className={`button token__card--content--price--buy`}
          style={{
            height: '52px',
            width: '100%',
            background: 'hsla(0, 54%, 56%, 1)',
            marginTop: '16px'
          }}
          onClick={handleNotUsedServiceClick}
        >
          Услуга не оказана
        </button>
      </Modal>
    </>
  )
}