import React from 'react';

import './index.css';

import StatChart from '../../components/StatChart';
import FilterTable from '../../components/FilterTable';
import TableCell from '../../components/TableRow/TableCell';
import TableRow from '../../components/TableRow';
import { BREAKPOINTS } from '../../const/breakpoints/BREAKPOINTS';
import { Link } from 'react-router-dom';
import FullDescription from '../../common/FullDescription';
import InfoBlock from '../../common/InfoBlock';
import Modal from '../../common/Modal';
import Input from '../../common/Input';

const sortList = [{ value: 'recent', name: 'Recent changes' }];

const sortList2 = [{ value: '90', name: 'Last 90 Days' }];

const Treasure = () => {
    const [modalMint, setModalMint] = React.useState(false);
    const [filter, setFilter] = React.useState('recent');
    const [filter2, setFilter2] = React.useState('90');
    const [filtersContent, setFiltersContent] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            name: 'Listed',
            active: false,
        },
        {
            name: 'Booked',
            active: false,
        },
        {
            name: 'Minted',
            active: false,
        },
        {
            name: 'Transfer',
            active: false,
        },
        {
            name: 'Sold',
            active: false,
        },
        {
            name: 'Booked (Sold)',
            active: false,
        },
        {
            name: 'No price',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            name: 'Brand name',
            brand: '/assets/img/person9.png',
            active: true,
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person8.png',
            active: true,
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person7.png',
            active: true,
        },
    ]);
    const [filterData3, setFilterData3] = React.useState([
        {
            name: 'Collection name',
            brand: '/assets/img/person1.png',
        },
        {
            name: 'Collection name',
            brand: '/assets/img/person2.png',
        },
        {
            name: 'Collection name',
            brand: '/assets/img/person3.png',
        },
    ]);
    const [filterData4, setFilterData4] = React.useState([
        {
            name: 'Attribute 1'
        },
        {
            name: 'Attribute 2'
        },
        {
            name: 'Attribute 3'
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    const chartOption = {
        chart: {
            width: "100%",
            height: 210,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                columnWidth: "25%",
                distributed: true,
            },
        },
        colors: ['#2081e2'],
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: ["", "", "3/7", "", "", "3/10", "", "", "3/13", "", "", "3/16", "", "", "3/19", "", "", "3/22", "", "", "3/26", "", "", "3/28", "", "", "3/31", "", "", "4/3"],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                style: {
                    colors: "#fff",
                    fontFamily: "Gilroy, sans-serif",
                    fontSize: "12px"
                }
            }
        },
        yaxis: {
            min: 0,
            max: 10,
            tickAmount: 2,
            labels: {
                style: {
                    colors: "#fff",
                    fontFamily: "Gilroy, sans-serif",
                    fontSize: "12px"
                }
            }
        },
    };

    const chartSeries = [
        {
            data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        },
    ];

    return(
        <section className="default__padding activity">
            <div className="container">
                <div className="activity__inner">
                    <h2 className="aboutblock__title">Treasury</h2>

                    <InfoBlock
                        data={[
                            {
                                title: 'CBCOIN',
                                value: `0`,
                            },
                            {
                                title: 'USDT',
                                value: `0`,
                            },
                            {
                                title: 'BNB',
                                value: `0`,
                            }
                        ]}
                    />
                    
                    <FullDescription description="The body manages the stages of the mint, monitors the statistics of submitted applications, application statuses, the content of applications, user names, contacts and wallet addresses. New - a newly received application, Whitelist - an application approved by the moderator, Booked - an application for which the token was booked, Minted - an application for which the token was minted, Redlist - an application suspended by the moderator." />

                    <StatChart 
                        options={chartOption}
                        series={chartSeries}
                    />

                    <FilterTable
                        views
                        placeholderSearchInput="Search by token or collection name"
                        sorts={[
                            {
                                optionsList: sortList2,
                                value: filter2,
                                placeholder: 'Select filter',
                                onChange: filterChange2,
                            },
                            {
                                optionsList: sortList,
                                value: filter,
                                placeholder: 'Select filter',
                                onChange: filterChange,
                            },
                        ]}
                        filters={[
                            {
                                title: "Status",
                                value: "2",
                                elements: filterData,
                                setData: setFilterData,
                                filters: filtersContent,
                                setFilters: setFiltersContent
                            },
                            {
                                title: "Brands",
                                value: "3",
                                elements: filterData2,
                                setData: setFilterData2,
                                filters: filtersContent,
                                filter: true,
                                setFilters: setFiltersContent
                            },
                            {
                                title: "Collections",
                                value: "4",
                                elements: filterData3,
                                setData: setFilterData3,
                                filters: filtersContent,
                                filter: true,
                                setFilters: setFiltersContent
                            },
                            {
                                title: "Attributes",
                                value: "5",
                                elements: filterData4,
                                setData: setFilterData4,
                                filters: filtersContent,
                                filter: true,
                                setFilters: setFiltersContent
                            }
                        ]}
                        tokensCount="25 days"
                    >
                        <TableRow names>
                            <TableCell title textCenter>
                                Period
                            </TableCell>

                            <TableCell title textCenter>
                                Sold (CBCoin)
                            </TableCell>

                            <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                                Payment (CBCoin)
                            </TableCell>

                            <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                                Collections
                            </TableCell>

                            <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                                NFT
                            </TableCell>

                            <TableCell title textCenter breakpoint={BREAKPOINTS.MD1600}>
                                Circulation (CBCoin)
                            </TableCell>

                            <TableCell title textCenter breakpoint={BREAKPOINTS.MD550}>
                                Received (USDT)
                            </TableCell>
                        </TableRow>

                        <TableRow onClick={() => setModalMint(true)}>
                            <TableCell text>
                                <p className="whithdraw__index">
                                    1
                                </p>

                                <div className="stats__item--wrapper withdraw__item">
                                    <p className="withdraw__item--collection--name">5 days ago</p>
                                </div>
                            </TableCell>

                            <TableCell text textCenter>
                                75.450
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD700}>
                                45.150
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                                ---
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                                ---
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD1600}>
                                30.300
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD550}>
                                75.450
                            </TableCell>
                        </TableRow>
                        
                    </FilterTable>
                </div>
            </div>

            <Modal active={modalMint} setActive={setModalMint} title="Mint CBCOIN">
                <div className="mint__modal--inner">
                    <Input
                        title="Mint CBCOIN"
                        text="Enter the address of the coin recipient"
                        placeholder="Wallet address"
                    />

                    <Input
                        title=""
                        text="Enter the amount of coins issued"
                        placeholder="1000"
                    />

                    <button className="button treasure__mint--button">
                        Mint coins
                    </button>
                </div>
            </Modal>
        </section>
    )
}

export default Treasure;