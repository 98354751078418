import React, {useState} from 'react';

import './index.css';
import FullDescription from '../FullDescription';
import {useDeleteServicesMutation} from "../../redux/api/dataService";
import {useNavigate} from "react-router-dom";

const ModalCompany = ({active, setActive, data, setIsRefetch}) => {
    const [fullDesc, setFullDesc] = React.useState(false);
    const [deleteServices] = useDeleteServicesMutation()


    const navigate = useNavigate()
    const handleDeleteClick = () => {
        deleteServices({
            id: data.id
        })
          .unwrap()
          .then(() => {
              setActive(false)
              setIsRefetch(true)
              setTimeout(() => {
                  setIsRefetch(false)
              })
        })
    };

    return(
      <>

          <div className={`modal${active ? ' active' : ''}`}>
              <div className="modal__inner">
                  <div className="modal__content">
                      <div className="modal__content--top">
                          {/*<div className="modalcompany__likes">*/}
                          {/*    <img src="/assets/img/like.svg" alt="like" className="modalcompany__likes--icon" />*/}

                          {/*    16*/}
                          {/*</div>*/}

                          <h2 className="modal__title">
                              {data.company.name}
                          </h2>

                          <img src="/assets/img/cross2.svg" alt="Cross" className="modalcompany__close" onClick={() => setActive(false)} />
                      </div>

                      <div className="modal__content--bottom modalcompany__content--bottom">
                          <p className="modalcompany__text vip">
                              {data.type === 'vip_service' && 'VIP Service'}
                              {data.type === 'pass_to' && 'Pass to'}
                              {data.type === 'discount' && 'Discount'}
                              {data.type === 'gift' && 'Gift'}
                          </p>

                          <p className="modalcompany__subtext">
                              {data.preview}
                          </p>

                          <FullDescription description={data.description} />

                          {data.contacts && data.contacts.length > 0 &&
                            <div className="modalcompany__address--inner">
                                <div className="modalcompany__address--item">
                                    <p className="modalcompany__address--text">
                                        {data.contacts[0].site}
                                    </p>

                                    <p className="modalcompany__address--text">
                                        {data.contacts[0].phone}
                                    </p>

                                    <p className="modalcompany__address--text">
                                        {data.contacts[0].social}
                                    </p>

                                    <p className="modalcompany__address--text">
                                        {data.contacts[0].email}
                                    </p>
                                </div>

                                <div className="modalcompany__address--item">
                                    <p className="modalcompany__address--text">
                                        Address:
                                    </p>

                                    <p className="modalcompany__address--text">
                                        {data.contacts[0].state}, {data.contacts[0].city}, {data.contacts[0].address}
                                    </p>

                                    {/*<p className="modalcompany__address--text seemore">*/}
                                    {/*    See 5 more addresses*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                          }

                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', marginTop: '20px'}}>
                              <p className="modalcompany__address--text" style={{marginTop: '10px'}}>
                                  Manager telegram:
                              </p>
                              <p style={{marginTop: '5px'}} className="modalcompany__address--text">{data.manager_telegram ? data.manager_telegram : 'Not specified'}</p>
                              <p className="modalcompany__address--text" style={{marginTop: '10px'}}>
                                  Manager whatsapp:
                              </p>
                              <p style={{marginTop: '5px'}} className="modalcompany__address--text">{data.manager_whatsapp ? data.manager_whatsapp : 'Not specified'}</p>
                          </div>


                          <div className="modalcompany__buttons">
                              <button
                                onClick={() => {
                                    setActive(false)
                                    navigate(`/admin/editservice/${data.id}`)
                                }}
                                className="button modalcompany__button"
                              >
                                  Edit
                              </button>

                              <button
                                onClick={handleDeleteClick}
                                className="button modalcompany__button delete"
                              >
                                  Delete
                              </button>
                          </div>

                          <div className="modalcompany__filter">
                              <div className="collection__search--inner">
                                  <input type="text" className="input header__search" placeholder="Filter" />

                                  <img
                                    src="/assets/img/search.svg"
                                    alt="search"
                                    className="header__search--icon"
                                  />
                              </div>

                              <div className="modal__filter--box--values">
                                  {data.collections.map(item => {
                                      return (
                                        <div className="collection__filter--box--value">
                                            <div className="modal__filter--value--text">
                                                <img
                                                  className="collection__filter--box--item--img"
                                                  alt="logo" src={item.logo}
                                                />
                                                {item.name}
                                            </div>
                                        </div>
                                      )
                                  })}
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </>

    )
}

export default ModalCompany;