import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { memo, useEffect, useState, useRef } from 'react';

const fetchData = async (setData, list) => {
    const fetchPromises = list.map(async (item) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/${item.resource_id}`,
            );
            const data = await response.json();
            return { data, id: item.role_user_id };
        } catch (error) {
            return { error };
        }
    });
    const results = await Promise.allSettled(fetchPromises);
    const successfulResponses = results
        .filter(({ status }) => status === 'fulfilled')
        .map(({ value }) => value);
    setData(successfulResponses);
};

export const UserResources = memo(({ list, selectedRole, currentRole }) => {
    const [resources, setResources] = useState([]);
    const isRoleSelected = selectedRole === currentRole;
    const token = localStorage.getItem('access_token')
    useEffect(() => {
        if (isRoleSelected) {
            fetchData(setResources, list);
        }
    }, [isRoleSelected]);

    const handleDelete = (id) => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/${id}/access`,
            {
                method: 'DELETE',
                headers:{
                    Authorization: `Bearer ${token}`
                }
            },
        )
            .then((res) => res.json())
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div style={{ width: '100%', position: 'absolute', top: '-40px' }}>
            {isRoleSelected &&
                resources.map((resource) => {
                    return (
                        <div
                            key={Date.now() + Math.random()}
                            className="User_access"
                            style={{
                                borderRadius: 0,
                                backgroundColor: '#0f5192',
                                width: '100%',
                                borderRadius: 0,
                                color: 'white',
                                padding: '10px',
                                maxHeight: '30px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                            }}
                        >
                            <p style={{ fontSize: '11px', color: 'white' }}>{resource.data.name}</p>
                            <button
                                onClick={() => handleDelete(resource.id)}
                                style={{
                                    outline: 'none',
                                    background: 'none',
                                    border: 'none',
                                    color: 'white',
                                    fontWeight: 200,
                                }}
                            >
                                &#10006;
                            </button>
                        </div>
                    );
                })}
        </div>
    );
});
