import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';

const TokenCardSkeleton = () => {
    return (
        <div className="token__card">
            <div className="container">
                <div className="token__card--inner">
                    {/*<div className="mobile__profile--inner">*/}
                    {/*    <div className="profile__ava--inner profile__ava--inner--skeleton">*/}
                    {/*        <Skeleton*/}
                    {/*            borderRadius="50%"*/}
                    {/*            containerClassName="profile__ava--skeleton"*/}
                    {/*            className="skeleton__content"*/}
                    {/*        />*/}
                    {/*    </div>*/}

                    {/*    <Skeleton*/}
                    {/*        containerClassName="profile__owned profile__owned--skeleton"*/}
                    {/*        className="skeleton__content"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="token__card--content">
                        <div className="token__card--left">
                            <div className="token__card--img--inner">
                                <div className="token__card--img token__card--img--inner--skeleton">
                                    <Skeleton
                                        containerClassName="token__card--img--skeleton"
                                        className="skeleton__content"
                                    />
                                </div>
                            </div>

                            <div className="token__card--wrap stack mobileoff">
                                {[...Array(4)].map((_, i) => (
                                    <Skeleton
                                        key={i}
                                        containerClassName="token__card--wrap--skeleton"
                                        className="skeleton__content"
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="token__card--content--right">
                            <div className="token__card--social--wrap">
                                <div className="token__card--content--title--inner token__card--content--title--inner--skeleton">
                                    <Skeleton
                                        containerClassName="token__card--subtitle--skeleton"
                                        className="skeleton__content"
                                    />

                                    <Skeleton
                                        containerClassName="token__card--content--title token__card--content--title--skeleton"
                                        className="skeleton__content"
                                    />

                                    <Skeleton
                                        containerClassName="token__card--content--name token__card--content--name--skeleton"
                                        className="skeleton__content"
                                    />
                                </div>
                            </div>

                            <Skeleton
                                containerClassName="token__card--price--skeleton"
                                className="skeleton__content"
                            />

                            {[...Array(3)].map((_, i) => (
                                <Skeleton
                                    key={i}
                                    containerClassName="token__card--wrap--skeleton mt0"
                                    className="skeleton__content"
                                />
                            ))}
                        </div>
                    </div>

                    {[...Array(2)].map((_, i) => (
                        <Skeleton
                            key={i}
                            containerClassName="token__card--wrap--skeleton mt0"
                            className="skeleton__content"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TokenCardSkeleton;
