import Modal from "../../common/Modal";
import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetTokenQuery} from "../../redux/api/dataService";
import styles from './tokenBuy.module.css'
import Input from "../../common/Input";

export const TokenSuccessBuy = () => {
  const telegramUrl = 'https://t.me/+5nhoVbOlL9dmNjJi'
  const {id} = useParams()
  const navigate = useNavigate()

  const {data} = useGetTokenQuery({id})

  return (
    <Modal
      size={'medium'}
      active={true}
      title={'Поздравляем!'}
      onClose={() => navigate(`/token/${data.id}`)}
    >
      {data && Object.keys(data).length > 0 &&
        <>
          <p className={styles.name}>{data.collection.name}</p>
          <p className={styles.text}>{data.name}</p>

          {data.file_1 && <img src={data.file_1} className={styles.img} alt={'token image'}/>}
          <p
            className={styles.text}
            style={{margin: '20px 0 32px 0'}}
          >
            Вы успешно приобрели сертификат
          </p>

          <button
            className="button token__card--content--price--buy"
            style={{height: '52px', width: '100%', marginTop: 0}}
            onClick={() => {
              window.open(telegramUrl)
              navigate(`/token/${data.id}`)
            }}
          >
            Изучить инструкцию
          </button>
          <p
            className={styles.text}
            style={{margin: '40px 0 10px 0', textAlign: 'start', width:'100%'}}>
            Уже знаете что делать дальше?
          </p>

          <button
            className={`button token__card--content--price--buy ${styles.button}`}
            style={{height: '52px', width: '100%', background: 'hsla(125, 41%, 62%, 0.8)'}}
            onClick={() => navigate(`/token/${data.id}`)}
          >
            Продолжить без инструкции
          </button>
          <div  style={{marginTop: '44px', minWidth: '100%'}}>
            <Input
              placeholder="После нажатия Изучить инструкцию, вы будете перенаправлены в Телеграм канал с инструкцией использования сертификата. Если что то будет не понятно вы всегда сможете связаться со службой заботы по указанным там контактам и получить помощь в любом вопросе. Благодарим вас за то что вы с нами."
              textarea
            />
          </div>

        </>
      }
    </Modal>
  )
}
