import React, { useState } from 'react';
import s from './tooltip.module.css'
import { useClickOut } from '../../../../../hooks/useClickout';

export const Tooltip = ({text, style, styleText}) => {
    const [isClick, setIsClick] = useState(false)

    const ref = useClickOut(() => setIsClick(false))

    return (
        <div className={s.wrapper} style={style} ref={ref}>
            <img
                src={'/assets/img/infoIcon.svg'}
                alt={'info'}
                style={{
                    cursor: 'pointer'
                }}
                onClick={() => setIsClick(!isClick)}
            />
            {isClick &&
                <div className={s.tooltipEl} style={styleText}>
                    <img
                        src={'/assets/img/closeIcon.svg'}
                        alt={'close'}
                        className={s.btn}
                        onClick={() => setIsClick(false)}
                    />

                    <p>{text}</p>
                </div>
            }

        </div>
    )
}
