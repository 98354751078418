import React from 'react';

const Instagram2 = ({ ...props }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4.96983C0 2.22947 2.22947 0 4.96983 0H13.0302C15.7706 0 18 2.22947 18 4.96983V13.0302C18 15.7706 15.7706 18 13.0302 18H4.96983C2.22947 18 0 15.7706 0 13.0302V4.96983ZM5.42623 9C5.42623 10.9706 7.02943 12.5738 9 12.5738C10.9706 12.5738 12.5738 10.9706 12.5738 9C12.5738 7.02943 10.9706 5.42623 9 5.42623C7.02943 5.42623 5.42623 7.02943 5.42623 9ZM4.2679 9C4.2679 6.39066 6.39066 4.2679 9 4.2679C11.6093 4.2679 13.7321 6.39066 13.7321 9C13.7321 11.6093 11.6093 13.7321 9 13.7321C6.39066 13.7321 4.2679 11.6093 4.2679 9ZM14.7643 1.90884C15.4503 1.90884 16.0064 2.46493 16.0064 3.15089C16.0064 3.83688 15.4503 4.39297 14.7643 4.39297C14.0784 4.39297 13.5223 3.83688 13.5223 3.15089C13.5223 2.46493 14.0784 1.90884 14.7643 1.90884Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Instagram2;
