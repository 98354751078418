import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import FilterTable from '../../../components/FilterTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TableNavigation } from '../../../components/TableNavigation/TableNavigation';
import { useLocation } from 'react-router-dom';
import { handleChangeCheckboxState } from './utils';
import { PAGE_FILTERS } from './utils';
import { setParentsList } from './tableSlice';

export const TableContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const nodesList = useSelector((state) => state.tableSlice.parentsList, shallowEqual);
    const [viewType, setViewType] = React.useState([
        { title: 'brands', state: false, src: '/assets/img/view3.svg' },
        { title: 'collections', state: false, src: '/assets/img/view1.svg' },
        { title: 'packs', state: false, src: '/assets/img/view2.svg' },
        { title: 'tokens', state: false, src: '/assets/img/view4.svg' },
    ]);
    const [count,setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState({ isFullListMode: false, type: null, name: '' });
    const [refetch, setRefetch] = useState(false);
    const [pageFilters, setPageFilters] = useState();
    const [requestParams, setrequestParams] = useState([
        {
            title: 'status_store',
            value: '',
        },
        {
            title: 'page_size',
            value: 20,
        },
        {
            title: 'q',
            value: '',
        },
    ]);
    const handleChangeTableView = (checkbox) => {
        if (checkbox) {
            const { state, title } = checkbox;
            if (state) {
                navigate(`../${title}?all=true`);
            }
            if (!state) {
                dispatch(setParentsList([]))
                navigate(`../`);
            }
        }
    };
    useEffect(() => {
        if (currentPage.isFullListMode) {
            setViewType((prev) => {
                return prev.map((checkbox) => {
                    if (checkbox.title === currentPage.type) {
                        return { ...checkbox, state: true };
                    }
                    return { ...checkbox, state: false };
                });
            });
        }
    }, [currentPage]);

    const handleSearch = (e) => {
        setrequestParams((prev) => {
            return prev.map((param) => {
                if (param.title === 'q') {
                    return { ...param, value: e };
                }
                return param;
            });
        });
        setRefetch(true);
    };

    const changeFilterState = useCallback((checkbox) => {
        setRefetch(true);
        handleChangeCheckboxState(setPageFilters, checkbox, setrequestParams);
    }, []);

    useEffect(() => {
        if (currentPage && currentPage.type && PAGE_FILTERS[currentPage.type]) {
            setPageFilters(
                PAGE_FILTERS[currentPage.type].map((filter) => {
                    return { ...filter, onChange: changeFilterState };
                }),
            );
        }
    }, [PAGE_FILTERS, currentPage]);

    const searchValue = useMemo(() => {
        const searchField = requestParams.find((param) => param.title === 'q');
        return searchField.value;
    }, [requestParams]);

    useEffect(() => {
        if (currentPage && currentPage.name === 'Категории') {
            dispatch(setParentsList([]))
        }
    },[currentPage])
    return (
        <div className="admins__inner">
            {nodesList && (
                <TableNavigation
                    linksList={nodesList}
                    currentPage={currentPage}
                    setCurrentPage={setViewType}
                />
            )}
            <FilterTable
                views={location.pathname !== '/admin/statistics2' ? true : false}
                viewTableType={'statistics2'}
                handleChangeView={handleChangeTableView}
                viewType={viewType}
                setViewType={setViewType}
                search={searchValue}
                setSearch={handleSearch}
                handleRefresh={setRefetch}
                filters={pageFilters}
                count={count}
            >
                <Outlet context={[setCurrentPage, refetch, setRefetch, requestParams,setCount]} />
            </FilterTable>
        </div>
    );
};

