import { configureStore, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import authSlice, { refreshToken, refreshTokenRequest, setIsAuth } from './slices/auth';
import pagesSlice from './slices/pages';
import accountsSlice from './slices/accounts';
import blockchainsSlice from './slices/blockchains';
import tokensSlice from './slices/tokens';
import collectionsSlice from './slices/collections';
import packsSlice from './slices/packs';
import {
    aplyToWhitelistDialogSlice,
    deleteEntityDialogSlice,
    confirmAplicationDialogSlice,
} from './dialogs';
import { authApi } from './api/authService';
import { dataApi } from './api/dataService';
import { userApi } from './api/userService';
import { ugcApi } from './api/ugcService';
import { packsFactoryReducer } from '../pages/Factory/Packs/slice/packsSlice';
import whitelistsSlice from './slices/admin/whitelists';
import { handleApi } from './api/handleService';
import {tokensStatisticReducer} from "../pages/Statistics2/Tokens/slice/tokenSlice";
import {packsStatisticReducer} from "../pages/Statistics2/Packs/slice/packsSlice";
import {collectionStatisticReducer} from "../pages/Statistics2/Collections/slice/collectionSlice";
import {pagesStatisticReducer} from "../pages/Statistics2/Pages/slice/pagesSlice";
import {brandsStatisticReducer} from "../pages/Statistics2/Brands/slice";
import { billingApi } from './api/billingService';
import { conclusionsReducer } from '../pages/Сonclusions/slice/slice';
import windowSizeReducer from './slices/windowSizeSlice'
import { brandsStoreReducer } from '../pages/Store/Brands/slice';
import { collectionStoreReducer } from '../pages/Store/Collections/slice/collectionSlice';
import { pagesStoreducer } from '../pages/Store/Pages/slice/pagesSlice';
import { tokensStoreReducer } from '../pages/Store/Tokens/slice/tokenSlice';
import { collectionFactoryReducer } from '../pages/Factory/Collections/slice/collectionSlice';
import { tokensFactoryReducer } from '../pages/Factory/Tokens/slice/tokenSlice';
import { packsStoreReducer } from '../pages/Store/Packs/slice/packsSlice';
import { pagesFactoryReducer } from '../pages/Factory/Pages/slice/pagesSlice';
import { brandsFactoryReducer } from '../pages/Factory/Brands/slice';
import { tableReducer } from '../pages/Statistics2/Container/tableSlice';
const REFRESH_AUTH_INTERVAL_IN_MS = 5 * 60 * 1000;

const listenerMiddleware = createListenerMiddleware();

let interval;

listenerMiddleware.startListening({
    actionCreator: setIsAuth,
    effect: async (action, { dispatch }) => {
        if (action.payload) {
            interval = setInterval(() => {
                dispatch(refreshTokenRequest());
            }, REFRESH_AUTH_INTERVAL_IN_MS);
        } else {
            clearInterval(interval);
        }
    },
});

export const store = configureStore({
    reducer: {
        auth: authSlice,
        pages: pagesSlice,
        accounts: accountsSlice,
        blockchains: blockchainsSlice,
        tokens: tokensSlice,
        collections: collectionsSlice,
        packs: packsSlice,
        whitelists: whitelistsSlice,
        tokensStatisticSlice: tokensStatisticReducer,
        conclusionsSlice: conclusionsReducer,
        packsStatisticSlice: packsStatisticReducer,
        collectionStatisticSlice: collectionStatisticReducer,
        pagesStatisticSlice: pagesStatisticReducer,
        brandsStatisticSlice: brandsStatisticReducer,
        brandsStoreSlice:brandsStoreReducer,
        collectionStoreSlice:collectionStoreReducer,
        deleteEntityDialog: deleteEntityDialogSlice,
        pagesStoreSlice:pagesStoreducer,
        aplyToWhitelistDialog: aplyToWhitelistDialogSlice,
        confirmAplicationDialog: confirmAplicationDialogSlice,
        windowSize: windowSizeReducer,
        tokensStoreSlice:tokensStoreReducer,
        packsFactorySlice:packsFactoryReducer,
        collectionFactorySlice:collectionFactoryReducer,
        tokensFactorySlice:tokensFactoryReducer,
        packsStoreSlice:packsStoreReducer,
        pagesFactorySlice:pagesFactoryReducer,
        brandsFactorySlice:brandsFactoryReducer,
        tableSlice:tableReducer,
        [authApi.reducerPath]: authApi.reducer,
        [dataApi.reducerPath]: dataApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [ugcApi.reducerPath]: ugcApi.reducer,
        [handleApi.reducerPath]: handleApi.reducer,
        [billingApi.reducerPath]: billingApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
          .prepend(listenerMiddleware.middleware)
          .concat(dataApi.middleware, authApi.middleware, handleApi.middleware, userApi.middleware, ugcApi.middleware, billingApi.middleware),
});
