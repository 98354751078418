export const transferTokenTitles = [
    'Команда CheckBrand поможет вашим клиентам получить цифровые сертификаты',
    'После того, как Вы разместите QR-коды на своей продукции, покупателям останется только отсканировать их, и CheckBrand автоматически передаст им цифровые сертификаты.',
    'Круглосуточная служба поддержки CheckBrand всегда готова помочь, если у Ваших клиентов возникнут какие-либо вопросы.',
    'Так Вы сможете сосредоточиться на удовлетворении растущего  спроса на Ваши товары, а техническая часть будет под контролем  CheckBrand.',
];

export const qrGivingTitles = [
    'На этом этапе мы передадим Вам уникальные QR-кода для нанесения на продукцию',
    'Эти QR-коды нужны для связи физического товара с его цифровой версией.',
    'Их можно разместить прямо на Вашей продукции - например, в виде скрытой бирки или как часть общего дизайна.',
    'Или же передавать покупателям отдельно, в качестве брелоков или карточек бренда.',
    'Специалисты CheckBrand помогут выбрать лучший способ размещения QR-кодов, чтобы максимально эффективно оцифровать Ваши товары.',
];