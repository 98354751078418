import React from 'react'
import s from './index.module.css'
import { useClickOut } from '../../../../hooks/useClickout';

export const СonclusionMenu = ({setIsConditionModalOpen, setIsOpenMenu}) => {
    const ref = useClickOut(() => setIsOpenMenu(false))

    return (
        <div className={s.wrapper} ref={ref}>
            <button
                className={s.item}
                onClick={() => {
                    setIsConditionModalOpen(true)
                    setIsOpenMenu(false)
                }}
            >
                <img src="/assets/img/conclusion.svg" alt="conclusion" />
                <p>Вывести</p>
            </button>
            <button
                className={s.item}
                onClick={() => {
                    setIsConditionModalOpen(true)
                    setIsOpenMenu(false)
                }}
            >
                <img src="/assets/img/return.svg" alt="return" />
                <p>Оформить возврат</p>
            </button>
        </div>
    )
}
