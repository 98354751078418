import React from 'react';
import {Link} from 'react-router-dom';

const MenuLink = ({ hasChildren = false, ancor = false, link = "", closeMenu, icon = "", text, setActiveLayerMenu = false, openLayerMenu}) => {
    const closeMenuLayer = () => {
        if(hasChildren){
            openLayerMenu(true);
        }
        else{
            closeMenu();
        }
        
        if(setActiveLayerMenu){
            setActiveLayerMenu(false);
        }
    }

    return (
        <>
            {(hasChildren || ancor) ? (
                <button className="button mobile__nav--link" onClick={closeMenuLayer}>
                    <span className="mobile__nav--link--wrapper">
                        {icon && <img
                            src={icon}
                            alt="admin"
                            className="mobile__nav--link--icon"
                        />}

                        {text}
                    </span>

                    {hasChildren && <img src="/assets/img/mobilemenu-arrow.svg" alt="arrow" />}
                </button>
            ) : (
                <Link to={`/${link}`} className="mobile__nav--link" onClick={closeMenuLayer}>
                    <span className="mobile__nav--link--wrapper">
                        {icon && <img
                            src={icon}
                            alt="admin"
                            className="mobile__nav--link--icon"
                        />}

                        {text}
                    </span>
                </Link>
            )}
        </>
    );
};

export default MenuLink;
