import React from 'react';

const Raise = ({ ...props }) => {
    return (
        <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_14618_2757)">
                <path
                    d="M12.8337 8.2513H9.16699C8.92388 8.2513 8.69072 8.34788 8.51881 8.51979C8.3469 8.6917 8.25033 8.92485 8.25033 9.16797V20.168C8.25033 20.4111 8.3469 20.6442 8.51881 20.8162C8.69072 20.9881 8.92388 21.0846 9.16699 21.0846H12.8337C13.0768 21.0846 13.3099 20.9881 13.4818 20.8162C13.6537 20.6442 13.7503 20.4111 13.7503 20.168V9.16797C13.7503 8.92485 13.6537 8.6917 13.4818 8.51979C13.3099 8.34788 13.0768 8.2513 12.8337 8.2513ZM11.917 19.2513H10.0837V10.0846H11.917V19.2513ZM20.167 0.917969H16.5003C16.2572 0.917969 16.0241 1.01455 15.8521 1.18645C15.6802 1.35836 15.5837 1.59152 15.5837 1.83464V20.168C15.5837 20.4111 15.6802 20.6442 15.8521 20.8162C16.0241 20.9881 16.2572 21.0846 16.5003 21.0846H20.167C20.4101 21.0846 20.6433 20.9881 20.8152 20.8162C20.9871 20.6442 21.0837 20.4111 21.0837 20.168V1.83464C21.0837 1.59152 20.9871 1.35836 20.8152 1.18645C20.6433 1.01455 20.4101 0.917969 20.167 0.917969ZM19.2503 19.2513H17.417V2.7513H19.2503V19.2513ZM5.50033 13.7513H1.83366C1.59054 13.7513 1.35739 13.8479 1.18548 14.0198C1.01357 14.1917 0.916992 14.4249 0.916992 14.668V20.168C0.916992 20.4111 1.01357 20.6442 1.18548 20.8162C1.35739 20.9881 1.59054 21.0846 1.83366 21.0846H5.50033C5.74344 21.0846 5.9766 20.9881 6.14851 20.8162C6.32042 20.6442 6.41699 20.4111 6.41699 20.168V14.668C6.41699 14.4249 6.32042 14.1917 6.14851 14.0198C5.9766 13.8479 5.74344 13.7513 5.50033 13.7513ZM4.58366 19.2513H2.75033V15.5846H4.58366V19.2513Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_14618_2757">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Raise;
