import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import { TableContainer } from './Container/Container';
import { TableContainer } from '../../components/TableContainer/Container';
import Pages from './Pages';
import Brands from './Brands';
import Collections from './Collections';
import Packs from './Packs';
import Tokens from './Tokens';
import CreateAccount from '../CreateAccount';
import CreatePage from '../CreatePage';
import CreateCollection from '../CreateCollection';

const Store = () => {
    return (
        <section className="statistics">
            <div className="container">
                <Routes>
                    <Route path="/" element={<TableContainer />}>
                        <Route index element={<Pages />} />
                        <Route path="/brands/:pageId" element={<Brands />} />
                        <Route path="/collections/:pageId/:accountId" element={<Collections />} />
                        <Route path="/packs/:pageId/:collectionId/:accountId" element={<Packs />} />
                        <Route
                            path="/tokens/:pageId/:collectionId/:packId/:accountId"
                            element={<Tokens />}
                        />
                        <Route path="/brands/" element={<Brands />} />
                        <Route path="/collections/" element={<Collections />} />
                        <Route path="/packs/" element={<Packs />} />
                        <Route path="/tokens/" element={<Tokens />} />
                        <Route
                            path="updatecollection/:id/:pageId"
                            element={<CreateCollection isEdit />}
                        />
                        <Route path="changepage/:url" element={<CreatePage isEdit />} />
                        <Route path="changeaccount/:id" element={<CreateAccount isEdit />} />
                    </Route>
                </Routes>
            </div>
        </section>
    );
};

{
    /* <Route index element={<Pages />} />
                    <Route path="/brands/:pageId" element={<Brands />} />
                    <Route path="/collections/:pageId/:accountId" element={<Collections />} />
                    <Route path="/packs/:pageId/:collectionId/:accountId" element={<Packs />} />
                    <Route path="/tokens/:pageId/:collectionId/:packId/:accountId" element={<Tokens />} />
                    <Route path="*" element={<Navigate to="/" />} /> */
}

{
    /* <Routes>
                    <Route path="/" element={<TableContainer />}>
                        <Route index element={<Pages />} />
                        <Route path="/brands/:pageId" element={<Brands />} />
                        <Route path="/collections/:pageId/:accountId" element={<Collections />} />
                        <Route path="/packs/:pageId/:collectionId/:accountId" element={<Packs />} />
                        <Route
                            path="/tokens/:pageId/:collectionId/:packId/:accountId"
                            element={<Tokens />}
                        />
                        <Route path="/brands/" element={<Brands />} />
                        <Route path="/collections/" element={<Collections />} />
                        <Route path="/packs/" element={<Packs />} />
                        <Route path="/tokens/" element={<Tokens />} />
                        <Route
                            path="updatecollection/:id/:pageId"
                            element={<CreateCollection isEdit />}
                        />
                        <Route path="changepage/:url" element={<CreatePage isEdit />} />
                        <Route path="changeaccount/:id" element={<CreateAccount isEdit />} />
                    </Route>
                </Routes> */
}
export default Store;







