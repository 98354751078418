import React, { useState, useMemo, useEffect, memo } from 'react';
import ActionMenu from '../../components/ActionMenu';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
const statuses = {
    Administrator: 'admin__status',
    'Super Administrator': 'admin__status',
    'Platform Administrator': 'admin__status',
    'Brand Administrator': 'brand',
    'Collection Administrator': 'collection__status',
    'Whitelist Moderator': 'whitelist',
    'Store Moderator': 'store',
    'Factory Moderator': 'factory',
    'Delivery Moderator': 'delivery',
    'Opportunity Moderator': 'opportunity',
};

const generateQuery = (accessList) => {
    if (accessList !== null) {
        let result = '';
        for (let i = 0; i < accessList.length; i++) {
            if (i > 0) {
                result += `&collection_id=${accessList[i].sub_resource_id}`;
            }
            if (i === 0) {
                result += `collection_id=${accessList[i].sub_resource_id}`;
            }
        }
        return result;
    }
};

const accessToken = localStorage.getItem('access_token');

const handleDeleteRole = (id, setRefetch) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        method: 'DELETE',
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                setRefetch();
                return res.json();
            }
            if (Number(res.status) >= 400) {
                return new Error(res.json());
            }
        })
        .then((response) => {
            console.log(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const AdminsItem = memo(({ data, id, access, setRefetch, setEdit, isEdit }) => {
    const navigate = useNavigate();
    const handleEditRole = () => {
        navigate(`/admin/createadmin/${data.user_profile.public_address}`);
    };
    const [loading, setLoading] = useState(true);
    const [accessList, setAccessList] = useState([]);
    useEffect(() => {
        if (access) {

            fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL
                }/handler_service/api/v1/collection/?${generateQuery(access)}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            )
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                })
                .then((response) => {
                    if (response && response.results) {
                        setAccessList(response.results);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [access]);

    return (
        data && (
            <>
                <div className="stats__item--values">
                    <div className="stats__item--item stats__item--status admins__item--wrap off700">
                        <p className="stats__item--value">{id}</p>

                        <div
                            className={`admins__item--circle ${
                                data && data.role && statuses[data.role.public_name]
                            }`}
                        ></div>

                        <p className="stats__item--status--value whithdraw__status">
                            {data.user_profile && data.user_profile.username}
                        </p>
                    </div>

                    <div className="stats__item--item admins__item--wrap">
                        <p className="whithdraw__index--mobile">{id}</p>
                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name">
                                {data && data.role && data.role.public_name}
                            </p>
                        </div>
                    </div>

                    <div
                        className="stats__item--item admins__item--wrap whithdraw__flex"
                        style={{
                            textAlign: 'left',
                            maxWidth: '150px',
                            width: '100%',
                            // maxHeight: '70px',
                            textOverflow: 'elipsis',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <p className="stats__item--value regular">
                            {data.user_profile && data.user_profile.public_address}
                        </p>
                    </div>

                    <div className="stats__item--item stats__item--value admins__item--wrap off700 activeoff1200">
                        <p className="withdraw__item--text off700">
                            {data.creator_profile ? data.creator_profile.username : '-'}
                        </p>
                    </div>

                    <div className="stats__item--item admins__item--wrap off998 activeoff1600">
                        <p className="opportunity__desc">{data.description}</p>
                    </div>

                    <div className="withdraw__item--text admins__item--wrap off1200 activeoff1600">
                        <ol className="opportunity__access">
                            {loading ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                accessList &&
                                accessList.length > 0 &&
                                accessList.map((collection, index) => {
                                    return <li>{collection.name}</li>;
                                })
                            )}
                        </ol>
                    </div>

                    <div className="stats__item--item withdraw__item--text admins__item--wrap off1200 activeoff1600"></div>

                    <div className="stats__item--item stats__item--value admins__item--wrap">
                        <ActionMenu
                            actions={[
                                {
                                    text: 'Изменить',
                                    action: () => handleEditRole(),
                                },
                                {
                                    text: 'Удалить',
                                    action: () => handleDeleteRole(data.id, setRefetch),
                                },
                            ]}
                            id={id}
                        />
                    </div>
                </div>
            </>
        )
    );
})

export default AdminsItem;
