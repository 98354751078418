import React from 'react';

const Light = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M6.09108 13.2809H9.18108V20.4809C9.18108 22.1609 10.0911 22.5009 11.2011 21.2409L18.7711 12.6409C19.7011 11.5909 19.3111 10.7209 17.9011 10.7209H14.8111V3.52087C14.8111 1.84087 13.9011 1.50087 12.7911 2.76087L5.22108 11.3609C4.30108 12.4209 4.69108 13.2809 6.09108 13.2809Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default Light;
