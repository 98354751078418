import React from 'react'
import Modal from '../../../common/Modal';
import s from './cancel.module.css'

export const CancelModal = (
    {
        setActive,
        handleOk,
        active,
        text = 'Подтверждение на отмену записи',
        okBtnText = 'Подтверждаю отмену',
        rejectBtnText = 'Не подтверждаю'
    }) => {
    return (
        <Modal
            active={active}
            title={'Внимание'}
            size={'small'}
            setActive={setActive}
        >
            <p
                style={{
                    color: 'hsla(210, 71%, 50%, 1)'
                }}
            >
                {text}
            </p>
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '50px',
                    width: '100%'
                }}
            >
                <button
                    className={s.btn}
                    onClick={handleOk}
                >
                    {okBtnText}
                </button>
                <button
                    className={s.btn}
                    style={{
                        background: 'hsla(210, 77%, 51%, 1)'
                    }}
                    onClick={() => setActive(false)}
                >
                    {rejectBtnText}
                </button>

            </div>

        </Modal>
    )
}
