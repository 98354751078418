import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './index.module.css';
import { Review } from './components/review/Review';
import { CertificateApplicationForm } from './components/CertificateApplicationForm/CertificateApplicationForm';
import { Welcome } from './components/Welcome/Welcome';
import HowItWorks from './components/HowItWorks/HowItWorks';
import CooperationStages from './components/CooperationStages/CooperationStages';
import BuyTarif from './components/BuyTarif/BuyTarif';
import CompanyBrands from './components/CompanyBrands/CompanyBrands';
import TeamSlider from './components/TeamSlider/TeamSlider';
import Partners from './components/Partners/Partners';
import FaqItems from './components/FaqItems/FaqItems';
import SuccessApplicationModal from './components/SuccessApplicationModal/SuccessApplicationModal';
import StartCollaboration from './components/StartCollaboration/StartCollaboration';

const MainNew = () => {
    // console.log(window.ethereum)

//     useEffect(() => {
// console.log(window.ethereum)
//     },[window.etherum])
    return (
        <div className={styles.marginWrapper}>
            <Welcome />
            <HowItWorks />
            <CooperationStages />
            <BuyTarif />
            <CompanyBrands />
            <TeamSlider />
            <Partners />
            <CertificateApplicationForm />
            <Review />
            <FaqItems />
            <StartCollaboration />
            {window.location.search === '?payment=success' && <SuccessApplicationModal />}
        </div>
    );
};

export default MainNew;
