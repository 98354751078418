import React, { useState } from 'react';

export const useCheckValidationErrors = () => {
    const [errors, setErrors] = useState([])

    const handlerErrors = (err) => {
        const initialArr = []
        for (const key in err) {
            initialArr.push(key)
        }
        setErrors(initialArr)
    }

    const handelClearErrors = (value) => {
        const arr = errors.filter(el => el !== value)
        setErrors(arr)
    }

    return {errors, handlerErrors, handelClearErrors}
}
