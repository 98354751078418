export function generateServiceUrl(search, certificate_type, collection_id) {
  let url = "/services/?";

  if (search) {
    url += `search=${search}&`;
  }

  if (certificate_type) {
    url += `certificate_type=${certificate_type}&`;
  }

  if (collection_id) {
    url += `collection_id=${collection_id}&`;
  }

  return url.slice(0, -1);
}