exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".СonclusionMenu_wrapper__2x7rX {\n    position: absolute;\n    top: 23px;\n    right: -20px;\n    padding: 16px;\n    width: 220px;\n    background: #161717;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 30px;\n    gap: 30px;\n}\n\n.СonclusionMenu_item__2bwFA {\n    display: flex;\n    align-items: center;\n    grid-gap: 16px;\n    gap: 16px;\n    color: white;\n}\n\n.СonclusionMenu_item__2bwFA:hover {\n    opacity: 0.6;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "СonclusionMenu_wrapper__2x7rX",
	"item": "СonclusionMenu_item__2bwFA"
};