import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setParentsList } from './Statistics2/Container/tableSlice';

export const NAME_VARIANTS = {
    [`/`]: 'Категории',
    brands: 'Бренды',
    collections: 'Коллекции',
    packs: 'Паки',
    tokens: 'Токены',
    conclutions:'Выводы'
};

export const useGetTableView = (path, isFullList, setPage, title) => {
    // const isRendered = useRef(true);

    useEffect(() => {
        if (path.includes(title)) {
            setPage({ isFullListMode: isFullList, type: title, name: NAME_VARIANTS[title] });
        }
    }, [path, isFullList, setPage, title]);
};

// `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/account/

const fetchEntity = async (route) => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/${route.title}/${route.id}`,
        {
            headers: {
                authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        },
    );

    // Используем await для того, чтобы дождаться выполнения json()
    const res = await response.json();

    return { name: res.name, id: res.id };
};

export const useGenerateRoutes = (idArray) => {
    const dispatch = useDispatch();
    const firstRender = useRef(true);

    const parentEntities = [
        { name: 'page', type: 'page' },
        { name: 'account', type: 'brands' },
        { name: 'collection', type: 'collections' },
        { name: 'pack', type: 'packs' },
    ];

    useEffect(() => {
        if (firstRender.current) {
            async function fetchByID() {
                dispatch(
                    setParentsList(
                        await Promise.all(
                            idArray.map(async (route, index) => {
                                if (route.id) {
                                    const entity = await fetchEntity(route);
                                    if (entity.name && entity.id) {
                                        return {
                                            name: entity.name,
                                            id: entity.id,
                                            type: parentEntities[index].type,
                                        };
                                    }
                                }
                                if (!route.id) {
                                    return {
                                        name: route.title,
                                        type: parentEntities[index].type,
                                    };
                                }
                            }),
                        ),
                    ),
                );
            }
            fetchByID();

            firstRender.current = false;
        }
    }, [idArray]);
};

// fetch(
//     `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/${route.title}/${route.id}`,
//     {
//         headers: {
//             authorization: `Bearer ${localStorage.getItem('access_token')}`,
//         },
//     },
// )
// .then((res) => {
//     if (Number(res.status) <= 210) {
//         return res.json();
//     }
// })
// .then((response) => {
//     if (response) {

//     }
// })
// .catch((err) => {
//     console.log(err);
// });
// dispatch(
//     setParentsList({
//         name: response.name,
//         id: response.id,
//         type: parentEntities[index].type,
//     }),
// );

// return {
//     id: route,
//     name: Object.keys(NAME_VARIANTS)[index],
// };

// dispatch(
//     setParentsList(
//         idArray.map((route,index) => {
//             return {
//                 id: route,
//                 name: Object.keys(NAME_VARIANTS)[index],
//             };
//         }),
//     ),
// );
