import React from 'react';
import { CollabForm } from '../CollabForm';
import styles from '../../index.module.css';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
const StartCollaboration = () => {
    return (
        <section className={styles.collab}>
            <div className={base.container}>
                <div id="start_cooperation" className={styles.collabInner}>
                    <h2 className={cn(base.title2, styles.collabTitle)}>
                        Начать
                        <br />
                        сотрудничество
                    </h2>

                    <CollabForm />
                </div>
            </div>
        </section>
    );
};

export default StartCollaboration;
