exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal_input__saaVg {\n    margin-bottom: 12px;\n    width: 100%;\n}\n\n.modal_btn__1wQii {\n    width: 100%;\n}\n\n.modal_text__1o35l {\n    margin-top: 16px;\n    color: hsla(210, 13%, 91%, 0.6);\n    font-size: 16px;\n    margin-right: auto;\n}\n\n.modal_welcome__1PO5z {\n    color: hsla(210, 77%, 51%, 1);\n    font-size: 16px;\n}", ""]);

// exports
exports.locals = {
	"input": "modal_input__saaVg",
	"btn": "modal_btn__1wQii",
	"text": "modal_text__1o35l",
	"welcome": "modal_welcome__1PO5z"
};