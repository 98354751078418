import React, { memo, useCallback, useEffect, useState } from 'react';

import css from './index.module.css';

export const InputField = memo(({ value, setValue, placeholder, onInputChange,onEnterSearch }) => {
    const handleSetValue = (e) => {
        setValue(e.target.value);
    };

    const handleClearValue = () => {
        setValue('');
    };

    return (
        <div className={css.collectionSearchInner}>
            <input
                type="text"
                className={`input ${css.headerSearch}`}
                placeholder={placeholder}
                value={value}
                onChange={handleSetValue}
                onKeyDown={(e) => {
                    // console.log(e.target.key)
                    onEnterSearch(e)
                }}
            />

            <img src="/assets/img/search.svg" alt="search" className={css.headerSearchIcon} onClick={(e) => {
                onEnterSearch(e)
            }}/>

            {value && (
                <img
                    src="/assets/img/cross2.svg"
                    alt="cross"
                    className={css.headerSearchRemove}
                    onClick={handleClearValue}
                />
            )}
        </div>
    );
});
