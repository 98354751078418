exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tokenBuy_name__3Glf8 {\n    color: hsla(210, 77%, 51%, 1);\n    font-size: 16px;\n}\n\n.tokenBuy_text__OnsOb {\n    margin-top: 8px;\n    color: hsla(210, 13%, 91%, 1);\n    font-size: 16px;\n}\n\n.tokenBuy_img__1WZZ7 {\n    margin-top: 20px;\n    width: 88px;\n    height: 88px;\n}\n\n.tokenBuy_button__1rVqe {\n    background: hsla(125, 41%, 62%, 0.8);\n    margin-top: 0;\n}\n\n.tokenBuy_button__1rVqe:hover {\n    background: hsla(125, 41%, 62%, 0.8);\n    opacity: 0.9;\n}", ""]);

// exports
exports.locals = {
	"name": "tokenBuy_name__3Glf8",
	"text": "tokenBuy_text__OnsOb",
	"img": "tokenBuy_img__1WZZ7",
	"button": "tokenBuy_button__1rVqe"
};