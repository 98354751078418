import React from 'react';
import Linkify from 'react-linkify';

import './index.css';
import FullDescription from '../../common/FullDescription';

const About = ({ title, text }) => {
    return (
        <>
            <h2 className="aboutblock__title">{title}</h2>

            <FullDescription description={text} />
        </>
    );
};

export default About;
