import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Vk from '../../icons/Vk';
import Youtube from '../../icons/Youtube';
import Instagram from '../../icons/Instagram';
import Telegram2 from '../../icons/Telegram2';
import Phone from '../../icons/Phone';
import { getRandomNumber } from './utils';
import Telegram from '../../icons/Telegram';

const ONLINE_USER_COUNT = getRandomNumber(398, 1043);
const LAST_ORDER_TIME = getRandomNumber(1, 5);

const FooterNew = () => {
    const [isSelectContacts, setisSelectContacts] = useState(false);
    return (
        <footer className={styles.footer} id="footer">
            <img src="/assets/img/footer-bg.svg" alt="footer bg" className={styles.footerBg} />

            <div className={styles.footerTop}>
                <div className={base.container}>
                    <div className={styles.footerTopInner}>
                        <div className={styles.footerLogoWrapper}>
                            <Link to="/" className={styles.footerLogo}>
                                <img src="/assets/img/logo.svg" alt="logo" />
                            </Link>

                            <div className={styles.footerStats}>
                                <p className={cn(styles.footerStat, styles.footerOnline)}>
                                    Онлайн: <span>{ONLINE_USER_COUNT}</span>
                                </p>

                                <p className={cn(styles.footerStat, styles.footerOrder)}>
                                    Заказ: <span>{LAST_ORDER_TIME} мин. назад</span>
                                </p>
                            </div>
                        </div>

                        <div className={styles.footerNav}>
                            <div className={styles.footerNavItem}>
                                <Link
                                    className={styles.footerNavLink}
                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                >
                                    Главная
                                </Link>

                                <Link to="/#how_it_work" className={styles.footerNavLink}>
                                    Как это работает
                                </Link>

                                <Link to="/#stages_and_deadlines" className={styles.footerNavLink}>
                                    Этапы и сроки
                                </Link>

                                <Link to="/#rate" className={styles.footerNavLink}>
                                    Тарифы
                                </Link>
                            </div>

                            <div className={styles.footerNavItem}>
                                <Link to="/#brand" className={styles.footerNavLink}>
                                    Бренды
                                </Link>

                                <Link to="/#team" className={styles.footerNavLink}>
                                    Команда
                                </Link>

                                <Link to="/#partner" className={styles.footerNavLink}>
                                    Партнёры
                                </Link>
                            </div>

                            <div className={styles.footerNavItem}>
                                <Link to="/#reviews" className={styles.footerNavLink}>
                                    Отзывы
                                </Link>

                                <Link to="/#faq" className={styles.footerNavLink}>
                                    Ответы и вопросы
                                </Link>

                                <Link
                                    // to="/#footer"
                                    className={styles.footerNavLink}
                                    onClick={() => setisSelectContacts((prev) => !prev)}
                                >
                                    Контакты
                                </Link>
                            </div>
                        </div>

                        <div className={styles.footerContacts}>
                            <a
                                href="tel:89223120735"
                                className={styles.footerPhone}
                                style={{ color: isSelectContacts ? '#ff7327' : '' }}
                            >
                                <span>
                                    <Phone />
                                </span>
                                8 922 312-07-35
                            </a>

                            <a
                                href="https://t.me/check_brand"
                                className={cn(styles.footerPhone, styles.footerManager)}
                            >
                                <span>
                                    <Telegram />
                                </span>
                                Написать менеджеру
                            </a>

                            <p className={styles.footerWork}>Ежедневно с 8:00 по 20:00 по МСК</p>

                            <a
                                style={{ color: isSelectContacts ? '#ff7327' : '' }}
                                href="mailto:support@checkbrand.com"
                                className={styles.footerMail}
                            >
                                support@checkbrand.com
                            </a>

                            <div className={styles.footerSocials}>
                                <a target="_blank" href="#" className={styles.footerSocialLink}>
                                    <Vk />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://www.youtube.com/@bratouverie4787"
                                    className={styles.footerSocialLink}
                                >
                                    <Youtube />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://www.instagram.com/bratouverie/"
                                    className={styles.footerSocialLink}
                                >
                                    <Instagram />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://t.me/bratouverie"
                                    className={styles.footerSocialLink}
                                >
                                    <Telegram2 />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.footerBottom}>
                <div className={base.container}>
                    <div className={styles.footerBottomInner}>
                        <div className={styles.footerBottomLinks}>
                            <Link to="https://t.me/+5nhoVbOlL9dmNjJi" target='_blank' className={styles.footerBottomLink}>
                                Инструкция
                            </Link>

                            <Link to="/ofer" className={styles.footerBottomLink}>
                                Оферта
                            </Link>

                            <Link to="/refund" className={styles.footerBottomLink}>
                                Возврат средств
                            </Link>

                            <Link to="/privacy" className={styles.footerBottomLink}>
                                Политика конфиденциальности
                            </Link>

                            <Link to="/rules" className={styles.footerBottomLink}>
                                Пользовательское соглашение
                            </Link>
                        </div>

                        <p className={styles.footerCopy}>&copy; CheckBrand, 2024</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterNew;
