import React from 'react';
import cn from 'classnames';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';


import ModalNew from '../../../../common/ModalNew';

export const ReviewModalThx = ({ reviewModalThx, setReviewModalThx }) => {
  return (
    <ModalNew active={ reviewModalThx } setActive={ setReviewModalThx }>
      <div className={ styles.modalSuccess }>
        <svg
          width="77"
          height="77"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.5 4.01172C39.8289 4.01172 40.9062 5.08903 40.9062 6.41797V12.8346C40.9062 14.1636 39.8289 15.2409 38.5 15.2409C37.1711 15.2409 36.0938 14.1636 36.0938 12.8346V6.41797C36.0938 5.08903 37.1711 4.01172 38.5 4.01172Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.7449 8.63138C56.8958 9.29585 57.2901 10.7675 56.6256 11.9184L53.4173 17.4753C52.7528 18.6262 51.2812 19.0205 50.1303 18.356C48.9794 17.6916 48.5851 16.2199 49.2495 15.069L52.4579 9.51211C53.1223 8.36122 54.594 7.9669 55.7449 8.63138Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.3688 21.2543C69.0333 22.4052 68.639 23.8768 67.4881 24.5413L61.9311 27.7496C60.7802 28.4141 59.3085 28.0198 58.6441 26.8689C57.9796 25.718 58.3739 24.2464 59.5248 23.5819L65.0818 20.3736C66.2327 19.7091 67.7043 20.1034 68.3688 21.2543Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.7603 38.5C61.7603 37.1711 62.8376 36.0938 64.1665 36.0938H70.5832C71.9121 36.0938 72.9894 37.1711 72.9894 38.5C72.9894 39.8289 71.9121 40.9062 70.5832 40.9062H64.1665C62.8376 40.9062 61.7603 39.8289 61.7603 38.5Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.6441 50.1293C59.3085 48.9784 60.7802 48.5841 61.9311 49.2486L67.4881 52.4569C68.639 53.1214 69.0333 54.593 68.3688 55.7439C67.7043 56.8948 66.2327 57.2891 65.0818 56.6246L59.5248 53.4163C58.3739 52.7518 57.9796 51.2802 58.6441 50.1293Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.1303 58.6431C51.2812 57.9786 52.7528 58.3729 53.4173 59.5238L56.6256 65.0808C57.2901 66.2317 56.8958 67.7034 55.7449 68.3678C54.594 69.0323 53.1223 68.638 52.4579 67.4871L49.2495 61.9301C48.5851 60.7792 48.9794 59.3076 50.1303 58.6431Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.5 61.7617C39.8289 61.7617 40.9062 62.839 40.9062 64.168V70.5846C40.9062 71.9136 39.8289 72.9909 38.5 72.9909C37.1711 72.9909 36.0938 71.9136 36.0938 70.5846V64.168C36.0938 62.839 37.1711 61.7617 38.5 61.7617Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.8699 58.6431C28.0208 59.3076 28.4151 60.7792 27.7506 61.9301L24.5423 67.4871C23.8778 68.638 22.4062 69.0323 21.2553 68.3678C20.1044 67.7034 19.7101 66.2317 20.3745 65.0808L23.5829 59.5238C24.2473 58.3729 25.719 57.9786 26.8699 58.6431Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3556 50.1293C19.02 51.2802 18.6257 52.7518 17.4748 53.4163L11.9179 56.6246C10.767 57.2891 9.29536 56.8948 8.63089 55.7439C7.96642 54.593 8.36073 53.1214 9.51162 52.4569L15.0686 49.2486C16.2194 48.5841 17.6911 48.9784 18.3556 50.1293Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.01025 38.5C4.01025 37.1711 5.08757 36.0938 6.4165 36.0938H12.8332C14.1621 36.0938 15.2394 37.1711 15.2394 38.5C15.2394 39.8289 14.1621 40.9062 12.8332 40.9062H6.4165C5.08757 40.9062 4.01025 39.8289 4.01025 38.5Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.63089 21.2543C9.29536 20.1034 10.767 19.7091 11.9179 20.3736L17.4748 23.5819C18.6257 24.2464 19.02 25.718 18.3556 26.8689C17.6911 28.0198 16.2194 28.4141 15.0686 27.7496L9.51162 24.5413C8.36073 23.8768 7.96642 22.4052 8.63089 21.2543Z"
            fill="#207CE2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2553 8.63138C22.4062 7.9669 23.8778 8.36122 24.5423 9.51211L27.7506 15.069C28.4151 16.2199 28.0208 17.6916 26.8699 18.356C25.719 19.0205 24.2473 18.6262 23.5829 17.4753L20.3745 11.9184C19.7101 10.7675 20.1044 9.29585 21.2553 8.63138Z"
            fill="#207CE2"
          />
        </svg>

        <p className={ cn(base.title2, styles.collabSuccessTitle) }>Спасибо!</p>

        <p className={ styles.modalSuccessText }>
          Ваш отзыв будет размещён после модерации.
        </p>
      </div>
    </ModalNew>
  );
};
