import React from 'react';
import FilterTable from '../../../components/FilterTable';
import { BREAKPOINTS, BREAKPOINTS_ACTIVE } from '../../../const/breakpoints/BREAKPOINTS';
import { Link } from 'react-router-dom';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';

const sortList = [{ value: 'recent', name: 'Recent changes' }];

const sortList2 = [{ value: '90', name: 'Last 90 Days' }];

const Collections = () => {
    const [filter, setFilter] = React.useState('recent');
    const [filter2, setFilter2] = React.useState('90');
    const [filtersContent, setFiltersContent] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            name: 'Ready',
            active: false,
        },
        {
            name: 'Waiting',
            active: false,
        },
        {
            name: 'Stoped',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            name: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    return (
        <div className="admins__inner">
            <h2 className="title">
                <Link to="../brands">
                    <img src="/assets/img/back-arrow.svg" alt="" />
                </Link>
                Delivery -  <Link to="../brands">Brand name</Link> - Collections
            </h2>

            <FilterTable
                views
                placeholderSearchInput="Search by token or collection name"
                sorts={[
                    {
                        optionsList: sortList2,
                        value: filter2,
                        placeholder: 'Select filter',
                        onChange: filterChange2,
                    },
                    {
                        optionsList: sortList,
                        value: filter,
                        placeholder: 'Select filter',
                        onChange: filterChange,
                    },
                ]}
                filters={[
                    {
                        title: "Status",
                        value: "2",
                        elements: filterData,
                        setData: setFilterData,
                        filters: filtersContent,
                        setFilters: setFiltersContent
                    },
                    {
                        title: "Character 1",
                        value: "3",
                        elements: filterData2,
                        setData: setFilterData2,
                        filters: filtersContent,
                        filter: true,
                        setFilters: setFiltersContent
                    }
                ]}
                tokensCount="316 collections"
            >
                <TableRow names size="medium">
                    <TableCell title>
                        Collection
                    </TableCell>

                    <TableCell title textCenter>
                        Items
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD700} breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}>
                        Description
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200} breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1200}>
                        Admin
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200} breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}>
                        Moderator
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD550}>
                        Chain
                    </TableCell>
                </TableRow>

                <TableRow size="medium" actionType="none">
                    <TableCell>
                        <p className="whithdraw__index">
                            1
                        </p>

                        <div className="stats__item--avatar--inner withdraw__item">

                        </div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../packs" className="withdraw__item--collection--name">Collection name</Link>
                        </div>
                    </TableCell>

                    <TableCell text textCenter>
                        1,000
                    </TableCell>
                    
                    <TableCell scroll breakpoint={BREAKPOINTS.MD700} breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text textCenter link scroll breakpoint={BREAKPOINTS.MD1200}>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter link scroll breakpoint={BREAKPOINTS.MD1200}>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD550} breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}>
                        Ethereum
                    </TableCell>
                </TableRow>
            </FilterTable>
        </div>
    )
}

export default Collections;