exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DeleteEntityDialog_btnsContainer__3nMVx {\n    margin-top: 15px;\n    width: 100%;\n}\n.DeleteEntityDialog_btnsContainer__3nMVx .DeleteEntityDialog_cancelBtn__1vcyf {\n    width: 100%;\n    max-width: 150px;\n    height: 45px;\n    font-size: 16px;\n    margin-right: 15px;\n    background: rgba(255, 255, 255, 0.9);\n    color: #000000;\n    text-transform: capitalize !important;\n    transition: all 250ms ease-in-out;\n    border-radius: 8px;\n}\n.DeleteEntityDialog_btnsContainer__3nMVx .DeleteEntityDialog_cancelBtn__1vcyf:hover {\n    background: rgba(255, 255, 255, 0.7);\n    color: #000000;\n}\n\n.DeleteEntityDialog_btnsContainer__3nMVx .DeleteEntityDialog_deleteBtn__isQgy {\n    width: 100%;\n    max-width: 150px;\n    height: 45px;\n    border-radius: 8px;\n    font-size: 16px !important;\n    text-transform: capitalize !important;\n    transition: all 250ms ease-in-out;\n}\n\n.DeleteEntityDialog_loaderContainer__2GCPC {\n    width: 100%;\n    max-width: 150px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.DeleteEntityDialog_loaderContainer__2GCPC .DeleteEntityDialog_loader__1AiHd {\n    width: 36px;\n    height: 36px;\n}\n", ""]);

// exports
exports.locals = {
	"btnsContainer": "DeleteEntityDialog_btnsContainer__3nMVx",
	"cancelBtn": "DeleteEntityDialog_cancelBtn__1vcyf",
	"deleteBtn": "DeleteEntityDialog_deleteBtn__isQgy",
	"loaderContainer": "DeleteEntityDialog_loaderContainer__2GCPC",
	"loader": "DeleteEntityDialog_loader__1AiHd"
};