exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_text__nBEXM {\n    color: white;\n    margin: 20px 0 40px 0;\n}\n\n.styles_email__2JyVy {\n    color: white;\n    margin-bottom: 12px;\n    margin-right: auto;\n}\n\n.styles_timerBtn__22Kfh {\n    background: hsla(210, 13%, 91%, 0.03);\n    margin-left: 10px;\n    color: white;\n    border: none;\n    min-width: 120px;\n    height: 55px;\n}\n\n.styles_container__p4YUA {\n    display: flex;\n    width: 100%;\n}\n\n.styles_container__p4YUA input {\n    min-height: 55px;\n    min-width: 100%;\n}", ""]);

// exports
exports.locals = {
	"text": "styles_text__nBEXM",
	"email": "styles_email__2JyVy",
	"timerBtn": "styles_timerBtn__22Kfh",
	"container": "styles_container__p4YUA"
};