import React, { useCallback, forwardRef } from 'react';
import { ONLY_NUMBERS_REGEX_ONLY_G } from '../../const/regExp';

export const INPUT_TYPE = {
    NUMERIC: 'numeric',
};

const Input = forwardRef((props, _ref) => {
    const {
        title,
        text,
        placeholder,
        value,
        setValue,
        typeItem = 'full',
        textarea = false,
        isLink = false,
        type,
        maxValue,
        name,
        className,
        inputProps,
        withButton = false,
        buttonTitle = '',
        buttonOnClick = false,
        isError = false,
        disabled,
        style,
    } = props;

    const onInputChange = useCallback(
        (e) => {
            if (!setValue) {
                return;
            }
            if (type !== INPUT_TYPE.NUMERIC) {
                setValue(e.target.value);
                return;
            }

            const value = e.currentTarget.value.match(ONLY_NUMBERS_REGEX_ONLY_G);

            const valueStr = value && value.join('');

            if (Number(valueStr) < maxValue) {
                setValue(valueStr);
            }
        },
        [type],
    );

    const input = withButton ? (
        <>
            <input
                {...inputProps}
                name={name}
                type={isLink ? '' : 'text'}
                className={`input create__item${isLink ? '--link' : ''}--input ${
                    isError ? 'error_input' : ''
                }`}
                placeholder={placeholder}
                value={value}
                onChange={onInputChange}
                disabled={disabled}
                style={style}
            />
            <button className="button control__add" onClick={buttonOnClick}>
                {buttonTitle}
            </button>
        </>
    ) : (
        <input
            {...inputProps}
            name={name}
            type={isLink ? '' : 'text'}
            className={`input create__item${isLink ? '--link' : ''}--input ${
                isError ? 'error_input' : ''
            }`}
            placeholder={placeholder}
            value={value}
            onChange={onInputChange}
            disabled={disabled}
            style={style}
        />
    );

    return (
        <div className={className || `admin__add--item ${typeItem}`} style={{ color: 'white' }}>
            {title ||
                (text && (
                    <div className="admin__add--title--inner">
                        {title && (
                            <p className="admin__add--title" style={{ color: 'white' }}>
                                {title}
                            </p>
                        )}

                        {text && <p className="admin__add--text">{text}</p>}
                    </div>
                ))}

            {textarea ? (
                <textarea
                    {...inputProps}
                    name={name}
                    type="text"
                    className={`input create__item--textarea ${isError ? 'error_input' : ''}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onInputChange}
                    disabled={disabled}
                    style={{ whiteSpace: 'normal' }}
                />
            ) : (
                input
            )}
        </div>
    );
});

Input.displayName = 'Input';

export default React.memo(Input);
