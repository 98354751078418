import React from 'react';

const ArrowLink = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.24408 14.7559C5.56951 15.0814 6.09715 15.0814 6.42259 14.7559L14.7559 6.42259C15.0814 6.09715 15.0814 5.56951 14.7559 5.24408C14.4305 4.91864 13.9028 4.91864 13.5774 5.24408L5.24408 13.5774C4.91864 13.9028 4.91864 14.4305 5.24408 14.7559Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1667 15C14.6269 15 15 14.6269 15 14.1667V5.83333C15 5.3731 14.6269 5 14.1667 5H5.83333C5.3731 5 5 5.3731 5 5.83333C5 6.29357 5.3731 6.66667 5.83333 6.66667H13.3333V14.1667C13.3333 14.6269 13.7064 15 14.1667 15Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ArrowLink;
