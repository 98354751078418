import React from 'react';

const Picture = ({ ...props }) => {
    return (
        <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.77606 1.38627C4.56169 1.38627 5.1981 1.99601 5.1981 2.74872C5.1981 3.50142 4.56169 4.11116 3.77606 4.11116C2.99044 4.11116 2.35403 3.50142 2.35403 2.74872C2.35403 1.99601 2.99044 1.38627 3.7755 1.38627H3.77606ZM7.40968 3.96391V3.96445H7.41024C7.48428 3.96445 7.55776 3.99153 7.61429 4.04514L9.31672 5.67623V8.067C9.31672 8.22025 9.18728 8.34425 9.0279 8.34479L0.972105 8.34534C0.812713 8.34425 0.683288 8.22025 0.683288 8.067V6.4495L1.88716 5.29553C1.94368 5.24192 2.01772 5.21485 2.09176 5.21485C2.1658 5.21485 2.23984 5.24192 2.29637 5.29607L3.8557 6.79006C3.91787 6.84963 4.00321 6.88645 4.09817 6.88645C4.1903 6.88645 4.27452 6.85125 4.33612 6.79439L7.20504 4.04514C7.26156 3.99098 7.33563 3.96391 7.40968 3.96391ZM9.0386 0H0.961397C0.429543 0.00595668 0 0.421303 0 0.931951V8.06697C0 8.57816 0.429558 8.9935 0.961397 8.99946L9.0386 9C9.57102 8.9935 10 8.57816 10 8.06697V0.931952C10 0.421303 9.57102 0.00596098 9.0386 0Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Picture;
