import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import OwnerItemSkeleton from './OwnerItemSkeleton';

const OwnersSkeletonPage = () => {
    return (
        <section className={styles.owners}>
            <img src="/assets/img/owners-bg.png" alt="main bg" className={styles.ownersBg} />

            <div className={base.container}>
                <div className={styles.ownersInner}>
                    <Skeleton
                        containerClassName={styles.ownersTitleSkeletonWrapper}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={styles.ownersTitleLongSkeletonWrapper}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={cn(styles.ownersDescSkeletonWrapper, styles.title)}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={cn(styles.ownersDescSkeletonWrapper, styles.text)}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={cn(styles.ownersDescSkeletonWrapper, styles.text2)}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={cn(styles.ownersDescSkeletonWrapper, styles.text3)}
                        className={styles.skeletonBrs}
                    />

                    <Skeleton
                        containerClassName={cn(styles.ownersDescSkeletonWrapper, styles.button)}
                        className={styles.skeletonBrs}
                    />

                    <div className={styles.ownersButtonsSkeleton}>
                        <Skeleton
                            containerClassName={cn(styles.ownersButtonSkeletonWrapper)}
                            className={styles.skeletonBrs}
                        />

                        <Skeleton
                            containerClassName={cn(styles.ownersButtonSkeletonWrapper, styles.buttonSmall)}
                            className={styles.skeletonBrs}
                        />
                    </div>

                    <div className={styles.ownersFiltersSkeleton}>
                        <Skeleton
                            containerClassName={styles.ownersSearchSkeletonWrapper}
                            className={styles.skeletonBrs15}
                        />

                        <div className={styles.ownersFilterItem}>
                            <div className={styles.ownersFilterTextInner}>
                                <Skeleton
                                    containerClassName={styles.ownersFilterTextSkeletonWrapper}
                                    className={styles.skeletonBrs}
                                />

                                <Skeleton
                                    containerClassName={cn(styles.ownersFilterTextSkeletonWrapper, styles.long)}
                                    className={styles.skeletonBrs}
                                />
                            </div>

                            <Skeleton
                                containerClassName={styles.ownersCollectionSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />
                        </div>

                        <div className={styles.ownersFilterItem}>
                            <div className={styles.ownersFilterTextInner}>
                                <Skeleton
                                    containerClassName={styles.ownersFilterTextSkeletonWrapper}
                                    className={styles.skeletonBrs}
                                />

                                <Skeleton
                                    containerClassName={cn(styles.ownersFilterTextSkeletonWrapper, styles.long)}
                                    className={styles.skeletonBrs}
                                />
                            </div>

                            <Skeleton
                                containerClassName={styles.ownersSortSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />
                        </div>
                    </div>

                    <div className={styles.ownersItemsSkeleton}>
                        <OwnerItemSkeleton />
                        <OwnerItemSkeleton />
                        <OwnerItemSkeleton />
                        <OwnerItemSkeleton />
                        <OwnerItemSkeleton />
                    </div>

                    <div className={styles.pagginationSkeleton}>
                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />

                        <Skeleton
                            containerClassName={styles.pagginationItemSkeletonWrapper}
                            className={styles.skeletonBrs4}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OwnersSkeletonPage;
