import React, { useRef, useEffect } from 'react';

import css from './index.module.css';

const StickyWrapper = (props) => {
    const {
        children,
        ref,
        className,
        indentTop = 0,
        shouldBeSticky = true,
        isLoading = false,
    } = props;

    const containerRef = useRef(null);
    const containerTopRef = useRef();

    useEffect(() => {
        const scrollHandler = () => {
            if (!containerRef.current || !containerTopRef.current) {
                return;
            }

            if (window.pageYOffset >= containerTopRef.current - indentTop && shouldBeSticky) {
                containerRef.current.classList.add(css.sticky);
                containerRef.current.style.paddingTop = `${indentTop}px`;
            } else {
                containerRef.current.classList.remove(css.sticky);
                containerRef.current.style.paddingTop = 0;
            }
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [shouldBeSticky]);

    useEffect(() => {
        if (containerRef.current) {
            containerTopRef.current = containerRef.current.getBoundingClientRect().top;
            if (ref) {
                ref.current = containerTopRef.current;
            }
        }
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <div ref={containerRef} className={className}>
            {children}
        </div>
    );
};

export default React.memo(StickyWrapper);
