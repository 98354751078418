import Modal from "../../common/Modal";
import React, {useState} from "react";
import styles from '../WelcomeModal/modal.module.css'

export const SuccessModal = (
  {
    isSuccessModal,
    setIsSuccessModal,
    name = 'name',
    email='email',
    setSite,
    setName,
    setPhone,
    setEmail
  }) => {
  const handleCloseModal = () => {
    setIsSuccessModal(false)
    setSite('')
    setName('')
    setPhone('')
    setEmail('')
  }

  return (
    <Modal active={isSuccessModal} title={'Поздравляем!'} onClose={handleCloseModal}>
      <p className={styles.welcome}>{name}</p>
      <img src={'/assets/img/successIcon.png'} alt={'link'} style={{margin: '10px 0 28px 0'}}/>
      <p style={{marginBottom: '8px', color: 'white', fontWeight: '500'}}>{email}</p>
      <p style={{marginBottom: '50px', color: 'white'}}>Ваши данные успешно отправленны</p>


      <button
        className={`button token__card--content--price--buy ${styles.btn}`}
        style={{height: '52px'}}
        onClick={handleCloseModal}
      >
        Благодарим вас
      </button>
    </Modal>
  )
}