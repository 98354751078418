import React from "react";
import Modal from "../../../../../../common/Modal";
import styles from '../../../OfferModal/styles.module.css'
import Input from "../../../../../../common/Input";
import {useDispatch} from "react-redux";
import {setIsNoServiceModal} from "../../../../../../redux/slices/auth";

export const NoServiceProvide = (
  {
    isNoServiceProvideActive,
    item,
  }
) => {
  const dispatch = useDispatch()

  const handleClickOnSocial = (link) => {
    dispatch(setIsNoServiceModal(false))
    window.open(link)
  }

  return (
    <Modal
      title={'Услуга не оказана'}
      active={isNoServiceProvideActive}
      onClose={() =>  dispatch(setIsNoServiceModal(false))}
      size={'medium'}
    >
      <p className={styles.name}>{item.company.name}</p>

      <img src={item.company.logo} className={styles.img} alt={'img'}/>

      <p className={styles.state}>{item.certificate_type.toUpperCase()}</p>
      <p className={styles.text}>Вы можеет связаться с нашим менеджером по урегулированию</p>

      <button
        className={`button token__card--content--price--buy`}
        onClick={() => handleClickOnSocial('https://t.me/bratouverie')}
        style={{height: '52px', width: '100%'}}
      >
        Телеграм
      </button>
      <button
        className={`button token__card--content--price--buy`}
        onClick={() => handleClickOnSocial("https://wa.me/79223120735")}
        style={{height: '52px', width: '100%', background: 'hsla(125, 41%, 62%, 0.8)', marginTop: '12px'}}
      >
        Ватсап
      </button>

      <div  style={{marginTop: '44px', minWidth: '100%'}}>
        <Input
          placeholder="Выберите способ связи нажатием кнопки. Откроется соответствующее приложение. Отправьте встроенное сообщение. Менеджер свяжется с вами в течении минуты, что бы помочь вам..."
          textarea
          disabled={true}
        />
      </div>
    </Modal>
  )
}