import React from 'react';

const Info = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_14667_3211)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99992 17.5013C5.85778 17.5013 2.49992 14.1434 2.49992 10.0013C2.49992 5.85917 5.85778 2.5013 9.99992 2.5013C14.1421 2.5013 17.4999 5.85917 17.4999 10.0013C17.4999 14.1434 14.1421 17.5013 9.99992 17.5013ZM0.833252 10.0013C0.833252 15.0639 4.93731 19.168 9.99992 19.168C15.0625 19.168 19.1666 15.0639 19.1666 10.0013C19.1666 4.93869 15.0625 0.834637 9.99992 0.834637C4.93731 0.834637 0.833252 4.93869 0.833252 10.0013Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0001 14.168C10.4603 14.168 10.8334 13.7949 10.8334 13.3346V10.0013C10.8334 9.54107 10.4603 9.16797 10.0001 9.16797C9.53984 9.16797 9.16675 9.54107 9.16675 10.0013V13.3346C9.16675 13.7949 9.53984 14.168 10.0001 14.168Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.16675 6.66667C9.16675 7.1269 9.53984 7.5 10.0001 7.5H10.0084C10.4687 7.5 10.8417 7.1269 10.8417 6.66667C10.8417 6.20643 10.4687 5.83333 10.0084 5.83333H10.0001C9.53984 5.83333 9.16675 6.20643 9.16675 6.66667Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_14667_3211">
                    <rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 0 20)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Info;
