import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { BREAKPOINTS, BREAKPOINTS_ACTIVE } from '../../../const/breakpoints/BREAKPOINTS';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { useDispatch, useSelector } from 'react-redux';
// import { brandsRemove } from './slice';
import { useDeleteAccountMutation } from '../../../redux/api/dataService';
import { CancelModal } from '../../Сonclusions/modals/CancelModal';
import { useDynamicPagination } from '../../useDynamicPagination';
import { useLocation } from 'react-router-dom';
import { useGenerateRoutes, useGetTableView } from '../../useGetTableView';
import { expandListOfParameters } from '../../utils';

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/account/`;

const Brands = () => {
    const location = useLocation();
    const { pageId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const [isDelete, setIsDelete] = useState(false);
    const [clickedItemId, setClickedItemId] = useState();
    const [deleteBrand] = useDeleteAccountMutation();
    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
    useOutletContext();
    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'brands');
  
    useGenerateRoutes([{title:'page',id:pageId}])
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            {
                title: 'page_id',
                value: pageId,
            },
        ]);
    }, [pageId, requestParams, requestParams]);

    const items = useDynamicPagination(TABLE_URL, params, isResetList, setIsResetList, setCount);
    // useEffect(() => {
    //     if (items) {
    //         setCount(items.count);
    //     }
    // }, [items]);

    const handleDeleteAccount = (id) => {
        setIsDelete(true);
        setClickedItemId(id);
    };

    const handleDeleteAccountRequest = () => {
        if (clickedItemId) {
            deleteBrand({
                id: clickedItemId,
            })
                .unwrap()
                .then(() => {
                    localStorage.setItem('isDelay', 'true');
                    // dispatch(brandsRemove());
                })
                .finally(() => {
                    setIsDelete(false);
                });
        }
    };


    const handleNavigate = useCallback(
        (el, type) => {
            if (type === 'navigateNext' && el && el.page) {
                navigate(`../collections/${el.page.id}/${el.id}`);
            }
            if (type === 'navigateEdit' && el) {
                navigate(`../../changeaccount/${el.id}`);
            }
        },
        [items],
    );

    return (
        <>
            <TableRow names size="medium">
                <TableCell title>Брэнд</TableCell>
                <TableCell title textCenter>
                    Коллекции
                </TableCell>

                <TableCell title textCenter breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}>
                    Доход
                </TableCell>

                <TableCell
                    title
                    textCenter
                    breakpoint={BREAKPOINTS.MD700}
                    breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1200}
                >
                    Описание
                </TableCell>
{/* 
                <TableCell
                    title
                    textCenter
                    breakpoint={BREAKPOINTS.MD1200}
                    breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                >
                    Url
                </TableCell> */}

                {/* <TableCell
                    title
                    textCenter
                    breakpoint={BREAKPOINTS.MD550}
                    breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                >
                    Категория
                </TableCell> */}

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    Админ
                </TableCell>
            </TableRow>

            {items &&
                items.items.map((el, index) => {
                    return (
                        <TableRow
                            size="medium"
                            actionType="stat"
                            key={index}
                            style={{ cursor: 'pointer' }}
                            handlerDeleteClick={() => handleDeleteAccount(el.id)}
                            handlerEditClick={() => {
                                handleNavigate(el, 'navigateEdit');
                            }}
                        >
                            <TableCell   onClick={() => handleNavigate(el, 'navigateNext')}>
                                <p className="whithdraw__index">{index + 1}</p>

                                <div className="stats__item--avatar--inner withdraw__item">
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.logo}`}
                                        alt={'logo'}
                                        style={{
                                            objectFit: 'cover',
                                            width: '48px',
                                            height: '48px',
                                        }}
                                    />
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <p
                                        onClick={() => handleNavigate(el, 'navigateNext')}
                                        className="withdraw__item--collection--name"
                                    >
                                        {el.name}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.collections_count}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {Math.floor(el.volume_troded_count / 100).toLocaleString('ru-RU')}{' '}
                                RUB
                            </TableCell>

                            <TableCell
                                scroll
                                breakpoint={BREAKPOINTS.MD700}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.description}
                            </TableCell>

                            {/* <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD1200}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.url}
                            </TableCell> */}

                            {/* <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD550}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {`/collection/${el.id}`}
                            </TableCell> */}

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD550}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                -
                            </TableCell>
                        </TableRow>
                    );
                })}
            <CancelModal
                handleOk={() => {
                    handleDeleteAccountRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            />
        </>
    );
};

export default Brands;
