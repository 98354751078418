import React from 'react';

const Clock2 = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.86989 2.43837C1.32089 3.06836 0.867694 3.8072 0.542694 4.63201C-0.758506 7.93422 0.343694 11.7525 3.16869 13.7378C5.99389 15.7243 9.76889 15.3313 12.1709 12.8037C12.4427 12.539 12.4501 12.0913 12.1887 11.8154C11.9271 11.5407 11.5011 11.5496 11.2503 11.8342C9.28149 13.9055 6.20749 14.2231 3.89289 12.5964C1.57929 10.9707 0.681294 7.86265 1.74689 5.15718C2.81249 2.45171 5.53649 0.920889 8.26689 1.49095C10.9971 2.06228 12.9565 4.57378 12.9565 7.50075C12.9565 7.88022 13.2481 8.18791 13.6085 8.18791C13.9691 8.18791 14.2607 7.88022 14.2607 7.50075C14.2617 3.92727 11.8545 0.841478 8.52109 0.144359C6.02209 -0.378481 3.51569 0.549035 1.86989 2.43837Z"
                fill="currentColor"
            />
            <path
                d="M7.16424 2.752C6.80604 2.75645 6.51844 3.06858 6.52264 3.44764L6.51224 7.7572C6.49764 8.14769 6.79144 8.47295 7.16004 8.47295C7.52884 8.47422 7.82464 8.15086 7.81104 7.76059L7.82164 3.45251C7.82784 3.06519 7.53104 2.74755 7.16424 2.752Z"
                fill="currentColor"
            />
            <path
                d="M7.15922 7.06445C6.57682 7.06445 6.28842 7.81197 6.70402 8.2446L8.80402 10.4768C9.05722 10.7459 9.46962 10.7459 9.72382 10.4781C9.97802 10.2095 9.97902 9.77331 9.72502 9.50374L7.62582 7.27283C7.50282 7.13899 7.33522 7.06445 7.15922 7.06445Z"
                fill="currentColor"
            />
            <path
                d="M15.6614 6.52038C15.647 6.15361 15.3604 5.86328 15.0124 5.86328H12.002C11.4332 5.86434 11.1394 6.58348 11.5292 7.02246L13.0342 8.70999C13.2904 8.99672 13.7218 8.99672 13.9792 8.70999L15.4844 7.02479C15.6062 6.88905 15.6698 6.70715 15.6614 6.52038Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Clock2;
