exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ActionMenu_adminsActionInner__MKbkW{\n    width: 32px;\n    height: 32px;\n    background: rgba(229, 232, 235, 0.04);\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: .2s linear;\n    position: relative;\n}\n\n.ActionMenu_adminsActionInner__MKbkW:hover{\n    background: rgba(229, 232, 235, 0.06);\n}\n\n.ActionMenu_adminsActionContent__3MbY7{\n    position: absolute;\n    top: calc(100% + 10px);\n    right: 0;\n    width: 180px;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    overflow: hidden;\n    background: linear-gradient(0deg, #1b1b1b, #1b1b1b), rgba(229, 232, 235, 0.04);\n    z-index: 4;\n}\n\n/* @media (max-width: 700px) {\n    .adminsActionContent{\n        top: auto;\n        bottom: calc(100% + 10px);\n    }\n} */\n\n.ActionMenu_adminsActionItem__Ygf0d{\n    width: 100%;\n    cursor: pointer;\n    transition: .2s linear;\n    font-size: 1.4rem;\n    font-weight: 500;\n    color: #E5E8EB;\n    padding: 1.2rem 2rem;\n    line-height: 143%;\n}\n\n.ActionMenu_adminsActionItem__Ygf0d:hover{\n    background: rgba(229, 232, 235, 0.06);\n}", ""]);

// exports
exports.locals = {
	"adminsActionInner": "ActionMenu_adminsActionInner__MKbkW",
	"adminsActionContent": "ActionMenu_adminsActionContent__3MbY7",
	"adminsActionItem": "ActionMenu_adminsActionItem__Ygf0d"
};