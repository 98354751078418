import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_API_URL } from '../../const/http/API_URLS';
import { HTTP_METHODS } from '../../const/http/HTTP_METHODS';
import { generateQuery } from '../../utils/http/generateQuery';
import { generateServiceUrl } from "../../utils/helperUrl";

export const dataApi = createApi({
    reducerPath: 'dataApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const {
                auth: { accessToken },
            } = getState();

            if (accessToken) {
                headers.set('Authorization', `Bearer ${accessToken}`);
            }

            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        // GET
        // PAGE
        getPages: builder.query({
            query: () => ({
                url: 'page/',
            }),
        }),
        getPageByUrl: builder.query({
            query: (url) => ({
                url: `page/${url}/`,
            }),
        }),
        // ACCOUNT
        getAccounts: builder.query({
            query: ({ page, pageSize, page_id }) => {
                const queryParams = { page, page_size: pageSize };
                if (page_id) {
                    queryParams.page_id = page_id;
                }
                return {
                    url: `account/${generateQuery(queryParams)}`,
                };
            },
        }),
        getAccount: builder.query({
            query: ({ id }) => {
                return {
                    url: `account/${id}/`,
                };
            },
        }),
        // COLLECTION
        getCollections: builder.query({
            query: ({ page, pageSize }) => ({
                url: `collection/${generateQuery({ page, page_size: pageSize })}`,
            }),
        }),
        getCollection: builder.query({
            query: ({ id }) => ({
                url: `collection/${id}/`,
            }),
        }),

        // PACK
        getPacks: builder.query({
            query: ({ page, pageSize }) => ({
                url: `pack/${generateQuery({ page, page_size: pageSize })}`,
            }),
        }),
        getPack: builder.query({
            query: ({ id }) => ({
                url: `pack/${id}/`,
            }),
        }),
        // TOKEN
        getTokens: builder.query({
            query: ({ page, pageSize }) => ({
                url: `token/${generateQuery({ page, page_size: pageSize })}`,
            }),
        }),
        getToken: builder.query({
            query: ({ id }) => ({
                url: `token/${id}/`,
            }),
        }),

        getCurrencyTokens: builder.query({
            query: ({ blockchainId }) => {
                return {
                    url: `currency_token/${generateQuery({ blockchain_id: blockchainId })}`,
                };
            },
        }),
        getBlockchains: builder.query({
            query: () => ({
                url: `blockchain/`,
            }),
        }),
        getMyTokens: builder.query({
            query: ({ page, pageSize }) => {
                return {
                    url: `token/user/me/${generateQuery({ page, page_size: pageSize })}`,
                };
            },
        }),

        // WITH FILTER PAGINATED
        getFilteredCollection: builder.query({
            query: ({ page, pageSize, accountId }) => ({
                url: `collection_filter/${generateQuery({
                    page,
                    page_size: pageSize,
                    account_id: accountId,
                })}`,
            }),
        }),
        getFilteredPacks: builder.query({
            query: ({ page, pageSize, collectionId }) => ({
                url: `pack_filter/${generateQuery({
                    page,
                    page_size: pageSize,
                    collection_id: collectionId,
                })}`,
            }),
        }),
        getFilteredTokens: builder.query({
            query: ({ page, pageSize, packId, collectionId }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (packId) {
                    queryObj.pack_id = packId;
                }

                if (collectionId) {
                    queryObj.collection_id = collectionId;
                }

                return {
                    url: `token_filter/${generateQuery(queryObj)}`,
                };
            },
        }),

        // POST
        createPage: builder.mutation({
            query: (data) => ({
                url: 'page/',
                method: HTTP_METHODS.POST,
                body: data,
            }),
        }),
        createAccount: builder.mutation({
            query: (data) => ({
                url: 'account/',
                method: HTTP_METHODS.POST,
                body: data,
            }),
        }),
        createCollection: builder.mutation({
            query: (data) => ({
                url: 'collection/',
                method: HTTP_METHODS.POST,
                body: data,
            }),
        }),
        createPack: builder.mutation({
            query: (data) => ({
                url: 'pack/',
                method: HTTP_METHODS.POST,
                body: data,
            }),
        }),

        // PATCH
        updatePage: builder.mutation({
            query: ({ url, data }) => ({
                url: `page/${url}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),
        updateAccount: builder.mutation({
            query: ({ id, data }) => ({
                url: `account/${id}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),
        updateCollection: builder.mutation({
            query: ({ id, data }) => ({
                url: `collection/${id}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),
        updateCollectionStatus: builder.mutation({
            query: ({ id, data }) => ({
                url: `collection/status/${id}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),
        updatePack: builder.mutation({
            query: ({ id, data }) => ({
                url: `pack/${id}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),
        updateToken: builder.mutation({
            query: ({ id, data }) => ({
                url: `token/${id}/`,
                method: HTTP_METHODS.PATCH,
                body: data,
            }),
        }),

        // DELETE ENTITY
        deletePage: builder.mutation({
            query: ({ url }) => ({
                url: `page/${url}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),

        // HIDE ENTITY
        hideAccount: builder.mutation({
            query: ({ id }) => ({
                url: `account/${id}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),
        hideCollection: builder.mutation({
            query: ({ id }) => ({
                url: `collection/${id}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),
        hidePack: builder.mutation({
            query: ({ id }) => ({
                url: `pack/${id}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),
        hideToken: builder.mutation({
            query: ({ id, isHide }) => ({
                url: `token/${id}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),

        getProperties: builder.query({
            query: () => ({
                url: `properties/`,
            }),
        }),

        getLevelStats: builder.query({
            query: () => ({
                url: `levels_stats/`,
            }),
        }),

        // services

        createServices: builder.mutation({
            query: ({ body }) => ({
                url: `/services/`,
                method: HTTP_METHODS.POST,
                body: body
            }),
        }),
        deleteServices: builder.mutation({
            query: ({ id }) => ({
                url: `/services/${id}/`,
                method: HTTP_METHODS.DELETE,
            }),
        }),
        changeServices: builder.mutation({
            query: ({ id, body }) => ({
                url: `/services/${id}/`,
                method: HTTP_METHODS.PUT,
                body
            }),
        }),
        getServices: builder.query({
            query: ({ id }) => ({
                url: `/services/${id}/`,
            }),
        }),
        getAllServices: builder.query({
            query: ({ search = '', certificate_type = '', collection_id = '' }) => ({
                url: `${generateServiceUrl(search, certificate_type, collection_id)}`,
            }),
        }),


        // account
        getAllAccounts: builder.query({
            query: () => ({
                url: `/account/`,
            }),
        }),

        // collection
        getAllCollection: builder.query({
            query: () => ({
                url: `/collection/`,
            }),
        }),

        // companies
        getAllCompanies: builder.query({
            query: () => ({
                url: `/companies/`,
            }),
        }),
        // companies
        createCompany: builder.mutation({
            query: ({ body }) => ({
                url: `/companies/`,
                method: 'POST',
                body: body
            }),
        }),
        // tinkoff
        initial: builder.mutation({
            query: (body) => ({
                url: `/payments`,
                method: 'POST',
                body: body
            }),
        }),
        // used_service
        usedService: builder.mutation({
            query: (body) => ({
                url: `/used_service/`,
                method: 'POST',
                body: body
            }),
        }),
        // used_service
        usedServiceList: builder.query({
            query: (body) => ({
                url: `/used_service/`,
            }),
        }),
        clickOnSocial: builder.mutation({
            query: ({ body }) => ({
                url: '/used_service/clicked/',
                method: 'POST',
                body: body
            }),
        }),
        notUsedService: builder.mutation({
            query: ({ body }) => ({
                url: '/used_service/not_used/',
                method: 'POST',
                body: body
            }),
        }),
        cooperation: builder.mutation({
            query: ({ body }) => ({
                url: '/cooperation/',
                method: 'POST',
                body: body
            }),
        }),
        sendApplication: builder.mutation({
            query: ({ body }) => ({
                url: '/application/',
                method: 'POST',
                body: body
            }),
        }),
        deleteAccount: builder.mutation({
            query: ({ id }) => ({
                url: `/account/${id}/`,
                method: 'DELETE',
            }),
        }),
        deleteCollection: builder.mutation({
            query: ({ id }) => ({
                url: `/collection/${id}/`,
                method: 'DELETE',
            }),
        }),
        deletePack: builder.mutation({
            query: ({ id }) => ({
                url: `/pack/${id}/`,
                method: 'DELETE',
            }),
        }),
        deleteToken: builder.mutation({
            query: ({ id }) => ({
                url: `/token/${id}/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetPagesQuery,
    useGetPageByUrlQuery,

    useGetAccountsQuery,
    useGetAccountQuery,

    useGetCollectionsQuery,
    useGetCollectionQuery,

    useGetPacksQuery,
    useGetPackQuery,
    useLazyGetPackQuery,

    useGetTokensQuery,
    useGetTokenQuery,
    useGetMyTokensQuery,

    useGetCurrencyTokensQuery,
    useGetBlockchainsQuery,

    useGetFilteredCollectionQuery,
    useGetFilteredPacksQuery,
    useGetFilteredTokensQuery,

    useCreatePageMutation,
    useCreateAccountMutation,
    useCreateCollectionMutation,
    useCreatePackMutation,

    useUpdatePageMutation,
    useUpdateAccountMutation,
    useUpdateCollectionMutation,
    useUpdatePackMutation,
    useUpdateTokenMutation,

    useUpdateCollectionStatusMutation,

    useDeletePageMutation,
    useHideAccountMutation,
    useHideCollectionMutation,
    useHidePackMutation,
    useHideTokenMutation,

    useGetPropertiesQuery,
    useGetLevelStatsQuery,

    useCreateServicesMutation,
    useChangeServicesMutation,
    useDeleteServicesMutation,
    useGetServicesQuery,
    useGetAllServicesQuery,

    useLazyGetAllServicesQuery,

    useGetAllAccountsQuery,
    useGetAllCollectionQuery,
    useGetAllCompaniesQuery,
    useInitialMutation,
    useUsedServiceMutation,
    useClickOnSocialMutation,
    useNotUsedServiceMutation,
    useCooperationMutation,
    useUsedServiceListQuery,
    useCreateCompanyMutation,
    useSendApplicationMutation,
    useDeleteAccountMutation,
    useDeleteCollectionMutation,
    useDeletePackMutation,
    useDeleteTokenMutation,
    useLazyGetCurrencyTokensQuery
} = dataApi;
