import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 1,
  lastItemsCount: 20,
  brands: [],
  brandsPage: 1,
  name: '',
}

const brandsFactorySlice = createSlice({
  name: 'brands',
  initialState: initialState,
  reducers: {
    brandsIncrementPage: (state) => {
      state.page += 1
    },
    brandsSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    brandsAddPageData: (state, action) => {
      state.brands = [...state.brands, ...action.payload]
    },
    brandsRemove: (state) => {
      state.brands = []
      state.page = 1
      state.lastItemsCount = 20
    },
    changeSearchBrandsName: (state, action) => {
      state.name = action.payload
    },
    setBrands: (state,action) => {
      state.brands = action.payload
    }
  },
})

export const {
  brandsSetLastItemsCount,
  brandsIncrementPage,
  brandsRemove,
  changeSearchBrandsName,
  brandsAddPageData,
  setBrands
} = brandsFactorySlice.actions

export const brandsFactoryReducer = brandsFactorySlice.reducer
