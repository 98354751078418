import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../index.module.css';
import base from '../../../styles/base.module.css';
import Wallet from '../../../icons/Wallet';
import Cross from '../../../icons/Cross';
import ModalNew from '../../../common/ModalNew';
import Question2 from '../../../icons/Question2';
import Star2 from '../../../icons/Star2';
import { detectDeviceUrl } from '../lib/detectDeviceUrl';
import { connectWallet, getShortAddress, logout } from '../../../functions/auth';
import { useGetTotalBalanceQuery } from '../../../redux/api/billingService';
import { NotificationContext } from '../../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { copyText } from '../../../functions/utils';
// import { WelcomeModal } from '../../WelcomeModal/WelcomeModal';
import { ContactsModal } from '../../WelcomeModal/ContactsModal';
import { ConfirmEmailModal } from '../../ConfirmEmailModal';


export const WalletModal = ({ connect, setConnect, profile, setProfile }) => {
    const isAuth = useSelector((state) => state.auth.isAuth);
    // const [connect,setConnect] = useState()
    const [trast, setTrast] = React.useState(false);
    const userName = useSelector((state) => state.auth.username);
    const userRole = useSelector((state) => state.auth.userRole);
    const walletAddress = useSelector((state) => state.auth.walletAddress);
    const email = useSelector((state) => state.auth.email);
    const [condition, setCondition] = React.useState(false);

    const { data, refetch } = useGetTotalBalanceQuery({}, { skip: !isAuth });
    const [isConfirmedEmail, setIsConfirmedEmail] = React.useState(false);
    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const {
        actions: { addNotification },
    } = React.useContext(NotificationContext);
    const copyAddress = () => {
        copyText(auth.walletAddress);
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
    };

    const handleAuth = () => {
        if (!window.ethereum) {
            setTrast(true);
        } 
        connectWallet(dispatch);
    };

    useEffect(() => {
        if (isAuth && trast && window.ethereum.isTrust) {
            setTrast(false);
        }
    }, [isAuth, trast]);

    const logoutProfile = async () => {
        return await logout(auth.accessToken, dispatch);
    };

    React.useEffect(() => {
        if (data && data.walletAddress !== '' && isAuth && connect) {
            setProfile(true);
            setConnect(false);
        }
    }, [data]);


    return (
        <>
            <div
                className={cn(styles.headerMenuOuter, {
                    [styles.active]: profile,
                })}
                onClick={() => setProfile(false)}
            >
                <div className={styles.headerMenuOuterWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.profileMenuContent}>
                        <Cross
                            className={styles.headerCross}
                            onClick={() => {
                                setProfile(false);
                            }}
                        />

                        <div className={styles.profileMenuWrapper}>
                            <div className={styles.profileInner}>
                                <div className={styles.profileAvatarInner}>
                                    <img
                                        src={auth.image ? auth.image : `/assets/img/avatar.png`}
                                        alt="avatar"
                                        className={styles.profileAvatar}
                                    />
                                </div>

                                <div className={styles.profileInfo}>
                                    <p className={styles.profileNickname}>{userName}</p>

                                    <p className={styles.profileAddress} onClick={copyAddress}>
                                        {getShortAddress(walletAddress)}
                                    </p>
                                </div>
                            </div>

                            <div className={styles.profileBalance}>
                                <p className={styles.profileText}>
                                    Баланс кошелька
                                    <div className={styles.tooltipInner}>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g opacity="0.37" clip-path="url(#clip0_14667_7252)">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M7.99935 13.9987C4.68564 13.9987 1.99935 11.3124 1.99935 7.9987C1.99935 4.68499 4.68564 1.9987 7.99935 1.9987C11.3131 1.9987 13.9993 4.68499 13.9993 7.9987C13.9993 11.3124 11.3131 13.9987 7.99935 13.9987ZM0.666016 7.9987C0.666016 12.0488 3.94926 15.332 7.99935 15.332C12.0494 15.332 15.3327 12.0488 15.3327 7.9987C15.3327 3.94861 12.0494 0.665365 7.99935 0.665365C3.94926 0.665365 0.666016 3.94861 0.666016 7.9987Z"
                                                    fill="#E5E8EB"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M8.00065 11.332C8.36884 11.332 8.66732 11.0336 8.66732 10.6654V7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987V10.6654C7.33398 11.0336 7.63246 11.332 8.00065 11.332Z"
                                                    fill="#E5E8EB"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M7.33398 5.33333C7.33398 5.70152 7.63246 6 8.00065 6H8.00732C8.37551 6 8.67398 5.70152 8.67398 5.33333C8.67398 4.96514 8.37551 4.66667 8.00732 4.66667H8.00065C7.63246 4.66667 7.33398 4.96514 7.33398 5.33333Z"
                                                    fill="#E5E8EB"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14667_7252">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="matrix(1 0 0 -1 0 16)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Баланс кошелька = Стоимость купленных сертификатов
                                                пользователя + (Начислено - Выведено).
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.profileValue}>
                                    {data && data.total_balance
                                        ? Math.floor(data.total_balance / 100).toLocaleString(
                                              'ru-RU',
                                          )
                                        : 0}{' '}
                                    RUB
                                </p>
                            </div>

                            <div className={styles.profileBalanceEmpty}>
                                <p className={styles.profileText}>Начислено</p>

                                <p className={styles.profileValue}>
                                    {' '}
                                    {Math.floor(data && data.total_accrued / 100).toLocaleString(
                                        'ru-RU',
                                    )}{' '}
                                    RUB
                                </p>
                            </div>

                            <div className={styles.profileMenu}>
                                <button
                                    className={cn(base.orangeButton, styles.profileIutputButton)}
                                    onClick={() => setCondition(true)}
                                >
                                    Вывести
                                </button>
                            </div>

                            <div className={cn(styles.profileNav, styles.profileNavBalance)}>
                                <button className={styles.profileNavItem}>
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M15.0643 2.03123C15.1883 2.0314 15.311 2.0556 15.4253 2.10243C15.5396 2.14926 15.6433 2.21778 15.7303 2.30403C15.8173 2.39028 15.8859 2.49254 15.9322 2.60488C15.9785 2.71723 16.0015 2.83743 15.9999 2.95853C15.955 5.57844 15.8515 7.5824 15.6581 9.16868C15.4695 10.755 15.1858 11.9334 14.7585 12.8869C14.4706 13.524 14.1152 14.0513 13.6976 14.4999C13.1348 15.0934 12.4929 15.524 11.7905 15.9329C11.4905 16.1081 11.1786 16.2813 10.852 16.4628C10.1539 16.8501 9.38672 17.2761 8.51589 17.8452C8.36234 17.9461 8.18148 18 7.99642 18C7.81136 18 7.63051 17.9461 7.47695 17.8452C6.59318 17.2699 5.81592 16.8383 5.11206 16.4487C4.95546 16.362 4.80231 16.2767 4.65261 16.1928C3.82929 15.7273 3.08801 15.2829 2.4403 14.6417C2.00848 14.2198 1.63511 13.7051 1.33773 13.0948C0.933271 12.2777 0.65835 11.2891 0.456838 10.0094C0.184364 8.29805 0.0505027 6.06065 0.000124583 2.95839C-0.0018901 2.83766 0.0205482 2.71773 0.0661488 2.60551C0.111749 2.49328 0.179612 2.39097 0.265833 2.30447C0.443712 2.13085 0.684575 2.0329 0.936006 2.03193H1.32291C2.51471 2.03629 5.14589 1.9221 7.42154 0.191101C7.58583 0.0672041 7.78752 0 7.99506 0C8.2026 0 8.40428 0.0672041 8.56858 0.191101C10.8442 1.92224 13.4754 2.03755 14.673 2.03193M12.9731 12.302C13.2654 11.7132 13.5085 10.9 13.6927 9.73134C13.9131 8.33349 14.0481 6.43501 14.1116 3.85166C12.7082 3.812 10.2973 3.54833 7.99858 2.03615C5.70005 3.5434 3.28909 3.80638 1.89002 3.85138C1.93954 5.98753 2.03842 7.64835 2.19589 8.95338C2.37581 10.4384 2.63202 11.4453 2.95156 12.1527C3.16301 12.6229 3.3969 12.9613 3.67557 13.2608C4.04894 13.6608 4.5212 13.9903 5.16388 14.369C5.43074 14.5251 5.72524 14.6881 6.04478 14.8654C6.61535 15.1808 7.26825 15.5418 7.99657 15.9947C8.71208 15.5489 9.35534 15.1917 9.91957 14.8781C10.0896 14.7836 10.2521 14.6938 10.4074 14.6057C11.199 14.1618 11.7834 13.7927 12.2195 13.3625C12.514 13.0668 12.753 12.7461 12.9732 12.3017"
                                                fill="#CFE4FF"
                                            />
                                        </svg>
                                    </div>
                                    Trust кошелёк
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ position: 'relative', right: '18px' }}
                                    >
                                        <circle cx="8" cy="8" r="8" fill="#1ECD6F" />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10.9655 5.83588C11.2779 6.1483 11.2779 6.65483 10.9655 6.96725L7.76549 10.1672C7.45307 10.4797 6.94654 10.4797 6.63412 10.1672L5.03412 8.56725C4.7217 8.25483 4.7217 7.7483 5.03412 7.43588C5.34654 7.12346 5.85307 7.12346 6.16549 7.43588L7.1998 8.47019L9.83412 5.83588C10.1465 5.52346 10.6531 5.52346 10.9655 5.83588Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>

                                <button
                                    className={styles.profileNavItem}
                                    onClick={() => {
                                        refetch();
                                    }}
                                >
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14667_7269)">
                                                <path
                                                    d="M5.01137 11.9996H2.39063L5.88495 15.494L9.37927 11.9996H6.75853C6.75853 9.10808 9.10846 6.75814 12 6.75814C12.8823 6.75814 13.721 6.97654 14.446 7.36965L15.7215 6.09422C14.647 5.41283 13.3715 5.01098 12 5.01098C8.13879 5.01098 5.01137 8.1384 5.01137 11.9996ZM17.2415 11.9996C17.2415 14.8912 14.8916 17.2411 12 17.2411C11.1177 17.2411 10.2791 17.0227 9.55399 16.6296L8.27856 17.905C9.35307 18.5864 10.6285 18.9883 12 18.9883C15.8612 18.9883 18.9887 15.8609 18.9887 11.9996H21.6094L18.1151 8.50531L14.6208 11.9996H17.2415Z"
                                                    fill="#CFE4FF"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14667_7269">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    Обновить данные
                                </button>

                                <button className={styles.profileNavItem}>
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.25 4.5C2.25 3.25736 3.25736 2.25 4.5 2.25H19.5C20.7427 2.25 21.75 3.25737 21.75 4.5V12C21.75 12.4142 21.4142 12.75 21 12.75C20.5858 12.75 20.25 12.4142 20.25 12V4.5C20.25 4.08578 19.9142 3.75 19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08578 20.25 4.5 20.25H12C12.4142 20.25 12.75 20.5858 12.75 21C12.75 21.4142 12.4142 21.75 12 21.75H4.5C3.25737 21.75 2.25 20.7427 2.25 19.5V4.5Z"
                                                fill="#CFE4FF"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16 13.75C14.7574 13.75 13.75 14.7574 13.75 16C13.75 17.2426 14.7574 18.25 16 18.25C17.2426 18.25 18.25 17.2426 18.25 16C18.25 14.7574 17.2426 13.75 16 13.75ZM12.25 16C12.25 13.9289 13.9289 12.25 16 12.25C18.0711 12.25 19.75 13.9289 19.75 16C19.75 18.0711 18.0711 19.75 16 19.75C13.9289 19.75 12.25 18.0711 12.25 16Z"
                                                fill="#CFE4FF"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M17.9144 17.5315C18.1731 17.2081 18.6451 17.1556 18.9685 17.4144L21.4685 19.4144C21.792 19.6731 21.8444 20.1451 21.5857 20.4685C21.3269 20.792 20.855 20.8444 20.5315 20.5857L18.0315 18.5857C17.7081 18.3269 17.6556 17.855 17.9144 17.5315Z"
                                                fill="#CFE4FF"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.25 8C6.25 7.58579 6.58579 7.25 7 7.25H17C17.4142 7.25 17.75 7.58579 17.75 8C17.75 8.41421 17.4142 8.75 17 8.75H7C6.58579 8.75 6.25 8.41421 6.25 8Z"
                                                fill="#CFE4FF"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.25 12C6.25 11.5858 6.58579 11.25 7 11.25H11C11.4142 11.25 11.75 11.5858 11.75 12C11.75 12.4142 11.4142 12.75 11 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12Z"
                                                fill="#CFE4FF"
                                            />
                                        </svg>
                                    </div>
                                    История транзакций
                                </button>

                                <a
                                    onClick={() => window.open('https://t.me/check_brand')}
                                    className={styles.profileNavItem}
                                >
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.9873 3C13.1676 3 14.3362 3.23278 15.4266 3.68505C16.517 4.13733 17.5078 4.80023 18.3423 5.63592C20.0278 7.32368 20.9747 9.61276 20.9747 11.9996C20.9825 14.0777 20.264 16.0931 18.9435 17.6963L20.741 19.4963C20.8657 19.6228 20.9502 19.7835 20.9838 19.9581C21.0174 20.1327 20.9986 20.3133 20.9297 20.4772C20.8551 20.6392 20.7341 20.7752 20.5821 20.8682C20.43 20.9611 20.2539 21.0067 20.0759 20.9992H11.9873C9.60374 20.9992 7.31778 20.051 5.63233 18.3633C3.94688 16.6755 3 14.3864 3 11.9996C3 9.61276 3.94688 7.32368 5.63233 5.63592C7.31778 3.94817 9.60374 3 11.9873 3ZM11.9873 19.1993H17.91L17.0742 18.3623C16.9068 18.1937 16.8128 17.9656 16.8128 17.7278C16.8128 17.4901 16.9068 17.262 17.0742 17.0934C18.251 15.9163 18.9838 14.367 19.1478 12.7095C19.3118 11.052 18.8968 9.38882 17.9736 8.00335C17.0503 6.61787 15.6759 5.59581 14.0845 5.11128C12.4931 4.62676 10.7832 4.70975 9.246 5.34612C7.70885 5.98249 6.43959 7.13286 5.65446 8.60125C4.86934 10.0696 4.61694 11.7652 4.94025 13.399C5.26356 15.0329 6.14259 16.5039 7.42758 17.5616C8.71256 18.6192 10.324 19.198 11.9873 19.1993Z"
                                                fill="#CFE4FF"
                                            />
                                        </svg>
                                    </div>
                                    Служба поддержки
                                </a>

                                <a
                                    onClick={() => window.open('https://t.me/+5nhoVbOlL9dmNjJi')}
                                    className={styles.profileNavItem}
                                >
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.5 11.9948H15.5M8.5 15.4211L15.5 15.4286M8.5 8.56843L13.1667 8.57143M17.8333 20H6.16667C5.52233 20 5 19.4883 5 18.8571V5.14286C5 4.51168 5.52233 4 6.16667 4H15.5L19 7.42857V18.8571C19 19.4883 18.4777 20 17.8333 20Z"
                                                stroke="#CFE4FF"
                                                stroke-width="1.6"
                                                stroke-linecap="square"
                                            />
                                        </svg>
                                    </div>
                                    Инструкция использования
                                    <br />
                                    цифрового кошелька
                                </a>

                                <button
                                    className={styles.profileNavItem}
                                    onClick={() => {
                                        logoutProfile();
                                    }}
                                >
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14667_7275)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M11.8136 16.1453C10.8962 16.6028 9.86164 16.8594 8.76761 16.8594C4.98071 16.8594 1.90625 13.785 1.90625 9.99808C1.90625 6.21118 4.98071 3.13672 8.76761 3.13672C9.90596 3.13672 10.9799 3.41452 11.9249 3.90787C11.5203 4.08871 11.2732 4.40333 11.1756 4.84339C10.4448 4.49972 9.62839 4.30817 8.76761 4.30817C5.62725 4.30817 3.0777 6.85772 3.0777 9.99808C3.0777 13.1384 5.62725 15.688 8.76761 15.688C9.61079 15.688 10.4114 15.5042 11.1308 15.173C11.1805 15.6812 11.4075 16.006 11.8136 16.1453ZM8.07813 9.995C8.07813 10.1993 8.14145 10.3637 8.2681 10.4884C8.39479 10.613 8.5623 10.6753 8.77065 10.6753H16.0579L12.8798 13.617C12.8185 13.6783 12.7705 13.7498 12.7358 13.8315C12.7011 13.9132 12.6837 14.0031 12.6837 14.1012C12.6837 14.2851 12.746 14.4393 12.8706 14.5639C12.9952 14.6885 13.1515 14.7508 13.3394 14.7508C13.5233 14.7508 13.6888 14.6793 13.8359 14.5363L17.8808 10.5037C17.9502 10.4342 18.0034 10.3556 18.0401 10.2677C18.0769 10.1799 18.0953 10.089 18.0953 9.99503C18.0953 9.90105 18.0769 9.81014 18.0401 9.72228C18.0034 9.63446 17.9502 9.55785 17.8808 9.49248L13.8359 5.45372C13.6888 5.31478 13.5233 5.24533 13.3394 5.24533C13.1515 5.24533 12.9952 5.30661 12.8706 5.42918C12.746 5.55175 12.6837 5.70702 12.6837 5.89495C12.6837 5.99302 12.7011 6.08292 12.7358 6.16462C12.7705 6.24635 12.8185 6.31785 12.8798 6.37913L16.0271 9.36268L8.77065 9.31475C8.5623 9.31475 8.39479 9.37808 8.2681 9.50473C8.14145 9.63138 8.07813 9.79481 8.07813 9.995Z"
                                                    fill="#E5E8EB"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14667_7275">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    Выйти
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={cn(styles.headerMenuOuter, {
                    [styles.active]: connect,
                })}
                onClick={() => setConnect(false)}
            >
                <div className={styles.headerMenuOuterWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.profileMenuContent}>
                        <Cross className={styles.headerCross} onClick={() => setConnect(false)} />

                        <div className={styles.profileMenuWrapper}>
                            <div
                                className={styles.profileIn}
                                onClick={() => {
                                    setConnect(false);
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                                        fill="white"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
                                        fill="white"
                                    />
                                </svg>

                                <div className={styles.profileInWrapper}>
                                    <p className={styles.profileInTitle}>Цифровой кошелёк</p>

                                    <p className={styles.profileInText}>
                                        Подключите кошелёк Trust для доступа к сайту
                                    </p>
                                </div>
                            </div>

                            <div className={cn(styles.profileNav, styles.profileInNav)}>
                                <div className={styles.profileConnectItem}>
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                                fill="#3375BB"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M21.4172 7.80944C21.5473 7.80963 21.6761 7.83598 21.7962 7.88697C21.9162 7.93796 22.025 8.01257 22.1164 8.10649C22.2078 8.2004 22.2799 8.31175 22.3284 8.43408C22.377 8.55642 22.4012 8.68731 22.3995 8.81917C22.3524 11.672 22.2437 13.854 22.0406 15.5813C21.8426 17.3086 21.5447 18.5918 21.096 19.6301C20.7937 20.3237 20.4206 20.898 19.9821 21.3864C19.3912 22.0327 18.7171 22.5015 17.9796 22.9468C17.6646 23.1376 17.3371 23.3261 16.9942 23.5238C16.2612 23.9455 15.4557 24.4094 14.5413 25.0291C14.3801 25.139 14.1902 25.1977 13.9959 25.1977C13.8015 25.1977 13.6116 25.139 13.4504 25.0291C12.5224 24.4026 11.7063 23.9327 10.9673 23.5085C10.8028 23.4141 10.642 23.3212 10.4849 23.2298C9.62037 22.723 8.84202 22.2391 8.16192 21.5408C7.70852 21.0814 7.31647 20.521 7.00423 19.8564C6.57954 18.9667 6.29088 17.8902 6.07929 16.4967C5.79319 14.6333 5.65264 12.197 5.59974 8.81901C5.59762 8.68755 5.62118 8.55697 5.66907 8.43476C5.71695 8.31256 5.7882 8.20116 5.87873 8.10696C6.06551 7.91792 6.31841 7.81126 6.58242 7.8102H6.98866C8.24006 7.81495 11.0028 7.69061 13.3922 5.80574C13.5647 5.67083 13.7765 5.59766 13.9944 5.59766C14.2123 5.59766 14.4241 5.67083 14.5966 5.80574C16.986 7.69076 19.7488 7.81633 21.0062 7.8102M19.2213 18.9932C19.5283 18.352 19.7835 17.4665 19.977 16.194C20.2084 14.6719 20.3501 12.6047 20.4168 9.79169C18.9432 9.74851 16.4117 9.46139 13.9981 7.81479C11.5847 9.45603 9.05316 9.74238 7.58413 9.79138C7.63612 12.1174 7.73995 13.9259 7.90529 15.3469C8.09421 16.9639 8.36323 18.0603 8.69875 18.8306C8.92076 19.3426 9.16636 19.7111 9.45895 20.0372C9.851 20.4727 10.3469 20.8315 11.0217 21.2439C11.3019 21.4139 11.6111 21.5913 11.9466 21.7844C12.5457 22.1279 13.2313 22.521 13.996 23.0141C14.7473 22.5286 15.4227 22.1397 16.0152 21.7982C16.1936 21.6953 16.3643 21.5976 16.5274 21.5016C17.3586 21.0183 17.9722 20.6164 18.4301 20.1479C18.7394 19.8259 18.9902 19.4768 19.2215 18.9929"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    Trust
                                    <button
                                        className={cn(base.orangeButton, styles.profileConnect)}
                                        onClick={() => handleAuth()}
                                    >
                                        Подключить
                                    </button>
                                </div>

                                <a
                                    href="https://t.me/+5nhoVbOlL9dmNjJi"
                                    target="_blank"
                                    className={styles.profileNavItem}
                                >
                                    <div className={styles.profileNavItemSvg}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.5 11.9948H15.5M8.5 15.4211L15.5 15.4286M8.5 8.56843L13.1667 8.57143M17.8333 20H6.16667C5.52233 20 5 19.4883 5 18.8571V5.14286C5 4.51168 5.52233 4 6.16667 4H15.5L19 7.42857V18.8571C19 19.4883 18.4777 20 17.8333 20Z"
                                                stroke="#CFE4FF"
                                                strokeWidth="1.6"
                                                strokeLinecap="square"
                                            />
                                        </svg>
                                    </div>
                                    Инструкция использования
                                    <br />
                                    цифрового кошелька
                                </a>

                                <a
                                    href="https://otzovik.com/reviews/elektronniy_koshelek_trust_wallet/ "
                                    target="_blank"
                                    className={styles.profileNavItem}
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 13.3637 19.9191 14.65 19.3333 15.7833L20.25 20.25L15.7833 19.3333C14.65 19.9191 13.3637 20.25 12 20.25Z"
                                            stroke="#CFE4FF"
                                            strokeWidth="1.83333"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            width="0.00833333"
                                            height="0.00833333"
                                            transform="matrix(-1 0 0 1 12 12)"
                                            stroke="#CFE4FF"
                                            strokeWidth="2.75"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            width="0.00833333"
                                            height="0.00833333"
                                            transform="matrix(-1 0 0 1 16.125 12)"
                                            stroke="#CFE4FF"
                                            strokeWidth="2.75"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            width="0.00833333"
                                            height="0.00833333"
                                            transform="matrix(-1 0 0 1 7.875 12)"
                                            stroke="#CFE4FF"
                                            strokeWidth="2.75"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Отзывы пользователей
                                </a>
                            </div>

                            <div className={styles.profileConnectInfo}>
                                <div className={styles.profileConnectRate}>
                                    <p className={styles.profileConnectRateTitle}>Рейтинг</p>

                                    <div className={styles.profileConnectRateContent}>
                                        <Star2 />
                                        <Star2 />
                                        <Star2 />
                                        <Star2 />
                                        <Star2 />
                                    </div>
                                </div>

                                <div className={styles.profileConnectNot}>
                                    <p className={styles.profileConnectNotText}>
                                        Нет цифрового кошелька Trust?
                                    </p>

                                    <div className={styles.profileConnectNotLinks}>
                                        <button
                                            className={styles.profileConnectNotLink}
                                            onClick={() => {
                                                setTrast(true);
                                            }}
                                        >
                                            Установить
                                        </button>

                                        <a
                                            target="_blank"
                                            href="https://t.me/check_brand"
                                            className={styles.profileConnectNotLink}
                                        >
                                            Поддержка
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalNew active={condition} setActive={setCondition}>
                <p className={cn(base.title2, styles.returnTitle)}>Условия вывода и возврата</p>

                <div className={styles.returnContent}>
                    <div className={styles.returnContentScroll}>
                        <p className={styles.returnSubtitle}>Вывод денежных средств</p>

                        <p className={styles.returnText}>
                            Для вывода подключите кошелёк, зайдите в профиль, выберите сертификат,
                            зайдите на страницу сертификата, нажмите кнопку «Вывести», укажите
                            данные карты на которую планируется вывод средств, подтвердите вывод.
                        </p>

                        <p className={styles.returnText}>
                            Средства поступят на вашу карту в течении от 1 до 7 банковских дней.
                        </p>

                        <p className={styles.returnText}>
                            При выводе средств до истечения 365 дней владения сертификатом
                            происходит перерасчёт по формуле: 1/2 ставки лицензионных выплат х
                            количество дней владения сертификатом.
                        </p>

                        <p className={styles.returnText}>
                            Вывод средств не препятствует последующему начислению.
                        </p>

                        <p className={styles.returnSubtitle}>Оформление возврата</p>

                        <p className={styles.returnText}>
                            Для возврата уплаченных за сертификат средств подключите кошелёк,
                            зайдите в профиль, выберите сертификат, зайдите на страницу сертификата,
                            нажмите кнопку «Оформить возврат», укажите контакты, подтвердите.
                        </p>

                        <p className={styles.returnText}>
                            Возврат средств будет осуществлён на вашу карту в течении от 1 до 7
                            банковских дней.
                        </p>

                        <p className={styles.returnText}>
                            При возврате уплаченных за сертификат средств до истечения 365 дней
                            владения сертификатом, взымается комиссия за услуги банка в размере 4%.
                        </p>

                        <p className={styles.returnText}>
                            В случае если ранее выводились начисленные средства, они так же
                            вычитаются из суммы подлежащих к возврату средств.
                        </p>

                        <p className={styles.returnText}>
                            В соответствии с Договором оферты не взымается комиссия и в полной мере
                            подлежат выводу начисленные и уплаченные за сертификат средства по
                            истечению 365 дней владения сертификатом.
                        </p>
                    </div>
                </div>

                <div className={styles.returnChangeInner}>
                    <button className={styles.returnChange}>Изменить</button>
                </div>
            </ModalNew>

            <ModalNew active={trast} setActive={setTrast}>
                <p className={cn(base.title2, styles.returnTitle)}>Подключение</p>

                <div className={styles.connectContent}>
                    <div className={styles.trustDownload}>
                        <a href="#" className={styles.trustDownloadText}>
                            Установите приложение Trust
                        </a>

                        <div className={styles.tooltipInner}>
                            <Question2 />

                            <div className={styles.tooltipContentWrapper}>
                                <div className={cn(styles.tooltipContent, styles.big)}>
                                    У вас не установлено приложение Trust. Нажмите Скачать
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src="/assets/img/trust.png" alt="trust" className={styles.trustImg} />

                    <div className={styles.trustInstruction}>
                        <a
                            href="https://t.me/+5nhoVbOlL9dmNjJi"
                            target="_blank"
                            className={styles.trustInstructionText}
                        >
                            Смотреть инструкцию
                        </a>

                        <div className={styles.tooltipInner}>
                            <Question2 />

                            <div className={styles.tooltipContentWrapper}>
                                <div className={styles.tooltipContent}>
                                    Просмотреть 60 сек. обучающий видеоролик
                                </div>
                            </div>
                        </div>
                    </div>

                    <a href={detectDeviceUrl()} target="_blank" className={styles.connectButton}>
                        Скачать плагин Trust
                    </a>
                </div>
            </ModalNew>

            <ContactsModal setIsConfirmedEmail={setIsConfirmedEmail} />
            {isConfirmedEmail && (
                <ConfirmEmailModal
                    isConfirmedEmail={isConfirmedEmail}
                    setIsConfirmedEmail={setIsConfirmedEmail}
                    email={email}
                />
            )}
        </>
    );
};
