exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TokenCard_items_wrapper__3Swt2 {\n    width: 100%;\n    container: itemsWrapper / inline-size;\n}\n\n.TokenCard_items_container__1G9Lg {\n    --items-count: 10;\n    --item-width: 50%;\n    --grid-gap: 8px;\n\n    display: grid;\n    grid-template-columns: repeat(var(--items-count), calc(var(--item-width) - var(--grid-gap)));\n    grid-template-rows: repeat(1, 100%);\n    grid-gap: var(--grid-gap);\n    grid-auto-flow: column;\n    overflow-x: auto;\n}\n\n.TokenCard_items_container__1G9Lg::-webkit-scrollbar {\n    height: 4px;\n}\n\n@container itemsWrapper (min-width: 480px) {\n    .TokenCard_items_container__1G9Lg {\n        --item-width: 33%;\n    }\n}\n\n@container itemsWrapper (min-width: 768px) {\n    .TokenCard_items_container__1G9Lg {\n        --item-width: 25%;\n    }\n}\n\n@container itemsWrapper (min-width: 1024px) {\n    .TokenCard_items_container__1G9Lg {\n        --item-width: 20%;\n    }\n}\n\n@container itemsWrapper (min-width: 1440px) {\n    .TokenCard_items_container__1G9Lg {\n        --item-width: 16.6%;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"items_wrapper": "TokenCard_items_wrapper__3Swt2",
	"items_container": "TokenCard_items_container__1G9Lg"
};