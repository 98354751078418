import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RecievedToken } from '../RecievedToken/RecievedToken';
import HeaderNew from '../../components/HeaderNew';
import FooterNew from '../../components/FooterNew';

const WrapperNew = ({headerWithBackground = false}) => {
    const isFirstRender = useRef(true);
    const [tokens, setTokens] = useState([]);
    const accessToken = localStorage.getItem('access_token');
    const [isShowModal, setIsShowModal] = useState(true);
    const [user, setUser] = useState(null);
    useEffect(() => {
        if (accessToken && isFirstRender.current === true) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/user/me/?viewed=false`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            )
                .then((res) => {
                    if (Number(res.status) === 200) {
                        setIsShowModal(true)
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return [];
                    }
                })
                .then((response) => {
                    setTokens(response);
                });

            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => {
                    if (res.status <= 210) {
                        return res.json();
                    }
                    if (res.status >= 400) {
                        return null;
                    }
                })
                .then((response) => {
                    setUser(response);
                });

            isFirstRender.current = false;
        }
    }, [accessToken, isFirstRender, accessToken]);

    return (
        <>
            {user && isShowModal && tokens.results && tokens.results.length > 0 && (
                <RecievedToken
                    tokensList={tokens}
                    handleCloseModal={() => setIsShowModal(false)}
                    user={user}
                />
            )}

            <HeaderNew withBackgraund={headerWithBackground} />

            <Outlet />

            <FooterNew />
        </>
    );
};

export default WrapperNew;
