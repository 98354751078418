const checkboxDataHelperByType = (checkbox, param) => {
    const variants = {
        paid: {
            ['Продано']: { ...param, value: false },
            ['В продаже']: { ...param, value: !checkbox.state },
        },
    };

    return variants[checkbox.title][checkbox.name];
};

export const handleChangeCheckboxState = (setFilterState, currentCheckbox, setParams) => {
    setFilterState((prev) =>
        prev.map((filter) => {
            if (filter.title !== currentCheckbox.type) return filter;
            const updatedElements = filter.elements.map((checkbox) => {
                const isCurrent = checkbox.name === currentCheckbox.name;
                if (isCurrent) {
                    setParams((prev) =>
                        prev.map((param) => {
                            if (param.title === checkbox.title) {
                                return checkboxDataHelperByType(checkbox, param)
                            }
                            return param;
                        }),
                    );
                    return { ...checkbox, state: !checkbox.state };
                }
                return { ...checkbox, state: false };
            });
            return { ...filter, elements: updatedElements };
        }),
    );
};

export const PAGE_FILTERS = {
    collections: [
        {
            title: 'Состояние',
            value: '5',
            elements: [
                {
                    name: 'В продаже',
                    state: true,
                    title: 'paid',
                },
                {
                    name: 'Продано',
                    state: false,
                    title: 'paid',
                },
            ],
        },
    ],
    packs: [
        {
            title: 'Состояние',
            value: '5',
            elements: [
                {
                    name: 'В продаже',
                    active: true,
                    title: 'paid',
                },
                {
                    name: 'Продано',
                    active: false,
                    title: 'paid',
                },
            ],
        },
    ],
    tokens: [
        {
            title: 'Состояние',
            value: '5',
            elements: [
                {
                    name: 'В продаже',
                    active: true,
                    title: 'paid',
                },
                {
                    name: 'Продано',
                    active: false,
                    title: 'paid',
                },
            ],
        },
    ],
};
