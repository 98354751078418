import React, { useState } from 'react';
import s from '../index.module.css'
import { InfoButtons } from './InfoButtons';
import { useGetActiveTokenBillQuery } from '../../../../../redux/api/billingService';

export const Info = ({token, collection, tokenRefetch}) => {
    const {data, refetch} = useGetActiveTokenBillQuery({token_id: token.id}, {skip: !token.wallet_owner})

    // мод. окно условия вывода и возврата
    const [isConditions, setIsConditions] = useState(false)

    // const helperCost = (value) => {
    //     if(value !== undefined) {
    //         return Math.floor(value / 100).toLocaleString('ru-RU')
    //     }
    // }

    return (
        <div className={s.wrapper}>
            <div
                className={s.items}
            >
                <p>{token && token.investor_royalty ? `Доходность: ${token.investor_royalty}%/день` : ''}</p>
                <p>Стоимость сертификата: {token && token.price} RUB</p>
                <p>
                    Начислено: {data && data.accrued  + data.frozen_amount  + data.withdrawn} RUB {data && data.total_result && `из ${data.total_result} RUB`}
                </p>
                <p>Выведено: {data && data.withdrawn ? data.withdrawn : '0'} RUB</p>

            </div>


            <InfoButtons
                token={token}
                data={data}
                collection={collection}
                isConditions={isConditions}
                setIsConditions={setIsConditions}
                tokenRefetch={refetch}
            />
            <div
                style={{
                    marginTop: '12px',
                    cursor: 'pointer'
                }}
                onClick={() => setIsConditions(true)}
            >
                <a className={s.link}>Условия вывода и возврата</a>
                <div className={s.line} />
            </div>

        </div>
    )
}
