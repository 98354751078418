exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TokenCommonFieldsForm_loadingContainer__1fzSz {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"loadingContainer": "TokenCommonFieldsForm_loadingContainer__1fzSz"
};