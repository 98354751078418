import React, {useEffect, useState} from 'react';

import './index.css';

const TransferMenu = ({active, setActive}) => {
    return (
        <>
            <div className={`transfer__menu${active ? ' active' : ''}`}>
                <div className="transfer__menu--title--inner">
                    <p className="transfer__menu--title">
                        Transfer items
                    </p>

                    <img src="/assets/img/cross2.svg" alt="close" className="transfer__menu--close" onClick={() => setActive(false)} />
                </div>

                <div className="transfer__menu--info">
                    <p className="transfer__menu--items">
                        2 items
                    </p>

                    <button className="button transfer__menu--clear">
                        Clear all
                    </button>
                </div>

                <div className="transfer__items">
                    <div className="transfer__item">
                        <div className="transfer__item--content">
                            <div className="transfer__item--img--inner">
                                <img src="/assets/img/about-img1.png" alt="img" className="transfer__item--img" />

                                <img src="/assets/img/bnb.svg" alt="bnb" className="transfer__item--currency" />
                            </div>

                            <div className="transfer__item--text--inner">
                                <p className="transfer__item--title">
                                    100 pears G1-113
                                </p>

                                <p className="transfer__item--subtitle">
                                    Reebok NFT Certificates
                                </p>

                                <p className="transfer__item--text">
                                    Investor fee: 2.5%
                                </p>
                            </div>
                        </div>

                        <div className="transfer__item--actions">
                            <button className="button transfer__item--action">
                                <img src="/assets/img/delete3.svg" alt="delete" className="transfer__item--action--icon" />
                            </button>
                        </div>
                    </div>

                    <div className="transfer__item">
                        <div className="transfer__item--content">
                            <div className="transfer__item--img--inner">
                                <img src="/assets/img/about-img1.png" alt="img" className="transfer__item--img" />

                                <img src="/assets/img/bnb.svg" alt="bnb" className="transfer__item--currency" />
                            </div>

                            <div className="transfer__item--text--inner">
                                <p className="transfer__item--title">
                                    100 pears G1-113
                                </p>

                                <p className="transfer__item--subtitle">
                                    Reebok NFT Certificates
                                </p>

                                <p className="transfer__item--text">
                                    Investor fee: 2.5%
                                </p>
                            </div>
                        </div>

                        <div className="transfer__item--actions">
                            <button className="button transfer__item--action">
                                <img src="/assets/img/delete3.svg" alt="delete" className="transfer__item--action--icon" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="transfer__menu--total">
                    <div className="transfer__menu--total--address--inner">
                        <p className="transfer__menu--total--title">
                            Address
                        </p>

                        <textarea className="input textarea transfer__menu--total--textarea" placeholder="e.g. 0x1ed3... or destination.eth, destination.lenth"></textarea>
                    </div>

                    <button className="button transfer__menu--button">
                        Transfer 2 items
                    </button>

                    <p className="transfer__menu--transfer">
                        Transfer to “Collection name” Smart Contract
                    </p>
                </div>
            </div>

            <div className={`transfer__bottom${active ? ' active' : ''}`}>
                <div className="transfer__bottom--text--inner">
                    <p className="transfer__bottom--text--items">
                        2 items
                    </p>

                    <button className="button transfer__bottom--action">
                        Select all
                    </button>

                    <button className="button transfer__bottom--action">
                        Clear
                    </button>
                </div>

                <div className="transfer__bottom--buttons">
                    <button className="button transfer__bottom--button">
                        <img src="/assets/img/hide.svg" alt="hide" className="transfer__bottom--button--icon" />

                        Hide
                    </button>

                    <button className="button transfer__bottom--button">
                        <img src="/assets/img/send.svg" alt="transfer" className="transfer__bottom--button--icon" />

                        Transfer 2 items
                    </button>
                </div>
            </div>
        </>
    )
}

export default TransferMenu;