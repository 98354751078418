import React from 'react';

import './index.css';

import About from '../../components/About';
import BrandBg from '../../components/BrandBg';
import AboutBlock from '../../components/About/AboutBlock';

const brandNft = {
    titleOrange: 'BNC:',
    title: 'Brand NFT Certificates',
    elements: [
        {
            img: '/assets/img/about1.png',
            title: 'Do you own a branded item?',
            textElements: [
                {
                    text: 'We all love beautiful high-quality branded items.'
                },
                {
                    text: 'They are an essential part of our lives.'
                }
            ]
        },
        {
            img: '/assets/img/about2.png',
            title: 'Do you have a story behind your branded items?',
            textElements: [
                {
                    text: 'We all have interesting stories related to our branded items.'
                },
                {
                    text: 'How we acquired them and what we achieved because of them.'
                }
            ]
        },
        {
            img: '/assets/img/about3.png',
            title: 'Mint your own Brand story!',
            textElements: [
                {
                    text: 'Put your story in NFT.'
                },
                {
                    text: 'Immortalize it in Blockchain.'
                }
            ]
        },
        {
            img: '/assets/img/about4.png',
            title: 'Sell your innovative NFT',
            textElements: [
                {
                    text: 'Your story can become a script for brand advertising.'
                },
                {
                    text: 'Millions of people will hear about it.'
                }
            ]
        }
    ]
}

const personNft = {
    titleOrange: 'PNC:',
    title: 'Person NFT Certificates',
    elements: [
        {
            img: '/assets/img/about5.png',
            title: 'Do you have a favorite celebrity?',
            textElements: [
                {
                    text: 'We admire the stars. They shine and show us the way.'
                },
                {
                    text: 'Now they all go to the NFT.'
                }
            ]
        },
        {
            img: '/assets/img/about6.png',
            title: 'Do you want to own his/her personal story?',
            textElements: [
                {
                    text: "The most unique thing is that you can't create many."
                },
                {
                    text: 'Super story of stars are super unique NFTs.'
                }
            ]
        },
        {
            img: '/assets/img/about7.png',
            title: 'Mint the Super story!',
            textElements: [
                {
                    text: 'Invest in your celebrity.'
                },
                {
                    text: 'Because of you, the world will know his/her story.'
                }
            ]
        },
        {
            img: '/assets/img/about8.png',
            title: 'Collect rare NFTs',
            textElements: [
                {
                    text: "The most unique thing is that you can't create many."
                },
                {
                    text: 'Super story of star is super unique NFT.'
                }
            ]
        }
    ]
}

const nrcNft = {
    titleOrange: 'NRC:',
    title: 'NFT—related clothing',
    elements: [
        {
            img: '/assets/img/about9.png',
            title: 'Can NFT live in two worlds?',
            textElements: [
                {
                    text: 'A T-shirt linked to the NFT,'
                },
                {
                    text: 'with QRAnBOOK technology can.'
                }
            ]
        },
        {
            img: '/assets/img/about10.png',
            title: 'Want to know how it works?',
            textElements: [
                {
                    text: "Become the owner of the world's first prototype"
                },
                {
                    text: 'of NFT - connected clothing.'
                }
            ]
        },
        {
            img: '/assets/img/about11.png',
            title: 'Mint your utility NFT!',
            textElements: [
                {
                    text: 'Invest in technology  to make'
                },
                {
                    text: 'any physical thing a personal NFT.'
                }
            ]
        },
        {
            img: '/assets/img/about12.png',
            title: 'Surprise your friends with a smart NFT',
            textElements: [
                {
                    text: "Friends won't believe you have a T-shirt"
                },
                {
                    text: 'whose ownership is recorded in Blockchain.'
                }
            ]
        }
    ]
}

const AboutPage = () => {
    return(
        <>
            <BrandBg src="/assets/img/about-banner.jpg" />

            <div className="aboutblock">
                <div className="container">
                    <div className="aboutblock__inner">
                        <About title="About project" text="The largest Minting Platform. Mint new types of NFT in a completely new category of Stories: Brand NFT Certificates (BNC), subcategories of Brand story, are a description of a branded item and its history; Person NET Certificates (PNG), NFS subcategories Superstory - Celebrity Stories Uploaded to the Blockchain; NFT - related clothes (NRC), a subcategory of Idea, for the mint of which it is necessary to have a strong idea that is placed in the metadata of the NFT and is associated with a physical T-shirt via QR. Become pioneers in the new dimension of NFT, invest in stories and ideas, change the world for the better." />

                        <div className="about__nft--block">
                            <p className="about__subtitle">
                                New kinds of NFT
                            </p>

                            <div className="about__nft--block--content">
                                <div className="about__nft--block--item">
                                    <div className="about__nft--block--item--img--inner">
                                        <img src="/assets/img/about-img1.png" alt="about" className="about__nft--block--item--img" />
                                    </div>
                                </div>

                                <div className="about__nft--block--item">
                                    <div className="about__nft--block--item--img--inner">
                                        <img src="/assets/img/about-img2.png" alt="about" className="about__nft--block--item--img" />
                                    </div>
                                </div>

                                <div className="about__nft--block--item">
                                    <div className="about__nft--block--item--img--inner">
                                        <img src="/assets/img/about-img3.png" alt="about" className="about__nft--block--item--img" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AboutBlock data={brandNft} />
                        <AboutBlock data={personNft} />
                        <AboutBlock data={nrcNft} />

                        <div className="about__white--content">
                            <a href="#" className="about__white--button">
                                Whitepaper

                                <img src="/assets/img/download.svg" alt="download" />
                            </a>

                            <div className="collection__social--inner mobile">
                                <a
                                    href=""
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/twitter.svg"
                                        alt="twitter"
                                        className="collection__social--icon"
                                    />
                                </a>

                                <a
                                    href=""
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/discord.svg"
                                        alt="discord"
                                        className="collection__social--icon"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutPage;