import React from 'react';

const Info2 = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_16464_4630)">
                <path
                    d="M16.5758 8.40188C16.5758 12.8035 12.9548 16.3819 8.50078 16.3819C4.04678 16.3819 0.425781 12.8035 0.425781 8.40188C0.425781 4.00027 4.04678 0.421875 8.50078 0.421875C12.9548 0.421875 16.5758 4.00027 16.5758 8.40188ZM8.50078 1.68187C4.74378 1.68187 1.70078 4.68907 1.70078 8.40188C1.70078 12.1147 4.74378 15.1219 8.50078 15.1219C12.2578 15.1219 15.3008 12.1147 15.3008 8.40188C15.3008 4.68907 12.2578 1.68187 8.50078 1.68187ZM9.35078 12.5347V7.49467C9.35078 7.02427 8.97678 6.65467 8.50078 6.65467C8.02478 6.65467 7.65078 7.02427 7.65078 7.49467V12.5347C7.65078 13.0051 8.02478 13.3747 8.50078 13.3747C8.97678 13.3747 9.35078 13.0051 9.35078 12.5347ZM8.50078 3.42908C7.83778 3.42908 7.31078 3.94987 7.31078 4.60507C7.31078 5.26027 7.83778 5.78108 8.50078 5.78108C9.16378 5.78108 9.69078 5.26027 9.69078 4.60507C9.69078 3.94987 9.16378 3.42908 8.50078 3.42908Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_16464_4630">
                    <rect width="17" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Info2;
