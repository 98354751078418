import React from 'react';
import Joi from '@hapi/joi';
import { useForm } from 'react-hook-form';
import { Form } from '../../common/Form';
import { TextField } from '../../common/TextField';
import { useValidationResolver } from '../../hooks/useValidationResolver';

import './index.css';
import CrossIcon from '../../icons/CrossIcon';

const schema = Joi.object({
    field_1: Joi.string().required(),
    field_2: Joi.string().required(),
    field_3: Joi.string().required(),
    field_4: Joi.string(),
    field_5: Joi.string().required(),
    field_6: Joi.string().required(),
    field_7: Joi.string().required(),
    field_8: Joi.string().required(),
    field_9: Joi.string().required(),
    field_10: Joi.string().required(),
    description: Joi.string(),
    email: Joi.string().required(),
    discord_name: Joi.string(),
});

const CarModal = (props) => {
    const { onClose, onSubmit, whiteListApplicationData, collection, isLoading } = props;

    const { validationResolver } = useValidationResolver(schema);

    const {
        register,
        handleSubmit: onSubmitForm,
        formState: { errors: formErrors },
    } = useForm({
        validationResolver,
        defaultValues: whiteListApplicationData,
        mode: 'onSubmit',
    });

    const handleSubmit = onSubmitForm((data) => {
        onSubmit(data);
    });

    return (
        <>
            <div className="modal__content">
                <div className="modal__content--top">
                    <h2 className="modal__title">Get on Whitelist</h2>
                    <CrossIcon className="modal__close" onClick={onClose} />
                </div>

                <div className="modal__content--bottom">
                    <p className="blue">{collection.name}</p>

                    <p className="create__item--text">
                        To get on Whitelist let us know the information about your car
                    </p>

                    <Form onSubmit={handleSubmit} className="whitelist_form">
                        <TextField
                            label="Enter model of the car"
                            placeholder="e.g. Model S Performance"
                            name="field_1"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputProps={{ ...register('field_1', { required: true }) }}
                            error={formErrors && formErrors['field_1']}
                        />
                        <TextField
                            label="Year"
                            placeholder="e.g. 2020"
                            name="field_2"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_2', { required: true }) }}
                            error={formErrors && formErrors['field_2']}
                        />
                        <TextField
                            label="Color"
                            placeholder="e.g. Red"
                            name="field_3"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_3', { required: true }) }}
                            error={formErrors && formErrors['field_3']}
                        />
                        <TextField
                            label="VIN"
                            placeholder="e.g. 5YJSA1E48LF411210"
                            name="field_4"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_4', { required: true }) }}
                            error={formErrors && formErrors['field_4']}
                        />
                        <TextField
                            label="Country of origin (Made in)"
                            placeholder="e.g. Made in USA"
                            name="field_5"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_5', { required: true }) }}
                            error={formErrors && formErrors['field_5']}
                        />
                        <TextField
                            label="Place of purchase (shop adress: country, city, street, office)"
                            placeholder="e.g. 5840 W. Centinela Ave, Los Angeles, CA 90045, USA"
                            name="field_6"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_6', { required: true }) }}
                            error={formErrors && formErrors['field_6']}
                        />
                        <TextField
                            label="Date of purchase"
                            placeholder="e.g. Feb 14, 2021"
                            name="field_7"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_7', { required: true }) }}
                            error={formErrors && formErrors['field_7']}
                        />
                        <TextField
                            label="Price"
                            placeholder="e.g. Price 89.900$"
                            name="field_8"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_8', { required: true }) }}
                            error={formErrors && formErrors['field_8']}
                        />
                        <TextField
                            label="Car number"
                            placeholder="e.g. 8YGK825"
                            name="field_9"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_9', { required: true }) }}
                            error={formErrors && formErrors['field_9']}
                        />
                        <TextField
                            label="URL link to a picture of your item"
                            placeholder="e.g. https://dropbox.com/..."
                            name="field_10"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('field_10', { required: true }) }}
                            error={formErrors && formErrors['field_10']}
                        />
                        <TextField
                            label="Describe story of the car"
                            placeholder="e.g. The battery in my car ran out. My phone also ran out. I went to the nearest house to call. A beautiful girl opened the door for me. We met and started dating. Then we got married. A miracle? Thank you Tesla!"
                            name="description"
                            inputWrapperClassName="modal__input_wrapper"
                            inputClassesProps={{
                                root: 'modal__root_textarea',
                                input: 'modal__root_textarea_input',
                            }}
                            multiline
                            inputProps={{ ...register('description', { required: true }) }}
                            error={formErrors && formErrors['description']}
                        />
                        <div className="modal__info--attention--container">
                            <p className="modal__info--attention">
                                Attention! The information above will be uploaded to the NFT
                                metadata.
                            </p>
                        </div>

                        <TextField
                            label="Specify data for feedback"
                            placeholder="Email"
                            name="email"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputWrapperClassName="modal__input_wrapper"
                            inputProps={{ ...register('email', { required: true }) }}
                            error={formErrors && formErrors['email']}
                        />

                        <TextField
                            placeholder="Discord name"
                            name="discord_name"
                            inputClassesProps={{
                                input: 'input modal__input',
                            }}
                            inputProps={{ ...register('discord_name', { required: true }) }}
                            error={formErrors && formErrors['fidiscord_nameled_13']}
                        />

                        <div className="modal__buttons">
                            {isLoading ? (
                                <button
                                    className="button modal__button modal__default--button"
                                    disabled
                                >
                                    Loading...
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="button modal__button modal__default--button"
                                >
                                    Confirm
                                </button>
                            )}
                        </div>
                    </Form>

                    <div className="modal__info">
                        <div className="modal__info--block">
                            After confirmation, the information will be sent to the moderator for
                            verification. If verified successfully, you will receive an email with
                            further instructions.
                            <br />
                            Whitelist cannot be sold or transferred to another person. It will be
                            attached to your wallet. Only you will be able to mint NFT of this
                            collection.
                            <br />
                            A whitelist gives the right to mint one token. No more than one
                            whitelist is possible in one collection during a minting.
                            <br />
                            By clicking Confirm you confirm your agreement to the processing of your
                            data.
                            <br />
                            By participating in the minting, you are assuming an obligation to
                            transfer the intellectual property rights to the information contained
                            in the NFT description belonging to you as the author of the story, in
                            case you sell the NFT containing such information in favor of the new
                            owner of the NFT.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(CarModal);
