import React, {useContext, useEffect, useState} from "react";
import File from "../../common/File";
import {useCreateCompanyMutation} from "../../redux/api/dataService";
import {NotificationContext} from "../../context/NotificationContext";
import NOTIFICATION_TYPES from "../../const/notifications/NOTIFICATION_TYPES";

export const CreateCompany = () => {
  const [companyName, setCompanyName] = useState('')
  const [fileValue, setFileValue] = useState(null)

  const [createCompany] = useCreateCompanyMutation()

  const {
    actions: { addNotification },
  } = useContext(NotificationContext);

  const handleCreateCompany = () => {
    if(companyName && fileValue) {
      let formData = new FormData();


      formData.append('name', companyName);


      formData.append('logo', fileValue)
      createCompany({
        body: formData
      })
        .unwrap()
        .then(() => {
          addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Компания создана',
          });
          setCompanyName('')
          setFileValue(null)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
    }
  }

  useEffect(() => {
    return () => {
      setCompanyName('')
      setFileValue(null)
    }
  }, [])

  return (
    <div className="default__padding createpage">
      <div className="container">
        <div className="createpage__inner">
          <div className="createpage__content--wrap">
            <h2 className="title left">Create company</h2>
            <div style={{marginTop: '40px', minWidth: '100%'}}>
              <input
                className="input service__item--input"
                placeholder="Company name"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
                style={{minWidth: '100%', marginBottom: '20px'}}
              />

              <File title='Logo company' type="logo_square" id="createpackLogo" value={fileValue} setValue={setFileValue}/>

            </div>

            <button
              className={`button token__card--content--price--buy`}
              onClick={handleCreateCompany}
              style={{height: '52px', margin: '30px auto 0 auto'}}
            >
              Создать
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}