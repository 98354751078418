exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loader_clearLoading__2DCMP {\n    text-align: center;\n    margin: 0 auto;\n    position: relative;\n    -ms-box-sizing: border-box;\n    -o-box-sizing: border-box;\n    box-sizing: border-box;\n    display: inline-block;\n}\n\n.Loader_loadingEffect__1bgM2 {\n    width: 75px;\n    height: 75px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.Loader_loadingEffect__1bgM2 > span,\n.Loader_loadingEffect__1bgM2 > span:before,\n.Loader_loadingEffect__1bgM2 > span:after {\n    content: '';\n    display: block;\n    border-radius: 50%;\n    border: 2px solid #2081e2;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n.Loader_loadingEffect__1bgM2 > img{\n    width: 100%;\n}\n.Loader_loadingEffect__1bgM2 > span {\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    border-left-color: transparent;\n    animation: Loader_effect-2__vo6qC 2s infinite linear;\n}\n.Loader_loadingEffect__1bgM2 > span:before {\n    width: 75%;\n    height: 75%;\n    border-right-color: transparent;\n}\n.Loader_loadingEffect__1bgM2 > span:after {\n    width: 50%;\n    height: 50%;\n    border-bottom-color: transparent;\n}\n@keyframes Loader_effect-2__vo6qC {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n@media (max-width: 768px) {\n    .Loader_loadingEffect__1bgM2 > img{\n        max-width: 50px;\n    }\n}", ""]);

// exports
exports.locals = {
	"clearLoading": "Loader_clearLoading__2DCMP",
	"loadingEffect": "Loader_loadingEffect__1bgM2",
	"effect-2": "Loader_effect-2__vo6qC"
};