import React, { useCallback, useEffect } from 'react';
import useTimer from '../../hooks/useTimer';

import css from './index.module.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setIsRefetch } from '../../redux/slices/collections';

const RefreshDataComponent = ({handleRefresh}) => {
    const { timeString, startTimer } = useTimer({
        intervalInMs: 1000 * 60,
    });

    const refreshHandler = () => {
        if(handleRefresh){
            // handleRefresh()
            handleRefresh((prev) => true)
        }

         startTimer();
    }

    useEffect(() => {
        startTimer();
    }, []);

    return (
        <div className={css.collectionContentUpdate}>
            <button
                onClick={refreshHandler}
                className={`button ${css.collectionContentUpdateButton}`}
            >
                <img
                    src="/assets/img/reload.svg"
                    alt="reload"
                    className={css.collectionContentUpdateIcon}
                />
            </button>

            <p className={css.collectionContentUpdateText}>{timeString}</p>
        </div>
    );
};

export default React.memo(RefreshDataComponent);
