import React, { useEffect, useState} from 'react'
import './offerItem.css';
import { useSelector} from "react-redux";
import {OfferModal} from "../OfferModal";
import {OfferItemModal} from "./modals/OfferItemModal";
import {BuyCertificate} from "../../../../components/BuyCertificate";
import {UseServiceModal} from "./modals/UseServiceModal/UseServiceModal";
import {NoServiceProvide} from "./modals/NoServiceProvide/NoServiceProvide";
import {TokenSuccessUse} from "../../../TokenSuccessUse";
import {useLazyGetHandlerTokenQuery} from "../../../../redux/api/handleService";
import {UserThanksModal} from "./modals/UserThanksModal";
import {UserContactsServiceModal} from "./modals/UserContactsServiceModal/UserContactsServiceModal";


export const OfferItem = ({item, token, usedItem, refetch, dataMyTokens, collection}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isModalActive, setIsModalActive] = useState(false)
  const [isOfferModalActive, setIsOfferModalActive] = useState(false)
  const [isUsedService, setIsUsedService] = useState(false)
  const [isSuccessUse, setIsSuccessUse] = useState(false)
  const [isBuyCertificate, setIsBuyCertificate] = useState(false)
  const [isMyToken, setIsMyToken] = useState(false)
  const [isUserThankModalActive, setIsUserThanksModalActive] = useState(false)
  const [isUserContact, setIsUserContact] = useState(false)

  const [contactType, setContactType] = useState('')

  const [isAllTokensSoldOut, setIsAllTokensSoldOut] = useState(false)
  const [isOtherToken, setIsOtherToken] = useState(false)

  const [tokenValue, setTokenValue] = useState()

  const isAuth = useSelector(state => state.auth.isAuth)

  const isNoServiceModal = useSelector(state => state.auth.isNoServiceModal)

  const [getToken] = useLazyGetHandlerTokenQuery()

  const usedItemFound = usedItem && usedItem.length && usedItem.find((el) => (el.service === item.id) && (el.token === token.id))


  useEffect(() => {
    if(dataMyTokens && dataMyTokens.results && dataMyTokens.results.length) {
      const myToken = dataMyTokens.results.find((el) => el.id === token.id)

      if(myToken) {
        setIsMyToken(true)
      }
    }
  }, [dataMyTokens])

  // если токен продан, показываем для продажи 1 токен из коллекции и пака
  useEffect(() => {
    if(token && token.pack.id && token.paid) {
      getToken({
        pack_id: token.pack.id,
        collection_id: token.collection.id
      })
        .unwrap()
        .then((result) => {
          if(result && result.results.length) {
            const filteredData = result.results.filter(el => !el.paid)
            setTokenValue(filteredData[0])
            setIsOtherToken(true)
          } else if(!result || !result.results.length) {
            setIsAllTokensSoldOut(true)
            setTokenValue(token)
          }
        })
    } else {
      setTokenValue(token)
    }
  }, [token])

  return (
    <>
      <div
        className={'wrapper'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={!isMyToken && isAuth && !token.paid
          ? () => {
            setIsOfferModalActive(true)
          }
          : !isMyToken && isAuth && token.paid
            ? () => {
              setIsOfferModalActive(true)
            }
          : usedItemFound && usedItemFound.status === 'clicked'
          ? () => {
            setIsUsedService(true)
          }
          :  () => {
            if(!usedItemFound || (usedItemFound && usedItemFound.status === 'not_used')) {
              setIsOfferModalActive(true)
            }
          }
        }
      >
        {usedItemFound && usedItemFound.status === 'used' ? <div className={'received'}>Получен</div> : <div className={'new'}>Новое</div> }
        <div style={{display: 'flex'}}>
          <img src={item.company.logo} alt={'logo'} className={'image'}/>
          <div className={'info'}>
            <p className={'name'}>{item.company.name}</p>
            <p className={'description'}>{item.preview}</p>
          </div>
        </div>
        <div style={{marginLeft: '8px'}}>
          {item.type === 'pass_to' &&  <p className={'type'}>Проход</p>}
          {item.type === 'vip_service' &&  <p className={'type'}>Сервис</p>}
          {item.type === 'discount' &&  <p className={'type'}>Скидка</p>}
          {item.type === 'gift' &&  <p className={'type'}>Подарок</p>}
          {item.certificate_type === 'unique' && <p className={'unique'}>Unique</p>}
          {item.certificate_type === 'premium' && <p className={'premium'}>Premium</p>}
          {item.certificate_type === 'vip' && <p className={'vip'}>VIP</p>}
        </div>
        {isHovered && (usedItemFound && usedItemFound.status !== 'used' || !usedItemFound) && <div className={'get'}>Получить</div>}
        {/*<BuyCertificate*/}
        {/*  token={token}*/}
        {/*  offer={item}*/}
        {/*/>*/}
      </div>
      <OfferItemModal
        offer={item}
        token={token}
        isOfferModalActive={isOfferModalActive}
        setIsOfferModalActive={setIsOfferModalActive}
        setIsModalActive={setIsModalActive}
        refetch={refetch}
        myToken={isMyToken}
        isAuth={isAuth}
        isAllTokensSoldOut={isAllTokensSoldOut}
      />

      <UseServiceModal
        offer={item}
        token={token}
        isUsedService={isUsedService}
        setIsUsedService={setIsUsedService}
        setIsModalActive={setIsModalActive}
        refetch={refetch}
        setIsSuccessUse={setIsSuccessUse}
      />

      <BuyCertificate
        offer={item}
        token={tokenValue}
        isBuyCertificate={isBuyCertificate}
        setIsBuyCertificate={setIsBuyCertificate}
        isAllTokensSoldOut={isAllTokensSoldOut}
        isOtherToken={isOtherToken}
        collection={collection}
      />

      <OfferModal
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
        item={item}
        token={token}
        refetch={refetch}
        setIsUserContact={setIsUserContact}
        setIsUserThanksModalActive={setIsUserThanksModalActive}
        setContactType={setContactType}
      />
      <NoServiceProvide
        item={item}
        isNoServiceProvideActive={isNoServiceModal}
      />

      <UserThanksModal
        item={item}
        contactType={contactType}
        isUserThankModalActive={isUserThankModalActive}
        setIsUserThanksModalActive={setIsUserThanksModalActive}
      />
      <UserContactsServiceModal
        contactType={contactType}
        isUserContact={isUserContact}
        setIsUserContact={setIsUserContact}
        item={item}
        token={token}
        refetch={refetch}
        setIsUserThanksModalActive={setIsUserThanksModalActive}
      />

      <TokenSuccessUse isSuccessUse={isSuccessUse} setIsSuccessUse={setIsSuccessUse}/>
    </>

  )
}
