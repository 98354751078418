import React, { useContext, useRef, useCallback, useState, useMemo } from 'react';
import axios from 'axios';
import { isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useNavigate } from 'react-router-dom';
import { CONFIRME_UPLOAD_TOKEN, TOKEN_BY_PACK } from '../../const/http/API_URLS';
import { HTTP_METHODS } from '../../const/http/HTTP_METHODS';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import { useFileDropzone } from '../../hooks/useFileDropzone';
import { arrayBufferToBinary } from '../../utils/arrayBufferToBinary';
import AsyncQueue from '../../utils/asyncQueue';
import { convertFileToBase64 } from '../../utils/convertFileToBase64';
import { generateNumericIndicator } from '../../utils/generateNumericIndicator';
import { getFileNameAndExt } from '../../utils/getFilenameAndExt';
import FileDropzone from '../FileDropzone';
import Loader from '../Loader';
import { useHideTokenMutation } from '../../redux/api/dataService';
import { onClose, onOpen } from '../../redux/dialogs/deleteEntityDialog';
import { normilizeError } from '../../utils/http/normilizeError';
import DeleteEntityDialog from '../../components/DeleteEntityDialog/DeleteEntityDialog';
import { EDIT_TOKEN_PAGE } from '../../const/http/CLIENT_URLS';
import restart from '../../assets/img/restart.png'

import css from './UploadManyTokensForm.module.css';
import '../../App.css';

const UPLOAD_FILES_MAX_LIMIT = 1000;

const UploadManyTokensForm = (props) => {
    const {
        numbering,
        tokenCommonName,
        tokenPrice,
        investorRoyalty,
        creatorRoyalty,
        isTokenNameEqualFileName,
        isAbleToUpload,
        isTokenUploadStarted,
        setIsTokenUploadStarted,
        tokensDataToUpload,
        preloadedTokens = [],
        requiredError,
        isCreateToken = false,
        checkIsAbleToUpload,
        isSendData,
        handlerErrors,
        handleClose = () => {}
    } = props;
    const authInfo = useSelector((state) => state.auth);


    const { isOpen, id: deletedTokenId } = useSelector((state) => state.deleteEntityDialog);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [
        hideToken,
        {
            isSuccess,
            isLoading: isDeletationProccessing,
            error: hideTokenError,
            reset: resetDeletationState,
        },
    ] = useHideTokenMutation();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const requestsQueueRef = useRef(new AsyncQueue({ maxParallelTasks: 2 }));

    const [numericIndicatorInProccess, setNumericIndicatorInProccess] = useState([]);
    const [numericIndicatorDone, setNumericIndicatorDone] = useState([]);
    const [numericIndicatorFailed, setNumericIndicatorFailed] = useState([]);

    const [preloadedTokensList, setPreloadedTokensList] = useState(
        preloadedTokens.map((pt, i) => {
            const numericIndicator = generateNumericIndicator(Number(numbering) + i);
            return {
                isPreloaded: true,
                id: pt.id,
                nameComponent: <>{pt.name}</>,
                name: pt.name,
                numericIndicator,
                tokenImgName: null,
                tokenImgFile: null,
                tokenPreviewName: null,
                tokenPreviewFile: null,
                tokenPrice: Number(pt.price),
                investorRoyalty: Number(pt.investor_royalty),
                creatorRoyalty: Number(pt.creator_royalty),
            };
        }) || [],
    );

    const {
        values: tokenImgValues,
        onAdd: onAddTokenImg,
        onDelete: onDeleteTokenImg,
        handleClearValues
    } = useFileDropzone({
        multiple: true,
        limit: UPLOAD_FILES_MAX_LIMIT,
    });

    const {
        values: tokenPreviewValues,
        onAdd: onAddTokenPreview,
        onDelete: onDeleteTokenPreview,
        handleClearValues: handleClearValuesPreview
    } = useFileDropzone({
        multiple: true,
        limit: UPLOAD_FILES_MAX_LIMIT,
    });

    const genrateTablesRow = useMemo(() => {
        if (tokenImgValues.length === 0) {
            return [];
        }
        const res = [];

        tokenImgValues.map((tiv, i) => {
            const fileNameAndExt = getFileNameAndExt(tiv.file.name);

            if (res.some((r) => r.tokenImgName === fileNameAndExt.fileName)) {
                return null;
            }

            const tokenPreviewImg = tokenPreviewValues.find((tpv) =>
                getFileNameAndExt(tpv.file.name).fileName.includes(fileNameAndExt.fileName),
            );
            const numericIndicator = generateNumericIndicator(Number(numbering) + i);

            res.push({
                nameComponent: (
                    <>
                        {isTokenNameEqualFileName ? (
                            <>{fileNameAndExt.fileName}</>
                        ) : (
                            <>{tokenCommonName}</>
                        )}
                    </>
                ),
                name: isTokenNameEqualFileName
                    ? fileNameAndExt.fileName
                    : `${tokenCommonName}${numericIndicator}`,
                numericIndicator,
                tokenImgName: tiv.file.name,
                tokenImgFile: tiv,
                tokenPreviewName: tokenPreviewImg ? tokenPreviewImg.file.name : null,
                tokenPreviewFile: tokenPreviewImg,
                tokenPrice,
                investorRoyalty,
                creatorRoyalty,
            });
        });

        return res;
    }, [
        tokenImgValues,
        tokenPreviewValues,
        numbering,
        tokenCommonName,
        tokenPrice,
        investorRoyalty,
        creatorRoyalty,
        isTokenNameEqualFileName,
    ]);

    const onDeleteHandler = useCallback((id) => {
        hideToken({ id });
    }, []);

    const removeTokenHandler = useCallback(
        ({ isPreloaded, id, fileImg, filePreview, numericIndicator }) => {
            if (numericIndicator) {
                setNumericIndicatorInProccess((p) => p.filter((n) => n !== numericIndicator));
                setNumericIndicatorDone((p) => p.filter((n) => n !== numericIndicator));
                setNumericIndicatorFailed((p) => p.filter((n) => n !== numericIndicator));
            }

            if (isPreloaded && id) {
                dispatch(
                    onOpen({
                        id,
                        onDelete: onDeleteHandler,
                        title: 'Are you sure you want to delete token?',
                    }),
                );
            }

            if (fileImg) {
                onDeleteTokenImg(fileImg);
            }

            if (filePreview) {
                onDeleteTokenPreview(filePreview);
            }
        },
        [],
    );

    const isNotFilled = !genrateTablesRow.length || !isAbleToUpload

    const closeDialogHandler = useCallback(() => {
        dispatch(onClose());
    }, []);

    const onEditUserHandler = useCallback(({ id }) => {
        if (id) {
            navigate(EDIT_TOKEN_PAGE({ id }));
        }
    }, []);

    const onUploadTokensHandler = useCallback(async () => {
        if (checkIsAbleToUpload && !checkIsAbleToUpload()) {
            return;
        }

        if (isNotFilled) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: requiredError,
            });
            return;
        }

        setIsTokenUploadStarted(true);

        Object.keys(tokensDataToUpload).forEach((key) => {
            if ((key !== 'investor_royalty' && key !== 'creator_royalty' && !tokensDataToUpload[key]) ||
                (isArray(tokensDataToUpload[key]) && !tokensDataToUpload[key].length)
            ) {
                delete tokensDataToUpload[key];
            }
        });

        await Promise.all(
            genrateTablesRow
                .filter((t) => !numericIndicatorDone.includes(t.numericIndicator))
                .map((token) => {
                    return new Promise((resolve) => {
                        const task = async () => {
                            setNumericIndicatorInProccess((p) => [...p, token.numericIndicator]);

                            try {
                                const res = await axios.request({
                                    method: HTTP_METHODS.POST,
                                    url: TOKEN_BY_PACK,
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${authInfo.accessToken}`,
                                    },
                                    data: {
                                        ...tokensDataToUpload,
                                        name: token.name,
                                        price: token.tokenPrice * 100,
                                        file_1_name_ext: token.tokenPreviewName,
                                        file_2_name_ext: token.tokenImgName,
                                    },
                                });

                                if (!res.data.file_1_pre_signed_url_data) {
                                    throw 'Bad request';
                                }

                                const imageBuffer = await convertFileToBase64(token.tokenImgFile.file);
                                const imageBlob = arrayBufferToBinary(
                                    imageBuffer,
                                    token.tokenImgFile.type,
                                );

                                try {
                                    await fetch(res.data.file_2_pre_signed_url_data, {
                                        method: HTTP_METHODS.PUT,
                                        body: imageBlob,
                                    }).catch((e) => {
                                        // console.log('fetcErr', { e });
                                    });
                                } catch (e) {
                                    setNumericIndicatorFailed((p) =>
                                        p.filter((num) => num !== token.numericIndicator),
                                    );
                                    // console.log({ e });
                                    throw `Token ${token.name} image upload failed`;
                                }

                                const previewBuffer = await convertFileToBase64(
                                    token.tokenPreviewFile.file,
                                );
                                const previewBlob = arrayBufferToBinary(
                                    previewBuffer,
                                    token.tokenPreviewFile.type,
                                );
                                try {
                                    await fetch(res.data.file_1_pre_signed_url_data, {
                                        method: HTTP_METHODS.PUT,
                                        body: previewBlob,
                                    }).catch((e) => {
                                        // console.log('fetcErr', { e });
                                    });
                                } catch (e) {
                                    // console.log({ e });
                                    throw `Token ${token.name} preview upload failed`;
                                }

                                let updateToken;
                                try {
                                    updateToken = await axios.request({
                                        method: HTTP_METHODS.PATCH,
                                        url: CONFIRME_UPLOAD_TOKEN(res.data.id),
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `Bearer ${authInfo.accessToken}`,
                                        },
                                        data: {
                                            file_1_name_ext: res.data.file_1_name_ext,
                                            file_2_name_ext: res.data.file_2_name_ext,
                                        },
                                    });
                                } catch (e) {
                                    console.log(e);
                                    throw `Token ${token.name} update failed`;
                                }
                                addNotification({
                                    type: NOTIFICATION_TYPES.SUCCESS,
                                    text:
                                        'Item was created',
                                });
                                handleClose()
                                // handleClearValues()
                                // handleClearValuesPreview()

                                return updateToken;

                            } catch (error) {
                                console.log(error, 'error')
                                setNumericIndicatorFailed((p) => [
                                    ...p,
                                    token.numericIndicator,
                                ]);
                                handlerErrors(error.response.data)
                                // handleClearValues()
                                // handleClearValuesPreview()
                            }
                        };

                        const getUploadsUrls = (urgently) => {
                            requestsQueueRef.current
                                .addTask(
                                    {
                                        key: token.numericIndicator,
                                        task,
                                    },
                                    urgently,
                                )
                                .then((res) => {
                                    setNumericIndicatorInProccess((p) => {
                                        return p.filter((el) => el !== token.numericIndicator);
                                    });
                                    setNumericIndicatorDone((p) => [...p, token.numericIndicator]);
                                    return resolve({
                                        numericId: token.numericIndicator,
                                    });
                                })
                                .catch((e) => {
                                    setNumericIndicatorFailed((p) => [
                                        ...p,
                                        token.numericIndicator,
                                    ]);
                                    setNumericIndicatorInProccess((p) => {
                                        return p.filter((el) => el !== token.numericIndicator);
                                    });

                                    setIsTokenUploadStarted(false);

                                    if (!e.response) {
                                        return;
                                    }

                                    const errorKeys = Object.keys(e.response.data);

                                    let error = `${e}`;

                                    if (typeof e.response.data === 'object') {
                                        error = `${errorKeys
                                            .map((k) => `${k} - ${e.response.data[k]}`)
                                            .join(', ')}`;
                                    }

                                    addNotification({
                                        type: NOTIFICATION_TYPES.ERROR,
                                        text: error,
                                    });
                                    // setTimeout(() => getUploadsUrls(true), 5000);
                                });
                        };

                        getUploadsUrls(true);
                    });
                }),
        );

        setIsTokenUploadStarted(false);
    }, [genrateTablesRow, tokensDataToUpload, isAbleToUpload, checkIsAbleToUpload]);

    const retryUploadHandler = (token) => {
        return new Promise(async (resolve, reject) => {
            try {
                setNumericIndicatorInProccess((p) => [...p, token.numericIndicator]);
                setNumericIndicatorFailed((p) => p.filter((num) => num !== token.numericIndicator));


                const res = await axios.request({
                    method: HTTP_METHODS.POST,
                    url: TOKEN_BY_PACK,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authInfo.accessToken}`,
                    },
                    data: {
                        ...tokensDataToUpload,
                        name: token.name,
                        price: token.tokenPrice,
                        file_1_name_ext: token.tokenPreviewName,
                        file_2_name_ext: token.tokenImgName,
                    },
                });

                if (!res.data.file_1_pre_signed_url_data) {
                    throw new Error('Bad request');
                }

                const imageBuffer = await convertFileToBase64(token.tokenImgFile.file);
                const imageBlob = arrayBufferToBinary(imageBuffer, token.tokenImgFile.type);

                try {
                    await fetch(res.data.file_2_pre_signed_url_data, {
                        method: HTTP_METHODS.PUT,
                        body: imageBlob,
                    });
                } catch (imageUploadError) {
                    console.error(`Token ${token.name} image upload failed:`, imageUploadError);
                    setNumericIndicatorFailed((p) => [...p, token.numericIndicator]);
                    setNumericIndicatorInProccess((p) => p.filter((el) => el !== token.numericIndicator));
                    setIsTokenUploadStarted(false);
                }

                const previewBuffer = await convertFileToBase64(token.tokenPreviewFile.file);
                const previewBlob = arrayBufferToBinary(previewBuffer, token.tokenPreviewFile.type);

                try {
                    await fetch(res.data.file_1_pre_signed_url_data, {
                        method: HTTP_METHODS.PUT,
                        body: previewBlob,
                    });
                } catch (previewUploadError) {
                    console.error(`Token ${token.name} preview upload failed:`, previewUploadError);
                    setNumericIndicatorFailed((p) => [...p, token.numericIndicator]);
                    setNumericIndicatorInProccess((p) => p.filter((el) => el !== token.numericIndicator));
                    setIsTokenUploadStarted(false);
                }

                const updateToken = await axios.request({
                    method: HTTP_METHODS.PATCH,
                    url: CONFIRME_UPLOAD_TOKEN(res.data.id),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authInfo.accessToken}`,
                    },
                    data: {
                        file_1_name_ext: res.data.file_1_name_ext,
                        file_2_name_ext: res.data.file_2_name_ext,
                    },
                });

                setNumericIndicatorInProccess((p) => p.filter((el) => el !== token.numericIndicator));
                setNumericIndicatorDone((p) => [...p, token.numericIndicator]);
                setIsTokenUploadStarted(false);
                resolve(updateToken);
            } catch (error) {
                console.error(`Token ${token.name} upload failed:`, error);
                setNumericIndicatorFailed((p) => [...p, token.numericIndicator]);
                setNumericIndicatorInProccess((p) => p.filter((el) => el !== token.numericIndicator));
                setIsTokenUploadStarted(false);

                if (error.response) {
                    const errorKeys = Object.keys(error.response.data);
                    const errorMessage = typeof error.response.data === 'object'
                      ? errorKeys.map((k) => `${k} - ${error.response.data[k]}`).join(', ')
                      : `${error.response.data}`;

                    addNotification({
                        type: NOTIFICATION_TYPES.ERROR,
                        text: errorMessage,
                    });
                }
            }
        });
    };

    useEffect(() => {
        if (hideTokenError) {
            closeDialogHandler();
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(hideTokenError),
            });
        }
    }, [hideTokenError]);

    useEffect(() => {
        if (isSuccess && deletedTokenId) {
            setPreloadedTokensList((p) => p.filter((t) => t.id !== deletedTokenId));
            resetDeletationState();
            closeDialogHandler();
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Token deleted successfuly',
            });
        }
    }, [isSuccess, deletedTokenId]);

    console.log(genrateTablesRow, 'genrateTablesRow')
    console.log(preloadedTokensList, 'preloadedTokensList')
    return (
        <>
            <div className="admin__add--item half">
                <div className="admin__add--title--inner">
                    <p className="admin__add--title">Images</p>
                </div>

                <FileDropzone
                    multiple
                    availableFormats={[
                        'image/png',
                        'image/gif',
                        'image/jpeg',
                        'image/jpg',
                        'video/mp4',
                        'video/mpeg',
                        'video/webm',
                    ]}
                    values={tokenImgValues}
                    disabled={isTokenUploadStarted}
                    onAdd={onAddTokenImg}
                    id="createpackImgs"
                    onDelete={onDeleteTokenImg}
                />

                <label htmlFor="createpackImgs" className="create__item--label preview mt">
                    <img
                        src="/assets/img/logo-img.svg"
                        alt="img"
                        className="create__item--label--img"
                    />
                </label>
            </div>

            <div className="admin__add--item half">
                <div className="admin__add--title--inner">
                    <p className="admin__add--title">Preview images</p>
                </div>

                <FileDropzone
                    multiple
                    availableFormats={['image/png', 'image/gif', 'image/jpg', 'image/jpeg']}
                    values={tokenPreviewValues}
                    disabled={isTokenUploadStarted}
                    onAdd={onAddTokenPreview}
                    id="createpackPreview"
                    onDelete={onDeleteTokenPreview}
                />

                <label htmlFor="createpackPreview" className="create__item--label preview mt">
                    <img
                        src="/assets/img/logo-img.svg"
                        alt="img"
                        className="create__item--label--img"
                    />
                </label>
            </div>

            <div className="admin__add--item full">
                <div className="admin__add--title--inner">
                    <p className="admin__add--title">Loading table</p>
                </div>

                <div className={`create__loading ${isSendData && isNotFilled ? 'error_input' : ''}`}>
                    <div className="create__loading--content">
                        <div className="create__loading--item">
                            <p className="create__loading--title">Name</p>

                            <p className="create__loading--title">Image</p>

                            <p className="create__loading--title">Preview</p>

                            <p className="create__loading--title">Upload</p>

                            <p className="create__loading--title">Action</p>
                        </div>

                        {![...genrateTablesRow, ...preloadedTokensList].lenght && (
                            <div className={css.noItems}>Upload files to see table</div>
                        )}
                        {[...genrateTablesRow, ...preloadedTokensList].map((row, i) => (
                            <div
                                className="create__loading--item content__item"
                                key={row.numericIndicator + i}
                            >
                                <div className="create__loading--text hide-overflow-ellipsis">
                                    <p className={css.overflowEllipsisWrapper}>
                                        {i + 1}. {row.nameComponent}
                                        {!row.id && !isTokenNameEqualFileName && (
                                            <span className="green__c">{row.numericIndicator}</span>
                                        )}
                                    </p>
                                </div>

                                <p className="create__loading--text hide-overflow-ellipsis">
                                    {row.tokenImgName}
                                </p>

                                <p className="create__loading--text hide-overflow-ellipsis">
                                    {row.tokenPreviewName}
                                </p>

                                <p className="create_pack_proccess_status_container create__loading--text">
                                    {!row.id &&  numericIndicatorInProccess.includes(row.numericIndicator) && (
                                        <span className="yellow__c">In process</span>
                                    )}


                                    {!row.id && !numericIndicatorInProccess.includes(row.numericIndicator) ?
                                        numericIndicatorFailed.includes(row.numericIndicator) ?
                                            (
                                                <div className={css.failed}>
                                                    <span className="red__c">Failed </span>
                                                    <IconButton
                                                        disableRipple
                                                        sx={{ padding: 0 }}
                                                        onClick={() =>
                                                            retryUploadHandler(row)
                                                        }
                                                >
                                                        <div>
                                                            <img
                                                                src={restart}
                                                                alt="restart"
                                                            />
                                                        </div>
                                                     </IconButton>
                                                </div>

                                    ) :
                                        (numericIndicatorDone.includes(row.numericIndicator) ||
                                            row.isPreloaded) && <span className="green__c">Done</span>
                                     : ''}
                                </p>

                                <p className="create__loading--actions create__loading--text">
                                    <IconButton
                                        disableRipple
                                        sx={{ padding: 0 }}
                                        onClick={() =>
                                            removeTokenHandler({
                                                isPreloaded: row.isPreloaded,
                                                id: row.id,
                                                numericIndicator: row.numericIndicator,
                                                fileImg: row.tokenImgFile,
                                                filePreview: row.tokenPreviewFile,
                                            })
                                        }
                                    >
                                        <div className={css.trashIconBox}>
                                            <img
                                                src="/assets/img/delete.svg"
                                                alt="delete"
                                                className="create__loading--delete"
                                            />
                                        </div>
                                    </IconButton>
                                    {row.isPreloaded && (
                                        <IconButton
                                            disableRipple
                                            sx={{ padding: 0 }}
                                            onClick={() => onEditUserHandler({ id: row.id })}
                                        >
                                            <div className={css.editIconBox}>
                                                <img
                                                    src="/assets/img/edit.svg"
                                                    alt="edit"
                                                    className="create__loading--delete"
                                                />
                                            </div>
                                        </IconButton>
                                    )}
                                </p>
                            </div>
                        ))}
                    </div>

                    {!isCreateToken && (
                        <button
                            className={`button create__loading--button ${css.btn}`}
                            onClick={onUploadTokensHandler}
                            disabled={isTokenUploadStarted || genrateTablesRow.every((token) =>
                              numericIndicatorDone.includes(token.numericIndicator))}
                        >
                            Submit Upload
                        </button>
                    )}
                </div>
            </div>
            {isCreateToken && (
                <div className={css.submitBtnContainer}>
                    <button
                        onClick={onUploadTokensHandler}
                        disabled={isTokenUploadStarted}
                        className="button create__button"
                    >
                        Submit upload
                    </button>
                </div>
            )}
            {isOpen && (
                <DeleteEntityDialog
                    open={isOpen}
                    isDeletationProccessing={isDeletationProccessing}
                    onClose={closeDialogHandler}
                />
            )}
        </>
    );
};

export default React.memo(UploadManyTokensForm);
