import React from 'react';

const Check2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.50821 0.946039C8.93032 1.34805 8.93032 1.99984 8.50821 2.40185L4.18468 6.51949C3.76257 6.9215 3.07819 6.9215 2.65608 6.51949L0.494318 4.46067C0.0722066 4.05866 0.0722066 3.40687 0.494318 3.00486C0.916428 2.60285 1.60081 2.60285 2.02292 3.00486L3.42038 4.33578L6.97961 0.946039C7.40172 0.544029 8.0861 0.544029 8.50821 0.946039Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Check2;
