import React from 'react';

const Filter = ({ ...props }) => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 4.35547C2 4.04052 2.12511 3.73848 2.34781 3.51578C2.57051 3.29308 2.87256 3.16797 3.1875 3.16797H16.6458C16.9608 3.16797 17.2628 3.29308 17.4855 3.51578C17.7082 3.73848 17.8333 4.04052 17.8333 4.35547C17.8333 4.67041 17.7082 4.97246 17.4855 5.19516C17.2628 5.41786 16.9608 5.54297 16.6458 5.54297H3.1875C2.87256 5.54297 2.57051 5.41786 2.34781 5.19516C2.12511 4.97246 2 4.67041 2 4.35547ZM5.16667 9.10547C5.16667 8.79052 5.29178 8.48848 5.51448 8.26578C5.73718 8.04308 6.03922 7.91797 6.35417 7.91797H13.4792C13.7941 7.91797 14.0962 8.04308 14.3189 8.26578C14.5416 8.48848 14.6667 8.79052 14.6667 9.10547C14.6667 9.42041 14.5416 9.72246 14.3189 9.94516C14.0962 10.1679 13.7941 10.293 13.4792 10.293H6.35417C6.03922 10.293 5.73718 10.1679 5.51448 9.94516C5.29178 9.72246 5.16667 9.42041 5.16667 9.10547ZM8.72917 12.668C8.41422 12.668 8.11218 12.7931 7.88948 13.0158C7.66678 13.2385 7.54167 13.5405 7.54167 13.8555C7.54167 14.1704 7.66678 14.4725 7.88948 14.6952C8.11218 14.9179 8.41422 15.043 8.72917 15.043H11.1042C11.4191 15.043 11.7212 14.9179 11.9439 14.6952C12.1666 14.4725 12.2917 14.1704 12.2917 13.8555C12.2917 13.5405 12.1666 13.2385 11.9439 13.0158C11.7212 12.7931 11.4191 12.668 11.1042 12.668H8.72917Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Filter;
