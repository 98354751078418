import { useSelector } from 'react-redux';

export const breakpoints = {
    small: '768px',
    medium: '768px',
    large: '1024px',
    xlarge: '1240px',
}

export const useWindowResize = () => {
    const innerWidth = useSelector((state) => state.windowSize.width)

    const isDesktop = innerWidth + 1 > parseInt(breakpoints.large)
    const isTablet =
        innerWidth <= parseInt(breakpoints.xlarge) &&
        innerWidth > parseInt(breakpoints.small)
    const isMobile = innerWidth <= parseInt(breakpoints.small)

    return { isDesktop, isTablet, isMobile, innerWidth }
}
