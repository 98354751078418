import React from 'react';

const User = ({ ...props }) => {
    return (
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.5 7.5C9.22589 7.5 10.625 6.10089 10.625 4.375C10.625 2.64911 9.22589 1.25 7.5 1.25C5.77411 1.25 4.375 2.64911 4.375 4.375C4.375 6.10089 5.77411 7.5 7.5 7.5Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8684 13.75C12.8684 11.3313 10.4621 9.375 7.49961 9.375C4.53711 9.375 2.13086 11.3313 2.13086 13.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default User;
