import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const SpoilerBlock = ({ title, icon, children }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div
            className={cn(styles.tokenInfoItem, {
                [styles.open]: open,
            })}
        >
            <div className={styles.tokenInfoItemTop} onClick={() => setOpen((prev) => !prev)}>
                <p className={styles.tokenInfoItemTopName}>
                    {icon}
                    {title}
                </p>

                <svg
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.77789 7.68192L-6.7148e-08 1.53617L1.44422 -5.05119e-07L6.5 5.37767L11.5558 -6.31287e-08L13 1.53617L7.22211 7.68192C7.03057 7.88559 6.77083 8 6.5 8C6.22917 8 5.96943 7.88559 5.77789 7.68192Z"
                        fill="#2069E2"
                    />
                </svg>
            </div>

            {open && <div className={styles.tokenInfoItemContent}>{children}</div>}
        </div>
    );
};

export default SpoilerBlock;
