import axios from 'axios';
import { BASE_AUTH_API_URL, BASE_USER_API_URL, BASE_API_URL } from './const/http/API_URLS';

export const auth = axios.create({
    baseURL: BASE_AUTH_API_URL,
});

export const user = axios.create({
    baseURL: BASE_USER_API_URL,
});

export const data = axios.create({
    baseURL: BASE_API_URL,
});
