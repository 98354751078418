import React from 'react';

const statuses = {
    "Ready": "",
    "Waiting": "wait",
    "Stoped": "stop"
}

const WhithdrawItem = ({data, id}) => {
    const { status, avatar, collection, accounted, withdrawn, user, wallet, last, action = false } = data;

    return(
        <div className="stats__item--values">
            <div className="stats__item--day stats__item--item stats__item--status withdraw__item--wrap off700">
                <p className="stats__item--value">{id}</p>

                <div className={`stats__item--status--circle ${statuses[status]}`}></div>

                <p className="stats__item--status--value whithdraw__status">{status}</p>
            </div>

            <div className="stats__item--day stats__item--item withdraw__item--wrap big">
                <p className="whithdraw__index--mobile">
                    {id}
                </p>

                <div className="stats__item--avatar--inner withdraw__item">
                    {!avatar && <img
                        src={avatar}
                        alt="avatar"
                        className="stats__item--avatar"
                    />}
                </div>

                <div className="stats__item--wrapper withdraw__item">
                    <p className="withdraw__item--collection--name">{collection.name}</p>

                    <p className="withdraw__item--token--name">{collection.token}</p>
                </div>
            </div>

            <div className="stats__item--item withdraw__item--wrap whithdraw__flex">
                <p className="stats__item--value">{accounted} ETH</p>

                <p className={`whithdraw__status--mobile ${statuses[status]}`}>
                    {status}
                </p>
            </div>

            <div className="stats__item--item stats__item--value withdraw__item--wrap off700 activeoff1200">
                {withdrawn} ETH
            </div>

            <div className="stats__item--item withdraw__item--wrap off998 activeoff1600">
                <p className="withdraw__item--text link">{user}</p>
            </div>

            <div className="stats__item--item withdraw__item--text withdraw__item--wrap off1200 activeoff1600">
                {wallet}
            </div>

            <div className="stats__item--item withdraw__item--text withdraw__item--wrap off1200 activeoff1600">
                {last}
            </div>

            <div className="stats__item--item stats__item--value withdraw__item--wrap off998">
                {/* {!action && "---"} */}
                <button className="button whithdraw__button">
                    Withdraw
                </button>
            </div>
        </div>
    )
}

export default WhithdrawItem;