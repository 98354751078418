const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/reviews`;

export const getReviews = async (page, pageSize) => {
  // const url = new URL(BASE_URL);
  // url.searchParams.append('page', page);
  // url.searchParams.append('page_size', pageSize);
  const url = `${BASE_URL}?page=${page}&page_size=${pageSize}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const addReview = async (review, token) => {
  const response = await fetch(BASE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: review,
  });
  const data = await response.json();
  return data;
};

export const deleteReview = async (id, token) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization:  `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

export const confirmReview = async (id, token) => {
  //В API нет метода на принятие отзыва
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization:  `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

export const calculatePercentages = (rating) => {
  const percentOfFive = (rating / 5) * 100;
  const percentToFive = 100 - percentOfFive;

  return {
    percentOfFive: percentOfFive.toFixed(2),
    percentToFive: percentToFive.toFixed(2)
  };
};

export const getReviewWord = (count) => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return `отзыв`;
  } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
    return `отзыва`;
  } else {
    return `отзывов`;
  }
};

export const calculatePercentagesStars = (starCounts) =>  {
  const totalReviews = Object.values(starCounts).reduce((sum, count) => sum + count, 0);

  const percentages = {};
  for (const [star, count] of Object.entries(starCounts)) {
      percentages[star] = ((count / totalReviews) * 100).toFixed(2) + '%';
  }

  return percentages;
}
