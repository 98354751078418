import Modal from "../common/Modal";
import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetTokenQuery} from "../redux/api/dataService";
import styles from './TokenSuccessBuy/tokenBuy.module.css'
import Input from "../common/Input";

export const TokenSuccessUse = (
  {
    setIsSuccessUse,
    isSuccessUse
  }) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const {data} = useGetTokenQuery({id})

  return (
    <Modal active={isSuccessUse} title={'Поздравляем!'} onClose={() => setIsSuccessUse(false)}>
      {data && Object.keys(data).length > 0 &&
        <>
          <p className={styles.name}>{data.collection.name}</p>
          <p className={styles.text}>{data.name}</p>

          {data.file_1 && <img src={data.file_1} className={styles.img} alt={'token image'}/>}
          <p className={styles.text}>Вы успешно воспользовались сервисом</p>

          <button
            className="button token__card--content--price--buy"
            style={{height: '52px', width: '100%'}}
            onClick={() => setIsSuccessUse(false)}
          >
            Благодарим вас
          </button>
          <div  style={{marginTop: '44px', minWidth: '100%'}}>
            <Input
              placeholder="Благодарим вас за использование сервиса. Мы рады стараться для вас. В следующий раз постараемся придумать для вас что то новое и интересное. Спасибо что вы с нами. Замечания и предложения вы можете оставить в Телеграм канале @bratouverie"
              textarea
            />
          </div>

        </>
      }
    </Modal>
  )
}