export const generateNumArrInRange = (start, end, step = 1) => {
    const len = Math.floor((end - start) / step) + 1;
    if (len > 0) {
        return Array(len)
            .fill(0)
            .map((_, idx) => start + idx * step);
    }

    return [];
};
