import React from 'react';

const CheckIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 15" fill="none">
            <path
                fill="currentColor"
                d="M17.405 0 5.893 10.345 2.595 7.377 0 9.713 5.893 15l.663-.58v-.017L20 2.336 17.405 0Z"
            />
        </svg>
    );
};

export default React.memo(CheckIcon);
