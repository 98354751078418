import Modal from "../../../../../../common/Modal";
import React, {useState} from 'react'
import styles from '../../../OfferModal/styles.module.css'
import {useClickOnSocialMutation, useSendApplicationMutation} from "../../../../../../redux/api/dataService";
import {useDispatch} from "react-redux";
import {setTelegram, setWhatsapp} from "../../../../../../redux/slices/auth";
import InputMask from "react-input-mask";
import {useChangeUserDataMutation} from "../../../../../../redux/api/userService";
import {TelegramMaskedInput} from "../../../../../../common/TelegramInput";

export const UserContactsServiceModal = (
  {
    contactType,
    isUserContact,
    setIsUserContact,
    item,
    token,
    refetch,
    setIsUserThanksModalActive
  }) => {
  const [value, setValue] = useState('')
  const [sendApplication] = useSendApplicationMutation()
  const [clickOnSocial] = useClickOnSocialMutation()

  const [isSendData, setIsSendData] = useState(false)

  const [changeData] = useChangeUserDataMutation()

  const dispatch = useDispatch()

  const handleSendApplication = () => {
    setIsSendData(true)
    const payload = {}
    if(contactType === 'telegram') {
      payload.telegram = value
      dispatch(setTelegram(value))
    }
    if(contactType === 'whatsapp') {
      payload.whatsapp = value
      dispatch(setWhatsapp(value))
    }
    if(value.length) {
      sendApplication({
        body: {
          service: item.id,
          token: token.id,
          payload: payload
        }
      })
        .unwrap()
        .then(() => {
          clickOnSocial({
              body: {
                service: item.id,
                token: token.id
              }
            }
          )
            .unwrap()
            .then(() => {
              refetch()
              setIsUserContact(false)
              setIsUserThanksModalActive(true)
              changeData({
                body: payload
              })
            })
        })
    }
  }

  return (
    <Modal
      title={'Получение услуги'}
      active={isUserContact}
      onClose={() => {
        setIsUserContact(false)
        setIsSendData(false)
        setIsSendData('')
      }}
      size={'medium'}
    >
      <p className={styles.text} style={{fontWeight: '400', margin: '8px 0 20px 0'}}>Пожалуйста, укажите Ваш контакт</p>
      <div
        className={`${isSendData && !value ? 'error' : ''}`}
        style={{width: '100%'}}
      >
        {contactType === 'whatsapp' ?
          <InputMask
            maskChar={null}
            mask="7\ 999 99 999 99"
            placeholder={'7 922 31 207 35'}
            className="input service__item--input"
            value={value}
            onChange={(e) => setValue( e.target.value)}
          /> :
          // <Input
          //   value={value}
          //   setValue={setValue}
          //   placeholder={contactType === 'telegram' ? 'Телеграм (введите телефон или логин)' : 'Ватсап (введите номер телефона)'}
          // />
          <TelegramMaskedInput
            value={value}
            onChange={(value) => {
              setValue(value)
            }}
          />
        }
      </div>

      <button
        className={`button token__card--content--price--buy`}
        style={{height: '52px', width: '100%', marginTop: '50px'}}
        onClick={handleSendApplication}
      >
        Готово
      </button>
    </Modal>

  )
}