import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS } from '../../../const/breakpoints/BREAKPOINTS';
import {
    Link,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useDeleteTokenMutation } from '../../../redux/api/dataService';
import { CancelModal } from '../../Сonclusions/modals/CancelModal';
import './style.css';
import { TokenModal } from './TokenModal';
import { ApproveCloseTransfer } from './ApproveCloseTransfer';
import { ApproveCancelModal } from './ApproveCancelModal';
import { useDynamicPagination } from '../../useDynamicPagination';
import { useGenerateRoutes, useGetTableView } from '../../useGetTableView';
import { expandListOfParameters } from '../../utils';
import { CircularProgress } from '@mui/material';
import { navigateTokenPage } from '../../../utils/navigateTokenPage';
moment.locale('ru');

const variants = {
    sold: 'продано',
    transfer: 'передается',
    posted: 'размещено',
};

const colorVariants = {
    sold: 'blue',
    transfer: '#4bef4b',
    posted: 'white',
};

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/token/statistics`;

const Tokens = () => {
    const myRole = useSelector((state) => state.auth.userRole);
    const { collectionId, packId, accountId, pageId } = useParams();
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const location = useLocation();
    const dispatch = useDispatch();
    const [currentElement, setCurrentElement] = useState(null);
    const [selectedField, setSelectedField] = useState([
        {
            type: 'email',
            state: false,
        },
        {
            type: 'phone',
            state: false,
        },
        {
            type: 'public_adress',
            state: false,
        },
    ]);
    const [isTokenTransfer, setIsTokenTransfer] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [clickedItemId, setClickedItemId] = useState();
    const [deleteToken] = useDeleteTokenMutation();
    const [isClose, setIsClose] = useState(false);
    const [isCancelTransfer, setIsCancelTransfer] = useState(false);
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
        useOutletContext();

    const transferList = useSelector(
        (state) => state.tokensStatisticSlice.tokenTransferList,
        shallowEqual,
    );

    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'tokens');

    useGenerateRoutes([
        { title: 'page', id: pageId },
        { title: 'account', id: accountId },
        { title: 'collection', id: collectionId },
        { title: 'pack', id: packId },
    ]);
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            { title: 'pack_id', value: packId },
            { title: 'collection_id', value: collectionId },
            { title: 'page_id', value: pageId },
        ]);
    }, [accountId, pageId, collectionId, pageId, requestParams]);
    const items = useDynamicPagination(TABLE_URL, params, isResetList, setIsResetList, setCount);

    const handleDeleteToken = (id) => {
        setClickedItemId(id);
        setIsDelete(true);
    };
    //  console.log(page)
    const handleDeleteTokenRequest = () => {
        if (clickedItemId) {
            deleteToken({
                id: clickedItemId,
            })
                .unwrap()
                .finally(() => {
                    setIsDelete(false);
                });
        }
    };

    const handleTransferToken = (el) => {
        setCurrentElement(el);
        setIsTokenTransfer(true);
    };
    const handleCloseModal = () => {
        setIsTokenTransfer(false);
    };
    const handleSetSelectedField = (title) => {
        setSelectedField((prev) =>
            prev.map((radio) => {
                if (radio.type === title) {
                    return { ...radio, state: !radio.state };
                }
                return { ...radio, state: false };
            }),
        );
    };

    const handleClearState = () => {
        setSelectedField([
            {
                type: 'email',
                state: false,
            },
            {
                type: 'phone',
                state: false,
            },
            {
                type: 'public_adress',
                state: false,
            },
        ]);
    };

    const handleCancelTransfer = (elem) => {
        setToken(elem);
        setIsCancelTransfer(true);
    };

    const handleCloseTransfer = () => {
        setIsCancelTransfer(false);
        setToken(null);
    };

    const handleNavigateEdit = (el) => {
        if (el && el.page && el.collection && el.pack && el.account) {
            navigate(
                `/admin/edit/token/${el.id}/${el.page}/${el.collection.id}/${el.pack.id}/${el.account.id}`,
            );
        }
    };

    const nameProvider = useCallback(
        (status, tokenId) => {
            // {el.status_store && variants[el.status_store]}
            const foundTokenTransfer = transferList.find((id) => id === tokenId);
            if (variants[status] && foundTokenTransfer && variants[status] !== 'transfer') {
                return (
                    <span>
                        <CircularProgress
                            size={'small'}
                            sx={{ width: '25px', height: '25px', color: '#4bef4b' }}
                        />{' '}
                        Передача...
                    </span>
                );
            }

            if (variants[status] && !foundTokenTransfer) {
                return variants[status];
            }

            return status;
        },
        [transferList],
    );

    return (
        <>
            {isCancelTransfer && (
                <ApproveCancelModal
                    element={token}
                    handleCloseModal={() => handleCloseTransfer()}
                />
            )}

            {isClose && !isTokenTransfer && <ApproveCloseTransfer setIsClose={setIsClose} />}
            {isTokenTransfer && (
                <TokenModal
                    selectedField={selectedField}
                    handleSetSelectedField={handleSetSelectedField}
                    element={currentElement}
                    handleCloseModal={() => handleCloseModal()}
                    clearSelectedFields={handleClearState}
                    setIsSuccess={setIsClose}
                />
            )}

            <TableRow names differentColumn>
                <TableCell title largeColumn>
                    Статус
                </TableCell>

                <TableCell title largeColumn>
                    Токен
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD620}>
                    Стоимость
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    Описание
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    Свойства
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    Владелец
                </TableCell>
                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1400}>
                    Дата
                </TableCell>
            </TableRow>

            {items &&
                items.items &&
                items.items.map((el, i) => {
                    const status = nameProvider(el.status_store, el.id);
                    return (
                        <TableRow
                            differentColumn
                            actionType="statTokenAdmin"
                            key={i}
                            handlerDeleteClick={() => handleDeleteToken(el.id)}
                            handleTransferToken={() => handleTransferToken(el)}
                            handleCancelTransfer={() => {
                                handleCancelTransfer(el);
                            }}
                            handlerEditClick={() => {
                                handleNavigateEdit(el);
                            }}
                        >
                            <TableCell largeColumn>
                                <p className="whithdraw__index">{i + 1}</p>

                                <div
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        borderRadius: '999px',
                                        background: colorVariants[el.status_store],
                                    }}
                                />

                                <div className="stats__item--wrapper withdraw__item">
                                    <p
                                        style={{
                                            color: el.status_store === 'transfer' && '#4bef4b',
                                        }}
                                        className={`withdraw__item--collection--name ${
                                            el.paid
                                                ? 'stats__item--text--blue'
                                                : 'stats__item--text--white'
                                        }`}
                                    >
                                        {status}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell largeColumn>
                                <div className="stats__item--avatar--inner withdraw__item">
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.file_1}`}
                                        alt={'img'}
                                        style={{
                                            objectFit: 'cover',
                                            width: '48px',
                                            height: '48px',
                                        }}
                                    />
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <Link
                                        to={`/token/${el.id}`}
                                        className="withdraw__item--collection--name"
                                    >
                                        {el && el.collection && el.collection.name}
                                        <br />
                                        <b>{el.name}</b>
                                    </Link>
                                </div>
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                                {Math.floor(el.price / 100).toLocaleString('ru-RU')} RUB
                            </TableCell>

                            <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                                {el.description}
                            </TableCell>

                            <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                                {el.properties
                                    ? el.properties.map((item) => {
                                          return (
                                              <>
                                                  {item.name}
                                                  <br />
                                              </>
                                          );
                                      })
                                    : '-'}
                            </TableCell>

                            <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                                <p style={{ maxWidth: '180px', wordBreak: 'break-word' }}>
                                    {el.email ? el.email : '---'}
                                </p>
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD1400}>
                                {moment(el.created_at).utc().calendar('l')}
                            </TableCell>
                        </TableRow>
                    );
                })}
            <CancelModal
                handleOk={() => {
                    handleDeleteTokenRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            />
        </>
    );
};

export default Tokens;
