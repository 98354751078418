import React, { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cnb } from 'cnbuilder';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import { ConfirmEmailModal } from '../../components/ConfirmEmailModal';
import './index.css';
import PrivatePageWrapper from '../../components/PrivatePageWrapper';
import ModalChange from '../../common/ModalChange';
import { initUsername, initImage, setIsWelcomeModalActive } from '../../redux/slices/auth';
import { getShortAddress } from '../../functions/auth';
import { changeNick, changeImage } from '../../functions/user';
import { copyText } from '../../functions/utils';
import { copyUrl } from '../../functions/utils';
import { useGetMyTokensQuery } from '../../redux/api/dataService';
import TokenItem from '../Collection/UI/TokenItem';
import css from './Profile.module.css';
import ProfileSkeleton from './ProfileSkeleton';
import CollectionInfo from '../../common/CollectionInfo';
import NoItems from '../../common/NoItems';
import { EditModal } from './modals/EditModal';

const viewOptions = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
};

const Profile = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const { instagram, whatsapp: whatsappState, telegram: telegramState, email: emailState } = auth;

    const [modal, setModal] = React.useState(false);
    const [nick, setNick] = React.useState('');
    const [imageLoad, setImageLoad] = React.useState(false);
    const [social, setSocial] = React.useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isConfirmEmail, setIsConfirmEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [telegram, setTelegram] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [inst, setInst] = useState('');
    const [phone, setPhone] = useState('');
    const [headerOffsetTop, setHeaderOffsetTop] = useState(0);
    const [view, setView] = useState(viewOptions.DESKTOP);

    const filtersTopRef = useRef(null);
    const filtersBottomRef = useRef(null);
    const containerRef = useRef(null);

    const [usersTokens, setUsersTokens] = useState([]);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const { data, isLoading, refetch } = useGetMyTokensQuery(
        {
            page: 1,
            pageSize: 1000,
        },
        {
            skip: false,
        },
    );

    const copyUrlPage = () => {
        copyUrl();
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Ссылка скопирована!',
        });
    };

    const changeNickname = () => {
        const res = changeNick(auth.accessToken, nick);

        res.then((data) => {
            dispatch(initUsername(data.data.username));
        }).catch((e) => {
            console.log(e);
        });
    };

    const changeImageProfile = (e) => {
        setImageLoad(true);
        let formData = new FormData();
        formData.append('image', e.target.files[0]);

        const res = changeImage(auth.accessToken, formData);

        res.then((data) => {
            dispatch(initImage(data.data.image_url));
        })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setImageLoad(false);
            });
    };

    const copyAddress = () => {
        copyText(auth.walletAddress);
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
        setSocial(false);
    };

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const div = document.getElementById('social__modal');
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setSocial(false);
            }
        });
    }, []);

    useEffect(() => {
        if (data && data.results) {
            setUsersTokens(data.results);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (filtersTopRef.current && filtersBottomRef.current) {
            const top = filtersTopRef.current.getBoundingClientRect().top;
            const bottom = filtersBottomRef.current.getBoundingClientRect().bottom;

            setHeaderOffsetTop(bottom - top);
        }
        // необходимо добавлять переменные в зависимости, если они меняются отступ итемов от верха страницы
    }, [data, isLoading]);

    if (isLoading) {
        return <ProfileSkeleton />;
    }

    console.log(usersTokens);

    return (
        <PrivatePageWrapper>
            <section className="profile">
                <div className="container">
                    <div className="profile__inner">
                        <div className="profile__buttons">
                            {telegram && (
                                <div
                                    className="profile__share"
                                    onClick={() => {
                                        copyText(telegram);
                                        addNotification({
                                            type: NOTIFICATION_TYPES.SUCCESS,
                                            text: 'Telegram скопирован',
                                        });
                                    }}
                                >
                                    <img
                                        src="/assets/img/telegram.svg"
                                        alt="share"
                                        className="profile__share--icon share"
                                    />
                                </div>
                            )}
                            {whatsapp && (
                                <div
                                    className="profile__share"
                                    onClick={() => {
                                        copyText(whatsapp);
                                        addNotification({
                                            type: NOTIFICATION_TYPES.SUCCESS,
                                            text: 'Whatsapp скопирован',
                                        });
                                    }}
                                >
                                    <img
                                        src="/assets/img/whatsapp.svg"
                                        alt="share"
                                        className="profile__share--icon share"
                                    />
                                </div>
                            )}
                            {instagram && (
                                <div
                                    className="profile__share inst"
                                    onClick={() => {
                                        copyText(instagram);
                                        addNotification({
                                            type: NOTIFICATION_TYPES.SUCCESS,
                                            text: 'Instagram скопирован',
                                        });
                                    }}
                                >
                                    <img
                                        src="/assets/img/Instagram2.svg"
                                        alt="share"
                                        className="profile__share--icon share"
                                    />
                                </div>
                            )}
                            {email && (
                                <div
                                    className="profile__share email"
                                    onClick={() => {
                                        copyText(email);
                                        addNotification({
                                            type: NOTIFICATION_TYPES.SUCCESS,
                                            text: 'Email скопирован',
                                        });
                                    }}
                                >
                                    <img
                                        src="/assets/img/email2.svg"
                                        alt="share"
                                        className="profile__share--icon share"
                                    />
                                </div>
                            )}

                            <div className="profile__share" onClick={copyUrlPage}>
                                <img
                                    src="/assets/img/share2.svg"
                                    alt="share"
                                    className="profile__share--icon share"
                                />
                            </div>

                            <div className="profile__share" id="social__modal">
                                <img
                                    src="/assets/img/dots.svg"
                                    alt="dots"
                                    className="profile__share--icon"
                                    onClick={() => setSocial((prev) => !prev)}
                                />

                                {social && (
                                    <div className="collection__dots--social">
                                        <p className="collection__dots--title">Контакты</p>
                                        {email && (
                                            <div
                                                className="collection__dots--link--copy email"
                                                onClick={() => {
                                                    copyText(email);
                                                    addNotification({
                                                        type: NOTIFICATION_TYPES.SUCCESS,
                                                        text: 'Email скопирован',
                                                    });
                                                }}
                                            >
                                                <div className="address__wrap">
                                                    <img
                                                        src="/assets/img/email2.svg"
                                                        alt="email2"
                                                        className="address__eth"
                                                    />
                                                    Эл. почта
                                                </div>
                                            </div>
                                        )}

                                        {instagram && (
                                            <div
                                                className="collection__dots--link--copy inst"
                                                onClick={() => {
                                                    copyText(instagram);
                                                    addNotification({
                                                        type: NOTIFICATION_TYPES.SUCCESS,
                                                        text: 'Instagram скопирован',
                                                    });
                                                }}
                                            >
                                                <div className="address__wrap">
                                                    <img
                                                        src="/assets/img/Instagram2.svg"
                                                        alt="Instagram2"
                                                        className="address__eth"
                                                    />
                                                    Нельзяграм
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className="collection__dots--link--copy"
                                            onClick={() => dispatch(setIsWelcomeModalActive(true))}
                                        >
                                            <div className="address__wrap">
                                                <img
                                                    src="/assets/img/edit3.svg"
                                                    alt="edit"
                                                    className="address__eth"
                                                />
                                                Редактировать
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="profile__ava--inner">
                            <input
                                type="file"
                                id="profile__ava"
                                className="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={changeImageProfile}
                            />

                            {imageLoad ? (
                                <div className="profile__ava--img--inner">Загрузка...</div>
                            ) : (
                                <label htmlFor="profile__ava" className="profile__ava--img--inner">
                                    <img
                                        src={auth.image ? auth.image : `/assets/img/avatar.png`}
                                        alt="avatar"
                                        className="profile__ava--img"
                                    />
                                </label>
                            )}
                        </div>

                        <div className="profile__name--inner">
                            <p className="profile__name">{auth.username}</p>

                            <button
                                className="button profile__settings"
                                onClick={() => setModal(true)}
                            >
                                <img
                                    src="/assets/img/pen.svg"
                                    alt="pen"
                                    className="profile__settings--icon"
                                />
                            </button>
                        </div>

                        <div className="profile__wrap">
                            <div className="profile__wallet" onClick={copyAddress}>
                                <img src="/assets/img/eth.svg" alt="eth" />

                                {getShortAddress(auth.walletAddress)}
                            </div>
                            <div className={css.linkWrapper}>
                                <p className="profile__date">Присоединился {auth.created}</p>
                            </div>
                        </div>
                        <div className="collection__wrapper">
                            <div ref={filtersTopRef} />
                            <div className="collection__filter--content"></div>

                            <div className="collection__content">
                                <div className="collection__content--preitems">
                                    <CollectionInfo
                                        refreshCallback={() => {}}
                                        tokensCount={`${data ? data.results.length : 0} шт.`}
                                    />

                                    <div className={css.collection_items_list}>
                                        {!isLoading && (
                                            <div className={css.items_wrapper}>
                                                {usersTokens && usersTokens.length > 0 ? (
                                                    <div
                                                        ref={containerRef}
                                                        className={cnb(css.items_container, {
                                                            [css.items_container_mobile]:
                                                                view === viewOptions.MOBILE,
                                                        })}
                                                    >
                                                        {usersTokens.map((t) => (
                                                            <TokenItem
                                                                key={t.id}
                                                                token={t}
                                                                bookTokenInProccessing={() => {}}
                                                                onBookTokenHandler={() => {}}
                                                                isAvailableForBooking={false}
                                                                canBeMinting={false}
                                                                isFullImagePathProvided
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <NoItems />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ModalChange
                active={modal}
                setActive={setModal}
                placeholder="Введите новое имя"
                value={nick}
                setValue={setNick}
                callback={changeNickname}
            />
            <EditModal
                phone={phone}
                setPhone={setPhone}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                setIsConfirmEmail={setIsConfirmEmail}
                email={email}
                setEmail={setEmail}
                telegram={telegram}
                setTelegram={setTelegram}
                whatsapp={whatsapp}
                setWhatsapp={setWhatsapp}
                inst={inst}
                setInst={setInst}
            />
            {isConfirmEmail && (
                <ConfirmEmailModal
                    isConfirmedEmail={isConfirmEmail}
                    setIsConfirmedEmail={setIsConfirmEmail}
                    email={emailState}
                    inst={inst}
                    whatsapp={whatsapp}
                    telegram={telegram}
                />
            )}
        </PrivatePageWrapper>
    );
};

export default Profile;
