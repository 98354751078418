import React, { useState } from 'react';
import Dialog from '../../Dialog';

import css from '../ImageWithModal.module.css';

const ImageModalDialog = (props) => {
    const { open, onClose, src, alt } = props;

    return (
        <Dialog
            visible={open}
            onClose={onClose}
            disabelDefaultStyles
            classes={{
                paper: css.modalPaper,
                root: css.modalRoot,
                dialogInner: css.modalDialogInner,
            }}
        >
            <img alt={alt} src={src} className={css.modalImage} />

            <img src="/assets/img/cross.svg" alt="close" className={css.modalImageClose} onClick={onClose} />
        </Dialog>
    );
};

export default React.memo(ImageModalDialog);
