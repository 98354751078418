import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const Tooltip = ({ text, placement = 'bottom', adaptive = false, wrapperStyles, children }) => {
    return (
        <div className={styles.tooltipInner}>
            {children}

            <div
                className={cn(styles.tooltipContentWrapper, styles[placement], {
                    [styles.adaptive]: adaptive,
                })}
                style={wrapperStyles}
            >
                <div className={styles.tooltipContent}>{text}</div>
            </div>
        </div>
    );
};

export default Tooltip;
