import React, { useCallback, useState,useEffect } from 'react';
import { InputNew } from '../../../../common/InputNew';
import { DescriptionBlock } from '../../../../components/DescriptionBlock';
import styles from '../../index.module.css';
import cn from 'classnames';
import ModalNew from '../../../../common/ModalNew';
import base from '../../../../styles/base.module.css';
import { handleFetchCodeByType } from '../../utils/utils';
import { handleUpdateUserContacts } from '../../utils/utils';
import { handleFetchSMSCode } from '../../utils/utils';

export const VerifyContacts = ({ userData }) => {
    const [contactModal, setContactModal] = React.useState(true);
    const [verifyStep, setVerifyStep] = useState(0);
    const [code, setCode] = useState({
        email: '',
        phone: '',
    });
    const [contacts, setContacts] = useState({ email: '', phone: '' });
    const [error, setError] = useState('');

    const handleNextStep = useCallback(() => {
        if (verifyStep === 0) {
            if (contacts.email.length > 0 && contacts.phone.length > 0) {
                handleUpdateUserContacts(
                    setVerifyStep,
                    contacts.email,
                    contacts.phone,
                    1,
                    'all',
                    setError,
                );
            }
            if (contacts.email.length > 0 && contacts.phone.length === 0) {
                handleUpdateUserContacts(
                    setVerifyStep,
                    contacts.email,
                    contacts.phone,
                    1,
                    'email',
                    setError,
                );
            }
            if (contacts.email.length === 0 && contacts.phone.length > 0) {
                handleUpdateUserContacts(
                    setVerifyStep,
                    contacts.email,
                    contacts.phone,
                    2,
                    'all',
                    setError,
                );
            }
        }
        if (verifyStep === 1) {
            handleFetchCodeByType(
                'email',
                code.email,
                setVerifyStep,
                contacts.phone.length > 0 ? 2 : 3,
                setError,

            );

            // handleFetchSMSCode(code.phone, setError);
        }
        if (verifyStep === 2) {
            handleFetchCodeByType('phone', code.phone, setVerifyStep, 3, setError, null);
        }
    }, [verifyStep, contacts, code]);

    const handleSetContactsByType = (type, value) => {
        setContacts((prev) => ({ ...prev, [type]: value }));
    };
    const handleSetCode = (type, value) => {
        setCode((prev) => ({ ...prev, [type]: value }));
    };
    useEffect(() => {
        if (verifyStep === 2) {
            handleFetchSMSCode(contacts.phone, setError);
        }
    }, [contacts.phone, verifyStep]);

    return (
        <ModalNew active={contactModal} setActive={setContactModal} title="Контакты">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                {verifyStep === 0 && (
                    <div className={styles.contactForm}>
                        <div className={styles.contactFormTop}>
                            <p className={styles.contactFormTopTitle}>
                                CheckBrand приветствует Вас!
                            </p>

                            <p className={styles.contactFormTopText}>
                                Пожалуйста, укажите свои контакты
                            </p>
                        </div>

                        <div className={styles.editProfileFormWrapper}>
                            <InputNew
                                title="Эл. почта*"
                                placeholder="Введите ваш email"
                                setValue={(value) => {
                                    console.log(value);
                                    handleSetContactsByType('email', value);
                                }}
                                value={contacts.email}
                            />
                            <InputNew
                                title="Номер телефона*"
                                placeholder="Введите ваш номер телефона"
                                setValue={(value) => handleSetContactsByType('phone', value)}
                                value={contacts.phone}
                            />

                            <p className={styles.inputRequired}>*Обязательное поле</p>
                        </div>

                        <button
                            className={cn(base.orangeButton, styles.editProfileFormButton)}
                            onClick={() => {
                                handleNextStep();
                            }}
                        >
                            Продолжить
                        </button>

                        <DescriptionBlock>
                            <p>Приветствуем Вас на платформе токенизации брендовых вещей!</p>

                            <p>
                                Пожалуйста, верифицируйте свою электронную почту и номер телефона.
                            </p>

                            <p>
                                Это важно для безопасного получения, отправки и приобретения
                                токенов.
                            </p>

                            <p>
                                Ваша безопасность и удобство — наш приоритет. Давайте сделаем Ваш
                                опыт приятным и комфортным!
                            </p>
                        </DescriptionBlock>
                    </div>
                )}
                {verifyStep === 1 && (
                    <>
                        <p className={styles.verifyModalTitle}>
                            Пожалуйста, подтвердите вашу эл. почту
                        </p>

                        <div className={styles.verifyModalWrapper}>
                            <InputNew
                                // title="Эл.почта: name2223@mail.ru"
                                placeholder="Введите код"
                                center
                                value={code.email}
                                setValue={(value) => {
                                    handleSetCode('email', value);
                                }}
                                // isCode
                                codeLength={6}
                            />

                            <button
                                className={cn(base.orangeButton, styles.editProfileFormButton)}
                                onClick={() => {
                                    handleNextStep();
                                }}
                            >
                                Продолжить
                            </button>
                            <p className={styles.inputRequired}>
                                *Новый код возможно получить через 45 секунд
                            </p>
                            <button className={styles.verifyModalResend}>
                                Отправить код повторно
                            </button>
                        </div>
                    </>
                )}
                {verifyStep === 2 && (
                    <>
                        <p className={styles.verifyModalTitle}>
                            Пожалуйста, подтвердите ваш телефон
                        </p>

                        <div className={styles.verifyModalWrapper}>
                            <InputNew
                                // title="Эл.почта: name2223@mail.ru"
                                placeholder="Введите код"
                                center
                                value={code.phone}
                                setValue={(value) => {
                                    handleSetCode('phone', value);
                                }}
                                // isCode
                                codeLength={6}
                            />

                            <button
                                className={cn(base.orangeButton, styles.editProfileFormButton)}
                                onClick={() => {
                                    handleNextStep();
                                }}
                            >
                                Продолжить
                            </button>
                            <DescriptionBlock>
                                <p style={{ maxWidth: '300px' }}>
                                    1.Сейчас на ваш телефон поступит звонок.
                                </p>
                                <p style={{ maxWidth: '300px' }}>
                                    2.Прослушайте 4-х значный код или введите 4 последние цифры
                                    номера, с которого поступил звонок.
                                </p>
                                <p style={{ maxWidth: '300px' }}>3.Нажмите кнопку Продолжить.</p>
                                <p style={{ maxWidth: '300px' }}>
                                    4.Если в течении минуты звонок не поступит, запросите отправку
                                    повторно.
                                </p>
                            </DescriptionBlock>
                            <p className={styles.inputRequired}>
                                *Новый код возможно получить через 45 секунд
                            </p>
                            <button className={styles.verifyModalResend}>
                                Отправить код повторно
                            </button>
                        </div>
                    </>
                )}
                {verifyStep === 3 && (
                    <div className={styles.verifyScreenSuccess}>
                        <svg
                            width="66"
                            height="66"
                            viewBox="0 0 66 66"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="33" cy="33" r="31.75" stroke="#207CE2" strokeWidth="2.5" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M43.7844 24.7098C44.352 25.2764 44.3525 26.1955 43.7854 26.7627L29.2571 41.2934C28.9847 41.5659 28.6151 41.719 28.2297 41.7191C27.8442 41.7192 27.4745 41.5663 27.202 41.2939L22.8435 36.9391C22.2761 36.3722 22.2761 35.4531 22.8435 34.8862C23.4109 34.3193 24.3307 34.3193 24.8981 34.8862L28.2288 38.2141L41.7298 24.7108C42.2969 24.1437 43.2167 24.1432 43.7844 24.7098Z"
                                fill="#207CE2"
                            />
                        </svg>

                        <p className={styles.verifyModalSuccessTitle}>верификация прошла успешно</p>

                        <button
                            className={cn(base.orangeButton, styles.editProfileFormButton)}
                            // onClick={() => setVerifyProfileModal(false)}
                        >
                            Хорошо
                        </button>
                    </div>
                )}
            </div>
        </ModalNew>
    );
};

{
    /* <ModalNew2
value={verifyProfileModal}
setValue={setVerifyProfileModal}
isSteps={verifyStep !== 3}
step={verifyStep}
setSteps={setVerifyStep}
title={
    verifyStep === 3
        ? 'Верификация пройдена'
        : `Верификация. Шаг ${verifyStep} из 2`
}
>
<div className={styles.verifyModalContent}>
    {verifyStep === 1 && (
        <>
            <p className={styles.verifyModalTitle}>
                Пожалуйста, подтвердите вашу эл. почту
            </p>

            <div className={styles.verifyModalWrapper}>
                <InputNew
                    title="Эл.почта: name2223@mail.ru"
                    placeholder="Введите код"
                    center
                    value={code}
                    setValue={setCode}
                    isCode
                    codeLength={6}
                />

                <p className={styles.inputRequired}>
                    *Новый код возможно получить через 45 секунд
                </p>

                <button className={styles.verifyModalResend}>
                    Отправить код повторно
                </button>
            </div>
        </>
    )}

    {verifyStep === 2 && (
        <>
            <p className={styles.verifyModalTitle}>
                Пожалуйста, подтвердите свой телефон
            </p>

            <div className={styles.verifyModalWrapper}>
                <InputNew
                    title="Телефон: +7 928 774 23 55"
                    placeholder="Введите код"
                    error
                    errorMessage="Неверный код"
                    center
                />

                <p className={styles.inputRequired}>
                    *Новый код возможно получить через 45 секунд
                </p>

                <button className={styles.verifyModalResend}>
                    Отправить код повторно
                </button>
            </div>
        </>
    )}

    {verifyStep <= 2 && (
        <button
            className={cn(base.orangeButton, styles.editProfileFormButton)}
            onClick={() => setVerifyStep((prev) => prev + 1)}
        >
            Продолжить
        </button>
    )}

    {verifyStep === 1 && (
        <DescriptionBlock>
            <ol>
                <li>На вашу почту отправлен 6-и значный код.</li>
                <li>Скопируйте его и вставьте в поле ввода.</li>
                <li>Нажмите кнопку Продолжить.</li>
            </ol>

            <p>
                Если в течении минуты код не поступит, запросите отправку повторно.
            </p>
        </DescriptionBlock>
    )}

    {verifyStep === 2 && (
        <DescriptionBlock>
            <ol>
                <li>Сейчас на ваш телефон поступит звонок.</li>
                <li>
                    Прослушайте 4-х значный код или введите 4 последние цифры
                    номера, с которого поступил звонок.
                </li>
                <li>Нажмите кнопку Продолжить.</li>
            </ol>

            <p>
                Если в течении минуты звонок не поступит, запросите отправку
                повторно.
            </p>
        </DescriptionBlock>
    )}

    {verifyStep === 3 && (
        <div className={styles.verifyScreenSuccess}>
            <svg
                width="66"
                height="66"
                viewBox="0 0 66 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="33"
                    cy="33"
                    r="31.75"
                    stroke="#207CE2"
                    strokeWidth="2.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.7844 24.7098C44.352 25.2764 44.3525 26.1955 43.7854 26.7627L29.2571 41.2934C28.9847 41.5659 28.6151 41.719 28.2297 41.7191C27.8442 41.7192 27.4745 41.5663 27.202 41.2939L22.8435 36.9391C22.2761 36.3722 22.2761 35.4531 22.8435 34.8862C23.4109 34.3193 24.3307 34.3193 24.8981 34.8862L28.2288 38.2141L41.7298 24.7108C42.2969 24.1437 43.2167 24.1432 43.7844 24.7098Z"
                    fill="#207CE2"
                />
            </svg>

            <p className={styles.verifyModalSuccessTitle}>
                верификация прошла успешно
            </p>

            <button
                className={cn(base.orangeButton, styles.editProfileFormButton)}
                onClick={() => setVerifyProfileModal(false)}
            >
                Хорошо
            </button>
        </div>
    )}
</div>
</ModalNew2> */
}
