import { createSlice } from '@reduxjs/toolkit';

const INITAL_STATE = {
    isOpen: false,
    onDelete: () => {},
    title: '',
    deleteBtnText: '',
};

export const deleteEntityDialogSlice = createSlice({
    name: 'deleteEntityDialog',
    initialState: INITAL_STATE,
    reducers: {
        onOpen: (state, action) => {
            state.isOpen = true;
            state.id = action.payload.id;
            state.onDelete = action.payload.onDelete;
            state.title = action.payload.title;
            state.deleteBtnText = action.payload.deleteBtnText || '';
        },
        onClose: () => INITAL_STATE,
    },
});

export const { onOpen, onClose } = deleteEntityDialogSlice.actions;

export default deleteEntityDialogSlice.reducer;
