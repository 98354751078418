import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '../../components/Header';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { useGetAccountsListFilteredQuery } from '../../redux/api/handleService';
import PrivatePageWrapper from '../../components/PrivatePageWrapper';
import AccountsSearchResults from '../../components/AccountsSearchResults';

const Wrapper = (props) => {
    const { isAdminPage } = props;

    const location = useLocation();

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebouncedValue(searchValue, 500);

    const onSearchValueChange = useCallback((e) => {
        setSearchValue(e ? e.target.value : "");
    }, []);

    useEffect(() => {
        setSearchValue('');
    }, [location]);
   

    return (
        <>
            {isAdminPage ? (
                <PrivatePageWrapper>
                    <img src="/assets/img/main-bg1.svg" alt="bg" className="bg1" />
                    <img src="/assets/img/main-bg2.svg" alt="bg" className="bg2" />

                    <Header
                        searchValue={searchValue}
                        onSearchValueChange={onSearchValueChange}
                        isAdminPage
                    />
                    {!debouncedSearchValue ? (
                        <Outlet />
                    ) : (
                        <AccountsSearchResults query={debouncedSearchValue} />
                    )}
                </PrivatePageWrapper>
            ) : (
                <>
                    <img src="/assets/img/main-bg1.svg" alt="bg" className="bg1" />
                    <img src="/assets/img/main-bg2.svg" alt="bg" className="bg2" />

                    <Header searchValue={searchValue} onSearchValueChange={onSearchValueChange} />

                    {!debouncedSearchValue ? (
                        <Outlet />
                    ) : (
                        <AccountsSearchResults query={debouncedSearchValue} />
                    )}
                </>
            )}
        </>
    );
};

export default React.memo(Wrapper);
