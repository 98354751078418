import React, { useState, memo } from 'react';
import Rocket from '../../../../../icons/Rocket';
import Option from '../../../../../icons/Option';
import RangeItem from '../../RangeItem';
import Question2 from '../../../../../icons/Question2';
import styles from '../../../index.module.css';
import Check2 from '../../../../../icons/Check2';
import cn from 'classnames';
import base from '../../../../../styles/base.module.css';
import Diamond from '../../../../../icons/Diamond';
// import Case from '../assets/Case.svg'
import { CaseIcon } from '../CaseIcon';
import Fire from '../../../../../icons/Fire';
import { Padding } from '@mui/icons-material';
const countTarif = {
    start: { min: 100, max: 999 },
    buisness: { min: 1000, max: 5999 },
    premium: { min: 6000, max: 30000 },
};

const initialPrice = {
    start: { price: 9900 },
    buisness: {
        price: 89000,
    },
    premium: {
        price: 414900,
    },
};
const tarifIcons = {
    start: <Rocket />,
    buisness: <CaseIcon />,
    premium: <Diamond />,
};

export const Tarif = memo(({ tarif, setSelectedTariff, handleOpenModal }) => {
    const [stateTarif, setStateTarif] = useState({
        start: { totalPrice: 9900, count: 100 },
        buisness: {
            totalPrice: 89000,
            count: 1000,
        },
        premium: {
            totalPrice: 414900,
            count: 6000,
        },
    });
    const handleSetTarifValue = (value, type) => {
        setStateTarif((prev) => ({
            ...prev,
            [type]: value,
        }));
    };

    const handleSelectTariff = (tarif) => {
        const selectedTarif = stateTarif[tarif.type];
        if (selectedTarif) {
            setSelectedTariff({
                name: tarif.name,
                count: selectedTarif.count,
                price: selectedTarif.totalPrice,
                type:tarif.type
            });
            handleOpenModal(true);
        }
    };
    return (
        <div className={styles.tarifItemWrapper}>
            <div className={styles.tarifItem}>
                {tarif.type === 'premium' && (
                    <span
                        style={{
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            padding: '10px',
                            display:'flex',
                            justifyContent:'flex-end'

                        }}
                    >
                        <Fire
                            style={{
                                width: '31px',
                                height: '31px',
                                background: '#fed451',
                                borderRadius: '50%',
                                padding: '5px',
                            }}
                        />
                    </span>
                )}
                <div className={styles.tarifItemOuter}>
                    <div className={styles.tarifItemIconInner}>
                        <div className={styles.tarifItemIcon}>{tarifIcons[tarif.type]}</div>
                    </div>

                    <p className={styles.tarifItemName}>{tarif.name}</p>

                    <p className={styles.tarifItemPrice}>от {tarif.price} ₽</p>

                    <div className={styles.tarifItemButtons}>
                        <button
                            className={styles.tarifItemButtonChoose}
                            onClick={() => {
                                setSelectedTariff({
                                    name: tarif.name,
                                    count: stateTarif[tarif.type].count,
                                    price: stateTarif[tarif.type].totalPrice,
                                });
                            }}
                        >
                            Выбрать {tarif.name}
                        </button>

                        <div className={styles.tarifItemButtonInInner}>
                            <button className={styles.tarifItemButtonIn}>
                                Выпуск {stateTarif[tarif.type].count} шт.
                                <Option />
                            </button>

                            <div className={styles.tarifSettingsWrapper}>
                                <div className={styles.tarifSettings}>
                                    <RangeItem
                                        min={countTarif[tarif.type].min}
                                        max={countTarif[tarif.type].max}
                                        state={stateTarif[tarif.type]}
                                        setState={(e) => handleSetTarifValue(e, tarif.type)}
                                        tariffs={tarif.tarifProps}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.tarifItemPoints}>
                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Разработка дизайнов для сертификатов
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Создание уникальных изображений и описаний продуктов бренда
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={cn(styles.tarifItemPoint, styles.line)}>
                            <span>{tarif.staticVariants}</span> статических
                            {tarif.animatedVariants &&
                                ` и ${tarif.animatedVariants} статических вариантов`}
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Оформление дизайна коллекций бренда
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Разработка фирменного дизайна бренда, включая баннеры,
                                        логотипы и текстовые описания
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>
                            <span>{tarif.decorationCollection}</span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Разработка оформления продукции
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Интеграция QR-кода в дизайн одежды бренда
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>
                            <span>{tarif.decorationProduct}</span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Дизайн карточки бренда с QR-кодом
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Создание карточки бренда с QR-кодом и инструкцией по
                                        использованию
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>
                            <span className={styles.tarifItemCheck}>
                                {tarif.cardDesign ? <Check2 /> : '-'}
                            </span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Фирменная эмблема с размещением QR-кода
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Разработка фирменной эмблемы для скрытого размещения на
                                        изделиях
                                    </div>
                                </div>
                            </div>
                        </p>
                        <p className={styles.tarifItemPoint}>
                            <span className={tarif.selfIcon && styles.tarifItemCheck}>
                                {tarif.selfIcon ? <Check2 /> : '-'}
                            </span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Передача прав на созданные произведения
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Оформление передачи прав на созданные дизайнерские работы
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>
                            <span className={tarif.transferRight && styles.tarifItemCheck}>
                                {tarif.transferRight ? <Check2 /> : '-'}
                            </span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Стратегия продажи с просчетом бюджета
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Наши маркетологи и аналитики проведут исследование рынка,
                                        разработают эффективный маркетинговый план и будут
                                        сопровождать его реализацию
                                    </div>
                                </div>
                            </div>
                        </p>
                        <p className={styles.tarifItemPoint}>
                            <span className={tarif.sellStrategy && styles.tarifItemCheck}>
                                {tarif.sellStrategy ? <Check2 /> : '-'}
                            </span>
                        </p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Подключение партнеров к сертификату
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Мы предоставим Вам список потенциальных партнеров, товары и
                                        услуги которых могут быть включены в сертификаты, чтобы Вы
                                        могли выбрать наиболее подходящие варианты
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>{tarif.conectPartners}</p>
                    </div>

                    <div className={styles.tarifItemPointWrapper}>
                        <p className={styles.tarifName}>
                            Сопровождение бизнеса
                            <div className={styles.tooltipInner}>
                                <Question2 />

                                <div className={styles.tooltipContentWrapper}>
                                    <div className={styles.tooltipContent}>
                                        Наша техническая поддержка поможет вашим клиентам с
                                        передачей и использованием сертификатов, а также ответит на
                                        любые возникающие вопросы
                                    </div>
                                </div>
                            </div>
                        </p>

                        <p className={styles.tarifItemPoint}>
                            <span>{tarif.mounthCount}</span> месяц
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.tarifItemBottom}>
                <p className={styles.tarifItemBottomPrice}>
                    {stateTarif[tarif.type] && (
                        <>
                            {stateTarif[tarif.type].totalPrice === initialPrice[tarif.type].price
                                ? 'от'
                                : ''}
                            <span> {stateTarif[tarif.type].totalPrice} </span> ₽
                        </>
                    )}
                </p>
                <button
                    className={cn(base.orangeButton, styles.tarifItemButtonBottom)}
                    onClick={() => handleSelectTariff(tarif)}
                >
                    Выбрать {tarif.name}
                </button>
            </div>
        </div>
    );
});
