import React from 'react';

import css from './index.module.css';

const InfoItem = ({title, value}) => {
    return (
        <div className={css.collectionDataItem}>
            <h3 className={css.collectionDataTitle}>
                {value}
            </h3>

            <p className={css.collectionDataText}>{title}</p>
        </div>
    )
}

export default InfoItem;