import React from 'react';

const Document = ({ ...props }) => {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.94886 0H9.05088C9.52875 0 9.97858 0.187778 10.3168 0.529028L12.6006 2.83292C12.9389 3.17356 13.125 3.62736 13.125 4.11005V4.21296H9.54545C9.21673 4.21296 8.94886 3.94273 8.94886 3.61111V0ZM13.125 5.41667V15.0463C13.125 16.0418 12.322 16.8519 11.3352 16.8519H1.78977C0.803011 16.8519 0 16.0418 0 15.0463V1.80556C0 0.810093 0.803011 0 1.78977 0H7.75568V3.61111C7.75568 4.60657 8.55869 5.41667 9.54545 5.41667H13.125ZM2.38636 6.01852C2.38636 6.35074 2.65364 6.62037 2.98295 6.62037H6.5625C6.89182 6.62037 7.15909 6.35074 7.15909 6.01852C7.15909 5.6863 6.89182 5.41667 6.5625 5.41667H2.98295C2.65364 5.41667 2.38636 5.6863 2.38636 6.01852ZM10.7386 13.2407C10.7386 12.9085 10.4714 12.6389 10.142 12.6389H2.98295C2.65364 12.6389 2.38636 12.9085 2.38636 13.2407C2.38636 13.573 2.65364 13.8426 2.98295 13.8426H10.142C10.4714 13.8426 10.7386 13.573 10.7386 13.2407ZM10.7386 10.8333C10.7386 10.5011 10.4714 10.2315 10.142 10.2315H2.98295C2.65364 10.2315 2.38636 10.5011 2.38636 10.8333C2.38636 11.1656 2.65364 11.4352 2.98295 11.4352H10.142C10.4714 11.4352 10.7386 11.1656 10.7386 10.8333ZM10.7386 8.42593C10.7386 8.0937 10.4714 7.82407 10.142 7.82407H2.98295C2.65364 7.82407 2.38636 8.0937 2.38636 8.42593C2.38636 8.75815 2.65364 9.02778 2.98295 9.02778H10.142C10.4714 9.02778 10.7386 8.75815 10.7386 8.42593Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Document;
