exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ConfirmDialog_btnsContainer__2m5hZ {\n    margin-top: 15px;\n    width: 100%;\n}\n.ConfirmDialog_btnsContainer__2m5hZ .ConfirmDialog_cancelBtn__3esQc {\n    width: 100%;\n    max-width: 150px;\n    height: 45px;\n    font-size: 16px;\n    margin-right: 15px;\n    background: rgba(255, 255, 255, 0.9);\n    color: #000000;\n    text-transform: capitalize !important;\n    transition: all 250ms ease-in-out;\n    border-radius: 8px;\n}\n.ConfirmDialog_btnsContainer__2m5hZ .ConfirmDialog_cancelBtn__3esQc:hover {\n    background: rgba(255, 255, 255, 0.7);\n    color: #000000;\n}\n\n.ConfirmDialog_btnsContainer__2m5hZ .ConfirmDialog_confirmBtn__FkjAu {\n    width: 100%;\n    max-width: 150px;\n    height: 45px;\n    border-radius: 8px;\n    font-size: 16px !important;\n    text-transform: capitalize !important;\n    transition: all 250ms ease-in-out;\n}\n\n.ConfirmDialog_loaderContainer__3Cd_d {\n    width: 100%;\n    max-width: 150px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.ConfirmDialog_loaderContainer__3Cd_d .ConfirmDialog_loader__3frPM {\n    width: 36px;\n    height: 36px;\n}\n", ""]);

// exports
exports.locals = {
	"btnsContainer": "ConfirmDialog_btnsContainer__2m5hZ",
	"cancelBtn": "ConfirmDialog_cancelBtn__3esQc",
	"confirmBtn": "ConfirmDialog_confirmBtn__FkjAu",
	"loaderContainer": "ConfirmDialog_loaderContainer__3Cd_d",
	"loader": "ConfirmDialog_loader__3frPM"
};