import React,{useState} from 'react'
import { Tab, Tabs } from '../../../../common/Tabs';
import ViewSmall from '../../../../icons/ViewSmall';
import ViewMedium from '../../../../icons/ViewMedium';
import ViewBig from '../../../../icons/ViewBig';
import CertificatesItem from '../../../BrandsNew/components/CertificatesItem';
import styles from '../../index.module.css';
import cn from 'classnames';
export const ProfileSertificates = () => {
    const [view, setView] = useState("big");

    return (
        <div className={styles.profileTokensContent}>
        <Tabs changeMobileTheme>
            <Tab left active={view === "small"} onClick={() => setView("small")}>
                <ViewSmall />
            </Tab>

            <Tab active={view === "normal"} onClick={() => setView("normal")}>
                <ViewMedium />
            </Tab>

            <Tab right active={view === "big"} onClick={() => setView("big")}>
                <ViewBig />
            </Tab>
        </Tabs>

        <div className={cn(styles.profileTokensItems, {
            [styles.smallCards]: view === "small",
            [styles.normalCards]: view === "normal",
        })}>
            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                    purchased: true
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #002',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
                invited={true}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />

            <CertificatesItem
                certificate={{
                    id: '1',
                    name: 'Anastasiya #001',
                    price: 1313200,
                    file_1: '/assets/img/brand1.jpg',
                    investor_royalty: '0.1',
                }}
                view={view}
            />
        </div>
    </div>
    )
}