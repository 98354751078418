import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';

const TemplatePageSkeleton = () => {
    return (
        <>
            <div className="brand__bg">
                <Skeleton containerClassName="brand__bg--skeleton" className="skeleton__content" />
            </div>

            <div className="aboutblock">
                <div className="container">
                    <div className="aboutblock__inner">
                        <Skeleton
                            containerClassName="aboutblock__title--skeleton"
                            className="skeleton__content"
                        />

                        <div className="collection__desc--inner">
                            <Skeleton
                                containerClassName="collection__desc--inner--skeleton"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="imgblock__title imgblock__title--inner--skeleton">
                            <Skeleton
                                containerClassName="imgblock__title--skeleton"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="imgblock__content">
                            {new Array(0, 0, 0, 0, 0, 0).map((_, id) => (
                                <div key={id} className="imgblock__item">
                                    <div className="imgblock__item--inner">
                                        <Skeleton
                                            containerClassName="imgblock__item--skeleton"
                                            className="skeleton__content"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TemplatePageSkeleton;
