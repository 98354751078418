import React from 'react';

const Crown = ({ ...props }) => {
    return (
        <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.47662 2.98193C1.8715 2.77796 2.35481 2.87225 2.64406 3.20971L7.50906 8.88554L10.6677 3.35792C10.8383 3.05932 11.1559 2.87505 11.4998 2.87505C11.8437 2.87505 12.1612 3.05932 12.3318 3.35792L15.4905 8.88554L20.3555 3.20971C20.6447 2.87225 21.128 2.77796 21.5229 2.98193C21.9178 3.18591 22.1206 3.63463 22.0128 4.06581L19.1378 15.5658C19.0312 15.9924 18.6479 16.2917 18.2081 16.2917H4.79144C4.35169 16.2917 3.96837 15.9924 3.86172 15.5658L0.986718 4.06581C0.878922 3.63463 1.08174 3.18591 1.47662 2.98193ZM3.83075 7.53931L5.53968 14.375H17.4599L19.1688 7.53931L16.0607 11.1654C15.8579 11.402 15.5527 11.5252 15.2424 11.4958C14.9322 11.4663 14.6557 11.2878 14.501 11.0172L11.4998 5.76496L8.4985 11.0172C8.34388 11.2878 8.06734 11.4663 7.75709 11.4958C7.44684 11.5252 7.14163 11.402 6.93882 11.1654L3.83075 7.53931ZM3.8331 19.1667C3.8331 18.6374 4.26216 18.2084 4.79144 18.2084H18.2081C18.7374 18.2084 19.1664 18.6374 19.1664 19.1667C19.1664 19.696 18.7374 20.125 18.2081 20.125H4.79144C4.26216 20.125 3.8331 19.696 3.8331 19.1667Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default Crown;
