import React from 'react';

import styles from './index.module.css';

const TokenInfo = ({ children }) => {
    return (
        <div className={styles.brandItemInfoButtonWrapper}>
            {children}

            <div className={styles.brandItemInfoPopupWrapper}>
                <div className={styles.brandItemInfoPopup}>
                    <div className={styles.brandItemInfoPopupContent}>
                        <div className={styles.brandItemInfoWeight}>
                            <img
                                src="/assets/img/alco1.png"
                                alt="logo"
                                className={styles.brandItemInfoWeightImg}
                            />

                            <div className={styles.brandItemInfoWeightInner}>
                                <p className={styles.brandItemInfoWeightText}>Куплен за</p>

                                <p className={styles.brandItemInfoTag}>
                                    11 798 291 RUB
                                </p>
                            </div>
                        </div>

                        <p className={styles.brandItemInfoPopupName}>Lexus RX 300-1</p>

                        <div className={styles.brandItemInfoPopupPoints}>
                            <p className={styles.brandItemInfoPopupPoint}>Lexus RX 300</p>
                            <p className={styles.brandItemInfoPopupPoint}>Белый</p>
                            <p className={styles.brandItemInfoPopupPoint}>А777АА777</p>
                            <p className={styles.brandItemInfoPopupPoint}>Premium</p>
                            <p className={styles.brandItemInfoPopupPoint}>2024</p>
                            <p className={styles.brandItemInfoPopupPoint}>4,5</p>
                        </div>

                        <p className={styles.brandItemInfoPopupText}>
                            С другой стороны рамки и место обучения кадров позволяет оценить значение существующих финансовых и административных условий. Задача организации, в особенности же постоянный количественный рост и сфера нашей активности позволяет оценить значение всесторонне сбалансированных нововведений! Равным образом сложившаяся структура организации позволяет выполнить важнейшие задания по разработке форм воздействия?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenInfo;
