import React, { useState } from 'react';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Star2 from '../../../../icons/Star2';
import Star from '../../../../icons/Star';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';

import WhatsApp from '../../../../icons/WhatsApp';
import Telegram2 from '../../../../icons/Telegram2';
import Vk from '../../../../icons/Vk';
import Instagram from '../../../../icons/Instagram';
import Youtube from '../../../../icons/Youtube';
import Twitter from '../../../../icons/Twitter';

import ModalNew from '../../../../common/ModalNew';
import { addReview } from '../../lib/reviews';
import { useSelector } from 'react-redux';

const socialMediaIcons = {
    'whatsapp.com': <WhatsApp />,
    't.me': <Telegram2 />,
    'vk.com': <Vk />,
    'instagram.com': <Instagram />,
    'youtube.com': <Youtube />,
    'twitter.com': <Twitter />,
};

const getSocialMediaIcon = (url) => {
    try {
        const domain = new URL(url).hostname;

        for (const [key, icon] of Object.entries(socialMediaIcons)) {
            if (domain.includes(key)) {
                return icon;
            }
        }
    } catch (error) {
        console.error('Invalid URL', error);
    }
    return null;
};
const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/reviews`;
const token = localStorage.getItem('access_token');
export const ReviewModal = ({ reviewModal, setReviewModal, setReviewModalThx, refechReview }) => {
    const [review, setReview] = React.useState(5);
    const [preview, setPreview] = React.useState(null);
    const [name, setName] = React.useState('');
    const [textReview, setTextReview] = React.useState('');
    const [link, setLink] = React.useState('');
    const [file, setFile] = React.useState('');
    const [type, setType] = React.useState('user');
    const [icon, setIcon] = React.useState(null);
    const [errormsg, setErrorMsg] = useState('');
    const [errors, setErrors] = React.useState({});
    const [charCount, setCharCount] = React.useState(0);

    const accessToken = useSelector((state) => state.auth.accessToken);

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleTextReview = (event) => {
        const value = event.target.value;
        setTextReview(value);
        setCharCount(value.length);
    };

    const handleLink = (event) => {
        setLink(event.target.value);
        setIcon(getSocialMediaIcon(event.target.value));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleType = (event) => {
        setType(event.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!name.trim()) newErrors.name = 'Это поле обязательно для заполнения';
        if (!textReview.trim()) newErrors.textReview = 'Это поле обязательно для заполнения';
        if (file === '' ) newErrors.file = 'Это поле обязательно для заполнения';
        // if (link === '' ) newErrors.link = 'Это поле обязательно для заполнения';
        if (textReview.length > 500)
            newErrors.textReview = 'Текст отзыва не может превышать 500 символов';
        if (type === 'brand_representative' && !name.trim())
            newErrors.name = 'Введите название бренда';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddReview = () => {
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append('type', type);
        formData.append('name', name);
        formData.append('stars', review);
        formData.append('review', textReview);
        formData.append('link', link);
        formData.append('logo', file);

        // const result = await addReview(formData, accessToken);

        fetch(BASE_URL, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-type': 'application/json',
            },
            body: formData,
        })
            .then((res) => {
                if (Number(res.status) === 201) {
                    setReviewModal(false);
                    setReviewModalThx(true);
                    refechReview();
                }
                if (Number(res.status) >= 400) {
                    return res.json();
                }
            })
            .then((response) => {
                if (response) {
                    // console.log(response)
                    setErrorMsg(response);
                }
            });
    };

    return (
        <ModalNew active={reviewModal} setActive={setReviewModal}>
            <div className={styles.reviewForm}>
                <p className={cn(base.title2, styles.modalReviewTitle)}>Написать отзыв</p>

                <div className={styles.reviewFormContent}>
                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>Тип пользователя</p>

                        <select
                            className={styles.reviewFormSelect}
                            onChange={handleType}
                            value={type}
                        >
                            <option value="user">Пользователь</option>
                            <option value="brand_representative">Представитель бренда</option>
                        </select>
                    </div>

                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>
                            {type === 'user' ? 'Имя Фамилия' : 'Название бренда'}
                        </p>

                        <input
                            type="text"
                            className={cn(styles.reviewFormInput, {
                                [styles.errorInput]: errors.name,
                            })}
                            placeholder={
                                type === 'user'
                                    ? 'Введите имя и фамилию'
                                    : 'Введите название бренда'
                            }
                            value={name}
                            onChange={handleName}
                        />
                        {errors.name && <p className={styles.errorText}>{errors.name}</p>}
                    </div>

                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>Логотип</p>

                        <input
                            type="file"
                            id="reviewPhoto"
                            accept=".pdf,.jpeg,.jpg,.png"
                            className={styles.reviewFormUploadInput}
                            onChange={handleFileChange}
                        />

                        <label htmlFor="reviewPhoto" className={styles.reviewFormUpload}>
                            Загрузите ваше фото
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14.5C14.7652 1 15.0196 1.10536 15.2071 1.29289L20.7071 6.79289C20.8946 6.98043 21 7.23478 21 7.5V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H4C3.44772 23 3 22.5523 3 22C3 21.4477 3.44772 21 4 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V7.91421L14.0858 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8V4C3 3.20435 3.31607 2.44129 3.87868 1.87868Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 1C14.5523 1 15 1.44772 15 2V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H14C13.4477 9 13 8.55228 13 8V2C13 1.44772 13.4477 1 14 1Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2 15C2 14.4477 2.44772 14 3 14H9C9.55228 14 10 14.4477 10 15C10 15.5523 9.55228 16 9 16H3C2.44772 16 2 15.5523 2 15Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6 11C6.55228 11 7 11.4477 7 12V18C7 18.5523 6.55228 19 6 19C5.44772 19 5 18.5523 5 18V12C5 11.4477 5.44772 11 6 11Z"
                                    fill="#2072E2"
                                />
                            </svg>
                        </label>
                        {preview && (
                            <div className={styles.previewContainer}>
                                <img src={preview} alt="Preview" className={styles.previewImage} />
                            </div>
                        )}
                         {errors.file && <p className={styles.errorText}>{errors.file}</p>}
                    </div>

                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>Оценка</p>

                        <div className={styles.reviewFormRate}>
                            {[...Array(5)].map((_, index) =>
                                review >= index + 1 ? (
                                    <Star2 key={index} onClick={() => setReview(index + 1)} />
                                ) : (
                                    <Star key={index} onClick={() => setReview(index + 1)} />
                                ),
                            )}
                        </div>
                    </div>

                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>Отзыв</p>

                        <textarea
                            type="text"
                            className={cn(styles.reviewFormTextarea, {
                                [styles.errorTextarea]: errors.textReview,
                            })}
                            placeholder="Напишите текст отзыва"
                            value={textReview}
                            onChange={handleTextReview}
                        ></textarea>
                        {errors.textReview && (
                            <p className={styles.errorText}>{errors.textReview}</p>
                        )}
                        <p className={styles.charCount}>Осталось символов: {500 - charCount}</p>
                    </div>

                    <div className={styles.reviewFormItem}>
                        <p className={styles.orderSubtext}>
                            Ссылка на месседжер / сайт (не обязательно)
                        </p>
                        <div className={styles.orderSubtextInput}>
                            <input
                                type="text"
                                className={styles.reviewFormInput}
                                placeholder="Введите ссылку"
                                value={link}
                                onChange={handleLink}
                            />
                        </div>
                    </div>
                    <div>
                        {Object.values(errormsg).map((item) => (
                            <p style={{ color: 'red' }}>{item}</p>
                        ))}
                    </div>
                    <button
                        className={cn(base.orangeButton, styles.reviewModalButton)}
                        onClick={handleAddReview}
                    >
                        Оставить отзыв
                    </button>
                </div>
            </div>
        </ModalNew>
    );
};
