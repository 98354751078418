import React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import checkIcon from './img/check.svg';
import CropSquareIcon from '@mui/icons-material/CropSquare';

import styles from './CheckBox.module.css';

const CheckBox = props => {
    const {
        className,
        checked,
        defaultChecked,
        disableRipple,
        required,
        disabled,
        ariaLabel,
        classes = {},
        onChange,
        style
    } = props;

    return (
        <MuiCheckbox
            className={className}
            checked={checked}
            required={required}
            disabled={disabled}
            disableRipple={disableRipple}
            defaultChecked={defaultChecked}
            icon={<img src={checkIcon} alt={'check'} />}
            checkedIcon={<img src={checkIcon} alt={'check'} />}
            classes={{
                root: styles.root,
                ...classes,
            }}
            inputProps={{ 'aria-label': `${ariaLabel}` }}
            onChange={onChange}
            style={style}
        />
    );
};

export default React.memo(CheckBox);
