import React from 'react';

const Star = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9974 1.75C12.283 1.74927 12.5442 1.91078 12.6711 2.16658L15.5928 8.05335L22.1082 9.0034C22.3904 9.04453 22.6247 9.24206 22.7131 9.51313C22.8014 9.78419 22.7285 10.0819 22.5248 10.2814L17.786 14.9223L18.9116 21.371C18.9608 21.6534 18.8449 21.9392 18.6127 22.1074C18.3806 22.2755 18.0729 22.2967 17.82 22.1619L11.9994 19.0595L6.18005 22.1618C5.92694 22.2968 5.61904 22.2755 5.38686 22.1071C5.15468 21.9387 5.03888 21.6526 5.08855 21.3701L6.22229 14.9223L1.47572 10.2818C1.2717 10.0824 1.19853 9.78444 1.28693 9.51316C1.37534 9.24188 1.61002 9.04426 1.89239 9.00331L8.44346 8.05337L11.3258 2.17003C11.4514 1.91358 11.7118 1.75074 11.9974 1.75ZM12.0037 4.19582L9.61634 9.06877C9.50736 9.2912 9.29558 9.44549 9.05045 9.48104L3.60935 10.27L7.55377 14.1263C7.7308 14.2993 7.81101 14.5486 7.76814 14.7924L6.83239 20.1142L11.6465 17.5478C11.867 17.4302 12.1316 17.4302 12.3521 17.5477L17.1697 20.1155L16.2405 14.7915C16.198 14.5482 16.2781 14.2995 16.4546 14.1267L20.3934 10.2692L14.9875 9.48095C14.7439 9.44544 14.5333 9.29269 14.4239 9.07222L12.0037 4.19582Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Star;
