export const tarifTitles = [
    {
        text: 'Разработка дизайнов для сертификатов',
        tooltip: 'Создание уникальных изображений и описаний продуктов бренда',
    },
    {
        text:'Оформление дизайна коллекций бренда',
        tooltip:
            'Разработка фирменного дизайна бренда, включая баннеры, логотипы и текстовые описания',
    },
    {
        text:'Разработка оформления продукции',
        tooltip: 'Интеграция QR-кода в дизайн одежды бренда',
    },
    {
        text:'Дизайн карточки бренда с QR-кодом',
        tooltip: 'Создание карточки бренда с QR-кодом и инструкцией по использованию',
    },
    {
        text:'Фирменная эмблема с размещением QR-кода',
        tooltip: 'Разработка фирменной эмблемы для скрытого размещения на изделиях',
    },
    {
        text:'Передача прав на созданные произведения',
        tooltip: 'Оформление передачи прав на созданные дизайнерские работы',
    },
    {
        text:'Стратегия продажи с просчетом бюджета',
        tooltip:
            'Наши маркетологи и аналитики проведут исследование рынка, разработают эффективный маркетинговый план и будут сопровождать его реализацию',
    },
    {
        text:'Подключение партнеров к сертификату',
        tooltip:
            'Мы предоставим Вам список потенциальных партнеров, товары и услуги которых могут быть включены в сертификаты, чтобы Вы могли выбрать наиболее подходящие варианты',
    },
    {
        text:'Сопровождение бизнеса',
        tooltip:
            'Наша техническая поддержка поможет вашим клиентам с передачей и использованием сертификатов, а также ответит на любые возникающие вопросы',
    },
];

export const tarifList = [
    {
        type: 'start',
        name: 'Старт',
        price: '9 900',
        staticVariants: 3,
        decorationCollection: 1,
        decorationProduct: 1,
        cardDesign: true,
        selfIcon: false,
        transferRight: false,
        sellStrategy: false,
        conectPartners: 1,
        mounthCount: 1,
        tarifProps: [
            {
                count: 100,
                price: 99,
            },
        ],
    },
    {
        type: 'buisness',
        name: 'Бизнес',
        price: '89 000',
        staticVariants: 10,
        decorationCollection: 3,
        decorationProduct: 2,
        cardDesign: true,
        selfIcon: true,
        transferRight: true,
        sellStrategy: false,
        conectPartners: 3,
        mounthCount: 6,
        tarifProps: [
            {
                count: 1000,
                price: 89,
            },
            {
                count: 3000,
                price: 79,
            },
        ],
    },
    {
        type: 'premium',
        name: 'Премиум',
        price: '414 900',
        staticVariants: 10,
        decorationCollection: 6,
        decorationProduct: 8,
        cardDesign: true,
        selfIcon: true,
        transferRight: true,
        sellStrategy: true,
        animatedVariants: 20,
        conectPartners: 10,
        mounthCount: 18,
        tarifProps: [
            {
                count: 6000,
                price: 69,
            },
            {
                count: 10000,
                price: 59,
            },
            {
                count: 20000,
                price: 49,
            },
            {
                count: 30000,
                price: 39,
            },
        ],
    },
];