import React from 'react';

export const Search3 = ({ ...props }) => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.9375 12.875C10.2167 12.875 12.875 10.2167 12.875 6.9375C12.875 3.65831 10.2167 1 6.9375 1C3.65831 1 1 3.65831 1 6.9375C1 10.2167 3.65831 12.875 6.9375 12.875Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.25 13.25L12 12"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
