import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const MINUTES_IN_HOUR = 60;
const DEFAULT_TIMER_STRING_VALUE = 'Прямо сейчас';

const convertMinuterToTimeString = (minutes) => {
    const hours = Math.floor(minutes / MINUTES_IN_HOUR);
    const remainingMinutes = minutes % MINUTES_IN_HOUR;

    if (hours > 0) {
        return `Обновлено ${hours}ч ${remainingMinutes}м назад`;
    }

    return `Обновлено ${remainingMinutes}м назад`;
};

export default function useTimer(options) {
    const { intervalInMs } = options;

    const timerRef = useRef(0);

    const [timer, setTimer] = useState(0);

    const timeString = useMemo(() => {
        if (!timer) {
            return DEFAULT_TIMER_STRING_VALUE;
        }

        return convertMinuterToTimeString(timer);
    }, [timer]);

    const startTimer = useCallback(() => {
        clearTimeout(timerRef.current);
        setTimer(0);
        tick();
    }, []);

    const tick = useCallback(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setInterval(() => {
            setTimer((timer) => timer + 1);
            tick();
        }, intervalInMs);
    }, []);

    useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        [],
    );

    return {
        timeString,
        startTimer,
    };
}
