import React from 'react';

const Search2 = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.1251 13.1251L10.4108 10.4108M10.4108 10.4108C10.8751 9.94645 11.2434 9.39524 11.4946 8.7886C11.7459 8.18195 11.8753 7.53176 11.8753 6.87513C11.8753 6.2185 11.7459 5.56831 11.4946 4.96166C11.2434 4.35502 10.8751 3.80381 10.4108 3.3395C9.94645 2.8752 9.39524 2.50689 8.7886 2.25561C8.18195 2.00433 7.53176 1.875 6.87513 1.875C6.2185 1.875 5.56831 2.00433 4.96166 2.25561C4.35502 2.50689 3.80381 2.8752 3.3395 3.3395C2.4018 4.27721 1.875 5.54901 1.875 6.87513C1.875 8.20125 2.4018 9.47305 3.3395 10.4108C4.27721 11.3485 5.54901 11.8753 6.87513 11.8753C8.20125 11.8753 9.47305 11.3485 10.4108 10.4108Z"
                stroke="#334D78"
                strokeWidth="1.5625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Search2;
