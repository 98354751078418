import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    page: 1,
    lastItemsCount: 20,
    tokens: [],
    tokensPage: 1,
    tokensLastItemsCount: 20,
    name: '',
    type: '',
    status: 'created'
}

const conclusionsSlice = createSlice({
    name: 'conclusion',
    initialState: initialState,
    reducers: {
        tokensIncrementPage: (state) => {
            state.page += 1
        },
        tokensSetLastItemsCount: (state, action) => {
            state.lastItemsCount = action.payload
        },
        tokensAddPageData: (state, action) => {
            state.tokens = [...state.tokens, ...action.payload]
        },
        tokensRemove: (state) => {
            state.tokens = []
            state.page = 1
            state.lastItemsCount = 20
        },
        changeSearchTokenName: (state, action) => {
            state.name = action.payload
        },
        changeType: (state, action) => {
            state.type = action.payload
        },
        changeStatus: (state, action) => {
            state.status = action.payload
        }
    },
})

export const {
    tokensSetLastItemsCount,
    tokensRemove,
    tokensAddPageData,
    tokensIncrementPage,
    changeSearchTokenName,
    changeType,
    changeStatus,
} = conclusionsSlice.actions

export const conclusionsReducer = conclusionsSlice.reducer
