exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StickyWrapper_sticky__3mGd_ {\n    position: fixed !important;\n    top: 0;\n    width: 100%;\n    padding: 0;\n    margin: 0 auto;\n}\n\n@media only screen and (max-width: 480px) {\n    .StickyWrapper_sticky__3mGd_ {\n        left: 0;\n        padding: 0 1.2rem;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"sticky": "StickyWrapper_sticky__3mGd_"
};