import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import styles from '../BrandsNew/index.module.css';
import base from '../../styles/base.module.css';

import Vk from '../../icons/Vk';
import Twitter from '../../icons/Twitter';
import Youtube from '../../icons/Youtube';
import Instagram from '../../icons/Instagram';
import Telegram2 from '../../icons/Telegram2';
import Share from '../../icons/Share';
import Bag from '../../icons/Bag';
import Star from '../../icons/Star';
import Chart from '../../icons/Chart';
import Check from '../../icons/Check';

import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';

import { copyUrl } from '../../functions/utils';
import { CollectionContext, CollectionContextProvider } from './lib/CollectionContext';
import { WhiteListContext, WhiteListContextProvider } from './lib/WhiteListContext';
import isElementIntoViewPort from '../../utils/isElementIntoViewPort';
import { FiltersContext, FiltersContextProvider } from './lib/FiltersContext';
import { useSelector } from 'react-redux';
import CollectionItemSkeleton from '../BrandsNew/components/CollectionItemSkeleton';
import CollectionItem from '../BrandsNew/components/CollectionItem';
import NotContent from '../../icons/NotContent';
import { S3Context } from '../../context/S3Context';



const BrandNew = () => {
    const {
         state: { collection, bookedTokensIds, collectionTokens, collectionTokensCount, sortBy: filterSortBy },
        // state: { bookedTokensIds, collectionTokens, collectionTokensCount, sortBy: filterSortBy },
        actions: { onNextPageCollectionsTokensHandler, onCollectionTokensRefetch, handleFilter },

        isTokensLoading,
        isFetchingLoading,
        isCollectionLoading,
    } = useContext(CollectionContext);
    const [fullText, setFullText] = React.useState(false);
    const showText = (e) => {
        e.preventDefault();
        setFullText(prev => !prev);
    };



    const {
        isWhitelistApplicationLoading,
        bookTokenIsLoading,
    } = useContext(WhiteListContext);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const {
        state: {
            query,
            sortBy,
            activeStatusesFilter,
            activePropertiesFilter,
            activeLevelStatsFilter,
            paid
        },
        actions: {
            handleSearchQueryHandler,
            unsetStatusesFilter,

            unsetPropertiesFilter,

        },
    } = useContext(FiltersContext);

    const [social, setSocial] = useState(false);

    const [headerOffsetTop, setHeaderOffsetTop] = useState(0);

    const containerRef = useRef(null);

    const filtersTopRef = useRef(null);
    const filtersBottomRef = useRef(null);

    const firstUndrowenItemIdx = useRef(-1);

    const [filter, setFilter] = React.useState(null);
    
  

    
    React.useEffect(() => {
        handleFilter(filter);
    }, [filter]);

    useEffect(() => {
        const clickHandler = (e) => {
            const div = document.getElementById('collection__social__modal');
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setSocial(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => {
            document.removeEventListener('click', clickHandler);
        };
    }, []);

    const emptySearch = useCallback(() => {
        handleSearchQueryHandler('');
    }, []);

    const copyUrlPage = () => {
        copyUrl();
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Ссылка скопирована!',
        });
    };

    const activeFiltersArr = useMemo(() => {
        const res = [];

        activeStatusesFilter.forEach((status) => {
            res.push({
                id: status,
                name: status,
                onRemove: () => unsetStatusesFilter(status),
            });
        });

        activePropertiesFilter.forEach((propertie) => {
            res.push({
                id: propertie.id,
                name: propertie.name,
                onRemove: () => unsetPropertiesFilter(propertie.id),
            });
        });

        return res;
    }, [activeStatusesFilter, activePropertiesFilter, activeLevelStatsFilter]);

    const isDataLoading = useMemo(() => {
        return (
            !collection ||
            !collectionTokens ||
            isCollectionLoading ||
            isWhitelistApplicationLoading ||
            isCollectionLoading ||
            isTokensLoading
        );
    }, [
        collection,
        collectionTokens,
        isCollectionLoading,
        isWhitelistApplicationLoading,
        isTokensLoading,
        isFetchingLoading,
        isCollectionLoading,
    ]);

    const onPageScrollHandler = useCallback(() => {
        if (containerRef.current && firstUndrowenItemIdx.current > -1) {
            const elem = Array.from(containerRef.current.parentNode.children[0].children)[
                firstUndrowenItemIdx.current
            ];

            if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
                onNextPageCollectionsTokensHandler();
                firstUndrowenItemIdx.current = -1;
            }
        }
    }, [isDataLoading]);

    useEffect(() => {
        window.addEventListener('scroll', onPageScrollHandler);

        return () => {
            window.removeEventListener('scroll', onPageScrollHandler);
        };
    }, [isDataLoading]);

    useEffect(() => {
        if (filtersTopRef.current && filtersBottomRef.current) {
            const top = filtersTopRef.current.getBoundingClientRect().top;
            const bottom = filtersBottomRef.current.getBoundingClientRect().bottom;

            setHeaderOffsetTop(bottom - top);
        }
    }, [collectionTokensCount, activeFiltersArr, isDataLoading]);


    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);



    return (
        <div class={ styles.marginWrapper }>
            <section className={ styles.brandpage }>
                <img src="/assets/img/brand-blur.svg" alt="blur" className={ styles.brandpageBg } />

                <div className={ base.container }>
                    <div className={ styles.brandpageInner }>
                        <div className={ styles.brandContentWrapper }>
                            <img
                                src="/assets/img/brand-light.png"
                                alt="light"
                                className={ styles.brandContentWrapperLight }
                            />

                            <div className={ styles.brandContent }>
                                <div className={ styles.brandImgInner }>
                                    <img src={ getImageBacketPath(collection.cover) } alt="image" />
                                </div>

                                <div className={ styles.brandInfoContent }>
                                    <div className={ styles.brandInfoNameInner }>
                                        <p className={ cn(base.title2, styles.brandInfoName) }>
                                            { collection.name }
                                        </p>

                                        <div className={ styles.brandInfoSocialLinks }>
                                            {
                                                collection.link_vk && <a target='_blank' href={ collection.link_vk } className={ styles.brandInfoSocialLink }>
                                                    <Vk />
                                                </a>

                                            }
                                            {
                                                collection.link_youtube && <a target='_blank' href={ collection.link_youtube } className={ styles.brandInfoSocialLink }>
                                                    <Youtube />
                                                </a>

                                            }
                                            {
                                                collection.link_instagram &&
                                                <a href={ collection.link_instagram } target='_blank' className={ styles.brandInfoSocialLink }>
                                                    <Instagram />
                                                </a>

                                            }
                                            {
                                                collection.link_telegram &&
                                                <a href={ collection.link_telegram } target='_blank' className={ styles.brandInfoSocialLink }>
                                                    <Telegram2 />
                                                </a>

                                            }

                                        </div>
                                    </div>

                                    <div className={ styles.brandInfoCategoryInner }>
                                        <div className={ styles.brandInfoCategory }>
                                            Категория:
                                            <p className={ styles.brandItemInfoTag }>{ collection.page ? collection.page.name : '' }</p>
                                        </div>


                                        {
                                            collection.link_yoursite &&
                                            <a href={ collection.link_yoursite } target='_blank' className={ styles.brandItemSite }>
                                                <Share />
                                                { collection.link_yoursite }
                                            </a>

                                        }

                                    </div>

                                    <div className={ styles.brandImgWrap }>
                                        <div className={ styles.brandInfoPoints }>
                                            <div className={ styles.brandInfoPoint }>
                                                <p className={ styles.brandInfoPointText }>
                                                    Объём продаж
                                                </p>

                                                <p className={ styles.brandInfoPointValue }>
                                                    <Bag />
                                                    { Math.floor(collection.volume_troded_count/100) } руб.
                                                </p>
                                            </div>

                                            <div className={ styles.brandInfoPoint }>
                                                <p className={ styles.brandInfoPointText }>
                                                    Коллекции
                                                </p>

                                                <p className={ styles.brandInfoPointValue }>
                                                    <Star />
                                                    { collection.collections_count } шт.
                                                </p>
                                            </div>

                                            <div className={ styles.brandInfoPoint }>
                                                <p className={ styles.brandInfoPointText }>
                                                    Сертификаты
                                                </p>

                                                <p className={ styles.brandInfoPointValue }>
                                                    <Check />
                                                    { collection.items_count } шт.
                                                </p>
                                            </div>

                                            <div className={ styles.brandInfoPoint }>
                                                <p className={ styles.brandInfoPointText }>Продано</p>

                                                <p className={ styles.brandInfoPointValue }>
                                                    <Chart />  { new Intl.NumberFormat('ru-RU').format(collection.owners_count) } шт.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={ styles.brandInfoText }>
                                        <p style={{whiteSpace:'break-spaces'}}>
                                            {
                                                collection.description && collection.description.length > 150 && !fullText ? `${collection.description.slice(0, 150)}...` : collection.description
                                            }
                                        </p>

                                        {
                                            collection.description && collection.description.length > 150 &&
                                            <a
                                                href="#"
                                                className={ styles.brandsTextShow }
                                                onClick={ showText }
                                            >
                                                { fullText ? 'Свернуть' : 'Развернуть' }
                                            </a>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className={ cn(base.title2, styles.brandCollectionsTitle) }>
                            Коллекции бренда
                        </p>

                        <div className={ styles.brandOuter }>
                            <div className={ styles.brandSubTabs }>
                                <button className={
                                    cn(styles.brandSubTab, {
                                        [styles.active]: filter === null,
                                    })
                                } onClick={ () => { setFilter(null); } }>
                                    Все
                                </button>

                                <button className={
                                    cn(styles.brandSubTab, {
                                        [styles.active]: filter,
                                    })
                                } onClick={ () => { setFilter(true); } }>
                                    Продано
                                </button>

                                <button className={
                                    cn(styles.brandSubTab, {
                                        [styles.active]: filter === false,
                                    })
                                } onClick={ () => { setFilter(false); } }>
                                    В продаже
                                </button>
                            </div>

                            <p className={ styles.tokenValue }>{ collectionTokensCount } шт.</p>
                        </div>

                        <div className={ styles.brandsContent }>
                            {
                                collectionTokens && collectionTokens.map(collection => <CollectionItem key={ collection.id } collection={ collection } />)
                            }
                            {
                                collectionTokens.length > 0 && collectionTokensCount - collectionTokens.length > 0 &&
                                Array.from(
                                    Array(
                                        collectionTokensCount - collectionTokens.length
                                    ).keys(),
                                ).map((idx) => {
                                    if (firstUndrowenItemIdx.current === -1) {
                                        firstUndrowenItemIdx.current =
                                            idx + collectionTokens.length;
                                    }
                                    return <CollectionItemSkeleton />;
                                })
                            }
                        </div>
                        {
                            !isCollectionLoading && collectionTokens.length === 0 &&
                            <div className={ styles.notContentWrapper }>
                                <div className={ styles.notContent }>
                                    <NotContent />

                                    <p className={ cn(base.title2, styles.notContentTitle) }>
                                        Нет информации<br />для отображения
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};


const BrandNewPrefecth = () => {
    const { id } = useParams();

    return (
        <FiltersContextProvider>
            <CollectionContextProvider id={ id }>
                <WhiteListContextProvider>
                    <BrandNew />
                </WhiteListContextProvider>
            </CollectionContextProvider>
        </FiltersContextProvider>
    );
};

export default BrandNewPrefecth;
