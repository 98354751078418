import React from 'react';

const Share = ({ ...props }) => {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33325 4.66536C3.15644 4.66536 2.98687 4.7356 2.86185 4.86063C2.73682 4.98565 2.66659 5.15522 2.66659 5.33203V12.6654C2.66659 12.8422 2.73682 13.0117 2.86185 13.1368C2.98687 13.2618 3.15644 13.332 3.33325 13.332H10.6666C10.8434 13.332 11.013 13.2618 11.138 13.1368C11.263 13.0117 11.3333 12.8422 11.3333 12.6654V8.66536C11.3333 8.29717 11.6317 7.9987 11.9999 7.9987C12.3681 7.9987 12.6666 8.29717 12.6666 8.66536V12.6654C12.6666 13.1958 12.4559 13.7045 12.0808 14.0796C11.7057 14.4546 11.197 14.6654 10.6666 14.6654H3.33325C2.80282 14.6654 2.29411 14.4546 1.91904 14.0796C1.54397 13.7045 1.33325 13.1958 1.33325 12.6654V5.33203C1.33325 4.8016 1.54397 4.29289 1.91904 3.91782C2.29411 3.54274 2.80282 3.33203 3.33325 3.33203H7.33325C7.70144 3.33203 7.99992 3.63051 7.99992 3.9987C7.99992 4.36689 7.70144 4.66536 7.33325 4.66536H3.33325Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33325 1.9987C9.33325 1.63051 9.63173 1.33203 9.99992 1.33203H13.9999C14.3681 1.33203 14.6666 1.63051 14.6666 1.9987V5.9987C14.6666 6.36689 14.3681 6.66536 13.9999 6.66536C13.6317 6.66536 13.3333 6.36689 13.3333 5.9987V2.66536H9.99992C9.63173 2.66536 9.33325 2.36689 9.33325 1.9987Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4714 1.52729C14.7318 1.78764 14.7318 2.20975 14.4714 2.4701L7.13807 9.80344C6.87772 10.0638 6.45561 10.0638 6.19526 9.80344C5.93491 9.54309 5.93491 9.12098 6.19526 8.86063L13.5286 1.52729C13.7889 1.26694 14.2111 1.26694 14.4714 1.52729Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Share;
