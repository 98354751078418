import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const FilterItemList = ({ title, titleValue, children }) => {
    const [open, setOpen] = React.useState(true);

    return (
        <div className={styles.collectionFilterBlockItem}>
            <div
                className={styles.collectionFilterTitleInner}
                onClick={() => setOpen((prev) => !prev)}
            >
                <p className={styles.collectionFilterTitle}>{title}</p>

                <div
                    className={cn(styles.collectionFilterTitleWrapper, {
                        [styles.open]: open,
                    })}
                >
                    {titleValue && <p>{titleValue}</p>}

                    <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M4.88898 6.72168L-5.87545e-08 1.34415L1.22203 -4.27409e-07L5.5 4.70546L9.77797 -5.34166e-08L11 1.34415L6.11102 6.72168C5.94895 6.89989 5.72916 7 5.5 7C5.27084 7 5.05105 6.89989 4.88898 6.72168Z"
                            fill="#334D78"
                        />
                    </svg>
                </div>
            </div>

            {open && children}
        </div>
    );
};

export default FilterItemList;
