import React, { useCallback, useContext, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
    WHITE_LIST_APPLICATION_STATUSES,
    WHITE_LIST_APPLICATION_STATUSES_LABELS,
} from '../../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import { WhitelistActionsContext } from '../lib/WhitelistActionsContext';
import ActionComponent from './ActionComponent';
import { S3Context } from '../../../context/S3Context';

const statuses = {
    [WHITE_LIST_APPLICATION_STATUSES.WHITE]: 'white',
    [WHITE_LIST_APPLICATION_STATUSES.BOOKED]: '',
    [WHITE_LIST_APPLICATION_STATUSES.NEW]: 'wait',
    [WHITE_LIST_APPLICATION_STATUSES.MINTED]: 'info',
    [WHITE_LIST_APPLICATION_STATUSES.RED]: 'stop',
};

const WhithdrawItem = ({ data, id, collection, shouldBeRendered = false }) => {
    const {
        id: applicationId,
        status: statusP,
        avatar,
        user,
        metadata,
        wallet,
        time,
        inWork,
        tokenId,
        hide,
    } = data;

    const {
        actions: { getImageBacketPath },
    } = useContext(S3Context);

    const {
        actions: { takeInWorkHandler },
    } = useContext(WhitelistActionsContext);

    const status = useMemo(() => {
        if (tokenId) {
            return WHITE_LIST_APPLICATION_STATUSES.BOOKED;
        }

        return statusP;
    }, [tokenId, statusP]);

    const takeinWork = useCallback(() => {
        takeInWorkHandler({ isInWork: inWork, applicationId });
    }, [applicationId, inWork]);

    if (!shouldBeRendered) {
        return (
            <div className="white_list_item_plug">
                <Skeleton
                    containerClassName=".collection__item--skeleton"
                    className="skeleton__content"
                />
            </div>
        );
    }

    return (
        <div className="stats__item--values">
            <div className="stats__item--day stats__item--item stats__item--status withdraw__item--wrap off700 medium">
                <p className="stats__item--value">{id}</p>

                <div className={`stats__item--status--circle ${statuses[status]}`} />

                <p className="stats__item--status--value whithdraw__status">
                    {WHITE_LIST_APPLICATION_STATUSES_LABELS[status]}
                </p>
            </div>

            <div className="stats__item--day stats__item--item withdraw__item--wrap big">
                <p className="whithdraw__index--mobile">{id}</p>

                <div className="stats__item--avatar--inner withdraw__item">
                    {collection && collection.logo ? (
                        <img
                            src={getImageBacketPath(collection.logo)}
                            alt="avatar"
                            className="stats__item--avatar"
                        />
                    ) : (
                        <div className="whitelist__collection_logoPlug" />
                    )}
                </div>

                <div className="stats__item--wrapper withdraw__item">
                    <p className="withdraw__item--collection--name">
                        {collection ? collection.name : 'Unknown collection'}
                    </p>
                </div>
            </div>

            <div className="stats__item--item withdraw__item--wrap off998 activeoff1600 column">
                <div className="withdraw__item--text whitelist__scroll">{user.email}</div>
                <div className="withdraw__item--text whitelist__scroll">{user.discordName}</div>
            </div>

            <div className="stats__item--item withdraw__item--wrap off1200 activeoff1200">
                <p className="whitelist__meta whitelist__scroll">{metadata}</p>
            </div>

            <div className="stats__item--item stats__item--value withdraw__item--wrap whitelist__flex">
                <p className={`whithdraw__status--mobile ${statuses[status]}`}>
                    {WHITE_LIST_APPLICATION_STATUSES_LABELS[status]}
                </p>

                <div className="whitelist__address">{`${wallet.address.slice(
                    0,
                    5,
                )}...${wallet.address.slice(-5)}`}</div>

                <div className="whitelist__balance">{wallet.balance} ETH</div>
            </div>

            <div className="stats__item--item withdraw__item--text withdraw__item--wrap off1200 activeoff1600 center__item">
                {time}
            </div>

            <div className="stats__item--item withdraw__item--text withdraw__item--wrap off700 activeoff1600 center__item">
                <div
                    className={`whitelist__inwork${inWork ? ' active' : ''}`}
                    onClick={takeinWork}
                />
            </div>

            <div className="stats__item--item stats__item--value withdraw__item--wrap off998">
                <ActionComponent
                    id={applicationId}
                    isHidden={hide}
                    isBooked={tokenId}
                    status={status}
                />
            </div>
        </div>
    );
};

export default React.memo(WhithdrawItem);
