import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import CertificatesItemSkeleton from '../BrandsNew/components/CertificatesItemSkeleton';

const CollectionNewSkeletonPage = () => {
    return (
        <>
            <div className={styles.collectionCoverInner}>
                <Skeleton
                    containerClassName={styles.collectionBannerSkeletonWrapper}
                    className={styles.skeletonBrs0}
                />

                <div className={base.container}>
                    <Skeleton
                        containerClassName={styles.collectionImgSkeletonWrapper}
                        className={styles.skeletonBrs0}
                    />
                </div>
            </div>

            <section className={styles.collection}>
                <div className={base.container}>
                    <div className={styles.collectionInner}>
                        <div className={styles.collectionInfoInner}>
                            <div className={styles.collectionInfoWrapper}>
                                <Skeleton
                                    containerClassName={styles.collectionNameSkeletonWrapper}
                                    className={styles.skeletonBrs15}
                                />

                                <div className={styles.collectionInfoPoints}>
                                    <div className={styles.collectionInfoPoint}>
                                        <Skeleton
                                            containerClassName={styles.collectionPointNameSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                        <Skeleton
                                            containerClassName={styles.collectionPointValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <Skeleton
                                            containerClassName={styles.collectionPointNameSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                        <Skeleton
                                            containerClassName={styles.collectionPointValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <Skeleton
                                            containerClassName={styles.collectionPointNameSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                        <Skeleton
                                            containerClassName={styles.collectionPointValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <Skeleton
                                            containerClassName={styles.collectionPointNameSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                        <Skeleton
                                            containerClassName={styles.collectionPointValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>

                                <div className={styles.collectionDescSkeletonWrap}>
                                    <Skeleton
                                        containerClassName={styles.collectionDescNameSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.collectionDescMiddleNameSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.collectionDescSmallNameSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>

                            <div className={styles.collectionInfoSocial}>
                                <Skeleton
                                    containerClassName={styles.collectionSocialNameSkeletonWrapper}
                                    className={styles.skeletonBrs10}
                                />

                                <Skeleton
                                    containerClassName={styles.collectionButtonNameSkeletonWrapper}
                                    className={styles.skeletonBrs10}
                                />
                            </div>
                        </div>

                        <Skeleton
                            containerClassName={styles.collectionMoreSkeletonWrapper}
                            className={styles.skeletonBrs10}
                        />

                        <div className={styles.collectionFilterInner}>
                            <Skeleton
                                containerClassName={styles.collectionFilterNameSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />

                            <Skeleton
                                containerClassName={styles.collectionSearchNameSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />

                            <Skeleton
                                containerClassName={styles.collectionSortNameSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />

                            <Skeleton
                                containerClassName={styles.collectionTabsNameSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />
                        </div>

                        <div className={styles.collectionContent}>
                            <div
                                className={cn(styles.collectionFilter, styles.filterOpen, styles.filterOpenSkeleton)}
                            >
                                <div className={styles.collectionFilterItemSkeleton}>
                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>

                                <div className={styles.collectionFilterItemSkeleton}>
                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.collectionFilterSearchSkeletonWrapper}
                                        className={styles.skeletonBrs6}
                                    />

                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>

                                <div className={styles.collectionFilterItemSkeleton}>
                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>

                                <div className={styles.collectionFilterItemSkeleton}>
                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>

                                <div className={styles.collectionFilterItemSkeleton}>
                                    <div className={styles.collectionFilterPointSkeleton}>
                                        <Skeleton
                                            containerClassName={styles.collectionFilterTextSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionFilterValueSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.collectionContentInner}>
                                <div className={styles.collectionContentBar}>
                                    <div className={styles.collectionContentUpdateBar}>
                                        <Skeleton
                                            containerClassName={styles.collectionReloadButtonSkeletonWrapper}
                                            className={styles.skeletonBrs6}
                                        />

                                        <Skeleton
                                            containerClassName={styles.collectionReloadSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.collectionCountSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.collectionContentItems}>
                                    <CertificatesItemSkeleton filterOpen />
                                    <CertificatesItemSkeleton filterOpen />
                                    <CertificatesItemSkeleton filterOpen />
                                    <CertificatesItemSkeleton filterOpen />
                                    <CertificatesItemSkeleton filterOpen />
                                    <CertificatesItemSkeleton filterOpen />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CollectionNewSkeletonPage;
