import React from 'react';

import css from './index.module.css';

import ActionMenu from '../ActionMenu';
import TableCell from './TableCell';

const TableRow = ({
    id,
    names = false,
    size = false,
    differentColumn = false,
    actionType = 'default',
    children,
    handlerDeleteClick = () => {},
    handlerEditClick = () => {},
    handlerInWorkClick = () => {},
    handlerConfirmClick = () => {},
    handlerCancelClick = () => {},
    handleCopyPack = () => {},
    handleTransferToken = () => {},
    handleCancelSell = () => {},
    handleCancelTransfer = () => {},
    handleDownloadQr = () => {},
    handleDownloadQrCollection = () => {},
    handleDownloadExcelCollection = () => {},
    handleSetStatusWork = () => {},
    handleSetAppliedStatus = () => {},
    handleChangeQr = () => {},
    ...props
}) => {
    const actionsPool = {
        in_work: {
            text: 'В работе',
            action: handlerInWorkClick,
        },
        confirmed: {
            text: 'Подтверждено',
            action: handlerConfirmClick,
        },
        canceled: {
            text: 'Отменено',
            action: handlerCancelClick,
        },
        edit: {
            text: 'Редактировать',
            action: handlerEditClick,
        },
        delete: {
            text: 'Удалить',
            action: handlerDeleteClick,
        },
        copy: {
            text: 'Copy Wallet',
            action: () => {},
        },
        copyPack: {
            text: 'Скопировать',
            action: handleCopyPack,
        },
        nextStage: {
            text: 'Next stage',
            action: () => {},
        },
        publicMinting: {
            text: 'Public minting',
            action: () => {},
        },
        soldOut: {
            text: 'Sold out',
            action: () => {},
        },
        sold: {
            text: 'Sold',
            action: () => {},
        },
        transfer: {
            text: 'Transfer',
            action: () => {},
        },
        updateWL: {
            text: 'Update whitelist',
            action: () => {},
        },
        cancelBooked: {
            text: 'Cancel Booked',
            action: () => {},
        },
        reissue: {
            text: 'Reissue',
            action: () => {},
        },
        cancelTransfer: {
            text: 'Отменить передачу',
            action: handleCancelTransfer,
        },
        cancelSold: {
            text: 'Cancel Sold',
            action: () => {},
        },
        delivery: {
            text: 'Delivery',
            action: () => {},
        },
        shipped: {
            text: 'Shipped',
            action: () => {},
        },
        troubles: {
            text: 'Troubles',
            action: () => {},
        },
        downloadQR: {
            text: 'Download QRs',
            action: () => {},
        },
        progress: {
            text: 'Progress',
            action: () => {},
        },
        waiting: {
            text: 'Waiting',
            action: () => {},
        },
        printed: {
            text: 'Printed',
            action: () => {},
        },
        editLink: {
            text: 'Edit link',
            action: () => {},
        },
        select: {
            text: 'Select',
            action: () => {},
        },
        transfer: {
            text: 'Передать',
            action: handleTransferToken,
        },
        cancelSell: {
            text: 'Отменить продажу',
            action: handleCancelSell,
        },
        downloadQr: {
            text: 'Скачать QRы',
            action: handleDownloadQr,
        },
        factoryCollection: {
            text: 'Скачать QRы',
            action: handleDownloadQrCollection,
        },
        factoryCollectionExcel: {
            text: 'Скачать таблицу',
            action: handleDownloadExcelCollection,
        },
        handleSetInWork: {
            text: 'В работу',
            action: handleSetStatusWork,
        },
        handleSetApplied: {
            text: 'Нанесено',
            action: handleSetAppliedStatus,
        },
        handleChangeQrLink: {
            text: 'Изменить',
            action: handleChangeQr,
        },
    };
    const actions = {
        none: [],
        default: [
            actionsPool['edit'],
            actionsPool['delete'],
            actionsPool['copy'],
            actionsPool['select'],
        ],
        stat: [actionsPool['edit'], actionsPool['delete']],
        statPacks: [actionsPool['edit'], actionsPool['delete'], actionsPool['copyPack']],
        whitelisting: [
            actionsPool['nextStage'],
            actionsPool['publicMinting'],
            actionsPool['soldOut'],
            actionsPool['edit'],
            actionsPool['delete'],
        ],
        booking: [
            actionsPool['nextStage'],
            actionsPool['soldOut'],
            actionsPool['edit'],
            actionsPool['delete'],
        ],
        private: [
            actionsPool['nextStage'],
            actionsPool['soldOut'],
            actionsPool['edit'],
            actionsPool['delete'],
        ],
        public: [actionsPool['nextStage'], actionsPool['edit'], actionsPool['delete']],
        soldout: [
            actionsPool['nextStage'],
            actionsPool['publicMinting'],
            actionsPool['edit'],
            actionsPool['delete'],
        ],
        tokenListed: [
            actionsPool['sold'],
            actionsPool['transfer'],
            actionsPool['edit'],
            actionsPool['delete'],
            actionsPool['updateWL'],
        ],
        tokenNoPrice: [
            actionsPool['sold'],
            actionsPool['transfer'],
            actionsPool['edit'],
            actionsPool['delete'],
            actionsPool['updateWL'],
        ],
        tokenBooked: [
            actionsPool['cancelBooked'],
            actionsPool['transfer'],
            actionsPool['edit'],
            actionsPool['updateWL'],
        ],
        tokenMinted: [actionsPool['edit'], actionsPool['reissue'], actionsPool['updateWL']],
        tokenTransfer: [
            actionsPool['cancelTransfer'],
            actionsPool['edit'],
            actionsPool['updateWL'],
        ],
        tokenSold: [actionsPool['cancelSold'], actionsPool['edit'], actionsPool['updateWL']],
        deliveryRequest: [actionsPool['delivery'], actionsPool['shipped'], actionsPool['troubles']],
        delivery: [actionsPool['shipped'], actionsPool['troubles']],
        deliveryShipped: [actionsPool['troubles']],
        deliveryHanded: [actionsPool['troubles']],
        deliveryTroubles: [actionsPool['delivery'], actionsPool['shipped']],
        factory: [actionsPool['downloadQR'], actionsPool['progress'], actionsPool['printed']],
        factoryProgress: [
            actionsPool['downloadQR'],
            actionsPool['waiting'],
            actionsPool['printed'],
        ],
        factoryPrinted: [
            actionsPool['downloadQR'],
            actionsPool['waiting'],
            actionsPool['progress'],
        ],
        factoryMixed: [
            actionsPool['downloadQR'],
            actionsPool['waiting'],
            actionsPool['progress'],
            actionsPool['printed'],
        ],
        factoryToken: [actionsPool['downloadQR'], actionsPool['progress'], actionsPool['printed']],
        factoryTokenProgress: [
            actionsPool['downloadQR'],
            actionsPool['waiting'],
            actionsPool['printed'],
        ],
        factoryTokenPrinted: [
            actionsPool['downloadQR'],
            actionsPool['waiting'],
            actionsPool['progress'],
        ],
        storeListed: [actionsPool['sold'], actionsPool['transfer']],
        storeMinted: [actionsPool['transfer'], actionsPool['reissue']],
        storeTransfer: [actionsPool['cancelTransfer'], actionsPool['sold']],
        storeSold: [actionsPool['cancelSold'], actionsPool['transfer']],
        storeBooked: [actionsPool['cancelBooked'], actionsPool['transfer']],
        tokensAdmin: [actionsPool['in_work'], actionsPool['confirmed'], actionsPool['canceled']],
        inWorkStatus: [actionsPool['confirmed'], actionsPool['canceled']],

        statTokenAdmin: [
            actionsPool['edit'],
            actionsPool['delete'],
            actionsPool['transfer'],
            actionsPool['cancelTransfer'],
            // actionsPool['cancelSell']
        ],
        statTokenModer: [
            // actionsPool['edit'],
            // actionsPool['delete'],
            actionsPool['transfer'],
            actionsPool['cancelTransfer'],
            // actionsPool['cancelSell']
        ],

        factoryPacks: [actionsPool['downloadQr']],
        factoryTokens: [
            actionsPool['downloadQr'],
            actionsPool['handleSetInWork'],
            actionsPool['handleSetApplied'],
            actionsPool['handleChangeQrLink'],
        ],
        factoryCollection: [
            actionsPool['factoryCollection'],
            actionsPool['factoryCollectionExcel'],
        ],
    };

    return (
        <div
            className={`${names ? css.tableItemNames : css.tableItem} ${
                size == 'medium' ? css.medium : size == 'small' ? css.small : ''
            } ${differentColumn ? css.differentColumn : ''}`}
            {...props}
        >
            {children}

            {names ? (
                <TableCell title>Действие</TableCell>
            ) : (
                <div className={css.tableAction}>
                    {actionType && <ActionMenu actions={actions[actionType]} id={id} />}
                </div>
            )}
        </div>
    );
};

export default TableRow;
