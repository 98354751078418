exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DescriptionBlock_descriptionBlock__2eWWx{\n    border: 1px solid rgba(255, 255, 255, 0.17);\n    border-radius: 10px;\n    padding: 2.5rem 1.2rem 2.8rem 2.5rem;\n}\n\n@media (max-width: 480px) {\n    .DescriptionBlock_descriptionBlock__2eWWx{\n        padding: 2.5rem 1.3rem 2.6rem 1.9rem;\n    }\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z{\n    width: 100%;\n    width: 100%;\n    max-height: 250px;\n    overflow-y: auto;\n    padding-right: 3.2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    grid-gap: 20px;\n    gap: 20px;\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z::-webkit-scrollbar{\n    width: 4px;\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z::-webkit-scrollbar-thumb{\n    background: #2081E2;\n}\n\n@media (max-width: 480px) {\n    .DescriptionBlock_descriptionBlockWrapper__10U9z{\n        padding-right: 0.9rem;\n    }\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z p{\n    color: rgba(229, 232, 235, 0.8);\n    font-size: 1.5rem;\n    font-weight: 300;\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z ol{\n    padding-left: 2rem;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin: 0;\n}\n\n.DescriptionBlock_descriptionBlockWrapper__10U9z ol li{\n    list-style-type: decimal;\n    font-size: 1.5rem;\n    font-weight: 300;\n    color: rgba(229, 232, 235, 0.8);\n}", ""]);

// exports
exports.locals = {
	"descriptionBlock": "DescriptionBlock_descriptionBlock__2eWWx",
	"descriptionBlockWrapper": "DescriptionBlock_descriptionBlockWrapper__10U9z"
};