import React from 'react';

import './index.css';

import { CustomSelect } from '../../common/CustomSelect';
import FilterItem from '../../components/FilterItem';
import SortItem from '../../components/SortItem';
import WithdrawItem from './WhithdrawItem';
import WhithdrawSkeleton from './WhithdrawSkeleton';
import InfoBlock from '../../common/InfoBlock';
import FullDescription from '../../common/FullDescription';
import CollectionInfo from '../../common/CollectionInfo';

const sortList = [
    { value: 'price', name: 'Price low to high' },
    { value: 'recently', name: 'Recently listed' },
    { value: 'recentlyBook', name: 'Recently booked' },
    { value: 'recentlyMint', name: 'Recently minted' },
    { value: 'rare', name: 'Most rare' },
    { value: 'soon', name: 'Ending soon' },
];

const data = [
    {
        status: 'Ready',
        avatar: '/assets/img/avatar.png',
        collection: {
            name: 'Collection name',
            token: 'Token name'
        },
        accounted: '0.00',
        withdrawn: '0.00',
        user: 'User name',
        wallet: '0x5218C...F96Ff3',
        last: '5 hours ago'
    },
    {
        status: 'Waiting',
        avatar: '/assets/img/avatar.png',
        collection: {
            name: 'Collection name',
            token: 'Token name'
        },
        accounted: '0.00',
        withdrawn: '0.00',
        user: 'User name',
        wallet: '0x5218C...F96Ff3',
        last: '5 hours ago'
    },
    {
        status: 'Stoped',
        avatar: '/assets/img/avatar.png',
        collection: {
            name: 'Collection name',
            token: 'Token name'
        },
        accounted: '0.00',
        withdrawn: '0.00',
        user: 'User name',
        wallet: '0x5218C...F96Ff3',
        last: '5 hours ago'
    }
];

const Whithdraw = () => {
    const [fakeLoading, setFakeLoading] = React.useState(true);

    React.useEffect(() => {
        const fakeTimer = setTimeout(() => {
            setFakeLoading(false);
        }, 2000);

        return () => clearTimeout(fakeTimer);
    }, []);

    const [filter, setFilter] = React.useState('price');
    const [filterActive, setFilterActive] = React.useState(false);
    const [sortActive, setSortActive] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            text: 'Ready',
            active: false,
        },
        {
            text: 'Waiting',
            active: false,
        },
        {
            text: 'Stoped',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            text: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const changeSearch = (e) => {
        setSearch(e.target.value);
    };

    const emptySearch = () => {
        setSearch('');
    };

    const filterChange = (value) => {
        setFilter(value);
    };

    if(fakeLoading){
        return <WhithdrawSkeleton />
    }

    return(
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <h2 className="title collection__title">Withdrawal Funds</h2>

                    <InfoBlock data={[
                        {
                            title: "total",
                            value: "0 ETH"
                        },
                        {
                            title: "withdrawn",
                            value: "0 ETH"
                        },
                        {
                            title: "accounted",
                            value: "0 ETH"
                        }
                    ]} />

                    <FullDescription description="To withdraw investor fee, connect wallet. In the table will be displayed all minted by you NFTs and information on accruals. 1. Click the “Withdraw” button; 2. Confirm; 3. Check the receipt of funds. In case of withdrawal problems, contact Discord support." />

                    <div className="collection__filter--content">
                        <button className="button collection__filter--button" onClick={() => setFilterActive((prev) => !prev)}>
                            <img src="/assets/img/filter.svg" alt="filter" className="collection__filter--icon" />

                            <img src="/assets/img/filter2.svg" alt="filter" className="collection__filter--icon mobile" />

                            <p className="collection__filter--button--text">Filters</p>
                        </button>

                        <button className="button collection__sort--button" onClick={() => setSortActive((prev) => !prev)}>
                            <img src="/assets/img/sort.svg" alt="sort" className="collection__filter--icon mobile" />

                            <p className="collection__sort--button--text">Sort</p>
                        </button>

                        <div className="collection__search--inner">
                            <input type="text" className="input header__search" placeholder="Search by token name" value={search} onChange={changeSearch} />

                            <img src="/assets/img/search.svg" alt="search" className="header__search--icon" />

                            {search && (
                                <img src="/assets/img/cross2.svg" alt="cross" className="header__search--remove" onClick={emptySearch} />
                            )}
                        </div>

                        <div className="collection__filter--order">
                            <CustomSelect
                                optionsList={sortList}
                                value={filter}
                                placeholder="Select filter"
                                onChange={filterChange}
                            />
                        </div>
                    </div>

                    <div className="collection__content">
                        {filterActive && (
                            <div className="collection__filter--box">
                                <div className="collection__filter--title--box">
                                    <p className="collection__filter--title">Filters</p>

                                    <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--title--cross" onClick={() => setFilterActive((prev) => !prev)} />
                                </div>

                                <FilterItem
                                    title="Status"
                                    value="2"
                                    elements={filterData}
                                    setData={setFilterData}
                                    filters={filters}
                                    setFilters={setFilters}
                                />

                                <FilterItem
                                    title="Character 1"
                                    value="3"
                                    elements={filterData2}
                                    setData={setFilterData2}
                                    setFilters={setFilters}
                                    filter
                                    filters={filters}
                                />

                                <div className="collection__filter--buttons">
                                    <button className="button collection__filter--button--filter">
                                        Clear all
                                    </button>

                                    <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive((prev) => !prev)}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        )}

                        {sortActive && (
                            <div className="collection__sort--box">
                                <div className="collection__filter--title--box">
                                    <p className="collection__filter--title">Sort by</p>

                                    <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--title--cross" onClick={() => setSortActive((prev) => !prev)} />
                                </div>

                                <div className="collection__sort--content">
                                    {sortList.map((data, id) => (
                                        <SortItem
                                            key={id}
                                            text={data.name}
                                            id={`${data.value}_${id}`}
                                        />
                                    ))}
                                </div>

                                <div className="collection__filter--buttons">
                                    <button className="button collection__sort--button--filter blue__button" onClick={() => setSortActive((prev) => !prev)}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="collection__content--preitems">
                            <CollectionInfo tokensCount="316 items" />

                            <div className="collection__filter--active">
                                <div className="collection__filter--active--content">
                                    <button className="button collection__filter--active--item">
                                        <p className="collection__filter--active--item--text">
                                            Ready
                                        </p>

                                        <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--active--item--delete" />
                                    </button>
                                </div>

                                <button className="button collection__filter--clear">
                                    Clear All
                                </button>
                            </div>

                            <div className="collection__content--items">
                                {false ? <div className="collection__items--none">
                                        <img src="/assets/img/items-none.svg" alt="none" />

                                        No items to display
                                    </div>
                                :
                                <div className={`stats__content${filterActive ? " active" : ""}`}>
                                    <div className="stats__item--names whithdraw__names">
                                        <div className="stats__item--day whithdraw__item--item stats__item--name off700">
                                            Status
                                        </div>

                                        <div className="stats__item--volume whithdraw__item--item stats__item--name big">
                                            Token
                                        </div>

                                        <div className="stats__item--day whithdraw__item--item stats__item--name">
                                            Accounted
                                        </div>

                                        <div className="stats__item--week whithdraw__item--item stats__item--name off700 activeoff1200">
                                            Withdrawn
                                        </div>

                                        <div className="stats__item--price whithdraw__item--item stats__item--name off998 activeoff1600">
                                            User
                                        </div>

                                        <div className="stats__item--owners whithdraw__item--item stats__item--name off1200 activeoff1600">
                                            Wallet Address
                                        </div>

                                        <div className="stats__item--items whithdraw__item--item stats__item--name off1200 activeoff1600">
                                            Last Withdraw
                                        </div>

                                        <div className="stats__item--items whithdraw__item--item stats__item--name off998">
                                            Action
                                        </div>
                                    </div>

                                    {data.map((data, id) => <WithdrawItem key={id} data={data} id={id + 1} />)}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whithdraw;