import React from 'react';

const Clothes = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.73666 1.29775C7.82087 1.26618 7.91007 1.25 8 1.25H16C16.0899 1.25 16.1791 1.26618 16.2633 1.29775L16 2C16.2633 1.29775 16.2632 1.29771 16.2633 1.29775L16.2665 1.29893L16.2725 1.30121L16.293 1.30909C16.3104 1.3158 16.3351 1.32542 16.3664 1.3379C16.4291 1.36286 16.5185 1.39929 16.6298 1.4467C16.8522 1.54142 17.1632 1.68059 17.5229 1.86043C18.2362 2.21709 19.1679 2.74698 19.9801 3.42383C21.585 4.76119 22.75 6.70366 22.75 8.5V18.5C22.75 18.9142 22.4142 19.25 22 19.25H18.5C18.0858 19.25 17.75 18.9142 17.75 18.5C17.75 18.0858 18.0858 17.75 18.5 17.75H21.25V8.5C21.25 7.29634 20.415 5.73881 19.0199 4.57617C18.3321 4.00302 17.5138 3.53291 16.8521 3.20207C16.5243 3.03816 16.2415 2.9117 16.042 2.82674C15.9683 2.79535 15.9062 2.7697 15.8576 2.75H8.14244C8.0938 2.7697 8.03166 2.79535 7.95796 2.82674C7.75847 2.9117 7.47572 3.03816 7.14791 3.20207C6.48622 3.53291 5.66791 4.00302 4.98014 4.57617C3.58497 5.73881 2.75 7.29634 2.75 8.5V17.75H5.5C5.91421 17.75 6.25 18.0858 6.25 18.5C6.25 18.9142 5.91421 19.25 5.5 19.25H2C1.58579 19.25 1.25 18.9142 1.25 18.5V8.5C1.25 6.70366 2.41503 4.76119 4.01986 3.42383C4.83209 2.74698 5.76378 2.21709 6.47709 1.86043C6.83678 1.68059 7.14778 1.54142 7.37017 1.4467C7.48148 1.39929 7.57095 1.36286 7.63361 1.3379C7.66495 1.32542 7.68961 1.3158 7.70699 1.30909L7.72751 1.30121L7.73353 1.29893L7.73666 1.29775C7.73678 1.29771 7.73666 1.29775 8 2L7.73666 1.29775Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 7.75C5.91421 7.75 6.25 8.08579 6.25 8.5V21.25H17.75V8.5C17.75 8.08579 18.0858 7.75 18.5 7.75C18.9142 7.75 19.25 8.08579 19.25 8.5V22C19.25 22.4142 18.9142 22.75 18.5 22.75H5.5C5.08579 22.75 4.75 22.4142 4.75 22V8.5C4.75 8.08579 5.08579 7.75 5.5 7.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V8.5C11.25 8.08579 11.5858 7.75 12 7.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.60703 1.36136C7.9598 1.14427 8.42176 1.25426 8.63885 1.60703L12.6388 8.10703C12.8559 8.4598 12.7459 8.92176 12.3932 9.13885C12.0404 9.35594 11.5784 9.24595 11.3614 8.89318L7.36136 2.39318C7.14427 2.04041 7.25426 1.57845 7.60703 1.36136Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3932 1.36136C16.7459 1.57845 16.8559 2.04041 16.6388 2.39318L12.6388 8.89318C12.4218 9.24595 11.9598 9.35594 11.607 9.13885C11.2543 8.92176 11.1443 8.4598 11.3614 8.10703L15.3614 1.60703C15.5784 1.25426 16.0404 1.14427 16.3932 1.36136Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Clothes;
