import React, {useState, useEffect, useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.css';

import {connectWallet, logout, getShortAddress} from '../../functions/auth';
import {copyText} from '../../functions/utils';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import { useGetTotalBalanceQuery } from '../../redux/api/billingService';
import { СonclusionMenu } from './components/СonclusionMenu/СonclusionMenu';
import { Tooltip } from '../../pages/TokenCard/modals/TokenActionsModal/components/Tooltip';
import { useClickOut } from '../../hooks/useClickout';

const WalletMenu = ({active, setActive, setIsConditionModalOpen}) => {
    const [profileMenu, setProfileMenu] = useState(false);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isAuth)

    const [isOpenMenu, setIsOpenMenu] = useState(false)

    const ref = useClickOut(() => setActive(false))

    const {data} = useGetTotalBalanceQuery({}, {skip: !isAuth})

    const connect = () => {
        connectWallet(dispatch);
    }

    const quit = async () => {
        await logout(auth.accessToken, dispatch);
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const div = document.getElementById('wallet__social__modal');
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setProfileMenu(false);
            }
        });
    }, []);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const copyAddress = () => {
        copyText(auth.walletAddress);
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
    }

    return(
        <div className={`wallet__menu${active ? ' active' : ''}`} ref={ref}>
            <img src="/assets/img/cross2.svg" alt="cross" className="wallet__menu--close" onClick={() => setActive(false)} />

            <div className="wallet__menu--content">
                {auth.isAuth
                ? <div className="wallet__menu--auth">
                    <div className="wallet__menu--box">
                        <div className="wallet__menu--back--inner">
                            <div className="wallet__menu--back" onClick={() => setActive(false)}>
                                <img src="/assets/img/back.svg" alt="back" />
                            </div>

                            <div className="wallet__menu--user--inner" id="wallet__social__modal" onClick={() => setProfileMenu(prev => !prev)}>
                                <div className="wallet__menu--user--img--inner">
                                    {auth.image && <img src={auth.image} alt="user" className="wallet__menu--user--img" />}
                                </div>

                                <div className="wallet__menu--profile">
                                    <p className="wallet__menu--user--name">
                                        {auth.username}
                                    </p>

                                    <img src="/assets/img/arrow-select.svg" alt="arrow" />
                                </div>

                                {profileMenu && <div className="wallet__profile--menu">
                                    <div className="wallet__profile--menu--item">
                                        <div className="wallet__profile--menu--wrap">
                                            <img src="/assets/img/trust.svg" alt="metamask" />

                                            Trust Wallet
                                        </div>

                                        <img src="/assets/img/check2.svg" alt="check" />
                                    </div>

                                    <div className="wallet__profile--menu--item" onClick={quit}>
                                        <div className="wallet__profile--menu--wrap">
                                            <img src="/assets/img/logout.svg" alt="logout" />

                                            Выход
                                        </div>
                                    </div>

                                    {/*<div className="wallet__profile--menu--item">*/}
                                    {/*    <div className="wallet__profile--menu--wrap">*/}
                                    {/*        <img src="/assets/img/refresh2.svg" alt="refresh" />*/}

                                    {/*        Refresh funds*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>}
                            </div>
                        </div>

                        <p className="wallet__menu--address" onClick={copyAddress}>
                            {getShortAddress(auth.walletAddress)}
                        </p>
                    </div>

                    <div className="wallet__menu--balance--inner">
                        <div className="wallet__menu--balance">
                            <p
                                className="wallet__menu--balance--text"
                                style={{
                                    display: 'flex',
                                    gap: '8px'
                                }}
                            >
                                Общий баланс
                                <Tooltip
                                    styleText={{
                                        top: '30px',
                                        right: '-100px'
                                    }}
                                    text={'Общий баланс = Стоимость купленных сертификатов пользователя + (Начислено - Выведено). '}
                                />
                            </p>

                            <p className="wallet__menu--balance--value">
                                {data && data.total_balance ? Math.floor(data.total_balance / 100).toLocaleString('ru-RU') : 0} RUB
                            </p>
                        </div>
                    </div>

                    <div className="wallet__menu--crypto--content">
                        <div className="wallet__menu--crypto--item">
                            <img src="/assets/img/rub2.svg" alt="eth" className="wallet__menu--crypto--icon" />

                            <div className="wallet__menu--crypto--wrap">
                                <div className="wallet__menu--crypto--wrap--item">
                                    <p className="wallet__menu--crypto--title">
                                        RUB
                                    </p>

                                    <p className="wallet__menu--crypto--title">
                                        {Math.floor(data && data.total_accrued / 100).toLocaleString('ru-RU')}
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <img
                                    src="/assets/img/dots-vertical.svg"
                                    alt="dots"
                                    onClick={() => setIsOpenMenu(true)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px'
                                    }}
                                />
                                {isOpenMenu &&
                                    <СonclusionMenu
                                        setIsConditionModalOpen={setIsConditionModalOpen}
                                        setIsOpenMenu={setIsOpenMenu}
                                    />
                                }
                            </div>
                        </div>
                        {/*<div className="wallet__menu--crypto--item">*/}
                        {/*    <img src="/assets/img/eth-token.svg" alt="eth" className="wallet__menu--crypto--icon" />*/}

                        {/*    <div className="wallet__menu--crypto--wrap">*/}
                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                ETH*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                0.0000*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                Ethereum*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                $ 0.00 USD*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <img src="/assets/img/dots-vertical.svg" alt="dots" />*/}
                        {/*</div>*/}

                        {/*<div className="wallet__menu--crypto--item">*/}
                        {/*    <img src="/assets/img/bnb2.svg" alt="bnb" className="wallet__menu--crypto--icon" />*/}

                        {/*    <div className="wallet__menu--crypto--wrap">*/}
                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                BNB*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                0.0000*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                BNB Chain*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                $ 0.00 USD*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <img src="/assets/img/dots-vertical.svg" alt="dots" />*/}
                        {/*</div>*/}

                        {/*<div className="wallet__menu--crypto--item">*/}
                        {/*    <img src="/assets/img/bnb2.svg" alt="bnb" className="wallet__menu--crypto--icon" />*/}

                        {/*    <div className="wallet__menu--crypto--wrap">*/}
                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                CBCOIN*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                0.0000*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                BNB Chain*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                $ 0.00 USD*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <img src="/assets/img/dots-vertical.svg" alt="dots" />*/}
                        {/*</div>*/}

                        {/*<div className="wallet__menu--crypto--item">*/}
                        {/*    <img src="/assets/img/rub2.svg" alt="rub2" className="wallet__menu--crypto--icon" />*/}

                        {/*    <div className="wallet__menu--crypto--wrap">*/}
                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                RUB*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--title">*/}
                        {/*                0.0000*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <div className="wallet__menu--crypto--wrap--item">*/}
                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                Fiat money*/}
                        {/*            </p>*/}

                        {/*            <p className="wallet__menu--crypto--text">*/}
                        {/*                $ 0.00 USD*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <img src="/assets/img/dots-vertical.svg" alt="dots" />*/}
                        {/*</div>*/}
                    </div>
                </div>
                : <div className="wallet__menu--noauth">
                    <div className="wallet__menu--box">
                        <div className="wallet__menu--back--inner" onClick={() => setActive(false)}>
                            <div className="wallet__menu--back">
                                <img src="/assets/img/back.svg" alt="back" />
                            </div>

                            <div className="wallet__menu--user--inner">
                                <div className="wallet__menu--user--img--inner">
                                    <img src={auth.image ? auth.image : `/assets/img/wallet2.svg`} alt="user" className="wallet__menu--user--img" />
                                </div>

                                <p className="wallet__menu--user--name">
                                    Цифровой кошелек
                                </p>
                            </div>
                        </div>
                    </div>

                    <p className="wallet__text">
                        Подключите свой{' '}
                        <a
                            className="blue"
                            href={'https://trustwallet.com/'}
                            target={'_blank'}
                        >
                            кошелёк
                        </a>{' '}
                        для доступа к сайту либо скачайте и установите новый
                    </p>

                    <div className="wallet__items">
                        {/*<div className="wallet__item">*/}
                        {/*    <div className="wallet__item--wrap">*/}
                        {/*        <img src="/assets/img/metamask.svg" alt="metamask" className="wallet__item--icon" />*/}

                        {/*        MetaMask*/}
                        {/*    </div>*/}

                        {/*    /!*<button className="button wallet__item--connect" onClick={connect}>*!/*/}
                        {/*    /!*    Connect*!/*/}
                        {/*    /!*</button>*!/*/}
                        {/*</div>*/}

                        <div className="wallet__item">
                            <div className="wallet__item--wrap">
                                <img src="/assets/img/trust.svg" alt="trust" className="wallet__item--icon" />

                                Trust кошелёк
                            </div>

                            <button className="button wallet__item--connect" onClick={connect}>
                                Подключить
                            </button>
                        </div>
                        <a
                            href={'https://trustwallet.com/'}
                            target={'_blank'}
                            className={'link'}
                        >
                            Скачать цифровой кошелёк Wallet Trust
                        </a>

                        {/*<div className="wallet__item">*/}
                        {/*    <div className="wallet__item--wrap">*/}
                        {/*        <img src="/assets/img/coinbase.svg" alt="coinbase" className="wallet__item--icon" />*/}

                        {/*        Coinbase Wallet*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="wallet__item">*/}
                        {/*    <div className="wallet__item--wrap">*/}
                        {/*        <img src="/assets/img/walletconnect.svg" alt="walletconnect" className="wallet__item--icon" />*/}

                        {/*        WalletConnect*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="wallet__item">*/}
                        {/*    <div className="wallet__item--wrap">*/}
                        {/*        <img src="/assets/img/fantom.svg" alt="fantom" className="wallet__item--icon" />*/}

                        {/*        Phantom*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default WalletMenu;
