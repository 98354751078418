import React, { memo, useMemo, useCallback, useState } from 'react';
import { UserResources } from './UserResources';
import { Button } from '@mui/material';

const translateNameVariants = {
    [`Moderator Store`]: 'Модератор магазина',
    [`User`]: 'Пользователь',
    [`Moderator Factory`]: 'Модератор Фабрики',
    [`Moderator`]: 'Модератор',
    [`Moderator Income`]: 'Модератор вывода',
};
export const roleVariants = [
    {
        name: 'Админ',
        title: 'Admin',
        value: 2,
    },
    {
        name: 'Модератор магазина',
        title: 'Moderator_Store',
        value: 9,
    },
    {
        name: 'Модератор фабрики',
        title: 'Moderator_Fabric',
        value: 10,
    },
    {
        name: 'Модератор вывода',
        title: 'Moderator',
        value: 15,
    },
];

export const UserRolesAccess = memo(({ userData, setRole }) => {
    const handleSelectRole = (roleNumber) => {
        setRole((prev) => ({ ...prev, roleNumber }));
    };
    const [selectedRole, setSelectedRole] = useState(null);
    const availibleRoles = useMemo(() => {
        if (!userData.length) return roleVariants;
        const existingRoles = new Set(
            userData.map((access) => String(access.role.name).toLowerCase()),
        );
        return roleVariants.filter(
            (variant) => !existingRoles.has(String(variant.title).toLowerCase()),
        );
    }, [roleVariants, userData]);

    const handleSelectAccess = (roleName) => {
        const isValueSame = roleName === selectedRole;
        setSelectedRole(isValueSame ? null : roleName);
    };

    return (
        <div className="user__roles">
            {userData.length > 0 &&
                userData.map((access, index) => {
                    // const isAccessExist = access.access.length > 0;
                    return (
                        // isAccessExist > 0 && (
                            <section
                                key={index + Math.random() + Math.PI}
                                style={{
                                    maxWidth: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '10px',
                                    width: '100%',
                                    position: 'relative',
                                }}
                            >
                                {selectedRole && (
                                    <UserResources
                                        key={Date.now() + Math.random()}
                                        list={access.access}
                                        selectedRole={selectedRole}
                                        currentRole={access.role.name}
                                        // roleUserId={access.access.role_user_id}
                                    />
                                )}

                                <Button
                                    color="primary"
                                    key={index}
                                    variant="contained"
                                    onClick={() => handleSelectAccess(access.role.name)}
                                    sx={{ width: '100%' }}
                                >
                                    {translateNameVariants[access.role.public_name]}
                                </Button>
                            </section>
                        )
                    // );
                })}

            {availibleRoles.map((role, index) => {
                return (
                    <Button
                        color="secondary"
                        key={index}
                        variant="contained"
                        onClick={() => handleSelectRole(role.value)}
                    >
                        {role.name} +
                    </Button>
                );
            })}
        </div>
    );
});
