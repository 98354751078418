import React from 'react';

const WhatsApp = ({ ...props }) => {
    return (
        <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.85 3.01875C15.8813 1.05 13.2562 0 10.5 0C4.725 0 0 4.725 0 10.5C0 12.3375 0.525008 14.175 1.44376 15.75L0 21L5.51252 19.5562C7.08752 20.3438 8.79375 20.8687 10.5 20.8687C16.275 20.8687 21 16.1437 21 10.3687C21 7.6125 19.8188 4.9875 17.85 3.01875ZM10.5 19.1625C8.925 19.1625 7.35001 18.7688 6.03751 17.9813L5.77499 17.85L2.49374 18.7687L3.41251 15.6187L3.14999 15.225C2.23124 13.7812 1.83749 12.2063 1.83749 10.6313C1.83749 5.90625 5.775 1.96875 10.5 1.96875C12.8625 1.96875 14.9625 2.8875 16.6688 4.4625C18.375 6.16875 19.1625 8.26875 19.1625 10.6313C19.1625 15.225 15.3562 19.1625 10.5 19.1625ZM15.225 12.6C14.9625 12.4688 13.65 11.8125 13.3875 11.8125C13.125 11.6812 12.9937 11.6813 12.8625 11.9438C12.7312 12.2063 12.2063 12.7312 12.075 12.9937C11.9438 13.125 11.8125 13.125 11.55 13.125C11.2875 12.9937 10.5 12.7312 9.45002 11.8125C8.66252 11.1562 8.13751 10.2375 8.00626 9.975C7.87501 9.7125 8.00627 9.58125 8.13752 9.45C8.26877 9.31875 8.4 9.1875 8.53125 9.05625C8.6625 8.925 8.66252 8.79375 8.79377 8.6625C8.92502 8.53125 8.79377 8.4 8.79377 8.26875C8.79377 8.1375 8.26876 6.825 8.00626 6.3C7.87501 5.90625 7.61252 5.90625 7.48127 5.90625C7.35002 5.90625 7.21873 5.90625 6.95623 5.90625C6.82498 5.90625 6.56248 5.90625 6.29998 6.16875C6.03748 6.43125 5.38126 7.0875 5.38126 8.4C5.38126 9.7125 6.29999 10.8938 6.43124 11.1562C6.56249 11.2875 8.26873 14.0437 10.8937 15.0938C13.125 16.0125 13.5188 15.75 14.0438 15.75C14.5688 15.75 15.6187 15.0938 15.75 14.5688C16.0125 13.9125 16.0125 13.3875 15.8813 13.3875C15.75 12.7313 15.4875 12.7313 15.225 12.6Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default WhatsApp;
