import React, {useEffect, useState} from "react";
import styles from './index.module.css';
import {OfferItem} from "../OfferItem/OfferItem";
import {
  useGetMyTokensQuery,
  useLazyGetAllServicesQuery,
  useUsedServiceListQuery
} from "../../../../redux/api/dataService";
import { useSelector } from 'react-redux';

export const PatnerOffer = ({setPartnerOffers, partnerOffers, token, collection}) => {
  const [data, setData] = useState([])
  const [usedItem, setUsedItem] = useState(null)
  const [certificateType, setCertificateType] = useState('')

  const isAuth = useSelector(state => state.auth.isAuth)

  const [getServices] = useLazyGetAllServicesQuery()

  const {data: dataUsedServices, refetch} = useUsedServiceListQuery({}, {skip: !isAuth})
  const { data: dataMyTokens } = useGetMyTokensQuery(
    {
      page: 1,
      pageSize: 1000,
    },
    {
      skip: !isAuth,
    },
  );

  useEffect(() => {
    if(dataUsedServices && dataUsedServices.length) {
      setUsedItem(dataUsedServices)
    }
  }, [dataUsedServices])


  useEffect(() => {
    if(token && token.properties) {
     const foundType = token.properties.find(el => el.type === 'Status')
      setCertificateType(foundType.name)
    }
  },[token])

  useEffect(() => {
    if(token && certificateType) {
      getServices({
        certificate_type: certificateType ? certificateType.toLowerCase() : '',
        collection_id: token.collection.id,
      }).unwrap().then((res) =>  setData(res.results))
    }

  }, [token, certificateType])


  return (
    <div className="token__card--wrap full mobileoff">
      <div className="token__card--item">
        <div
          onClick={() => setPartnerOffers(prev => !prev)}
          className="token__card--item--top"
          style={{cursor: 'pointer'}}
        >
          <span className="token__card--item--top--title--inner">
            <img
              src="/assets/img/about2.svg"
              alt="about"
              className="token__card--item--top--title--icon"
            />

            <p className="token__card--item--top--title">
              Предложения партнёров
            </p>
          </span>
          <img
            src="/assets/img/arrow-select.svg"
            alt="arrow"
            className={`token__card--item--open${
              partnerOffers ? ' active' : ''
            }`}
          />
        </div>

        {partnerOffers && (
          <div className={styles.wpapper}>
            <div className={styles.wrapper__text}>
              <p className={styles.text}>КОМПАНИЯ</p>
              <p className={styles.text}>УСЛУГА</p>
            </div>
            <div className={styles.offersContainer}>
              {data.length > 0 && data.map(item => <OfferItem item={item} token={token} usedItem={usedItem} refetch={refetch} dataMyTokens={dataMyTokens} collection={collection}/> )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
