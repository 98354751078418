import React from 'react';

import './index.css';

import ImgBlockItem from './ImgBlockItem';

const ImgBlock = (props) => {
    const { title, data } = props;

    return (
        <>
            <h2 className="imgblock__title">{title}</h2>

            {data.length > 0 ? (
                <div className="imgblock__content">
                    {data.map((item) => (
                        <ImgBlockItem
                            key={item.id}
                            name={item.name}
                            path={item.logo}
                            id={item.id}
                            shouldBeSkeleton={item.shouldBeSkeleton}
                            collectionsCount={item.collections_count}
                        />
                    ))}
                </div>
            ) : (
                <div className="collection__items--none imgblock__none">No items to display</div>
            )}
        </>
    );
};

export default ImgBlock;
