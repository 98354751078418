import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// import Pages from './Pages';
import Brands from './Brands';
import Collections from './Collections';
import Packs from './Packs';
import Tokens from './Tokens';

const Delivery = () => {
    return (
        <section className="statistics">
            <div className="container">
                <Routes>
                    <Route path="/brands" element={<Brands />} />
                    <Route path="/collections" element={<Collections />} />
                    <Route path="/packs" element={<Packs />} />
                    <Route path="/tokens" element={<Tokens />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route index element={<Navigate to="./brands" />} />
                </Routes>
            </div>
        </section>
    )
}

export default Delivery;