import React, { useCallback } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './index.css';

const CustomSelect = (props) => {
    const { optionsList, value, placeholder, onChange, isError } = props;

    const onChangeHandler = useCallback((e) => {
        onChange(e.target.value);
    }, []);

    const menuProps = {
        classes: {
            paper: 'CustomSelect_paper',
            list: 'CustomSelect_list',
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    return (
        <FormControl fullWidth style={{border: `1px solid ${isError ? 'red' : 'transparent'}`}}>
            <InputLabel
                classes={{
                    root: 'CustomSelect_root_label',
                    focused: 'CustomSelect_root_label_focused',
                    shrink: 'CustomSelect_root_label_shrink',
                }}
                id={placeholder}
            >
                {placeholder}
            </InputLabel>
            <Select
                classes={{
                    select: `CustomSelect_root`,
                    icon: 'CustomSelect_root_icon',
                }}
                MenuProps={menuProps}
                labelId={placeholder}
                id={`${placeholder}_select`}
                value={value}
                label={placeholder}
                onChange={onChangeHandler}
                multiple={props.multiple}
            >
                {optionsList.map((o) => (
                    <MenuItem
                      key={o.value || o.id} value={o.value || o.id}>
                        {o.icon && <img src={o.icon} />}
                        {o.name}
                        {props.multiple && value.some((el) => el.id === o.id) && (
                          <span className="CustomSelect_check">✔</span>
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default React.memo(CustomSelect);
