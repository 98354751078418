export  const howItWorksTitles = [
    {
        text: `Мы создаём бренд-токены - цифровые сертификаты, которые можно привязывать к брендовой одежде с помощью QR-кодов`,
        subText: `Одежда с бренд-токенами привлекает больше внимания покупателей и повышает лояльность к бренду`,
        description: `
        QR-код с бренд-токеном может быть 
        частью дизайна одежды или 
        размещен на незаметной детали (например, на фирменном шильде).
        
        Это делает одежду более интересной 
        и высокотехнологичной в глазах 
        потребителей.`,
        img: '/assets/img/how1.svg',
    },
    {
        text: ` Бренд-токен привязывает одежду к конкретному владельцу, что исключает возможность подделки`,
        subText: ` Защита бренда от контрафакта позволяет укрепить его престиж и избежать финансовых потерь`,
        description: `
        Каждая вещь Вашего бренда будет иметь свой уникальный цифровой сертификат, привязанный к владельцу.
        
        Это позволит гарантировать подлинность товара и защитить Ваш бренд от подделок.`,
        img: '/assets/img/how2.svg',
    },
    {
        text: `Доступ к дополнительным сервисам и привилегиям для владельцев брендовой одежды`,
        subText: `Стоимость брендовой одежды с бренд-токенами может быть на 25-500% выше обычной`,
        description: `Цифровой сертификат служит основанием для получения специальных предложений от партнёров бренда.
        
        Вы можете гибко управлять этими предложениями, добавляяили меняя партнёров.`,
        img: '/assets/img/how3.svg',
    },
];