import Input from "../../../common/Input";
import Modal from "../../../common/Modal";
import React, {useEffect, useState} from "react";
import {emailRegex} from "../../TokenCard/PayModal/PayModal";
import styles from '../../../components/WelcomeModal/modal.module.css'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useChangeUserDataMutation} from "../../../redux/api/userService";
import {
  initEmail as setEmailDispatch,
  setInstagram as setInstagramDispatch,
  setTelegram as setTelegramDispatch,
  setWhatsapp as setWhatsappDispatch
} from "../../../redux/slices/auth";

export const EditModal = (
  {
    setIsEdit,
    isEdit,
    setIsConfirmEmail,
    email,
    setEmail,
    telegram,
    setTelegram ,
    whatsapp,
    setWhatsapp,
    inst,
    setInst,
    phone,
    setPhone
  }) => {
  const auth = useSelector((state) => state.auth);
  const {instagram: instagramAuth, whatsapp: whatsappAuth , telegram: telegramAuth, email: emailAuth} = auth

  const [isClicked, setIsClicked] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userName = useSelector(state => state.auth.username)

  const [changeData] = useChangeUserDataMutation()

  const handleClickBtn = () => {
    const body = {
      username: userName,
      instagram: inst,
      telegram: telegram,
      whatsapp: whatsapp,
      phone:phone
    }
    if(email !== emailAuth) {
      body.email = email
    }
    setIsClicked(true)

    if(emailRegex.test(email) && telegram) {
      changeData({
        body: body
      }).unwrap().then(() => {
        email !== emailAuth && setIsConfirmEmail(true)
        dispatch(setEmailDispatch(email))
        dispatch(setInstagramDispatch(inst))
        dispatch(setTelegramDispatch(telegram))
        dispatch(setWhatsappDispatch(whatsapp))
        setEmail('')
        setTelegram('')
        setWhatsapp('')
        setInst('')
        setIsClicked(false)
        navigate('/profile')
        setIsEdit(false)
      })
    }
  }

  const handleCloseModal = () => {
    setEmail('')
    setTelegram('')
    setWhatsapp('')
    setInst('')
    setIsClicked(false)
    setIsEdit(false)
  }

  useEffect(() => {
    setInst(instagramAuth)
    setEmail(emailAuth)
    setWhatsapp(whatsappAuth)
    setTelegram(telegramAuth)

  }, [instagramAuth, emailAuth, whatsappAuth, telegramAuth, isEdit])

  return (
    <Modal active={isEdit} title={'Редактировать контакты'} onClose={handleCloseModal}>
      <Input
        placeholder="Электронная почта*"
        value={email}
        setValue={setEmail}
        isError={isClicked ? !email : email ? !emailRegex.test(email) : false}
        className={styles.input}
      />
      <Input
        placeholder="Телеграм*"
        value={telegram}
        setValue={setTelegram}
        className={styles.input}
        isError={isClicked ? !telegram :  false}
      />
      <Input
        placeholder="Телефон"
        value={phone}
        setValue={setPhone}
        className={styles.input}
      />
      <Input
        placeholder="Ватсап"
        value={whatsapp}
        setValue={setWhatsapp}
        className={styles.input}
      />
      <Input
        placeholder="Инстаграм"
        value={inst}
        setValue={setInst}
      />
      <p className={styles.text}>*Обязательное поле</p>

      <button
        className={`button token__card--content--price--buy ${styles.btn}`}
        onClick={handleClickBtn}
        style={{height: '52px', width: '100%'}}

      >
        Готово
      </button>
      <div  style={{marginTop: '44px', minWidth: '100%'}}>
        <Input
          placeholder="Укажите адрес электронной почты, которую использовали при покупке сертификата. Телеграм и Инстаграм потребуются для идентификации вашего профиля для предоставлении вам услуг. Благодарим за то что вы с нами."
          textarea
          disabled={true}
        />
      </div>


    </Modal>
  )
}