import React, {useState} from 'react';


import ModalCompany from '../../common/ModalCompany';
import moment from "moment";


const OpportunitiesItem = ({data, id, setIsRefetch}) => {
    const { company, description,contacts, type, certificate_type } = data;

    const [modal, setModal] = React.useState(false);
    const [isEdit, setIsEdit] = useState(false)

    const openModal = () => {
        setModal(true);
    }


    return(
        <>
            { <ModalCompany active={modal} setActive={setModal} id={id} data={data} setIsEdit={setIsEdit} setIsRefetch={setIsRefetch} />}
            <div className="stats__item--values opp__item--values" onClick={openModal}>
                <div className="stats__item--day stats__item--item stats__item--status opp__item--wrap off700">
                    <p className="stats__item--value">{id}</p>

                    <div className={`stats__item--status--circle ${certificate_type}`}></div>

                    <p className={`stats__item--status--value opportunity__status ${certificate_type}`}>
                        {certificate_type.charAt(0).toUpperCase() + certificate_type.slice(1)}
                    </p>
                </div>
                <div className="stats__item--day stats__item--item stats__item--status opp__item--wrap off700">
                    <p className={`stats__item--status--value opportunity__status ${type}`}>
                        {type === 'vip_service' && 'VIP'}
                        {type === 'pass_to' && 'Pass to'}
                        {type === 'discount' && 'Discount'}
                        {type === 'gift' && 'Gift'}
                    </p>
                </div>

                <div className="stats__item--day stats__item--item opp__item--wrap big">
                    <p className="whithdraw__index--mobile">
                        {id}
                    </p>

                    <div className="stats__item--avatar--inner opp__item">
                        {company && <img
                            src={company.logo}
                            alt="avatar"
                            className="stats__item--avatar"
                        />}
                    </div>

                    <div className="stats__item--wrapper opp__item">
                        <p className="opp__item--collection--name">{company.name}</p>
                    </div>
                </div>

                <div className="stats__item--item opp__item--wrap off700 activeoff1200">
                    <p className="opportunity__desc">{description}</p>
                </div>
                {contacts && contacts.length > 0
                  ? <div className="opp__item--wrap off998 activeoff1600">
                      <p className="opportunity__desc">{contacts[0].country}</p>
                      <p className="opportunity__desc">{contacts[0].city}</p>
                      <p className="opportunity__desc">{contacts[0].email}</p>
                      <p className="opportunity__desc" >{contacts[0].site}</p>
                    </div>
                  : <div className="opp__item--wrap off998 activeoff1600"></div>
                }


                <div className="withdraw__item--text opp__item--wrap off1200 activeoff1600">
                    {data.collections.map(item => {
                        return (
                          <>
                              <p className="opportunity__access link">{item.name}</p>
                          </>
                        )
                    })}

                </div>

                <div className="stats__item--item withdraw__item--text opp__item--wrap off1200 activeoff1600">
                    {moment(data.created_at).fromNow()}
                </div>
            </div>
        </>
    )
}

export default OpportunitiesItem;