import React from 'react';

import './index.css';

import StatsItem from '../Stats/StatsItem';
import { CustomSelect } from '../../common/CustomSelect';
import RankingsSkeleton from './RankingsSkeleton';

const data = {
    avatar: '/assets/img/avatar.png',
    collection: 'Collection name',
    volume: '0',
    change: '-0%',
    floor: '--',
    items: '100',
    status: 'Whitelisting',
    minted: {
        value: '0%',
        total: '0 of 100'
    }
}

const sortList = [
    { value: 'all', name: 'All categories' },
    { value: 'category1', name: 'Category1' },
    { value: 'category2', name: 'Category2' },
];

const sortList2 = [
    { value: 'all', name: 'All chains' },
    { value: 'solana', name: 'Solana' },
    { value: 'eth', name: 'Eth' },
];

const sortList3 = [
    { value: '1h', name: '1h' },
    { value: '6h', name: '6h' },
    { value: '24h', name: '24h' },
    { value: '7d', name: '7d' },
    { value: '30d', name: '30d' },
    { value: 'all', name: 'All' },
];

const Rankings = () => {
    const [fakeLoading, setFakeLoading] = React.useState(true);

    React.useEffect(() => {
        const fakeTimer = setTimeout(() => {
            setFakeLoading(false);
        }, 2000);

        return () => clearTimeout(fakeTimer);
    }, []);
    
    const [filter, setFilter] = React.useState('all');
    const [filter2, setFilter2] = React.useState('all');
    const [filter3, setFilter3] = React.useState('24h');

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    const filterChange3 = (value) => {
        setFilter3(value);
    };

    if(fakeLoading){
        return <RankingsSkeleton />
    }

    return(
        <div className="rankings">
            <div className="container">
                <div className="rankings__inner">
                    <h1 className="rankings__title">
                        Collection stats
                    </h1>

                    <p className="rankings__subtitle">
                        The top NFTs
                    </p>

                    <div className="rankings__filter--content">
                        <div className="rankings__filter--wrap">
                            <div className="rankings__filter--categories">
                                <CustomSelect
                                    optionsList={sortList}
                                    value={filter}
                                    placeholder="Select filter"
                                    onChange={filterChange}
                                />
                            </div>

                            <div className="rankings__filter--time">
                                <div className="rankings__filter--time--item">
                                    All chains
                                </div>

                                <div className="rankings__filter--time--item active">
                                    <img src="/assets/img/eth-big.svg" alt="eth" />
                                </div>

                                <div className="rankings__filter--time--item">
                                    <img src="/assets/img/matic.svg" alt="matic" />
                                </div>

                                <div className="rankings__filter--time--item">
                                    <img src="/assets/img/sol.svg" alt="solana" />
                                </div>
                            </div>

                            <div className="rankings__filter--categories mobile">
                                <CustomSelect
                                    optionsList={sortList2}
                                    value={filter2}
                                    placeholder="Select filter"
                                    onChange={filterChange2}
                                />
                            </div>

                            <div className="rankings__filter--categories mobile">
                                <CustomSelect
                                    optionsList={sortList3}
                                    value={filter3}
                                    placeholder="Select filter"
                                    onChange={filterChange3}
                                />
                            </div>
                        </div>

                        <div className="rankings__filter--time">
                            <div className="rankings__filter--time--item">
                                1h
                            </div>

                            <div className="rankings__filter--time--item">
                                6h
                            </div>

                            <div className="rankings__filter--time--item active">
                                24h
                            </div>

                            <div className="rankings__filter--time--item">
                                7d
                            </div>

                            <div className="rankings__filter--time--item">
                                30d
                            </div>

                            <div className="rankings__filter--time--item">
                                All
                            </div>
                        </div>
                    </div>

                    <div className="stats__content">
                        <div className="stats__item--names">
                            <div className="stats__item--collection stats__item--item stats__item--name">
                                Collection
                            </div>

                            <div className="stats__item--volume stats__item--item stats__item--name">
                                Volume

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>

                            <div className="stats__item--day stats__item--item stats__item--name">
                                % change

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>

                            <div className="stats__item--week stats__item--item stats__item--name">
                                Floor price

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>

                            <div className="stats__item--price stats__item--item stats__item--name">
                                Items

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>

                            <div className="stats__item--owners stats__item--item stats__item--name">
                                Status

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>

                            <div className="stats__item--items stats__item--item stats__item--name">
                                % Items minted

                                <img src="/assets/img/filter-arrow.svg" alt="arrow" className="stats__item--filter" />
                            </div>
                        </div>

                        <StatsItem data={data} id="1" />
                        <StatsItem data={data} id="2" />
                        <StatsItem data={data} id="3" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rankings;