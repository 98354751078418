import React, { useEffect, useState } from 'react';
import FilterTable from '../../../components/FilterTable';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS, BREAKPOINTS_ACTIVE } from '../../../const/breakpoints/BREAKPOINTS';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { changeSearchPagesName, pagesRemove } from './slice/pagesSlice';
import { useDeletePageMutation } from '../../../redux/api/dataService';
import { CancelModal } from '../../Сonclusions/modals/CancelModal';
import { useDynamicPagination } from '../../useDynamicPagination';
import { useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { useGetTableView } from '../../useGetTableView';

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/page/`;

const Pages = () => {
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDelete, setIsDelete] = useState(false);
    const [clickedItem, setClickedItem] = useState();
    const [deletePage] = useDeletePageMutation();
    const [setCurrentPage, refetch, setRefetch, requestParams,setCount] = useOutletContext();
    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, '/');
    const items = useDynamicPagination(TABLE_URL, requestParams, { refetch, setRefetch });
    useEffect(() => {
        if (items) {
            setCount(items.count);
        }
    }, [items]);
    const handleDeletePageRequest = () => {
        if (clickedItem) {
            deletePage({ url: clickedItem.url })
                .unwrap()
                .then(() => {
                })
                .finally(() => {
                    setIsDelete(false);
                });
        }
    };

    const handleDeletePage = () => {
        setIsDelete(true);
    };

    const handleNavigate = (id) => {
        navigate(`brands/${id}`);
    };

    console.log(items)

    return (
        <>
            <TableRow names>
                <TableCell title>Категории</TableCell>
                <TableCell title breakpoint={BREAKPOINTS.MD700}>
                    Брэнды
                </TableCell>
                <TableCell
                    title
                    textCenter
                    breakpoint={BREAKPOINTS.MD998}
                    breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1200}
                >
                    Описание
                </TableCell>
                <TableCell
                    title
                    textCenter
                    breakpoint={BREAKPOINTS.MD1200}
                    breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                >
                    Позиция
                </TableCell>
                <TableCell title textCenter>
                    Ссылка
                </TableCell>
            </TableRow>

            {items &&
                items.items.map((el, index) => {
                    return (
                        <TableRow
                            actionType="stat"
                            style={{ cursor: 'pointer' }}
                            key={index}
                            handlerDeleteClick={() => {
                                handleDeletePage();
                                setClickedItem(el);
                            }}
                            handlerEditClick={() => {
                                navigate(`../changepage/${el.url}`);
                            }}
                        >
                            <TableCell>
                                <p className="whithdraw__index">{index + 1}</p>

                                <div className="stats__item--avatar--inner withdraw__item">
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.banner}`}
                                        alt={'img'}
                                        style={{
                                            objectFit: 'cover',
                                            width: '48px',
                                            height: '48px',
                                        }}
                                    />
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <p
                                        onClick={() => handleNavigate(el.id)}
                                        // to="brands"
                                        className="withdraw__item--collection--name"
                                        style={{ wordBreak: 'break-word' }}
                                    >
                                        {el.name}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell
                                text
                                breakpoint={BREAKPOINTS.MD700}
                                onClick={() => handleNavigate(el.id)}
                            >
                                <p style={{ textAlign: 'center' }}>{el.number}</p>
                            </TableCell>

                            <TableCell
                                scroll
                                breakpoint={BREAKPOINTS.MD998}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el.id)}
                            >
                                {el.description}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD1200}
                                breakpointActive={BREAKPOINTS_ACTIVE.ACTIVE1600}
                                onClick={() => handleNavigate(el.id)}
                            >
                                {el.number}
                            </TableCell>

                            <TableCell text textCenter>
                                {el.url}
                            </TableCell>
                        </TableRow>
                    );
                })}
            <button
                onClick={() => navigate(`/admin/createpage`)}
                className={'button'}
                style={{
                    width: '100%',
                    background: 'hsla(210, 13%, 91%, 0.02)',
                    marginTop: '30px',
                    height: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'hsla(210, 13%, 91%, 0.8)',
                    fontSize: '16px',
                }}
            >
                Create new Page
            </button>

            <CancelModal
                handleOk={() => {
                    handleDeletePageRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            />
        </>
    );
};

export default Pages;
