import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Modal';
import s from './index.module.css'
import styles from '../../../TokenSuccessBuy/tokenBuy.module.css';
import Input from '../../../../common/Input';
import { Tooltip } from './components/Tooltip';
import { returnText, withdrawText } from './constants';
import { useRefundMoneyMutation, useWithdrawalOfFundsMutation } from '../../../../redux/api/billingService';
import { useSelector } from 'react-redux';

// мод. окна для Оформление вывода и возврата
export const TokenActionsModal = (
    {
        isOpen,
        setIsOpen,
        title = 'Оформление вывода',
        type = 'withdrawal',
        token,
        data,
        setIsSuccessModal,
        setIsConditions,
        onReturnBtnClick,
        tokenRefetch
    }) => {
    // Стоимость токена
    const [tokenCost, setTokenCost] = useState('')
    // кол-во дней владения
    const [dayOwnership, setDayOwnership] = useState('')
    // Начислено(всего)
    const [accruedTotal, setAccruedTotal] = useState('')
    // Начислено(на данный момент)
    const [accrued, setAccrued] = useState('')
    // Кол-во Выведено
    const [withdrawn, setWithdrawn] = useState('')
    // сумма к выводу(указывает пользователь)
    const [withdrawalAmount, setWithdrawalAmount] = useState(0)

    const emailValue = useSelector(state => state.auth.email)
    const telegramValue = useSelector(state => state.auth.telegram)
    const instagram = useSelector(state => state.auth.inst)
    const userName = useSelector(state => state.auth.username)
    const whatsapp = useSelector(state => state.auth.whatsapp)

    // api
    const [withdrawal] = useWithdrawalOfFundsMutation()
    const [returnMoney] = useRefundMoneyMutation()

    const helperCalculateRefund = (tokenCost, withdrawn) => {
        const cleanedTokenCost = tokenCost.trim().replace(/\D/g, '');
        const fee = Number(cleanedTokenCost) * 0.96;
        if(withdrawn >= 0) {
            const clearWithDrawn = withdrawn.trim().replace(/\D/g, '')
            return fee - (Number(clearWithDrawn) / 2)
        }

        if(fee < 0) {
            return 0
        }
    }


    const helperCost = (value) => {
        if(value !== undefined) {
            return Math.floor(value / 100).toLocaleString('ru-RU')
        }
    }

    const generateRequestId = () => {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
    }

    const handleCloseModal = () => {
        setIsSuccessModal && setIsSuccessModal(true)
        setIsOpen(false)
        tokenRefetch()
        setWithdrawalAmount(0)
    }


    const handleButtonClick = () => {
        if(type === 'return') {
            returnMoney({
                body: {
                    token_account: data.id,
                    request_id: generateRequestId()
                }
            })
                .unwrap()
                .then(() => {
                    handleCloseModal()
            })
        } else {
            withdrawal({
                body: {
                    amount: `${withdrawalAmount}00`,
                    token_account: data.id,
                    request_id: generateRequestId()
                }
            }) .unwrap()
                .then(() => {
                    handleCloseModal()
                })
        }

    }

    useEffect(() => {
        if(token) {
            setTokenCost(helperCost(token.price))
        }
    }, [token, isOpen])

    useEffect(() => {
        if(data) {
            setDayOwnership(data.days_ownership ? data.days_ownership : '0')
            setAccruedTotal(helperCost(data.total_result))
            setAccrued(helperCost(data.accrued  + data.frozen_amount  + data.withdrawn))
            setWithdrawn(helperCost(data.withdrawn ))
        }

    }, [data, isOpen])

    return (
        <Modal
            active={isOpen}
            title={title}
            setActive={setIsOpen}
            isReturnBtn={true}
            onReturnBtnClick={onReturnBtnClick}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                }}
            >
                <p>Пользователь: <span style={{color: 'hsla(210, 77%, 51%, 1)'}}>{userName}</span> </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        marginTop: '20px',
                        width: '100%'
                    }}
                >
                    <Input
                        title=""
                        placeholder={`${emailValue}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    <Input
                        title=""
                        placeholder={`${telegramValue}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    <Input
                        title=""
                        placeholder={`${whatsapp}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    {instagram &&
                        <Input
                            title=""
                            placeholder={`${instagram}`}
                            value={''}
                            setValue={setTokenCost}
                            disabled
                        />
                    }

                </div>

            </div>

            <div
                className={s.box}
            >
                {token && token.file_1 && <img src={token.file_1} className={styles.img} alt={'token image'}/>}
                <div className={s.block}>
                    <a
                        className={s.link}
                        href={`/collection/${token.collection.id}`}
                    >
                        {token && token.collection && token.collection.name}
                    </a>
                    <p>{token && token.name}</p>
                    <p>{token && token.investor_royalty ? `Доходность: ${token.investor_royalty}%/день` : ''}</p>
                </div>

            </div>
            <div
                className={s.inputs}
            >
                <Input
                    title=""
                    placeholder={`Стоимость: ${tokenCost} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Во владении: ${dayOwnership}/365 дней`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Начислено: ${accrued} RUB/${accruedTotal} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Выведено: ${withdrawn} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />

                <div className={s.flex}>
                    <p style={{whiteSpace: 'nowrap'}}>{type === 'return' ? 'К возврату:' : 'Доступно к выводу:'}</p>

                    <div
                        className={'input create__item--input '}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '373px'
                        }}
                    >
                        <p
                            style={{
                                color: 'hsla(210, 13%, 91%, 0.6)'
                            }}
                        >
                            {type === 'return' ? `${helperCalculateRefund(tokenCost, withdrawn)} RUB` : `${data ? Math.floor(data.accrued / 100).toLocaleString('ru-RU') : '0 RUB'}`}
                        </p>
                        <Tooltip
                            text={type === 'return' ? returnText : withdrawText}
                        />
                    </div>
                </div>
                {type === 'withdrawal' &&
                    <div className={s.flex}>
                        <p style={{whiteSpace: 'nowrap'}}>Вывести:</p>
                        <Input
                            title=""
                            value={withdrawalAmount === 0 ? '' : withdrawalAmount}
                            setValue={(value) => {
                                if(value <= (data ? (Number(data.accrued) / 100) : 0) && (value > 0)) {
                                    setWithdrawalAmount(value)
                                }
                            }}
                            typeItem={'medium'}
                        />
                    </div>
                }

            </div>

            <button
                className={s.withdrawalBtn }
                style={{
                    background: type === 'return' ? 'hsla(304, 23%, 51%, 1)' : 'hsla(210, 78%, 40%, 1)'
                }}
                onClick={handleButtonClick}
                disabled={type === 'withdrawal' && withdrawalAmount === 0}
            >
                {type === 'withdrawal' ? `Вывести ${withdrawalAmount} RUB` : `Оформить возврат ${helperCalculateRefund(tokenCost, withdrawn)} RUB`}
            </button>
            {setIsConditions &&
                <div
                    className={s.link}
                    onClick={() => setIsConditions(true)}
                >
                    <p>Условия вывода и возврата</p>
                    <div className={s.line}/>
                </div>
            }

        </Modal>
    )
}
