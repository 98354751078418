import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { isVideo as isVideoCheck } from '../../utils/isVideo';
import { VideoPlayer } from '../VideoPlayer';

const File = ({
    title,
    text,
    id,
    value,
    setValue,
    multiple = false,
    typeItem = 'full',
    defaultValue,
    type = 'banner',
    accept,
    defaultFileName,
    isError = false
}) => {
    const [img, setImg] = useState(defaultValue);
    const [isVideo, setIsVideo] = useState(false);

    const onInputChange = useCallback((e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setValue(selectedFile);
            let fileReader = new FileReader();
            fileReader.readAsDataURL(selectedFile);

            fileReader.onloadend = () => {
                setImg(fileReader.result);
            };
        }
    }, []);


    const preview = useMemo(() => {
        if (value && isVideoCheck(value.name)) {
            setIsVideo(true);

            let blobURL = URL.createObjectURL(value);
            return <VideoPlayer src={blobURL} />;
        }

        if (defaultFileName && defaultValue && isVideoCheck(defaultFileName) && !value) {
            setIsVideo(true);
            return <VideoPlayer src={defaultValue} />;
        }

        setIsVideo(false);

        if (img) {
            return <img src={img} alt="preview" className="create__item--label--preview" />;
        }

        return (
            <img
                src={`${
                    type == 'logosmall' || type == 'logo'
                        ? '/assets/img/logo-img-small.svg'
                        : '/assets/img/logo-img.svg'
                }`}
                alt="img"
                className="create__item--label--img"
            />
        );
    }, [value, img]);

    const changeVideoHandler = useCallback(() => {
        const input = document.getElementById(id);

        if (input) {
            input.click();
        }
    }, []);

    useEffect(() => {
        if(value !== undefined) {
            setImg(value)
        }
    }, [value])

    return (
        <div className={`admin__add--item ${typeItem}`}>
            <div className="admin__add--title--inner">
                {title && <p className="admin__add--title">{title}</p>}

                {text && <p className="admin__add--text">{text}</p>}
            </div>

            {isVideo && (
                <button
                    onClick={changeVideoHandler}
                    className="button create__button default__hover"
                >
                    Change file
                </button>
            )}

            <input
                id={id}
                type="file"
                className={`file`}
                multiple={multiple}
                accept={accept || 'image/png, image/gif, image/jpeg'}
                onChange={onInputChange}
            />

            <label htmlFor={id} className={`create__item--label ${type} ${isError ? 'error_input' : ''}`}>
                {preview}
            </label>
        </div>
    );
};

export default React.memo(File);
