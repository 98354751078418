exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UploadManyTokensForm_trashIconBox__2soR6,\n.UploadManyTokensForm_editIconBox__1xdYo {\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: background-color 200ms ease-in-out;\n}\n\n.UploadManyTokensForm_overflowEllipsisWrapper__3Gyrt {\n    max-width: 190px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.UploadManyTokensForm_noItems__3zl1U {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    color: white;\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n.UploadManyTokensForm_submitBtnContainer__3imCI {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n\n.UploadManyTokensForm_failed__3CZsW {\n    display: flex;\n    align-items: center;\n    grid-gap: 10px;\n    gap: 10px;\n    cursor: pointer;\n}\n\n.UploadManyTokensForm_btn__3YfR9:disabled {\n    opacity: 0.5;\n}\n", ""]);

// exports
exports.locals = {
	"trashIconBox": "UploadManyTokensForm_trashIconBox__2soR6",
	"editIconBox": "UploadManyTokensForm_editIconBox__1xdYo",
	"overflowEllipsisWrapper": "UploadManyTokensForm_overflowEllipsisWrapper__3Gyrt",
	"noItems": "UploadManyTokensForm_noItems__3zl1U",
	"submitBtnContainer": "UploadManyTokensForm_submitBtnContainer__3imCI",
	"failed": "UploadManyTokensForm_failed__3CZsW",
	"btn": "UploadManyTokensForm_btn__3YfR9"
};