import React from 'react';

import styles from './index.module.css';

import User2 from '../../icons/User2';

const TokenPartnerOffer = ({
    name,
    text,
    buttonText = "сервис",
    buttonCallback = () => {}
}) => {
    return (
        <div className={styles.blockPartnersItem}>
            <div className={styles.blockPartnersItemContent}>
                <div className={styles.blockPartnersItemWrapper}>
                    <div className={styles.blockPartnersItemAvatar}>
                        <User2 />
                    </div>

                    <div className={styles.blockPartnersItemTextInner}>
                        <p className={styles.blockPartnersItemName}>{name}</p>

                        <p className={styles.blockPartnersItemText}>
                            {text}
                        </p>
                    </div>
                </div>

                <button className={styles.blockPartnersItemButton} onClick={buttonCallback}>{buttonText}</button>
            </div>
        </div>
    );
};

export default TokenPartnerOffer;
