exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FullDescription_collectionDescInner__9Nmeb {\n    width: 100%;\n    margin-top: 2.4rem;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    grid-gap: 1px;\n    gap: 1px;\n}\n\n.FullDescription_collectionDesc__1ispw {\n    max-width: 760px;\n    max-height: 40px;\n    overflow-y: hidden;\n    font-size: 1.5rem;\n    line-height: 133%;\n    color: #e5e8eb;\n    white-space: pre-wrap;\n}\n\n.FullDescription_collectionDesc__1ispw.FullDescription_full__d4Jr7 {\n    max-height: unset;\n}\n\n.FullDescription_collectionDescFull__1_sVY {\n    display: flex;\n    align-items: center;\n    grid-gap: 10px;\n    gap: 10px;\n    color: #e5e8eb;\n    font-weight: 500;\n    font-size: 1.5rem;\n    line-height: 133%;\n    cursor: pointer;\n}\n\n.FullDescription_collectionDescFullIcon__37OhV {\n    width: 10px;\n    transform: rotate(180deg);\n    position: relative;\n    top: 1px;\n    opacity: 0.8;\n}\n\n.FullDescription_collectionDescFullIcon__37OhV.FullDescription_full__d4Jr7 {\n    transform: rotate(0);\n}\n\n.FullDescription_descLink__3wE2e{\n    word-break: break-all;\n    color: rgb(96, 96, 244);\n}", ""]);

// exports
exports.locals = {
	"collectionDescInner": "FullDescription_collectionDescInner__9Nmeb",
	"collectionDesc": "FullDescription_collectionDesc__1ispw",
	"full": "FullDescription_full__d4Jr7",
	"collectionDescFull": "FullDescription_collectionDescFull__1_sVY",
	"collectionDescFullIcon": "FullDescription_collectionDescFullIcon__37OhV",
	"descLink": "FullDescription_descLink__3wE2e"
};