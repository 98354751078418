import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';

import Question from '../../../icons/Question';
import ArrowLink from '../../../icons/ArrowLink';

const FaqItem = ({question, children}) => {
    const [step, setStep] = React.useState(false);

    return (
        <div
            className={cn(styles.stepItem, {
                [styles.active]: step,
            })}
        >
            <div className={styles.faqItemTop} onClick={() => setStep((prev) => !prev)}>
                <div className={styles.stepItemTopStepInner}>
                    <Question className={styles.faqIcon} />

                    <p className={styles.stepItemTopName}>
                        {question}
                    </p>
                </div>

                <ArrowLink className={styles.stepItemArrow} />
            </div>

            {step && (
                <div className={styles.faqItemContent}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default FaqItem;
