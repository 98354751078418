import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useHideWhiteListRequestMutation,
    usePatchApplicationMutation,
} from '../../../redux/api/ugcService';
import { updateteWhitelistItem } from '../../../redux/slices/admin/whitelists';
import { NotificationContext } from '../../../context/NotificationContext';
import { WHITE_LIST_APPLICATION_STATUSES } from '../../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import {
    onClose as onConfirmDialogClose,
    onOpen as onConfirmDialogOpen,
} from '../../../redux/dialogs/confirmAplicationDialog';
import {
    onClose as onRejectDialogClose,
    onOpen as onRejectDialogOpen,
} from '../../../redux/dialogs/deleteEntityDialog';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { normilizeError } from '../../../utils/http/normilizeError';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import DeleteEntityDialog from '../../../components/DeleteEntityDialog/DeleteEntityDialog';

const WhitelistActionsContext = React.createContext({});

const WhitelistActionsContextProvider = (props) => {
    const { children } = props;

    const isChangeInWorkProccessing = useRef(false);

    const { isOpen: isDeleteEntityOpen, id: rejectedAplicationId } = useSelector(
        (state) => state.deleteEntityDialog,
    );
    const { isOpen: isConfirmeApplicationOpen, id: confimedAplicationId } = useSelector(
        (state) => state.confirmAplicationDialog,
    );

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const dispatch = useDispatch();

    const [
        patchApplication,
        {
            data: patchDataSuccided,
            isLoading: isPatchApplicationLoading,
            error: patchApplicationError,
            reset: patchApplicationReset,
        },
    ] = usePatchApplicationMutation();

    const [
        confirmApplication,
        {
            data: confirmApplicationSuccidedData,
            isLoading: isconfirmApplicationLoading,
            error: confirmApplicationError,
            reset: confirmApplicationReset,
        },
    ] = usePatchApplicationMutation();

    const [
        recoverApplication,
        {
            data: recoverAppliationSuccidedData,
            isLoading: isRecoverApplicationLoading,
            error: recoverApplicationError,
            reset: recoverApplicationReset,
        },
    ] = usePatchApplicationMutation();

    const [
        rejectApplication,
        {
            data: rejectApplicationSuccidedData,
            isLoading: isrejectApplicationLoading,
            error: rejectApplicationError,
            reset: rejectApplicationReset,
        },
    ] = usePatchApplicationMutation();

    const [
        hideWhiteListRequest,
        {
            data: hideApplicationSucciededData,
            error: whiteListHiddenError,
            isLoading: isHideWhiteListProccessing,
            reset,
        },
    ] = useHideWhiteListRequestMutation();

    const [
        cancelBookedWhiteListRequest,
        {
            data: cancelBookedApplicationSucciededData,
            error: cancelBookedWhiteListRequestError,
            isLoading: isCancelBookedWhiteListRequestProccessing,
            reset: resetCancelBookedWhiteListRequest,
        },
    ] = usePatchApplicationMutation();

    const takeInWorkHandler = useCallback(
        ({ isInWork, applicationId }) => {
            if (isPatchApplicationLoading || isChangeInWorkProccessing.current) {
                return;
            }

            isChangeInWorkProccessing.current = true;

            patchApplication({ id: applicationId, data: { in_work: !isInWork } });
        },
        [isPatchApplicationLoading, isChangeInWorkProccessing],
    );

    const onReject = useCallback((id) => {
        rejectApplication({
            id,
            data: { status: WHITE_LIST_APPLICATION_STATUSES.RED },
        });
    }, []);

    const onDelete = useCallback((id) => {
        hideWhiteListRequest({ id });
    }, []);

    const onConfirm = useCallback((id) => {
        confirmApplication({
            id,
            data: { status: WHITE_LIST_APPLICATION_STATUSES.WHITE },
        });
    }, []);

    const onRecover = useCallback((id) => {
        recoverApplication({
            id,
            data: { status: WHITE_LIST_APPLICATION_STATUSES.NEW },
        });
    }, []);

    const onCancel = useCallback((id) => {
        cancelBookedWhiteListRequest({
            id,
            data: { token_none: true },
        });
    }, []);

    const onConfirmHandler = useCallback((id) => {
        dispatch(
            onConfirmDialogOpen({
                id,
                onConfirm,
                title: 'Are you sure you want to approve application?',
            }),
        );
    }, []);

    const onRecoverHandler = useCallback((id) => {
        dispatch(
            onConfirmDialogOpen({
                id,
                onConfirm: onRecover,
                title: 'Are you sure you want to recover application?',
            }),
        );
    }, []);

    const onRejectHandler = useCallback((id) => {
        dispatch(
            onRejectDialogOpen({
                id,
                onDelete: onReject,
                deleteBtnText: 'Add to Red',
                title: 'Are you sure you want to add aplication to Red?',
            }),
        );
    }, []);

    const onDeleteHandler = useCallback((id) => {
        dispatch(
            onRejectDialogOpen({
                id,
                onDelete: onDelete,
                title: 'Are you sure you want to delete aplication?',
            }),
        );
    }, []);

    const onCancelBookedApplicationHandler = useCallback((id) => {
        dispatch(
            onRejectDialogOpen({
                id,
                onDelete: onCancel,
                deleteBtnText: 'Yes, cancel',
                title: 'Are you sure you want to cancel booking?',
            }),
        );
    }, []);

    const closeRejectDialogHandler = useCallback(() => {
        dispatch(onRejectDialogClose());
    }, []);

    const closeConfirmDialogHandler = useCallback(() => {
        dispatch(onConfirmDialogClose());
    }, []);

    useEffect(() => {
        if (patchDataSuccided) {
            isChangeInWorkProccessing.current = false;

            dispatch(
                updateteWhitelistItem({
                    id: patchDataSuccided.id,
                    data: {
                        id: patchDataSuccided.id,
                        inWork: patchDataSuccided.in_work,
                        tokenId: patchDataSuccided.token_id,
                    },
                }),
            );

            if (rejectedAplicationId) {
                addNotification({
                    type: NOTIFICATION_TYPES.SUCCESS,
                    text: 'Account add to red successfuly',
                });
                closeRejectDialogHandler();
            } else {
                patchApplicationReset();
            }
        }
    }, [patchDataSuccided, rejectedAplicationId, confimedAplicationId]);

    useEffect(() => {
        if (patchApplicationError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(patchApplicationError),
            });
            isChangeInWorkProccessing.current = false;
        }
    }, [patchApplicationError, rejectedAplicationId, confimedAplicationId]);

    // CONFIRM
    useEffect(() => {
        if (confirmApplicationSuccidedData && confimedAplicationId) {
            dispatch(
                updateteWhitelistItem({
                    id: confirmApplicationSuccidedData.id,
                    data: {
                        id: confirmApplicationSuccidedData.id,
                        status: confirmApplicationSuccidedData.status,
                        tokenId: confirmApplicationSuccidedData.token_id,
                    },
                }),
            );

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application confirmed successfuly',
            });
            closeConfirmDialogHandler();
            confirmApplicationReset();
        }
    }, [confirmApplicationSuccidedData, confimedAplicationId]);

    useEffect(() => {
        if (confirmApplicationError && confimedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(confirmApplicationError),
            });
            closeConfirmDialogHandler();
        }
    }, [confirmApplicationError, confimedAplicationId]);

    // REJECT
    useEffect(() => {
        if (rejectApplicationSuccidedData && rejectedAplicationId) {
            dispatch(
                updateteWhitelistItem({
                    id: rejectApplicationSuccidedData.id,
                    data: {
                        id: rejectApplicationSuccidedData.id,
                        status: rejectApplicationSuccidedData.status,
                        tokenId: rejectApplicationSuccidedData.token_id,
                    },
                }),
            );

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application added to Red',
            });
            closeRejectDialogHandler();
            rejectApplicationReset();
        }
    }, [rejectApplicationSuccidedData, rejectedAplicationId]);

    useEffect(() => {
        if (rejectApplicationError && rejectedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(rejectApplicationError),
            });
            closeRejectDialogHandler();
        }
    }, [rejectApplicationError, rejectApplicationError]);

    // RECOVER
    useEffect(() => {
        if (recoverAppliationSuccidedData && confimedAplicationId) {
            dispatch(
                updateteWhitelistItem({
                    id: recoverAppliationSuccidedData.id,
                    data: {
                        id: recoverAppliationSuccidedData.id,
                        status: recoverAppliationSuccidedData.status,
                        tokenId: recoverAppliationSuccidedData.token_id,
                    },
                }),
            );

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application recover successfully',
            });
            closeConfirmDialogHandler();
            recoverApplicationReset();
        }
    }, [recoverAppliationSuccidedData, confimedAplicationId]);

    useEffect(() => {
        if (recoverApplicationError && confimedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(recoverApplicationError),
            });
            closeConfirmDialogHandler();
        }
    }, [recoverApplicationError, confimedAplicationId]);

    // HIDE
    useEffect(() => {
        if (hideApplicationSucciededData && rejectedAplicationId) {
            dispatch(
                updateteWhitelistItem({
                    id: hideApplicationSucciededData.id,
                    data: {
                        id: hideApplicationSucciededData.id,
                        status: hideApplicationSucciededData.status,
                        tokenId: hideApplicationSucciededData.token_id,
                    },
                }),
            );

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application removed successfully',
            });
            closeRejectDialogHandler();
            recoverApplicationReset();
        }
    }, [hideApplicationSucciededData, rejectedAplicationId]);

    useEffect(() => {
        if (whiteListHiddenError && rejectedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(whiteListHiddenError),
            });
            closeRejectDialogHandler();
        }
    }, [whiteListHiddenError, rejectedAplicationId]);

    // CANCEL
    useEffect(() => {
        if (cancelBookedApplicationSucciededData && rejectedAplicationId) {
            dispatch(
                updateteWhitelistItem({
                    id: cancelBookedApplicationSucciededData.id,
                    data: {
                        id: cancelBookedApplicationSucciededData.id,
                        status: cancelBookedApplicationSucciededData.status,
                        tokenId: cancelBookedApplicationSucciededData.token_id,
                    },
                }),
            );

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Application booking was canceled successfully',
            });
            closeRejectDialogHandler();
            resetCancelBookedWhiteListRequest();
        }
    }, [cancelBookedApplicationSucciededData, rejectedAplicationId]);

    useEffect(() => {
        if (cancelBookedWhiteListRequestError && rejectedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(cancelBookedWhiteListRequestError),
            });
            closeRejectDialogHandler();
        }
    }, [cancelBookedWhiteListRequestError, rejectedAplicationId]);

    useEffect(
        () => () => {
            reset();
            confirmApplicationReset();
            recoverApplicationReset();
            rejectApplicationReset();
            resetCancelBookedWhiteListRequest();
            patchApplicationReset();
        },
        [],
    );

    const actions = {
        takeInWorkHandler,
        onDeleteHandler,
        onCancelBookedApplicationHandler,
        onConfirmHandler,
        onRejectHandler,
        onRecoverHandler,
    };

    return (
        <WhitelistActionsContext.Provider
            value={{
                actions,
            }}
        >
            {children}
            {isConfirmeApplicationOpen && (
                <ConfirmDialog
                    open={isConfirmeApplicationOpen}
                    isConfimationProccessing={
                        isconfirmApplicationLoading || isRecoverApplicationLoading
                    }
                    onClose={closeConfirmDialogHandler}
                />
            )}
            {isDeleteEntityOpen && (
                <DeleteEntityDialog
                    open={isDeleteEntityOpen}
                    isDeletationProccessing={
                        isrejectApplicationLoading ||
                        isHideWhiteListProccessing ||
                        isCancelBookedWhiteListRequestProccessing
                    }
                    onClose={closeRejectDialogHandler}
                />
            )}
        </WhitelistActionsContext.Provider>
    );
};

export { WhitelistActionsContext, WhitelistActionsContextProvider };
