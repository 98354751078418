import React from 'react';

const Wallet = ({ ...props }) => {
    return (
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.275 8.46874C11.0125 8.72499 10.8625 9.09375 10.9 9.4875C10.9563 10.1625 11.575 10.6562 12.25 10.6562H13.4375V11.4C13.4375 12.6937 12.3812 13.75 11.0875 13.75H3.9125C2.61875 13.75 1.5625 12.6937 1.5625 11.4V7.19376C1.5625 5.90001 2.61875 4.84375 3.9125 4.84375H11.0875C12.3812 4.84375 13.4375 5.90001 13.4375 7.19376V8.09376H12.175C11.825 8.09376 11.5063 8.23124 11.275 8.46874Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5625 7.75704V4.90083C1.5625 4.15708 2.01875 3.49455 2.7125 3.23205L7.675 1.35705C8.45 1.0633 9.28125 1.63832 9.28125 2.46957V4.84456"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0993 8.73122V10.0188C14.0993 10.3625 13.8243 10.6437 13.4743 10.6562H12.2493C11.5743 10.6562 10.9555 10.1625 10.8993 9.48749C10.8618 9.09374 11.0118 8.72498 11.2743 8.46873C11.5055 8.23123 11.8243 8.09375 12.1743 8.09375H13.4743C13.8243 8.10625 14.0993 8.38747 14.0993 8.73122Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.375 7.5H8.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Wallet;
