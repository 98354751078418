import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setUserTokenTransferStatus } from './slice/tokenSlice';

export const ApproveCloseTransfer = ({ setIsClose }) => {
    const userIsAuth = useSelector(
        (state) => state.tokensStatisticSlice.userTokenReciever,
        shallowEqual,
    );
    const dispatch = useDispatch();
    const successTransfer = useRef(null);
    useEffect(() => {
        return () => {
            dispatch(setUserTokenTransferStatus(null));
        };
    }, []);

    return (
        <div className="tokenTransferModal" ref={successTransfer}>
            <div className="tokenTransferModal__container">
                <div className="container__header">
                    <h3>Передача</h3>
                </div>

                <div className="container__content">
                    <p style={{ color: 'white', userSelect: 'none', textAlign: 'center' }}>
                        {userIsAuth && userIsAuth.isAuth == true
                            ? 'Токен передан'
                            : 'Токен передан, пользователь получит его при верификации данных'}
                    </p>

                    <div className="container__footer">
                        <button
                            className="modal__button"
                            onClick={() => setIsClose((prev) => !prev)}
                        >
                            хорошо
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
