import _ from 'lodash';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { ONLY_NUMBERS_REGEX_ONLY_G } from '../../const/regExp';
import { useSelector } from 'react-redux';

const ONE_HUNDRED = 100;



const RoyaltyDestribution = ({
    royaltyDestribution,
    deleteDestributionItem,
    saveRoyaltyWalletHandler,
    isSendData
}) => {
    const [royaltyInput, setRoyaltyInput] = useState(null);

    const walletAddress = useSelector(state => state.auth.walletAddress)

    const ROYALTY_DESTRIBIUTION = {
        percentage: '100',
        wallet: walletAddress,
    };

    const onRoyaltyInputPercentageChange = useCallback(
        e => {
            if (royaltyInput) {
                const value = e.currentTarget.value.match(ONLY_NUMBERS_REGEX_ONLY_G);

                const valueStr = value && value.join('');

                if (Number(valueStr) <= ONE_HUNDRED) {
                    setRoyaltyInput(p => ({
                        ...p,
                        percentage: valueStr,
                    }));
                }
            }
        },
        [royaltyInput],
    );

    const onRoyaltyInputWalletChange = useCallback(
        e => {
            if (royaltyInput) {
                setRoyaltyInput(p => ({
                    ...p,
                    wallet: e.target.value,
                }));
            }
        },
        [royaltyInput],
    );

    const onSaveRoyaltyWalletHandler = useCallback(() => {
        const sumRoyaltyPerc = royaltyDestribution.reduce((a, c) => a + Number(c.percentage), 0);
        if (
            royaltyInput &&
            royaltyInput.percentage &&
            royaltyInput.wallet &&
            sumRoyaltyPerc + Number(royaltyInput.percentage) <= ONE_HUNDRED
        ) {
            saveRoyaltyWalletHandler({ id: _.uniqueId('incomeRoyalty_'), ...royaltyInput });

            setRoyaltyInput(null);
        }
    }, [royaltyInput, royaltyDestribution]);

    const addRoyaltyWallet = useCallback(() => {
        setRoyaltyInput(ROYALTY_DESTRIBIUTION);
    }, []);

    const incomeSumRoyaltyPerc = royaltyDestribution && royaltyDestribution.reduce(
        (a, c) => a + Number(c.percentage),
        0,
    );

    const isError = incomeSumRoyaltyPerc < 100 && isSendData
    // const isErrorInput = (royaltyInput &&  royaltyInput.percentage < 100 )&& isSendData
    const isErrorInput = false

    return (
        <>
            {Boolean(royaltyDestribution && royaltyDestribution.length) &&
                royaltyDestribution.map(wallet => (
                    <div className="control__item" key={wallet.id}>
                        <input
                            type="text"
                            className={`input control__input control__input--mini ${isError ? 'error_input' : ''}`}
                            disabled
                            value={wallet.percentage}
                        />

                        <input
                            type="text"
                            className="input control__input"
                            value={wallet.wallet}
                            disabled
                        />

                        <button
                            className="button control__item--confirm"
                            onClick={() => deleteDestributionItem(wallet.id)}
                        >
                            <img src="/assets/img/delete.svg" alt="delete" className="control__item--confirm--icon" />
                        </button>
                    </div>
                ))}
            {royaltyInput && (
                <div className="control__item">
                    <input
                        type="text"
                        className={`input control__input control__input--mini ${isErrorInput ? 'error_input' : ''}`}
                        value={royaltyInput.percentage}
                        onChange={onRoyaltyInputPercentageChange}
                    />

                    <input
                        type="text"
                        className="input control__input"
                        placeholder="0xeA09D6D8Cff17b11E45763d1025831de3E2DdaAF"
                        value={royaltyInput.wallet}
                        onChange={onRoyaltyInputWalletChange}
                    />

                    <button
                        className="button control__item--confirm"
                        onClick={onSaveRoyaltyWalletHandler}
                    >
                        <img src="/assets/img/save.svg" alt="save" className="control__item--confirm--icon" />
                    </button>
                </div>
            )}

            <div className="control__button--inner">
                <button className="button control__add" onClick={addRoyaltyWallet}>
                    Add address
                </button>

                <button className="button control__add">
                    Create address
                </button>
            </div>
        </>
    );
};

export default React.memo(RoyaltyDestribution);
