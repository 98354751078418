import React, { useEffect } from 'react';

import css from './index.module.css';
import { useNavigate } from 'react-router-dom';
import FilterItem from '../../components/FilterItem';
import SortItem from '../../components/SortItem';
import { CustomSelect } from '../../common/CustomSelect';
import CollectionInfo from '../../common/CollectionInfo';
import NoItems from '../../common/NoItems';
// import SearchInput from '../../common/SearchInput';
import { InputField } from '../../common/SearchInput';

const FilterTable = ({
    sorts = [],
    filters = [],
    button,
    views = false,
    tokensCount,
    withoutFilter = false,
    placeholderSearchInput,
    children,
    onInputChange,
    isActiveFilters,
    setIsActiveFilters,
    handleRefresh,
    viewTableType,
    handleChangeView,
    viewType,
    setViewType,
    search,
    setSearch,
    count,
    type,
    filterOnChnage,
    onEnterSearch
}) => {
    // const navigate = useNavigate();
    const [filterActive, setFilterActive] = React.useState(false);
    const [sortActive, setSortActive] = React.useState(false);
    const handleSetViewType = (type) => {
        setViewType((prevState) => {
            return prevState.map((checkbox) => {
                if (checkbox.title === type) {
                    handleChangeView({ ...checkbox, state: !checkbox.state });
                    return { ...checkbox, state: !checkbox.state };
                }
                return { ...checkbox, state: false };
            });
        });
    };

    return (
        <>
            <div className={css.collectionFilterContent}>
                {!withoutFilter && (
                    <>
                        <button
                            className={`button ${css.collectionFilterButton}`}
                            onClick={() =>
                                setIsActiveFilters
                                    ? setIsActiveFilters((prev) => !prev)
                                    : setFilterActive((prev) => !prev)
                            }
                        >
                            <img
                                src="/assets/img/filter.svg"
                                alt="filter"
                                className={css.collectionFilterIcon}
                            />

                            <img
                                src="/assets/img/filter2.svg"
                                alt="filter"
                                className={`${css.collectionFilterIcon} ${css.mobile}`}
                            />

                            <p className={css.collectionFilterButtonText}>Filters</p>
                        </button>

                        <button
                            className={`button ${css.collectionSortButton}`}
                            onClick={() => setSortActive((prev) => !prev)}
                        >
                            <img
                                src="/assets/img/sort.svg"
                                alt="sort"
                                className={`${css.collectionFilterIcon} ${css.mobile}`}
                            />

                            <p className={css.collectionFilterButtonText}>Sort</p>
                        </button>
                    </>
                )}

                <InputField
                    value={search}
                    setValue={setSearch}
                    placeholder={placeholderSearchInput || 'Search by user name'}
                    onInputChange={onInputChange}
                    onEnterSearch={onEnterSearch}
                />

                {sorts.map((data, id) => (
                    <div key={id} className={css.collectionFilterOrder}>
                        <CustomSelect
                            optionsList={data.optionsList}
                            value={data.value}
                            placeholder={data.placeholder}
                            onChange={data.onChange}
                        />
                    </div>
                ))}

                {views && (
                    <div className={css.collectionFilterView}>
                        {viewType &&
                            viewType.map((checkbox) => {
                                return (
                                        <button
                                            style={{
                                                border:
                                                    checkbox.state === true
                                                        ? '1px solid gray'
                                                        : 'none',
                                            }}
                                            className={`button collection__filter--view--item`}
                                            onClick={() => handleSetViewType(checkbox.title)}
                                        >
                                            <img
                                                src={checkbox.src}
                                                alt="view"
                                                className="collection__filter--view--icon"
                                            />
                                        </button>
                                    )
                                
                            })}
                    </div>
                )}

                {button || ''}
            </div>

            <div className={css.collectionContent}>
                {(isActiveFilters !== undefined ? isActiveFilters : filterActive) && (
                    <div className={css.collectionFilterBox}>
                        <div className="collection__filter--title--box">
                            <p className="collection__filter--title">Filters</p>

                            <img
                                src="/assets/img/cross2.svg"
                                alt="cross"
                                className="collection__filter--title--cross"
                                onClick={() =>
                                    setIsActiveFilters
                                        ? setIsActiveFilters((prev) => !prev)
                                        : setFilterActive((prev) => !prev)
                                }
                            />
                        </div>
                        {filters.map((filter, id) => {
                            return (
                                <FilterItem
                                    key={id}
                                    title={filter.title}
                                    value={filter.value}
                                    elements={filter.elements}
                                    setData={filter.setData}
                                    filters={filter.filters}
                                    setFilters={filter.setFilters}
                                    filter={filter.filter}
                                    onCheckItem={filter.onCheckItem ? filter.onCheckItem : () => {}}
                                    onRemoveItem={
                                        filter.onRemoveItem ? filter.onRemoveItem : () => {}
                                    }
                                    onChange={filter.onChange ? filter.onChange :  filterOnChnage}
                                />
                            );
                        })}

                        <div className="collection__filter--buttons">
                            <button className="button collection__filter--button--filter">
                                Clear all
                            </button>

                            <button
                                className="button collection__filter--button--filter blue__button"
                                onClick={() => setFilterActive((prev) => !prev)}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                )}

                {sortActive && (
                    <div className="collection__sort--box">
                        <div className="collection__filter--title--box">
                            <p className="collection__filter--title">Sort by</p>

                            <img
                                src="/assets/img/cross2.svg"
                                alt="cross"
                                className="collection__filter--title--cross"
                                onClick={() => setSortActive((prev) => !prev)}
                            />
                        </div>

                        <div className="collection__sort--content">
                            {sorts.map((sort) =>
                                sort.optionsList.map((data, id) => (
                                    <SortItem
                                        key={`${data.value}_${id}`}
                                        text={data.name}
                                        id={`${data.value}_${id}`}
                                    />
                                )),
                            )}
                        </div>

                        <div className="collection__filter--buttons">
                            <button
                                className="button collection__sort--button--filter blue__button"
                                onClick={() => setSortActive((prev) => !prev)}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                )}

                <div className="collection__content--preitems">
                    <CollectionInfo count={count} handleRefresh={handleRefresh} />

                    <div className="collection__content--items">
                        {false ? (
                            <NoItems />
                        ) : (
                            <div className={`stats__content${filterActive ? ' active' : ''}`}>
                                {children}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterTable;
