import React, { memo, useState } from 'react';
import './style.css';
import Vk from '../../../../../icons/Vk';
import Telegram2 from '../../../../../icons/Telegram2';
import Instagram from '../../../../../icons/Instagram';
import styles from '../../../index.module.css';
import ModalNew from '../../../../../common/ModalNew';
import { TeamModal } from '../TeamModal/TeamModal';

export const TeamItem = memo(({ name, role, image, text, setOpenModal, setCurrentEmploye }) => {
    // const [openTeamModal, setOpenTeamModal] = useState(false);

    const openModal = () => {
        setCurrentEmploye({ name, role, image, text });
        setOpenModal(true);
    };
    return (
        <div className="TeamItemContainer" onClick={openModal}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    borderRadius: '12px',
                }}
                className="TeamItemContainer__card"
            >
                <div
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}
                >
                    <p style={{ fontWeight: 500, color: 'white', fontSize: '20px' }}>{name}</p>
                    <p style={{ color: '#e5e8ebcc' }}>{role}</p>

                    <div className={styles.modalTeamSocials} style={{ margin: 0 }}>
                        <a href={''} className={styles.teamSlideInfoSocialLink}>
                            <Vk />
                        </a>

                        <a href={''} className={styles.teamSlideInfoSocialLink}>
                            <Instagram />
                        </a>

                        <a href={''} className={styles.teamSlideInfoSocialLink}>
                            <Telegram2 />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
});
