import React from 'react';

const Vk2 = ({ ...props }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.8195 0.832031H7.19577C2.05694 0.832031 0.835938 2.05395 0.835938 7.18178V12.8064C0.835938 17.9434 2.04869 19.1654 7.18569 19.1654H12.8104C17.9474 19.1654 19.1693 17.9526 19.1693 12.8156V7.19186C19.1693 2.05303 17.9565 0.832031 12.8195 0.832031ZM15.6364 13.9129H14.299C13.793 13.9129 13.6409 13.5031 12.7334 12.5956C11.9414 11.832 11.6068 11.7367 11.406 11.7367C11.1292 11.7367 11.0531 11.8128 11.0531 12.195V13.3977C11.0531 13.7231 10.9477 13.9138 10.0979 13.9138C9.2734 13.8578 8.47391 13.607 7.76512 13.182C7.05633 12.757 6.45846 12.1699 6.0206 11.469C4.98143 10.177 4.2588 8.65986 3.91044 7.03878C3.91044 6.83803 3.98652 6.65653 4.36877 6.65653H5.70619C6.04994 6.65653 6.17369 6.80961 6.30752 7.16253C6.95652 9.07286 8.06385 10.7339 8.51302 10.7339C8.68535 10.7339 8.76052 10.6578 8.76052 10.2279V8.2607C8.70369 7.36328 8.22702 7.2872 8.22702 6.9627C8.23314 6.87711 8.27237 6.79727 8.33637 6.74011C8.40037 6.68296 8.48412 6.65298 8.56985 6.65653H10.6709C10.9578 6.65653 11.0531 6.79953 11.0531 7.14328V9.79795C11.0531 10.0849 11.1769 10.1802 11.263 10.1802C11.4354 10.1802 11.5683 10.0849 11.8836 9.76953C12.5609 8.94356 13.1142 8.02335 13.5263 7.03787C13.5686 6.91966 13.6484 6.81849 13.7534 6.74968C13.8585 6.68087 13.9831 6.64817 14.1084 6.65653H15.4458C15.8464 6.65653 15.9316 6.85728 15.8464 7.14328C15.3601 8.23269 14.7584 9.2668 14.0515 10.2279C13.9076 10.4479 13.8499 10.5624 14.0515 10.82C14.1844 11.0208 14.6529 11.4122 14.9682 11.7844C15.4263 12.2416 15.8068 12.7704 16.0948 13.35C16.2094 13.7222 16.0178 13.9129 15.6364 13.9129Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Vk2;
