import React, { useRef, useState } from 'react';
import styles from '../../index.module.css';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
import ArrowLeftShort from '../../../../icons/ArrowLeftShort';
import ArrowRightShort from '../../../../icons/ArrowRightShort';
import Slider from 'react-slick';
import { TeamItem } from './TeamItem/TeamItem';
import { TeamModal } from './TeamModal/TeamModal';
import ModalNew from '../../../../common/ModalNew';
const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.05,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 1.05,
            },
        },
    ],
};

const TEAM_LIST = [
    {
        name: 'Михляев Игорь',
        role: 'CEO (Генеральный директор)',
        image: '/assets/img/team1.png',
        text: `
Игорь Михляев - основатель и CEO платформы CheckBrand. С самого
                                    детства Игорь мечтал изменить мир к лучшему. Повзрослев и став
                                    предпринимателем, он понял, что одна из главных проблем,
                                    требующих решения - это масштабная подделка брендовых товаров.

                                    Движимый этой целью, Игорь создал CheckBrand - инновационную
                                    платформу, которая позволяет любому бренду надежно защитить свою
                                    продукцию от контрафакта. Используя технологию токенизации,
                                    CheckBrand дает возможность производителям маркировать товары
                                    уникальными цифровыми сертификатами, которые невозможно
                                    подделать. Это гарантирует подлинность изделий для конечных
                                    потребителей.


                                    Но Игорь пошел еще дальше. Он разработал целую экосистему вокруг
                                    токенизированных брендов, включающую партнерские программы,
                                    клубы коллекционеров и системы поощрения. Это позволяет брендам
                                    не только защитить свою продукцию, но и выстроить более тесные,
                                    вовлекающие отношения с лояльными клиентами.

                                    Благодаря своей дальновидности, предпринимательскому таланту и
                                    безграничной преданности идее, Игорь Михляев заслужил репутацию
                                    лидера, способного воплотить в жизнь грандиозные планы по
                                    трансформации рынка брендовых товаров. Работая с ним, бренды
                                    могут быть уверены в надежной защите своей продукции и выходе на
                                    качественно новый уровень взаимодействия с потребителями.`,
    },
    {
        name: 'Михляева Мария',
        role: 'CMO (Директор по маркетингу)',
        image: '/assets/img/team2.png',
        text: `
    
                                    Мария Михляева - директор по маркетингу платформы CheckBrand.
                                    Будучи супругой основателя Игоря Михляева, Мария разделяет его
                                    страсть к инновациям и стремление сделать мир лучше.

                                    Обладая глубокими знаниями в области брендинга и маркетинговых
                                    коммуникаций, Мария играет ключевую роль в формировании
                                    уникального позиционирования CheckBrand. Она создает яркий,
                                    запоминающийся образ платформы, который вдохновляет бренды
                                    присоединиться к революции в защите подлинности товаров.

                                    Под руководством Марии команда CheckBrand разработала
                                    комплексную маркетинговую стратегию, позволяющую эффективно
                                    взаимодействовать с целевой аудиторией - производителями
                                    брендовой продукции. Ее креативный подход и умение находить
                                    нестандартные решения помогают платформе выделяться среди
                                    конкурентов и привлекать все больше партнеров.

                                    Мария Михляева - не просто опытный маркетолог, но и
                                    единомышленник Игоря, разделяющий его видение будущего рынка
                                    товаров класса люкс. Вместе они формируют сплоченную
                                    управленческую команду, способную вывести CheckBrand на
                                    лидирующие позиции в индустрии.
`,
    },
    {
        name: 'Калекин Андрей',
        role: 'CTO (Технический директор)',
        image: '/assets/img/team3.png',
        text: `
                                    Андрей Калекин - CTO и ведущий разработчик платформы CheckBrand.
                                    Обладая глубокими познаниями в области блокчейна, криптографии и
                                    распределенных реестров, Андрей стоял у истоков создания
                                    инновационной технологии, лежащей в основе CheckBrand.

                                    Именно благодаря его усилиям CheckBrand может предложить брендам
                                    надежную систему защиты от подделок, основанную на
                                    невзаимозаменяемых токенах. Андрей разработал уникальный
                                    протокол, позволяющий генерировать цифровые сертификаты, которые
                                    невозможно подделать или скопировать. Это гарантирует
                                    аутентичность каждого товара для конечных потребителей.

                                    Но технологические решения Андрея вышли далеко за рамки простой
                                    защиты от контрафакта. Он спроектировал целостную экосистему
                                    токенизированных брендов, включающую партнерские программы,
                                    системы поощрения и клубы коллекционеров. Это позволяет брендам
                                    не только защитить свою продукцию, но и выстроить более
                                    глубокие, вовлекающие отношения с лояльными клиентами.

                                    Благодаря своим выдающимся техническим навыкам, инженерному
                                    таланту и страсти к инновациям, Андрей Калекин заслужил
                                    репутацию ведущего эксперта в области блокчейн-решений для
                                    брендовой индустрии. Работая с ним, бренды могут быть уверены в
                                    надежности и масштабируемости технологической платформы
                                    CheckBrand.
`,
    },
    {
        name: 'Амирханов Эльман',
        role: 'Front end developer (Разработчик)',
        image: '/assets/img/team4.png',
        text: `
                                    Эльман Амирханов - талантливый Front-end разработчик и ключевой
                                    член команды CheckBrand. Именно его усилиями была создана яркая,
                                    интуитивно понятная и функциональная веб-платформа, которая
                                    сегодня является лицом проекта.

                                    Обладая глубокими знаниями в области современных веб-технологий,
                                    Эльман спроектировал и реализовал все пользовательские
                                    интерфейсы CheckBrand. Он продумал каждую деталь, чтобы
                                    обеспечить максимальное удобство и вовлеченность клиентов -
                                    брендов и их покупателей.

                                    Благодаря его таланту, платформа CheckBrand выглядит не просто
                                    как технический инструмент, а как стильное, эстетичное решение,
                                    способное заинтересовать и увлечь пользователей. Эльман создал
                                    уникальный дизайн, который гармонично сочетает в себе элементы
                                    роскоши, эксклюзивности и инновационности - ключевые ценности
                                    CheckBrand.

                                    Эльман - настоящий профессионал своего дела, способный воплотить
                                    самые амбициозные идеи в жизнь. Его работа над CheckBrand стала
                                    ярким примером того, как талант и креативность могут
                                    трансформировать простую технологическую платформу в
                                    захватывающий, вдохновляющий продукт.
`,
    },
    {
        name: 'Потапов Виктор',
        role: 'Front end developer (Разработчик)',
        image: '/assets/img/team5.png',
        text: `
                                    Виктор Потапов - ключевой специалист в команде CheckBrand,
                                    отвечающий за разработку и интеграцию фронтенд части платформы.
                                    Обладая глубокими знаниями в области современных веб-технологий,
                                    Виктор сыграл ключевую роль в создании интуитивно понятного и
                                    визуально привлекательного пользовательского интерфейса
                                    CheckBrand.

                                    Под его руководством была разработана удобная система
                                    взаимодействия брендов и их клиентов с платформой токенизации.
                                    Виктор обеспечил бесшовную интеграцию фронтенда и бэкенда, что
                                    позволило реализовать все инновационные функции CheckBrand - от
                                    генерации уникальных цифровых сертификатов до организации клубов
                                    коллекционеров брендовых товаров.

                                    Благодаря профессионализму и творческому подходу Виктора,
                                    пользователи CheckBrand получают исключительно комфортный и
                                    вовлекающий опыт взаимодействия с платформой. Это способствует
                                    росту лояльности брендов и их клиентов, открывая новые
                                    возможности для развития экосистемы токенизированных товаров.

                                    Виктор Потапов - ключевой специалист, чей талант и самоотдача
                                    помогают воплощать в жизнь грандиозную миссию CheckBrand по
                                    защите брендов и их клиентов от контрафакта. Его работа вносит
                                    неоценимый вклад в успех всего проекта.
`,
    },
    {
        name: 'Сагунов Матвей',
        role: 'Graphic designer (Главный дизайнер)',
        image: '/assets/img/team6.png',
        text: `
                                    Безупречный графический дизайн - визитная карточка нашей
                                    платформы токенизации брендовых вещей CheckBrand. Матвей
                                    Сагунов, талантливый дизайнер команды, виртуозно воплощает в
                                    жизнь самые смелые творческие идеи.

                                    Его работы отличаются безупречным вкусом, оригинальностью и
                                    высочайшим уровнем исполнения. Будь то стильный логотип,
                                    эффектный баннер или уникальные иллюстрации - Матвей всегда
                                    находит идеальное визуальное решение, чтобы в полной мере
                                    раскрыть потенциал бренда.

                                    Сочетая глубокие профессиональные знания с безграничной
                                    фантазией, Матвей создает яркие, запоминающиеся образы,
                                    способные вдохновлять и завораживать. Его творческий подход и
                                    безупречное чувство стиля неоценимы для продвижения цифровых
                                    коллекций брендов на платформе CheckBrand.

                                    Благодаря таланту и самоотдаче Матвея, наша платформа всегда
                                    выглядит стильно, современно и притягательно для ценителей моды
                                    и искусства. Его работы - это воплощение гармонии, эстетики и
                                    инновационного мышления.
                                `,
    },
];

const TeamSlider = () => {
    const sliderRef = useRef(null);
    const next = () => {
        sliderRef.current.slickNext();
    };
    const [openModal, setOpenModal] = useState(false);
    const [currentEmploye, setCurrentEmploye] = useState(null);

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <section className={styles.team} id="team">
            {/* <img src="/assets/img/blur-right.svg" alt="blur" className={styles.teamBlur} /> */}

            <div className={base.container}>
                <div className={styles.teamInner}>
                    <div className={styles.teamWrapper}>
                        <h2 className={cn(base.title2, styles.teamTitle)}>Команда проекта</h2>

                        <p className={styles.teamSubtitle}>
                            Наша миссия в том что бы ваш бренд становился большим и сильным,
                            <br />а пользователи счастливыми и довольными
                        </p>

                        <div className={styles.teamArrows}>
                            <button className={styles.teamArrow} onClick={previous}>
                                <ArrowLeftShort />
                            </button>

                            <button className={styles.teamArrow} onClick={next}>
                                <ArrowRightShort />
                            </button>
                        </div>

                        <p className={styles.teamText}>
                            Представляем вам самых выдающихся членов нашего дружного сообщества,
                            которые меняют мир к лучшему
                        </p>
                    </div>

                    <div className={styles.teamSlider}>
                        <Slider ref={sliderRef} {...settings} >
                            {TEAM_LIST.map((employe, index) => {
                                return (
                                    <TeamItem
                                        name={employe.name}
                                        key={index}
                                        role={employe.role}
                                        image={employe.image}
                                        text={employe.text}
                                        setOpenModal={setOpenModal}
                                        setCurrentEmploye={setCurrentEmploye}
                                    />
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
            <ModalNew active={openModal} setActive={setOpenModal}>
                <TeamModal employe={currentEmploye} />
            </ModalNew>
        </section>
    );
};

export default TeamSlider;
