import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NAME_VARIANTS } from '../../pages/useGetTableView';
import { useDispatch } from 'react-redux';
import { setParentsList } from '../../pages/Statistics2/Container/tableSlice';

const getNavigatePath = (list, parent, isFullList) => {
    if (parent) {
        let pageId = '';
        let accountId = '';
        let collectionId = '';

        for (let i = 0; i < list.length; i++) {
            if (list[i].type === 'page') {
                pageId = list[i].id;
            }
            if (list[i].type === 'brands') {
                accountId = list[i].id;
            }
            if (list[i].type === 'collections') {
                collectionId = list[i].id;
            }
        }
        const pathVariants = {
            page: `../`,
            brands: `../${parent}/${pageId}`,
            collections: `../${parent}/${pageId}/${accountId}`,
            packs: `../${parent}/${pageId}/${collectionId}/${accountId}`,
        };
        const pathVariantsFullList = {
            page: `../`,
            brands: `../${parent}?all=true`,
            collections: `../${parent}?all=true`,
            packs: `../${parent}?all=true`,
        };

        return isFullList ? pathVariantsFullList[parent] : pathVariants[parent];
    }
};

export const TableNavigation = memo(({ linksList, currentPage, setCurrentPage }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const getRoutesList = useMemo(() => {
        const viewModeAllRoutes = linksList.map((route) => {
            if (route) {
                return { type: route.type, name: NAME_VARIANTS[route.type] };
            }
        });

        return currentPage.isFullListMode ? viewModeAllRoutes : linksList;
    }, [linksList, currentPage]);

    const handleGoBack = useCallback(() => {
        if (linksList) {
            const prev = linksList[linksList.length - 1];
            if (prev && prev.type) {
                const route = getNavigatePath(getRoutesList, prev.type, currentPage.isFullListMode);
                    navigate(route);

               
            }
        }
    }, [linksList, getRoutesList, currentPage]);



    return (
        <h2 className="title">
            {currentPage && currentPage.name !== 'Категории' && (
                <span onClick={() => handleGoBack()}>
                    <img src="/assets/img/back-arrow.svg" alt="" />
                </span>
            )}

            {getRoutesList.map((parent) => {
                return (
                    <Link
                        to={getNavigatePath(getRoutesList, parent.type, currentPage.isFullListMode)}
                        key={parent.type}
                    >
                        {parent.name} -
                    </Link>
                );
            })}
            {currentPage && currentPage.name}
        </h2>
    );
});
