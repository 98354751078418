import Modal from "../../../../../../common/Modal";
import React from 'react'
import styles from './offer.module.css'
import {setIsBuyCertificateModalActive} from "../../../../../../redux/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import {TextMore} from "../../../../../../common/TextMore/TextMore";
import FullDescription from "../../../../../../common/FullDescription";

export const OfferItemModal = (
  {
    offer,
    token,
    setIsOfferModalActive,
    isOfferModalActive,
    setIsModalActive,
    myToken
  }) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.auth.isAuth)

    const handleCheckOfferType = (type) => {
      if(type === 'pass_to') return 'Проход'
      if(type === 'vip_service') return 'Сервис'
      if(type === 'discount') return 'Скидка'
      if(type === 'gift') return 'Подарок'
      if(type === 'unique') return 'Unique'
      if(type === 'premium') return 'Premium'
      if(type === 'vip') return 'VIP'
    }

    const handleCheckType = (type) => {
        if(type === 'unique') return <p className={'unique'} style={{margin: '0 auto'}}>Unique</p>
        if(type === 'premium') return <p className={'premium'} style={{margin: '0 auto'}}>Premium</p>
        if(type === 'vip') return <p className={'vip'} style={{margin: '0 auto'}}>VIP</p>
    }

  return (
    <>
      <Modal
        active={isOfferModalActive}
        title={offer.company.name}
        onClose={() => setIsOfferModalActive(!isOfferModalActive)}
      >
        <div style={{display: 'flex', justifyContent: 'flex-start', margin: '8px 0 36px 0', width: '100%', position: 'relative'}}>
          <p style={{color: 'hsla(210, 13%, 91%, 1)', position: 'absolute', left: 0, top: 0 }}>{handleCheckOfferType(offer.type)}</p>
            {handleCheckType(offer.certificate_type)}
        </div>

        <div className={styles.wrapper}>
          <p style={{color: 'hsla(210, 13%, 91%, 1)', fontWeight: '500', marginBottom: '20px'}}>{offer.preview}</p>
          <div style={{marginBottom: '36px'}}>
            <FullDescription description={offer.description} />
          </div>
          {offer.contacts && offer.contacts.length > 0 &&
            <div className={styles.footer}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {offer.contacts[0].site && <a target={'_blank'} href={`https://${offer.contacts[0].site}`}>{offer.contacts[0].site}</a>}
                {offer.contacts[0].phone && <a href={`tel:${offer.contacts[0].phone}`}>{offer.contacts[0].phone}</a>}
                {offer.contacts[0].social &&  <a target={'_blank'} href={`https://${offer.contacts[0].social}`}>{offer.contacts[0].social}</a> }
                {offer.contacts[0].email &&  <a href={`mailto:${offer.contacts[0].email}`}>{offer.contacts[0].email}</a> }

              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <p>Адрес:</p>
                <p>
                  {offer.contacts[0].state && offer.contacts[0].state},
                  {offer.contacts[0].country && offer.contacts[0].country},
                  {offer.contacts[0].city && offer.contacts[0].city},
                  {offer.contacts[0].address && offer.contacts[0].address}
                </p>
              </div>
            </div>
          }


        </div>

        <button
          className={`button token__card--content--price--buy`}
          style={{height: '52px', width: '100%', marginTop: '50px', marginBottom: '25px'}}
          onClick={() => {
              isAuth && myToken && setIsModalActive(true)
              isAuth && !myToken && dispatch(setIsBuyCertificateModalActive(true))
              setIsOfferModalActive(false)
              !isAuth && dispatch(setIsBuyCertificateModalActive(true))
          }}
        >
          Воспользоваться услугой
        </button>

      </Modal>
    </>
  )
}


// !myToken && isAuth && token.paid
