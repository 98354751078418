import React from 'react';

const Question = ({ ...props }) => {
    return (
        <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle
                cx="15.5"
                cy="15.5"
                r="14.4527"
                fill="transparent"
                stroke="currentColor"
                strokeWidth="2.09459"
            />
            <path
                d="M15.7956 18.6205H14.2288C14.0445 18.6205 13.9524 18.5345 13.9524 18.3625V17.404C13.9524 16.9247 14.063 16.5069 14.2841 16.1506C14.5176 15.7819 14.7941 15.487 15.1136 15.2658C15.4454 15.0323 15.771 14.805 16.0905 14.5838C16.4223 14.3626 16.6988 14.0923 16.92 13.7728C17.1535 13.4533 17.2702 13.0908 17.2702 12.6853C17.2702 12.12 17.092 11.6531 16.7357 11.2844C16.3916 10.9035 15.857 10.713 15.132 10.713C14.1981 10.713 13.3625 11.0632 12.6252 11.7637C12.4655 11.9234 12.3365 11.9111 12.2381 11.7268L11.4271 10.5655C11.3411 10.4427 11.3534 10.3198 11.464 10.1969C11.8695 9.81596 12.3979 9.49032 13.0492 9.21998C13.7005 8.94964 14.4132 8.81446 15.1873 8.81446C16.3547 8.81446 17.3439 9.15239 18.155 9.82825C18.9783 10.4918 19.3899 11.438 19.3899 12.6668C19.3899 13.2567 19.2732 13.7666 19.0397 14.1967C18.8185 14.6268 18.542 14.9709 18.2103 15.229C17.8908 15.4747 17.5651 15.7082 17.2333 15.9294C16.9138 16.1506 16.6374 16.4148 16.4039 16.722C16.1827 17.0292 16.0721 17.3917 16.0721 17.8095V18.3625C16.0721 18.5345 15.9799 18.6205 15.7956 18.6205ZM15.9984 19.9845C16.2564 20.2426 16.3854 20.5682 16.3854 20.9614C16.3854 21.3547 16.2564 21.6803 15.9984 21.9384C15.7526 22.1841 15.427 22.307 15.0214 22.307C14.6282 22.307 14.3087 22.1841 14.063 21.9384C13.8172 21.6803 13.6943 21.3547 13.6943 20.9614C13.6943 20.5682 13.8172 20.2426 14.063 19.9845C14.3087 19.7265 14.6282 19.5974 15.0214 19.5974C15.427 19.5974 15.7526 19.7265 15.9984 19.9845Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Question;
