export const generateQuery = (query) => {
    if (!query || !Object.values(query).length) {
        return '';
    }

    const obj = {};

    Object.keys(query).forEach((key) => {
        if (Array.isArray(query[key])) {
            obj[key] = `${query[key][0]}&${query[key]
                .slice(1)
                .reduce((acc, cur) => `${acc}&${key}=${cur}`, '')}`;
        } else {
            obj[key] = query[key];
        }
    });


    return `?${Object.keys(obj)
        .map((k) => `${(obj[k] !== undefined && obj[k] !== null)  ? `${k}=${obj[k]}` : ''}`)
        .join('&')}`;
};
