import React from 'react';

const Option = ({ ...props }) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 5.25C7.5 4.83579 7.83579 4.5 8.25 4.5H15C15.4142 4.5 15.75 4.83579 15.75 5.25C15.75 5.66421 15.4142 6 15 6H8.25C7.83579 6 7.5 5.66421 7.5 5.25Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 12.75C3 12.3358 3.33579 12 3.75 12H10.5C10.9142 12 11.25 12.3358 11.25 12.75C11.25 13.1642 10.9142 13.5 10.5 13.5H3.75C3.33579 13.5 3 13.1642 3 12.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.75 11.25C11.9216 11.25 11.25 11.9216 11.25 12.75C11.25 13.5784 11.9216 14.25 12.75 14.25C13.5784 14.25 14.25 13.5784 14.25 12.75C14.25 11.9216 13.5784 11.25 12.75 11.25ZM9.75 12.75C9.75 11.0931 11.0931 9.75 12.75 9.75C14.4069 9.75 15.75 11.0931 15.75 12.75C15.75 14.4069 14.4069 15.75 12.75 15.75C11.0931 15.75 9.75 14.4069 9.75 12.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.25 3.75C4.42157 3.75 3.75 4.42157 3.75 5.25C3.75 6.07843 4.42157 6.75 5.25 6.75C6.07843 6.75 6.75 6.07843 6.75 5.25C6.75 4.42157 6.07843 3.75 5.25 3.75ZM2.25 5.25C2.25 3.59315 3.59315 2.25 5.25 2.25C6.90685 2.25 8.25 3.59315 8.25 5.25C8.25 6.90685 6.90685 8.25 5.25 8.25C3.59315 8.25 2.25 6.90685 2.25 5.25Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Option;
