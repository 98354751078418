import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';


const OwnerItem = ({
    
}) => {
    return (
        <div className={styles.ownersItem}>
            <img src="/assets/img/brand-light.png" alt="light" className={styles.ownersItemLight} />

            <div className={styles.ownersItemWrapper}>
                <div className={styles.ownersItemUser}>
                    <div className={styles.ownersItemUserAvatar}>
                        <img src="/assets/img/silver.png" alt="img" />
                    </div>

                    <div className={styles.ownersItemUserInfo}>
                        <p className={styles.ownersItemUserName}>Виктор Сергеевич</p>

                        <p className={styles.ownersItemUserRole}>Collector Adran</p>
                    </div>
                </div>

                <div className={styles.ownersItemCollections}>
                    <div className={styles.ownersItemCollection}>
                        <img src="/assets/img/brand1.jpg" alt="brand" />
                    </div>

                    <div className={styles.ownersItemCollection}>
                        <img src="/assets/img/brand2.jpg" alt="brand" />
                    </div>

                    <div className={styles.ownersItemCollection}>
                        <img src="/assets/img/brand1.jpg" alt="brand" />
                    </div>

                    <div className={styles.ownersItemCollection}>
                        <img src="/assets/img/brand2.jpg" alt="brand" />
                    </div>

                    <Link to="#" className={styles.ownersItemCollectionsMore}>
                        Ещё 10
                    </Link>
                </div>

                <p className={cn(styles.ownersItemPrice, styles.silver)}>38,7 млн</p>
            </div>
        </div>
    );
};

export default OwnerItem;
