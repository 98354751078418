import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../index.module.css';

import Info from '../../../icons/Info';
import Vk from '../../../icons/Vk';
import Twitter from '../../../icons/Twitter';
import Youtube from '../../../icons/Youtube';
import Instagram from '../../../icons/Instagram';
import Telegram2 from '../../../icons/Telegram2';
import Share from '../../../icons/Share';
import { S3Context } from '../../../context/S3Context';

const CollectionItem = ({ collection }) => {
    const {
        id,
        featured,
        name,
        logo,
        items_count: itemsCount,
        floor_price_count: floorPriceCount,
        items_count,
        owners_count,
        description,
        volume_troded_count,
        link_instagram: linkInstagram,
        link_telegram: linkTelegram,
        link_vk: linkVk,
        link_yoursite: linkYoursite,
        link_youtube: linkYoutube } = collection;
        
        const {
            actions: { getImageBacketPath },
        } = React.useContext(S3Context);

    return (
        <div className={ styles.brandItemWrapper }>
            <div className={ styles.brandItem }>
                <img src="/assets/img/element-fire.png" alt="decor" className={ styles.brandItemDecor } />

                <Link to={ `/collection/${id}` } className={ styles.collectionItemImgInner }>
                    <img src={ getImageBacketPath(featured) } alt="image" />
                </Link>

                <div className={ styles.collectionItemInfo }>
                    <p className={ styles.brandItemInfoName }>{ name }</p>

                    <div className={ styles.brandItemInfoTags }>
                        <p className={ styles.brandItemInfoTag }>{ items_count == owners_count ? 'Продано' : 'В продаже' }</p>
                    </div>

                    <div className={ styles.brandItemInfoWrapper }>
                        <div className={ styles.brandItemInfoPoints }>
                            <div className={ styles.brandItemInfoPoint }>
                                <p className={ styles.brandItemInfoPointName }>Выпуск</p>

                                <p className={ styles.brandItemInfoPointValue }>{ itemsCount } шт.</p>
                            </div>

                            <div className={ styles.brandItemInfoPoint }>
                                <p className={ styles.brandItemInfoPointName }>Мин. цена</p>

                                <p className={ styles.brandItemInfoPointValue }>{  Math.floor(floorPriceCount/100) } RUB</p>
                            </div>
                        </div>

                        <div className={ styles.brandItemInfoButtonWrapper }>
                            <button className={ styles.brandItemInfoButton }>
                                <Info />
                            </button>

                            <div className={ styles.brandItemInfoPopupWrapper }>
                                <div className={ styles.brandItemInfoPopup }>
                                    <div className={ styles.brandItemInfoPopupContent }>
                                        <div className={ styles.brandItemInfoWeight }>
                                            <img src={ getImageBacketPath(logo) } alt="logo" className={ styles.brandItemInfoWeightImg }  />

                                            <div className={ styles.brandItemInfoWeightInner }>
                                                <p className={ styles.brandItemInfoWeightText }>Объем продаж</p>

                                                <p className={ styles.brandItemInfoTag }>{ Math.floor(volume_troded_count/100)} RUB</p>
                                            </div>
                                        </div>

                                        <p className={ styles.brandItemInfoPopupName }>{ name }</p>

                                        <p className={ styles.brandItemInfoPopupText }>
                                            { description }
                                        </p>

                                        <div className={ styles.brandItemPopupSocialLinks }>
                                            {
                                                linkVk &&
                                                <a href={ linkVk } className={ styles.brandItemPopupSocialLink }>
                                                    <Vk />
                                                </a>
                                            }
                                            {
                                                linkInstagram &&
                                                <a href={ linkInstagram } className={ styles.brandItemPopupSocialLink }>
                                                    <Instagram />
                                                </a>
                                            }
                                            {
                                                linkYoutube &&
                                                <a href={ linkYoutube } className={ styles.brandItemPopupSocialLink }>
                                                    <Youtube />
                                                </a>

                                            }
                                            {
                                                linkTelegram &&
                                                <a href={ linkTelegram } className={ styles.brandItemPopupSocialLink }>
                                                    <Telegram2 />
                                                </a>
                                            }
                                        </div>
                                        {
                                            linkYoursite &&
                                            <a href= { linkYoursite } className={ styles.brandItemSite }>
                                                <Share />
                                                { linkYoursite }
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionItem;
