import React, {useState} from 'react';

import './index.css';
import FullDescription from '../../common/FullDescription';
import InfoBlock from '../../common/InfoBlock';

const Brand = () => {
    const [social, setSocial] = useState(false);

    return (
        <>
            <div
                className="collection__header"
                style={{
                    backgroundImage: `url('/assets/img/about1.png')`,
                    backgroundSize: 'cover',
                    objectFit: 'cover',
                    backgroundPosition: 'center',
                }}
            />

            <div className="collection">
                <div className="collection__social--mobile">
                    <a href="#" className="collection__social--share">
                        <img src="/assets/img/share2.svg" alt="share" />
                    </a>

                    <div
                        className="collection__dots--inner"
                        id="collection__social__modal"
                        onClick={() => setSocial((prev) => !prev)}
                    >
                        <img
                            src="/assets/img/dots.svg"
                            alt="dots"
                            className="collection__social--dots"
                        />

                        {social && 
                            <div className="collection__dots--social">
                                <p className="collection__dots--title">Links</p>

                                <a
                                    href="#"
                                    className="collection__dots--link"
                                >
                                    <img
                                        src="/assets/img/discord.svg"
                                        alt="insta"
                                        className="collection__dots--link--icon"
                                    />
                                    Discord
                                </a>

                                <a
                                    href="#"
                                    className="collection__dots--link"
                                >
                                    <img
                                        src="/assets/img/insta.svg"
                                        alt="insta"
                                        className="collection__dots--link--icon"
                                    />
                                    Instagram
                                </a>

                                <a
                                    href="#"
                                    className="collection__dots--link"
                                >
                                    <img
                                        src="/assets/img/global.svg"
                                        alt="medium"
                                        className="collection__dots--link--icon"
                                    />

                                    
                                </a>

                                <a
                                    href="#"
                                    className="collection__dots--link"
                                >
                                    <img
                                        src="/assets/img/opensea.svg"
                                        alt="opensea"
                                        className="collection__dots--link--icon"
                                    />
                                    OpenSea
                                </a>

                                <a
                                    href="#"
                                    className="collection__dots--link"
                                >
                                    <img
                                        src="/assets/img/twitter.svg"
                                        alt="Twitter"
                                        className="collection__dots--link--icon"
                                    />
                                    Twitter
                                </a>
                            </div>
                        }
                    </div>
                </div>

                <div className="container">
                    <div className="collection__inner">
                        <div className="collection__avatar--inner circle">
                            <img
                                src="/assets/img/about-img1.png"
                                alt="avatar"
                                className="collection__avatar"
                            />
                        </div>

                        <div className="collection__link--inner">
                            <h2 className="title collection__title">Reebok</h2>

                            <div className="collection__social--inner">
                                <a
                                    href="#"
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/globus.svg"
                                        alt="globus"
                                        className="collection__social--icon"
                                    />
                                </a>

                                <a
                                    href="#"
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/twitter.svg"
                                        alt="twitter"
                                        className="collection__social--icon"
                                    />
                                </a>

                                <a
                                    href="#"
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/insta.svg"
                                        alt="instagram"
                                        className="collection__social--icon"
                                    />
                                </a>

                                <a
                                    href="#"
                                    className="collection__social--link"
                                >
                                    <img
                                        src="/assets/img/discord.svg"
                                        alt="discord"
                                        className="collection__social--icon"
                                    />
                                </a>

                                <div className="collection__social--link">
                                    <img
                                        src="/assets/img/share2.svg"
                                        alt="share"
                                        className="collection__social--icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <InfoBlock
                            data={[
                                {
                                    title: 'total volume',
                                    value: `0 ETH`,
                                },
                                {
                                    title: 'floor price',
                                    value: `--`,
                                },
                                {
                                    title: 'collections',
                                    value: `0`,
                                },
                                {
                                    title: 'items',
                                    value: "0",
                                },
                                {
                                    title: 'owners',
                                    value: "0",
                                },
                            ]}
                        />

                        <FullDescription description='Click "Get on whitelist" and fill in the form and confirm. After you receive a notification in the mail, make the booking. Wait for the minting to begin.' />

                        <div className="brand__items">
                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img1.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value">
                                                Whitelisting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img2.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value booking">
                                                Booking
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value private">
                                                Private minting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value public">
                                                Public minting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value sold">
                                                Sold out
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value sold">
                                                Sold out
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value public">
                                                Public minting
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value sold">
                                                Sold out
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brand__item">
                                <div className="brand__item--img--inner">
                                    <img src="/assets/img/about-img3.png" alt="img" className="brand__item--img" />
                                </div>

                                <div className="brand__item--text--inner">
                                    <p className="brand__item--title">
                                        Reebok NFT Certificates
                                    </p>

                                    <div className="brand__item--elements">
                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Floor
                                            </p>

                                            <p className="brand__item--element--value">
                                                1.8 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Volume
                                            </p>

                                            <p className="brand__item--element--value">
                                                356 ETH
                                            </p>
                                        </div>

                                        <div className="brand__item--element">
                                            <p className="brand__item--element--title">
                                                Status
                                            </p>

                                            <p className="brand__item--element--value sold">
                                                Sold out
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Brand;