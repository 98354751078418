import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Car2 from '../../icons/Car2';
import Watch2 from '../../icons/Watch2';
import Tshirt from '../../icons/Tshirt';
import User2 from '../../icons/User2';
import Alcohol2 from '../../icons/Alcohol2';
import Diamond2 from '../../icons/Diamond2';
import { Tooltip } from '../../components/Tooltip';
import OwnerItem from './OwnerItem';

const OwnersPage = () => {
    const [fullText, setFullText] = React.useState(false);

    const [description, setDescription] = React.useState(
        `Ваш личный бренд начинается здесь!
        
        В современном обществе токенизация становится настоящим трендом, позволяя вам не только инвестировать в предметы роскоши, но и формировать свой уникальный личный бренд В современном обществе токенизация становится настоящим трендом, позволяя вам не только инвестировать в предметы роскоши, но и формировать свой уникальный личный бренд`,
     );

    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };

    return (
        <div className={styles.marginWrapper}>
            <section className={styles.owners}>
                <img src="/assets/img/owners-bg.png" alt="main bg" className={styles.ownersBg} />

                <div className={base.container}>
                    <div className={styles.ownersInner}>
                        <h1 className={styles.ownersTitle}>Владельцы токенизированных вещей</h1>

                        <div className={styles.ownersDesc} style={{ whiteSpace: 'pre-line' }}>
                            <p>
                                {description && description.length > 150 && !fullText
                                    ? `${description.slice(0, 150)}...`
                                    : description}
                            </p>

                            {description && description.length > 150 && (
                                <a
                                    href="#"
                                    className={styles.ownersTextShow}
                                    onClick={showText}
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {fullText ? 'Свернуть' : 'Развернуть'}
                                </a>
                            )}
                        </div>

                        <div className={styles.ownersButtons}>
                            <button className={cn(base.orangeButton, styles.ownersButton)}>
                                Токенизировать свой авто
                            </button>

                            <button className={cn(base.whiteButton, styles.ownersButton)}>
                                Узнать подробнее
                            </button>
                        </div>

                        <div className={styles.ownersContent}>
                            <div className={styles.ownersFilter}>
                                <div className={styles.ownersSearchInner}>
                                    <input className={styles.searchInput} type="text" placeholder='Поиск по имени, логину...' />

                                    <button className={styles.searchButton}>
                                        Найти
                                    </button>
                                </div>

                                <div className={styles.tabsInner}>
                                    <p className={styles.tabsText}>Коллекция<br/>токенов</p>

                                    <div className={styles.tabsContent}>
                                        <button className={cn(styles.tabsItem, styles.tabsItemLarge)}>
                                            <Car2 />
                                        </button>

                                        <button className={styles.tabsItem}>
                                            <Watch2 />
                                        </button>

                                        <Tooltip text="Для приобретения токенов верифицируйте телефон или почту">
                                            <button className={cn(styles.tabsItem, styles.active)}>
                                                <Tshirt />
                                            </button>
                                        </Tooltip>

                                        <button className={styles.tabsItem}>
                                            <User2 />
                                        </button>

                                        <button className={cn(styles.tabsItem, styles.tabsItemLarge)}>
                                            <Alcohol2 />
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.tabsInner}>
                                    <p className={styles.tabsText}>Стоимость<br />коллекции, руб.</p>

                                    <div className={styles.tabsContent}>
                                        <button className={cn(styles.tabsItem, styles.tabsItemLarge, styles.small)}>
                                            <Diamond2 />
                                        </button>

                                        <button className={cn(styles.tabsItem, styles.medium)}>
                                            <Diamond2 />
                                        </button>

                                        <button className={cn(styles.tabsItem, styles.tabsItemLarge, styles.big, styles.active)}>
                                            <Diamond2 />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.filtersMobile}>
                                <button className={styles.filtersMobileItem}>
                                    Поиск
                                </button>

                                <button className={styles.filtersMobileItem}>
                                    Коллекция токенов
                                </button>

                                <button className={styles.filtersMobileItem}>
                                    Стоимость
                                </button>
                            </div>

                            <div className={styles.ownersItems}>
                                <OwnerItem />

                                <div className={styles.ownersItem}>
                                    <img src="/assets/img/brand-light.png" alt="light" className={styles.ownersItemLight} />

                                    <div className={styles.ownersItemWrapper}>
                                        <div className={styles.ownersItemUser}>
                                            <div className={styles.ownersItemUserAvatar}>
                                                <img src="/assets/img/gold.png" alt="img" />
                                            </div>

                                            <div className={styles.ownersItemUserInfo}>
                                                <p className={styles.ownersItemUserName}>Виктор Сергеевич</p>

                                                <p className={styles.ownersItemUserRole}>Collector Adran</p>
                                            </div>
                                        </div>

                                        <div className={styles.ownersItemCollections}>
                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand1.jpg" alt="brand" />
                                            </div>

                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand2.jpg" alt="brand" />
                                            </div>

                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand1.jpg" alt="brand" />
                                            </div>

                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand2.jpg" alt="brand" />
                                            </div>

                                            <Link to="#" className={styles.ownersItemCollectionsMore}>Ещё 10</Link>
                                        </div>

                                        <p className={cn(styles.ownersItemPrice, styles.gold)}>38,7 млн</p>
                                    </div>
                                </div>
                                
                                <div className={styles.ownersItem}>
                                    <img src="/assets/img/brand-light.png" alt="light" className={styles.ownersItemLight} />

                                    <div className={styles.ownersItemWrapper}>
                                        <div className={styles.ownersItemUser}>
                                            <div className={styles.ownersItemUserAvatar}>
                                                <img src="/assets/img/bronze.png" alt="img" />
                                            </div>

                                            <div className={styles.ownersItemUserInfo}>
                                                <p className={styles.ownersItemUserName}>Виктор Сергеевич</p>

                                                <p className={styles.ownersItemUserRole}>Collector Adran</p>
                                            </div>
                                        </div>

                                        <div className={styles.ownersItemCollections}>
                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand1.jpg" alt="brand" />
                                            </div>

                                            <div className={styles.ownersItemCollection}>
                                                <img src="/assets/img/brand2.jpg" alt="brand" />
                                            </div>
                                        </div>

                                        <p className={cn(styles.ownersItemPrice, styles.bronze)}>38,7 млн</p>
                                    </div>
                                </div>

                                <div className={styles.paggination}>
                                    <a className={cn(styles.pagginationLink, styles.disabled)} href="#">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.1602 7.41L11.5802 12L16.1602 16.59L14.7502 18L8.75016 12L14.7502 6L16.1602 7.41Z" fill="currentColor"/>
                                        </svg>
                                    </a>

                                    <a className={cn(styles.pagginationLink, styles.active)} href="#">
                                        1
                                    </a>

                                    <a className={styles.pagginationLink} href="#">
                                        2
                                    </a>

                                    <a className={styles.pagginationText} href="#">
                                        ...
                                    </a>

                                    <a className={styles.pagginationLink} href="#">
                                        9
                                    </a>

                                    <a className={styles.pagginationLink} href="#">
                                        10
                                    </a>

                                    <a className={styles.pagginationLink} href="#">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.83984 7.41L12.4198 12L7.83984 16.59L9.24984 18L15.2498 12L9.24984 6L7.83984 7.41Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OwnersPage;
