exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tooltip_wrapper__1nxaj {\n    display: flex;\n    grid-gap: 15px;\n    gap: 15px;\n    align-items: center;\n    padding: 0 5px;\n    position: relative;\n}\n\n.tooltip_tooltipEl__37pOx {\n    position: absolute;\n    right: -240px;\n    min-height: 120px;\n    width: 228px;\n    background: hsla(0, 0%, 10%, 1);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 16px 14px 16px;\n    z-index: 1;\n}\n\n.tooltip_tooltipEl__37pOx p {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: white;\n}\n\n.tooltip_btn__1C-hz {\n    position: absolute;\n    top: 4px;\n    right: 4px;\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "tooltip_wrapper__1nxaj",
	"tooltipEl": "tooltip_tooltipEl__37pOx",
	"btn": "tooltip_btn__1C-hz"
};