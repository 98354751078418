import React from 'react';

import './index.css';
import FilterItem from '../../components/FilterItem';
import SortItem from '../../components/SortItem';
import AdminsItem from '../Admins/AdminsItem';
import { CustomSelect } from '../../common/CustomSelect';
import Modal from '../../common/Modal';
import CollectionInfo from '../../common/CollectionInfo';
import FilterTable from '../../components/FilterTable';

const sortList = [
    { value: 'recent', name: 'Recent changes' }
];

const sortList2 = [
    { value: '90', name: 'Last 90 Days' }
];

const data = [
    {
        status: 'Platform Administrator',
        avatar: '/assets/img/avatar.png',
        user: {
            name: 'User name',
        },
        wallet: '0x5218C...F96Ff3',
        createdby: 'User name',
        description: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        access: 'Brand name name name Brand name name name Brand name name name',
        last: '5 days ago'
    },
    {
        status: 'User',
        avatar: '/assets/img/avatar.png',
        user: {
            name: 'User name',
        },
        wallet: '0x5218C...F96Ff3',
        createdby: 'User name',
        description: 'Description description... Description description. Description description... Description description... Descripti .. Descripti',
        access: 'Brand name name name Brand name name name Brand name name name',
        last: '5 days ago'
    }
];

const Royalty = () => {
    const [createModal, setCreateModal] = React.useState(false);
    const [filter, setFilter] = React.useState('recent');
    const [filter2, setFilter2] = React.useState('90');
    const [filterActive, setFilterActive] = React.useState(false);
    const [sortActive, setSortActive] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [filtersContent, setFiltersContent] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            text: 'Ready',
            active: false,
        },
        {
            text: 'Waiting',
            active: false,
        },
        {
            text: 'Stoped',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            text: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const changeSearch = (e) => {
        setSearch(e.target.value);
    };

    const emptySearch = () => {
        setSearch('');
    };

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    return (
        <>
            <section className="admin__padding admins">
                <div className="container">
                    <div className="admins__inner">
                        <h1 className="title">Royalty Recipients</h1>

                        <FilterTable
                            sorts={[
                                {
                                    optionsList: sortList2,
                                    value: filter2,
                                    placeholder: 'Select filter',
                                    onChange: filterChange2,
                                },
                                {
                                    optionsList: sortList,
                                    value: filter,
                                    placeholder: 'Select filter',
                                    onChange: filterChange,
                                },
                            ]}
                            filters={[
                                {
                                    title: "Status",
                                    value: "2",
                                    elements: filterData,
                                    setData: setFilterData,
                                    filters: filtersContent,
                                    setFilters: setFiltersContent
                                },
                                {
                                    title: "Character 1",
                                    value: "3",
                                    elements: filterData2,
                                    setData: setFilterData2,
                                    filters: filtersContent,
                                    filter: true,
                                    setFilters: setFiltersContent
                                }
                            ]}
                            button={
                                <button className="button collection__get default__hover" onClick={() => setCreateModal(true)}>
                                    Create recipient
                                </button>
                            }
                            tokensCount="25 points"
                        >
                            <div className="stats__item--names whithdraw__names">
                                <div className="admins__item--item stats__item--name off700">
                                    Status
                                </div>

                                <div className="admins__item--item stats__item--name">
                                    Point
                                </div>

                                <div className="admins__item--item stats__item--name">
                                    Address
                                </div>

                                <div className="admins__item--item stats__item--name off700 activeoff1200">
                                    Moderator
                                </div>

                                <div className="admins__item--item stats__item--name off998 activeoff1600">
                                    Description
                                </div>

                                <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                    Access
                                </div>

                                <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                    Date
                                </div>

                                <div className="admins__item--item stats__item--name">
                                    Action
                                </div>
                            </div>

                            {data.map((data, id) => (
                                <AdminsItem key={id} data={data} id={id + 1} />
                            ))}
                        </FilterTable>

                        {/* <div className="collection__filter--content">
                            <button className="button collection__filter--button" onClick={() => setFilterActive((prev) => !prev)}>
                                <img src="/assets/img/filter.svg" alt="filter" className="collection__filter--icon" />

                                <img src="/assets/img/filter2.svg" alt="filter" className="collection__filter--icon mobile" />

                                <p className="collection__filter--button--text">Filters</p>
                            </button>

                            <button className="button collection__sort--button" onClick={() => setSortActive((prev) => !prev)}>
                                <img src="/assets/img/sort.svg" alt="sort" className="collection__filter--icon mobile" />

                                <p className="collection__sort--button--text">Sort</p>
                            </button>

                            <div className="collection__search--inner">
                                <input type="text" className="input header__search" placeholder="Search by token name" value={search} onChange={changeSearch} />

                                <img src="/assets/img/search.svg" alt="search" className="header__search--icon" />

                                {search && (
                                    <img src="/assets/img/cross2.svg" alt="cross" className="header__search--remove" onClick={emptySearch} />
                                )}
                            </div>

                            <div className="collection__filter--order">
                                <CustomSelect
                                    optionsList={sortList2}
                                    value={filter2}
                                    placeholder="Select filter"
                                    onChange={filterChange2}
                                />
                            </div>

                            <div className="collection__filter--order">
                                <CustomSelect
                                    optionsList={sortList}
                                    value={filter}
                                    placeholder="Select filter"
                                    onChange={filterChange}
                                />
                            </div>

                            <button className="button collection__get default__hover" onClick={() => setCreateModal(true)}>
                                Create recipient
                            </button>
                        </div>

                        <div className="collection__content">
                            {filterActive && (
                                <div className="collection__filter--box">
                                    <div className="collection__filter--title--box">
                                        <p className="collection__filter--title">Filters</p>

                                        <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--title--cross" onClick={() => setFilterActive((prev) => !prev)} />
                                    </div>

                                    <FilterItem
                                        title="Status"
                                        value="2"
                                        elements={filterData}
                                        setData={setFilterData}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />

                                    <FilterItem
                                        title="Character 1"
                                        value="3"
                                        elements={filterData2}
                                        setData={setFilterData2}
                                        setFilters={setFilters}
                                        filter
                                        filters={filters}
                                    />

                                    <div className="collection__filter--buttons">
                                        <button className="button collection__filter--button--filter">
                                            Clear all
                                        </button>

                                        <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive((prev) => !prev)}>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            )}

                            {sortActive && (
                                <div className="collection__sort--box">
                                    <div className="collection__filter--title--box">
                                        <p className="collection__filter--title">Sort by</p>

                                        <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--title--cross" onClick={() => setSortActive((prev) => !prev)} />
                                    </div>

                                    <div className="collection__sort--content">
                                        {sortList.map((data, id) => (
                                            <SortItem
                                                key={id}
                                                text={data.name}
                                                id={`${data.value}_${id}`}
                                            />
                                        ))}
                                    </div>

                                    <div className="collection__filter--buttons">
                                        <button className="button collection__sort--button--filter blue__button" onClick={() => setSortActive((prev) => !prev)}>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className="collection__content--preitems">
                                <CollectionInfo tokensCount="10 persons" />

                                <div className="collection__filter--active">
                                    <div className="collection__filter--active--content">
                                        <button className="button collection__filter--active--item">
                                            <p className="collection__filter--active--item--text">
                                                Ready
                                            </p>

                                            <img src="/assets/img/cross2.svg" alt="cross" className="collection__filter--active--item--delete" />
                                        </button>
                                    </div>

                                    <button className="button collection__filter--clear">
                                        Clear All
                                    </button>
                                </div>

                                <div className="collection__content--items">
                                    {false ? <div className="collection__items--none">
                                        <img src="/assets/img/items-none.svg" alt="none" />

                                            No items to display
                                        </div>
                                    :
                                    <div className={`stats__content${filterActive ? " active" : ""}`}>
                                        <div className="stats__item--names whithdraw__names">
                                            <div className="admins__item--item stats__item--name off700">
                                                Status
                                            </div>

                                            <div className="admins__item--item stats__item--name">
                                                User
                                            </div>

                                            <div className="admins__item--item stats__item--name">
                                                Wallet
                                            </div>

                                            <div className="admins__item--item stats__item--name off700 activeoff1200">
                                                Created by
                                            </div>

                                            <div className="admins__item--item stats__item--name off998 activeoff1600">
                                                Description
                                            </div>

                                            <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                                Access
                                            </div>

                                            <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                                Date
                                            </div>

                                            <div className="admins__item--item stats__item--name">
                                                Action
                                            </div>
                                        </div>

                                        {data.map((data, id) => <AdminsItem key={id} data={data} id={id + 1} />)}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <Modal active={createModal} setActive={setCreateModal} title="Create Recipient">
                <p className="modal__subtitle--blue">
                    By administrator name
                </p>

                <p className="modal__text center">
                    Select the royalty recipient from among the users
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            User status
                        </p>

                        <CustomSelect
                            optionsList={sortList2}
                            value={filter2}
                            placeholder="Select filter"
                            onChange={filterChange2}
                        />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            User name
                        </p>

                        <CustomSelect
                            optionsList={sortList2}
                            value={filter2}
                            placeholder="Select filter"
                            onChange={filterChange2}
                        />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Wallet
                        </p>

                        <input type="text" className="input modal__input" placeholder="Wallet address" />
                    </div>

                    <div className="modal__inputs--item">
                        <div className="admin__add--title--inner">
                            <p className="modal__inputs--item--text">
                                Description
                            </p>

                            <p className="admin__add--text">500</p>
                        </div>

                        <textarea type="text" className="input modal__input textarea" placeholder="Enter a description of the user (if necessary)"></textarea>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Create a position
                </button>
            </Modal>
        </>
    )
}

export default Royalty;