import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthElements = ({ setActive, menu, setMenu }) => {
    const auth = useSelector((state) => state.auth);

    const classNameToggle = useCallback(() => {
        document.body.classList.toggle('scroll');
    }, []);

    const hideMenu = () => {
        setMenu((prev) => !prev);
        setActive(false);
        classNameToggle();
    };

    React.useEffect(() => {
        const menu = document.getElementById('menu');

        menu.addEventListener('click', classNameToggle);

        return () => {
            menu.removeEventListener('click', classNameToggle);
        };
    }, []);

    return (
        <>
            {auth.isAuth ? (
                <Link to="/profile" className="header__wallet--button">
                    <img
                        src="/assets/img/account.svg"
                        alt="Profile"
                        className="header__wallet--icon"
                    />
                </Link>
            ) : (
                <button
                    onClick={() => setActive((prev) => !prev)}
                    className="button header__wallet--button"
                >
                    <img
                        src="/assets/img/account.svg"
                        alt="Profile"
                        className="header__wallet--icon"
                    />
                </button>
            )}

            <button
                className="button header__wallet--button"
                onClick={() => setActive((prev) => !prev)}
            >
                <img src="/assets/img/wallet.svg" alt="Wallet" className="header__wallet--icon" />
            </button>

            <div className="menu__inner">
                <img src={`/assets/img/${menu ? "cross2" : "menu"}.svg`} alt="menu" className="menu" id="menu" onClick={hideMenu}/>
            </div>
        </>
    );
};

export default AuthElements;
