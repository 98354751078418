import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@mui/material';
import { useDialog } from '../../hooks/useDialog';
import ImageModalDialog from './UI/ImageModalDialog';

import css from './ImageWithModal.module.css';

const ImageWithModal = (props) => {
    const { src, alt, className, style } = props;

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imageDialog = useDialog();

    return (
        <>
            <div
                className={css.container}
                style={{
                    minHeight: !isImageLoaded ? '580px' : 0,
                }}
            >
                {!isImageLoaded && (
                    <Skeleton
                        sx={{ bgcolor: 'grey.900' }}
                        variant="rectangular"
                        className={css.skeleton}
                        animation="wave"
                    />
                )}
                <LazyLoadImage
                    effect="blur"
                    alt={alt}
                    src={src}
                    onLoad={() => {
                        setIsImageLoaded(true);
                    }}
                    onClick={imageDialog.onShow}
                    style={style}
                    className={className}
                />
            </div>
            {imageDialog.visible && (
                <ImageModalDialog
                    alt={alt}
                    src={src}
                    open={imageDialog.visible}
                    onClose={imageDialog.onClose}
                />
            )}
        </>
    );
};

export default React.memo(ImageWithModal);
