import React from 'react';
import Linkify from 'react-linkify';

import css from './index.module.css';

const FullDescription = ({ description, className, url,isOffer }) => {
    const [fullDesc, setFullDesc] = React.useState(false);
    const handleOpenOffer = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };
    return (
        <div className={`${css.collectionDescInner}${className ? ` ${className}` : ''}`}>
            <Linkify
                componentDecorator={(decoratedHref, decoratedText) => (
                    <a key={decoratedText} href={decoratedHref} className={css.descLink}>
                        {decoratedText}
                    </a>
                )}
            >
                <p className={`${css.collectionDesc}${fullDesc ? ` ${css.full}` : ''}`}>
                    {description}

                    {isOffer && (
                        <div style={{ color: 'white' }}>
                            Подробнее читайте в{' '}
                            <span
                                style={{ color: '#2081E2', textDecoration: 'underline' }}
                                onClick={() => handleOpenOffer()}
                            >
                                Договоре оферты
                            </span>
                        </div>
                    )}
                </p>
            </Linkify>
            <div style={{ display: 'flex', gap: '20px' }}>
                <div
                    className={css.collectionDescFull}
                    onClick={() => setFullDesc((prev) => !prev)}
                >
                    {fullDesc ? 'Скрыть' : 'Показать'}

                    <img
                        src="/assets/img/arrow-top.svg"
                        alt="arrow"
                        className={`${css.collectionDescFullIcon}${fullDesc ? ` ${css.full}` : ''}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default FullDescription;
