import React from 'react'
import Modal from "../../../../../../common/Modal";
import {useSelector} from "react-redux";
import styles from '../../../OfferModal/styles.module.css'

export const UserThanksModal = ({contactType, item, setIsUserThanksModalActive, isUserThankModalActive}) => {
  const username = useSelector(state => state.auth.username)
  const telegram = useSelector(state => state.auth.telegram)
  const whatsapp = useSelector(state => state.auth.whatsapp)

  return (
    <Modal
      title={'Получение услуги'}
      active={isUserThankModalActive}
      setActive={setIsUserThanksModalActive}
      onClose={() => setIsUserThanksModalActive(false)}
      size={'medium'}
    >
      <p className={styles.name}>{username}</p>
      <img src={item && item.company && item.company.logo} className={styles.img}/>
      <p className={styles.text}>{contactType === 'whatsapp' ? whatsapp : telegram}</p>
      <p className={styles.text} style={{fontWeight: '400', marginTop: '8px'}}>Менеджер свяжется с вами в течении минуты</p>
      <button
        className={`button token__card--content--price--buy`}
        style={{height: '52px', width: '100%', marginTop: '50px'}}
        onClick={() => setIsUserThanksModalActive(false)}
      >
        Благодарим вас
      </button>
    </Modal>
  )
}