import React, { useEffect } from 'react';
import ModalNew from '../../../../common/ModalNew';
import Check3 from '../../../../icons/Check3';
import styles from '../../index.module.css';
import cn from 'classnames';
import base from '../../../../styles/base.module.css';
import { useNavigate, useParams } from 'react-router-dom';
const SuccessApplicationModal = () => {
const navigate = useNavigate()
    return (
        <div>
            <button
                style={{ color: 'white' }}
                onClick={() => navigate('/')}
            >
                закрыть
            </button>
            <div className={styles.modalSuccess}>
                <Check3 />

                <p className={cn(base.title2, styles.collabSuccessTitle)}>Ваша заявка принята</p>

                <p className={styles.modalSuccessText}>
                    Менеджер свяжется с вами в течение 30 мин <span>(в рабочее время)</span>
                </p>
            </div>
        </div>
    );
};

export default SuccessApplicationModal;
