import React from 'react';

const Telegram2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.09992 5.59647C5.39487 3.76188 8.25885 2.55241 9.69185 1.96805C13.7834 0.299594 14.6335 0.00976514 15.1877 9.27819e-05C15.3095 -0.00191006 15.582 0.0277027 15.7586 0.16813C15.9076 0.286703 15.9486 0.44688 15.9683 0.5593C15.9879 0.671721 16.0123 0.927819 15.9929 1.12793C15.7712 3.41192 14.8118 8.95457 14.3237 11.5127C14.1172 12.5951 13.7105 12.958 13.3168 12.9936C12.4613 13.0707 11.8116 12.4392 10.9829 11.9067C9.68624 11.0733 8.95369 10.5545 7.69503 9.74136C6.24042 8.80157 7.18338 8.28505 8.01236 7.44091C8.22931 7.21999 11.999 3.85836 12.0719 3.55341C12.0811 3.51527 12.0895 3.37311 12.0034 3.29804C11.9172 3.22297 11.7901 3.24864 11.6983 3.26906C11.5683 3.29799 9.4968 4.64035 5.48389 7.29611C4.89591 7.69195 4.36333 7.88482 3.88616 7.87472C3.36012 7.86357 2.34822 7.58311 1.59598 7.34338C0.673328 7.04933 -0.0599784 6.89387 0.00387615 6.3945C0.0371355 6.13439 0.402482 5.86838 1.09992 5.59647Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Telegram2;
