import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';

const WhithdrawSkeleton = () => {
    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <Skeleton containerClassName="withdraw__title--skeleton" className="skeleton__content" />

                    <div className="collection__data">
                        <Skeleton containerClassName="collection__data--skeleton" className="skeleton__content" />
                    </div>

                    <div className="collection__desc--inner">
                        <Skeleton containerClassName="collection__desc--skeleton" className="skeleton__content" />
                    </div>

                    <div className="collection__filter--content">
                        <Skeleton containerClassName="collection__filter--skeleton" className="skeleton__content" />
                        <Skeleton containerClassName="collection__filter--skeleton mobile" className="skeleton__content" />
                    </div>

                    <div className="collection__content">
                        <div className="collection__content--preitems">
                            <div className="collection__content--info">
                                <Skeleton containerClassName="collection__content--info--skeleton" className="skeleton__content" />
                            </div>

                            <div className="collection__content--items">
                                <div className="stats__content stats__content--skeletons">
                                    {new Array(0, 0, 0).map((_, id) => <Skeleton containerClassName="stats__content--skeleton" className="skeleton__content" />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhithdrawSkeleton;
