exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TokenActionsModal_box__1d6pn {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    grid-gap: 20px;\n    gap: 20px;\n    width: 100%;\n}\n\n.TokenActionsModal_block__3qiyx {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 5px;\n    gap: 5px;\n    color: white;\n}\n\n.TokenActionsModal_link__2jLD9 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 21.44px;\n    letter-spacing: 0.02em;\n    text-align: left;\n    color: hsla(210, 77%, 51%, 1);\n}\n\n.TokenActionsModal_inputs__2EvOu {\n    margin-top: 40px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 12px;\n    gap: 12px;\n}\n\n.TokenActionsModal_flex__2VB-3 {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    grid-gap: 15px;\n    gap: 15px;\n}\n\n.TokenActionsModal_withdrawalBtn__11vse {\n    margin-top: 62px;\n    width: 100%;\n    height: 55px;\n    top: 2283px;\n    left: 251px;\n    grid-gap: 10px;\n    gap: 10px;\n    border-radius: 4px;\n    background: hsla(210, 78%, 40%, 1);\n    color: hsla(210, 13%, 91%, 1);\n    font-size: 16px;\n}\n\n.TokenActionsModal_withdrawalBtn__11vse:hover {\n    opacity: 0.9;\n}\n\n.TokenActionsModal_link__2jLD9 {\n    margin-top: 12px;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.TokenActionsModal_link__2jLD9 p {\n    color: white;\n    font-size: 14px;\n    cursor: pointer;\n}\n\n.TokenActionsModal_line__ET_BC {\n    position: absolute;\n    top: 18px;\n    left: 0;\n    background: white;\n    width: 190px;\n    height: 1px;\n}\n\n\n.TokenActionsModal_custom_checkbox__2lce4 svg {\n    width: 15px;\n    height: 15px;\n}\n\n", ""]);

// exports
exports.locals = {
	"box": "TokenActionsModal_box__1d6pn",
	"block": "TokenActionsModal_block__3qiyx",
	"link": "TokenActionsModal_link__2jLD9",
	"inputs": "TokenActionsModal_inputs__2EvOu",
	"flex": "TokenActionsModal_flex__2VB-3",
	"withdrawalBtn": "TokenActionsModal_withdrawalBtn__11vse",
	"line": "TokenActionsModal_line__ET_BC",
	"custom_checkbox": "TokenActionsModal_custom_checkbox__2lce4"
};