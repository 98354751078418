import React, { useState } from 'react';
import { Info } from './compoents/Info';

export const OwnersReturn = ({token, collection, tokenRefetch}) => {
    const [isShow, setIsShow] = useState(true)

    return (
        <div className="token__card--wrap full mobileoff"
             style={{
                marginTop: '-5px'
                }}
        >
            <div className="token__card--item">
                <div
                    onClick={() => setIsShow(prev => !prev)}
                    className="token__card--item--top"
                    style={{cursor: 'pointer'}}
                >
                 <span className="token__card--item--top--title--inner">
                    <img
                        src="/assets/img/diagram.svg"
                        alt="diagram"
                        className="token__card--item--top--title--icon"
                    />

                    <p className="token__card--item--top--title">
                        Доходность владельца
                    </p>
                 </span>
                    <img
                        src="/assets/img/arrow-select.svg"
                        alt="arrow"
                        className={`token__card--item--open${
                            isShow ? ' active' : ''
                        }`}
                    />
                </div>
                {isShow &&
                    <>
                        <Info token={token} collection={collection} tokenRefetch={tokenRefetch}/>
                    </>
                }
            </div>
        </div>

    )
}
