import React from 'react';

const RightShortFilledArrowIcon = (props) => {
    const { className } = props;

    return (
        <svg className={className} viewBox="0 0 19 18" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 1.79297C13.4777 1.79297 16.707 5.02232 16.707 9C16.707 12.9777 13.4777 16.207 9.5 16.207C5.52232 16.207 2.29297 12.9777 2.29297 9C2.29297 5.02232 5.52232 1.79297 9.5 1.79297ZM8.30866 13.3945C8.4939 13.3945 8.6515 13.3295 8.78151 13.1996L12.5204 9.5484C12.6049 9.46392 12.6666 9.37779 12.7056 9.29004C12.7446 9.20229 12.7657 9.10642 12.769 9.00243C12.769 8.79444 12.6861 8.61247 12.5204 8.45648L8.78151 4.80045C8.71651 4.73871 8.645 4.69079 8.56702 4.65666C8.48901 4.62252 8.40288 4.60547 8.30866 4.60547C8.18192 4.60547 8.06735 4.63553 7.96497 4.69564C7.86263 4.75576 7.78054 4.83782 7.71881 4.94181C7.65707 5.0458 7.62621 5.15957 7.62621 5.28304C7.62621 5.47154 7.69768 5.63727 7.8407 5.78029L11.1541 9.00686L7.8407 12.2197C7.69768 12.3627 7.62621 12.5285 7.62621 12.717C7.62621 12.8404 7.65707 12.9542 7.71881 13.0582C7.78054 13.1622 7.86263 13.2442 7.96497 13.3044C8.06735 13.3645 8.18192 13.3945 8.30866 13.3945Z"
                fill="#E5E8EB"
            />
        </svg>
    );
};

export default React.memo(RightShortFilledArrowIcon);
