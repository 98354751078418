import React from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../common/Modal';
import LightModal from '../../common/LightModal';
import Input from '../../common/Input';
import TransferMenu from "../../components/TransferMenu";

const TestModal = () => {
    const [modal1, setModal1] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const [modal3, setModal3] = React.useState(false);
    const [modal4, setModal4] = React.useState(false);
    const [modal5, setModal5] = React.useState(false);
    const [modal6, setModal6] = React.useState(false);
    const [modal7, setModal7] = React.useState(false);
    const [modal8, setModal8] = React.useState(false);
    const [modal9, setModal9] = React.useState(false);
    const [modal10, setModal10] = React.useState(false);
    const [modal11, setModal11] = React.useState(false);
    const [modal12, setModal12] = React.useState(false);
    const [modal13, setModal13] = React.useState(false);
    const [modal14, setModal14] = React.useState(false);
    const [modal15, setModal15] = React.useState(false);
    const [modal16, setModal16] = React.useState(false);
    const [modal17, setModal17] = React.useState(false);
    const [modal18, setModal18] = React.useState(false);
    const [modal19, setModal19] = React.useState(false);
    const [modal20, setModal20] = React.useState(false);
    const [modal21, setModal21] = React.useState(false);
    const [modal22, setModal22] = React.useState(false);
    const [modal23, setModal23] = React.useState(false);
    const [modal24, setModal24] = React.useState(false);
    const [modal25, setModal25] = React.useState(false);
    const [modal26, setModal26] = React.useState(false);
    const [modal27, setModal27] = React.useState(false);
    const [modal28, setModal28] = React.useState(false);
    const [modal29, setModal29] = React.useState(false);
    const [modal30, setModal30] = React.useState(false);
    const [modal31, setModal31] = React.useState(false);
    const [modal32, setModal32] = React.useState(false);
    const [modal33, setModal33] = React.useState(false);
    const [transferMenu, setTransferMenu] = React.useState(false);

    return(
        <section className="modalpage">
            <div className="container">
                <div className="modalpage__inner">
                    <button className="button modalpage__button" onClick={() => setModal1(true)}>
                        Модалка 1
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal2(true)}>
                        Модалка 2
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal3(true)}>
                        Модалка 3
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal4(true)}>
                        Модалка 4
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal5(true)}>
                        Модалка 5
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal6(true)}>
                        Модалка 6
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal7(true)}>
                        Модалка 7
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal8(true)}>
                        Модалка 8
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal9(true)}>
                        Модалка 9
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal10(true)}>
                        Модалка 10
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal11(true)}>
                        Модалка 11
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal12(true)}>
                        Модалка 12
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal13(true)}>
                        Модалка 13
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal14(true)}>
                        Модалка 14
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal15(true)}>
                        Модалка 15
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal16(true)}>
                        Модалка 16
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal17(true)}>
                        Модалка 17
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal18(true)}>
                        Модалка 18
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal19(true)}>
                        Модалка 19
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal20(true)}>
                        Модалка 20
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal21(true)}>
                        Модалка 21
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal22(true)}>
                        Модалка 22
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal23(true)}>
                        Модалка 23
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal24(true)}>
                        Модалка 24
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal25(true)}>
                        Модалка 25
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal26(true)}>
                        Модалка 26
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal27(true)}>
                        Модалка 27
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal28(true)}>
                        Модалка 28
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal29(true)}>
                        Модалка 29
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal30(true)}>
                        Модалка 30
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal31(true)}>
                        Модалка 31
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal32(true)}>
                        Модалка 32
                    </button>

                    <button className="button modalpage__button" onClick={() => setModal33(true)}>
                        Модалка 33
                    </button>

                    <button className="button modalpage__button" onClick={() => setTransferMenu(true)}>
                        Меню
                    </button>
                </div>
            </div>

            <Modal active={modal1} setActive={setModal1} title="To get on whitelist" size="small">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__steps">
                    <div className="modal__step">
                        <img src="/assets/img/wallet-icon.svg" alt="wallet" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Connect your wallet
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/wl.svg" alt="wl" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Click “Get on whitelist” button
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/write.svg" alt="write" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Fill in the form
                        </p>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Connect wallet
                </button>
            </Modal>

            <Modal active={modal2} setActive={setModal2} title="Get on whitelist">
                <p className="modal__subtitle--blue">
                    Doja Cat Super Story
                </p>

                <p className="modal__text center">
                    To get on whitelist specify data for feedback
                </p>

                <div className="modal__inputs">
                    <input type="text" className="input modal__input" placeholder="Email" />
                    <input type="text" className="input modal__input" placeholder="Discord name" />
                </div>

                <button className="button modal__button modal__default--button">
                    Confirm
                </button>

                <div class="modal__info">
                    <div class="modal__info--block">
                        After confirmation, the information will be sent to the moderator for verification. If verified successfully, you will receive an email with further instructions.<br/>
                        Whitelist cannot be sold or transferred to another person. It will be attached to your wallet. Only you will be able to mint NFT of this collection.<br/>
                        A whitelist gives the right to mint one token. No more than one whitelist is possible in one collection during a minting.<br/>
                        By clicking Confirm you confirm your agreement to the processing of your data.<br/>
                        By participating in the minting, you are assuming an obligation to transfer the intellectual property rights to the information contained in the NFT description belonging to you as the author of the story, in case you sell the NFT containing such information in favor of the new owner of the NFT.
                    </div>
                </div>
            </Modal>

            <Modal active={modal3} setActive={setModal3} title="Get on whitelist">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <p className="modal__text center">
                    To get on whitelist let us know the information of your branded item
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Enter name of the product:
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Shaq Attaq Basketball Shoes" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Material
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Eco-leather" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            The size
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Size 10.5" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Color
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Color Black" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Country of origin
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Made in Indonesia" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Place of purchase
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Newark Avenue 160, Jersey, USA" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Date of purchase
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Okt 29, 2022" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Price
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Price 180$" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Unique number (if any)
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. Item no. P00584395" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            URL link to a picture of your item
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. https://dropbox.com/..." />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Describe story about your branded item
                        </p>

                        <textarea type="text" className="input modal__input textarea" placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."></textarea>

                        <p className="modal__info--attention">
                            Attention! The information above will be uploaded to the NFT metadata.
                        </p>
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Specify data for feedback
                        </p>

                        <input type="text" className="input modal__input" placeholder="Email" />
                        <input type="text" className="input modal__input" placeholder="Discord name" />
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Confirm
                </button>

                <div class="modal__info">
                    <div class="modal__info--block">
                        After confirmation, the information will be sent to the moderator for verification. If verified successfully, you will receive an email with further instructions.<br/>
                        Whitelist cannot be sold or transferred to another person. It will be attached to your wallet. Only you will be able to mint NFT of this collection.<br/>
                        A whitelist gives the right to mint one token. No more than one whitelist is possible in one collection during a minting.<br/>
                        By clicking Confirm you confirm your agreement to the processing of your data.<br/>
                        By participating in the minting, you are assuming an obligation to transfer the intellectual property rights to the information contained in the NFT description belonging to you as the author of the story, in case you sell the NFT containing such information in favor of the new owner of the NFT.
                    </div>
                </div>
            </Modal>

            <Modal active={modal4} setActive={setModal4} title="Congratulations!" size="medium" padding>
                <div className="modal__steps">
                    <div className="modal__step">
                        <img src="/assets/img/sentinfo.svg" alt="info" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Your information has been sent.
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/email.svg" alt="email" className="modal__step--icon" />

                        <p className="modal__step--text">
                            You will receive an email if you are successfully submitted and can book 1 NFT of this collection.
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/build.svg" alt="build" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Next, you will be notified of the start of the minting and will be able to mint the booked NFT.
                        </p>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal5} setActive={setModal5} title="Congratulations!" size="medium" padding>
                <div className="modal__steps">
                    <div className="modal__step">
                        <img src="/assets/img/sentinfo.svg" alt="info" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Your information has been sent.
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/email.svg" alt="email" className="modal__step--icon" />

                        <p className="modal__step--text">
                            You will receive an email if you are successfully submitted.
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/build.svg" alt="build" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Next, you will be notified of the start of the minting and will be able to mint an NFT.
                        </p>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal6} setActive={setModal6} title="Pending" size="medium" padding>
                <p className="modal__text">
                    Your application is being reviewed by a moderator. If approved, you will receive an email notification with instructions on how to proceed.
                </p>

                <p className="modal__text">
                    At this time, you can edit your application or delete it and submit a new one.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal7} setActive={setModal7} title="Whitelisted" size="medium" padding>
                <p className="modal__text">
                    You are now on the whitelist. Congratulations! You will receive an email notification with instructions on how to proceed.
                </p>

                <p className="modal__text">
                    At this time, you can delete your application and submit a new one.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal8} setActive={setModal8} title="Booking stage" size="medium" padding>
                <p className="modal__text">
                    Booking stage is currently in progress.
                </p>

                <p className="modal__text">
                    For booking:
                </p>

                <p className="modal__text mt0">
                    1. Connect wallet;
                </p>

                <p className="modal__text mt0">
                    2. Choose and book NFT (click “Book” button). Booking is free.
                </p>

                <p className="modal__text">
                    Next wait for the Private minting to start. You will receive a notification in the mail.
                </p>

                <p className="modal__text">
                    At the moment, you can delete your application and submit a new one.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal9} setActive={setModal9} title="Booked" size="medium" padding>
                <p className="modal__text">
                    Congratulations! You have successfully booked a token.
                </p>

                <p className="modal__text">
                    Now wait for Private minting to start. You will be notified by mail.
                </p>

                <p className="modal__text">
                    At this point you can cancel your booking and make a new one.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal10} setActive={setModal10} title="Private minting" size="medium" padding>
                <p className="modal__text">
                    Private minting is currently in progress.
                </p>

                <p className="modal__text">
                    For minting:
                </p>

                <p className="modal__text mt0">
                    1. Connect wallet;
                </p>

                <p className="modal__text mt0">
                    2. Mint booked NFT (click “Mint” button).
                </p>

                <p className="modal__text">
                    After the mint, you will become the owner of the utility NFT, the new Stories category.
                </p>

                <p className="modal__text">
                    If you do not mint the booked NFT in the allotted time, the booking will be forfeited.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal11} setActive={setModal11} title="Public minting" size="medium" padding>
                <p className="modal__text">
                    Public minting is currently in progress.
                </p>

                <p className="modal__text">
                    For minting:
                </p>

                <p className="modal__text mt0">
                    1. Connect wallet;
                </p>

                <p className="modal__text mt0">
                    2. Mint NFT (click “Mint” button).
                </p>

                <p className="modal__text">
                    After the mint, you will become the owner of the utility NFT, the new Stories category.
                </p>

                <p className="modal__text">
                    If you haven't had time to mint, wait for the next issue of the collection.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal12} setActive={setModal12} title="Minted" size="medium" padding>
                <p className="modal__text">
                    Congratulations! You have successfully minted a token.
                </p>

                <p className="modal__text">
                    You are now the owner of the NFT utility, the new Stories category.
                </p>

                <p className="modal__text">
                    More social features will be available to you in the near future.
                </p>

                <p className="modal__text">
                    Thank you for your support and investment in the project. We will do our best to meet your expectations.
                </p>

                <p className="modal__text">
                    Recommended sale price: X9-X10 of the mint cost.
                </p>

                <p className="modal__text">
                    For sale:
                </p>

                <p className="modal__text mt0">
                    1. Go to https://opensea.io;
                </p>

                <p className="modal__text mt0">
                    2. Connect wallet;
                </p>

                <p className="modal__text mt0">
                    3. Go to page of minted NFT, click "Sell" button, set the selling price.
                </p>

                <p className="modal__text">
                    Also, pay attention to the collections of other brands. If you have the products of the brands presented on the Minting Platform, this is the reason to get a Whitelist.
                </p>

                <p className="modal__text">
                    Subscribe to our social networks and be aware of the development plans of the project. Thank you for your participation.
                </p>

                <p className="modal__text">
                    Regards, the Minting Platform team.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal13} setActive={setModal13} title="Sold out" size="medium" padding>
                <p className="modal__text">
                    Minting of the collection is complete.
                </p>

                <p className="modal__text">
                    Thank you for your participation.
                </p>

                <p className="modal__text">
                    If you got whitelisted and haven't had time to take advantage of it, wait for the next issue of the collection.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal14} setActive={setModal14} title="Redlist" size="medium" padding>
                <p className="modal__text">
                    Your application is on the redlist.
                </p>

                <p className="modal__text">
                    You may:
                </p>

                <div className="modal__text--points">
                    <p className="modal__text--point">
                        reapply;
                    </p>

                    <p className="modal__text--point">
                        edit and submit the application;
                    </p>

                    <p className="modal__text--point">
                        delete the application and submit a new one.
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal15} setActive={setModal15} title="Deleted" size="medium" padding>
                <p className="modal__text">
                    Your application has been removed by a moderator.
                </p>

                <p className="modal__text">
                    Reasons for deletion:
                </p>

                <div className="modal__text--points">
                    <p className="modal__text--point">
                        the application was filled out incorrectly;
                    </p>

                    <p className="modal__text--point">
                        the information specified in the application does not correspond to reality;
                    </p>

                    <p className="modal__text--point">
                        other reasons.
                    </p>
                </div>

                <p className="modal__text">
                    You can apply again.
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal16} setActive={setModal16} title="Deleting application" size="medium" padding>
                <p className="modal__text center">
                    Are you sure you want to delete the application?
                </p>

                <div className="modal__buttons--inner">
                    <button className="button modal__button modal__default--button">
                        Cancel
                    </button>

                    <button className="button modal__button modal__default--button delete">
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal active={modal17} setActive={setModal17} title="Deleting application" size="medium" padding>
                <p className="modal__text">
                    Deleting the application will result in you being removed from the whitelist.
                </p>

                <p className="modal__text">
                    Are you sure you want to delete the application?
                </p>

                <div className="modal__buttons--inner">
                    <button className="button modal__button modal__default--button">
                        Cancel
                    </button>

                    <button className="button modal__button modal__default--button delete">
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal active={modal18} setActive={setModal18} title="Cancellation of booking" size="medium" padding>
                <p className="modal__text">
                    Cancellation of booking:
                </p>

                <div className="modal__text--points">
                    <p className="modal__text--point">
                        causes you to be unable to participate in Private minting;
                    </p>

                    <p className="modal__text--point">
                        allows you to book another token that is not booked.
                    </p>
                </div>

                <p className="modal__text">
                    Are you sure you want to cancel your booking?
                </p>

                <div className="modal__buttons--inner">
                    <button className="button modal__button modal__default--button">
                        Cancel
                    </button>

                    <button className="button modal__button modal__default--button delete">
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal active={modal19} setActive={setModal19} title="Approve booking">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--content">
                    <div className="modal__token--info">
                        <div className="modal__token--img--inner">
                            {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--img" /> */}
                        </div>

                        <div className="modal__token--info--content">
                            <p className="modal__token--info--title">
                                Unique card #002
                            </p>

                            <p className="modal__token--info--text">
                                Investor fee: 4.5%
                            </p>

                            <p className="modal__token--info--text">
                                Chain: Ethereum
                            </p>
                        </div>
                    </div>

                    <div className="modal__token--price">
                        <p className="modal__token--price--value">
                            0.56 ETH
                        </p>

                        <p className="modal__token--price--value--usd">
                            $ 1,452.00
                        </p>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Confirm
                </button>

                <div class="modal__info">
                    <div class="modal__info--block">
                        By booking this NFT, you get the opportunity to participate in Private minting of this collection.<br/>
                        The booking is valid for the entire duration of the Private minting.<br/>
                        During this period only you will be able to mint the booked token.<br/>
                        Private minting will start after all the tokens in the collection have been booked and will end after 90% of all tokens in the collection have been minted.<br/>
                        The next stage is Public minting.<br/>
                        When it begins, bookings for the 10% remaining NFTs will be cancelled and the minting of these tokens will take place among all whitelist holders of that collection.<br/>
                        Booking is free of charge.
                    </div>
                </div>
            </Modal>

            <Modal active={modal20} setActive={setModal20} title="Approve minting">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--content">
                    <div className="modal__token--info">
                        <div className="modal__token--img--inner">
                            {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--img" /> */}
                        </div>

                        <div className="modal__token--info--content">
                            <p className="modal__token--info--title">
                                Unique card #002
                            </p>

                            <p className="modal__token--info--text">
                                Investor fee: 4.5%
                            </p>

                            <p className="modal__token--info--text">
                                Chain: Ethereum
                            </p>
                        </div>
                    </div>

                    <div className="modal__token--price">
                        <p className="modal__token--price--value">
                            0.56 ETH
                        </p>

                        <p className="modal__token--price--value--usd">
                            $ 1,452.00
                        </p>
                    </div>
                </div>

                <div className="modal__auth--block">
                    <p className="modal__auth--title">
                        Go to your wallet
                    </p>

                    <p className="modal__auth--text">
                        You&rsquo;ll be asked to approve this action from your wallet.
                    </p>
                </div>

                <button className="button modal__button modal__default--button mobile">
                    Continue
                </button>

                <div className="modal__info--block--wrapper">
                    <p className="modal__info--attention">
                        Attention! The information below will be uploaded to the NFT metadata:
                    </p>

                    <div class="modal__info">
                        <div class="modal__info--block">
                            Shaq Attaq Basketball Shoes<br/>
                            Eco-leather<br/>
                            Size 10.5<br/>
                            Color Black<br/>
                            Made in Indonesia<br/>
                            Newark Avenue 160, Jersey, USA<br/>
                            Okt 29, 2022<br/>
                            Price 180$<br/>
                            Item no. P00584395<br/>
                            https://dropbox.com/...<br/>
                            In these sneakers I won the basketball World Cup, the universe Cup and all the galaxy cups of the world. Very pleasant memories.
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal active={modal21} setActive={setModal21} title="Approve withdrawal">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--content">
                    <div className="modal__token--info">
                        <div className="modal__token--img--inner">
                            {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--img" /> */}
                        </div>

                        <div className="modal__token--info--content">
                            <p className="modal__token--info--title">
                                Unique card #002
                            </p>

                            <p className="modal__token--info--text">
                                Investor fee: 4.5%
                            </p>

                            <p className="modal__token--info--text">
                                Chain: Ethereum
                            </p>
                        </div>
                    </div>

                    <div className="modal__token--price">
                        <p className="modal__token--price--value">
                            0.56 ETH
                        </p>

                        <p className="modal__token--price--value--usd">
                            $ 1,452.00
                        </p>
                    </div>
                </div>

                <div className="modal__auth--block">
                    <p className="modal__auth--title">
                        Go to your wallet
                    </p>

                    <p className="modal__auth--text">
                        You&rsquo;ll be asked to approve this action from your wallet.
                    </p>
                </div>

                <button className="button modal__button modal__default--button mobile">
                    Continue
                </button>

                <div class="modal__info">
                    <div class="modal__info--block">
                        After confirming the withdrawal, the funds will be transferred to your wallet.<br/>
                        Payment for the gas transaction is made at the expense of the recipient.<br/>
                        There must be enough funds in your wallet account to pay for the transaction.<br/>
                        Otherwise the transaction will not be possible.<br/>
                        If withdrawal of funds is not possible for other reasons, contact Minting Platform administration in Discord: https://discord.gg/xpgxpSxWXd
                    </div>
                </div>
            </Modal>

            <Modal active={modal22} setActive={setModal22} title="Congratulations!" size="medium">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--box">
                    <p className="modal__token--box--text">
                        Unique card #002
                    </p>

                    <div className="modal__token--box--img--inner">
                        {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--box--img" /> */}
                    </div>

                    <p className="modal__token--box--text">
                        has been minted by you
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>

                <div class="modal__info">
                    <div class="modal__info--block">
                        You are now the owner of the NFT utility, the new Stories category.<br/>
                        More social features will be available to you in the near future.<br/>
                        Thank you for your support and investment in the project. We will do our best to meet your expectations.<br/>
                        Recommended sale price: X9-X10 of the mint cost.<br/>
                        For sale:<br/>
                        Go to https://opensea.io<br/>
                        Connect wallet; Click "Sell" button and set the selling price.<br/>
                        Also, pay attention to the collections of other brands. If you have the products of the brands presented on the Minting Platform, this is the reason to get on whitelist.<br/>
                        Subscribe to our social networks and be aware of the development plans of the project.<br/>
                        Thank you for your participation.<br/>
                        Regards, the Minting Platform team.
                    </div>
                </div>
            </Modal>

            <Modal active={modal23} setActive={setModal23} title="Congratulations!" size="xsmall">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--box">
                    <p className="modal__token--box--text">
                        Unique card #002
                    </p>

                    <div className="modal__token--box--img--inner">
                        {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--box--img" /> */}
                    </div>

                    <p className="modal__token--box--text">
                        has been booked by you
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal24} setActive={setModal24} title="Congratulations!" size="xsmall">
                <p className="modal__subtitle--blue">
                    Reebok NFT Certificates
                </p>

                <div className="modal__token--box">
                    <p className="modal__token--box--text">
                        Unique card #002
                    </p>

                    <div className="modal__token--box--img--inner">
                        {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--box--img" /> */}
                    </div>

                    <p className="modal__token--box--text">
                        the funds have been transferred to your wallet
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal25} setActive={setModal25} title="Congratulations!" size="xsmall">
                <p className="modal__subtitle--blue">
                    Company name
                </p>

                <div className="modal__token--box">
                    <p className="modal__token--box--text">
                        VIP service
                    </p>

                    <div className="modal__token--box--img--inner vip">
                        {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--box--img" /> */}
                    </div>

                    <p className="modal__token--box--text">
                        will be available to users after moderation
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal26} setActive={setModal26} title="Token Sold">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <p className="modal__text center">
                    Enter the information to transfer the token to the user
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Telephone
                        </p>

                        <input type="text" className="input modal__input" placeholder="+ 1-999-999-99-99" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Email
                        </p>

                        <input type="text" className="input modal__input" placeholder="email@site.com" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Wallet address
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. 0xeA09D6D8Cff17b11E45763d1025831de3E2DdaAF" />
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Confirm
                </button>
            </Modal>

            <Modal active={modal27} setActive={setModal27} title="Token Transfer">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <p className="modal__text center">
                    Enter the information to transfer the token to the user
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Telephone
                        </p>

                        <input type="text" className="input modal__input" placeholder="+ 1-999-999-99-99" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Email
                        </p>

                        <input type="text" className="input modal__input" placeholder="email@site.com" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Wallet address
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. 0xeA09D6D8Cff17b11E45763d1025831de3E2DdaAF" />
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Confirm
                </button>
            </Modal>

            <Modal active={modal28} setActive={setModal28} title="To activate the token" size="small">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <div className="modal__steps">
                    <div className="modal__step">
                        <img src="/assets/img/wallet.svg" alt="info" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Connect your wallet
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/activate-button.svg" alt="email" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Click “Activate” button
                        </p>
                    </div>

                    <div className="modal__step">
                        <img src="/assets/img/write.svg" alt="build" className="modal__step--icon" />

                        <p className="modal__step--text">
                            Enter verification code
                        </p>
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Connect wallet
                </button>
            </Modal>

            <Modal active={modal29} setActive={setModal29} title="Congratulations!" size="xsmall">
                <p className="modal__subtitle">
                    Activated
                </p>

                <div className="modal__token--box">
                    <p className="modal__token--box--text">
                        Unique card #001
                    </p>

                    <div className="modal__token--box--img--inner">
                        {/* <img src="/assets/img/token-img.png" alt="token" className="modal__token--box--img" /> */}
                    </div>

                    <p className="modal__token--box--text">
                        has been successfully completed
                    </p>
                </div>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>

            <Modal active={modal30} setActive={setModal30} title="Token Activate" size="medium">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <p className="modal__text center">
                    Please complete the verification.
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__input-row">
                        <Input
                            title="Mobile: 1-999****779"
                            placeholder="Enter verification code"
                            withButton
                            buttonTitle="Get code"
                        />
                    </div>

                    <div className="modal__input-row">
                        <Input
                            title="Email: check****com"
                            placeholder="Enter verification code"
                            withButton
                            buttonTitle="Get code"
                        />
                    </div>
                </div>

                <button className="button modal__button modal__default--button">
                    Submit
                </button>

                <div className="modal__text-left">
                    <Link to="/" className="modal__link">Get help from customer service</Link>
                </div>

            </Modal>

            <Modal active={modal31} setActive={setModal31} title="Edit Link" size="medium">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <p className="modal__text center">
                    Please establish a new connection
                </p>

                <div className="modal__inputs--content">
                    <div className="modalInputsContent">
                        <p className="admin__add--title">QR link</p>

                        <div className="modalInputWrapper">
                            <Input
                                placeholder="https://checkbrand.com/A1B2C3"
                            />

                            <img src="/assets/img/refresh2.svg" alt="refresh" className="modalInputIcon" />
                        </div>
                    </div>

                    <Input
                        title="Token link"
                        placeholder="https://checkbrand.com/token/2342429034042090920222"
                    />
                </div>

                <button className="button modal__button modal__default--button">
                    Save
                </button>

            </Modal>

            <LightModal active={modal32} setActive={setModal32} title="Edit Link">
                <p className="light-modal__title">
                    Reebok NFT Certificates
                </p>

                <p className="light-modal__subtitle">
                    Unique card #001
                </p>

                <img src="assets/img/qr-code.svg" alt="" className="light-modal__qr" />

                <p className="light-modal__text-line">
                    Character - male description
                </p>

                <p className="light-modal__text-line">
                    Character - male more description
                </p>

                <p className="light-modal__text-line">
                    Character - male fill description
                </p>

                <p className="light-modal__text-line">
                    Character - male seem description
                </p>

                <p className="light-modal__description">
                    To verify NFT ownership, scan the QR and check the website domain name (checkbrand.com) in the window that opens. Compare the avatar with the user's face. A match indicates successful verification.
                    <br />
                    An additional option for verification: put a predefined image.
                </p>

                <p className="light-modal__text-line">
                    Reebok
                </p>

            </LightModal>

            <Modal active={modal33} setActive={setModal33} title="Information for delivery">
                <p className="modal__subtitle">
                    Unique card #001
                </p>

                <p className="modal__text center">
                    Enter the information to deliver the real thing to you
                </p>

                <div className="modal__inputs--content">
                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Country
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. United States" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Full name
                        </p>

                        <input type="text" className="input modal__input" placeholder="First and last name" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Address line 1
                        </p>

                        <input type="text" className="input modal__input" placeholder="Street address, company name, c/o" />
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Address line 2
                        </p>

                        <input type="text" className="input modal__input" placeholder="Apartment, suite, until, building, floor, etc" />
                    </div>

                    <div className="modal__inputs-in-row">
                        <div className="modal__inputs--item">
                            <p className="modal__inputs--item--text">
                                City
                            </p>

                            <input type="text" className="input modal__input" placeholder="e.g. Las Vegas" />
                        </div>

                        <div className="modal__inputs--item">
                            <p className="modal__inputs--item--text">
                                State
                            </p>

                            <input type="text" className="input modal__input" placeholder="e.g. Nevada" />
                        </div>

                        <div className="modal__inputs--item">
                            <p className="modal__inputs--item--text">
                                Zip
                            </p>

                            <input type="text" className="input modal__input" placeholder="e.g. 89101" />
                        </div>
                    </div>

                    <div className="modal__inputs--item">
                        <p className="modal__inputs--item--text">
                            Phone number
                        </p>

                        <input type="text" className="input modal__input" placeholder="e.g. +1-775-777-00-00" />
                    </div>
                </div>

                <div className="modal__buttons--inner">
                    <button className="button modal__button modal__default--button">
                        Confirm
                    </button>

                    <button className="button modal__button modal__default--button delete">
                        Not now
                    </button>
                </div>
            </Modal>
            <TransferMenu active={transferMenu} setActive={setTransferMenu} />
        </section>
    )
}

export default TestModal;