exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OwnersReturn_flex__1RcfT {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.OwnersReturn_wrapper__qqeCr {\n    padding: 24px;\n    background: rgba(229, 232, 235, 0.02);\n    width: 100%;\n}\n\n.OwnersReturn_wrapper__qqeCr p {\n    color: white;\n    font-size: 14px;\n}\n\n.OwnersReturn_buttons__3JiWZ {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 24px;\n    grid-gap: 10px;\n    gap: 10px;\n}\n\n.OwnersReturn_return__1b0CH {\n    width: 50%;\n    background: hsla(304, 23%, 51%, 0.5);\n    height: 62px;\n    color: white;\n    border: none;\n    border-radius: 2px;\n    cursor: pointer;\n}\n\n.OwnersReturn_conclusion__7kpG- {\n    width: 50%;\n    background: hsla(210, 77%, 51%, 0.5);\n    height: 62px;\n    color: white;\n    border: none;\n    border-radius: 2px;\n    cursor: pointer;\n}\n\n.OwnersReturn_link__2pjyH {\n    color: white;\n}\n\n.OwnersReturn_line__WDNvx {\n    height: 1px;\n    width: 220px;\n    background: white;\n}\n\n@media(max-width: 998px) {\n    .OwnersReturn_buttons__3JiWZ {\n        flex-direction: column;\n    }\n\n    .OwnersReturn_conclusion__7kpG- {\n        width: 100%;\n        height: 44px;\n    }\n\n    .OwnersReturn_return__1b0CH {\n        width: 100%;\n        height: 44px;\n    }\n}\n\n.OwnersReturn_items__10_nI {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    grid-gap: 5px;\n    gap: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"flex": "OwnersReturn_flex__1RcfT",
	"wrapper": "OwnersReturn_wrapper__qqeCr",
	"buttons": "OwnersReturn_buttons__3JiWZ",
	"return": "OwnersReturn_return__1b0CH",
	"conclusion": "OwnersReturn_conclusion__7kpG-",
	"link": "OwnersReturn_link__2pjyH",
	"line": "OwnersReturn_line__WDNvx",
	"items": "OwnersReturn_items__10_nI"
};