import React from 'react';
import Input from '../../common/Input';

import css from './index.module.css';

const Administrator = () => {
    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">Super Administrator</h2>
                        <div className={css.content}>
                            <div className={css.contentGroup}>
                                <Input
                                    title="Super Administrator address"
                                    text="Enter the address of the new administrator"
                                    placeholder="Wallet address"
                                    withButton
                                    buttonTitle="Change address"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="CBCOIN / USDT"
                                    text="Enter the coin value"
                                    placeholder="1.0"
                                    withButton
                                    buttonTitle="Change cost"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="1 NFT / CBCOIN"
                                    text="Enter the cost of placing one NFT"
                                    placeholder="1.0"
                                    withButton
                                    buttonTitle="Change cost"
                                />
                            </div>

                            <div className={css.contentGroup}>
                                <Input
                                    title="ERC-20 CBCOIN"
                                    text="Available coins"
                                    placeholder="2.299.999.999.000"
                                />

                                <Input
                                    text="Issued coins"
                                    placeholder="1.000"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Administrator;