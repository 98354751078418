exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".offer_wrapper__3rjYt {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n}\n\n.offer_wrapper__3rjYt a {\n    font-size: 14px;\n    color: hsla(210, 13%, 91%, 0.8);\n    font-weight: 400;\n    display: block;\n    margin-bottom: 4px;\n}\n\n.offer_footer__3sIOF {\n    display: flex;\n    align-items: flex-start;\n    flex-direction: row;\n    grid-gap: 130px;\n    gap: 130px;\n}\n\n@media (max-width: 665px) {\n    .offer_footer__3sIOF {\n        flex-direction: column;\n        grid-gap: 30px;\n        gap: 30px;\n    }\n}\n\n.offer_footer__3sIOF p {\n    font-size: 14px;\n    color: hsla(210, 13%, 91%, 0.8);\n    font-weight: 400;\n    margin-bottom: 4px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "offer_wrapper__3rjYt",
	"footer": "offer_footer__3sIOF"
};