import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { InputLabel, Radio } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTokenTransferList,setUserTokenTransferStatus } from './slice/tokenSlice';

export const TokenModal = ({
    selectedField,
    handleSetSelectedField,
    element,
    handleCloseModal,
    clearSelectedFields,
    setIsSuccess,
}) => {
    const tokenTransferModal = useRef(null);
    const dispatch = useDispatch();
    const [searchType, setSearchType] = useState('');
    const [searchData, setSearchData] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const accessToken = localStorage.getItem('access_token');
    const [error, setError] = useState('');
    const [formValue, setFormValue] = useState({
        email: '',
        public_adress: '',
        phone: '',
    });
    const [searchUser, setSearchUser] = useState({
        searchStatusPhone: null,
        searchStatusEmail: null,
        searchStatusPublicAdress: null,
    });
    const handleFetchInputData = useCallback(
        (type, data, searchType) => {
            const findActiveRadio = selectedField.find(
                (field) => field.type === type && field.state === true,
            );
            setError('');
            if (data.length !== 0 && findActiveRadio) {
                setSearchType(type);
                setSearchData(data);
                setSearchStatus(searchType);
            }
        },
        [selectedField],
    );

    const handleClearValues = () => {
        setFormValue({
            email: '',
            public_adress: '',
            phone: '',
        });
        setSearchUser({
            searchStatusPhone: null,
            searchStatusEmail: null,
            searchStatusPublicAdress: null,
        });
        clearSelectedFields();
    };

    useEffect(() => {
        if (searchType.length !== 0 && searchData.length !== 0) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/${searchType}/${searchData}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                },
            ).then((res) => {
                if (Number(res.status) === 200) {
                    setSearchUser((prev) => ({
                        ...prev,
                        [`${searchStatus}`]: true,
                    }));
                    dispatch(setUserTokenTransferStatus({ isAuth: true }));
                }
                if (Number(res.status) >= 400) {
                    setSearchUser((prev) => ({
                        ...prev,
                        [`${searchStatus}`]: false,
                    }));
                    dispatch(setUserTokenTransferStatus({ isAuth: false }));
                }
            });
        }
    }, [searchData, searchStatus, searchType]);

    const handleTransferToken = useCallback(() => {
        const findActiveRadio = selectedField.find((field) => field.state === true);
        if (findActiveRadio && formValue[findActiveRadio.type]) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/store/transfers/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    [findActiveRadio.type]: formValue[findActiveRadio.type],
                    token: element.id,
                }),
            })
                .then((res) => {
                    if (Number(res.status) < 210) {
                        dispatch(setTokenTransferList(element.id));
                        setIsSuccess(true);
                        handleCloseModal();
                        handleClearValues();
                        setError('');
                    }
                    if (Number(res.status) >= 400) {
                        return res.json();
                    }
                })
                .then((response) => {
                    if (response && response.token) {
                        response.token.join(' ');
                        setError(
                            `При отправке токена произошла ошибка: ${response.token.join(' ')}`,
                        );
                        handleClearValues();
                    }
                });
        }
    }, [selectedField, formValue, element]);

    return (
        <div className="tokenTransferModal" ref={tokenTransferModal}>
            <div className="tokenTransferModal__container">
                <div className="container__header">
                    <h1>Передача токена</h1>{' '}
                    <button className="header__button" onClick={handleCloseModal}>
                        ×
                    </button>
                </div>

                <div className="container__content">
                    <div className="stats__item__modal">
                        <img
                            src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${element.file_1}`}
                            alt={'img'}
                            style={{
                                objectFit: 'cover',
                                width: '48px',
                                height: '48px',
                            }}
                        />
                        <div className="stats__item__name">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                {element.collection.name}
                            </Link>

                            <p>{element.name}</p>
                        </div>
                    </div>

                    <p style={{ color: 'white', userSelect: 'none' }}>
                        Заполните одно из полей для передачи токена пользователю:
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            marginTop: '20px',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Radio
                                        checked={selectedField[0].state}
                                        onChange={() =>
                                            handleSetSelectedField(selectedField[0].type)
                                        }
                                        value={selectedField[0].state}
                                        size="small"
                                        sx={{ width: '15px', height: '15px' }}
                                    />
                                    <InputLabel
                                        sx={{ color: 'white', fontSize: '15px' }}
                                        onClick={() =>
                                            handleSetSelectedField(selectedField[0].type)
                                        }
                                    >
                                        Адресс электронной почты
                                    </InputLabel>
                                </div>

                                <div>
                                    {searchUser.searchStatusEmail === true && (
                                        <p style={{ color: '#77C57F' }}>Пользователь найден</p>
                                    )}

                                    {searchUser.searchStatusEmail === false && (
                                        <p style={{ color: '#CC5554' }}>Пользователь не найден</p>
                                    )}
                                </div>
                            </div>
                            <input
                                className="container__input"
                                placeholder="Введите email"
                                onBlur={() =>
                                    handleFetchInputData(
                                        'email',
                                        formValue.email,
                                        'searchStatusEmail',
                                    )
                                }
                                value={formValue.email}
                                onChange={(e) =>
                                    setFormValue((prev) => ({ ...prev, email: e.target.value }))
                                }
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Radio
                                        checked={selectedField[1].state}
                                        onChange={() =>
                                            handleSetSelectedField(selectedField[1].type)
                                        }
                                        value={selectedField[1].state}
                                        size="small"
                                        sx={{ width: '15px', height: '15px' }}
                                    />
                                    <InputLabel
                                        sx={{ color: 'white', fontSize: '15px' }}
                                        onClick={() =>
                                            handleSetSelectedField(selectedField[1].type)
                                        }
                                    >
                                        Телефон
                                    </InputLabel>
                                </div>
                                <div>
                                    {searchUser.searchStatusPhone === true && (
                                        <p style={{ color: '#77C57F' }}>Пользователь найден</p>
                                    )}

                                    {searchUser.searchStatusPhone === false && (
                                        <p style={{ color: '#CC5554' }}>Пользователь не найден</p>
                                    )}
                                </div>
                            </div>

                            <input
                                className="container__input"
                                placeholder="Введите номер телефона"
                                // style={{ borderColor: getColorPhone }}
                                onBlur={() =>
                                    handleFetchInputData(
                                        'phone',
                                        formValue.phone,
                                        'searchStatusPhone',
                                    )
                                }
                                value={formValue.phone}
                                onChange={(e) =>
                                    setFormValue((prev) => ({ ...prev, phone: e.target.value }))
                                }
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Radio
                                        checked={selectedField[2].state}
                                        onChange={() =>
                                            handleSetSelectedField(selectedField[2].type)
                                        }
                                        value={selectedField[2].state}
                                        size="small"
                                        sx={{ width: '15px', height: '15px' }}
                                    />
                                    <InputLabel
                                        sx={{ color: 'white', fontSize: '15px' }}
                                        onClick={() =>
                                            handleSetSelectedField(selectedField[2].type)
                                        }
                                    >
                                        Адресс кошелька
                                    </InputLabel>
                                </div>
                                <div>
                                    {searchUser.public_adress === true && (
                                        <p style={{ color: '#77C57F' }}>Пользователь найден</p>
                                    )}

                                    {searchUser.public_adress === false && (
                                        <p style={{ color: '#CC5554' }}>Пользователь не найден</p>
                                    )}
                                </div>
                            </div>

                            <input
                                className="container__input"
                                placeholder="Введите адрес кошелька"
                                // style={{ borderColor: getColorPublicAdress }}
                                onBlur={() =>
                                    handleFetchInputData(
                                        'public_adress',
                                        formValue.public_adress,
                                        'searchStatusPublicAdress',
                                    )
                                }
                                value={formValue.public_adress}
                                onChange={(e) =>
                                    setFormValue((prev) => ({
                                        ...prev,
                                        public_adress: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        {error.length > 0 && (
                            <p style={{ fontSize: '14px', color: '#CC5554' }}>{error}</p>
                        )}

                        <button className="modal__button" onClick={() => handleTransferToken()}>
                            Подтвердить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
// const getColorEmail = useMemo(() => {
//     const findActiveRadio = selectedField.find((field) => field.state === true);
//     if (findActiveRadio) {
//         if (formValue.searchStatusEmail === true && findActiveRadio.state) {
//             return '#77C57F';
//         }
//         if (formValue.searchStatusEmail === false && findActiveRadio.state) {
//             return '#CC5554';
//         }
//         if (formValue.searchStatusEmail === null) {
//             return '';
//         }
//     }
// }, [formValue, selectedField]);

// const getColorPhone = useMemo(() => {
//     const findActiveRadio = selectedField.find((field) => field.state === true);
//     if (findActiveRadio) {
//         if (formValue.searchStatusPhone === true && findActiveRadio.state) {
//             return '#77C57F';
//         }
//         if (formValue.searchStatusPhone === false && findActiveRadio.state) {
//             return '#CC5554';
//         }
//         if (formValue.searchStatusPhone === null) {
//             return '';
//         }
//     }
// }, [formValue, selectedField]);

// const getColorPublicAdress = useMemo(() => {
//     const findActiveRadio = selectedField.find((field) => field.state === true);
//     if (findActiveRadio) {
//         if (formValue.searchStatusPublicAdress === true && findActiveRadio.state) {
//             return '#77C57F';
//         }
//         if (formValue.searchStatusPublicAdress === false && findActiveRadio.state) {
//             return '#CC5554';
//         }
//         if (formValue.searchStatusPublicAdress === null) {
//             return '';
//         }
//     }
// }, [formValue, selectedField]);
