exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cancel_btn__2blBn {\n    height: 55px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: hsla(0, 54%, 56%, 1);\n    color: white;\n}\n", ""]);

// exports
exports.locals = {
	"btn": "cancel_btn__2blBn"
};