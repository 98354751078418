import Modal from "../../common/Modal";
import Input from "../../common/Input";
import styles from "../WelcomeModal/modal.module.css";
import React, { useContext, useEffect, useState } from 'react';
import {useChangeUserDataMutation, useLazyConfirmUserDataQuery} from "../../redux/api/userService";
import {useNavigate} from "react-router-dom";
import stylesModal from './styles.module.css'
import {initEmail, setAllUserData, setInstagram,  setTelegram, setWhatsapp} from "../../redux/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import {emailRegex} from "../../pages/TokenCard/PayModal/PayModal";
import {getProfileData } from "../../functions/auth";
import { NotificationContext } from '../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';


export const ConfirmEmailModal = ({isConfirmedEmail, setIsConfirmedEmail, email,  telegram, emailValue}) => {
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [timer, setTimer] = useState(60)
  const accessToken = localStorage.getItem('access_token');

  const [confirmUserData] = useLazyConfirmUserDataQuery()
  const [changeData] = useChangeUserDataMutation()
  const dispatch = useDispatch()

  const userName= useSelector(state => state.auth.userName)

  const {
    actions: { addNotification },
  } = useContext(NotificationContext);

  const handleClickBtn = () => {
    setIsClicked(true)
    if(code && code.length === 6) {
      confirmUserData({
        code: code
      })
          .unwrap()
          .then(async () => {
            navigate('/profile')
            const profile = await getProfileData(accessToken)

            if (profile.data) {
              const { email, instagram, whatsapp, telegram } = profile.data;
              dispatch(setAllUserData(profile.data))
              dispatch(initEmail(email));
              instagram && dispatch(setInstagram(instagram))
              whatsapp && dispatch(setWhatsapp(whatsapp))
              telegram && dispatch(setTelegram(telegram))
            }
            handleCloseModal()
          })
          .catch(() => {
            addNotification({
              type: NOTIFICATION_TYPES.ERROR,
              text: 'Не удалось изменить данные',
            })
          })
    }
  }

  const handleCloseModal = () => {
    setIsConfirmedEmail(false)
    setCode('')
    setIsClicked(false)
    setTimer(60)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);


    return () => clearInterval(intervalId);
  }, []);

  const handleSendBtnClick = () => {
    setTimer(60)
    if(emailRegex.test(email) && telegram) {
      changeData({
        body: {
          username: userName,
          email: email ? email : emailValue,
        }
      })
    }
  };

  return (
    <Modal
      title={'Верификация'}
      active={isConfirmedEmail}
      onClose={handleCloseModal}
    >
      <p className={stylesModal.text}>Пожалуйста подтвердите вашу эл. почту</p>

      <p className={stylesModal.email}>Эл. почта: {emailValue ? emailValue : email}</p>
      <div className={stylesModal.container}>
        <Input
          placeholder="Введите 6-и значный код"
          value={code}
          setValue={(text) => {
            if(text.length <= 6) {
              setCode(text)
            }
          }}

          className={styles.input}
          isError={isClicked ? !code : false}
        />

        <button
          className={stylesModal.timerBtn}
          onClick={handleSendBtnClick}
          disabled={timer !== 0}
        >
          {timer === 0 ? 'Отправить' : `${timer} сек`}
        </button>
      </div>


      <button
        className={`button token__card--content--price--buy ${styles.btn}`}
        onClick={handleClickBtn}
        style={{height: '52px', marginBottom: '44px'}}
      >
        Готово
      </button>

      <Input
        placeholder="Нажмите кнопку Отправить и на вашу почту придёт 6-и значный код. Скопируйте его и вставьте в поле ввода. Нажмите кнопку Готово. После этого действия вы сможете начать пользоваться услугами платформы. Благодарим вас."
        textarea
        disabled={true}
      />
    </Modal>
  )
}
