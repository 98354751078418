import React from 'react';
import { cnb } from 'cnbuilder';
import Skeleton from 'react-loading-skeleton';

import './index.css';
import styles from './Profile.module.css';

const ProfileSkeleton = () => {
    return (
        <section className="profile">
            <div className="container">
                <div className="profile__inner">
                    <div className="profile__ava--inner profile__ava--inner--skeleton">
                        <Skeleton
                            borderRadius="50%"
                            containerClassName="profile__ava--skeleton"
                            className="skeleton__content"
                        />
                    </div>

                    <div className="profile__name--inner profile__name--inner--skeleton">
                        <Skeleton
                            containerClassName="profile__name--skeleton"
                            className="skeleton__content"
                        />
                    </div>

                    <div className="profile__wrap">
                        <div className="profile__wallet profile__wallet--inner--skeleton">
                            <Skeleton
                                containerClassName="profile__wallet--skeleton"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="profile__date profile__date--inner--skeleton">
                            <Skeleton
                                containerClassName="profile__date--skeleton"
                                className="skeleton__content"
                            />
                        </div>
                    </div>

                    <div className="collection__filter--content">
                        <Skeleton
                            containerClassName="collection__filter--skeleton"
                            className="skeleton__content"
                        />
                        <Skeleton
                            containerClassName="collection__filter--skeleton mobile"
                            className="skeleton__content"
                        />
                    </div>

                    <div className="collection__content">
                        <div className="collection__content--preitems">
                            <div className="collection__content--info">
                                <Skeleton
                                    containerClassName="collection__content--info--skeleton"
                                    className="skeleton__content"
                                />
                            </div>

                            <div className={styles.items_wrapper}>
                                <div
                                    className={cnb(
                                        styles.items_container,
                                        'items_container--skeleton',
                                    )}
                                >
                                    {new Array(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0).map((_, id) => (
                                        <div key={id} className="collection__item--skeleton">
                                            <Skeleton
                                                containerClassName="collection__item--skeleton"
                                                className="skeleton__content"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileSkeleton;
