import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import { CustomSelect } from '../../common/CustomSelect';
import FilterItem from '../../components/FilterItem';
import SortItem from '../../components/SortItem';
import OpportunitiesItem from './OpportunitiesItem';
import OpportunitiesSkeleton from './OpportunitiesSkeleton';
import CollectionInfo from '../../common/CollectionInfo';
import FullDescription from '../../common/FullDescription';
import InfoBlock from '../../common/InfoBlock';
import {useLazyGetAllServicesQuery} from "../../redux/api/dataService";

const sortList = [
    { value: 'price', name: 'Price low to high' },
    { value: 'recently', name: 'Recently listed' },
    { value: 'recentlyBook', name: 'Recently booked' },
    { value: 'recentlyMint', name: 'Recently minted' },
    { value: 'rare', name: 'Most rare' },
    { value: 'soon', name: 'Ending soon' },
];

const Opportunities = () => {
    const [data, setData] = useState([])
    const [filter, setFilter] = React.useState('price');
    const [filterActive, setFilterActive] = React.useState(false);
    const [sortActive, setSortActive] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            text: 'Ready',
            active: false,
        },
        {
            text: 'Waiting',
            active: false,
        },
        {
            text: 'Stoped',
            active: false,
        },
    ]);


    const [isRefetch, setIsRefetch] = useState(false)
    const [filterData2, setFilterData2] = React.useState([
        {
            text: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const [getData,{isLoading}] = useLazyGetAllServicesQuery({search: search})

    const changeSearch = (e) => {
        setSearch(e.target.value);

        if(e.target.value === '') {
            getData({
                search: search
            }).unwrap()
              .then((result) => setData(result))
        }
    };

    const emptySearch = () => {
        setSearch('');
        getData({
            search: search
        }).unwrap()
          .then((result) => setData(result))
    };

    const filterChange = (value) => {
        setFilter(value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getData({
                search: search
            }).unwrap()
              .then((result) => setData(result))
        }
    };


    useEffect(() => {
        getData({})
          .unwrap()
          .then((result) => setData(result))
    }, [isRefetch])

    if (isLoading) {
        return <OpportunitiesSkeleton />;
    }

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <h2 className="title collection__title">Social Opportunities</h2>

                    <InfoBlock
                        data={[
                            {
                                title: 'collections',
                                value: 0,
                            },
                            {
                                title: 'companies',
                                value: '0',
                            },
                        ]}
                    />

                    <FullDescription description="Get additional social opportunities from owning NFT: VIP service, passes to private events, significant discounts and gifts from business owners. 1. Find a service available to the owner of the NFT; 2. Contact the service provider; 3. Show the QR code from the NFT page and receive the service/gift. If you would like to attract NFT customers to your business, click 'Add Service' and fill in the form." />

                    <div className="collection__filter--content">
                        <button
                            className="button collection__filter--button"
                            onClick={() => setFilterActive((prev) => !prev)}
                        >
                            <img
                                src="/assets/img/filter.svg"
                                alt="filter"
                                className="collection__filter--icon"
                            />

                            <img
                                src="/assets/img/filter2.svg"
                                alt="filter"
                                className="collection__filter--icon mobile"
                            />

                            <p className="collection__filter--button--text">Filters</p>
                        </button>

                        <button
                            className="button collection__sort--button"
                            onClick={() => setSortActive((prev) => !prev)}
                        >
                            <img
                                src="/assets/img/sort.svg"
                                alt="sort"
                                className="collection__filter--icon mobile"
                            />

                            <p className="collection__sort--button--text">Sort</p>
                        </button>

                        <div className="collection__search--inner">
                            <input
                                type="search"
                                className="input header__search"
                                placeholder="Search by token name"
                                value={search}
                                onChange={changeSearch}
                                onKeyDown={handleKeyPress}
                            />

                            <img
                                src="/assets/img/search.svg"
                                alt="search"
                                className="header__search--icon"
                            />

                            {search && (
                                <img
                                    src="/assets/img/cross2.svg"
                                    alt="cross"
                                    className="header__search--remove"
                                    onClick={emptySearch}
                                />
                            )}
                        </div>

                        <div className="collection__filter--order">
                            <CustomSelect
                                optionsList={sortList}
                                value={filter}
                                placeholder="Select filter"
                                onChange={filterChange}
                            />
                        </div>

                        <Link to="/admin/createservice" className="collection__get default__hover">
                            Add Service
                        </Link>
                    </div>

                    <div className="collection__content">
                        {filterActive && (
                            <div className="collection__filter--box">
                                <div className="collection__filter--title--box">
                                    <p className="collection__filter--title">Filters</p>

                                    <img
                                        src="/assets/img/cross2.svg"
                                        alt="cross"
                                        className="collection__filter--title--cross"
                                        onClick={() => setFilterActive((prev) => !prev)}
                                    />
                                </div>

                                <FilterItem
                                    title="Status"
                                    value="2"
                                    elements={filterData}
                                    setData={setFilterData}
                                    filters={filters}
                                    setFilters={setFilters}
                                />

                                <FilterItem
                                    title="Character 1"
                                    value="3"
                                    elements={filterData2}
                                    setData={setFilterData2}
                                    setFilters={setFilters}
                                    filter
                                    filters={filters}
                                />

                                <div className="collection__filter--buttons">
                                    <button className="button collection__filter--button--filter">
                                        Clear all
                                    </button>

                                    <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive((prev) => !prev)}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        )}

                        {sortActive && (
                            <div className="collection__sort--box">
                                <div className="collection__filter--title--box">
                                    <p className="collection__filter--title">Sort by</p>

                                    <img
                                        src="/assets/img/cross2.svg"
                                        alt="cross"
                                        className="collection__filter--title--cross"
                                        onClick={() => setSortActive((prev) => !prev)}
                                    />
                                </div>

                                <div className="collection__sort--content">
                                    {sortList.map((data, id) => (
                                        <SortItem
                                            key={id}
                                            text={data.name}
                                            id={`${data.value}_${id}`}
                                        />
                                    ))}
                                </div>

                                <div className="collection__filter--buttons">
                                    <button className="button collection__sort--button--filter blue__button" onClick={() => setSortActive((prev) => !prev)}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="collection__content--preitems">
                            <CollectionInfo tokensCount={`${data && data.results && data.results.length} items`}/>

                            <div className="collection__filter--active">
                                <div className="collection__filter--active--content">
                                    <button className="button collection__filter--active--item">
                                        <p className="collection__filter--active--item--text">
                                            Ready
                                        </p>

                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="collection__filter--active--item--delete"
                                        />
                                    </button>
                                </div>

                                <button className="button collection__filter--clear">
                                    Clear All
                                </button>
                            </div>

                            <div className="collection__content--items">
                                {data.length === 0 ? (
                                    <div className="collection__items--none">
                                        <img src="/assets/img/items-none.svg" alt="none" />
                                        No items to display
                                    </div>
                                ) : (
                                    <div
                                        className={`stats__content${filterActive ? ' active' : ''}`}
                                    >
                                        <div className="stats__item--names whithdraw__names">
                                            <div className="stats__item--day opp__item--item stats__item--item stats__item--name order__last">
                                                Status
                                            </div>
                                            <div className="stats__item--day opp__item--item stats__item--item stats__item--name order__last">
                                                Service
                                            </div>

                                            <div className="stats__item--volume opp__item--item stats__item--item stats__item--name big">
                                                Company
                                            </div>

                                            <div className="stats__item--day opp__item--item stats__item--item stats__item--name off700 activeoff1200">
                                                Description
                                            </div>


                                            <div className="stats__item--price opp__item--item stats__item--item stats__item--name off998 activeoff1600">
                                                Contacts
                                            </div>

                                            <div className="stats__item--owners opp__item--item stats__item--item stats__item--name off1200 activeoff1600">
                                                Access
                                            </div>

                                            <div className="stats__item--items opp__item--item stats__item--item stats__item--name off1200 activeoff1600">
                                                Birth
                                            </div>
                                        </div>

                                        {data && data.results && data.results.length && data.results.map((data, id) => (
                                            <OpportunitiesItem key={id} data={data} id={id + 1} setIsRefetch={setIsRefetch}/>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Opportunities;
