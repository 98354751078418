import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const RedirectByShortCode = () => {
    const { code } = useParams();
   const navigate = useNavigate()
    useEffect(() => {
        fetch(`${BACKEND_URL}/admin_service/api/v1/factory/shorts/by_hash/${code}/`, {
            method: 'GET',
        })
            .then((res) => {
                return res.json();
            })
            .then((response) => {
               if (response && response.full_url) {
                       window.location.href = response.full_url
                    
               }
            });
    }, [code]);

    return <div></div>;
};
