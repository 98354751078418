import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userTokenReciever: null,
    tokenTransferList: [],
};

const tokensStatisticSlice = createSlice({
    name: 'tokens',
    initialState: initialState,
    reducers: {
        setUserTokenTransferStatus: (state, action) => {
            state.userTokenReciever = action.payload;
        },
        setTokenTransferList: (state, action) => {
            state.tokenTransferList = [...state.tokenTransferList, action.payload];
        },
    },
});

export const { setUserTokenTransferStatus, setTokenTransferList } = tokensStatisticSlice.actions;

export const tokensStatisticReducer = tokensStatisticSlice.reducer;
