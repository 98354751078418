import React, { useCallback, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './index.css';
import { useClickOut } from '../../functions/useClickOut';

const Modal = ({
    active,
    setActive,
    title,
    size = 'big',
    padding = false,
    children,
    onClose,
    style,
    isReturnBtn,
    onReturnBtnClick,
    type,
    goPrevStep,
    verifyStep,
}) => {
    const handleKeyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && active) {
                onClose ? onClose() : setActive(false);
            }
        },
        [onClose, setActive, active],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <div className={`modal${active ? ' active' : ''}`}>
            <div className={`modal__inner ${size}`} style={style}>
                <div className="modal__content">
                    <div style={{display:'flex', alignItems:'center', flexDirection:'row'}} className="modal__content--top">
                        {type === 'contacts' && verifyStep !== 0 && verifyStep !== 3 && (
                            <button
                                style={{ alignSelf: 'flex-start' }}
                                onClick={() => goPrevStep()}
                            >
                                <ArrowBackIcon sx={{ color: 'white', fill: 'white' }} size="big" />
                            </button>
                        )}
                        {isReturnBtn && (
                            <img
                                src="/assets/img/leftArrow.png"
                                alt="Cross"
                                onClick={onReturnBtnClick}
                                className={'modal__return'}
                            />
                        )}

                        <h2 style={{position:'static'}}className="modal__title">{title}</h2>
                        <img
                            src="/assets/img/cross2.svg"
                            alt="Cross"
                            className="modal__close"
                            onClick={onClose ? () => onClose() : () => setActive(false)}
                        />
                    </div>

                    <div className={`modal__content--bottom${padding ? ' padding' : ''}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
