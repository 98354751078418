import React, { useEffect } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

const ModalNew2 = ({
    value,
    setValue,
    title,
    isSteps = false,
    icon,
    step,
    size = '',
    setSteps = () => {},
    emptyModal = false,
    contentClassName,
    children,
}) => {
    const handleKeyPress = (e) => e.key === 'Escape' && value && setValue((prev) => !prev);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleCloseModal = (e) => {
        e.stopPropagation();
        setValue(false);
    };

    return createPortal(
        <div
            className={cn(styles.modal, styles[size], {
                [styles.active]: value,
            })}
        >
            <div className={cn(styles.modalInner, contentClassName)}>
                <div
                    className={cn(styles.modalContent, {
                        [styles.empty]: emptyModal,
                    })}
                >
                    {!emptyModal && (
                        <div className={styles.modalContentTop}>
                            <div
                                className={cn(styles.modalTitleInner, {
                                    [styles.steps]: isSteps,
                                })}
                            >
                                {isSteps &&
                                    (icon ? (
                                        icon
                                    ) : (
                                        <svg
                                            width="31"
                                            height="31"
                                            viewBox="0 0 31 31"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => {
                                                if (step > 1) {
                                                    setSteps((prev) => prev - 1);
                                                }
                                            }}
                                        >
                                            <path
                                                d="M7.75 15.5013L24.5417 15.5013M7.75 15.5013L12.9167 10.3346M7.75 15.5013L12.9167 20.668"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    ))}

                                {title && <h2 className={styles.modalTitle}>{title}</h2>}
                            </div>

                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={styles.modalClose}
                                onClick={(e) => handleCloseModal(e)}
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                    fill="currentColor"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    )}

                    {emptyModal && (
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={styles.modalClose}
                            onClick={(e) => handleCloseModal(e)}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                fill="currentColor"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                fill="currentColor"
                            />
                        </svg>
                    )}

                    {children}
                </div>
            </div>
        </div>,
        document.body,
    );
};

export default ModalNew2;
