import React from 'react';

const PartnersComing = ({ ...props }) => {
    return (
        <svg
            width="79"
            height="49"
            viewBox="0 0 79 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10.2199 43.5977H1.16406V45.1969H10.2199V43.5977Z" fill="currentColor" />
            <path d="M10.2199 46.0703H1.16406V47.714H10.2199V46.0703Z" fill="currentColor" />
            <path
                d="M48.4966 1.40242L48.3113 1.32481L48.0651 1.28906H30.9078L30.6633 1.32481L30.4928 1.39545L30.3466 1.51229L30.1822 1.7146L29.4375 2.91011H49.5823L48.8072 1.70065L48.6784 1.52276L48.4966 1.40242Z"
                fill="currentColor"
            />
            <path d="M10.2199 6.25H1.16406V42.7249H10.2199V6.25Z" fill="currentColor" />
            <path
                d="M77.5266 3.60771C77.5127 3.60161 77.5048 3.59027 77.4927 3.58417C77.4683 3.57109 77.4422 3.56237 77.4204 3.54405L77.1647 3.33565C77.1542 3.3278 77.1507 3.31733 77.1429 3.30861C77.1342 3.30077 77.1238 3.29728 77.1159 3.28943L76.9071 3.05573C76.8958 3.04353 76.8924 3.0287 76.8837 3.01562C76.8776 3.0069 76.868 3.00341 76.8628 2.99557L76.0659 1.71721L75.9066 1.52189L75.7431 1.40329L75.5386 1.32394L75.3342 1.28906H58.1369L57.9333 1.32394L57.7297 1.40155L57.5636 1.5245L57.4061 1.71547L56.6083 2.99557C56.6048 3.00167 56.5979 3.00341 56.5935 3.00952C56.5874 3.01911 56.5857 3.0287 56.5787 3.03742L56.3925 3.27112C56.3821 3.2842 56.3664 3.28943 56.3551 3.30077C56.3403 3.31559 56.3308 3.3339 56.3134 3.34785L56.3142 3.3496C56.3308 3.36181 56.3377 3.37837 56.3516 3.39145C56.3647 3.40453 56.3804 3.41064 56.3917 3.42546L56.5787 3.65829C56.5839 3.66526 56.5857 3.67485 56.5909 3.68183C56.5961 3.68881 56.6048 3.69404 56.6092 3.70189L57.4087 5.0064L57.5549 5.18429L57.7141 5.28544L57.9194 5.36479L58.1134 5.38136H77.8641V3.73764C77.8441 3.73241 77.8215 3.73241 77.8024 3.72369L77.5266 3.60771Z"
                fill="currentColor"
            />
            <path d="M20.1222 43.5977H11.0898V45.1969H20.1222V43.5977Z" fill="currentColor" />
            <path d="M20.1222 46.0703H11.0898V47.714H20.1222V46.0703Z" fill="currentColor" />
            <path
                d="M11.3457 5.00465L12.1443 3.70014C12.1513 3.68793 12.1635 3.68183 12.1713 3.67137C12.1791 3.66003 12.1826 3.64695 12.1913 3.63649L12.4001 3.40366C12.4079 3.39494 12.4184 3.39145 12.4271 3.38361C12.4349 3.37663 12.4393 3.36617 12.4471 3.35919L12.7029 3.14904C12.7333 3.12462 12.7681 3.11241 12.8012 3.09672C12.8186 3.088 12.8325 3.07579 12.8508 3.06969L12.8543 3.06881L12.8569 3.06794L13.1518 2.97725C13.1614 2.97377 13.1709 2.97638 13.1814 2.97377C13.1909 2.97202 13.1988 2.96505 13.2083 2.9633L13.4876 2.91621C13.5007 2.9136 13.5128 2.91883 13.525 2.91709C13.5372 2.91621 13.5468 2.91011 13.559 2.91011H22.3407L21.576 1.71721L21.4098 1.51404L21.2619 1.39545L21.0914 1.32481L20.8478 1.28906H3.69228L3.44781 1.32481L3.25902 1.40329L3.09285 1.51404L2.97975 1.6928L2.97801 1.69454C2.97714 1.69629 2.97627 1.6989 2.97627 1.6989L2.11671 3.00429C2.11323 3.00952 2.10714 3.01126 2.10366 3.01649C2.09931 3.0226 2.09844 3.03045 2.09322 3.03655L1.90704 3.27025C1.90008 3.27897 1.88877 3.28245 1.88094 3.2903C1.87224 3.30077 1.86789 3.3121 1.85745 3.32169L1.63734 3.52051L1.62603 3.53097C1.59123 3.56149 1.55208 3.58242 1.51206 3.59986C1.50423 3.60422 1.49901 3.61033 1.49118 3.61381L1.48857 3.61469L1.4877 3.61556L1.18668 3.72892C1.17972 3.73153 1.17189 3.73066 1.16406 3.73328V5.37961H10.7984C10.8002 5.37874 10.8019 5.37874 10.8045 5.37787C10.8158 5.37525 10.8272 5.37089 10.8393 5.36828L11.036 5.28631L11.1986 5.18254L11.3457 5.00465Z"
                fill="currentColor"
            />
            <path d="M77.8632 43.5977H20.9922V45.1969H77.8632V43.5977Z" fill="currentColor" />
            <path
                d="M21.2949 5.00205L22.0214 3.78125H13.5963L13.3788 3.81787L13.19 3.87543L13.0247 4.01146L12.8638 4.18935L12.0712 5.48339C12.0669 5.49124 12.0582 5.49386 12.0529 5.50083C12.046 5.5113 12.0442 5.52263 12.0364 5.53223L11.8267 5.78772C11.8146 5.80255 11.798 5.80691 11.785 5.81999C11.765 5.83917 11.7493 5.86271 11.7249 5.87754L11.47 6.04147C11.4622 6.04671 11.4526 6.04409 11.4448 6.04932C11.43 6.05804 11.4196 6.07025 11.403 6.07723L11.1238 6.19407C11.1125 6.19843 11.1012 6.19756 11.0898 6.20105V42.7256H20.1222V6.11734C20.1222 6.09815 20.1309 6.08071 20.1335 6.0624C20.1361 6.04409 20.1318 6.02665 20.1361 6.00834L20.1596 5.91678C20.1631 5.9037 20.1718 5.89411 20.177 5.8819C20.184 5.85923 20.197 5.8383 20.2083 5.81737C20.2222 5.79295 20.2335 5.76767 20.2518 5.74674C20.2596 5.72755 20.2718 5.71099 20.2831 5.69267C20.2901 5.67785 20.2979 5.6639 20.3075 5.64995C20.3266 5.62466 20.3484 5.60373 20.3719 5.58367C20.3858 5.57147 20.3928 5.55751 20.4084 5.54705L20.4389 5.52699L20.4554 5.50955C20.4632 5.50083 20.4745 5.49822 20.4832 5.49037C20.5041 5.47206 20.5267 5.45898 20.5511 5.44415C20.5789 5.42759 20.6059 5.41363 20.6364 5.40491C20.6459 5.40143 20.6529 5.39445 20.6625 5.39183L20.7555 5.36916C20.7608 5.36742 20.766 5.36916 20.7721 5.36829L20.9861 5.28545L21.1479 5.18255L21.2949 5.00205Z"
                fill="currentColor"
            />
            <path
                d="M58.062 6.24901L57.7836 6.22634C57.7662 6.22459 57.7523 6.21587 57.7366 6.21239C57.7114 6.20715 57.687 6.20803 57.6627 6.19843L57.3599 6.08159C57.3408 6.07374 57.3286 6.05979 57.3103 6.05019C57.3008 6.04496 57.2912 6.04758 57.2816 6.04147L57.0267 5.87754C57.0024 5.86184 56.9867 5.83917 56.9667 5.81999C56.9536 5.80691 56.938 5.80167 56.9258 5.78772L56.7153 5.53223C56.7083 5.52263 56.7057 5.5113 56.6987 5.50083C56.6935 5.49386 56.6848 5.49124 56.6805 5.48339L55.8792 4.17627L55.7461 4.01059L55.5677 3.8763L55.3911 3.817L55.158 3.78125H51.0315H27.9487H23.811H23.5404L23.2803 3.81875L23.1002 3.87368L22.9575 4.00274L22.7913 4.1876L22.0231 5.47816C22.0188 5.48601 22.0101 5.48863 22.0049 5.4956C21.997 5.50781 21.9944 5.52089 21.9857 5.53223L21.7769 5.78772C21.7647 5.80342 21.7465 5.80865 21.7334 5.82173C21.7134 5.84004 21.6978 5.86359 21.6743 5.87841L21.4176 6.04235C21.408 6.04845 21.3976 6.04583 21.388 6.05107C21.3715 6.06066 21.3593 6.07374 21.3419 6.08071L21.0409 6.19756C21.0244 6.20367 21.0087 6.20279 20.9922 6.20715V42.7256H77.8632V6.25075H58.0977C58.0855 6.25075 58.0742 6.24988 58.062 6.24901Z"
                fill="currentColor"
            />
            <path d="M77.8632 46.0703H20.9922V47.714H77.8632V46.0703Z" fill="currentColor" />
        </svg>
    );
};

export default PartnersComing;
