import React from 'react';

const Web = ({ ...props }) => {
    return (
        // <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M1.04199 10.0013C1.04199 5.05375 5.05277 1.04297 10.0003 1.04297C14.9479 1.04297 18.9587 5.05375 18.9587 10.0013C18.9587 14.9489 14.9479 18.9596 10.0003 18.9596C5.05277 18.9596 1.04199 14.9489 1.04199 10.0013ZM10.0003 2.29297C5.74313 2.29297 2.29199 5.74411 2.29199 10.0013C2.29199 14.2585 5.74313 17.7096 10.0003 17.7096C14.2575 17.7096 17.7087 14.2585 17.7087 10.0013C17.7087 5.74411 14.2575 2.29297 10.0003 2.29297Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M1.04199 10C1.04199 9.65482 1.32181 9.375 1.66699 9.375H18.3337C18.6788 9.375 18.9587 9.65482 18.9587 10C18.9587 10.3452 18.6788 10.625 18.3337 10.625H1.66699C1.32181 10.625 1.04199 10.3452 1.04199 10Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M8.24874 1.8934C8.72117 1.39385 9.31286 1.04297 10.0003 1.04297C10.6878 1.04297 11.2795 1.39385 11.7519 1.8934C12.2231 2.39163 12.6186 3.07903 12.9376 3.87663C13.5771 5.47529 13.9587 7.64096 13.9587 10.0013C13.9587 12.3616 13.5771 14.5273 12.9376 16.126C12.6186 16.9236 12.2231 17.611 11.7519 18.1092C11.2795 18.6088 10.6878 18.9596 10.0003 18.9596C9.31286 18.9596 8.72117 18.6088 8.24874 18.1092C7.77757 17.611 7.38204 16.9236 7.063 16.126C6.42354 14.5273 6.04199 12.3616 6.04199 10.0013C6.04199 7.64096 6.42354 5.47529 7.063 3.87663C7.38204 3.07903 7.77757 2.39163 8.24874 1.8934ZM8.2236 4.34086C7.65663 5.75828 7.29199 7.75927 7.29199 10.0013C7.29199 12.2433 7.65663 14.2443 8.2236 15.6617C8.50777 16.3722 8.83052 16.9052 9.15693 17.2503C9.4821 17.5942 9.76731 17.7096 10.0003 17.7096C10.2333 17.7096 10.5186 17.5942 10.8437 17.2503C11.1701 16.9052 11.4929 16.3722 11.7771 15.6617C12.344 14.2443 12.7087 12.2433 12.7087 10.0013C12.7087 7.75927 12.344 5.75828 11.7771 4.34086C11.4929 3.63042 11.1701 3.09744 10.8437 2.75229C10.5186 2.40845 10.2333 2.29297 10.0003 2.29297C9.76731 2.29297 9.4821 2.40845 9.15693 2.75229C8.83052 3.09744 8.50777 3.63042 8.2236 4.34086Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M3.66548 3.78462C3.90956 3.54054 4.30529 3.54054 4.54936 3.78462C5.94498 5.18025 7.87121 6.04235 9.99996 6.04235C12.1287 6.04235 14.055 5.18024 15.4506 3.78462C15.6947 3.54054 16.0904 3.54054 16.3345 3.78462C16.5786 4.0287 16.5786 4.42443 16.3345 4.6685C14.714 6.28896 12.4736 7.29235 9.99996 7.29235C7.52637 7.29235 5.28593 6.28896 3.66548 4.6685C3.4214 4.42443 3.4214 4.0287 3.66548 3.78462Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M3.66548 15.3309C5.28593 13.7104 7.52637 12.707 9.99996 12.707C12.4736 12.707 14.714 13.7104 16.3345 15.3309C16.5786 15.575 16.5786 15.9707 16.3345 16.2148C16.0904 16.4588 15.6947 16.4588 15.4506 16.2148C14.055 14.8191 12.1287 13.957 9.99996 13.957C7.87121 13.957 5.94498 14.8191 4.54936 16.2148C4.30529 16.4588 3.90956 16.4588 3.66548 16.2148C3.4214 15.9707 3.4214 15.575 3.66548 15.3309Z"
        //         fill="currentColor"
        //     />
        // </svg>
        <svg
            viewBox="0 0 15.4028 18.0005"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs />
            <path
                id="Vector"
                d="M0 3.91L2.3 7.81L2.3 10.19L0 14.08L0 3.91Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M2.1 7.86L2.1 10.13L0.2 13.34L0.2 4.65L2.1 7.86ZM2.3 7.81L0 3.91L0 13.68L0 14.08L0.2 13.74L2.3 10.19L2.3 7.81Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M8.83 6.39L10.98 5.14L15.4 5.14L8.83 8.95L8.83 6.39Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M11.04 5.34L9.03 6.51L9.03 8.6L14.65 5.34L11.04 5.34ZM15 5.14L15.4 5.14L15.05 5.34L9.03 8.83L8.83 8.95L8.83 6.39L10.98 5.14L15 5.14Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M8.82 3.89L8.83 9.05L6.52 7.71L6.52 0L8.82 3.89Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M8.83 8.82L8.83 9.05L8.63 8.93L6.52 7.71L6.52 0L8.82 3.89L8.83 8.82ZM8.63 8.7L6.72 7.59L6.72 0.73L8.62 3.94L8.63 8.7Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M15.4 5.14L10.99 5.14L8.82 3.89L6.52 0L15.4 5.14Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M10.99 5.14L8.82 3.89L6.73 0.35L6.52 0L15.05 4.94L15.4 5.14L15 5.14L10.99 5.14ZM14.65 4.94L7.08 0.55L8.96 3.74L11.04 4.94L14.65 4.94Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M8.83 14.09L8.83 11.59L6.52 10.28L6.52 17.99L8.83 14.09Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M8.83 11.59L8.83 14.09L6.72 17.66L6.52 17.99L6.52 17.6L6.52 10.51L6.52 10.28L6.72 10.39L8.83 11.59ZM6.72 10.62L6.72 17.26L8.63 14.04L8.63 11.71L6.72 10.62Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M10.98 12.86L2.3 7.81L0 3.91L15.39 12.85L10.98 12.86Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M2.3 7.81L0 3.91L15.04 12.65L15.39 12.85L14.99 12.85L10.98 12.86L2.3 7.81ZM11.03 12.66L14.65 12.65L0.56 4.47L2.44 7.66L11.03 12.66Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M6.52 18L8.83 14.1L10.98 12.86L15.39 12.85L6.52 18Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M8.97 14.24L7.09 17.44L14.64 13.05L11.03 13.06L8.97 14.24ZM10.98 12.86L14.99 12.85L15.39 12.85L15.04 13.05L6.88 17.79L6.52 18L6.73 17.64L8.83 14.1L10.98 12.86Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Vector"
                d="M0 14.08L6.54 10.28L4.34 9L2.3 10.19L0 14.08Z"
                fill="#207CE2"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M6.54 10.28L6.34 10.16L4.34 9L2.3 10.19L0 14.08L0.35 13.87L6.34 10.39L6.54 10.28ZM6.14 10.28L0.56 13.52L2.44 10.33L4.34 9.23L6.14 10.28Z"
                fill="#161828"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default Web;
