import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import CollectionItem from '../CollectionItem';
import NotContent from '../../../../icons/NotContent';
// import CollectionItemSkeleton from '../CollectionItemSkeleton';
// import isElementIntoViewPort from '../../../../utils/isElementIntoViewPort';
// import {
//     CollectionContext,
//     CollectionContextProvider,
// } from '../../lib/Collection/CollectionContext';
// import { WhiteListContext, WhiteListContextProvider } from '../../lib/Collection/WhiteListContext';
// import {
//     FiltersContext,
//     FiltersContextProvider,
//     SORT_BY_OPTIONS_ARR,
// } from '../../lib/Collection/FiltersContext';

export const Collections = ({ setCount }) => {
    const [fullText, setFullText] = React.useState(false);
    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };
    const { pathname, search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const cat = queryParams.get('cat');
    const [selectedCategory, setSelectedCategory] = React.useState('all');
    const containerRef = useRef(null);
    const [filter, setFilter] = React.useState(null);

    // const [isLoading, setIsLoadingCollection] = React.useState(true);
    const pages = useSelector((state) => state.pages.entities);
    const [description, setDescription] = React.useState(
        `Цифровые сертификаты - это новый прорыв в мире современных технологий. 
        Они не только подтверждают подлинность и права владения, но и открывают доступ к 
        эксклюзивным товарам, услугам и мероприятиям. 
        
        Цифровые сертификаты могут служить в качестве членских билетов, 
        пропусков, а также участвовать в различных розыгрышах призов.   
        Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
        коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`,
    );
    const [collections, setCollections] = useState(null);
    const accesToken = localStorage.getItem('access_token');
    useEffect(() => {
        const params = new URLSearchParams();

        if (filter !== null) {
            params.append('paid', filter);
        }

        if (selectedCategory !== 'all') {
            params.append('page_id', selectedCategory);
        }

        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL
            }/handler_service/api/v1/collection/?${params.toString()}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accesToken}`,
                },
            },
        )
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                setCollections(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [selectedCategory, filter]);
    // const {
    //     state: {
    //         bookedTokensIds,
    //         collectionTokens,
    //         collectionTokensCount: count,
    //         sortBy: filterSortBy,
    //     },
    //     actions: { onNextPageCollectionsTokensHandler, handlePage, handleFilter },

    //     isTokensLoading,
    //     isFetchingLoading,
    // } = React.useContext(CollectionContext);

    // const { isWhitelistApplicationLoading } = React.useContext(WhiteListContext);

    // const containerRef = React.useRef(null);
    // const firstUndrowenItemIdx = React.useRef(-1);

    // const isDataLoading = React.useMemo(() => {
    //     return !collectionTokens || isWhitelistApplicationLoading || isTokensLoading;
    // }, [collectionTokens, isWhitelistApplicationLoading, isTokensLoading, isFetchingLoading]);

    // React.useEffect(() => {
    //     setIsLoadingCollection(isDataLoading);
    // }, [isDataLoading]);

    // React.useEffect(() => {
    //     setCount(count);
    // }, [count]);

    // const onPageScrollHandler = React.useCallback(() => {
    //     if (containerRef.current && firstUndrowenItemIdx.current > -1) {
    //         const elem = Array.from(containerRef.current.childNodes)[firstUndrowenItemIdx.current];

    //         if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
    //             onNextPageCollectionsTokensHandler();
    //             firstUndrowenItemIdx.current = -1;
    //         }
    //     }
    // }, [isDataLoading]);

    React.useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const cat = queryParams.get('cat');
        setSelectedCategory(cat && cat !== '' ? cat : 'all');
    }, [search]);
    
    React.useEffect(() => {
        if (selectedCategory !== null) {
            // handlePage(selectedCategory);

            setDescription(
                selectedCategory == 'all'
                    ? `
                    Цифровые сертификаты - это новый прорыв в мире современных технологий. 
                    Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. 
                    Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. 
                    
                    Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
                    коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`
                    : pages[selectedCategory].description,
            );
        }
    }, [selectedCategory]);

    // React.useEffect(() => {
    //     handleFilter(filter);
    // }, [filter]);

    // React.useEffect(() => {
    //     window.addEventListener('scroll', onPageScrollHandler);

    //     return () => {
    //         window.removeEventListener('scroll', onPageScrollHandler);
    //     };
    // }, [isDataLoading]);
    // console.log('render Colelctions')
    return (
        <>
            <div className={styles.brandSubTabs}>
                {/* {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />
                    </>
                ) : ( */}
                <>
                    <button
                        className={cn(styles.brandSubTab, {
                            [styles.active]: selectedCategory === 'all',
                        })}
                        onClick={() => {
                            setSelectedCategory('all');
                        }}
                    >
                        Все
                    </button>
                    {Object.values(pages).map((page) => (
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: selectedCategory === page.id,
                            })}
                            onClick={() => {
                                setSelectedCategory(page.id);
                            }}
                        >
                            {page.name}
                        </button>
                    ))}
                </>
                {/* )} */}
            </div>

            {/* {isLoading ? (
                <div className={styles.brandsText}>
                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />
                </div>
            ) : ( */}
            <div className={styles.brandsText} style={{ whiteSpace: 'pre-line' }}>
                <p>
                    {description && description.length > 150 && !fullText
                        ? `${description.slice(0, 150)}...`
                        : description}
                </p>

                {description && description.length > 150 && (
                    <a href="#" className={styles.brandsTextShow} onClick={showText}>
                        {fullText ? 'Свернуть' : 'Развернуть'}
                    </a>
                )}
            </div>
            {/* )} */}

            <div className={styles.brandOuter}>
                <div className={styles.brandSubTabs}>
                    {/* {isLoading ? (
                        <>
                            <Skeleton
                                containerClassName={cn(
                                    styles.brandSubTabSkeletonWrapper,
                                    styles.small,
                                )}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />
                        </>
                    ) : ( */}
                    <>
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === null,
                            })}
                            onClick={() => {
                                setFilter(null);
                            }}
                        >
                            Все
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === true,
                            })}
                            onClick={() => {
                                setFilter(true);
                            }}
                        >
                            Продано
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === false,
                            })}
                            onClick={() => {
                                setFilter(false);
                            }}
                        >
                            В продаже
                        </button>
                    </>
                    {/* )} */}
                </div>

                {/* {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={styles.tokenValueSkeletonWrapper}
                            className={styles.tokenValueSkeleton}
                        />
                    </>
                ) : (
                    <p className={styles.tokenValue}>{count} шт.</p>
                )} */}
            </div>

            <div className={styles.brandsContent} ref={containerRef}>
                {/* {isLoading ? (
                    <>
                        {' '}
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />{' '}
                    </>
                ) : (
                    <> */}
                {collections &&
                    collections.results &&
                    collections.results.length > 0 &&
                    collections.results.map((collection) => (
                        <CollectionItem key={collection.id} collection={collection} />
                    ))}
                {/* {collectionTokens.length > 0 &&
                            count - collectionTokens.length > 0 &&
                            Array.from(Array(count - collectionTokens.length).keys()).map((idx) => {
                                if (firstUndrowenItemIdx.current === -1) {
                                    firstUndrowenItemIdx.current = idx + collectionTokens.length;
                                }
                                return <CollectionItemSkeleton />;
                            })}
                    </>
                )} */}
            </div>
            {collections && collections.results && collections.results.length === 0 && (
                <div className={styles.notContentWrapper}>
                    <div className={styles.notContent}>
                        <NotContent />

                        <p className={cn(base.title2, styles.notContentTitle)}>
                            Нет информации
                            <br />
                            для отображения
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export const CollectionsContext = ({ setCount }) => {
    // const { id } = useParams();

    return (
        // <FiltersContextProvider>
        //     <CollectionContextProvider id={id}>
        //         <WhiteListContextProvider>
        <Collections setCount={setCount} />
        //         </WhiteListContextProvider>
        //     </CollectionContextProvider>
        // </FiltersContextProvider>
    );
};
