import React from 'react';

import css from './index.module.css';

import RefreshDataComponent from '../RefreshDataComponent';

const CollectionInfo = ({ count ,handleRefresh}) => {
    return (
        <div className={css.collectionContentInfo}>
            <RefreshDataComponent handleRefresh={handleRefresh} />

            <p className={css.collectionItemsValue}>{count} шт.</p>
        </div>
    );
};

export default CollectionInfo;
