import React from 'react';

import styles from './index.module.css';

const SocialLink = ({ children, href = "#" }) => {
    return (
        <a href={href} className={styles.profileSocialLink}>
            {children}
        </a>
    );
};

export default SocialLink;
