import React from 'react';

const Reload = ({ ...props }) => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.04685 3.60156C6.98356 3.60156 6.91938 3.61682 6.85989 3.64895C6.7859 3.68883 6.7299 3.7486 6.69555 3.81845L4.45491 7.44479L4.45367 7.44511C4.41322 7.50436 4.38883 7.57538 4.38685 7.65224C4.38232 7.82828 4.49693 7.97995 4.65831 8.03197L4.65733 8.03215C4.69802 8.04533 4.73999 8.05157 4.78177 8.05157C4.78956 8.05157 4.79733 8.05135 4.80511 8.05091L4.80623 8.05123L9.09928 8.16005C9.10877 8.16074 9.11825 8.1611 9.1277 8.1611C9.19029 8.1611 9.25201 8.14577 9.31151 8.11381C9.50161 8.01144 9.57177 7.77556 9.46839 7.5873L9.01642 6.76666C9.02639 6.7612 9.03638 6.75576 9.04642 6.75036C9.69912 6.39872 10.4032 6.23187 11.0974 6.23187C12.6224 6.23187 14.0997 7.03708 14.8795 8.4573C16.0144 10.5241 15.2428 13.1106 13.1561 14.2347C12.5034 14.5864 11.7992 14.7532 11.1049 14.7532C10.0025 14.7532 8.92513 14.3325 8.11826 13.5631C7.87477 13.3332 7.55088 13.2076 7.21969 13.2076C7.0098 13.2076 6.79695 13.258 6.59965 13.3643C5.96882 13.7041 5.73559 14.486 6.07868 15.1109C6.14439 15.2305 6.22779 15.3328 6.32209 15.4226L6.32287 15.4234C7.61674 16.6541 9.34214 17.3267 11.1075 17.3267C12.221 17.3267 13.3503 17.0591 14.3973 16.4952C17.7443 14.692 18.9819 10.5431 17.1616 7.2279C15.9106 4.94984 13.5412 3.65831 11.0951 3.65831C9.9816 3.65831 8.85221 3.92595 7.80523 4.48998C7.79556 4.49516 7.78591 4.50041 7.77643 4.50593L7.39111 3.80423C7.3201 3.67492 7.18564 3.60156 7.04685 3.60156Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Reload;
