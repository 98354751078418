import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const SellTimer = () => {
    const { id } = useParams();
    const [time, setTime] = useState(getInitialTime(id));

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => {
                if (prevTime === 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const timers = JSON.parse(localStorage.getItem('timer')) || [];
        const updatedTimers = timers.map(timer => {
            if (timer.id === id) {
                return { ...timer, time };
            }
            return timer;
        });

        const existingTimerIndex = updatedTimers.findIndex(timer => timer.id === id);
        if (existingTimerIndex === -1) {
            updatedTimers.push({ id, time });
        }

        localStorage.setItem('timer', JSON.stringify(updatedTimers));
    }, [time]);

    // Функция для форматирования времени в формате 23ч 55м 12с
    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}ч ${minutes}м ${seconds}с`;
    };

    return (
        <p className="token__card--price--text">
            До закрытия продажи: {formatTime(time)}
        </p>
    );
};

// Функция для получения начального времени (если ранее сохранено, то продолжает с того же момента)
function getInitialTime(id) {
    const timers = JSON.parse(localStorage.getItem('timer')) || [];
    const foundTimer = timers.find(timer => timer.id === id);
    return foundTimer ? foundTimer.time : 86400; // 24 часа в секундах
}
