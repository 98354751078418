import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const InputNew = ({
    value,
    setValue,
    title,
    errorMessage,
    error = false,
    success = false,
    successMessage = "",
    placeholder,
    withHideButton = false,
    center = false,
    isCode = false,
    codeLength,
}) => {
    const [hide, setHide] = React.useState(false);

    const codeRef = React.useRef(null);

    return (
        <div className={styles.inputItem}>
            <div className={styles.inputItemTop}>
                <p className={styles.inputItemTitle}>{title}</p>

                {errorMessage && <p className={styles.inputItemErrorMessage}>{errorMessage}</p>}
                {successMessage && (
                    <p className={styles.inputItemSuccessMessage}>{successMessage}</p>
                )}
            </div>

            <div className={styles.inputItemWrapper}>
                <input
                    type="text"
                    placeholder={placeholder}
                    className={cn(styles.input, {
                        [styles.error]: error,
                        [styles.errorInput]: error || errorMessage,
                        [styles.success]: success || successMessage,
                        [styles.withHide]: withHideButton,
                        [styles.center]: center,
                        [styles.code]: isCode && !!value.length,
                    })}
                    value={value}
                    onChange={(e) => {
                        // const value = e.target.value;
                        setValue(e.target.value)
                        // if (isCode && value.length <= codeLength) {
                        //     setValue(e.target.value);
                        // }
                    }}
                    ref={codeRef}
                />

                {isCode && !!value.length && (
                    <>
                        <div className={styles.inputCodeFake} onClick={() => {
                            codeRef.current && codeRef.current.focus();
                        }}></div>

                        <div className={styles.inputCodeNumbers} onClick={() => {
                            codeRef.current && codeRef.current.focus();
                        }}>
                            {[...Array(codeLength)].map((_, id) => (
                                <p
                                    key={id}
                                    className={cn(styles.inputCodeNumber, {
                                        [styles.show]: id + 1 <= value.length,
                                    })}
                                >
                                    {value[id] || ""}
                                </p>
                            ))}
                        </div>

                        <div className={styles.inputCodeDots} onClick={() => {
                            codeRef.current && codeRef.current.focus();
                        }}>
                            {[...Array(codeLength)].map((_, id) => (
                                <div
                                    key={id}
                                    className={cn(styles.inputCodeDot, {
                                        [styles.hide]: id + 1 <= value.length,
                                    })}
                                ></div>
                            ))}
                        </div>
                    </>
                )}

                {withHideButton && (
                    <button
                        className={styles.inputHideButton}
                        onClick={() => setHide((prev) => !prev)}
                    >
                        {!hide && (
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.5013 7.125C8.87141 7.125 8.26732 7.37522 7.82192 7.82062C7.37652 8.26602 7.1263 8.87011 7.1263 9.5C7.1263 10.1299 7.37652 10.734 7.82192 11.1794C8.26732 11.6248 8.87141 11.875 9.5013 11.875C10.1312 11.875 10.7353 11.6248 11.1807 11.1794C11.6261 10.734 11.8763 10.1299 11.8763 9.5C11.8763 8.87011 11.6261 8.26602 11.1807 7.82062C10.7353 7.37522 10.1312 7.125 9.5013 7.125ZM9.5013 13.4583C8.45149 13.4583 7.44467 13.0413 6.70234 12.299C5.96001 11.5566 5.54297 10.5498 5.54297 9.5C5.54297 8.45018 5.96001 7.44337 6.70234 6.70104C7.44467 5.9587 8.45149 5.54167 9.5013 5.54167C10.5511 5.54167 11.5579 5.9587 12.3003 6.70104C13.0426 7.44337 13.4596 8.45018 13.4596 9.5C13.4596 10.5498 13.0426 11.5566 12.3003 12.299C11.5579 13.0413 10.5511 13.4583 9.5013 13.4583ZM9.5013 3.5625C5.54297 3.5625 2.16255 6.02458 0.792969 9.5C2.16255 12.9754 5.54297 15.4375 9.5013 15.4375C13.4596 15.4375 16.8401 12.9754 18.2096 9.5C16.8401 6.02458 13.4596 3.5625 9.5013 3.5625Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}

                        {hide && (
                            <svg
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18 8.00086C16.2032 11.1544 12.8466 13.2751 9.0002 13.2751C7.73644 13.2751 6.5255 13.0465 5.40496 12.6268L2.28139 15.798C2.01447 16.0673 1.58377 16.0673 1.31842 15.798C1.05229 15.5278 1.05229 15.0905 1.31764 14.8211L4.07817 12.0193L12.5946 3.37401L15.7182 0.202044C15.9835 -0.067348 16.415 -0.067348 16.6803 0.202044C16.9473 0.471436 16.9457 0.91109 16.6819 1.18048L13.9222 3.98163C15.6206 4.91133 17.0347 6.30764 18 8.00086ZM7.07801 10.9283C7.6284 11.3016 8.28901 11.519 9.00004 11.519C10.9134 11.519 12.4653 9.94343 12.4653 8.001C12.4653 7.27996 12.2511 6.60847 11.8842 6.05131L10.896 7.05373C11.0346 7.33911 11.1125 7.66127 11.1125 8.001C11.1125 9.1849 10.1661 10.1457 9.00006 10.1457C8.66541 10.1457 8.34809 10.0665 8.06622 9.92583L7.07801 10.9283ZM2.89135 11.26C1.71893 10.3919 0.730699 9.28233 0 8.00088C1.79684 4.84733 5.1534 2.7258 8.9998 2.7258C9.71397 2.7258 10.4124 2.80094 11.0865 2.93924C8.12659 5.94504 6.01794 8.0856 2.89135 11.26Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}
                    </button>
                )}

                {(errorMessage || error) && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.inputErrorIcon}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
                            fill="currentColor"
                        />
                        <path
                            d="M15.9741 14.8831L13.091 12L15.9741 9.11691C16.2753 8.81565 16.2753 8.32721 15.9741 8.02595C15.6728 7.72468 15.1844 7.72468 14.8831 8.02595L12 10.909L9.11691 8.02595C8.81565 7.72468 8.32721 7.72468 8.02595 8.02595C7.72468 8.32721 7.72468 8.81565 8.02595 9.11691L10.909 12L8.02595 14.8831C7.72468 15.1844 7.72468 15.6728 8.02595 15.9741C8.32721 16.2753 8.81565 16.2753 9.11691 15.9741L12 13.091L14.8831 15.9741C15.1844 16.2753 15.6728 16.2753 15.9741 15.9741C16.2753 15.6728 16.2753 15.1844 15.9741 14.8831Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="0.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}

                {(successMessage || success) && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.inputSuccessIcon}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                            fill="currentColor"
                        />
                    </svg>
                )}
            </div>
        </div>
    );
};

export default InputNew;
