exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NoItems_collectionItemsNone__1KeJ2{\n    width: 100%;\n    background: rgba(229, 232, 235, 0.02);\n    border-radius: 2px;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    color: #e5e8eb;\n    font-size: 2rem;\n    padding: 2rem 1.2rem;\n    text-align: center;\n    min-height: 200px;\n    grid-gap: 8px;\n    gap: 8px;\n}\n\n@media (max-width: 480px) {\n    .NoItems_collectionItemsNone__1KeJ2{\n        font-size: 1.8rem;\n    }\n}", ""]);

// exports
exports.locals = {
	"collectionItemsNone": "NoItems_collectionItemsNone__1KeJ2"
};