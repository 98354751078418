import React from 'react';

import css from './index.module.css';

const NoItems = ({text}) => {
    return (
        <div className={css.collectionItemsNone}>
            <img src="/assets/img/items-none.svg" alt="none" />

            {text || "No items to display"}
        </div>
    )
}

export default NoItems;