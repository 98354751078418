import React, { memo, useState } from 'react';
import ModalNew from '../../../../../common/ModalNew';
import styles from '../../../index.module.css';
import cn from 'classnames';
import base from '../../../../../styles/base.module.css';
import Diamond from '../../../../../icons/Diamond';
import Card from '../../../../../icons/Card';
import { sendTariffCard } from '../../../lib/requests';
import Cash from '../../../../../icons/Cash';
import Doc from '../../../../../icons/Doc';
import Order from '../../../../../icons/Order';
import Web from '../../../../../icons/Web';
import Rocket from '../../../../../icons/Rocket';
import { CaseIcon } from '../CaseIcon';
const emailRegex = /\S+@\S+\.\S+/;
   

                    // disabled={
                    //     !emailRegex.test(userEmail) ||
                    //     !valueOrderCheckbox.offer ||
                    //     !valueOrderCheckbox.conditions
                    // }

const handlePayment = async (
    closeModal,
    setEmail,
    paymentMethod,
    selectedTariff,
    userEmail,
    checkbox,
    setIsValidate
) => {
    const { offer, conditions } = checkbox;
    if (offer && conditions && emailRegex.test(userEmail)) {
        setIsValidate(false)
        if (paymentMethod === 'card' || paymentMethod === 'default') {
            const result = await sendTariffCard(userEmail, selectedTariff.count);
            window.open(result.payment_url, '_blank');
            closeModal(false);
        }
        setEmail('');
    } else {
        setIsValidate(true)
    }
};
{
    /* <div
className={cn(styles.orderMethod, {
    [styles.active]: paymentMethod === 'card',
})}
onClick={() => setPaymentMethod('card')}
>
<div className={styles.orderMethodIconInner}>
    <Card />
</div>

<p>карта</p>
</div>
<div
className={cn(styles.orderMethod, {
    [styles.active]: paymentMethod === 'order',
})}
onClick={() => setPaymentMethod('order')}
>
<div className={styles.orderMethodIconInner}>
    <Order />
</div>

<p>квитанция</p>
</div>
<div
className={cn(styles.orderMethod, {
    [styles.active]: paymentMethod === 'cash',
})}
onClick={() => setPaymentMethod('cash')}
>
<div className={styles.orderMethodIconInner}>
    <Cash />
</div>

<p>наличные</p>
</div>
<div
className={cn(styles.orderMethod, {
    [styles.active]: paymentMethod === 'web',
})}
onClick={() => setPaymentMethod('web')}
>
<div
    className={styles.orderMethodIconInner}
    style={{
        border:
            userEmail.length === 0 ||
            !valueOrderCheckbox.conditions ||
            !valueOrderCheckbox.offer
                ? '1px solid red'
                : '1px solid #207ce2',
    }}
>
    <Web />
</div>

<p>платежная система</p>
</div> */
}

const paymentMethods = [
    {
        title: 'card',
        icon: <Card />,
        name: 'Карта',
    },
    {
        title: 'order',
        icon: <Order />,
        name: 'Квитанция',
    },
    {
        title: 'cash',
        icon: <Cash />,
        name: 'Наличные',
    },
    {
        title: 'web',
        icon: <Web />,
        name: 'Платежная система',
    },
];

const tarifIcons = {
    start: <Rocket />,
    buisness: <CaseIcon />,
    premium: <Diamond />,
};

export const PaymentModal = memo(({ state, handleSetModal, selectedTariff }) => {
    const [valueOrderCheckbox, setValueOrderCheckbox] = useState({
        offer: false,
        conditions: false,
    });
    const [paymentMethod, setPaymentMethod] = React.useState('card');
    const [userEmail, setUserEmail] = React.useState('');
    const [isValidate,setIsValidate] = useState(false)

    return (
        <ModalNew active={state} setActive={handleSetModal}>
            <p className={cn(base.title2, styles.orderTitle)}>Оформление покупки</p>

            <div className={styles.orderInfo}>
                <div className={styles.orderInfoTarif}>
                    <div className={styles.modalTarifItemIconInner}>
                        <div className={styles.tarifItemIcon}>
                            {tarifIcons[selectedTariff.type]}
                        </div>
                    </div>

                    <div className={styles.modalTarifInner}>
                        <p className={styles.modalTarifTitle}>Тариф:</p>
                        <p className={styles.modalTarifName}>{selectedTariff.name}</p>
                    </div>
                </div>

                <div className={styles.modalTarifInner}>
                    <p className={styles.modalTarifTitle}>Количество шт.:</p>
                    <p className={styles.modalTarifName}>{selectedTariff.count}</p>
                </div>

                <div className={styles.modalTarifInner}>
                    <p className={styles.modalTarifTitle}>Стоимость:</p>
                    <p className={cn(styles.modalTarifName, styles.blue)}>
                        {new Intl.NumberFormat('ru-RU').format(selectedTariff.price)} ₽
                    </p>
                </div>
            </div>

            <div className={styles.orderContent}>
                <p className={styles.orderSubtext}>Способ оплаты:</p>

                <div className={styles.orderMethods}>
                    {paymentMethods.map((method, index) => {
                        return (
                            <div
                                key={index}
                                className={cn(styles.orderMethod, {
                                    [styles.active]: paymentMethod === method.title,
                                })}
                                onClick={() => setPaymentMethod(method.title)}
                            >
                                <div
                                    className={styles.orderMethodIconInner}
                                    style={{
                                        border: isValidate ? '1px solid red' : '1px solid #207ce2',
                                    }}
                                >
                                    {method.icon}
                                </div>

                                <p>{method.name}</p>
                            </div>
                        );
                    })}
                </div>

                <p className={styles.orderSubtext}>Адрес электронной почты:</p>

                <div className={styles.orderInputInner}>
                    <input
                        type="text"
                        placeholder="Введите e-mail"
                        className={styles.orderEmail}
                        style={{
                            border: isValidate ? '1px solid red' : '1px solid #207ce2',
                        }}
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />

                    <div className={styles.orderInputCheckboxes}>
                        <div className={styles.orderInputCheckbox}>
                            <input
                                id="agree"
                                type="checkbox"
                                className={styles.orderInputCheckboxInput}
                                value={valueOrderCheckbox.offer}
                                onChange={(e) =>
                                    setValueOrderCheckbox((prev) => ({
                                        ...prev,
                                        offer: !prev.offer,
                                    }))
                                }
                            />

                            <label htmlFor="agree"  style={{ color: isValidate ? 'red' : 'white' }} className={styles.orderInputCheckboxLabel}>
                                Я принимаю условия <a href="#">Договора оферты</a>
                            </label>
                        </div>

                        <div className={styles.orderInputCheckbox}>
                            <input
                                id="agree2"
                                type="checkbox"
                                className={styles.orderInputCheckboxInput}
                                value={valueOrderCheckbox.conditions}
                                onChange={(e) =>
                                    setValueOrderCheckbox((prev) => ({
                                        ...prev,
                                        conditions: !prev.conditions,
                                    }))
                                }
                            />

                            <label
                                htmlFor="agree2"
                                style={{ color: isValidate ? 'red' : 'white' }}
                                className={`${styles.orderInputCheckboxLabel}`}
                            >
                                Я согласен на обработку моих персональных данных в соответствии с{' '}
                                <a href="#">Условиями</a>
                            </label>
                        </div>
                    </div>
                </div>

                <button
                    className={cn(base.orangeButton, styles.orderButton)}
                    onClick={() =>
                        handlePayment(
                            handleSetModal,
                            setUserEmail,
                            paymentMethod,
                            selectedTariff,
                            userEmail,
                            valueOrderCheckbox,
                            setIsValidate,
                        )
                    }
                    // disabled={
                    //     !emailRegex.test(userEmail) ||
                    //     !valueOrderCheckbox.offer ||
                    //     !valueOrderCheckbox.conditions
                    // }
                >
                    Перейти к оплате
                </button>

                <div className={styles.orderText}>
                    <div className={styles.orderTextContent}>
                        Приобретая цифровые сертификаты на нашей платформе, Вы не только получаете
                        подлинное подтверждение уникальности Ваших брендовых вещей, но и
                        инвестируете в будущее моды. Ваш выбор демонстрирует ответственность бренда
                        и заботу о покупателях. Завершите оплату и станьте частью нашего сообщества
                        токенизации - вместе мы создаем новую эру прозрачности и доверия в
                        индустрии.
                    </div>
                </div>
            </div>
        </ModalNew>
    );
});
