import React, { useCallback, useEffect, useState } from 'react';
import useDebouncedValue from '../../../hooks/useDebouncedValue';
import { useGetLevelStatsQuery, useGetPropertiesQuery } from '../../../redux/api/dataService';
import { set } from 'lodash';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

export const SORT_BY_OPTIONS = {
    DESCENDING: {
        name: 'От новых к старым',
        value: '-created_at',
    },
    ASCENDING: {
        name: 'От старых к новым',
        value: 'created_at',
    },
    PRICE_LOW_TO_HIGHT: {
        name: 'От большей цены',
        value: '-price',
    },
    PRICE_HIGHT_TO_LOW: {
        name: 'От меньшей цены',
        value: 'price',
    },
};

const DEFAULT_STATE = {
    activeStatusesFilter: [],
    activePropertiesFilter: [],
    activeLevelStatsFilter: [],
    query: '',
    sortBy: SORT_BY_OPTIONS.DESCENDING.value,
    searchQueryProperties: '',
    searchQueryLevelsStats: '',
    paid: null,
};

export const SORT_BY_OPTIONS_ARR = Object.values(SORT_BY_OPTIONS);

const FiltersContext = React.createContext({});

const FiltersContextProvider = (props) => {
    const { children } = props;

    const { data: propertiesData } = useGetPropertiesQuery();

    const { data: levelStatsData } = useGetLevelStatsQuery();

    const [state, setState] = useState(DEFAULT_STATE);

    const [properties, setProperties] = useState([]);
    const [levelStats, setLevelStats] = useState([]);

    const debouncedQuery = useDebouncedValue(state.query, 300);

    const setStatusesFilter = useCallback((status) => {
        setState((p) => ({
            ...p,
            paid: status.text === 'В продаже' ? false : true,
        }));
    }, []);

    const unsetStatusesFilter = useCallback((status) => {
        setState((p) => ({
            ...p,
            paid: false,
        }));
    }, []);

    const onChangeStatusesFilter = (status) => {
        console.log(status, 'status')
        setState((p) => ({
            ...p,
            paid: status.text === 'Все' ? null : status.text === 'В продаже' ? false : true,
        }));
    }

    const setPropertiesFilter = useCallback((value) => {
        setState((p) => ({
            ...p,
            activePropertiesFilter: [...p.activePropertiesFilter, value],
        }));
    }, []);

    const unsetPropertiesFilter = useCallback((id) => {
        setState((p) => ({
            ...p,
            activePropertiesFilter: p.activePropertiesFilter.filter(
                (propertie) => propertie.id !== id,
            ),
        }));
    }, []);

    const onChangePropertiesFilter = (value) => {
        const arrayId = state.activePropertiesFilter.length > 0 ? state.activePropertiesFilter.map((el) => el.id) : []
        if(arrayId.length && arrayId.includes(value.id)) {
            setState((p) => ({
                ...p,
                activePropertiesFilter: p.activePropertiesFilter.filter(
                  (propertie) => propertie.id !== value.id,
                ),
            }));
        } else {
            setState((p) => ({
                ...p,
                activePropertiesFilter: [...p.activePropertiesFilter, value],
            }));
        }
    }

    const setLevelStatsFilter = useCallback((value) => {
        setState((p) => ({
            ...p,
            activeLevelStatsFilter: [...p.activeLevelStatsFilter, value],
        }));
    }, []);

    const unsetLevelStatsFilter = useCallback((id) => {
        setState((p) => ({
            ...p,
            activeLevelStatsFilter: p.activeLevelStatsFilter.filter(
                (levelStats) => levelStats.id !== id,
            ),
        }));
    }, []);

    const handleSearchQueryHandler = useCallback((query) => {
        setState((p) => ({
            ...p,
            query,
        }));
    }, []);

    const handleSortByChange = useCallback((sortBy) => {
        setState((p) => ({
            ...p,
            sortBy,
            active: sortBy === p.value
        }));
    }, []);

    const changePropertiesSearchQuery = useDebouncedCallback(
        (query) => {
            if (!query) {
                setProperties(propertiesData);
            }

            setProperties((p) => p.filter((property) => property.name.includes(query)));
        },
        [propertiesData],
        200,
    );

    const changeLevelsStatsSearchQuery = useDebouncedCallback(
        (query) => {
            if (!query) {
                setProperties(levelStatsData);
            }

            setLevelStats((p) => p.filter((levelStat) => levelStat.name.includes(query)));
        },
        [levelStatsData],
        200,
    );

    const dropAllFilters = useCallback(() => {
        setState(DEFAULT_STATE);
    }, []);

    useEffect(() => {
        if (propertiesData) {
            setProperties(propertiesData);
        }
    }, [propertiesData]);

    useEffect(() => {
        if (levelStatsData) {
            setLevelStats(levelStatsData);
        }
    }, [levelStatsData]);

    const actions = {
        setStatusesFilter,
        unsetStatusesFilter,
        handleSearchQueryHandler,
        handleSortByChange,

        setPropertiesFilter,
        unsetPropertiesFilter,
        changePropertiesSearchQuery,
        onChangePropertiesFilter,
        onChangeStatusesFilter,

        setLevelStatsFilter,
        unsetLevelStatsFilter,
        changeLevelsStatsSearchQuery,

        dropAllFilters,
    };

    return (
        <FiltersContext.Provider
            value={{
                actions,
                state,
                properties,
                levelStats,
                debouncedQuery,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};

export { FiltersContext, FiltersContextProvider };
