import React from 'react';

const Download2 = ({ ...props }) => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.5163 7.875H13.125V3.5C13.125 3.01875 12.7312 2.625 12.25 2.625H8.75C8.26875 2.625 7.875 3.01875 7.875 3.5V7.875H6.48375C5.705 7.875 5.31125 8.82 5.8625 9.37125L9.87875 13.3875C10.22 13.7288 10.7712 13.7288 11.1125 13.3875L15.1288 9.37125C15.68 8.82 15.295 7.875 14.5163 7.875ZM4.375 16.625C4.375 17.1062 4.76875 17.5 5.25 17.5H15.75C16.2312 17.5 16.625 17.1062 16.625 16.625C16.625 16.1438 16.2312 15.75 15.75 15.75H5.25C4.76875 15.75 4.375 16.1438 4.375 16.625Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Download2;
