import { useRef, useState, useEffect } from 'react';
import { generateNumArrInRange } from '../utils/generateNumArrInRange';
import useDebouncedValue from './useDebouncedValue';

const SCROLL_TOP_DEBOUNCE_DELAY_IN_MS = 200;

export default function useVirtualizedList(props) {
    const {
        itemsCount = 0,
        itemHeight = 0,
        itemsCountIntoView = 3,
        scrollContainerElement,
        topIndent = 0,
    } = props;

    const containerElem = useRef(null);

    const [scrollTop, setScrollTop] = useState(0);
    const [itemsIndexesToRender, setItemsIndexesToRender] = useState(
        itemsCountIntoView > 0 ? generateNumArrInRange(0, itemsCountIntoView) : [],
    );

    const debouncedScrollTop = useDebouncedValue(scrollTop, SCROLL_TOP_DEBOUNCE_DELAY_IN_MS);

    useEffect(() => {
        containerElem.current = scrollContainerElement || document.getElementById('root');
    }, [scrollContainerElement]);

    useEffect(() => {
        const scrollHandler = () => {
            if (!containerElem.current) {
                return;
            }
            const scrollTop = containerElem.current.scrollTop || containerElem.current.pageYOffset;

            setScrollTop((p) => {
                if (Math.abs(p - scrollTop - topIndent) > Math.floor(itemHeight)) {
                    return scrollTop;
                }
                return p;
            });
        };

        containerElem.current.addEventListener('scroll', scrollHandler);

        return () => {
            containerElem.current.removeEventListener('scroll', scrollHandler);
        };
    }, [topIndent]);

    useEffect(() => {
        const scrollTop = debouncedScrollTop - topIndent < 0 ? 0 : debouncedScrollTop - topIndent;
        const firstIndexIntoView = Math.floor(scrollTop / itemHeight);

        const startIndex = firstIndexIntoView - 5 > 0 ? firstIndexIntoView - 5 : 0;

        if (containerElem.current.outerHeight > itemsCountIntoView * itemHeight) {
            const updatedItemsCount = Math.floor(containerElem.current.outerHeight / itemHeight);
            const endIndex = startIndex + updatedItemsCount + 4;

            setItemsIndexesToRender(
                generateNumArrInRange(startIndex, Math.min(endIndex, itemsCount)),
            );
        } else {
            const endIndex = startIndex + itemsCountIntoView + 4;

            setItemsIndexesToRender(
                generateNumArrInRange(startIndex, Math.min(endIndex, itemsCount)),
            );
        }
    }, [debouncedScrollTop, topIndent, itemsCount, itemHeight]);

    return {
        itemsIndexesToRender,
    };
}
