import Modal from '../../../../common/Modal';
import s from '../TokenActionsModal/index.module.css';
import styles from '../../../TokenSuccessBuy/tokenBuy.module.css';
import React from 'react';

// мод. окно после успешного вывода или возврата токена
export const SuccessConclusionModal = ({isOpen, setIsOpen, token, collection}) => {
    return (
        <Modal
            active={isOpen}
            title={'Вывод денежных средств'}
            setActive={setIsOpen}
            size={'medium'}
            style={{
                maxWidth: '560px'
            }}
        >
            <div
                className={s.box}
            >
                {token && token.file_1 && <img src={token.file_1} className={styles.img} alt={'token image'}/>}
                <div className={s.block}>
                    <a
                        className={s.link}
                        href={`/collection/${token.collection.id}`}
                    >
                        {token && token.collection && token.collection.name}
                    </a>
                    <p>{token && token.name}</p>
                    <p>{token && token.investor_royalty ? `Доходность: ${token.investor_royalty}%/день` : ''}</p>
                </div>

            </div>
            <p
                style={{
                    marginTop: '60px'
                }}
            >
                Менеджер свяжется с вами в течении минуты
            </p>

            <button
                className={s.withdrawalBtn }
                style={{
                    background: 'hsla(210, 78%, 40%, 1)',
                    marginTop: '50px'
                }}
                onClick={() => setIsOpen(false)}
            >
                Благодарим вас
            </button>
        </Modal>
    )
}
