// const checkboxDataHelperByType = (checkbox, param) => {
//     const variants = {
//         status_store: {
//             ['Продано']: 'sold',
//             ['В продаже']: 'posted',
//             ['В передаче']: 'transfer',
//         },
//     };
//     return { ...param, value: checkbox.state ? '' : variants[checkbox.title][checkbox.name] };
// };

export const PAGE_FILTERS = {
    // collections: [
    //     {
    //         title: 'Состояние',
    //         value: '5',
    //         elements: [
    //             {
    //                 name: 'В продаже',
    //                 state: true,
    //                 title: 'paid',
    //             },
    //             {
    //                 name: 'Продано',
    //                 state: false,
    //                 title: 'paid',
    //             },
    //         ],
    //     },
    // ],
    // packs: [
    //     {
    //         title: 'Состояние',
    //         value: '5',
    //         elements: [
    //             {
    //                 name: 'В продаже',
    //                 active: true,
    //                 title: 'paid',
    //             },
    //             {
    //                 name: 'Продано',
    //                 active: false,
    //                 title: 'paid',
    //             },
    //         ],
    //     },
    // ],
    tokens: [
        {
            title: 'Состояние',
            value: '5',
            type: 'status_store',
            elements: [
                {
                    name: 'В продаже',
                    state: false,
                    title: 'status_store',
                },
                {
                    name: 'Продано',
                    state: false,
                    title: 'status_store',
                },
                {
                    name: 'В передаче',
                    state: false,
                    title: 'status_store',
                },
            ],
        },
    ],
};

const variants = {
    // status_store: {
    ['Продано']: 'sold',
    ['В продаже']: 'posted',
    ['В передаче']: 'transfer',
    // },
};

export const handleSelectFilterOption = (
    checkbox,
    setRequestParams,
    setPageFilters,
    setIsResetList,
) => {
    setRequestParams((prev) =>
        prev.map((param) =>
            param.title === checkbox.title
                ? { ...param, value: !checkbox.state ? variants[checkbox.name] : null }
                : param,
        ),
    );

    setPageFilters((prev) =>
        prev.map((filter) =>
            filter.type === checkbox.title
                ? {
                      ...filter,
                      elements: filter.elements.map((variant) =>
                          variant.name === checkbox.name
                              ? { ...variant, state: !checkbox.state }
                              : { ...variant, state: false },
                      ),
                  }
                : filter,
        ),
    );

    setIsResetList(true);
};

export const handleSelectTableMode = (checkbox, navigate, dispatch, setParentsList) => {
    if (checkbox) {
        const { state, title } = checkbox;
        if (state) {
            navigate(`../${title}?all=true`);
        }
        if (!state) {
            dispatch(setParentsList([]));
            navigate(`../`);
        }
    }
};

export const handleSearchValueBykey = (e, search, setRequestParams, setIsResetList) => {
    if (e.type === 'click' || e.code === 'Enter') {
        setRequestParams((prev) => {
            return prev.map((param) => {
                if (param.title === 'q') {
                    return { ...param, value: search };
                }
                return param;
            });
        });
        setIsResetList(true);
    }
};
