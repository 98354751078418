import React,{useState} from 'react';
import cn from 'classnames';
import styles from '../../index.module.css';
import ModalNew from '../../../../common/ModalNew';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import { InputNew } from '../../../../common/InputNew';
import base from '../../../../styles/base.module.css';
export const EditInfoModal = () => {
    const [editProfileModal, setEditProfileModal] = useState(true);
    return (
        <ModalNew
            active={editProfileModal}
            setActive={setEditProfileModal}
            title="Редактирование профиля"
        >
            <div className={styles.editProfileForm}>
                <div className={styles.editProfileFormImgInner}>
                    <UserAvatar
                        avatar="/assets/img/avatar2.png"
                        className={styles.editProfileFormImg}
                        style="silver"
                    />

                    <div className={styles.editProfileUploadInner}>
                        <p className={styles.editProfileUploadTitle}>Фото</p>

                        <input
                            type="file"
                            id="profileAvatar"
                            className={styles.editProfileUploadInput}
                        />

                        <label
                            htmlFor="profileAvatar"
                            className={styles.editProfileUploadInputLabel}
                        >
                            Загрузите ваше фото
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14.5C14.7652 1 15.0196 1.10536 15.2071 1.29289L20.7071 6.79289C20.8946 6.98043 21 7.23478 21 7.5V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H4C3.44772 23 3 22.5523 3 22C3 21.4477 3.44772 21 4 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V7.91421L14.0858 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8V4C3 3.20435 3.31607 2.44129 3.87868 1.87868Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14 1C14.5523 1 15 1.44772 15 2V7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H14C13.4477 9 13 8.55228 13 8V2C13 1.44772 13.4477 1 14 1Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2 15C2 14.4477 2.44772 14 3 14H9C9.55228 14 10 14.4477 10 15C10 15.5523 9.55228 16 9 16H3C2.44772 16 2 15.5523 2 15Z"
                                    fill="#2072E2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6 11C6.55228 11 7 11.4477 7 12V18C7 18.5523 6.55228 19 6 19C5.44772 19 5 18.5523 5 18V12C5 11.4477 5.44772 11 6 11Z"
                                    fill="#2072E2"
                                />
                            </svg>
                        </label>
                    </div>
                </div>

                <div className={styles.editProfileFormWrapper}>
                    <InputNew
                        title="Псевдоним*"
                        placeholder="Введите псевдоним"
                        error
                        errorMessage="Ник занят"
                    />
                    <InputNew title="Имя Фамилия" placeholder="Введите имя и фамилию" />
                    <InputNew title="Эл.адрес*" error placeholder="Введите ваш email" />
                    <InputNew title="Номер телефона*" placeholder="Введите ваш номер телефона" />
                    <InputNew
                        title="WhatsApp"
                        withHideButton
                        placeholder="Укажите аккаунт WhatsApp"
                    />
                    <InputNew
                        title="Телеграм"
                        withHideButton
                        placeholder="Укажите аккаунт Телеграм"
                    />
                    <InputNew
                        title="Инстаграм"
                        withHideButton
                        placeholder="Укажите аккаунт Инстаграм"
                    />

                    <p className={styles.inputRequired}>*Обязательное поле</p>
                </div>

                <button className={cn(base.orangeButton, styles.editProfileFormButton)}>
                    Сохранить
                </button>
            </div>
        </ModalNew>
    );
};
