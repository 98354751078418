import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentsList: [],
};

const tableFactorySlice = createSlice({
    name: 'tableSlice',
    initialState: initialState,
    reducers: {
        setParentsList: (state, action) => {
            state.parentsList = action.payload
        },
    },
});

export const { setParentsList } = tableFactorySlice.actions;

export const tableReducer = tableFactorySlice.reducer;
