exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CollectionInfo_collectionContentInfo__2Do7- {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.CollectionInfo_collectionItemsValue__28Z33 {\n    font-size: 1.5rem;\n    font-weight: 400;\n    color: #e5e8eb;\n}\n\n@media (max-width: 480px) {\n    .CollectionInfo_collectionItemsValue__28Z33 {\n        font-size: 1.5rem;\n    }\n}", ""]);

// exports
exports.locals = {
	"collectionContentInfo": "CollectionInfo_collectionContentInfo__2Do7-",
	"collectionItemsValue": "CollectionInfo_collectionItemsValue__28Z33"
};