import React, { useCallback, useContext, useMemo } from 'react';
import { IconButton } from '@mui/material';
import { WHITE_LIST_APPLICATION_STATUSES } from '../../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import { WhitelistActionsContext } from '../lib/WhitelistActionsContext';
import CheckIcon from '../../../icons/CheckIcon';
import TrashIcon from '../../../icons/TrashIcon';
import RecoverIcon from '../../../icons/RecoverIcon';
import TrashWithArrow from '../../../icons/TrashWithArrow';

import css from '../WhiteList.module.css';

const ActionComponent = (props) => {
    const { id, isHidden, isBooked, status } = props;

    const {
        actions: {
            onDeleteHandler: onDelete,
            onCancelBookedApplicationHandler: onCancelBookedApplicationHandler,
            onConfirmHandler: onConfirm,
            onRejectHandler: onReject,
            onRecoverHandler: onRecover,
        },
    } = useContext(WhitelistActionsContext);

    const onConfirmUserHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onConfirm(id);
        },
        [onConfirm, id],
    );

    const onRejectUserHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onReject(id);
        },
        [onReject, id],
    );

    const onRecoverUserHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onRecover(id);
        },
        [onRecover, id],
    );

    const onDeleteUserHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete(id);
        },
        [onDelete, id],
    );

    const onCancelHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onCancelBookedApplicationHandler(id);
        },
        [onCancelBookedApplicationHandler, id],
    );

    const action = useMemo(() => {
        if (isHidden) {
            return <span style={{ color: '#fff' }}>---</span>;
        }

        if (isBooked) {
            return (
                <button
                    onClick={onCancelHandler}
                    className={`button ${css.cancelBookApplicationBtn}`}
                >
                    Cancel
                </button>
            );
        }

        switch (status) {
            case WHITE_LIST_APPLICATION_STATUSES.NEW:
                return (
                    <>
                        <IconButton
                            disableRipple
                            sx={{ padding: 0 }}
                            onClick={onConfirmUserHandler}
                        >
                            <div className={css.confirmIconBox}>
                                <CheckIcon className={css.confirmIcon} />
                            </div>
                        </IconButton>
                        <IconButton disableRipple sx={{ padding: 0 }} onClick={onRejectUserHandler}>
                            <div className={css.rejectIconBox}>
                                <TrashIcon className={css.rejectIcon} />
                            </div>
                        </IconButton>
                    </>
                );
            case WHITE_LIST_APPLICATION_STATUSES.RED:
                return (
                    <>
                        <IconButton
                            disableRipple
                            sx={{ padding: 0 }}
                            onClick={onRecoverUserHandler}
                        >
                            <div className={css.recoverIconBox}>
                                <RecoverIcon className={css.confirmIcon} />
                            </div>
                        </IconButton>
                        <IconButton disableRipple sx={{ padding: 0 }} onClick={onDeleteUserHandler}>
                            <div className={css.rejectIconBox}>
                                <TrashWithArrow className={css.rejectIcon} />
                            </div>
                        </IconButton>
                    </>
                );
            case WHITE_LIST_APPLICATION_STATUSES.WHITE:
                return (
                    <IconButton disableRipple sx={{ padding: 0 }} onClick={onRejectUserHandler}>
                        <div className={css.rejectIconBox}>
                            <TrashIcon className={css.rejectIcon} />
                        </div>
                    </IconButton>
                );
            default:
                return <span style={{ color: '#fff' }}>---</span>;
        }
    }, [status, isBooked, isHidden]);

    return <div className={css.buttonsCell}>{action}</div>;
};

export default React.memo(ActionComponent);
