import React from 'react';

const Download = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.50033 11.668C2.96056 11.668 3.33366 12.0411 3.33366 12.5013V15.8346C3.33366 16.0556 3.42146 16.2676 3.57774 16.4239C3.73402 16.5802 3.94598 16.668 4.16699 16.668H15.8337C16.0547 16.668 16.2666 16.5802 16.4229 16.4239C16.5792 16.2676 16.667 16.0556 16.667 15.8346V12.5013C16.667 12.0411 17.0401 11.668 17.5003 11.668C17.9606 11.668 18.3337 12.0411 18.3337 12.5013V15.8346C18.3337 16.4977 18.0703 17.1336 17.6014 17.6024C17.1326 18.0712 16.4967 18.3346 15.8337 18.3346H4.16699C3.50395 18.3346 2.86807 18.0712 2.39923 17.6024C1.93038 17.1336 1.66699 16.4977 1.66699 15.8346V12.5013C1.66699 12.0411 2.04009 11.668 2.50033 11.668Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0003 1.66797C10.4606 1.66797 10.8337 2.04106 10.8337 2.5013V12.5013C10.8337 12.9615 10.4606 13.3346 10.0003 13.3346C9.54009 13.3346 9.16699 12.9615 9.16699 12.5013V2.5013C9.16699 2.04106 9.54009 1.66797 10.0003 1.66797Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Download;
