import React from 'react';

export const Minus = ({ ...props }) => {
    return (
        <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 1H9" stroke="currentColor" strokeLinecap="round" />
        </svg>
    );
};
