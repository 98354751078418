import React from 'react';

const Check3 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="36" cy="36" r="34.75" stroke="currentColor" strokeWidth="2.5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.761 26.9554C48.3802 27.5736 48.3807 28.5762 47.7621 29.195L31.913 45.0467C31.6159 45.3439 31.2126 45.511 30.7922 45.5111C30.3717 45.5112 29.9684 45.3443 29.6711 45.0472L24.9164 40.2965C24.2974 39.678 24.2974 38.6754 24.9164 38.0569C25.5353 37.4385 26.5388 37.4385 27.1578 38.0569L30.7912 41.6874L45.5196 26.9566C46.1382 26.3378 47.1417 26.3373 47.761 26.9554Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Check3;
