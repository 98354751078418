import React from 'react';

import styles from './index.module.css';

const Tooltip = ({ text, children }) => {
    return (
        <div className={styles.tooltipInner}>
            {children}

            <div className={styles.tooltipContentWrapper}>
                <div className={styles.tooltipContent}>{text}</div>
            </div>
        </div>
    );
};

export default Tooltip;
