import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    whitelists: [],
    selectedCollections: [],
    statuses: [],
    createdGreateThenInH: 'all',
    query: '',
    page: 1,
    rowsPerPage: 20,
    totalCount: 0,
    isLoading: true,
};

export const whitelistsSlice = createSlice({
    name: 'whitelists',
    initialState: INITIAL_STATE,
    reducers: {
        setWhitelists: (state, action) => {
            state.whitelists = action.payload;
        },
        addWhitelists: (state, action) => {
            state.whitelists.push(...action.payload);
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        increasePage: (state) => {
            state.page += 1;
        },
        resetWhiteList: (state) => {
            state.whitelists = [];
            state.page = 1;
            state.totalCount = 0;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload;
        },
        updateteWhitelistItem: (state, action) => {
            const { id, data } = action.payload;

            const index = state.whitelists.findIndex((item) => item.id === id);

            if (index !== -1) {
                state.whitelists[index] = { ...state.whitelists[index], ...data };
            }
        },
        addCollection: (state, action) => {
            state.selectedCollections.push(action.payload);
        },
        removeCollection: (state, action) => {
            state.selectedCollections = state.selectedCollections.filter(
                (el) => el.id !== action.payload,
            );
        },
        addStatus: (state, action) => {
            state.statuses.push(action.payload);
        },
        removeStatus: (state, action) => {
            state.statuses = state.statuses.filter((status) => status.id !== action.payload);
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },

        dropFilters: (state) => {
            state.selectedCollections = [];
            state.statuses = [];
            state.page = 1;
        },

        setCreatedGreateThenInH: (state, action) => {
            state.createdGreateThenInH = action.payload;
        },
    },
});

export const {
    setWhitelists,
    addWhitelists,
    setPage,
    increasePage,
    setIsLoading,
    setTotalCount,
    updateteWhitelistItem,
    resetWhiteList,

    addCollection,
    removeCollection,
    addStatus,
    removeStatus,
    setQuery,
    setCreatedGreateThenInH,
    dropFilters,
} = whitelistsSlice.actions;

export default whitelistsSlice.reducer;
