import React from 'react';

import styles from './index.module.css';

import Check5 from '../../icons/Check5';

const FilterItem = ({ title, count, id }) => {
    return (
        <div className={styles.collectionFilterValueItem}>
            <input type="checkbox" id={id} className={styles.collectionFilterValueItemCheckbox} />

            <label htmlFor={id} className={styles.collectionFilterValueItemLabel}>
                <span className={styles.collectionFilterValueItemText}>{title}</span>

                <span className={styles.collectionFilterValueItemWrapper}>
                    <span className={styles.collectionFilterValueItemValue}>{count}</span>

                    <span className={styles.collectionFilterValueItemSquare}>
                        <Check5 />
                    </span>
                </span>
            </label>
        </div>
    );
};

export default FilterItem;
