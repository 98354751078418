import React from 'react';

import FilterTable from '../../../components/FilterTable';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS } from '../../../const/breakpoints/BREAKPOINTS';
import { Link } from 'react-router-dom';

const sortList = [{ value: 'recent', name: 'Recent changes' }];

const sortList2 = [{ value: '90', name: 'Last 90 Days' }];

const Tokens = () => {
    const [filter, setFilter] = React.useState('recent');
    const [filter2, setFilter2] = React.useState('90');
    const [filtersContent, setFiltersContent] = React.useState([]);
    const [filterData, setFilterData] = React.useState([
        {
            name: 'Listed',
            active: false,
        },
        {
            name: 'Booked',
            active: false,
        },
        {
            name: 'Minted',
            active: false,
        },
        {
            name: 'Transfer',
            active: false,
        },
        {
            name: 'Sold',
            active: false,
        },
        {
            name: 'Booked (Sold)',
            active: false,
        },
        {
            name: 'No price',
            active: false,
        }
    ]);
    const [filterData2, setFilterData2] = React.useState([
        {
            name: 'Brand name',
            brand: '/assets/img/person9.png',
            active: true,
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person8.png',
            active: true,
        },
        {
            name: 'Brand name',
            brand: '/assets/img/person7.png',
            active: true,
        },
    ]);
    const [filterData3, setFilterData3] = React.useState([
        {
            name: 'Collection name',
            brand: '/assets/img/person1.png',
        },
        {
            name: 'Collection name',
            brand: '/assets/img/person2.png',
        },
        {
            name: 'Collection name',
            brand: '/assets/img/person3.png',
        },
    ]);
    const [filterData4, setFilterData4] = React.useState([
        {
            name: 'Attribute 1'
        },
        {
            name: 'Attribute 2'
        },
        {
            name: 'Attribute 3'
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    const filterChange2 = (value) => {
        setFilter2(value);
    };

    return (
        <div className="admins__inner">
            <h2 className="title">
                <Link to="../packs">
                    <img src="/assets/img/back-arrow.svg" alt="" />
                </Link>
                Delivery - Brand name - Collection name - Pack name - Tokens
            </h2>

            <FilterTable
                views
                placeholderSearchInput="Search by token or collection name"
                sorts={[
                    {
                        optionsList: sortList2,
                        value: filter2,
                        placeholder: 'Select filter',
                        onChange: filterChange2,
                    },
                    {
                        optionsList: sortList,
                        value: filter,
                        placeholder: 'Select filter',
                        onChange: filterChange,
                    },
                ]}
                filters={[
                    {
                        title: "Status",
                        value: "2",
                        elements: filterData,
                        setData: setFilterData,
                        filters: filtersContent,
                        setFilters: setFiltersContent
                    },
                    {
                        title: "Brands",
                        value: "3",
                        elements: filterData2,
                        setData: setFilterData2,
                        filters: filtersContent,
                        filter: true,
                        setFilters: setFiltersContent
                    },
                    {
                        title: "Collections",
                        value: "4",
                        elements: filterData3,
                        setData: setFilterData3,
                        filters: filtersContent,
                        filter: true,
                        setFilters: setFiltersContent
                    },
                    {
                        title: "Attributes",
                        value: "5",
                        elements: filterData4,
                        setData: setFilterData4,
                        filters: filtersContent,
                        filter: true,
                        setFilters: setFiltersContent
                    }
                ]}
                tokensCount="316 items"
            >
                <TableRow names differentColumn>
                    <TableCell title>
                        Status
                    </TableCell>

                    <TableCell title largeColumn>
                        Token
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                        Description
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1600}>
                        Properties
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                        Address
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                        Owner
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                        Date
                    </TableCell>

                    <TableCell title textCenter breakpoint={BREAKPOINTS.MD620}>
                        In work
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="deliveryRequest">
                    <TableCell>
                        <p className="whithdraw__index">
                            1
                        </p>

                        <div className="stats__item--status--circle blue-white"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--white">To send</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="deliveryRequest">
                    <TableCell>
                        <p className="whithdraw__index">
                            2
                        </p>

                        <div className="stats__item--status--circle blue-green"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--green">Request</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="delivery">
                    <TableCell>
                        <p className="whithdraw__index">
                            3
                        </p>

                        <div className="stats__item--status--circle blue-lightblue"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--lightblue">Delivery</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="deliveryShipped">
                    <TableCell>
                        <p className="whithdraw__index">
                            4
                        </p>

                        <div className="stats__item--status--circle blue-yellow"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--wait">Shipped</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="deliveryHanded">
                    <TableCell>
                        <p className="whithdraw__index">
                            5
                        </p>

                        <div className="stats__item--status--circle blue-pink"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--pink">Handed</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>

                <TableRow differentColumn actionType="deliveryTroubles">
                    <TableCell>
                        <p className="whithdraw__index">
                            1
                        </p>

                        <div className="stats__item--status--circle blue-red"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <p className="withdraw__item--collection--name stats__item--text--stop">Troubles</p>
                        </div>
                    </TableCell>

                    <TableCell largeColumn>
                        <div className="stats__item--avatar--inner withdraw__item"></div>

                        <div className="stats__item--wrapper withdraw__item">
                            <Link to="../collections" className="withdraw__item--collection--name">
                                Collection name
                                <br />
                                <b>Token name</b>
                            </Link>
                        </div>
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Description description... Description description. Description description... Description description... Descripti .. Descripti
                    </TableCell>

                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1600}>
                        Character/Describe Male;
                        Character/Describe Male; 
                        Character/Describe Male;
                    </TableCell>
                    
                    <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                        Country name name nam
                        City name name name na
                        Address name name nam
                    </TableCell>

                    <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                        <Link to="/">User name</Link>
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD1200}>
                        5 days ago
                    </TableCell>

                    <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                        <div className="stats__item--status--circle"></div>
                    </TableCell>
                </TableRow>
                
            </FilterTable>
        </div>
    )
}

export default Tokens