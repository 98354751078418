import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_AUTH_API_URL } from '../../const/http/API_URLS';
import { HTTP_METHODS } from '../../const/http/HTTP_METHODS';
import { generateQuery } from '../../utils/http/generateQuery';

export const billingApi = createApi({
    reducerPath: 'billingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/billing/`,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const {
                auth: { accessToken, refreshToken },
            } = getState();

            if (endpoint !== 'refreshToken' && accessToken) {
                headers.set('Authorization', `Bearer ${accessToken}`);
            }

            if (endpoint === 'refreshToken' && refreshToken) {
                headers.set('Authorization', `Bearer ${refreshToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        // GET
        getActiveTokenBill: builder.query({
            query: ({token_id}) => ({
                url: `user/balance_token/${token_id}`,
            }),
        }),
        // Создать транзакцию на вывод средств по счету сертификата.
        withdrawalOfFunds: builder.mutation({
            query: ({body}) => ({
                url: `user/transaction/new/output`,
                method: 'POST',
                body: body
            }),
        }),
        // Создать транзакцию на возврат средств по счету сертификата.
        refundMoney: builder.mutation({
            query: ({body}) => ({
                url: `user/transaction/new/refund`,
                method: 'POST',
                body: body
            }),
        }),
        // Получить общий баланс пользователя
        getTotalBalance:  builder.query({
            query: () => ({
                url: `user/balance_token/my/total`,
            }),
        }),
        // Получение списка транзакций
        getTransactionList:  builder.query({
            query: ({page, pageSize, type, status, collection_id, search}) => ({
                url: `admin/transaction${generateQuery({ page, page_size: pageSize, collection_id})}${type && `&type=${type}`}${status && `&status=${status}`}${search && `&search=${search}`}&sort_by=date`,
            }),
        }),
        // Перевод транзакции в работу
        transformTransactionInWork:  builder.mutation({
            query: ({id}) => ({
                url: `admin/transaction/${id}/in_work`,
                method: 'PUT'
            }),
        }),
        // Подтверждение транзакции
        confirmTransaction:  builder.mutation({
            query: ({id}) => ({
                url: `admin/transaction/${id}/confirm`,
                method: 'PUT'
            }),
        }),
        // Отмена транзакции
        cancelTransaction:  builder.mutation({
            query: ({id}) => ({
                url: `admin/transaction/${id}/cancel`,
                method: 'PUT'
            }),
        }),
    }),
});

export const {
    useGetActiveTokenBillQuery,
    useWithdrawalOfFundsMutation,
    useRefundMoneyMutation,
    useGetTotalBalanceQuery,
    useGetTransactionListQuery,
    useLazyGetTransactionListQuery,
    useTransformTransactionInWorkMutation,
    useConfirmTransactionMutation,
    useCancelTransactionMutation,
} = billingApi;
