import React from 'react';

const Watch = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M17.4781 15.5L15.4999 22H8.4999L6.52175 15.5M17.4781 15.5C18.1249 14.4897 18.5 13.2887 18.5 12C18.5 10.7113 18.1249 9.5103 17.4781 8.5M17.4781 15.5C16.323 17.3041 14.3012 18.5 12 18.5C9.6988 18.5 7.67685 17.3041 6.52175 15.5M6.52175 15.5C5.87495 14.4897 5.5 13.2887 5.5 12C5.5 10.7113 5.87495 9.5103 6.52175 8.5M6.52175 8.5L8.4999 2H15.4999L17.4781 8.5M6.52175 8.5C7.67685 6.69585 9.6988 5.5 12 5.5C14.3012 5.5 16.323 6.69585 17.4781 8.5M12 8.5V12L14 14"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default Watch;
