import React from 'react';
import ActionMenu from '../../components/ActionMenu';

const statuses = {
    "Transfer": "transfer",
    "Static": "static"
}

const WhitelistItem = ({data, id}) => {
    const { status, avatar, user, moderator, description, access, last, action = false } = data;

    const [actionMenu, setActionMenu] = React.useState(false);

    React.useEffect(() => {
        const clickHandler = (e) => {
            const div = document.getElementById(`actionMenu_${id}`);
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setActionMenu(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
    }, []);

    return(
        <div className="stats__item--values">
            <div className="stats__item--item stats__item--status whitelist__item--wrap off700">
                <p className="stats__item--value">{id}</p>

                <div className={`admins__item--circle ${statuses[status]}`}></div>

                <p className="stats__item--status--value whithdraw__status">{status}</p>
            </div>

            <div className="stats__item--item whitelist__item--wrap">
                <p className="whithdraw__index--mobile">
                    {id}
                </p>

                <div className="stats__item--avatar--inner withdraw__item">
                    {!avatar && <img
                        src={avatar}
                        alt="avatar"
                        className="stats__item--avatar"
                    />}
                </div>

                <div className="stats__item--wrapper withdraw__item">
                    <p className="withdraw__item--collection--name">{user.name}</p>
                </div>
            </div>

            <div className="stats__item--item whitelist__item--wrap whithdraw__flex">
                <p className="opportunity__access link off700">{moderator}</p>

                <p className={`admins__status--mobile mt0 ${statuses[status]}`}>
                    {status}
                </p>
            </div>

            <div className="stats__item--item whitelist__item--wrap off998 activeoff1600">
                <p className="opportunity__desc">{description}</p>
            </div>

            <div className="withdraw__item--text whitelist__item--wrap off1200 activeoff1600">
                <p className="opportunity__access link">{access}</p>
            </div>

            <div className="stats__item--item withdraw__item--text whitelist__item--wrap off1200 activeoff1600">
                {last}
            </div>

            <div className="stats__item--item stats__item--value whitelist__item--wrap">
                <ActionMenu actions={[
                    {
                        text: "Edit",
                        action: () => {}
                    },
                    {
                        text: "Delete",
                        action: () => {}
                    },
                    {
                        text: "Copy Wallet",
                        action: () => {}
                    },
                    {
                        text: "Select",
                        action: () => {}
                    }
                ]} id={id} />
            </div>
        </div>
    )
}

export default WhitelistItem;