import React from 'react';

const ViewSmall = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.406 4.21484H0.266368C0.118708 4.21484 0 4.33215 0 4.47808V6.59254C0 6.73846 0.118708 6.85577 0.266368 6.85577H2.406C2.55366 6.85577 2.67237 6.73846 2.67237 6.59254V4.47808C2.67526 4.33502 2.55366 4.21484 2.406 4.21484Z"
                fill="currentColor"
            />
            <path
                d="M6.41772 4.21484H4.27809C4.13043 4.21484 4.01172 4.33215 4.01172 4.47808V6.59254C4.01172 6.73846 4.13043 6.85577 4.27809 6.85577H6.41772C6.56538 6.85577 6.68409 6.73846 6.68409 6.59254V4.47808C6.68409 4.33502 6.56538 4.21484 6.41772 4.21484Z"
                fill="currentColor"
            />
            <path
                d="M10.4294 4.21484H8.28981C8.14214 4.21484 8.02344 4.33215 8.02344 4.47808V6.59254C8.02344 6.73846 8.14214 6.85577 8.28981 6.85577H10.4294C10.5771 6.85577 10.6958 6.73846 10.6958 6.59254V4.47808C10.6987 4.33502 10.5771 4.21484 10.4294 4.21484Z"
                fill="currentColor"
            />
            <path
                d="M14.4412 4.21484H12.3015C12.1539 4.21484 12.0352 4.33215 12.0352 4.47808V6.59254C12.0352 6.73846 12.1539 6.85577 12.3015 6.85577H14.4412C14.5888 6.85577 14.7075 6.73846 14.7075 6.59254V4.47808C14.7075 4.33502 14.5888 4.21484 14.4412 4.21484Z"
                fill="currentColor"
            />
            <path
                d="M2.406 0.253906H0.266368C0.118708 0.253906 0 0.371217 0 0.517141V2.6316C0 2.77752 0.118708 2.89484 0.266368 2.89484H2.406C2.55366 2.89484 2.67237 2.77752 2.67237 2.6316V0.517141C2.67526 0.371217 2.55366 0.253906 2.406 0.253906Z"
                fill="currentColor"
            />
            <path
                d="M6.41772 0.253906H4.27809C4.13043 0.253906 4.01172 0.371217 4.01172 0.517141V2.6316C4.01172 2.77752 4.13043 2.89484 4.27809 2.89484H6.41772C6.56538 2.89484 6.68409 2.77752 6.68409 2.6316V0.517141C6.68409 0.371217 6.56538 0.253906 6.41772 0.253906Z"
                fill="currentColor"
            />
            <path
                d="M10.4294 0.253906H8.28981C8.14214 0.253906 8.02344 0.371217 8.02344 0.517141V2.6316C8.02344 2.77752 8.14214 2.89484 8.28981 2.89484H10.4294C10.5771 2.89484 10.6958 2.77752 10.6958 2.6316V0.517141C10.6987 0.371217 10.5771 0.253906 10.4294 0.253906Z"
                fill="currentColor"
            />
            <path
                d="M14.4412 0.253906H12.3015C12.1539 0.253906 12.0352 0.371217 12.0352 0.517141V2.6316C12.0352 2.77752 12.1539 2.89484 12.3015 2.89484H14.4412C14.5888 2.89484 14.7075 2.77752 14.7075 2.6316V0.517141C14.7075 0.371217 14.5888 0.253906 14.4412 0.253906Z"
                fill="currentColor"
            />
            <path
                d="M2.406 8.18359H0.266368C0.118708 8.18359 0 8.3009 0 8.44683V10.5613C0 10.7072 0.118708 10.8245 0.266368 10.8245H2.406C2.55366 10.8245 2.67237 10.7072 2.67237 10.5613V8.44683C2.67526 8.3009 2.55366 8.18359 2.406 8.18359Z"
                fill="currentColor"
            />
            <path
                d="M6.41772 8.18359H4.27809C4.13043 8.18359 4.01172 8.3009 4.01172 8.44683V10.5613C4.01172 10.7072 4.13043 10.8245 4.27809 10.8245H6.41772C6.56538 10.8245 6.68409 10.7072 6.68409 10.5613V8.44683C6.68409 8.3009 6.56538 8.18359 6.41772 8.18359Z"
                fill="currentColor"
            />
            <path
                d="M10.4294 8.18359H8.28981C8.14214 8.18359 8.02344 8.3009 8.02344 8.44683V10.5613C8.02344 10.7072 8.14214 10.8245 8.28981 10.8245H10.4294C10.5771 10.8245 10.6958 10.7072 10.6958 10.5613V8.44683C10.6987 8.3009 10.5771 8.18359 10.4294 8.18359Z"
                fill="currentColor"
            />
            <path
                d="M14.4412 8.18359H12.3015C12.1539 8.18359 12.0352 8.3009 12.0352 8.44683V10.5613C12.0352 10.7072 12.1539 10.8245 12.3015 10.8245H14.4412C14.5888 10.8245 14.7075 10.7072 14.7075 10.5613V8.44683C14.7075 8.3009 14.5888 8.18359 14.4412 8.18359Z"
                fill="currentColor"
            />
            <path
                d="M2.406 12.1445H0.266368C0.118708 12.1445 0 12.2618 0 12.4078V14.5222C0 14.6682 0.118708 14.7855 0.266368 14.7855H2.406C2.55366 14.7855 2.67237 14.6682 2.67237 14.5222V12.4078C2.67526 12.2647 2.55366 12.1445 2.406 12.1445Z"
                fill="currentColor"
            />
            <path
                d="M6.41772 12.1445H4.27809C4.13043 12.1445 4.01172 12.2618 4.01172 12.4078V14.5222C4.01172 14.6682 4.13043 14.7855 4.27809 14.7855H6.41772C6.56538 14.7855 6.68409 14.6682 6.68409 14.5222V12.4078C6.68409 12.2647 6.56538 12.1445 6.41772 12.1445Z"
                fill="currentColor"
            />
            <path
                d="M10.4294 12.1445H8.28981C8.14214 12.1445 8.02344 12.2618 8.02344 12.4078V14.5222C8.02344 14.6682 8.14214 14.7855 8.28981 14.7855H10.4294C10.5771 14.7855 10.6958 14.6682 10.6958 14.5222V12.4078C10.6987 12.2647 10.5771 12.1445 10.4294 12.1445Z"
                fill="currentColor"
            />
            <path
                d="M14.4412 12.1445H12.3015C12.1539 12.1445 12.0352 12.2618 12.0352 12.4078V14.5222C12.0352 14.6682 12.1539 14.7855 12.3015 14.7855H14.4412C14.5888 14.7855 14.7075 14.6682 14.7075 14.5222V12.4078C14.7075 12.2647 14.5888 12.1445 14.4412 12.1445Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ViewSmall;
