import React from 'react';

const Comment = ({ ...props }) => {
    return (
        <svg viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.122 0H4.4878L0 7.92767V23H11.122V11.8426H6.63415V8.80851L11.122 0ZM23.5122 8.80851L28 0H21.3658L16.878 7.92767V23H28V11.8426H23.5122V8.80851Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Comment;
