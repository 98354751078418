exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialBlock_profileSocialContent__12uCx{\n    width: 236px;\n    max-width: 100%;\n    flex-shrink: 0;\n    background: #182A48;\n    border-radius: 15px;\n    display: flex;\n    align-items: center;\n}\n\n@media (max-width: 998px) {\n    .SocialBlock_profileSocialContent__12uCx{\n        width: 278px;\n    }\n}\n@media (max-width: 400px) {\n    .SocialBlock_profileSocialContent__12uCx{\n        width: 100%;\n    }\n}\n\n.SocialBlock_profileSocialLink__2rrrB{\n    width: 100%;\n    height: 37px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #9099a7;\n    padding: .7rem 1.7rem;\n}\n\n@media (max-width: 998px) {\n    .SocialBlock_profileSocialLink__2rrrB{\n        height: 33px;\n        padding: 0.8rem 1rem;\n    }\n}\n@media (max-width: 716px) {\n    .SocialBlock_profileSocialLink__2rrrB{\n        height: 38px;\n    }\n\n    .SocialBlock_profileCircle__xnq0w{\n        display: none;\n    }\n}\n\n.SocialBlock_profileSocialLink__2rrrB:first-child{\n    border-radius: 15px 0 0 15px;\n    overflow: hidden;\n}\n\n.SocialBlock_profileSocialLink__2rrrB:last-child{\n    border-radius: 0 15px 15px 0;\n}\n\n.SocialBlock_profileSocialLink__2rrrB:only-child{\n    border-radius: 15px;\n}\n\n.SocialBlock_profileSocialLink__2rrrB svg{\n    width: 24px;\n    height: 24px;\n    max-width: 75%;\n    max-height: 75%;\n}\n\n@media (any-hover: hover) {\n    .SocialBlock_profileSocialLink__2rrrB:hover{\n        background: #161E32;\n        color: #fff;\n    }\n}", ""]);

// exports
exports.locals = {
	"profileSocialContent": "SocialBlock_profileSocialContent__12uCx",
	"profileSocialLink": "SocialBlock_profileSocialLink__2rrrB",
	"profileCircle": "SocialBlock_profileCircle__xnq0w"
};