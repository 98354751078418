import React from 'react';

const Check4 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2559 4.41205C17.5814 4.73748 17.5814 5.26512 17.2559 5.59056L8.08926 14.7572C7.76382 15.0827 7.23618 15.0827 6.91074 14.7572L2.74408 10.5906C2.41864 10.2651 2.41864 9.73748 2.74408 9.41205C3.06951 9.08661 3.59715 9.08661 3.92259 9.41205L7.5 12.9895L16.0774 4.41205C16.4028 4.08661 16.9305 4.08661 17.2559 4.41205Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Check4;
