exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SpoilerBlock_tokenInfoItem__Bv_T8{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    border-bottom: 1px solid #2E354D;\n}\n\n.SpoilerBlock_tokenInfoItem__Bv_T8:last-child{\n    border-bottom: none;\n}\n\n.SpoilerBlock_tokenInfoItemTop__uxK4B{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    grid-gap: 8px;\n    gap: 8px;\n    padding: 3.3rem 0;\n}\n\n.SpoilerBlock_tokenInfoItemTop__uxK4B > svg{\n    color: #2069E2;\n}\n\n.SpoilerBlock_tokenInfoItem__Bv_T8.SpoilerBlock_open__dQ3iQ .SpoilerBlock_tokenInfoItemTop__uxK4B > svg{\n    transform: rotate(180deg);\n}\n\n.SpoilerBlock_tokenInfoItemTopName__2O70W{\n    display: flex;\n    align-items: center;\n    grid-gap: 8px;\n    gap: 8px;\n    color: #fff;\n    font-size: 2rem;\n}\n\n@media (max-width: 998px) {\n    .SpoilerBlock_tokenInfoItemTopName__2O70W{\n        font-size: 1.7rem;\n    }\n}\n@media (max-width: 768px) {\n    .SpoilerBlock_tokenInfoItemTopName__2O70W{\n        font-size: 1.5rem;\n    }\n}\n\n.SpoilerBlock_tokenInfoItemTopName__2O70W svg{\n    color: #2069E2;\n}\n\n.SpoilerBlock_tokenInfoItemContent__2b0lJ{\n    width: 100%;\n    padding-bottom: 3.3rem;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    grid-gap: 30px;\n    gap: 30px;\n}\n\n@media (any-hover: hover) {\n    .SpoilerBlock_tokenInfoItemTop__uxK4B{\n        cursor: pointer;\n    }\n}", ""]);

// exports
exports.locals = {
	"tokenInfoItem": "SpoilerBlock_tokenInfoItem__Bv_T8",
	"tokenInfoItemTop": "SpoilerBlock_tokenInfoItemTop__uxK4B",
	"open": "SpoilerBlock_open__dQ3iQ",
	"tokenInfoItemTopName": "SpoilerBlock_tokenInfoItemTopName__2O70W",
	"tokenInfoItemContent": "SpoilerBlock_tokenInfoItemContent__2b0lJ"
};