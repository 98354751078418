import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import './index.css';

import StatChart from '../../components/StatChart';
import { CustomSelect } from '../../common/CustomSelect';
import FilterItem from '../../components/FilterItem';
import SortItem from '../../components/SortItem';
import WhiteListItem from './UI/WhiteListItem';
import { WhitelistItemsContext, WhitelistItemsContextProvider } from './lib/WhitelistItemsContext';
import { useDispatch, useSelector } from 'react-redux';
import useVirtualizedList from '../../hooks/useVirtualizedList';
import { dropFilters, increasePage } from '../../redux/slices/admin/whitelists';
import { WhitelistActionsContextProvider } from './lib/WhitelistActionsContext';
import {
    WhitelistFiltersContext,
    WhitelistFiltersContextProvider,
} from './lib/WhitelistFiltersContext';
import { useGetFilteredSimplifiedCollectionsQuery } from '../../redux/api/handleService';
import Loader from '../../common/Loader';
import { setQuery } from '../../redux/slices/admin/whitelists';
import FullDescription from '../../common/FullDescription';
import InfoBlock from '../../common/InfoBlock';
import CollectionInfo from '../../common/CollectionInfo';
import StickyWrapper from '../../common/StickyWrapper/StickyWrapper';
import { WHITE_LIST_APPLICATION_STATUSES_OPTIONS } from '../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import NoItems from '../../common/NoItems';

import css from './WhiteList.module.css';
import { cnb } from 'cnbuilder';

const TIME_FILTER_LIST = [
    { value: 'all', name: 'All items' },
    { value: 1, name: 'Last hour' },
    { value: 6, name: 'Last 6 hours' },
    { value: 24, name: 'Last 24 hours' },
    { value: 24 * 7, name: 'Last 7 days' },
    { value: 24 * 30, name: 'Last 30 days' },
    { value: 24 * 90, name: 'Last 90 days' },
];

const WhiteList = () => {
    const {
        page,
        whitelistItems,
        totalCount,
        rowsPerPage,
        createdGreateThenInH,
        isLoading,
        query,
        selectedCollections,
        statuses,
    } = useSelector((state) => ({
        page: state.whitelists.page,
        whitelistItems: state.whitelists.whitelists,
        totalCount: state.whitelists.totalCount,
        rowsPerPage: state.whitelists.rowsPerPage,
        selectedCollections: state.whitelists.selectedCollections,
        createdGreateThenInH: state.whitelists.createdGreateThenInH,
        isLoading: state.whitelists.isLoading,
        query: state.whitelists.query,
        statuses: state.whitelists.statuses,
    }));

    const {
        state: { accounts, selectedAccounts, collections, isCollectionsLoading, isAccountsLoading },
        actions: {
            increaseCollectionsPage,
            increaseAccountsPage,

            handleSelectAccounts,
            handleRemoveAccounts,

            handleChangeCollectionsSearchQuery,
            handleChangeAccountsSearchQuery,

            addCollectionIdHandler,
            removeCollectionIdHandler,

            handleChangeDateGte,

            handleAddStatusFilter,
            removeStatusFilter,
        },
    } = useContext(WhitelistFiltersContext);

    const {
        actions: { refetchApplicationsHandler, nextPageHandler },
    } = useContext(WhitelistItemsContext);

    const { data: collectionsDataForMap } = useGetFilteredSimplifiedCollectionsQuery({
        page: 1,
        pageSize: 10000,
    });

    const filtersBarRef = useRef(null);
    const filtersBarOffsetTopRef = useRef();

    const filtersTopRef = useRef(null);
    const filtersBottomRef = useRef(null);

    const tableHeader = useRef(null);

    const pageRef = useRef(page);

    const [tableHeaderWidth, setTableHeaderWidth] = useState(0);
    const [stickyHeaderOffsetTop, setStickyHeaderOffsetTop] = useState(0);

    const dispatch = useDispatch();

    const { itemsIndexesToRender } = useVirtualizedList({
        itemsCount: totalCount,
        itemsCountIntoView: 18,
        itemHeight: 72,
        scrollContainerElement: window,
        topIndent: filtersBarOffsetTopRef.current,
    });

    const [filterActive, setFilterActive] = useState(false);
    const [sortActive, setSortActive] = useState(false);

    const selectedAccountsIds = useMemo(() => {
        return selectedAccounts.map((el) => el.id);
    }, [selectedAccounts]);

    const selectedCollectionsIds = useMemo(() => selectedCollections.map((el) => el.id), [
        selectedCollections,
    ]);

    const collectionMap = useMemo(() => {
        const map = new Map();

        if (collectionsDataForMap && collectionsDataForMap.results) {
            collectionsDataForMap.results.forEach((el) => {
                map.set(el.id, el);
            });
        }

        return map;
    }, [collectionsDataForMap]);

    const activeFilters = useMemo(() => {
        const res = [];

        selectedCollections.forEach((col) => {
            res.push({
                id: col.id,
                name: col.name,
                onRemove: () => removeCollectionIdHandler(col.id),
            });
        });
        statuses.forEach((status) => {
            res.push({
                id: status.id,
                name: status.name,
                onRemove: () => removeStatusFilter(status.id),
            });
        });

        return res;
    }, [selectedCollections, statuses]);

    const changeWhitelistSearchHandler = useCallback((e) => {
        dispatch(setQuery(e.target.value));
    }, []);

    const emptySearch = useCallback(() => {
        dispatch(setQuery(''));
    }, []);

    useEffect(() => {
        if (filtersBarRef.current) {
            filtersBarOffsetTopRef.current = filtersBarRef.current.getBoundingClientRect().top;
        }
    }, []);

    useEffect(() => {
        pageRef.current = page;
    }, [page]);

    useEffect(() => {
        const lastRenderItemIndex = itemsIndexesToRender[itemsIndexesToRender.length - 1];
        const currentItemsAmount = rowsPerPage * pageRef.current;

        if (lastRenderItemIndex + 1 >= currentItemsAmount && totalCount > currentItemsAmount) {
            nextPageHandler();
        }
    }, [itemsIndexesToRender, totalCount]);

    useEffect(() => {
        if (filtersTopRef.current && filtersBottomRef.current) {
            const top = filtersTopRef.current.getBoundingClientRect().top;
            const bottom = filtersBottomRef.current.getBoundingClientRect().bottom;

            setStickyHeaderOffsetTop(bottom - top);
        }
    }, [whitelistItems, activeFilters, filterActive, isLoading]);

    useEffect(() => {
        if (tableHeader.current) {
            new ResizeObserver(() => {
                if (tableHeader.current) {
                    setTableHeaderWidth(tableHeader.current.offsetWidth);
                }
            }).observe(tableHeader.current);
        }
    }, []);

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <h2 className="title collection__title">Whitelist statistic</h2>

                    <InfoBlock
                        data={[
                            {
                                title: 'whitelisted',
                                value: '0',
                            },
                            {
                                title: 'booked',
                                value: '0',
                            },
                            {
                                title: 'minted',
                                value: '0',
                            },
                        ]}
                    />

                    <FullDescription
                        description="The body manages the stages of the mint, monitors the statistics of
                            submitted applications, application statuses, the content of
                            applications, user names, contacts and wallet addresses. New - a newly
                            received application, Whitelist - an application approved by the
                            moderator, Booked - an application for which the token was booked,
                            Minted - an application for which the token was minted, Redlist - an
                            application suspended by the moderator."
                    />

                    <StatChart />
                    <div className={css.items_section_wrapper}>
                        <StickyWrapper
                            shouldBeSticky={totalCount > 10}
                            indentTop={60}
                            className="collection__wrapper container"
                        >
                            <div ref={filtersTopRef} />
                            <div ref={filtersBarRef} className="collection__filter--content">
                                <button
                                    className="button collection__filter--button"
                                    onClick={() => setFilterActive((prev) => !prev)}
                                >
                                    <img
                                        src="/assets/img/filter.svg"
                                        alt="filter"
                                        className="collection__filter--icon"
                                    />

                                    <img
                                        src="/assets/img/filter2.svg"
                                        alt="filter"
                                        className="collection__filter--icon mobile"
                                    />

                                    <p className="collection__filter--button--text">Filters</p>
                                </button>

                                <button
                                    className="button collection__sort--button"
                                    onClick={() => setSortActive((prev) => !prev)}
                                >
                                    <img
                                        src="/assets/img/sort.svg"
                                        alt="sort"
                                        className="collection__filter--icon mobile"
                                    />

                                    <p className="collection__sort--button--text">Sort</p>
                                </button>

                                <div className="collection__search--inner">
                                    <input
                                        type="text"
                                        className="input header__search"
                                        placeholder="Search by discord name or wallet"
                                        value={query}
                                        onChange={changeWhitelistSearchHandler}
                                    />

                                    <img
                                        src="/assets/img/search.svg"
                                        alt="search"
                                        className="header__search--icon"
                                    />

                                    {query && (
                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="header__search--remove"
                                            onClick={emptySearch}
                                        />
                                    )}
                                </div>

                                <div className="collection__filter--order">
                                    <CustomSelect
                                        optionsList={TIME_FILTER_LIST}
                                        value={createdGreateThenInH}
                                        onChange={handleChangeDateGte}
                                    />
                                </div>
                            </div>

                            <div className="whitelist_filters">
                                {filterActive && (
                                    <div className="collection__filter--box">
                                        <div className="collection__filter--title--box">
                                            <p className="collection__filter--title">Filters</p>

                                            <img
                                                src="/assets/img/cross2.svg"
                                                alt="cross"
                                                className="collection__filter--title--cross"
                                                onClick={() => setFilterActive((prev) => !prev)}
                                            />
                                        </div>

                                        <FilterItem
                                            title="Status"
                                            elements={WHITE_LIST_APPLICATION_STATUSES_OPTIONS}
                                            checkedItemsIds={statuses.map((el) => el.id)}
                                            onCheckItem={handleAddStatusFilter}
                                            onRemoveItem={removeStatusFilter}
                                        />

                                        <FilterItem
                                            title="Brands"
                                            elements={accounts}
                                            getNewItemsHandler={increaseAccountsPage}
                                            handleChangeSearchQuery={
                                                handleChangeAccountsSearchQuery
                                            }
                                            checkedItemsIds={selectedAccountsIds}
                                            onCheckItem={handleSelectAccounts}
                                            onRemoveItem={handleRemoveAccounts}
                                            isLoading={isAccountsLoading}
                                            filter
                                        />

                                        <FilterItem
                                            title="Collection"
                                            elements={collections}
                                            getNewItemsHandler={increaseCollectionsPage}
                                            handleChangeSearchQuery={
                                                handleChangeCollectionsSearchQuery
                                            }
                                            checkedItemsIds={selectedCollectionsIds}
                                            onCheckItem={addCollectionIdHandler}
                                            onRemoveItem={removeCollectionIdHandler}
                                            isLoading={isCollectionsLoading}
                                            filter
                                        />

                                        <div className="collection__filter--buttons">
                                            <button
                                                className="button collection__filter--button--filter"
                                                onClick={() => dispatch(dropFilters())}
                                            >
                                                Clear all
                                            </button>

                                            <button
                                                className="button collection__filter--button--filter blue__button"
                                                onClick={() => setFilterActive((prev) => !prev)}
                                            >
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {sortActive && (
                                    <div className="collection__sort--box">
                                        <div className="collection__filter--title--box">
                                            <p className="collection__filter--title">Sort by</p>

                                            <img
                                                src="/assets/img/cross2.svg"
                                                alt="cross"
                                                className="collection__filter--title--cross"
                                                onClick={() => setSortActive((prev) => !prev)}
                                            />
                                        </div>

                                        <div className="collection__sort--content">
                                            {TIME_FILTER_LIST.map((data, id) => (
                                                <SortItem
                                                    key={id}
                                                    text={data.name}
                                                    id={`${data.value}_${id}`}
                                                />
                                            ))}
                                        </div>

                                        <div className="collection__filter--buttons">
                                            <button className="button collection__sort--button--filter blue__button" onClick={() => setSortActive((prev) => !prev)}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div
                                    className={`collection__content--preitems ${filterActive &&
                                        'collection__content--preitems-active paddingRight30'}`}
                                >
                                    <CollectionInfo
                                        refreshCallback={refetchApplicationsHandler}
                                        tokensCount={`${totalCount} applications`}
                                    />

                                    {activeFilters.length > 0 && (
                                        <div className="collection__filter--active">
                                            {activeFilters.map((option) => (
                                                <div
                                                    key={option.id}
                                                    className="collection__filter--active--content"
                                                >
                                                    <button className="button collection__filter--active--item">
                                                        <p className="collection__filter--active--item--text">
                                                            {option.name}
                                                        </p>

                                                        <img
                                                            src="/assets/img/cross2.svg"
                                                            alt="cross"
                                                            className="collection__filter--active--item--delete"
                                                            onClick={option.onRemove}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                className="button collection__filter--clear"
                                                onClick={() => dispatch(dropFilters())}
                                            >
                                                Clear All
                                            </button>
                                        </div>
                                    )}

                                    <div
                                        className="stats__item--names whithdraw__names"
                                        ref={tableHeader}
                                    >
                                        {/* {tableHeaderWidth > 700 && ( */}
                                        <div className="stats__item--day whithdraw__item--item stats__item--name medium order__last off700">
                                            Status
                                        </div>
                                        {/* )} */}

                                        <div className="stats__item--volume whithdraw__item--item stats__item--name big">
                                            Collection
                                        </div>

                                        {/* {tableHeaderWidth > 998 && ( */}
                                        <div className="stats__item--day whithdraw__item--item stats__item--name off998 activeoff1600">
                                            User
                                        </div>
                                        {/* )} */}

                                        {/* {tableHeaderWidth > 1200 && ( */}
                                        <div className="stats__item--week whithdraw__item--item stats__item--name off1200 activeoff1200">
                                            Meta data
                                        </div>
                                        {/* )} */}

                                        {/* {tableHeaderWidth > 700 && ( */}
                                        <div className="stats__item--price whithdraw__item--item stats__item--name off700 centertext">
                                            Wallet address / balance
                                        </div>
                                        {/* )} */}

                                        {/* {tableHeaderWidth > 1200 && ( */}
                                        <div className="stats__item--owners whithdraw__item--item stats__item--name off1200 activeoff1600 center__item">
                                            Time
                                        </div>
                                        {/* )} */}

                                        {/* {tableHeaderWidth > 700 && ( */}
                                        <div className="stats__item--items whithdraw__item--item stats__item--name off700 activeoff1600 center__item">
                                            In work
                                        </div>
                                        {/* )} */}

                                        {/* {tableHeaderWidth > 998 && ( */}
                                        <div className="stats__item--items whithdraw__item--item stats__item--name">
                                            Action
                                        </div>
                                        {/* )} */}
                                    </div>
                                    <div ref={filtersBottomRef} />
                                </div>
                            </div>
                        </StickyWrapper>

                        <div
                            className={css.whitelist_items_container}
                            style={{ marginTop: `${stickyHeaderOffsetTop + 10}px` }}
                        >
                            {filterActive && <div className="active-box-plug" />}
                            <div
                                className="collection__content--items"
                                style={{
                                    minHeight: `calc(100vh - ${stickyHeaderOffsetTop + 10 + 64}px)`,
                                }}
                            >
                                {whitelistItems && !whitelistItems.length && !isLoading ? (
                                    <NoItems />
                                ) : (
                                    <>
                                        <div
                                            className={`stats__content${
                                                filterActive ? ' active' : ''
                                            }`}
                                        >
                                            {whitelistItems.map((application, index) => (
                                                <WhiteListItem
                                                    key={`${application.id}_${index}`}
                                                    data={application}
                                                    shouldBeRendered={itemsIndexesToRender.includes(
                                                        index,
                                                    )}
                                                    collection={collectionMap.get(
                                                        application.collection.collectionId,
                                                    )}
                                                    id={index + 1}
                                                />
                                            ))}
                                        </div>
                                    </>
                                )}
                                {isLoading && (
                                    <div
                                        className="start-container"
                                        style={{
                                            height: `calc(100vh - ${stickyHeaderOffsetTop +
                                                10 +
                                                64}px)`,
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                )}
                                {/* <div className={css.bottomSpace} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PrefetchWrapper = (props) => {
    return (
        <WhitelistActionsContextProvider>
            <WhitelistFiltersContextProvider>
                <WhitelistItemsContextProvider>
                    <WhiteList {...props} />
                </WhitelistItemsContextProvider>
            </WhitelistFiltersContextProvider>
        </WhitelistActionsContextProvider>
    );
};

export default React.memo(PrefetchWrapper);
