import React from 'react';

import css from './index.module.css';

const ActionMenu = ({actions, id}) => {
    const [actionMenu, setActionMenu] = React.useState(false);

    React.useEffect(() => {
        const clickHandler = (e) => {
            const target = e.target;

            if(!target.closest(`#actionMenu_${id}`)){
                setActionMenu(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
    }, []);

    return (
        <div className={css.adminsActionInner} id={`actionMenu_${id}`} onClick={() => setActionMenu(prev => !prev)}>
            <img src="/assets/img/dots-vertical.svg" alt="dots" />

            {actionMenu && <div className={css.adminsActionContent}>
                {actions.map((data, id) => <div key={id} className={css.adminsActionItem} onClick={data.action}>
                    {data.text}
                </div>)}
            </div>}
        </div>
    )
}

export default ActionMenu;
