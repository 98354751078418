import React from 'react';

export const CaseIcon = () => {
    return (
        <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.07143 4.03571V3.42857C7.07143 2.08731 8.15874 1 9.5 1C10.8413 1 11.9286 2.08731 11.9286 3.42857V4.03571M1 8.28571C5.49987 13.4284 13.5001 13.4284 18 8.28571M2.21429 4.64286H16.7857C17.4563 4.64286 18 5.18651 18 5.85714V16.7857C18 17.4563 17.4563 18 16.7857 18H2.21429C1.54365 18 1 17.4563 1 16.7857V5.85714C1 5.18651 1.54365 4.64286 2.21429 4.64286Z"
                stroke="white"
                stroke-width="1.8"
            ></path>
        </svg>
    );
};
