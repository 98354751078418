import React from 'react';

const RecoverIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path
                fill="currentColor"
                d="M7.341 5.294c1.594-1.387 3.782-1.992 5.731-1.992 5.246 0 9.505 4.259 9.505 9.505s-4.259 9.505-9.505 9.505a9.458 9.458 0 0 1-4.675-1.228.938.938 0 0 1 .924-1.632 7.634 7.634 0 0 0 11.381-6.645 7.634 7.634 0 0 0-7.63-7.63c-1.487 0-3.164.42-4.4 1.447l1.561 1.562a.187.187 0 0 1-.107.318l-5.03.695a.188.188 0 0 1-.212-.212l.695-5.03a.187.187 0 0 1 .318-.108l1.444 1.445ZM6.057 17.38a.938.938 0 1 1-.09 1.874.938.938 0 0 1 .09-1.874Zm6.285-9.461a.469.469 0 0 1 .332-.138h3.75c.259 0 .469.21.469.469v8.316c0 .258-.21.468-.469.468H9a.469.469 0 0 1-.469-.468v-4.642c0-.124.05-.244.138-.332l3.673-3.673Zm-8.016 6.513a.938.938 0 1 1-.09 1.874.938.938 0 0 1 .09-1.874Zm5.527.812c0-.103.084-.187.187-.187h5.297c.104 0 .188.084.188.187v.422a.187.187 0 0 1-.188.188H10.04a.187.187 0 0 1-.187-.188v-.422Zm0-1.593c0-.104.084-.188.187-.188h5.297c.104 0 .188.084.188.188v.421a.188.188 0 0 1-.188.188H10.04a.188.188 0 0 1-.187-.188v-.421Zm-5.777-2.728a.938.938 0 1 1-.09 1.874.938.938 0 0 1 .09-1.874Zm5.777 1.134c0-.104.084-.188.187-.188h5.297c.104 0 .188.084.188.188v.422a.187.187 0 0 1-.188.187H10.04a.187.187 0 0 1-.187-.187v-.422Zm2.527-2.54c0-.103.084-.187.188-.187h2.769c.104 0 .188.084.188.188v.422a.187.187 0 0 1-.188.187h-2.77a.188.188 0 0 1-.187-.187v-.422Z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default React.memo(RecoverIcon);
