import React from 'react';

const Qr = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.5 0C0.224 0 0 0.224 0 0.5V6.5C0 6.776 0.224 7 0.5 7H6.5C6.776 7 7 6.776 7 6.5V0.5C7 0.224 6.776 0 6.5 0H0.5ZM9.5 0C9.224 0 9 0.224 9 0.5V6.5C9 6.776 9.224 7 9.5 7H15.5C15.776 7 16 6.776 16 6.5V0.5C16 0.224 15.776 0 15.5 0H9.5ZM1 1H6V6H1V1ZM10 1H15V6H10V1ZM2 2V5H5V2H2ZM11 2V5H14V2H11ZM0.5 9C0.224 9 0 9.224 0 9.5V15.5C0 15.776 0.224 16 0.5 16H6.5C6.776 16 7 15.776 7 15.5V9.5C7 9.224 6.776 9 6.5 9H0.5ZM9.5 9C9.224 9 9 9.224 9 9.5V12H10V10H12V9H9.5ZM13.5 9C13.224 9 13 9.224 13 9.5V12H14V10H16V9H13.5ZM1 10H6V15H1V10ZM2 11V14H5V11H2ZM11 12V13H10.5C10.224 13 10 13.224 10 13.5V16H11V14H12V16H13V13.5C13 13.224 12.776 13 12.5 13H12V12H11ZM15 12V16H16V12H15Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Qr;
