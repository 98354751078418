export const WHITE_LIST_APPLICATION_STATUSES = {
    NEW: 'new',
    RED: 'read',
    WHITE: 'white',
    BOOKED: 'booked',
    MINTED: 'minted',
    DELETED: 'deleted',
};

export const WHITE_LIST_APPLICATION_STATUSES_OPTIONS = [
    {
        id: 'new',
        name: 'New',
    },
    {
        id: 'read',
        name: 'Red',
    },
    {
        id: 'white',
        name: 'White',
    },
    {
        id: 'booked',
        name: 'Booked',
    },
    {
        id: 'minted',
        name: 'Minted',
    },
];

export const WHITE_LIST_APPLICATION_STATUSES_LABELS = {
    [WHITE_LIST_APPLICATION_STATUSES.NEW]: 'New',
    [WHITE_LIST_APPLICATION_STATUSES.RED]: 'Redlist',
    [WHITE_LIST_APPLICATION_STATUSES.WHITE]: 'Whitelist',
    [WHITE_LIST_APPLICATION_STATUSES.BOOKED]: 'Booked',
    [WHITE_LIST_APPLICATION_STATUSES.MINTED]: 'Minted',
    [WHITE_LIST_APPLICATION_STATUSES.DELETED]: 'Deleted',
};
