import React from 'react';

const Card = ({ ...props }) => {
    return (
        <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.375 3.95833C1.93777 3.95833 1.58333 4.31277 1.58333 4.75V14.25C1.58333 14.6872 1.93777 15.0417 2.375 15.0417H16.625C17.0622 15.0417 17.4167 14.6872 17.4167 14.25V4.75C17.4167 4.31277 17.0622 3.95833 16.625 3.95833H2.375ZM0 4.75C0 3.43832 1.06332 2.375 2.375 2.375H16.625C17.9367 2.375 19 3.43832 19 4.75V14.25C19 15.5617 17.9367 16.625 16.625 16.625H2.375C1.06332 16.625 0 15.5617 0 14.25V4.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 7.91667C0 7.47944 0.354441 7.125 0.791667 7.125H18.2083C18.6456 7.125 19 7.47944 19 7.91667C19 8.35389 18.6456 8.70833 18.2083 8.70833H0.791667C0.354441 8.70833 0 8.35389 0 7.91667Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Card;
