import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentsList: [],
};

const tableStoreSlice = createSlice({
    name: 'tableSlice',
    initialState: initialState,
    reducers: {
        setParentsList: (state, action) => {
            state.parentsList = action.payload
        },
    },
});

export const { setParentsList } = tableStoreSlice.actions;

export const tableReducer = tableStoreSlice.reducer;
