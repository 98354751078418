import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './index.css';
import { CustomSelect } from '../../common/CustomSelect';
import { Link, useParams } from 'react-router-dom';
import { useLazyGetAccountsListFilteredQuery } from '../../redux/api/handleService';
import {
    createAdmin,
    editRole,
    fetchCollections,
    fetchUserInfoById,
    getRoleNameByStringTitle,
    handleChangeDeleteRolesList,
    handleSelectId,
} from './utils';
// import { UsersAccess } from './UserAccess';
// import { selectVariants } from './utils';
import { roleVariants } from './utils';
// import { SelectMenu } from './SelectMenu';
import { SelectMenu } from './SelectMenu';
import { Button } from '@mui/material';
import { UserResources } from './UserResources';
import { UserRolesAccess } from './UserRolesAccess';

const translateNameVariants = {
    [`Moderator Store`]: 'Модератор магазина',
    [`User`]: 'Пользователь',
    [`Moderator Factory`]: 'Модератор Фабрики',
    [`Moderator`]: 'Модератор',
    [`Moderator Income`]: 'Модератор вывода',
};

const CreateAdmin = () => {
    const { roleId } = useParams();
    const [userData, setUserData] = useState([]);
    const isRendered = useRef(true);
    const [brands, setBrands] = React.useState([]);
    const [collections, setCollections] = React.useState([]);
    const [name, setName] = useState('');
    const [role, setRole] = useState({
        resource_id: '',
        sub_resource_id: '',
        roleNumber: 0,
    });
    const [getBrands] = useLazyGetAccountsListFilteredQuery();
    const [isRefetch, setIsRefetch] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    // const [rolesDeleteList, setRolesDeleteList] = useState([]);
    const [searchValue, setSearchValue] = useState({ brands: '', collections: '' });
    const [userAccess, setUserAccess] = useState({});

    useEffect(() => {
        if (isRefetch === true) {
            fetchCollections(role, setCollections, searchValue.collections);
            setIsRefetch(false);
        }
    }, [role, isRefetch, searchValue.collections]);

    useEffect(() => {
        if (isRendered.current === true) {
            setIsEditMode(true);
            fetchUserInfoById(setUserData, roleId);
            isRendered.current = false;
        }

        getBrands({
            query: searchValue.brands,
        })
            .unwrap()
            .then((result) => {
                if (result && result.results) {
                    setBrands(
                        result.results.map((brand) => {
                            return { value: brand.id, name: brand.name, state: false };
                        }),
                    );
                }
            });
    }, [roleId, searchValue.brands]);

    const handleSetBrandId = (brand, type) => {
        setIsRefetch(true);
        handleSelectId(setRole, setBrands, brand, type);
    };

    const handleSetCollectionId = (collection, type) => {
        handleSelectId(setRole, setCollections, collection, type);
    };
    // const handleRemoveAcces = useCallback((payload, isDelete) => {
    //     handleChangeDeleteRolesList(setRolesDeleteList, payload, isDelete);
    // }, []);

    const handleAddRole = useCallback(() => {
        const accessToken = localStorage.getItem('access_token');
        const { sub_resource_id, resource_id, roleNumber } = role;
        fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/${roleId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                access: [
                    {
                        resource_id: sub_resource_id,
                        sub_resource_id: resource_id,
                    },
                ],
                role_number: roleNumber,
                public_address: roleId,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                console.log(response);
            });
        // }
    }, [role, userData]);

    // const handleSelectRole = (roleNumber) => {
    //     setRole((prev) => ({ ...prev, roleNumber }));
    // };

    // const availibleRoles = useMemo(() => {
    //     if (!userData.length) return roleVariants;
    //     const existingRoles = new Set(
    //         userData.map((access) => String(access.role.name).toLowerCase()),
    //     );
    //     return roleVariants.filter(
    //         (variant) => !existingRoles.has(String(variant.title).toLowerCase()),
    //     );
    // }, [roleVariants, userData]);

    const handleCancel = () => {
        setRole({
            resource_id: '',
            sub_resource_id: '',
            roleNumber: 0,
        });

        setCollections((prev) => {
            return prev.map((checkbox) => {
                return { ...checkbox, state: false };
            });
        });
        setBrands((prev) => {
            return prev.map((checkbox) => {
                return { ...checkbox, state: false };
            });
        });
    };

    const handleSetSearchValue = (type, value) => {
        setSearchValue((prev) => ({ ...prev, [type]: value }));
        if (type === 'collections') {
            setIsRefetch(true);
        }
    };

    // const handleViewRoles = (access) => {
    //     setUserAccess((prevAccess) => {
    //         if (prevAccess[access.role.name]) {
    //             return { ...prevAccess, [access.role.name]: null };
    //         } else {
    //             return { ...prevAccess, [access.role.name]: access.access };
    //         }
    //     });
    // };

    // console.log(userAccess);
    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <div className="title__inner">
                        <Link to="../admins">
                            <img src="/assets/img/back-arrow.svg" alt="arrow" />
                        </Link>

                        <h2 className="title collection__title">
                            {isEditMode ? 'Изменить доступы' : 'Создать администратора/модератора'}
                        </h2>
                    </div>
                    <div className="collection__content">
                        <div className={`checkbox__filter`}>
                            <SelectMenu
                                data={brands}
                                title={'brands'}
                                setOpen={() => {}}
                                onChange={handleSetBrandId}
                                setSearchValue={handleSetSearchValue}
                                search={searchValue.brand}
                            />

                            <SelectMenu
                                data={collections}
                                title={'collections'}
                                setOpen={() => {}}
                                onChange={handleSetCollectionId}
                                setSearchValue={handleSetSearchValue}
                                search={searchValue.collections}
                            />
                        </div>
                        <div>
                            {/* {isEditMode && userData.access && userData.access.length > 0 && (
                                <div className="userAccess">
                                    <h3>Доступы пользователя</h3>
                                    <UsersAccess
                                        user={userData}
                                        rolesDeleteList={rolesDeleteList}
                                        handleRemoveAcces={handleRemoveAcces}
                                    />
                                </div>
                            )} */}
                        </div>

                        <div className="user">
                            <div className="user__profile">
                                <div className="profile">
                                    <img />
                                    <div className="profile__info">
                                        <p>
                                            {userData[0] &&
                                                userData[0].user_profile &&
                                                userData[0].user_profile.username}
                                        </p>
                                        <p className="info__address">
                                            {userData[0] &&
                                                userData[0].user_profile &&
                                                userData[0].user_profile.public_address}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <UserRolesAccess userData={userData} setRole={setRole} />

                            <div style={{ display: 'flex', gap: '14px', paddingTop: '10px' }}>
                                {(role.resource_id || role.sub_resource_id || role.roleNumber) && (
                                    <Button
                                        onClick={handleCancel}
                                        variant="contained"
                                        sx={{ backgroundColor: '#5b5b5b' }}
                                    >
                                        Отменить
                                    </Button>
                                )}

                                <Button
                                    onClick={handleAddRole}
                                    variant="contained"
                                    disabled={
                                        !role.resource_id ||
                                        !role.sub_resource_id ||
                                        !role.roleNumber
                                    }
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateAdmin;
