import React, { useCallback, useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import Input from '../../common/Input';
import File from '../../common/File';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import {
    useCreatePageMutation,
    useGetPageByUrlQuery,
    useUpdatePageMutation,
} from '../../redux/api/dataService';
import { normilizeError } from '../../utils/http/normilizeError';
import CenteredContainer from '../../common/CenteredContainer';
import Loader from '../../common/Loader';

import './index.css';
import { useCheckValidationErrors } from '../../hooks/useCheckValidationErrors';

export function isValidURL(string) {
    const pattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$', 'i')
    return !!pattern.test(string);
}
const CreatePage = (props) => {
    const { isEdit } = props;

    const {errors, handlerErrors, handelClearErrors} = useCheckValidationErrors()

    const { url: urlP } = useParams();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const [
        onCreatePageRequest,
        { isLoading, error, isSuccess, reset: resetPageCreation },
    ] = useCreatePageMutation();

    const [
        onUpdatePageRequest,
        {
            isLoading: isPageUpdatingProccessing,
            error: updatePageError,
            isSuccess: isPageUpdatingSuccessfully,
            reset: resetPageUpdate,
        },
    ] = useUpdatePageMutation();

    const { data: page, isLoading: isPageLoading } = useGetPageByUrlQuery(urlP, {
        skip: !urlP || !isEdit,
    });

    const navigate = useNavigate()

    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [url, setUrl] = useState('');
    const [banner, setBanner] = useState('');
    const [title1, setTitle1] = useState('');
    const [description, setDescription] = useState('');
    const [title2, setTitle2] = useState('');

    const [isSendData, setIsSendData] = useState(false)



    const createPageFunc = useCallback(() => {
        setIsSendData(true)
        if (isLoading) {
            return;
        }

        if (
            !name ||
            !url ||
            !number ||
            (!banner && !isEdit) ||
            !title1 ||
            !description ||
            !title2 ||
            (!logo && !isEdit)
        ) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Fill all required fields',
            });
            return;
        }

        let formData = new FormData();

        if (logo && !isValidURL(logo)) {
            formData.append('icon', logo);
        }
        formData.append('name', name);
        formData.append('number', parseInt(number));
        formData.append('url', url);
        if (banner && !isValidURL(banner)) {
            formData.append('banner', banner);
        }
        formData.append('title_1', title1);
        formData.append('description', description);
        formData.append('title_2', title2);

        if (isEdit) {
            onUpdatePageRequest({
                url: urlP,
                data: formData,
            })
              .unwrap()
              .then(() => {
                  localStorage.setItem('isDelay', 'true')
                  navigate(`/admin/statistics2`)
              })
                .catch((err) => {
                    handlerErrors(err.data)
                })
                .finally(() => {
                    setIsSendData(false)
                })
        } else {
            onCreatePageRequest(formData)
                .unwrap()
                .then(() => {
                    localStorage.setItem('isDelay', 'true')
                    navigate(`/admin/statistics2`)
                })
                .catch((err) => {
                    handlerErrors(err.data)
                })
                .finally(() => {
                    setIsSendData(false)
                })
        }
    }, [logo, isEdit, name, number, urlP, banner, title1, description, title2, isLoading]);

    useEffect(() => {
        if (error) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(error),
            });
        }
    }, [error]);

    useEffect(() => {
        if (updatePageError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(updatePageError),
            });
        }
    }, [updatePageError]);

    useEffect(() => {
        if (isSuccess) {
            setName('');
            setNumber('');
            setUrl('');
            setBanner('');
            setTitle1('');
            setDescription('');
            setTitle2('');

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Page successfuly created',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isPageUpdatingSuccessfully) {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Page successfuly updated',
            });
        }
    }, [isPageUpdatingSuccessfully]);


    useEffect(() => {
        if (page && isEdit) {
            setName(page.name);
            setNumber(String(page.number));
            setUrl(page.url);
            setTitle1(page.title_1);
            setDescription(page.description);
            setTitle2(page.title_2);
            page.icon && setLogo(page.icon)
            page.banner && setBanner(page.banner)
        }
    }, [page, isEdit]);

    useEffect(
        () => () => {
            resetPageCreation();
            resetPageUpdate();
        },
        [],
    );

    if (isPageLoading) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">{isEdit ? 'Update Page' : 'Create Page'}</h2>

                        <div className="create__content">
                            <File
                                title="Logo image"
                                text="100x100 recommended"
                                id="createpageLogo"
                                defaultValue={page && page.icon}
                                value={logo}
                                setValue={(value) => {
                                    setLogo(value)
                                    handelClearErrors('icon')
                                }}
                                type="logosmall"
                                accept={'image/svg+xml, image/png'}
                                isError={(!isEdit && isSendData && !logo) || errors.includes('icon')}
                            />

                            <File
                                title="Banner image"
                                text="1400 x 350 recommended"
                                required
                                id="createpageBanner"
                                defaultValue={page && page.banner}
                                value={banner}
                                setValue={(value) => {
                                    setBanner(value)
                                    handelClearErrors('banner')
                                }}
                                isError={(!isEdit && isSendData && !banner) || errors.includes('banner')}
                            />

                            <Input
                                title="Page name"
                                placeholder="Enter page name"
                                typeItem="medium"
                                required
                                value={name}
                                setValue={(value) => {
                                    setName(value)
                                    handelClearErrors('name')
                                }}
                                isError={(isSendData && !name) || errors.includes('name')}
                            />

                            <Input
                                title="Page number"
                                placeholder="1"
                                typeItem="small"
                                required
                                value={number}
                                setValue={(value) => {
                                    setNumber(value)
                                    handelClearErrors('number')
                                }}
                                isError={(isSendData && !number) || errors.includes('number')}
                            />

                            <Input
                                title="URL"
                                text="50"
                                placeholder="page-name"
                                required
                                value={url}
                                setValue={(value) => {
                                    setUrl(value)
                                    handelClearErrors('url')
                                }}
                                isError={(isSendData && !url) || errors.includes('url')}
                            />

                            <Input
                                title="Header"
                                text="50"
                                placeholder="Enter title name"
                                required
                                value={title1}
                                setValue={(value) => {
                                    setTitle1(value)
                                    handelClearErrors('title_1')
                                }}
                                isError={(isSendData && !title1) || errors.includes('title_1')}

                            />

                            <Input
                                title="Description"
                                text="1000"
                                placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."
                                required
                                value={description}
                                setValue={(value) => {
                                    setDescription(value)
                                    handelClearErrors('description')
                                }}
                                textarea
                                isError={(isSendData && !description) || errors.includes('description')}
                            />

                            <Input
                                title="Footer"
                                text="50"
                                placeholder="Enter title name"
                                required
                                value={title2}
                                setValue={(value) => {
                                    setTitle2(value)
                                    handelClearErrors('title_2')
                                }}
                                isError={(isSendData && !title2) || errors.includes('title_2')}
                            />
                        </div>

                        <div className="create__button--content">
                            {isLoading || isPageUpdatingProccessing ? (
                                <button className="button create__button disabled">
                                    Loading..
                                </button>
                            ) : (
                                <button className="button create__button" onClick={createPageFunc}>
                                    {isEdit ? 'Save changes' : 'Create page'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreatePage);
