import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';
import styles from './index.module.css';
import { cnb } from 'cnbuilder';

const CollectionSkeleton = () => {
    return (
        <>
            <div className="collection__header">
                <Skeleton
                    containerClassName="collection__skeleton--abs"
                    className="skeleton__content"
                />
            </div>

            <div className="collection">
                <div className="container">
                    <div className="collection__inner">
                        <div className="collection__avatar--inner collection__skeleton--avatar--inner" style={{borderColor: 'transparent'}}>
                            <Skeleton
                                containerClassName="collection__skeleton--abs"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="collection__link--inner">
                            <Skeleton
                                containerClassName="collection__title--skeleton--inner"
                                className="skeleton__content"
                            />

                            <Skeleton
                                containerClassName="collection__social--skeleton--inner"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="collection__data">
                            <Skeleton
                                containerClassName="collection__data--skeleton"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="collection__desc--inner">
                            <Skeleton
                                containerClassName="collection__desc--skeleton"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="collection__filter--content">
                            <Skeleton
                                containerClassName="collection__filter--skeleton"
                                className="skeleton__content"
                            />
                            <Skeleton
                                containerClassName="collection__filter--skeleton mobile"
                                className="skeleton__content"
                            />
                            <Skeleton
                                containerClassName="collection__filter--skeleton mobile"
                                className="skeleton__content"
                            />
                        </div>

                        <div className="collection__content">
                            <div className="collection__content--preitems">
                                <div className="collection__content--info">
                                    <Skeleton
                                        containerClassName="collection__content--info--skeleton"
                                        className="skeleton__content"
                                    />
                                </div>

                                <div className={styles.items_wrapper}>
                                    <div
                                        className={cnb(
                                            styles.items_container,
                                            'items_container--skeleton',
                                        )}
                                    >
                                        {new Array(0, 0, 0, 0, 0, 0, 0, 0, 0, 0).map((_, id) => (
                                            <div key={id} className="collection__item--skeleton">
                                                <Skeleton
                                                    containerClassName="collection__item--skeleton"
                                                    className="skeleton__content"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CollectionSkeleton;
