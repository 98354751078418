import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Telegram from '../../icons/Telegram';
import Search from '../../icons/Search';
import Wallet from '../../icons/Wallet';
import User from '../../icons/User';
import Menu from '../../icons/Menu';
import Cross from '../../icons/Cross';
import Vk from '../../icons/Vk';
import Telegram2 from '../../icons/Telegram2';
import Instagram from '../../icons/Instagram';
import Youtube from '../../icons/Youtube';
import Twitter from '../../icons/Twitter';
import ArrowLink from '../../icons/ArrowLink';
import { WalletModal } from './modal/Wallet';


const HEADER_LINKS = [
    {
        title:'Владельцы и бренды',
        path:'/owners',
        id:7,
    },
    {
        title:'Главная',
        path:'/',
        id:1,
    },
        {
        title:'Как это работает',
        path:'/#how_it_work',
        id:2,
    },    {
        title:'Этапы и сроки',
        path:'/#stages_and_deadlines',
        id:3,
    },    {
        title:'Тарифы',
        path:'/#rate',
        id:4,
    },    {
        title:' Отзывы',
        path:'/#reviews',
        id:5,
    },
      {
        title:'Контакты',
        path:'#footer',
        id:6,
    },
 ]


const HeaderNew = () => {
    const [menu, setMenu] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [dropdown, setDropdown] = React.useState(false);
    const [connect, setConnect] = React.useState(false);

    const [search, setSearch] = React.useState(false);


    const [searchText, setSearchText] = React.useState('');

    const pages = useSelector(state => state.pages.entities);

    const email = useSelector(state => state.auth.email);

    const isAuth = useSelector(state => state.auth.isAuth);

    const auth = useSelector(state => state.auth);


    const navigate = useNavigate();
    const searchBrand = () => { // @Dveyn
        navigate(`/brandsnew?search=${searchText}`);
    };

    const handleSelectLink = (path) => {
        if (path === '/') {
            window.scroll({top:0, behavior: "smooth"})
            setMenu(false)
        }
        if (path === '#footer') {
            window.scroll({bottom:0, behavior: "smooth"})
            setMenu(false)
        }
        setMenu(false)
    }

    return (
        <>
            <header className={styles.header}>
                <div className={base.container}>
                    <div className={styles.headerInner}>
                        <Link to="/" className={styles.headerLogoInner}>
                            <img
                                src="/assets/img/logo.svg"
                                alt="logo"
                                className={styles.headerLogo}
                            />
                        </Link>

                        <div className={styles.headerContent}>
                            <div className={styles.headerNav}>
                                <Link className={styles.headerDropdownTitle} to="/owners">
                                    Владельцы
                                </Link>

                                <div className={styles.headerDropdownInner}>
                                    <p className={styles.headerDropdownTitle} onClick={() => navigate(`/brandsnew/collections`)}>
                                        Коллекции
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.76462 2.76365C1.8948 2.63347 2.10585 2.63347 2.23603 2.76365L4.00033 4.52794L5.76462 2.76365C5.8948 2.63347 6.10585 2.63347 6.23603 2.76365C6.3662 2.89382 6.3662 3.10488 6.23603 3.23505L4.23603 5.23505C4.10585 5.36523 3.8948 5.36523 3.76462 5.23505L1.76462 3.23505C1.63445 3.10488 1.63445 2.89382 1.76462 2.76365Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </p>

                                    <div className={styles.headerDropdown}>
                                        <div className={styles.headerDropdownContentWrapper}>
                                            <div className={styles.headerDropdownContent}>
                                                {Object.values(pages).map((page) => (
                                                    <Link
                                                        to={`/brandsnew/collections?cat=${page.id}`}
                                                        className={styles.headerDropdownLink}
                                                        onClick={() => setMenu(false)}
                                                    >
                                                        {page.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link className={styles.headerDropdownTitle} to="/brands">
                                    Брендам
                                </Link>

                                {isAuth && auth.userRole == 'admin' && (
                                    <Link to="/admin" className={styles.headerLink}>
                                        Админ
                                    </Link>
                                )}
                            </div>

                            <div className={styles.headerPoints}>
                                <a
                                    href="https://t.me/check_brand"
                                    target="_blank"
                                    className={cn(styles.headerPoint, styles.headerMobilePoint)}
                                >
                                    <Telegram />
                                </a>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => setSearch((prev) => !prev)}
                                >
                                    <Search />
                                </div>

                                <div
                                    className={cn(styles.headerPoint, styles.headerPointMobile)}
                                    onClick={() => {
                                        if (isAuth) {
                                            setProfile(true);
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <Wallet />
                                </div>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => {
                                        if (isAuth) {
                                            navigate('/profile');
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <User />
                                </div>

                                <div
                                    className={cn(styles.headerMenu, {
                                        [styles.active]: menu || profile,
                                    })}
                                    onClick={() => setMenu(true)}
                                >
                                    <Menu />
                                    <Cross className={styles.headerMenuCross} />

                                    <span>Меню</span>
                                </div>

                                <div
                                    className={cn(styles.headerSearchInner, {
                                        [styles.active]: search,
                                    })}
                                >
                                    <input
                                        type="text"
                                        placeholder="Введите запрос"
                                        className={styles.headerSearch}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                    />

                                    <button
                                        className={styles.headerSearchButton}
                                        onClick={searchBrand}
                                    >
                                        Найти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div
                className={cn(styles.headerMenuOuter, {
                    [styles.active]: menu,
                })}
                onClick={() => setMenu(false)}
            >
                <div className={styles.headerMenuOuterWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.headerMenuContent}>
                        <Cross className={styles.headerCross} onClick={() => setMenu(false)} />

                        <div className={styles.headerMenuMobileInner}>
                            <button
                                className={cn(styles.headerMenuMobileLink, {
                                    [styles.active]: dropdown,
                                })}
                                onClick={() => setDropdown((prev) => !prev)}
                            >
                                Коллекции
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.76462 2.76365C1.8948 2.63347 2.10585 2.63347 2.23603 2.76365L4.00033 4.52794L5.76462 2.76365C5.8948 2.63347 6.10585 2.63347 6.23603 2.76365C6.3662 2.89382 6.3662 3.10488 6.23603 3.23505L4.23603 5.23505C4.10585 5.36523 3.8948 5.36523 3.76462 5.23505L1.76462 3.23505C1.63445 3.10488 1.63445 2.89382 1.76462 2.76365Z"
                                        fill="white"
                                        fill-opacity="0.5"
                                    />
                                </svg>
                            </button>

                            {dropdown && (
                                <div className={styles.dropdownMenu}>
                                    {Object.values(pages).map((page) => (
                                        <Link
                                            to={`/brandsnew/brand?cat=${page.id}`}
                                            className={styles.dropdownMenuLink}
                                            onClick={() => setDropdown(false)}
                                        >
                                            {page.name}
                                        </Link>
                                    ))}
                                </div>
                            )}
                            {isAuth && auth.userRole == 'admin' && (
                                <Link to="admin" className={styles.headerMenuMobileLink}>
                                    Админ
                                </Link>
                            )}

                            <div className={styles.headerMenuMobileWrap}>
                                <div
                                    className={cn(styles.headerPoint, {
                                        [styles.active]: search,
                                    })}
                                    onClick={() => setSearch((prev) => !prev)}
                                >
                                    <Search />
                                </div>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => {
                                        if (isAuth) {
                                            navigate('/profile');
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <User />
                                </div>
                            </div>

                            {search && (
                                <div className={styles.headerMenuSearchInner}>
                                    <input
                                        type="text"
                                        placeholder="Введите запрос"
                                        className={styles.headerMenuSearch}
                                    />

                                    <button
                                        className={styles.headerMenuSearchButton}
                                        onClick={searchBrand}
                                    >
                                        Найти
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className={styles.headerMenuWrapper}>
                            <p className={styles.headerMenuTitle}>Меню</p>
                            <nav className={styles.headerMenuNav}>
                                {HEADER_LINKS.map((link) => (
                                    <Link
                                        to={link.path}
                                        key={link.id}
                                        className={styles.headerMenuNavLink}
                                        onClick={() => {
                                            handleSelectLink(link.path);
                                        }}
                                    >
                                        {link.title}
                                        <ArrowLink />
                                    </Link>
                                ))}
                            </nav>
                            <div className={styles.headerSocial}>
                                <a href="#" className={styles.headerSocialLink} target="_blank">
                                    <Vk />
                                </a>

                                <a href="#" className={styles.headerSocialLink} target="_blank">
                                    <Twitter />
                                </a>

                                <a
                                    href="https://www.youtube.com/@bratouverie4787"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Youtube />
                                </a>

                                <a
                                    href="https://www.instagram.com/bratouverie/"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Instagram />
                                </a>

                                <a
                                    href="https://t.me/bratouverie"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Telegram2 />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WalletModal
                connect={connect}
                profile={profile}
                setConnect={setConnect}
                setProfile={setProfile}
            />
        </>
    );
};

export default HeaderNew;
