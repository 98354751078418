import React from 'react';

const SortItem = ({text, id, onChange, checked}) => {
    return (
        <div className="collection__sort--item">
            <input
                type="radio"
                className="radio"
                name="sort"
                id={id}
                onChange={onChange}
                checked={checked}
            />

            <label htmlFor={id} className="collection__sort--item--label">
                {text}
            </label>
        </div>
    );
};

export default SortItem;
