import React from 'react';

const Telegram = ({ ...props }) => {
    return (
        <svg
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.59234 5.05779L13.7286 2.34571C17.3798 1.12862 19.3636 3.12196 18.1561 6.77321L15.444 14.9095C13.6232 20.3815 10.6332 20.3815 8.81234 14.9095L8.00734 12.4945L5.59234 11.6895C0.120261 9.86862 0.120261 6.88821 5.59234 5.05779Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.1875 12.081L11.6183 8.64062"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Telegram;
