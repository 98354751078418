import React from 'react';
import { Link } from 'react-router-dom';

const DropLinkMenuItem = (props) => {
    const { to = '', label = '' } = props;

    return (
        <Link to={to} className="header__admin--nav--drop--link">
            {label}
        </Link>
    );
};

export default React.memo(DropLinkMenuItem);
