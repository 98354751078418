import React from 'react';

import './index.css';

const LightModal = ({active, setActive, title, padding = false, children}) => {
    React.useEffect(() => {
        if(active){
            document.body.classList.add("scroll");
        }else{
            document.body.classList.remove("scroll");
        }
    }, [active]);

    return(
        <div className={`modal${active ? ' active' : ''}`}>
            <div className="light-modal__inner">
                <div className="light-modal__content">
                    <img src="/assets/img/cross.svg" alt="Cross" className="light-modal__close" onClick={() => setActive(false)} />

                    {children}
                </div>
            </div>
        </div>
    )
}

export default LightModal;