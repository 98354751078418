import React, { useEffect, useState } from 'react';
import s from './token.module.css'
import styles from '../../TokenSuccessBuy/tokenBuy.module.css';
import Input from '../../../common/Input/index'
import { Tooltip } from '../../TokenCard/modals/TokenActionsModal/components/Tooltip';
import { returnText, withdrawText } from '../../TokenCard/modals/TokenActionsModal/constants';
import Modal from '../../../common/Modal';
import { useGetProfileByPublicAddressQuery } from '../../../redux/api/userService';
import { helperCost } from '../index';
import {
    useCancelTransactionMutation,
    useConfirmTransactionMutation,
    useTransformTransactionInWorkMutation,
} from '../../../redux/api/billingService';

// мод. окна для перевода токенов в статусы(Админ)
export const TokenActionAdminModal = (
    {
        isOpen,
        setIsOpen,
        title = 'Оформление вывода',
        type = 'withdrawal',
        token,
        tokenRefetch
    }) => {
    // Стоимость токена
    const [tokenCost, setTokenCost] = useState('')
    // кол-во дней владения
    const [dayOwnership, setDayOwnership] = useState('')
    // Начислено(всего)
    const [accruedTotal, setAccruedTotal] = useState('')
    // Начислено(на данный момент)
    const [accrued, setAccrued] = useState('')
    // Кол-во Выведено
    const [withdrawn, setWithdrawn] = useState('')
    // сумма к выводу(указывает пользователь)
    const [withdrawalAmount, setWithdrawalAmount] = useState(0)

    const {data: dataUser} = useGetProfileByPublicAddressQuery({public_address: (token && token.wallet_owner)}, {skip: (!token || !token.wallet_owner)})

    const [transformToWork] = useTransformTransactionInWorkMutation()
    const [confirmTransaction] = useConfirmTransactionMutation()
    const [cancelTransaction] = useCancelTransactionMutation()

    const helperCalculateRefund = (tokenCost, withdrawn) => {
        const cleanedTokenCost = tokenCost.trim().replace(/\D/g, '');
        const fee = Number(cleanedTokenCost) * 0.96;
        if(withdrawn >= 0) {
            const clearWithDrawn = withdrawn.trim().replace(/\D/g, '')
            return fee - (Number(clearWithDrawn) / 2)
        }

        if(fee < 0) {
            return 0
        }
    }

    const handleCloseModal = () => {
        setIsOpen(false)
        tokenRefetch()
        setWithdrawalAmount(0)
    }

    const handlerInWorkClick = (id) => {
        transformToWork({id})
            .unwrap()
            .then(() => {
                handleCloseModal()
            })
    }

    const handlerConfirmClick = (id) => {
        confirmTransaction({id})
            .unwrap()
            .then(() => {
                handleCloseModal()
            })
    }

    const handlerCancelClick = (id) => {
        cancelTransaction({id})
            .unwrap()
            .then(() => {
                handleCloseModal()
            })
    }

    const handleButtonClick = (status, type) => {
        if(status === 'created') {
            handlerInWorkClick(token.id)
        } else if(status === 'in_work' && !type) {
            handlerConfirmClick(token.id)
        } else if (status === 'in_work' && type === 'cancel') {
            handlerCancelClick(token.id)
        }

    }

    useEffect(() => {
        if(token && token.token_account) {
            setTokenCost(helperCost(token.token_account.price_token))
            setDayOwnership(token.token_account.days_ownership ? token.token_account.days_ownership : '0')
            setAccruedTotal(helperCost(token.token_account.total_result))
            setWithdrawn(helperCost(token.token_account.withdrawn ))
            setWithdrawalAmount(helperCost(token.amount))
            setAccrued(helperCost(token.token_account.accrued  + token.token_account.frozen_amount  + token.token_account.withdrawn))
        }
    }, [token, isOpen])

    return (
        <Modal
            active={isOpen}
            title={title}
            setActive={setIsOpen}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    color: 'white'
                }}
            >
                <p>Пользователь: <span style={{color: 'hsla(210, 77%, 51%, 1)'}}>{dataUser && dataUser.username}</span> </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        marginTop: '20px',
                        width: '100%'
                    }}
                >
                    <Input
                        title=""
                        placeholder={`${dataUser && dataUser.email}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    <Input
                        title=""
                        placeholder={`${dataUser && dataUser.telegram}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    <Input
                        title=""
                        placeholder={`${dataUser && dataUser.whatsapp}`}
                        value={''}
                        setValue={setTokenCost}
                        disabled
                    />
                    {dataUser && dataUser.instagram &&
                        <Input
                            title=""
                            placeholder={`${dataUser && dataUser.instagram}`}
                            value={''}
                            setValue={setTokenCost}
                            disabled
                        />
                    }

                </div>

            </div>

            <div
                className={s.box}
            >
                {token && token.token && token.token.file_1 && <img src={token.token.file_1} className={styles.img} alt={'token image'}/>}
                <div className={s.block}>
                    <a
                        className={s.link}
                        href={token && `/collection/${token.token.collection.id}`}
                    >
                        {token && token.token && token.token.collection.name}
                    </a>
                    <p>{token && token.token && token.token.name}</p>
                </div>

            </div>
            <div
                className={s.inputs}
            >
                <Input
                    title=""
                    placeholder={`Стоимость: ${tokenCost} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Во владении: ${dayOwnership}/365 дней`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Начислено: ${accrued} RUB/${accruedTotal} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                <Input
                    title=""
                    placeholder={`Выведено: ${withdrawn} RUB`}
                    value={''}
                    setValue={setTokenCost}
                    disabled
                />
                {token && token.status !== 'cancelled' &&
                    <>
                        <div className={s.flex}>
                            <p style={{whiteSpace: 'nowrap'}}>{type === 'return' ? 'К возврату:' : 'Доступно к выводу:'}</p>

                            <div
                                className={'input create__item--input '}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '373px'
                                }}
                            >
                                <p
                                    style={{
                                        color: 'hsla(210, 13%, 91%, 0.6)'
                                    }}
                                >
                                    {type === 'return' ? `${helperCalculateRefund(tokenCost, withdrawn)} RUB` : `${token && (token.token_account || token.amount) ? Math.floor((token.token_account.accrued + token.amount) / 100).toLocaleString('ru-RU') : '0'} RUB`}
                                </p>
                                <Tooltip
                                    text={type === 'return' ? returnText : withdrawText}
                                />
                            </div>
                        </div>
                        {type === 'withdrawal' &&
                            <div className={s.flex}>
                                <p style={{whiteSpace: 'nowrap'}}>Вывести:</p>
                                <Input
                                    title=""
                                    value={withdrawalAmount === 0 ? '' : `${withdrawalAmount} RUB`}
                                    typeItem={'medium'}
                                    disabled
                                />
                            </div>
                        }
                    </>
                }
            </div>

            {token && (token.status !== 'cancelled' && token.status !== 'confirmed') &&
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <button
                        className={s.withdrawalBtn}
                        style={{
                            background: type === 'return' ? 'hsla(304, 23%, 51%, 1)' : 'hsla(210, 78%, 40%, 1)'
                        }}
                        onClick={() => handleButtonClick(token.status)}
                    >
                        {token && token.status === 'created' ? 'Перевести в статус "В работе"' : token && token.status === 'in_work' ? 'Перевести в статус "Подтверждено"' : ''}
                        {/*{type === 'withdrawal' ? `Вывести ${withdrawalAmount} RUB` : `Оформить возврат ${helperCalculateRefund(tokenCost, withdrawn)} RUB`}*/}
                    </button>
                    {token && token.status === 'in_work' &&
                        <button
                            className={s.withdrawalBtn}
                            style={{
                                background: 'hsla(0, 54%, 56%, 1)',
                                marginTop: '10px'
                            }}
                            onClick={() => handleButtonClick(token.status, 'cancel')}
                        >
                            Перевести в статус "Отменено"
                        </button>
                    }
                </div>
            }
        </Modal>
    )
}
