import React from 'react';

import styles from './index.module.css';

const PropertyItem = ({ title, value, text }) => {
    return (
        <div className={styles.tokenInfoProp}>
            <p className={styles.tokenInfoPropTitle}>{title}</p>

            <p className={styles.tokenInfoPropValue}>{value}</p>

            <p className={styles.tokenInfoPropSubtext}>{text}</p>
        </div>
    );
};

export default PropertyItem;
