exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchInput_collectionSearchInner__3ppEd{\n    width: 100%;\n    flex-grow: 1;\n    position: relative;\n}\n\n.SearchInput_headerSearch__VcIck{\n    padding: 1.3rem 3.5rem 1.3rem 3.6rem;\n    width: 100%;\n    border-radius: 4px;\n    background: rgba(229, 232, 235, 0.02);\n    color: #fff;\n    font-size: 1.4rem;\n}\n\n.SearchInput_headerSearch__VcIck::placeholder{\n    color: rgba(255, 255, 255, 0.6);\n}\n\n.SearchInput_headerSearchIcon__1lOgV{\n    width: 16px;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 12px;\n    opacity: 0.6;\n    /* pointer-events: none; */\n    -webkit-user-select: none;\n            user-select: none;\n}\n\n.SearchInput_headerSearchRemove__1WTd_{\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    right: 12px;\n    width: 16px;\n    cursor: pointer;\n}\n\n@media (max-width: 480px) {\n    .SearchInput_collectionSearchInner__3ppEd {\n        margin: 0rem -1.2rem;\n        width: calc(100% + 24px);\n    }\n\n    .SearchInput_headerSearch__VcIck {\n        border-radius: 0;\n        padding: 1.3rem 3.5rem 1.3rem 5.6rem;\n    }\n\n    .SearchInput_headerSearchIcon__1lOgV {\n        left: 27px;\n    }\n}", ""]);

// exports
exports.locals = {
	"collectionSearchInner": "SearchInput_collectionSearchInner__3ppEd",
	"headerSearch": "SearchInput_headerSearch__VcIck",
	"headerSearchIcon": "SearchInput_headerSearchIcon__1lOgV",
	"headerSearchRemove": "SearchInput_headerSearchRemove__1WTd_"
};