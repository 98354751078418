import React from 'react';

const WhatsApp2 = ({ ...props }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.482745 17.3864C0.442261 17.5559 0.492618 17.7351 0.621965 17.8578C0.717742 17.9503 0.844128 18 0.975439 18C1.01889 18 1.06134 17.9951 1.10182 17.9854L5.21439 16.9306C6.40516 17.477 7.67594 17.7536 9.00088 17.7536C13.9625 17.7536 18 13.772 18 8.87679C18 3.98261 13.9607 0 8.99911 0C4.03749 0 0 3.98261 0 8.87679C0 10.5676 0.479858 12.2029 1.39319 13.6219L0.482745 17.3864ZM5 4.92469L5.65166 4.31402C5.8995 4.08222 6.29147 4.09196 6.52647 4.33642L7.99471 5.86262C8.22971 6.10709 8.21983 6.49374 7.972 6.72554L7.33019 7.32941C7.33019 7.32941 6.48104 7.83294 8.26031 9.52861L8.33338 9.60555C9.95861 11.4454 10.5125 10.635 10.5125 10.635L11.1543 10.0312C11.4022 9.79936 11.7942 9.8091 12.0292 10.0536L13.4727 11.5525C13.7077 11.7969 13.6978 12.1836 13.45 12.4154L12.7983 13.0261C12.3007 13.4926 11.6026 13.7069 10.9302 13.5695C10.0179 13.3855 8.65231 12.7465 6.81965 10.9485C6.8552 10.9885 6.89075 11.0255 6.92629 11.0683L6.70413 10.8385C6.74165 10.8784 6.78016 10.9135 6.81768 10.9505C5.09174 9.05516 4.5151 7.67699 4.37587 6.76917C4.27713 6.09422 4.50234 5.39122 5 4.92469Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default WhatsApp2;
