import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

const FeedbackForm = () => {
    return (
        <div className={styles.mainformInner}>
            <h2 className={cn(base.title2, styles.mainformTitle)}>Обратная связь</h2>

            <p className={styles.mainFormText}>
                Заполните форму и специалист службы поддержки ответит вам в ближайшее время
            </p>

            <div className={styles.mainformContent}>
                <div className={styles.mainformForm}>
                    <div className={styles.mainformInputs}>
                        <div className={styles.mainformInputInner}>
                            <p className={styles.mainformInputName}>Имя</p>
                            <input
                                type="text"
                                className={styles.mainformInput}
                                placeholder="Введите имя"
                            />
                        </div>

                        <div className={styles.mainformInputInner}>
                            <p className={styles.mainformInputName}>Телефон</p>
                            <input
                                type="text"
                                className={styles.mainformInput}
                                placeholder="Введите телефон"
                            />
                        </div>

                        <div className={styles.mainformInputInner}>
                            <p className={styles.mainformInputName}>Сообщение</p>
                            <input
                                type="text"
                                className={styles.mainformInput}
                                placeholder="Введите сообщение"
                            />
                        </div>
                    </div>

                    <div className={styles.mainformFormBottom}>
                        <button className={cn(base.orangeButton, styles.mainformButton)}>
                            Отправить
                        </button>

                        <p className={styles.mainformAgree}>
                            Нажимая на кнопку «Отправить, вы даёте своё согласие на обработку
                            персональных данных в соответствии с{' '}
                            <Link to="/privacy">Политикой конфиденциальности</Link>
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.mainFormBottomText}>
                <p>
                    Если у вас возникли трудности с использованием формы, напишите нам по адресу 
                    <a href="mailto:support@checkbrand.com">support@checkbrand.com</a>
                </p>
                <p>
                    Также вы можете написать в 
                    <a href="https://t.me/check_brand" target="_blank">
                        чат поддержки
                    </a>
                     CheckBrand в Телеграм
                </p>
            </div>
        </div>
    );
};

export default FeedbackForm;
