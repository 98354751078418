import React from 'react';

import styles from '../index.module.css';

const CollectionItemEmpty = () => {
    return (
        <div className={styles.collectionEmpty}>
            <svg
                width="130"
                height="155"
                viewBox="0 0 130 155"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.38726 25.6127 0 16.5 0C7.38732 0 0 7.38726 0 16.5C0 25.6127 7.38732 33 16.5 33Z"
                    fill="#1B283F"
                    fillOpacity="0.54"
                />
                <path
                    d="M67.2366 132.913C42.5893 132.913 22.599 113.028 22.599 88.4887C22.599 63.9718 42.5893 44.0872 67.2366 44.0872C83.3003 44.0872 97.3932 52.5345 105.246 65.198H130C125.472 51.5808 117.756 40.9686 106.859 33.3691C95.955 25.7846 83.2242 22 68.6824 22C50.0999 22 34.3253 28.3582 21.3967 41.1125C8.4681 53.8516 2 69.4671 2 87.9664C2 106.806 8.4681 122.694 21.3967 135.615C34.3253 148.528 50.2825 155 69.2227 155C83.2775 155 95.643 151.215 106.312 143.616C116.98 136.031 124.871 125.654 130 112.506H104.79C96.8681 124.791 83.0111 132.913 67.2366 132.913Z"
                    fill="#1B283F"
                    fillOpacity="0.54"
                />
            </svg>

            <div className={styles.collectionEmptyLine}></div>
            <div className={styles.collectionEmptyLine}></div>
            <div className={styles.collectionEmptyLine}></div>
        </div>
    );
};

export default CollectionItemEmpty;
