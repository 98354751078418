import React from 'react';

const Eye = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.14074 9C2.25003 9.18887 2.39492 9.42955 2.57441 9.70747C3.03543 10.4213 3.71817 11.3706 4.60454 12.3161C6.39552 14.2264 8.89951 16 12 16C15.1005 16 17.6045 14.2264 19.3955 12.3161C20.2818 11.3706 20.9646 10.4213 21.4256 9.70747C21.6051 9.42955 21.75 9.18887 21.8593 9C21.75 8.81113 21.6051 8.57045 21.4256 8.29253C20.9646 7.57869 20.2818 6.6294 19.3955 5.68394C17.6045 3.77356 15.1005 2 12 2C8.89951 2 6.39552 3.77356 4.60454 5.68394C3.71817 6.6294 3.03543 7.57869 2.57441 8.29253C2.39492 8.57045 2.25003 8.81113 2.14074 9ZM23 9C23.8944 8.55279 23.8941 8.55208 23.8941 8.55208L23.8925 8.54887L23.889 8.54204L23.8777 8.51982C23.8681 8.50127 23.8546 8.47531 23.8372 8.44244C23.8025 8.3767 23.752 8.28323 23.686 8.16599C23.5542 7.93164 23.3601 7.60152 23.1057 7.20747C22.5979 6.42131 21.8432 5.3706 20.8545 4.31606C18.8955 2.22644 15.8995 0 12 0C8.10049 0 5.10448 2.22644 3.14546 4.31606C2.15683 5.3706 1.40207 6.42131 0.894336 7.20747C0.63985 7.60152 0.445792 7.93164 0.313971 8.16599C0.248023 8.28323 0.19754 8.3767 0.162753 8.44244C0.145357 8.47531 0.131875 8.50127 0.122338 8.51982L0.11099 8.54204L0.107539 8.54887L0.10637 8.5512C0.10637 8.5512 0.105573 8.55279 1 9L0.105573 8.55279C-0.0351909 8.83431 -0.0351909 9.16569 0.105573 9.44721L1 9C0.105573 9.44721 0.105573 9.44721 0.105573 9.44721L0.107539 9.45113L0.11099 9.45796L0.122338 9.48018C0.131875 9.49873 0.145357 9.52469 0.162753 9.55756C0.19754 9.6233 0.248023 9.71677 0.313971 9.83401C0.445792 10.0684 0.63985 10.3985 0.894336 10.7925C1.40207 11.5787 2.15683 12.6294 3.14546 13.6839C5.10448 15.7736 8.10049 18 12 18C15.8995 18 18.8955 15.7736 20.8545 13.6839C21.8432 12.6294 22.5979 11.5787 23.1057 10.7925C23.3601 10.3985 23.5542 10.0684 23.686 9.83401C23.752 9.71677 23.8025 9.6233 23.8372 9.55756C23.8546 9.52469 23.8681 9.49873 23.8777 9.48018L23.889 9.45796L23.8925 9.45113L23.8936 9.4488C23.8936 9.4488 23.8944 9.44721 23 9ZM23 9L23.8944 9.44721C24.0352 9.16569 24.0348 8.83361 23.8941 8.55208L23 9Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7ZM8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default Eye;
