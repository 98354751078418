export const getAgoTimeFormat = (ms) => {
    const diffDays = Math.floor(ms / 86400000);
    const diffHrs = Math.floor((ms % 86400000) / 3600000);
    const diffMins = Math.round(((ms % 86400000) % 3600000) / 60000);

    if (diffDays > 0) {
        return `${diffDays} дней назад`;
    }

    if (diffHrs > 0) {
        return `${diffHrs} часов назад`;
    }

    if (diffMins > 0) {
        return `${diffMins} минут назад`;
    }

    return 'Прямо сейчас';
};
