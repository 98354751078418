import React from 'react';

import './index.css';

import { CustomSelect } from '../../common/CustomSelect';
import { FilterItemOldLists } from '../../components/FilterItemOldLists/FilterItemOldLists';
import File from '../../common/File';
import FullDescription from '../../common/FullDescription';
import { Link } from 'react-router-dom';

const sortList = [
    { value: 'user1', name: 'User 1' },
    { value: 'user2', name: 'User 2' },
    { value: 'user3', name: 'User 3' },
    { value: 'user4', name: 'User 4' },
];

const CreateWhitelist = () => {
    const [logo, setLogo] = React.useState('');

    const [filter, setFilter] = React.useState('user1');
    const [filters, setFilters] = React.useState([]);
    const [filterActive, setFilterActive] = React.useState(false);
    const [filterData2, setFilterData2] = React.useState([
        {
            text: 'Brand name',
            brand: '/assets/img/person9.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person8.png',
        },
        {
            text: 'Brand name',
            brand: '/assets/img/person7.png',
        },
    ]);

    const filterChange = (value) => {
        setFilter(value);
    };

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <div className="title__inner">
                        <Link to="../whitelistes">
                            <img src="/assets/img/back-arrow.svg" alt="arrow" />
                        </Link>
                        
                        <h2 className="title collection__title">Create Whitelist</h2>
                    </div>

                    <FullDescription description="Fill in the fields and click Save. The service will be available to users after moderation.
                            Specify the NFT - collections whose owners will be able to use the service.
                            Specify the type of service, description, contacts and other information.
                            In case of providing a low-quality/inconsistent with the service description, the service will be deleted.
                            It is forbidden to provide illegal services.
                            Before providing the service it is necessary to scan the QR code of the NFT owner on his/her phone and verify the avatar of the NFT owner with the client's face.
                            Or ask the user to put your company logo on his/her profile avatar and send you the link to his/her NFT.
                            The service can only be provided if there is a match.
                            By posting your ad you agree to the rules of the service." />

                    <button className="button service__show--collection" onClick={() => setFilterActive(true)}>
                        Choose collections

                        <span className="service__collection--count">
                            8
                        </span>
                    </button>

                    <div className="collection__content">
                        <div className={`collection__filter--box service__filter${filterActive ? " active" : ""}`}>
                            <div className="collection__filter--title--box">
                                <p className="collection__filter--title">Filters</p>

                                <img
                                    src="/assets/img/cross2.svg"
                                    alt="cross"
                                    className="collection__filter--title--cross"
                                    onClick={() => setFilterActive(false)}
                                />
                            </div>

                            <FilterItemOldLists
                                title="Brands"
                                value={filterData2.length}
                                elements={filterData2}
                                setData={setFilterData2}
                                setFilters={setFilters}
                                filter
                                filters={filters}
                            />

                            <div className="collection__filter--buttons">
                                <button className="button collection__filter--button--filter">
                                    Clear all
                                </button>

                                <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive(false)}>
                                    Done
                                </button>
                            </div>
                        </div>

                        <div className="collection__content--preitems">
                            <div className="collection__filter--active">
                                <div className="collection__filter--active--content">
                                    <button className="button collection__filter--active--item">
                                        <p className="collection__filter--active--item--text">
                                            Ready
                                        </p>

                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="collection__filter--active--item--delete"
                                        />
                                    </button>
                                </div>

                                <button className="button collection__filter--clear">
                                    Clear All
                                </button>
                            </div>

                            <div className="service__content">
                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Moderator
                                    </p>

                                    <div className="service__item--content">
                                        <CustomSelect
                                            optionsList={sortList}
                                            value={filter}
                                            placeholder="Choose Whitelist moderator"
                                            onChange={filterChange}
                                        />
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        Whitelist name
                                    </p>

                                    <div className="service__item--content">
                                        <input className="input service__item--input" placeholder="Enter whitelist name" />

                                        <p className="service__item--input--char">
                                            30 characters
                                        </p>
                                    </div>
                                </div>

                                <div className="service__item">
                                    <p className="service__item--title">
                                        Whitelist logo
                                    </p>

                                    <div className="service__item--content">
                                        <File
                                            type="logosmall"
                                            id="createwlLogo"
                                            value={logo}
                                            setValue={setLogo}
                                        />
                                    </div>
                                </div>

                                <div className="service__item">
                                    <p className="service__item--title">
                                        Kind of WL
                                    </p>

                                    <div className="service__item--content">
                                        <div className="service__item--points">
                                            <div className="service__item--point active">
                                                Transfer
                                            </div>

                                            <div className="service__item--point">
                                                Static
                                            </div>
                                        </div>

                                        <div className="service__list--mobile">
                                            <CustomSelect
                                                optionsList={sortList}
                                                value={filter}
                                                placeholder="Select filter"
                                                onChange={filterChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">
                                        WL description
                                    </p>

                                    <div className="service__item--content">
                                        <textarea className="input service__item--textarea long" placeholder="Enter a whitelist description (if necessary)"></textarea>

                                        <p className="service__item--input--char">
                                            500 characters
                                        </p>
                                    </div>
                                </div>

                                <div className="service__item--elem">
                                    <div className="modal__content">
                                        <div className="modal__content--top">
                                            <h2 className="modal__title">
                                                Get on whitelist
                                            </h2>

                                            <img src="/assets/img/cross2.svg" alt="Cross" className="modal__close" />
                                        </div>

                                        <div className="modal__content--bottom">
                                            <p className="modal__subtitle--blue">
                                                Reebok NFT Certificates
                                            </p>

                                            <div className="with__edit">
                                                <div className="modal__change--inner">
                                                    <div className="modal__change">
                                                        <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                    </div>
                                                </div>

                                                <p className="modal__text center">
                                                    To get on whitelist let us know the information of your branded item
                                                </p>
                                            </div>

                                            <div className="modal__inputs--content">
                                                <div className="modal__inputs--item">
                                                    <div className="modal__inputs--wrap">
                                                        <p className="modal__inputs--item--text">
                                                            Enter name of the product:
                                                        </p>

                                                        <div className="modal__change--inner">
                                                            <div className="modal__change">
                                                                <img src="/assets/img/add.svg" alt="add" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/delete2.svg" alt="delete" className="modal__change--icon" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal__changeInner">
                                                        <input type="text" className="input modal__input" placeholder="e.g. Shaq Attaq Basketball Shoes" />

                                                        <div className="checkboxInner">
                                                            <input type="checkbox" name="required" id="checkbox1" className="reqCheckbox" />

                                                            <label htmlFor="checkbox1" className="label__req"></label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal__inputs--item">
                                                    <div className="modal__inputs--wrap">
                                                        <p className="modal__inputs--item--text">
                                                            Material
                                                        </p>

                                                        <div className="modal__change--inner">
                                                            <div className="modal__change">
                                                                <img src="/assets/img/add.svg" alt="add" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/delete2.svg" alt="delete" className="modal__change--icon" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal__changeInner">
                                                        <input type="text" className="input modal__input" placeholder="e.g. Eco-leather" />

                                                        <div className="checkboxInner">
                                                            <input type="checkbox" name="required" id="checkbox2" className="reqCheckbox" />

                                                            <label htmlFor="checkbox2" className="label__req"></label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal__inputs--item">
                                                    <div className="modal__inputs--wrap">
                                                        <p className="modal__inputs--item--text">
                                                            Describe story about your branded item
                                                        </p>

                                                        <div className="modal__change--inner">
                                                            <div className="modal__change">
                                                                <img src="/assets/img/add.svg" alt="add" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/delete2.svg" alt="delete" className="modal__change--icon" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal__changeInner">
                                                        <textarea type="text" className="input modal__input textarea" placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."></textarea>
                                                        
                                                        <div className="checkboxInner">
                                                            <input type="checkbox" name="required" id="checkbox3" className="reqCheckbox" />

                                                            <label htmlFor="checkbox3" className="label__req"></label>
                                                        </div>
                                                    </div>

                                                    <p className="modal__info--attention">
                                                        Attention! The information above will be uploaded to the NFT metadata.
                                                    </p>
                                                </div>

                                                <div className="modal__inputs--item">
                                                    <div className="modal__inputs--wrap">
                                                        <p className="modal__inputs--item--text">
                                                            Specify data for feedback
                                                        </p>

                                                        <div className="modal__change--inner">
                                                            <div className="modal__change">
                                                                <img src="/assets/img/add.svg" alt="add" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                            </div>

                                                            <div className="modal__change">
                                                                <img src="/assets/img/delete2.svg" alt="delete" className="modal__change--icon" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal__changeInner">
                                                        <input type="text" className="input modal__input" placeholder="Email" />
                                                        
                                                        <div className="checkboxInner">
                                                            <input type="checkbox" name="required" id="checkbox4" className="reqCheckbox" />

                                                            <label htmlFor="checkbox4" className="label__req"></label>
                                                        </div>
                                                    </div>

                                                    <div className="modal__changeInner">
                                                        <input type="text" className="input modal__input" placeholder="Discord name" />
                                                        
                                                        <div className="checkboxInner">
                                                            <input type="checkbox" name="required" id="checkbox5" className="reqCheckbox" />

                                                            <label htmlFor="checkbox5" className="label__req"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="with__edit">
                                                <div className="modal__change--inner">
                                                    <div className="modal__change">
                                                        <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                    </div>
                                                </div>

                                                <button className="button modal__button modal__default--button w100">
                                                    Confirm
                                                </button>
                                            </div>

                                            <div className="with__edit">
                                                <div className="modal__change--inner">
                                                    <div className="modal__change">
                                                        <img src="/assets/img/edit2.svg" alt="edit" className="modal__change--icon" />
                                                    </div>

                                                    <div className="modal__change">
                                                        <img src="/assets/img/delete2.svg" alt="delete" className="modal__change--icon" />
                                                    </div>
                                                </div>

                                                <div class="modal__info">
                                                    <div class="modal__info--block">
                                                        After confirmation, the information will be sent to the moderator for verification. If verified successfully, you will receive an email with further instructions.<br/>
                                                        Whitelist cannot be sold or transferred to another person. It will be attached to your wallet. Only you will be able to mint NFT of this collection.<br/>
                                                        A whitelist gives the right to mint one token. No more than one whitelist is possible in one collection during a minting.<br/>
                                                        By clicking Confirm you confirm your agreement to the processing of your data.<br/>
                                                        By participating in the minting, you are assuming an obligation to transfer the intellectual property rights to the information contained in the NFT description belonging to you as the author of the story, in case you sell the NFT containing such information in favor of the new owner of the NFT.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button className="button service__confirm">
                                    Save сhanges
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateWhitelist;
