import { cnb } from 'cnbuilder';
import React from 'react';
import css from './Loader.module.css';

const Loader = ({ className }) => (
    <div className={cnb(css.clearLoading, css.loadingEffect, className)}>
        <img src="/assets/img/loader.svg" alt="loader" />
    </div>
);

export default React.memo(Loader);
