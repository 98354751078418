import React from 'react'
import Modal from '../../../../common/Modal';
import s from './index.module.css'

export const WithdrawalAndReturnConditions = ({isOpen, setIsOpen}) => {
    return (
        <Modal
            active={isOpen}
            title={'Условия вывода и возврата'}
            setActive={setIsOpen}
            size={'medium'}
        >
            <div
                className={s.block}
            >
                <div>
                    <p>Вывод денежных средств</p><br />
                    <span>
                        Для вывода подключите кошелёк, зайдите в профиль, выберите сертификат, зайдите на страницу сертификата, нажмите кнопку «Вывести», укажите данные карты на которую планируется вывод средств, подтвердите вывод.
                        Средства поступят на вашу карту в течении от 1 до 7 банковских дней.
                        При выводе средств до истечения 365 дней владения сертификатом происходит перерасчёт по формуле: 1/2 ставки лицензионных выплат х количество дней владения сертификатом.
                        Вывод средств не препятствует последующему начислению.
                    </span>

                </div>
                <div>
                    <p>Оформление возврата</p><br />
                    <span>
                        Для возврата уплаченных за сертификат средств подключите кошелёк, зайдите в профиль, выберите сертификат, зайдите на страницу сертификата, нажмите кнопку «Оформить возврат», укажите контакты, подтвердите.
                        Возврат средств будет осуществлён на вашу карту в течении от 1 до 7 банковских дней.
                        При возврате уплаченных за сертификат средств до истечения 365 дней владения сертификатом, взымается комиссия за услуги банка в размере 4%.
                        В случае если ранее выводились начисленные средства, они так же вычитаются из суммы подлежащих к возврату средств.

                        В соответствии с Договором оферты не взымается комиссия и в полной мере подлежат выводу начисленные и уплаченные за сертификат средства по истечению 365 дней владения сертификатом.
                    </span>

                </div>
            </div>

        </Modal>
    )
}
