import React, { memo } from 'react';
import { Button, Checkbox, InputLabel } from '@mui/material';

const variants = {
    brands: {
        name: 'Бренды',
        type: 'resource_id',
    },
    collections: {
        name: 'Коллекции',
        type: 'sub_resource_id',
    },
};
export const SelectMenu = memo(({ data, title, setOpen, onChange }) => {
    return (
        <div className="selectFilter">
            <button style={{ color: 'white' }} onClick={(e) => setOpen(e, title)}>
                <h3>{variants[title] && variants[title].name}</h3>
            </button>
            <section style={{ width: '100%', paddingLeft: '20px' }}>
                {data.map((item, index) => {
                    return (
                        <section
                            key={index}
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '35px',
                            }}
                        >
                            <div>
                                <p style={{ color: 'white' }}>
                                    {index + 1}. {item.name}
                                </p>
                            </div>
                            <Checkbox
                                size="small"
                                checked={item.state === true}
                                sx={{ width: '20px', height: '20px' }}
                                value={item.value}
                                color="default"
                                onChange={(e) => onChange(item, variants[title].type)}
                            />
                        </section>
                    );
                })}
            </section>
        </div>
    );
});
