import React, { useState, useEffect, useRef,memo } from 'react';
import Loader from '../../common/Loader';

import './index.css';

export const FilterItemOldLists = memo(({
    title,
    elements,
    filter = false,
    isLoading,
    checkedItemsIds = [],
    getNewItemsHandler = () => {},
    handleChangeSearchQuery = () => {},
    onCheckItem = () => {},
    onRemoveItem = () => {},
    onChange = () => {},
    isOpen,
}) => {
    const path = window.location.pathname.split('/');

    const listContainerRef = useRef(null);

    const [open, setOpen] = useState(path.includes('conclusions') ? true : false);

    const inputChange = (value) => {
        onChange(value);
        if (checkedItemsIds.includes(value.id || value.name || value)) {
            onRemoveItem(value.id || value);
        } else {
            onCheckItem(value);
        }
    };

    useEffect(() => {
        const scrollHandler = () => {
            if (
                listContainerRef.current &&
                listContainerRef.current.scrollHeight - listContainerRef.current.scrollTop <= 420 &&
                getNewItemsHandler
            ) {
                getNewItemsHandler();
            }
        };

        if (listContainerRef.current && getNewItemsHandler) {
            listContainerRef.current.addEventListener('scroll', scrollHandler);
        }

        return () => {
            if (listContainerRef.current) {
                listContainerRef.current.removeEventListener('scroll', scrollHandler);
            }
        };
    }, [getNewItemsHandler, open]);

    useEffect(() => {
        if (isOpen) {
            setOpen(isOpen);
        }
    }, [isOpen]);

    return (
        <div className="collection__filter--box--item">
            <div
                className="collection__filter--box--title"
                onClick={() => setOpen((prev) => !prev)}
            >
                <p className="collection__filter--box--title--value">{title}</p>

                <div className="collection__filter--box--attrs">
                    <img
                        src="/assets/img/arrow-top.svg"
                        alt="arrow"
                        className={`collection__filter--box--attr--arrow${!open ? ' active' : ''}`}
                    />
                </div>
            </div>

            {open && (
                <>
                    {filter && (
                        <div className="collection__search--inner">
                            <input
                                type="text"
                                className="input header__search"
                                placeholder="Filter"
                                onChange={(e) => handleChangeSearchQuery(e.target.value)}
                            />

                            <img
                                src="/assets/img/search.svg"
                                alt="search"
                                className="header__search--icon"
                            />
                        </div>
                    )}

                    <div ref={listContainerRef} className="collection__filter--box--values">
                        {elements.length ? (
                            elements.map((data, index) => (
                                <div key={index} className="collection__filter--box--value">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={data.id || data.text || data}
                                        checked={checkedItemsIds.includes(
                                            data.id || data.text || data || data.name,
                                        )}
                                        value={data.id || data.text || data}
                                        onChange={() => inputChange(data)}
                                    />

                                    <label
                                        htmlFor={
                                            title === 'Brands'
                                                ? data.id
                                                : data.text || data.id || data
                                        }
                                        className="collection__filter--box--value--label"
                                    >
                                        {data.brand && (
                                            <img
                                                className="collection__filter--box--item--img"
                                                alt="logo"
                                                src={data.brand}
                                            />
                                        )}

                                        {title === 'Brands'
                                            ? data.text
                                            : data.name || data.text || JSON.stringify(data)}
                                    </label>
                                </div>
                            ))
                        ) : (
                            <>
                                {!isLoading && (
                                    <div className="collection__filter--box--value not_found">
                                        <span>No results found</span>
                                    </div>
                                )}
                            </>
                        )}
                        {isLoading && (
                            <div className="loader_container">
                                <Loader />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
})


