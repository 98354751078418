import css from '../../components/TableRow/index.module.css';

export const BREAKPOINTS_ACTIVE = {
    ACTIVE1600: css.activeoff1600,
    ACTIVE1200: css.activeoff1200,
}

export const BREAKPOINTS = {
    MD1600: css.off1600,
    MD1400: css.off1400,
    MD1200: css.off1200,
    MD998: css.off998,
    MD700: css.off700,
    MD620: css.off620,
    MD550: css.off550
}