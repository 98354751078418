import Modal from "../../common/Modal";
import React, {useEffect, useState} from 'react'
import styles from './index.module.css'
import Input from "../../common/Input";
import {PayModal} from "../../pages/TokenCard/PayModal/PayModal";
import {useDispatch, useSelector} from "react-redux";
import {connectWallet} from "../../functions/auth";
import {setIsBuyCertificateModalActive} from "../../redux/slices/auth";
import {useGetPackQuery, useLazyGetPackQuery} from "../../redux/api/dataService";
import {useLazyGetHandlerTokenQuery} from "../../redux/api/handleService";
import {roundInt} from "../../utils/roundInt";

export const BuyCertificate = (
  {
    token,
    offer,
    isBuyCertificate,
    setIsBuyCertificate,
    isAllTokensSoldOut,
    isOtherToken,
      collection
  }
) => {
  const [isPay, setIsPay] = useState(false)

  const dispatch = useDispatch()

  const userName = useSelector(state => state.auth.username)
  const isAuth = useSelector(state => state.auth.isAuth)
  const isBuyCertificateModalActive = useSelector(state => state.auth.isBuyCertificateModalActive)

  const handleCloseModal = () => {
    dispatch(setIsBuyCertificateModalActive(!isBuyCertificateModalActive))
    setIsBuyCertificate && setIsBuyCertificate(false)
  }

  const connect = () => {
    connectWallet(dispatch);
  }

  useEffect(() => {
    if(userName) {
      dispatch(setIsBuyCertificateModalActive(false))
    }
  }, [userName])

  return (
    <Modal
      title={'Приобретение сертификата'}
      active={isBuyCertificateModalActive || isBuyCertificate}
      onClose={handleCloseModal}
    >
      {token
        && <>
          <p className={styles.text}>Цифровой сертификат</p>
          <div className={styles.wrapper}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {token.file_1 && <img src={token.file_1}  className={styles.img}/>}
              <div style={{marginLeft: '21px'}}>
                <p className={styles.textCollection}>{token.collection.name}</p>
                <p className={styles.description}>{token.name}</p>
              </div>

            </div>
            <div>
              <p className={styles.description}>Стоимость</p>
              <p className={styles.description}>{roundInt({ num: Number(token.price) / 100 })}  RUB</p>
            </div>
          </div>
          {!isAuth && <>
            <p className={styles.description} style={{margin: '52px 0 5px 0', marginRight: 'auto'}}>Внимание!</p>
            <p className={styles.description} style={{marginBottom: '10px'}}>
              Для получения услуг вам необходимо приобрести данный сертификат
            </p>
          </>
          }

          <button
            className={`button token__card--content--price--buy`}
            style={{height: '52px', width: '100%'}}
            onClick={isAllTokensSoldOut
              ? () => {
                window.location.replace(`/collection/${token.collection.id}`)
              }
              : () => {
              setIsPay(true)
            }}
          >
            {isAllTokensSoldOut ? 'Выбрать сертификат' : 'Купить сертификат'}
          </button>
          {!isAuth &&
            <>
              <p className={styles.description} style={{margin: '40px 0 10px 0', marginRight: 'auto'}}>
                Уже приобрели сертификат?
              </p>
              <button
                onClick={connect}
                className={`button token__card--content--price--buy`}
                style={{height: '52px', width: '100%', background: 'hsla(125, 41%, 62%, 0.8)', margin: '12px 0 44px 0'}}
              >
                Авторизоваться
              </button>
            </>
          }
          <div style={{marginTop: '45px', width: '100%'}}>
            <Input
              placeholder={isOtherToken
                ? "К сожалению данный сертификат уже продан.\n" +
                "Но есть решение. \n" +
                "Нажмите Купить сертификат. Вы будете перенаправлены на другой, доступный к покупке сертификат. \n" +
                "Далее нажмите кнопку Купить, следуйте инструкциям."
                : isAllTokensSoldOut
                ? "К сожалению данный сертификат уже продан.\n" +
                "Но есть решение. \n" +
                "Нажмите Выбрать сертификат. Вы будете перенаправлены на страницу выбора сертификатов. \n" +
                "У свободных сертификатов - синяя кнопка Купить. Нажмите на неё и следуйте инструкциям."
                : "Сертификат позволяет получить доступ ко всем включённым в него услугам партнёров. Для покупки нажмите Купить сертификат, введите данные карты и эл. почту, подтвердите оплату, следуйте инструкциям."}
              textarea
              disabled={true}
            />
          </div>

          <PayModal setIsPay={setIsPay} isPay={isPay} token={token} collection={collection}/>
          </>
      }

    </Modal>
  )
}
