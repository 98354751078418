import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from '../index.module.css';

const CertificatesItemMiniSkeleton = ({ view = 'normal', filterOpen = false }) => {
    return (
        <div
            className={cn(styles.certificateItemWrapperMini, styles[view], {
                [styles.filterOpen]: filterOpen
            })}
        >
            <div className={styles.brandItem}>
                <Skeleton
                    containerClassName={styles.certificatesItemImgSkeletonWrapper}
                    className={styles.brandItemImgSkeleton}
                />

                <div className={styles.certificateItemInfo}>
                    <Skeleton
                        containerClassName={styles.certificatesItemNameSkeletonWrapper}
                        className={styles.certificatesItemNameSkeleton}
                    />

                    <Skeleton
                        containerClassName={styles.certificatesItemTextSkeletonWrapper}
                        className={styles.certificatesItemTextSkeleton}
                    />

                    <Skeleton
                        containerClassName={styles.certificatesItemButtonSkeletonWrapper}
                        className={styles.certificatesItemButtonSkeleton}
                    />
                </div>
            </div>
        </div>
    );
};

export default CertificatesItemMiniSkeleton;
