import React from 'react';

import css from './index.module.css';

const TableCell = ({
    title = false,
    text = false,
    scroll = false,
    link = false,
    subtext = false,
    breakpoint = "",
    breakpointActive = "",
    textCenter = false,
    children,
    textOrange = false,
    textGreen = false,
    textBlue = false,
    largeColumn = false,
    linkWithText = false,
    decorationLink = false,
    onClick = () => {},
    style
}) => {
    return (
        <div style={style}
          onClick={onClick}
          className={`
            ${title ? ` ${css.tableElementName}` : ""} ${css.tableElement} ${text ? ` ${css.tableElementText}` : ""} ${largeColumn ? css.largeColumn : ""} ${link ? ` ${css.tableElementLink}` : ""}${decorationLink ? ` ${css.decorationLink}` : ""}${linkWithText ? ` ${css.linkWithText}` : ""}${subtext ? ` ${css.tableElementSubtext}` : ""}${breakpoint ? ` ${breakpoint}` : ""}${breakpointActive ? ` ${breakpointActive}` : ""}${textCenter ? ` ${css.textCenter}` : ""} ${textOrange ? css.textOrange : ""}${textGreen ? css.textGreen : ""}${textBlue ? css.textBlue : ""}`}>
            {scroll ? link ? <div className={css.tableElementLinkScroll}>{children.map(item => item)}</div> : <p className={css.tableElementScroll}>{children}</p> : children}
        </div>
    )
}

export default TableCell;
