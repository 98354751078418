import React, {useState} from 'react'
import styles from './styles.module.css'

export const TextMore = (
  {
    text
  }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

 const previewText = text.slice(0, 213)

  return (
    <div>
      <div>
        <p className={styles.text}> {expanded ? text : previewText}</p>
      </div>
      {text.length > 213 &&
        <button onClick={toggleExpanded} className={`button ${styles.btn}`}>
          {expanded ? 'Скрыть' : 'Показать'}
          <img alt='logo' src={'/assets/img/triangle.svg'} style={{transform: `${expanded ? 'rotateX(180deg)' : 'rotateX(0deg)'}`}}/>
        </button>
      }
    </div>
  );
}