import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const UserAvatar = ({ style = 'default', avatar, className }) => {
    return (
        <span className={cn(styles.ownersItemUserAvatar, styles[style], className)}>
            <span>
                <img src={avatar} alt="img" />
            </span>
        </span>
    );
};

export default UserAvatar;
