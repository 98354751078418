import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const screenWidth = window.innerWidth;
const maxLength = screenWidth > 716 ? 120 : 30;

const FadeDescription = ({ description, theme = '', className }) => {
    const [fullText, setFullText] = React.useState(false);

    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };

    return (
        <div
            className={cn(styles.fadeDesc, styles[theme], className)}
            style={{ whiteSpace: 'pre-line' }}
        >
            <p>
                {description && description.length > maxLength && !fullText
                    ? `${description.slice(0, maxLength)}...`
                    : description}
            </p>

            {description && description.length > maxLength && (
                <a
                    href="#"
                    className={styles.fadeDescTextShow}
                    onClick={showText}
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {fullText ? 'Свернуть' : 'Развернуть'}
                </a>
            )}
        </div>
    );
};

export default FadeDescription;
