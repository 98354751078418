import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // page: 1,
  // lastItemsCount: 20,
  // collection: [],
  // collectionPage: 1,
  // packsLastItemsCount: 20,
  // name: '',
}

const collectionFactorySlice = createSlice({
  name: 'collection',
  initialState: initialState,
  reducers: {
    // collectionIncrementPage: (state) => {
    //   state.page += 1
    // },
    // collectionSetLastItemsCount: (state, action) => {
    //   state.lastItemsCount = action.payload
    // },
    // collectionAddPageData: (state, action) => {
    //   state.collection = [...state.collection, ...action.payload]
    // },
    // collectionRemove: (state) => {
    //   state.collection = []
    //   state.page = 1
    //   state.lastItemsCount = 20
    // },
    // changeSearchCollectionName: (state, action) => {
    //   state.name = action.payload
    // },
    // setCollection: (state,action) => {
    //   state.collection = action.payload
    // }
  },
})

export const {
  // changeSearchCollectionName,
  // collectionIncrementPage,
  // collectionSetLastItemsCount,
  // collectionRemove,
  // collectionAddPageData,
  // setCollection
} = collectionFactorySlice.actions

export const collectionFactoryReducer = collectionFactorySlice.reducer
