import React, { useState } from 'react';
import s from '../index.module.css'
import { IssueARefundModal } from '../../../modals/IssueARefundModal';
import { TokenActionsModal } from '../../../modals/TokenActionsModal/TokenActionsModal';
import { SuccessConclusionModal } from '../../../modals/SuccessConclusionModal/SuccessConclusionModal';
import {
    WithdrawalAndReturnConditions
} from '../../../modals/WithdrawalAndReturnConditions/WithdrawalAndReturnConditions';
import { ConfirmEmailModal } from '../../../../../components/ConfirmEmailModal';
import { useSelector } from 'react-redux';

export const InfoButtons = ({token, data, collection, setIsConditions, isConditions, tokenRefetch}) => {
    const [isContacts, setIsContacts] = useState(false)
    // оформление возврата мод. окно
    const [isReturnModal, setIsReturnModal] = useState(false)

    // вывод денег мод. окно
    const [isWithdrawalOfMoney, setIsWithdrawalOfMoney] = useState(false)

    // мод. окно после успешного вывода
    const [isSuccessModal, setIsSuccessModal] = useState(false)

    // мод. окно подтверждение Email
    const [isConfirmEmailModal, setIsConfirmEmailModal] = useState(false)

    const [changedEmail, setChangedEmail] = useState('')

    const auth = useSelector((state) => state.auth);
    const {instagram, whatsapp, telegram, email: emailState, walletAddress} = auth

    const [type, setType] = useState('')

    const isDisabledOutput = !token.paid || token.wallet_owner !== walletAddress || (data && !data.accrued) || (data && data.is_frozen)
    const isDisabledReturn = !token.paid || token.wallet_owner !== walletAddress || (data && data.is_frozen)

    return (
        <div
            className={s.buttons}
        >
            <button
                className={s.conclusion}
                style={{
                    background: token.paid && !isDisabledOutput ? 'hsla(210, 77%, 51%, 1)' : 'hsla(210, 77%, 51%, 0.5)'
                }}
                disabled={isDisabledOutput}
                onClick={() => {
                    setType('withdrawal')
                    setIsContacts(true)
                }}
            >
                Вывести {data && data.accrued ? Math.floor(data.accrued / 100).toLocaleString('ru-RU') : '0'} RUB
            </button>

            <button
                className={s.return}
                style={{
                    background: token.paid && !isDisabledReturn ? 'hsla(304, 23%, 51%, 1)' : 'hsla(304, 23%, 51%, 0.5)'
                }}
                disabled={isDisabledReturn}
                onClick={() => {
                    setType('return')
                    setIsContacts(true)
                }}
            >
                Оформить возврат
            </button>
            <IssueARefundModal
                isReturn={isContacts}
                setIsReturn={setIsContacts}
                setIsReturnModal={setIsReturnModal}
                setIsWithdrawalOfMoney={setIsWithdrawalOfMoney}
                type={type}
                setIsConfirmEmailModal={setIsConfirmEmailModal}
                setChangedEmail={setChangedEmail}
            />
            <TokenActionsModal
                isOpen={isReturnModal || isWithdrawalOfMoney}
                setIsOpen={isReturnModal ? setIsReturnModal : setIsWithdrawalOfMoney}
                title={isReturnModal ? 'Оформление возврата' : 'Оформление вывода'}
                type={type}
                token={token}
                data={data}
                setIsSuccessModal={setIsSuccessModal}
                setIsConditions={setIsConditions}
                onReturnBtnClick={() => {
                    setIsReturnModal(false)
                    setIsWithdrawalOfMoney(false)
                    setIsContacts(true)
                }}
                tokenRefetch={tokenRefetch}
            />
            <SuccessConclusionModal
                isOpen={isSuccessModal}
                setIsOpen={setIsSuccessModal}
                token={token}
                collection={collection}
            />
            <WithdrawalAndReturnConditions
                isOpen={isConditions}
                setIsOpen={setIsConditions}
            />

            {isConfirmEmailModal &&
                <ConfirmEmailModal
                    isConfirmedEmail={isConfirmEmailModal}
                    setIsConfirmedEmail={setIsConfirmEmailModal}
                    email={emailState}
                    inst={instagram}
                    whatsapp={whatsapp}
                    telegram={telegram}
                    handleCloseModal={() => {
                        type === 'return' ? setIsReturnModal(true) : setIsWithdrawalOfMoney(true)
                        setIsConfirmEmailModal(false)
                    }}
                    emailValue={changedEmail}
                />
            }
        </div>
    )
}
