import React from 'react';

import './index.css';
import Modal from '../../common/Modal';
import Loader from '../../common/Loader';
import { CustomSelect } from '../../common/CustomSelect';

const currencyList = [
    {
        value: '1',
        name: 'USDT',
        icon: '/assets/img/tether.svg'
    },
    {
        value: '2',
        name: 'Ruble (RUB)',
        icon: '/assets/img/rub.svg'
    },
    {
        value: '3',
        name: 'BNB',
        icon: '/assets/img/bnb.svg'
    }
];

const Cbcoin = () => {
    const [question, setQuestion] = React.useState(false);
    const [waiting, setWaiting] = React.useState(false);
    const [reject, setReject] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);

    const [usdt, setUsdt] = React.useState("");
    const [totalUsdt, setTotalUsdt] = React.useState(0);
    const [cbcoin, setCbcoin] = React.useState("");
    const [totalCbcoin, setTotalCbcoin] = React.useState(0);
    const [currency, setCurrency] = React.useState('1');

    const setCurrencyHandler = (value) => {
        setCurrency(value);
    };

    const inputHandlerUsdt = (e) => {
        setUsdt(e.target.value);
    }

    const inputHandlerCbcoin = (e) => {
        setCbcoin(e.target.value);
    }

    React.useEffect(() => {
        setTotalUsdt((Number(usdt) * 52).toFixed(2));
    }, [usdt]);

    React.useEffect(() => {
        setTotalCbcoin((Number(cbcoin) * 52).toFixed(2));
    }, [cbcoin]);

    return (
        <>
            <div className="cbcoin">
                <div className="container">
                    <div className="cbcoin__inner">
                        <div className="cbcoin__form">
                            <h1 className="title left">
                                Buy CBCOIN
                            </h1>

                            <div className="cbcoin__content">
                                <div className="cbcoin__item">
                                    <div className="cbcoin__item--text">
                                        <div className="cbcoin__item--value">
                                            <CustomSelect
                                                optionsList={currencyList}
                                                value={currency}
                                                placeholder="Currency"
                                                onChange={setCurrencyHandler}
                                            />
                                        </div>
                                        
                                        <p className="cbcoin__item--placeholder">
                                            Balance 200
                                        </p>
                                    </div>

                                    <div className="cbcoin__item--input--inner">
                                        <input type="number" className="input cbcoin__item--input" placeholder="0" value={usdt} onChange={inputHandlerUsdt} />

                                        {usdt && <p className="cbcoin__item--input--total">
                                            ~{totalUsdt} USD
                                        </p>}
                                    </div>
                                </div>

                                <button className="button cbcoin__change">
                                    <img src="/assets/img/arrow-bottom.svg" alt="arrow" className="cbcoin__change--icon" />
                                </button>

                                <div className="cbcoin__item">
                                    <div className="cbcoin__item--text">
                                        <div className="cbcoin__item--value">
                                            <img src="/assets/img/cbcoin.svg" alt="cbcoin" className="cbcoin__item--icon" />

                                            <p className="cbcoin__item--value--text">
                                                CBCOIN
                                            </p>
                                        </div>
                                        
                                        <p className="cbcoin__item--placeholder">
                                            Min 0.01
                                        </p>
                                    </div>

                                    <div className="cbcoin__item--input--inner">
                                        <input type="number" className="input cbcoin__item--input" placeholder="0" value={cbcoin} onChange={inputHandlerCbcoin} />

                                        {cbcoin && <p className="cbcoin__item--input--total">
                                            ~{totalCbcoin} USD
                                        </p>}
                                    </div>
                                </div>
                            </div>

                            <div className="cbcoin__swap">
                                <p className="cbcoin__wrap--title">
                                    Price
                                </p>

                                <p className="cbcoin__currency">
                                    1 CBCOIN
                                </p>

                                <button className="button cbcoin__swap--button">
                                    <img src="/assets/img/swap.svg" alt="swap" className="cbcoin__swap--icon" />
                                </button>

                                <p className="cbcoin__currency">
                                    1.0 USDT
                                </p>

                                <button className="button cbcoin__reload">
                                    <img src="/assets/img/reload2.svg" alt="reload" className="cbcoin__reload--icon" />
                                </button>
                            </div>

                            <div className="cbcoin__steps">
                                <div className="cbcoin__steps--buttons">
                                    <button className="button cbcoin__steps--button disabled" onClick={() => setReject(true)}>
                                        Enabled
                                    </button>

                                    <button className="button cbcoin__steps--button" onClick={() => setQuestion(true)}>
                                        Buy CBCOIN
                                    </button>
                                </div>

                                <div className="cbcoin__steps--items">
                                    <div className="cbcoin__steps--item disabled">
                                        <img src="/assets/img/arrow-next.svg" alt="arrow" className="cbcoin__steps--icon" />
                                    </div>

                                    <div className="cbcoin__steps--item">
                                        2
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title="Confirm Swap" size="small" active={question} setActive={setQuestion}>
                <div className="swap__confirm">
                    <div className="swap__confirm--item">
                        <div className="swap__confirm--wrap">
                            <div className="swap__confirm--currency">
                                <img src="/assets/img/usdt.svg" alt="usdt" className="swap__confirm--currency--icon" />
                            </div>

                            <p className="swap__item--text">
                                100
                            </p>
                        </div>

                        <p className="swap__item--text">
                            USDT
                        </p>
                    </div>

                    <div className="swap__confirm--currency">
                        <img src="/assets/img/arrow-current.svg" alt="arrow" className="swap__confirm--currency--icon" />
                    </div>

                    <div className="swap__confirm--item">
                        <div className="swap__confirm--wrap">
                            <div className="swap__confirm--currency">
                                <img src="/assets/img/cbcoin.svg" alt="cbcoin" className="swap__confirm--currency--icon" />
                            </div>

                            <p className="swap__item--text">
                                100
                            </p>
                        </div>

                        <p className="swap__item--text">
                            CBCOIN
                        </p>
                    </div>
                </div>

                <p className="swap__modal--text w100">
                    Slippage tolerance
                </p>

                <p className="swap__modal--subtext w100">
                    Output is estimated. You will receive at least 100 CBCOIN or the transaction will revert.
                </p>

                <div className="swap__total">
                    <div className="swap__total--item">
                        <p className="swap__total--title">
                            Price
                        </p>

                        <div className="swap__total--wrap">
                            <p className="swap__total--value">
                                1 CBCOIN / 1 USDT
                            </p>

                            <button className="button cbcoin__reload">
                                <img src="/assets/img/reload2.svg" alt="reload" className="cbcoin__reload--icon" />
                            </button>
                        </div>
                    </div>

                    <div className="swap__total--item">
                        <p className="swap__total--title">
                            Minimum received
                        </p>

                        <div className="swap__total--wrap">
                            <p className="swap__total--value">
                                100 CBCOIN
                            </p>
                        </div>
                    </div>
                </div>

                <button className="button modal__button swap__button" onClick={() => setConfirm(true)}>
                    Confirm Swap
                </button>
            </Modal>

            <Modal title="Confirm Swap" size="small" active={reject} setActive={setReject}>
                <div className="swap__reject--icon--inner">
                    <img src="/assets/img/reject.svg" alt="reject" className="swap__reject--icon" />
                </div>

                <p className="swap__modal--text">
                    Transaction rejected
                </p>

                <button className="button modal__button modal__default--button" onClick={() => setWaiting(true)}>
                    Dismiss
                </button>
            </Modal>

            <Modal title="Confirm Swap" size="small" active={waiting} setActive={setWaiting}>
                <div className="swap__reject--icon--inner">
                    <Loader />
                </div>

                <p className="swap__modal--text">
                    Waiting for confirmation
                </p>

                <p className="swap__modal--subtext">
                    Swapping 100 CBCOIN for 100 USDT
                </p>

                <p className="swap__modal--subtext">
                    Confirm this transaction in your wallet
                </p>
            </Modal>

            <Modal title="Confirm Swap" size="small" active={confirm} setActive={setConfirm}>
                <div className="swap__reject--icon--inner">
                    <img src="/assets/img/confirm.svg" alt="confirm" className="swap__reject--icon" />
                </div>

                <p className="swap__modal--text">
                    Transaction successful
                </p>

                <button className="button modal__button modal__default--button">
                    Thank you
                </button>
            </Modal>
        </>
    )
}

export default Cbcoin;