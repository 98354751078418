import React from 'react';

const Question2 = ({ ...props }) => {
    return (
        <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            { ...props }
        >
            <g clipPath="url(#clip0_14635_2276)">
                <mask
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                >
                    <path d="M16 0H0V16H16V0Z" fill="currentColor" />
                </mask>
                <g mask="url(#mask0_14635_2276)">
                    <path
                        d="M6.0587 5.99869C6.21544 5.55314 6.5248 5.17743 6.932 4.93811C7.33921 4.6988 7.81796 4.61132 8.28349 4.69117C8.74901 4.77102 9.17125 5.01304 9.47542 5.37438C9.77959 5.73571 9.94607 6.19304 9.94537 6.66536C9.94537 7.99869 7.94537 8.66536 7.94537 8.66536M7.9987 11.332H8.00537M14.6654 7.99869C14.6654 11.6806 11.6806 14.6653 7.9987 14.6653C4.3168 14.6653 1.33203 11.6806 1.33203 7.99869C1.33203 4.31679 4.3168 1.33203 7.9987 1.33203C11.6806 1.33203 14.6654 4.31679 14.6654 7.99869Z"
                        stroke="currentColor"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath>
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Question2;
