import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const SocialBlock = ({ children, className }) => {
    return <div className={cn(styles.profileSocialContent, className)}>{children}</div>;
};

export default SocialBlock;
