exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SuperAdministrator_content__mupLV {\n  width: 100%;\n  margin-top: 5rem;\n}\n\n@media (max-width: 768px) {\n  .SuperAdministrator_content__mupLV{\n    margin-top: 3rem;\n  }\n}\n\n.SuperAdministrator_contentGroup__3bSz_ {\n  margin-bottom: 5.7rem;\n}\n\n.SuperAdministrator_contentGroup__3bSz_ div:not(:first-child) {\n  margin-top: 12px;\n}", ""]);

// exports
exports.locals = {
	"content": "SuperAdministrator_content__mupLV",
	"contentGroup": "SuperAdministrator_contentGroup__3bSz_"
};