import React from 'react';

const Tshirt = ({ ...props }) => {
    return (
        <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6807 17V6.56839L20.4033 7.35586L21.25 2.78392L14.4765 0.0833787C14.3661 0.958856 13.9611 1.74632 13.3675 2.34387C12.6635 3.04796 11.6926 3.48338 10.625 3.48338C9.55744 3.48338 8.59111 3.04796 7.88707 2.34387C7.28887 1.74632 6.88393 0.958856 6.7735 0.0833787L0 2.78392L0.846687 7.35586L4.56935 6.56839V17H16.6807ZM10.625 2.81635C11.5131 2.81635 12.3138 2.45041 12.8982 1.87139C13.3905 1.37575 13.7265 0.727248 13.8185 0C12.7555 0.162125 11.6926 0.245504 10.625 0.250136C9.55744 0.250136 8.49448 0.166757 7.43612 0C7.52355 0.727248 7.85946 1.37575 8.35183 1.87139C8.93623 2.45041 9.7415 2.81635 10.625 2.81635Z"
                fill="currentColor"
            />
            <path
                d="M7.63672 6.375C7.52675 6.375 7.4375 6.4583 7.4375 6.56094V8.79219C7.4375 8.89483 7.52675 8.97813 7.63672 8.97813H10.0273C10.1373 8.97813 10.2266 8.89483 10.2266 8.79219V6.56094C10.2266 6.4583 10.1373 6.375 10.0273 6.375H7.63672ZM11.2227 6.375C11.1127 6.375 11.0234 6.4583 11.0234 6.56094V8.79219C11.0234 8.89483 11.1127 8.97813 11.2227 8.97813H13.6133C13.7232 8.97813 13.8125 8.89483 13.8125 8.79219V6.56094C13.8125 6.4583 13.7232 6.375 13.6133 6.375H11.2227ZM7.83594 6.74688H9.82812V8.60625H7.83594V6.74688ZM11.4219 6.74688H13.4141V8.60625H11.4219V6.74688ZM8.23438 7.11875V8.23438H9.42969V7.11875H8.23438ZM11.8203 7.11875V8.23438H13.0156V7.11875H11.8203ZM7.63672 9.72188C7.52675 9.72188 7.4375 9.80518 7.4375 9.90781V12.1391C7.4375 12.2417 7.52675 12.325 7.63672 12.325H10.0273C10.1373 12.325 10.2266 12.2417 10.2266 12.1391V9.90781C10.2266 9.80518 10.1373 9.72188 10.0273 9.72188H7.63672ZM11.2227 9.72188C11.1127 9.72188 11.0234 9.80518 11.0234 9.90781V10.8375H11.4219V10.0938H12.2188V9.72188H11.2227ZM12.8164 9.72188C12.7064 9.72188 12.6172 9.80518 12.6172 9.90781V10.8375H13.0156V10.0938H13.8125V9.72188H12.8164ZM7.83594 10.0938H9.82812V11.9531H7.83594V10.0938ZM8.23438 10.4656V11.5813H9.42969V10.4656H8.23438ZM11.8203 10.8375V11.2094H11.6211C11.5111 11.2094 11.4219 11.2927 11.4219 11.3953V12.325H11.8203V11.5813H12.2188V12.325H12.6172V11.3953C12.6172 11.2927 12.5279 11.2094 12.418 11.2094H12.2188V10.8375H11.8203ZM13.4141 10.8375V12.325H13.8125V10.8375H13.4141Z"
                fill="black"
                fillOpacity={0.6}
            />
        </svg>
    );
};

export default Tshirt;
