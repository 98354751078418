import React from "react";

import './index.css';

const StatsItem = ({ data, id }) => {
    const { avatar, collection, volume, change, floor, items, status, minted } =
        data;

    const [fade, setFade] = React.useState(false);

    return (
        <div className="stats__item--values">
            <div className="stats__item--collection stats__item--item">
                <p className="stats__item--value">{id}</p>

                <div className="stats__item--avatar--inner">
                    {!avatar && <img
                        src={avatar}
                        alt="avatar"
                        className="stats__item--avatar"
                    />}
                </div>

                <div className="stats__item--wrapper">
                    <p className="stats__item--value">{collection}</p>

                    <button className="button stats__item--more" onClick={() => setFade(!fade)}>{fade ? '-Less' : '+More'}</button>
                </div>
            </div>

            <div className="stats__item--volume stats__item--item stats__item--wrapper">
                <p className="stats__item--value">{volume} ETH</p>

                <p className="stats__item--status--mobile">
                    {status}
                </p>
            </div>

            <div className={`stats__item--fade${fade ? ' active' : ''}`}>
                <div className="stats__item--day stats__item--wrap">
                    <p className="stats__item--wrap--title">% change</p>

                    <div
                        className={`stats__item--item stats__item--value ${
                            change[0] === "-" ? "loss" : "profit"
                        }`}
                    >
                        {change}
                    </div>
                </div>

                <div className="stats__item--week stats__item--wrap">
                    <p className="stats__item--wrap--title">Floor price</p>

                    <div className="stats__item--item stats__item--value">
                        {floor} ETH
                    </div>
                </div>

                <div className="stats__item--price stats__item--wrap">
                    <p className="stats__item--wrap--title">Items</p>

                    <div className="stats__item--item">
                        <p className="stats__item--value">{items}</p>
                    </div>
                </div>

                <div className="stats__item--owners stats__item--wrap">
                    <p className="stats__item--wrap--title">Status</p>

                    <div className="stats__item--item stats__item--value">
                        {status}
                    </div>
                </div>

                <div className="stats__item--items stats__item--wrap">
                    <p className="stats__item--wrap--title">Minted</p>

                    <div className="stats__item--item stats__item--value stats__items">
                        <p className="stats__item--item--title">{minted.value}</p>

                        <p className="stats__item--item--text">{minted.total}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsItem;
